<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { interval, Subscription } from 'rxjs'

@Component({})
export default class DataTable extends Vue {
  @Prop() public todos!: object[]

  private pulse = interval(1000)
  private subscription: Subscription

  private countDownTimer() {
    this.todos.forEach((todo: any) => {
      if (!todo.completed) {
        const now = new Date()
        const convertedDeadline = this.getDate(todo)
        const countDown = Math.floor(
          // @ts-ignore
          (convertedDeadline - now) / 1000
        )
        // @ts-ignore
        if (countDown <= 0 && !todo.completed) {
          this.setCompleted(todo)
        }
      }
    })
  }

  private setCompleted(todo) {
    this.$store.dispatch('todos/setCompleted', todo)
  }

  private getDate(todo) {
    let date = new Date()
    try {
      date = new Date(todo.deadline.toDate())
    } catch (err) {
      date = new Date()
    }
    return date
  }

  private mounted() {
    this.subscription = this.pulse.subscribe(() => this.countDownTimer())
  }

  private beforeDestroy() {
    this.subscription.unsubscribe()
  }
}
</script>
