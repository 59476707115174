import { render, staticRenderFns } from "./WebRtcPlayer.vue?vue&type=template&id=20276f93&scoped=true"
import script from "./WebRtcPlayer.vue?vue&type=script&lang=ts"
export * from "./WebRtcPlayer.vue?vue&type=script&lang=ts"
import style0 from "./WebRtcPlayer.vue?vue&type=style&index=0&id=20276f93&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20276f93",
  null
  
)

export default component.exports