<template>
  <div style="display: inline">
    <div v-if="isNamesLoading" class="loader-wrapper">
      <v-progress-circular
        indeterminate
        color="secondary"
        size="15"
      ></v-progress-circular>
    </div>
    <span v-if="!isNamesLoading"> {{ nodeNames }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

const namespaceHealthCheck = { namespace: 'liveHealthCheck' }

@Component({
  components: {}
})
export default class HealthCheckBreadCrumb extends Vue {
  @Prop() public selectedHealthCheck!: any
  @Prop() public configLevel!: string

  @Action('fetchBreadCrumbForHealthCheck', namespaceHealthCheck)
  private fetchBreadCrumbForHealthCheck

  public isNamesLoading: boolean = false
  public nodeNames: string = ''

  public async mounted() {
    await this.fetchHealthCheckData()
  }

  public async fetchHealthCheckData() {
    this.isNamesLoading = true
    this.nodeNames = await this.fetchBreadCrumbForHealthCheck({
      nodeLevel: this.configLevel,
      cameraRef: this.selectedHealthCheck.cameraRef,
      siteRef: this.selectedHealthCheck.siteRef,
      clientRef: this.selectedHealthCheck.clientRef
    })
    this.isNamesLoading = false
  }
}
</script>

<style scoped>
.bold-med-text {
  font-weight: 500;
}

.bold-high-text {
  font-weight: 700;
}

.text-detail-grey {
  color: #c4c4c4;
}
</style>
