var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_vm._v(" Live Stream ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","data-test-id":"live-stream-expand-icon","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pr-3 pl-3 py-4 form"},[(_vm.isExpanded)?_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Stream Type ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-select',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Select Stream Type","items":['RTSP', 'Heitel'],"outlined":"","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"data-test-id":"live-stream-type-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1732385462),model:{value:(_vm.liveStreamType),callback:function ($$v) {_vm.liveStreamType=$$v},expression:"liveStreamType"}})],1)],1):_vm._e(),(_vm.liveStreamType === 'RTSP')?[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" RTSP Url ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"RTSP Url","outlined":"","color":"secondary","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.rtspUrlError,"error":_vm.rtspUrlError.length > 0,"data-test-id":"rtsp-link-input"},model:{value:(_vm.rtspUrl),callback:function ($$v) {_vm.rtspUrl=$$v},expression:"rtspUrl"}})],1)],1)]:_vm._e(),(_vm.isExpanded && _vm.liveStreamType === 'Heitel')?[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Server Url ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Server Url - (eg: http://<servername>.com)","outlined":"","color":"secondary","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.serverUrlError,"error":_vm.serverUrlError.length > 0,"data-test-id":"server-url-heitel-input"},model:{value:(_vm.heitelData.url),callback:function ($$v) {_vm.$set(_vm.heitelData, "url", $$v)},expression:"heitelData.url"}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Username ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Username","outlined":"","color":"secondary","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.usernameError,"error":_vm.usernameError.length > 0,"data-test-id":"username-heitel-input"},model:{value:(_vm.heitelData.username),callback:function ($$v) {_vm.$set(_vm.heitelData, "username", $$v)},expression:"heitelData.username"}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Password ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Password","outlined":"","type":"password","color":"secondary","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.passwordError,"error":_vm.passwordError.length > 0,"data-test-id":"password-heitel-input"},model:{value:(_vm.heitelData.password),callback:function ($$v) {_vm.$set(_vm.heitelData, "password", $$v)},expression:"heitelData.password"}})],1)],1)]:_vm._e(),(_vm.isLiveStreamEnabled)?_c('v-row',{staticClass:"d-flex flex-row justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[(_vm.hasValidLiveStream)?_c('v-btn',{staticStyle:{"border-radius":"25px"},attrs:{"depressed":"","color":"secondary black--text"},on:{"click":function($event){return _vm.onClickRouteToLiveCamera()}}},[_c('span',{staticStyle:{"width":"10px","height":"10px","border-radius":"50px","margin-right":"5px","background-color":"var(--v-error-base)"}}),_c('p',{staticClass:"mb-0",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Open Live ")])]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        })},[_vm._v(" Turn on/off live stream ")]),_c('div',[_c('v-switch',{staticClass:"notification-switch pt-1",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"id":"alarm-ftp-notification-switch","text":"","color":"secondary","inset":"","disabled":!_vm.isWriteEnabled,"data-test-id":"live-stream-switch"},on:{"change":_vm.onToggleLiveStreamStatus},model:{value:(_vm.isLiveStreamEnabled),callback:function ($$v) {_vm.isLiveStreamEnabled=$$v},expression:"isLiveStreamEnabled"}})],1)])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }