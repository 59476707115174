var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[_c('div',{staticClass:"d-flex justify-left align-center pl-2"},[_c('Button',{class:['back-button', _vm.getBtnStyle()],attrs:{"btnStyle":'default pl-1'},on:{"onButtonClick":function($event){return _vm.goBack()}}},[_c('span',{staticClass:"v-btn__content"},[_c('v-icon',[_vm._v("mdi-menu-left")]),_c('div',{staticClass:"text-body-2",style:({ color: _vm.isDarkModeToggleEnabled ? 'white' : 'black' })},[_vm._v(" Back ")])],1)])],1),_c('v-row',{staticClass:"pl-2"},[_c('v-col',{staticClass:"pr-1 pb-6",attrs:{"cols":"7"}},[(_vm.isTypeIncident)?_c('SimpleGalleryElement',{attrs:{"mediaPath":_vm.selectedHealthCheck.processedFile.path,"mediaType":_vm.selectedHealthCheck.mediaType,"isThumbnail":false}}):_vm._e(),(!_vm.isTypeIncident)?_c('HealthCheckLiveStreamPlayer',{attrs:{"selectedHealthCheck":_vm.selectedHealthCheck,"liveStreamQuality":_vm.liveStreamQuality}}):_vm._e(),(!_vm.isTypeIncident)?_c('p',{staticClass:"text-left pt-4",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_c('span',{staticClass:"bold-high-text"},[_vm._v("Live Stream: ")]),_c('span',[_vm._v(_vm._s(_vm.selectedHealthCheck.healthCheck.liveStream.url))])]):_vm._e(),_c('div',{staticClass:"d-flex justify-left align-center pt-2"},[_c('p',{staticClass:"mb-0 pr-3 bold-high-text",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Status: ")]),_vm._l((_vm.selectedHealthCheck.healthCheck.deviation),function(deviation){return _c('v-btn',{key:deviation,staticClass:"py-0 px-2 mr-1",staticStyle:{"text-transform":"none","color":"#ffffff","height":"25px","font-size":"12px","box-shadow":"none"},style:(`background-color: ${
            deviation === 'No Deviation' ? '#4dd053' : '#ff5252'
          }`)},[_vm._v(" "+_vm._s(deviation)+" ")])})],2),(_vm.selectedHealthCheck.healthCheck.reportMsg !== '')?_c('div',[_c('p',{staticClass:"text-left bold-high-text mb-0 mt-3",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Comment ")]),_c('div',{staticClass:"px-2 py-2",staticStyle:{"border":"1px solid #c4c4c4 !important","border-radius":"5px"}},[_c('p',{staticClass:"text-left mb-0",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" "+_vm._s(_vm.selectedHealthCheck.healthCheck.reportMsg)+" ")])])]):_vm._e()],1),_c('v-col',{staticClass:"pl-1 pr-5",attrs:{"cols":"5"}},[_c('v-img',{attrs:{"src":_vm.referenceImageUrl,"lazy-src":require("../../../public/img/lazy-load-frame.svg"),"alt":"todo-image-incident-health-check"}}),(_vm.referenceImageUrl)?_c('p',{staticClass:"text-left bold-high-text mb-0 py-3",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" Reference image ")]):_vm._e(),_c('p',{staticClass:"text-left mb-0",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_c('span',{staticClass:"bold-med-text"},[_vm._v("Check datetime:")]),_c('span',{staticClass:"text-detail-grey"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(_vm.selectedHealthCheck.healthCheck.createdAt)))])]),_c('p',{staticClass:"text-left mb-0",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_c('span',{staticClass:"bold-med-text",staticStyle:{"display":"inline"}},[_vm._v("Location:")]),_c('span',{staticClass:"text-detail-grey"},[_c('HealthCheckBreadCrumb',{attrs:{"selectedHealthCheck":_vm.selectedHealthCheck,"configLevel":_vm.configLevel}})],1)]),_c('p',{staticClass:"text-left mb-0",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_c('span',{staticClass:"bold-med-text"},[_vm._v("Camera ID:")]),_c('span',{staticClass:"text-detail-grey"},[_vm._v(" "+_vm._s(_vm.cameraId)+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }