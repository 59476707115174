var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"container"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"reference",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"text-body-1 font-weight-bold",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v("Reference picture")])]),(_vm.currCameraRef && !!_vm.referenceImageUrl)?_c('v-row',{staticClass:"mr-1",attrs:{"justify":"center"}},[_c('v-img',{staticClass:"reference-image",staticStyle:{"width":"100%"},attrs:{"src":_vm.referenceImageUrl,"lazy-src":require("../../../public/img/lazy-load-frame.svg"),"alt":"reference-image-live-health-check"},on:{"load":_vm.onRefImageLoad}})],1):_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[(_vm.isRefImgLoading)?_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_c('v-col',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","variant":"outlined"}},[_vm._v(" No reference image ")])],1)],1)],1),_c('v-col',{staticClass:"incident",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"text-body-1 font-weight-bold",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v("Live Stream")])]),_c('v-row',{staticClass:"ml-1",staticStyle:{"height":"100%","width":"100%","max-height":"500px"},attrs:{"justify":"center"}},[_c('LiveStreamPlayer',{staticClass:"reference-image",staticStyle:{"width":"100%"},attrs:{"cameraId":_vm.currentHcDueCam.id,"cameraConfig":_vm.currentHcDueCam,"isSiteHardwareDevice":_vm.isSiteHardwareDevice}})],1)],1)],1),_c('v-row',{staticClass:"pt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('span',{staticClass:"text-body-1 font-weight-bold",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v("Report options")])]),_c('v-row',{staticClass:"pt-2"},[_c('v-card',{style:(_vm.adminCardStyle),attrs:{"width":"100%","outlined":""}},[_c('v-row',{staticClass:"pl-5 pr-5 pt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',_vm._l((_vm.deviationOptions),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex justify-start py-2 px-1"},[_c('input',{style:({
                            borderColor: !_vm.isDecisionEnabled
                              ? 'grey'
                              : '#ffd42a'
                          }),attrs:{"type":"checkbox","disabled":!_vm.isDecisionEnabled},domProps:{"checked":_vm.isTicked(item)},on:{"change":function($event){return _vm.handleFeedback(item)}}}),_c('span',{staticClass:"px-2",style:({
                            color: !_vm.isDecisionEnabled
                              ? 'grey'
                              : _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.lightPrimaryColor
                              : _vm.getColors.darkBlackColor
                          }),on:{"click":function($event){return _vm.handleFeedback(item)}}},[_vm._v(_vm._s(item))])])])],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-textarea',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"dense":"","prepend-inner-icon":_vm.comment != '' ? '' : 'mdi-pencil',"outlined":"","placeholder":"Add a comment","disabled":_vm.selectedFeedback.length === 0},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-row',{staticClass:"ml-5 mr-5 mb-5",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('button',{class:'key key-custom-spacebar key-spacebar',staticStyle:{"width":"100px"},attrs:{"disabled":_vm.selectedFeedback.length === 0},on:{"click":_vm.onSendCameraHealthComment}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)])],1)],1)],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close","btnStyle":"outlined"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }