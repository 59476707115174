<template>
  <div class="alert-container">
    <p>{{ `${message} ${message && '-'} ${status}` }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import EventBus from '@/utils/EventBus'

@Component
export default class Timer extends Vue {
  @Prop() public countDownStartValue!: number
  @Prop() public status!: string

  private countDown: number = 0
  private message: string = ''

  private mounted() {
    this.countDown = this.countDownStartValue
  }

  @Watch('countDownStartValue')
  private watchCountDownStartValue() {
    this.countDown = this.countDownStartValue
  }

  @Watch('countDown', { immediate: true })
  private(value: number): void {
    if (value > 0) {
      setTimeout(() => {
        this.countDown--
        if (this.countDown <= 0) {
          this.message = 'Timeout'
          EventBus.$emit('sandBoxCounterTimeOut')
        } else {
          this.message = 'Timeout in ' + this.countDown + 's'
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.alert-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}
</style>
