import { adminApi } from '@/services/backendServices/signedRequest'

export const getCompleteTreeViewNodes = async (params: {
  [key: string]: any
}) => {
  return await adminApi.request({
    method: 'GET',
    url: `/nodes`,
    params
  })
}

export const getTreeViewSiteAndCameraNodes = async (params: {
  [key: string]: any
}) => {
  return await adminApi.request({
    method: 'GET',
    url: `/nodes/pagination`,
    params
  })
}
