<template>
  <div>
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left pl-4 py-4"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="alarm-definitions-title"
      >
        Alarm Definitions
      </div>

      <div class="text-subtitle-2 notification-title pl-4">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
          data-test-id="person-detection-label"
        >
          Person Detection<v-icon
            class="menu-icon"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            mdi-walk
          </v-icon>
        </div>
        <v-switch
          id="person-detection-switch"
          v-model="isPersonDetectionEnabled"
          :disabled="
            isPersonDetectionSwitchDisabled ||
            isToggleDisabled ||
            !isWriteEnabled
          "
          text
          inset
          class="notification-switch"
          color="secondary"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          data-test-id="person-detection-switch"
        ></v-switch>
      </div>

      <div class="text-subtitle-2 notification-title pl-4">
        <div
          style="display: inline-flex; align-items: center"
          class="text-subtitle-2"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
          data-test-id="vehicle-movement-label"
        >
          Vehicle/Moving Vehicle detection
          <div class="ml-2">
            <div
              class="menu-icon"
              v-if="isUserAdmin && isDarkModeToggleEnabled"
            >
              <img :src="'/img/vehicle_movement_white.svg'" alt="Icon" />
            </div>
            <div class="menu-icon" v-else>
              <img :src="'/img/vehicle_movement _black.svg'" alt="Icon" />
            </div>
            <v-icon
              class="menu-icon"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-slash-forward </v-icon
            ><v-icon
              class="menu-icon"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              mdi-car-hatchback
            </v-icon>
          </div>
        </div>
        <v-switch
          id="vehicle-detection-switch"
          v-model="isVehicleDetectionEnabled"
          :disabled="
            isVehicleDetectionSwitchDisabled ||
            isToggleDisabled ||
            !isWriteEnabled
          "
          text
          inset
          class="notification-switch"
          color="secondary"
          @change="onVehicleAlarmDefinitionChange"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          data-test-id="vehicle-detection-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

enum AlarmDefinitionType {
  PERSON = 'person',
  PERSON_VEHICLE = 'person_vehicle'
}

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({})
export default class AlarmDefinitionConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onChange: (value: string, isChanged: boolean) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() isAlarmFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public alarmDefinitionConfig: AlarmDefinitionType = AlarmDefinitionType.PERSON
  public isPersonDetectionEnabled: boolean = true
  public isVehicleDetectionEnabled: boolean = true
  public isPersonDetectionSwitchDisabled: boolean = true
  public isVehicleDetectionSwitchDisabled: boolean = false

  public originalAdData: any = null

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isAlarmFieldEnabled === null
      ? this.configuration?.blockToggles?.alarmSettings !== true
      : this.isAlarmFieldEnabled !== true && this.level !== 'client'
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('isAlarmFieldEnabled')
  private watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.alarmSettings
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      this.alarmDefinitionConfig = showingConfig.alarmDefinitionConfig
      if (this.alarmDefinitionConfig === undefined) {
        this.isVehicleDetectionEnabled = true
      }

      if (this.alarmDefinitionConfig === AlarmDefinitionType.PERSON_VEHICLE) {
        this.isVehicleDetectionEnabled = true
      } else if (this.alarmDefinitionConfig === AlarmDefinitionType.PERSON) {
        this.isVehicleDetectionEnabled = false
      }

      this.originalAdData = {
        alarmDefinitionConfig:
          this.alarmDefinitionConfig || AlarmDefinitionType.PERSON_VEHICLE
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.alarmDefinitionConfig !== this.originalAdData.alarmDefinitionConfig
    )
  }

  public onVehicleAlarmDefinitionChange(value: boolean) {
    if (value) {
      this.alarmDefinitionConfig = AlarmDefinitionType.PERSON_VEHICLE
    } else {
      this.alarmDefinitionConfig = AlarmDefinitionType.PERSON
    }
    this.onChange(this.alarmDefinitionConfig, this.hasSwitchChanged())
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
</style>
