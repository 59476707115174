<template>
  <div>
    <Header :route="path" v-if="!isUserWorker" />
    <div style="display: flex; align-items: center">
      <!-- Left side text -->
      <div
        style="
          flex: 1;
          padding-left: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          height: 80vh;
        "
      >
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor,
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: '20px'
          }"
        >
          Oops! This page could not be found.
        </p>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor,
            textAlign: 'left'
          }"
        >
          Sorry! The page you are looking for does not exist, have been removed,
          name changed or is temporarily unavailable.Please try again later.
        </p>
        <!-- Buttons -->
        <div style="margin-top: 20px">
          <button
            :class="
              isUserAdmin && isDarkModeToggleEnabled
                ? 'refresh-dark-mode'
                : 'refresh-light-mode'
            "
            @click="refresh"
          >
            <v-icon
              size="22"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              >mdi-refresh
            </v-icon>
            <!-- Refresh icon -->
            Refresh
          </button>
          <button
            :class="'homepage-light-mode'"
            @click="goHome"
            style="margin-left: 20px"
          >
            <v-icon
              size="22"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              >mdi-web
            </v-icon>
            <!-- Home icon -->
            Homepage
          </button>
        </div>
      </div>
      <!-- Right side SVG -->
      <div style="width: 55%; padding-left: 4%">
        <img
          v-if="isUserAdmin && isDarkModeToggleEnabled"
          src="../../../public/img/404_dark.svg"
          alt="404 SVG"
          style="max-width: 100%; height: auto"
        />
        <img
          v-else
          src="../../../public/img/404_light.svg"
          alt="404 SVG"
          style="max-width: 100%; height: auto"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const namespaceUser = { namespace: 'user' }
import Header from '../../components/app/Header.vue'

const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Header
  }
})
export default class NotFound extends Vue {
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  public get isUserWorker() {
    return this.currentUser?.role === 'Worker'
  }
  //refresh page
  public refresh() {
    window.location.reload()
  }
  //go home
  public goHome() {
    this.$router.replace('/')
  }

  //calculate the path of the current route to show the header
  public get path() {
    let path = this.$route.name.toLowerCase()
    if (path === 'usermanagement') {
      path = 'userManagement'
    }
    //set the path to the alarms as default if the route is invalied.
    if (
      path !== 'sandbox' &&
      path !== 'alarms' &&
      path !== 'live' &&
      path !== 'maps' &&
      path !== 'statistics' &&
      path !== 'configurations' &&
      path !== 'userManagement' &&
      path !== 'workerspace'
    ) {
      path = 'alarms'
    }
    return path
  }
}
</script>

<style scoped>
.refresh-light-mode {
  color: black;
  border-radius: 3px;
  border: 2px solid #ffd42a;
  background: #fff;
  padding: 5px 16px; /* Adjust padding as needed */
}

.refresh-dark-mode {
  color: white;
  border-radius: 3px;
  border: 2px solid #ffd42a;
  background: #333333;
  padding: 5px 16px; /* Adjust padding as needed */
}

.homepage-light-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.image {
  padding: 0%;
}
</style>
