var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"card-style block-header d-flex justify-between align-center pb-0 pt-0",class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
      ? 'block-header-admin'
      : 'block-header'},[_c('v-col',{class:_vm.titleOnly ? 'py-5' : '',attrs:{"cols":"6"}},[_c('p',{staticStyle:{"text-align":"left","margin":"0px"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlackColor
      }),attrs:{"data-test-id":"block-header-title"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticClass:"text-subtitle-1-disabled font-weight-bold",style:({ color: '#c4c4c4' })},[_vm._v(" "+_vm._s(_vm.isPromiseQubeCameraInfo ? _vm.edgeDeviceStatus : ''))])])]),(!_vm.titleOnly)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-end align-center"},[(!_vm.isPromiseQubeCameraInfo)?_c('v-tooltip',{attrs:{"left":"","open-on-click":false,"open-on-focus":false,"nudge-left":"6","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"data-test-id":"block-header-tooltip","size":"15"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant-circle ")])]}}],null,false,455218872)},[_c('span',{staticClass:"mx-2 white--text",attrs:{"data-test-id":"block-header-tooltip-text"}},[_vm._v("ON: activate these configs"),_c('br'),_vm._v(" OFF: activate upper level configs")])]):_vm._e(),_c('v-switch',{staticClass:"pl-2",attrs:{"disabled":!_vm.isWriteEnabled || _vm.isToggleDisabled,"color":"secondary","inset":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"small":true,"data-test-id":"block-header-switch"},on:{"change":_vm.onSettingsChange},model:{value:(_vm.isBlockToggleEnabled),callback:function ($$v) {_vm.isBlockToggleEnabled=$$v},expression:"isBlockToggleEnabled"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }