<template>
  <div class="card-style">
    <div class="recording-toggle">
      <div class="font-weight-bold text-left recording-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          Recordings
          <span
            class="text-subtitle-1-disabled font-weight-bold"
            :style="{ color: '#c4c4c4' }"
          >
            {{ isEdgeDeviceRecordingEnabled ? 'on' : 'off' }}
          </span>
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isEdgeDeviceRecordingEnabled"
            text
            class="recording-switch pt-1"
            color="secondary"
            inset
            @change="onToggleEdgeRecordingStatus"
            :disabled="!isWriteEnabled || isToggleDisabled"
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component
export default class EdgeDeviceRecording extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Prop()
  configuration: any
  @Prop() isWriteEnabled!: boolean
  @Prop() onRecordingSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() isEdgeRecordingsFieldEnabled!: boolean
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: null }) siteConfig!: any
  @Prop() isLimitExceeded!: boolean

  public isEdgeDeviceRecordingEnabled: boolean = false
  public originalEdgeDeviceData: any = null

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  public get isToggleDisabled() {
    return this.isEdgeRecordingsFieldEnabled === null
      ? this.configuration?.blockToggles?.recordings !== true
      : this.isEdgeRecordingsFieldEnabled !== true && this.level !== 'site'
  }
  public mounted() {
    this.watchConfiguration()
  }

  public hasEdgeRecordingSettingChanged(): boolean {
    return (
      this.isEdgeDeviceRecordingEnabled !==
      this.originalEdgeDeviceData.isEdgeDeviceRecordingEnabled
    )
  }

  public onToggleEdgeRecordingStatus(value: boolean) {
    this.onRecordingSwitchChange(value, this.hasEdgeRecordingSettingChanged())
  }

  @Watch('isLimitExceeded')
  public watchRecordingLimitExceeded() {
    if (this.isLimitExceeded) {
      this.isEdgeDeviceRecordingEnabled =
        this.configuration?.isEdgeDeviceRecordingEnabled ?? false
    }
  }

  @Watch('configuration')
  @Watch('siteConfig')
  @Watch('isEdgeRecordingsFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any
    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'camera') {
        if (!this.configuration?.blockToggles?.recordings) {
          showingConfig = this.siteConfig
        } else {
          showingConfig = this.configuration
        }
      }
    }
    if (showingConfig) {
      this.isEdgeDeviceRecordingEnabled =
        showingConfig?.isEdgeDeviceRecordingEnabled ?? false
    }
    this.originalEdgeDeviceData = {
      isEdgeDeviceRecordingEnabled: this.isEdgeDeviceRecordingEnabled
    }
  }
}
</script>

<style scoped>
.card-style {
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
}
.recording-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.recording-switch {
  float: left;
  padding-left: 15px;
}
</style>
