<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="genetec-expand-title"
      >
        Genetec
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="genetec-expand-icon"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div class="pl-4 pb-2" v-if="isExpanded">
      <div
        style="display: flex; flex-direction: row; font-size: 13px"
        class="pr-3"
      >
        <v-col cols="12" style="flex: 1">
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :disabled="!isWriteEnabled"
              v-model="serviceUrl"
              label="Service Url - (eg: https://127.0.0.1:4590/WebSdk)"
              outlined
              :error-messages="serviceUrlError"
              :error="serviceUrlError.length > 0"
              data-test-id="genetec-service-url-input"
            />
          </v-row>
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :disabled="!isWriteEnabled"
              v-model="applicationId"
              label="Application Id"
              outlined
              :error-messages="appIdError"
              :error="appIdError.length > 0"
              data-test-id="genetec-application-id-input"
            />
          </v-row>
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :disabled="!isWriteEnabled"
              v-model="username"
              label="Username"
              outlined
              :error-messages="usernameError"
              :error="usernameError.length > 0"
              data-test-id="genetec-username-input"
            />
          </v-row>
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :disabled="!isWriteEnabled"
              v-model="password"
              label="Password"
              outlined
              :error-messages="passwordError"
              :error="passwordError.length > 0"
              data-test-id="genetec-password-input"
            />
          </v-row>
        </v-col>
      </div>

      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            class="text-left pt-5 pb-3 my-2 mt-2"
            data-test-id="genetec-acknowledge-title"
          >
            Automatic Event Acknowledgement
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isAcknowledgementEnabled"
            :disabled="!isWriteEnabled"
            text
            class="notification-switch"
            color="secondary"
            inset
            @change="onClickGenetecAcknowledgeEnabledStatus"
            data-test-id="genetec-acknowledge-switch"
          ></v-switch>
        </div>
      </div>
      <div class="font-weight-bold text-left notification-title pr-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="genetec-switch-title"
        >
          Turn on/off Genetec
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isGenetecEnabled"
            :disabled="!isWriteEnabled"
            text
            class="toggle-switch pt-2"
            color="secondary"
            inset
            @change="onClickGenetecEnabled"
            data-test-id="genetec-switch"
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class GenetecConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChangeAcknowledgementStatus: (
    status: boolean,
    isChanged: boolean
  ) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: false }) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isGenetecEnabled: boolean = false
  public serviceUrl = ''
  public applicationId = ''
  public username = ''
  public password = ''
  public isAcknowledgementEnabled = false

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public originalGenetectData: any = null

  public serviceUrlError: string = ''
  public appIdError: string = ''
  public usernameError: string = ''
  public passwordError: string = ''

  public isExpanded: boolean = false

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      const genetecData = this.configuration.genetecData
      this.isGenetecEnabled = this.configuration.isGenetecEnabled ?? false
      this.isExpanded = this.isGenetecEnabled
      this.serviceUrl = genetecData ? genetecData['serviceUrl'] : ''
      this.applicationId = genetecData ? genetecData['applicationId'] : ''
      this.username = genetecData ? genetecData['username'] : ''
      this.password = genetecData ? genetecData['password'] : ''
      this.isAcknowledgementEnabled = genetecData
        ? genetecData['isAcknowledgementEnabled']
        : ''

      this.originalGenetectData = {
        isGenetecEnabled: this.isGenetecEnabled,
        isAcknowledgementEnabled: this.isAcknowledgementEnabled,
        serviceUrl: this.serviceUrl,
        applicationId: this.applicationId,
        username: this.username,
        password: this.password
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return this.isGenetecEnabled !== this.originalGenetectData.isGenetecEnabled
  }

  public hasAcknowledgeSwitchChanged(): boolean {
    return (
      this.isAcknowledgementEnabled !==
      this.originalGenetectData.isAcknowledgementEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.serviceUrl?.trim() !== this.originalGenetectData.serviceUrl ||
      this.applicationId?.trim() !== this.originalGenetectData.applicationId ||
      this.username?.trim() !== this.originalGenetectData.username ||
      this.password?.trim() !== this.originalGenetectData.password ||
      this.isAcknowledgementEnabled !==
        this.originalGenetectData.isAcknowledgementEnabled ||
      this.isGenetecEnabled !== this.originalGenetectData.isGenetecEnabled
    )
  }

  public onClickGenetecEnabled(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public onClickGenetecAcknowledgeEnabledStatus(value: boolean) {
    this.onChangeAcknowledgementStatus(
      value,
      this.hasAcknowledgeSwitchChanged()
    )
    this.isAcknowledgementEnabled = value
  }

  @Watch('serviceUrl')
  @Watch('applicationId')
  @Watch('username')
  @Watch('password')
  @Watch('isAcknowledgementEnabled')
  @Watch('isGenetecEnabled')
  public onClickSaveGenetecData() {
    let isError: boolean = false
    this.serviceUrlError = ''
    this.appIdError = ''
    this.usernameError = ''
    this.passwordError = ''

    if (this.serviceUrl == '') {
      isError = true
      this.serviceUrlError = 'Service Url is required'
    }

    if (this.applicationId == '') {
      isError = true
      this.appIdError = 'Application Id is required'
    }

    if (this.username == '') {
      isError = true
      this.usernameError = 'Username is required'
    }

    if (this.password == '') {
      isError = true
      this.passwordError = 'Password is required'
    }

    this.onChange({
      serviceUrl: this.serviceUrl?.trim(),
      applicationId: this.applicationId?.trim(),
      username: this.username?.trim(),
      password: this.password?.trim(),
      isAcknowledgementEnabled: this.isAcknowledgementEnabled,
      isError,
      isChanged: isError
        ? false
        : this.hasDataChanged() || this.hasSwitchChanged()
    })
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
