<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="hikvision-hcp-expand-title"
      >
        HikVision HCP
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="hikvision-hcp-expand-icon"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div class="pl-4 pb-2" v-if="isExpanded">
      <div
        style="display: flex; flex-direction: row; font-size: 13px"
        class="pr-3"
      >
        <v-col cols="12" style="flex: 1">
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="serviceUrl"
              label="Service Url - (eg: https://127.0.0.1:4443)"
              :disabled="!isWriteEnabled"
              outlined
              data-test-id="hikvision-hcp-service-url-input"
            />
          </v-row>
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="appKey"
              label="Partner App Key"
              :disabled="!isWriteEnabled"
              outlined
              data-test-id="hikvision-hcp-app-key-input"
            />
          </v-row>
          <v-row>
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="appSecret"
              :disabled="!isWriteEnabled"
              label="Partner App Secret"
              outlined
              data-test-id="hikvision-hcp-app-secret-input"
            />
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" md="4" sm="6" class="px-0 pt-0">
              <v-select
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                v-model="hikCentralVersion"
                :items="['1.7.0', '2.2.1 or Above']"
                label="HikVision HCP Version"
                outlined
                hide-details="true"
                :disabled="!isWriteEnabled"
                data-test-id="hikvision-hcp-version-select"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            class="text-left pt-5 pb-3 my-2 mt-2"
            data-test-id="hikvision-hcp-acknowledge-title"
          >
            Automatic Event Acknowledgement
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isAcknowledgementEnabled"
            :disabled="!isWriteEnabled"
            text
            class="notification-switch"
            color="secondary"
            inset
            @change="onClickHikCentralAcknowledgeEnabledStatus"
            data-test-id="hikvision-hcp-acknowledge-switch"
          ></v-switch>
        </div>
      </div>
      <div class="font-weight-bold text-left notification-title pr-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="hikvision-hcp-notification-title"
        >
          Turn on/off Hikvision HCP
        </div>
        <v-switch
          id="alarm-hikvision-notification-switch"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          v-model="isHikCentralEnabled"
          :disabled="!isWriteEnabled"
          text
          class="notification-switch"
          color="secondary"
          inset
          @change="onClickHikCentralEnabled"
          data-test-id="hikvision-hcp-notification-switch"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class HikVisionConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() onSwitchChange: (
    status: boolean,
    data: any,
    isChanged: boolean
  ) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: false }) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isHikCentralEnabled: boolean = false
  public serviceUrl = ''
  public appKey = ''
  public appSecret = ''
  public hikCentralVersion = ''
  public isAcknowledgementEnabled = false

  public originalHikvisionData: any = null

  public isExpanded: boolean = false

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      const hikCentralData = this.configuration.hikCentralData
      this.isHikCentralEnabled = this.configuration.isHikCentralEnabled ?? false
      this.isExpanded = this.isHikCentralEnabled
      this.isAcknowledgementEnabled = hikCentralData
        ? hikCentralData['isAcknowledgementEnabled']
        : false
      this.serviceUrl = hikCentralData ? hikCentralData['serviceUrl'] : ''
      this.appKey = hikCentralData ? hikCentralData['appKey'] : ''
      this.appSecret = hikCentralData ? hikCentralData['appSecret'] : ''
      this.hikCentralVersion = hikCentralData
        ? hikCentralData['hikCentralVersion'] || '2.2.1 or Above'
        : '2.2.1 or Above'

      this.originalHikvisionData = {
        isHikCentralEnabled: this.isHikCentralEnabled,
        isAcknowledgementEnabled: this.isAcknowledgementEnabled,
        serviceUrl: this.serviceUrl,
        appKey: this.appKey,
        appSecret: this.appSecret,
        hikCentralVersion: this.hikCentralVersion
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isHikCentralEnabled !==
      this.originalHikvisionData.isHikCentralEnabled
    )
  }

  public hasAcknowledgeSwitchChanged(): boolean {
    return (
      this.isAcknowledgementEnabled !==
      this.originalHikvisionData.isAcknowledgementEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.serviceUrl?.trim() !== this.originalHikvisionData.serviceUrl ||
      this.appKey?.trim() !== this.originalHikvisionData.appKey ||
      this.appSecret?.trim() !== this.originalHikvisionData.appSecret ||
      this.hikCentralVersion !== this.originalHikvisionData.hikCentralVersion ||
      this.isAcknowledgementEnabled !==
        this.originalHikvisionData.isAcknowledgementEnabled ||
      this.isHikCentralEnabled !==
        this.originalHikvisionData.isHikCentralEnabled
    )
  }

  public onClickHikCentralEnabled(value: boolean) {
    this.onSwitchChange(
      value,
      {
        serviceUrl: this.serviceUrl,
        appKey: this.appKey,
        appSecret: this.appSecret,
        hikCentralVersion: this.hikCentralVersion,
        isAcknowledgementEnabled: this.isAcknowledgementEnabled
      },
      this.hasSwitchChanged()
    )
  }

  public onClickHikCentralAcknowledgeEnabledStatus(value: boolean) {
    this.isAcknowledgementEnabled = value
  }

  @Watch('serviceUrl')
  @Watch('appKey')
  @Watch('appSecret')
  @Watch('hikCentralVersion')
  @Watch('isAcknowledgementEnabled')
  @Watch('isHikCentralEnabled')
  public onClickSaveHikCentralData() {
    this.onChange({
      serviceUrl: this.serviceUrl?.trim(),
      appKey: this.appKey?.trim(),
      appSecret: this.appSecret?.trim(),
      hikCentralVersion: this.hikCentralVersion,
      isAcknowledgementEnabled: this.isAcknowledgementEnabled,
      isChanged: this.hasDataChanged()
    })
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
