export const getDefaultState = () => {
  return {
    status: [] // Will be bound as an array
  }
}

const state = getDefaultState()

export default {
  ...state
}
