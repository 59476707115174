<template>
  <div>
    <v-dialog max-width="600px" text-xs-right v-model="isDialog" persistent>
      <v-card
        style="padding: 10px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor,
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
      >
        <v-form
          @submit.prevent="saveUser"
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <v-toolbar class="secondary elevation-0" dark>
            <v-toolbar-title
              style="color: black"
              data-test-id="create-new-user-label"
            >
              Add New
              {{ isUserCustomer ? 'Collaborator' : 'Customer' }}
            </v-toolbar-title>
          </v-toolbar>
          <v-layout v-if="error">
            <v-flex>
              <app-alert
                :text="error.message"
                @dismissed="onDismissed"
              ></app-alert>
            </v-flex>
          </v-layout>
          <v-card-text class="text-center">
            <div>
              Please fill the required information to add this
              {{ isUserCustomer ? 'collaborator' : 'customer' }}.
            </div>
            <v-container grid-list-md pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    :rules="emailRule"
                    label="Email*"
                    required
                    prepend-icon="mdi-email"
                    v-model="email"
                    :data-ci-new-user-email="true"
                    :dark="
                      isUserAdmin && isDarkModeToggleEnabled ? true : false
                    "
                    data-test-id="create-user-email-input"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex sm6 xs12>
                  <v-text-field
                    :rules="nameRule"
                    :counter="20"
                    label="First Name*"
                    required
                    prepend-icon="mdi-account"
                    v-model="firstName"
                    :data-ci-new-user-first-name="true"
                    :dark="
                      isUserAdmin && isDarkModeToggleEnabled ? true : false
                    "
                    data-test-id="create-user-first-name-input"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex sm6 xs12>
                  <v-text-field
                    :rules="nameRule"
                    :counter="20"
                    prepend-icon="mdi-account"
                    label="Last Name*"
                    required
                    v-model="lastName"
                    :data-ci-new-user-last-name="true"
                    :dark="
                      isUserAdmin && isDarkModeToggleEnabled ? true : false
                    "
                    data-test-id="create-user-last-name-input"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>* indicates required field</small>
          </v-card-text>

          <v-card-actions class="d-flex justify-space-between">
            <Button
              @onButtonClick="cancelNewUser"
              btnStyle="outlined"
              :disabled="isLoading"
              :data-ci-new-user-close-button="true"
              class="text-subtitle-2 close-btn"
              btnText="Close"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : 'black'
              }"
              data-test-id="create-user-cancel-button"
            />
            <Button
              type="submit"
              color="black--text elevation-0"
              :disabled="!isValid"
              :loading="isLoading"
              :data-ci-new-user-save-button="true"
              class="text-subtitle-2"
              btnText="Add"
              data-test-id="create-user-save-button"
            />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import { generateEmailContent } from '../../../utils/emailTemplateUtils'
import { mailCollection } from '@/provider/firebase/index'
import { sendEmailForCloudFunction } from '@/provider/firebase/user'
import { AUTH_DOMAIN } from '@/utils/EnvMapping'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class AddNewUserDialog extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: '' }) userRole!: string
  @Prop({ required: true }) handleCreateUser: (payload: {
    firstName: string
    lastName: string
    email: string
    role: string
  }) => Promise<any>

  @Getter('currentUser', namespaceUser)
  public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getBreadcrumbList', namespaceConfig) public breadcrumbList
  @Action('searchUserByEmail', namespaceUser) public searchUserByEmail!: any

  public isValid: boolean = false
  public isDialog: boolean = true
  public isLoading: boolean = false
  public userRoles = []
  public hasFormReset: boolean = false
  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public isNewUser: boolean = false
  public coloboratorDetails: any = {}
  public userId: string = ''

  public setUserRoles() {
    if (this.userRole === 'Customer') {
      this.userRoles = ['Customer']
    } else if (this.userRole === 'Administrator') {
      this.userRoles = ['Administrator']
    } else {
      this.userRoles = ['Worker']
    }
    return this.userRoles
  }

  public get onUserRoleChange(): string {
    this.userRoles = this.setUserRoles()
    return this.userRole
  }

  get error() {
    return this.$store.getters.error
  }

  get fullPathUrl() {
    if (this.coloboratorDetails) {
      this.id = this.coloboratorDetails[0].id
      const baseUrl = `https://${AUTH_DOMAIN}`
      let path = this.$route.path.replace(/^\/+/, '')

      //replace the current user id with added user id/'
      path = path.replace(/(user-management\/)[^/]+/, `$1${this.id}`)

      return `${baseUrl}/${path}`
    }
    return null
  }
  get getBreadcrumbList() {
    let adjustedBreadcrumbList = [...this.breadcrumbList]

    if (this.isUserAdmin) {
      // Remove the first item from the list if the user is an admin
      adjustedBreadcrumbList.shift()
    }

    return adjustedBreadcrumbList.map((item) => item.text).join(' > ')
  }
  public emailRule = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
  ]
  public nameRule = [
    (v) => !!v || 'Name is required',
    (v) => (v && v.length <= 20) || 'Name must be less than 20 characters',
    (v) =>
      /^[a-zA-Z0-9äöüÄÖÜß ]+$/.test(v) ||
      'Name should not contain special characters',
    (v) => (v && v.trim() != '') || 'Name cannot be only whitespaces'
  ]

  public roleRule = [(v) => !!v || 'The input is required']

  public firstName = ''
  public lastName = ''
  public email = ''
  public id = ''
  public role = 'Customer'

  @Ref('form') public createForm: { validate: () => any; reset: () => void }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  mounted(): void {
    this.$store.dispatch('clearError')
    this.hasFormReset = true
    this.role = this.onUserRoleChange
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public async saveUser(): Promise<any> {
    this.userId = this.$route.params.userId ?? ''
    this.coloboratorDetails = await this.searchUserByEmail({
      email: this.email
    })
    if (this.coloboratorDetails.length > 0) {
      if (this.coloboratorDetails[0].id == this.userId) {
        this.$store.commit('setError', {
          message: 'You cannot add yourself as a collaborator'
        })
      }
    }
    if (this.coloboratorDetails.length > 0) {
      this.isNewUser = false
    } else {
      this.isNewUser = true
    }
    if (this.createForm.validate()) {
      this.isLoading = true
      let first = ''
      let last = ''
      if (!this.isNewUser) {
        first = this.coloboratorDetails[0].firstName
        last = this.coloboratorDetails[0].lastName
      } else {
        first = this.firstName.trim()
        last = this.lastName.trim()
      }
      this.handleCreateUser({
        firstName: first,
        lastName: last,
        email: this.email,
        role: 'Customer'
      })
        .then(async () => {
          this.isLoading = false
          if (!this.error) {
            this.$emit('save')
            this.isDialog = false
          }
          if (!this.error) {
            this.$emit('update-success-dialog', true)
            this.sendUserEmail(this.email)
            this.popSnackbarMessage('User added successfully')
            this.$emit('close')
          }
          this.createForm.reset()
          this.hasFormReset = true
          this.$emit('close')
        })
        .catch((error) => {
          this.isLoading = false
          this.popSnackbarMessage('Unable to add user')
        })
    }
  }

  async sendUserEmail(email: string) {
    try {
      if (this.isNewUser) {
        await sendEmailForCloudFunction({
          email: email,
          functionName: 'sendCustomPasswordResetEmail',
          newCollaborator: true
        })
      } else {
        const firstName = this.coloboratorDetails[0].firstName
        const lastName = this.coloboratorDetails[0].last_name
        const breadcrumbList = this.getBreadcrumbList
        const path = this.fullPathUrl
        const emailContent = await generateEmailContent(
          'addExistingCollaborator',
          {
            firstName,
            lastName,
            breadcrumbList,
            path
          }
        )
        const payload = {
          to: email,
          message: {
            subject: emailContent.subject,
            html: emailContent.html
          }
        }
        // Add the email payload to the Firestore collection used by the Firebase Extension
        await mailCollection.add(payload)

        return { status: 'success', message: 'email sent' }
      }
    } catch (error: any) {
      console.error('Error sending email:', error)
    }
  }

  public onDismissed() {
    this.$store.dispatch('clearError')
  }
  public cancelNewUser() {
    this.$emit('close')
    this.isDialog = false
    this.createForm.reset()
    this.$store.dispatch('clearError')
  }
}
</script>

<style scoped>
.close-btn:hover {
  background-color: transparent !important;
}
</style>
