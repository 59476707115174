<template>
  <div>
    <div
      v-if="filteredAlarmsAccumulatedOverTimeStatus !== 'Ready'"
      class="loading-spinner"
    >
      <div v-if="filteredAlarmsAccumulatedOverTimeStatus === 'Error'">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is an error while loading this chart
        </p>
      </div>
      <div v-if="filteredAlarmsAccumulatedOverTimeStatus === 'Loading'">
        <v-progress-circular indeterminate color="secondary" />
      </div>
    </div>
    <div
      v-if="filteredAlarmsAccumulatedOverTimeStatus === 'Ready'"
      class="my-2 pa-4"
    >
      <div v-if="filteredAlarmsAccumulatedOverTimeData.length > 1">
        <GChart
          key="reduction-chart"
          type="AreaChart"
          :data="modifiedChartData"
          :options="reductionChartOptions"
        />
      </div>
      <div v-else>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is not enough data to show this chart
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { reductionChartOptions } from '../../utils/Charts'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceStatistics = { namespace: 'statistics' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    GChart
  }
})
export default class ReductionChart extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getFiLabel', namespaceStatistics) public xAxisLabel: string
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getFilteredAlarmsAccumulatedOverTimeData', namespaceStatistics)
  public filteredAlarmsAccumulatedOverTimeData: any[]
  @Getter('getFilteredAlarmsAccumulatedOverTimeStatus', namespaceStatistics)
  public filteredAlarmsAccumulatedOverTimeStatus: string

  @Action('fetchFilteredAlarmsAccumulatedOverTimeData', namespaceStatistics)
  fetchFilteredAlarmsAccumulatedOverTimeData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('$route.params')
  public fetchChartData = debounce(async () => {
    await this.fetchFilteredAlarmsAccumulatedOverTimeData({
      startTime: this.startTime,
      endTime: this.endTime,
      ...this.$route.params
    })
  }, 300)

  public get modifiedChartData(): any[] {
    return [
      ['Time per 3 hours', 'No-Alarm'],
      ...this.filteredAlarmsAccumulatedOverTimeData
    ]
  }

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors?.lightPrimaryColor
      : this.getColors?.darkBlackColor
  }

  public get reductionChartOptions(): any {
    return reductionChartOptions({
      hAxisTitle: this.xAxisLabel,
      vAxisTitle: 'Filtered Alarms',
      textColors: this.textColors
    })
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
