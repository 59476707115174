import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#333333',
        secondary: '#ffd42a',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#C62828',
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a',
        void: '#ffffff',
        low: '#e1dede',
        card: '#f5f5f5'
      },
      dark: {
        primary: '#333333',
        secondary: '#ffd42a',
        tertiary: '#495057',
        accent: '#726A95',
        error: '#f55a4e',
        info: '#1597BB',
        success: '#4ECB71',
        warning: '#FEC260',
        void: '#ffffff',
        card: '#f5f5f5'
      }
    }
  }
})

// export default new Vuetify({
//   theme: {
//     options: {
//       customProperties: true
//     },
//     dark: false,
//     themes: {
//       light: {
//         primary: '#333333',
//         secondary: '#ffd42a',
//         tertiary: '#E6EEFF',
//         accent: '#82B1FF',
//         error: '#C62828',
//         info: '#00d3ee',
//         success: '#5cb860',
//         warning: '#ffa21a',
//         void: '#ffffff',
//         card: '#f5f5f5'
//       },
//       dark: {
//         primary: '#c4c4c4',
//         secondary: '#ffd42a',
//         tertiary: '#495057',
//         accent: '#726A95',
//         error: '#f55a4e',
//         info: '#1597BB',
//         success: '#4ECB71',
//         warning: '#FEC260',
//         void: '#ffffff',
//         card: '#f5f5f5'
//       }
//     }
//   }
// })
