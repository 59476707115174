var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.facet.data.length)?_c('v-container',{staticClass:"d-flex flex-column py-0 pl-0",attrs:{"fluid":""}},[_c('v-autocomplete',{attrs:{"items":_vm.getItems(_vm.facet.data),"outlined":"","item-color":"white","background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? _vm.getColors.darkSectionColor : '',"chips":"","label":_vm.facetName,"placeholder":_vm.facetName,"small-chips":"","multiple":"","solo":"","text-color":"white","color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkLowFrameColor
        : ''},on:{"change":function($event){return _vm.setFilter($event)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkFrameColor
              : '',
          position: 'absolute',
          top: '0px'
        }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected},on:{"click":data.select}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(_vm.getDisplayValue(data.item))+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item',_vm._g(_vm._b({style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkFrameColor
              : ''
        })},'v-list-item',data.attrs,false),data.on),[_c('div',{staticClass:"d-flex flex-row justify-start"},[_c('input',{staticClass:"checkbox-disabled",attrs:{"type":"checkbox","id":"checkbox","disabled":true},domProps:{"checked":_vm.isChecked(data.item)},on:{"click":function($event){return _vm.toggleSelection(data.item)}}}),_c('span',{staticClass:"px-4",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : '#000'
            })},[_vm._v(_vm._s(_vm.getDisplayValue(data.item)))])])])]}}],null,false,871458678),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }