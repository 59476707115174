<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="evalink-configuration-expand-bar"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="evalink-configuration-expand-title"
      >
        Evalink <span v-if="notificationType === 'HC'">(HC)</span>
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>
    <div class="pl-4 pb-2" v-if="isExpanded">
      <div style="display: flex; flex-direction: row; font-size: 13px">
        <div style="flex: 1">
          <h3
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            data-test-id="evalink-configuration-static-device-id-title"
            class="text-left pt-5 pb-3 my-2 mt-2"
          >
            Override Device from Static Device Id
          </h3>
        </div>
        <div class="mt-2 mr-1">
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="isEvalinkDeviceOverrideEnabled"
            text
            class="notification-switch"
            color="secondary"
            inset
            @change="onClickIsEvalinkDeviceOverrideEnabled"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-override-enabled-switch"
          ></v-switch>
        </div>
      </div>
      <div
        style="display: flex; flex-direction: row; font-size: 13px"
        class="pr-3"
      >
        <div style="flex: 1">
          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkAuthorization"
            label="Authorization Key"
            outlined
            color="secondary"
            :error-messages="evalinkAuthorizationError"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-authorization-input"
          ></v-text-field>

          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkCompanyId"
            label="Company Id"
            outlined
            color="secondary"
            :error-messages="evalinkCompanyIdError"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-company-id-input"
          ></v-text-field>
          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkDeviceId"
            label="Device Id"
            outlined
            color="secondary"
            :error-messages="evalinkDeviceIdError"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-device-id-input"
          ></v-text-field>
          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkPartition"
            label="Partition (Optional)"
            outlined
            color="secondary"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-partition-input"
          ></v-text-field>
          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkPartitionName"
            label="Partition Name (Optional)"
            outlined
            color="secondary"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-partition-name-input"
          ></v-text-field>
          <v-text-field
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="evalinkAlarmZoneName"
            label="Alarm Zone Name (Optional)"
            outlined
            color="secondary"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="evalink-config-alarm-zone-name-input"
          ></v-text-field>
        </div>
      </div>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="evalink-config-onoff-lable"
        >
          Turn on/off evalink notifications
        </div>

        <v-switch
          id="alarm-evalink-notification-switch"
          v-model="isEvalinkEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          @change="onClickIsEvalinkEnabled"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          data-test-id="evalink-config-notifiction-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class EvalinkNotificationConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChangeDeviceOverrideEnabled: (
    status: boolean,
    isChanged: boolean
  ) => void
  @Prop() onChange: (value: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public isEvalinkEnabled: boolean = false
  public isEvalinkDeviceOverrideEnabled: boolean = false
  public evalinkAuthorization = ''
  public evalinkCompanyId = ''
  public evalinkDeviceId = ''
  public evalinkPartition = ''
  public evalinkPartitionName = ''
  public evalinkAlarmZoneName = ''

  public evalinkAuthorizationError: string = ''
  public evalinkCompanyIdError: string = ''
  public evalinkDeviceIdError: string = ''

  public evalinkOriginalData: any = null

  public isNotificationDataInherited: boolean = true

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true && this.level !== 'client'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  get healthCheckNotification() {
    return this.configuration?.notificationData?.healthCheck
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('healthCheckNotification')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      let evalinkData = {}
      if (this.notificationType === 'HC') {
        evalinkData = showingConfig?.notificationData?.healthCheck?.evalinkData
        this.isEvalinkEnabled =
          showingConfig?.notificationData?.healthCheck?.isEvalinkEnabled
      } else {
        evalinkData = showingConfig.evalinkData
        this.isEvalinkEnabled = showingConfig.isEvalinkEnabled
      }

      this.isExpanded = this.isEvalinkEnabled
      this.evalinkAuthorization = evalinkData
        ? evalinkData['Authorization']
        : ''
      this.evalinkCompanyId = evalinkData ? evalinkData['companyId'] ?? '' : ''
      this.evalinkDeviceId = evalinkData ? evalinkData['deviceId'] ?? '' : ''
      this.evalinkPartition = evalinkData ? evalinkData['partition'] ?? '' : ''
      this.evalinkPartitionName = evalinkData
        ? evalinkData['partitionName'] ?? ''
        : ''
      this.evalinkAlarmZoneName = evalinkData
        ? evalinkData['alarmZoneName'] ?? ''
        : ''
      this.isEvalinkDeviceOverrideEnabled = evalinkData
        ? evalinkData['isDeviceOverrideEnabled'] ?? false
        : false

      this.evalinkOriginalData = {
        isEvalinkEnabled: this.isEvalinkEnabled,
        evalinkAuthorization: this.evalinkAuthorization,
        evalinkCompanyId: this.evalinkCompanyId,
        evalinkDeviceId: this.evalinkDeviceId,
        evalinkPartition: this.evalinkPartition,
        evalinkPartitionName: this.evalinkPartitionName,
        evalinkAlarmZoneName: this.evalinkAlarmZoneName,
        isEvalinkDeviceOverrideEnabled: this.isEvalinkDeviceOverrideEnabled
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isEvalinkEnabled !==
      (this.evalinkOriginalData.isEvalinkEnabled || false)
    )
  }

  public hasOverrideSwitchChanged(): boolean {
    return (
      this.isEvalinkDeviceOverrideEnabled !==
      (this.evalinkOriginalData.isEvalinkDeviceOverrideEnabled || false)
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.evalinkAuthorization?.trim() !==
        this.evalinkOriginalData.evalinkAuthorization ||
      this.evalinkCompanyId?.trim() !==
        this.evalinkOriginalData.evalinkCompanyId ||
      this.evalinkDeviceId?.trim() !==
        this.evalinkOriginalData.evalinkDeviceId ||
      this.evalinkPartition?.trim() !==
        this.evalinkOriginalData.evalinkPartition ||
      this.evalinkPartitionName?.trim() !==
        this.evalinkOriginalData.evalinkPartitionName ||
      this.evalinkAlarmZoneName?.trim() !==
        this.evalinkOriginalData.evalinkAlarmZoneName
    )
  }

  public onClickIsEvalinkEnabled(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  public onClickIsEvalinkDeviceOverrideEnabled(value: boolean) {
    this.onChangeDeviceOverrideEnabled(value, this.hasOverrideSwitchChanged())
  }

  @Watch('evalinkAuthorization')
  @Watch('evalinkCompanyId')
  @Watch('evalinkDeviceId')
  @Watch('evalinkPartition')
  @Watch('evalinkPartitionName')
  @Watch('evalinkAlarmZoneName')
  @Watch('isEvalinkEnabled')
  public onClickSaveEvalinkConfig() {
    let isError: boolean = false
    this.evalinkAuthorizationError = ''
    this.evalinkDeviceIdError = ''
    this.evalinkCompanyIdError = ''

    // check required fields are available or not
    if (this.evalinkAuthorization == '') {
      isError = true
      this.evalinkAuthorizationError = 'Evalink Authorization key is required'
    }

    if (this.evalinkCompanyId === '') {
      isError = true
      this.evalinkCompanyIdError = 'Evalink Company id is required'
    }

    if (this.isEvalinkDeviceOverrideEnabled && this.evalinkDeviceId === '') {
      isError = true
      this.evalinkDeviceIdError = 'Evalink Device id is required'
    }

    if (this.evalinkDeviceId.length !== 32) {
      isError = true
      this.evalinkDeviceIdError =
        'Evalink Device id must contain 32 characters.'
    }

    const evalinkData = {
      Authorization: this.evalinkAuthorization?.trim(),
      alarmZoneName: this.evalinkAlarmZoneName?.trim(),
      companyId: this.evalinkCompanyId?.trim(),
      deviceId: this.evalinkDeviceId?.trim(),
      partition: this.evalinkPartition?.trim(),
      partitionName: this.evalinkPartitionName?.trim(),
      isDeviceOverrideEnabled: this.isEvalinkDeviceOverrideEnabled,
      isError,
      isChanged: isError ? false : this.hasDataChanged()
    }
    this.onChange(evalinkData)
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
