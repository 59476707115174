// Define an enum for DeviceType
export enum DeviceType {
  ScreenTooSmall = 'Screen Too Small',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Laptop = 'Laptop',
  Desktop = 'Desktop',
  LargeScreen = 'Large Screen'
}

// Define an enum for ScreenOrientation
export enum ScreenOrientation {
  Portrait = 'Portrait',
  Landscape = 'Landscape'
}

// Define an enum for ScreenSize
enum ScreenSize {
  MobilePortraitMin = 320,
  MobilePortraitMax = 480,
  MobileLandscapeMax = 640,
  TabletPortraitMax = 700,
  TabletLandscapeMax = 1200,
  LaptopLandscapeMax = 1440,
  DesktopMax = 1920
}

// Function to get device type
export default function getDeviceType(width: number): {
  device: DeviceType
  orientation: ScreenOrientation
} {
  if (width < ScreenSize.MobilePortraitMin) {
    return {
      orientation: ScreenOrientation.Portrait,
      device: DeviceType.ScreenTooSmall
    }
  }

  const orientations: { [key: string]: ScreenSize[] } = {
    [ScreenOrientation.Portrait]: [
      ScreenSize.MobilePortraitMax,
      ScreenSize.TabletPortraitMax
    ],
    [ScreenOrientation.Landscape]: [
      ScreenSize.MobileLandscapeMax,
      ScreenSize.TabletLandscapeMax,
      ScreenSize.LaptopLandscapeMax,
      ScreenSize.DesktopMax
    ]
  }

  const isPortrait = orientations[ScreenOrientation.Portrait].some(
    (breakpoint) => width <= breakpoint
  )
  const isLandscape = orientations[ScreenOrientation.Landscape].some(
    (breakpoint) => width <= breakpoint
  )

  let device: DeviceType
  let orientation: ScreenOrientation

  if (isPortrait) {
    orientation = ScreenOrientation.Portrait
    device =
      width <= ScreenSize.MobilePortraitMax
        ? DeviceType.Mobile
        : DeviceType.Tablet
  } else if (isLandscape) {
    orientation = ScreenOrientation.Landscape
    device =
      width <= ScreenSize.MobileLandscapeMax
        ? DeviceType.Mobile
        : width <= ScreenSize.TabletLandscapeMax
        ? DeviceType.Tablet
        : width <= ScreenSize.LaptopLandscapeMax
        ? DeviceType.Laptop
        : DeviceType.Desktop
  } else {
    // Unexpected width, return large screen
    orientation = ScreenOrientation.Landscape
    device = DeviceType.LargeScreen
  }

  return { orientation, device }
}

export const isCompactView = (): boolean => {
  const { device } = getDeviceType(window.innerWidth)

  // Only return true if the device type is ScreenTooSmall (less than 320px)
  // or Mobile, Tablet, or Laptop AND the width is less than 700px.
  if (
    device === DeviceType.ScreenTooSmall ||
    device === DeviceType.Mobile ||
    device === DeviceType.Tablet ||
    device === DeviceType.Laptop
  ) {
    return window.innerWidth < 700
  }

  return false
}
