var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-style"},[_c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        })},[_vm._v(" Firmware version ")])]),_c('div',{staticClass:"text-field",style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
        border:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? '1px solid #c4c4c4'
            : 'none',
        padding: '10px',
        borderRadius: '4px',
        position: 'relative',
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_c('span',[_vm._v(_vm._s(_vm.edgeVersion))]),_c('div',{staticClass:"version-update-container"},[(_vm.isPromiseQubeUpdateAvailable)?_c('div',{staticClass:"version-update-info"},[_c('span',{staticClass:"new-version-label",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlack
            })},[_vm._v("New version is available!")]),_c('v-badge',{staticClass:"top-right-badge",attrs:{"dot":"","color":"red"}})],1):_vm._e()])])]),_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        })},[_vm._v(" IP ")])]),_c('div',{staticClass:"text-field",style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
        border:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? '1px solid #c4c4c4'
            : 'none',
        padding: '10px',
        borderRadius: '4px',
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_vm._v(" "+_vm._s(_vm.ipAddress)+" ")])]),_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        })},[_vm._v(" MAC Address ")])]),_c('div',{staticClass:"text-field",style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'none' : '#f5f5f5',
        border:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? '1px solid #c4c4c4'
            : 'none',
        padding: '10px',
        borderRadius: '4px',
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_vm._v(" "+_vm._s(_vm.macAddress)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }