import { getDefaultState } from './state'

const mutations = {
  /**
   * Sets loginUser from currentUser
   * @param state
   * @param payload
   */
  setLoginUser(state, payload): void {
    if (payload !== undefined && payload !== null) {
      const UserModel = payload
      if (UserModel !== null && payload.created !== undefined) {
        UserModel.createdAt = payload.created.toDate().toLocaleString('de-DE')
      }
      state.loginUser = UserModel
    }
  },
  setCurrentUser(state: { currentUser: {} }, payload: any): void {
    state.currentUser = { ...payload }
  },
  setCreatedUser(state: { createdUser: any }, payload: any): void {
    state.createdUser = { ...payload }
  },
  setSelectedUser(state: { selectedUser: any }, payload: any): void {
    state.selectedUser = payload
  },
  setPermissionUser(state: { permissionUser: any }, payload: any): void {
    state.permissionUser = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setAllUsersIsBind(state: { allUsersIsBind: any }, payload: any): void {
    state.allUsersIsBind = payload
  },
  setColors: (state, colors: string[]) => {
    state.colors = colors
  },
  setCompanyName(state, { userId, companyName, isCurrentUser }) {
    const user = state.allUser.find((user) => user.id === userId)
    if (user) {
      user.companyName = companyName
      if (isCurrentUser) {
        state.currentUser = { ...state.currentUser, companyName }
        console.log('Updated currentUser company name in Vuex state.')
      }
    }
  },
  setEagleEyeAccounts: (state, payload) => {
    state.eagleEyeAccounts = payload
  },
  removeEagleEyeAccount: (state, vms_account: string) => {
    state.eagleEyeAccounts = state.eagleEyeAccounts.filter(
      (account: { vms_account: string }) => account.vms_account !== vms_account
    )
  },
  setIsInitialUser(state, payload) {
    state.isInitialUser = payload
  },
  setIsLoadingUsers(state, payload) {
    state.isLoadingUsers = payload
  },
  setEagleEyeConnect(
    state: {
      eagleEyeConnect: {
        progress: boolean
        message: string
      }
    },
    payload: {
      progress: boolean
      message: string
    }
  ): void {
    state.eagleEyeConnect = payload
  },
  setGlobalCamera(state: { globalCameras: any }, payload: any): void {
    state.globalCameras = payload
  },
  setGlobalLiveIsLoading(
    state: { globalLiveIsLoading: any },
    payload: any
  ): void {
    state.globalLiveIsLoading = payload
  }
}

export default {
  ...mutations
}
