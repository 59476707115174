import { getDefaultState, ICameraState } from './state'
const mutations = {
  setCameraConfig(state: ICameraState, payload: any): void {
    state.cameraConfig = payload
  },
  setReferenceImageUrl(state: ICameraState, payload: string): void {
    state.referenceImageUrl = payload
  },
  resetState(state: ICameraState): void {
    Object.assign(state, getDefaultState())
  },
  setHeitelImage(state: ICameraState, payload) {
    state.heitelImage = payload
  },
  setLiveStreamError(state: ICameraState, payload) {
    state.liveStreamError = payload
  },
  setHcDueCameras(state: ICameraState, payload) {
    state.hcDueCameras = payload
  },
  setCameraLocationList(state: ICameraState, payload) {
    state.cameraLocationList = payload
  },
  setCameraUsers(state: ICameraState, payload: any[]): void {
    state.cameraUsers = payload
  },
  setCameraDocId(state: ICameraState, payload: string): void {
    state.cameraDocId = payload
  },
  setHealthCheckType(state: ICameraState, payload: string): void {
    state.healthCheckType = payload
  },
  setPreDeleteReferenceImage(state: ICameraState, payload: boolean): void {
    state.preDeleteReferenceImage = payload
  },
  setCameraStatus(state: ICameraState, payload: boolean): void {
    state.cameraStatus = payload
  }
  // TODO: Use this when cameraId is not unique issue is resolved
  // async addToCameraList(
  //   state: {
  //     cameraList: Set<{
  //       cameraId: string
  //       excludedZones: any[]
  //     }>
  //   },
  //   payload: {
  //     cameraId: string
  //     excludedZones: any[]
  //   }[]
  // ): Promise<void> {
  //   console.log('addToCameraList', payload)
  //   // add each camera to the Set
  //   await Promise.all(
  //     payload.map((camera) => {
  //       state.cameraList.add(camera)
  //       console.log(state.cameraList)
  //     })
  //   )
  // }
}

export default {
  ...mutations
}
