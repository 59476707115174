var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.isOptionalHcOn ? 'gallery-element-health-check' : 'gallery-element'},[_c('div',[_c('div',{class:_vm.isOptionalHcOn
          ? 'gallery-container-health-check'
          : 'gallery-container'},[_c('div',{staticClass:"gallery-element-container",attrs:{"id":"gallery-element-container"}},[(!_vm.isTodoLoaded)?_c('v-progress-circular',{staticClass:"loader",attrs:{"indeterminate":"","color":"amber"}}):_vm._e(),(_vm.mediaUrl !== null)?_c('div',{staticClass:"carousel"},[_c('div',{class:_vm.isOptionalHcOn
                ? 'img-magnifier-container gallery-element-wrapper-health-check'
                : 'img-magnifier-container gallery-element-wrapper',on:{"mousewheel":_vm.changeZoomLevel,"click":_vm.changeZoomLevel}},[_c('div',{style:(_vm.isMagnifierEnabled
                  ? 'visibility : visible'
                  : 'visibility : hidden'),attrs:{"id":"img-magnifier-glass"}},[_vm._v(" "+_vm._s(_vm.currentZoomLevel)+"x ")]),_c('img',{class:_vm.isOptionalHcOn
                  ? 'image-wrapper-health-check'
                  : 'image-wrapper',attrs:{"id":"todo-image","contain":true,"src":_vm.currentImage,"alt":"todo-image"},on:{"load":_vm.handleImageLoad}})]),_c('div',{class:_vm.isOptionalHcOn
                ? 'progress-bar-wrapper-health-check'
                : 'progress-bar-wrapper'},[_c('WorkerTodoTimer',{attrs:{"timeStamp":_vm.timeStamp}}),_c('div',{staticClass:"progress-bar"}),(_vm.todosByCameraId.length == 2)?_c('div',{staticClass:"dividers divider",style:({ border: _vm.borderColor })}):_vm._e(),(_vm.todosByCameraId.length == 3)?_c('div',{staticClass:"dividers divider-1",style:({ border: _vm.borderColor1 })}):_vm._e(),(_vm.todosByCameraId.length == 3)?_c('div',{staticClass:"dividers divider-2",style:({ border: _vm.borderColor2 })}):_vm._e(),_c('div',{staticClass:"moving-progress-bar",style:({ width: _vm.width + '%' })})],1)]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }