import { functions } from '@/provider/firebase'
import { store } from '@/store'

const actions = {
  setNotificationStatus({ commit }, notificationStatus: boolean) {
    store.commit('notifications/setNotificationStatus', notificationStatus)
  },
  async initializeLiveCallParameters(
    { commit }: any,
    data: {
      cameraDocId: string
      clientDocId: string
      incidentId: string
      siteDocId: string
      url: string
      contactNumber: string
      incidentObservationLink: string
      message: string
    }
  ): Promise<any> {
    const fetchLiveCallParameters = functions.httpsCallable(
      'fetchLiveCallParameters'
    )
    const result = await fetchLiveCallParameters(data)
    return result
  }
}

export default {
  ...actions
}
