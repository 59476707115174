import firebase from 'firebase/compat/app'
import {
  functions,
  siteCollection,
  cameraCollection
} from '@/provider/firebase'
import { convertAddressToLocation } from '@/services/geocodingService'

export default {
  namespaced: true,
  async updateSiteConfig(payload: any) {
    try {
      const { docId, ...updatedData } = payload
      const site = await siteCollection.doc(docId).get()
      const siteAddress = site.data().address

      if (!site.exists) {
        return {
          status: 'error',
          message: 'Something went wrong or site does not exist!'
        }
      } else {
        const siteData = site.data()
        const checkSiteName = await siteCollection
          .where('name', '==', payload.name)
          .where('client', '==', siteData.client)
          .get()

        const isSiteNameExist =
          checkSiteName.docs.length > 1 ||
          (checkSiteName.docs.length === 1 &&
            checkSiteName.docs[0]?.id !== docId)

        if (isSiteNameExist) {
          return {
            status: 'error',
            message: 'Same site name exists in the same client!'
          }
        } else {
          const _ = require('lodash')
          if (
            !_.isEqual(
              siteAddress,
              updatedData.address && updatedData.address !== null
            )
          ) {
            const tomTomData = await convertAddressToLocation(
              updatedData.address
            )
            const { results } = tomTomData
            if (results && results.length > 0) {
              const { position } = results[0]
              const { lat, lon } = position
              await site.ref.update({
                ...updatedData,
                location: { lat: lat, lng: lon }
              })
            } else {
              await site.ref.update(updatedData)
            }
            await this.removeCameraLocations(site.ref)
          } else {
            await site.ref.update(updatedData)
          }

          return {
            status: 'success',
            message: 'Site configuration updated successfully!'
          }
        }
      }
    } catch (error) {
      return {
        status: 'error',
        message: error as string
      }
    }
  },
  async updateCameraLocations(
    siteRef: any,
    location: { lat: number; lon: number }
  ) {
    try {
      const camerasQuerySnapshot = await cameraCollection
        .where('site', '==', siteRef)
        .get()
      const updatePromises = camerasQuerySnapshot.docs.map(async (doc) => {
        const cameraRef = doc.ref
        await cameraRef.update({
          location: { lat: location.lat, lng: location.lon }
        })
      })
      await Promise.all(updatePromises)
    } catch (error) {
      console.error('Error updating camera locations:', error)
    }
  },
  async removeCameraLocations(siteRef: any) {
    try {
      const camerasQuerySnapshot = await cameraCollection
        .where('site', '==', siteRef)
        .get()
      const updatePromises = camerasQuerySnapshot.docs.map(async (doc) => {
        const cameraRef = doc.ref
        await cameraRef.update({
          location: firebase.firestore.FieldValue.delete()
        })
      })
      await Promise.all(updatePromises)
    } catch (error) {
      console.error('Error removing camera locations:', error)
    }
  },
  async generateSiaReceiver(uid: string, data: any) {
    try {
      return functions.httpsCallable('generateSiaReceiver')({
        siteId: uid,
        data
      })
    } catch (error) {
      console.error('Error generating sia receiver', error)
    }
  },
  async deleteSiaIntegration(uid: string, payload: any) {
    try {
      return siteCollection.doc(uid).update({
        siaReceivers: firebase.firestore.FieldValue.arrayRemove(payload)
      })
    } catch (error) {
      console.log('Error deleting sia integration')
    }
  }
}
