import { adminApi } from '@/services/backendServices/signedRequest'

export const getMetrics = async (params?: Record<string, string>) => {
  return await adminApi.request({
    method: 'GET',
    url: `/metrics`,
    params
  })
}

export const getStats = async (params?: Record<string, string>) => {
  return await adminApi.request({
    method: 'GET',
    url: `/stats`,
    params
  })
}
