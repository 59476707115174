<template>
  <div class="paging-info">
    Showing
    {{ start }} - {{ Math.min(end, searchState.totalResults) }} out of
    {{ searchState.totalResults }}
  </div>
</template>

<script>
export default {
  props: {
    searchState: {
      required: true,
      type: Object
    }
  },
  computed: {
    start() {
      return this.searchState.totalResults === 0
        ? 0
        : (this.searchState.current - 1) * this.searchState.resultsPerPage + 1
    },
    end() {
      return this.searchState.totalResults <= this.searchState.resultsPerPage
        ? this.searchState.totalResults
        : this.start + this.searchState.resultsPerPage - 1
    }
  }
}
</script>

<style>
.paging-info {
  color: grey !important;
  font-size: 14px;
}
</style>
