<template>
  <div
    v-if="isLiveStreamEnabled"
    class="small-card"
    style="height: 30vh; margin-bottom: 80px"
  >
    <nav>
      <span>
        <v-icon
          class="pr-lg-2 mx-2"
          :color="online ? 'rgb(255,82,82)' : 'rgb(128, 128, 128)'"
        >
          {{ online ? 'mdi-circle' : 'mdi-circle-outline' }}
        </v-icon>
        <span>Live Preview</span>
      </span>
      <v-icon v-if="expanded" class="pr-lg-2 close-button" @click="close"
        >mdi-arrow-collapse</v-icon
      >
      <v-icon v-else class="pr-lg-2">mdi-arrow-expand</v-icon>
    </nav>
    <LiveStreamPlayer
      :cameraId="cameraDocId"
      :cameraConfig="getCameraConfig"
      :isSiteHardwareDevice="isSiteHardwareDevice"
      @online="onOnline"
    />
  </div>
  <div v-else class="small-card" style="margin-bottom: 30px">
    <nav>
      <span>
        <span>There is No Live Preview Available</span>
      </span>
    </nav>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import LiveStreamPlayer from '../camera/player/LiveStreamPlayer.vue'

@Component({
  components: {
    LiveStreamPlayer
  }
})
export default class LiveView extends Vue {
  @Prop({ required: true }) isLiveStreamEnabled?: boolean
  @Prop({ required: false, default: false }) isSiteHardwareDevice?: boolean
  @Prop({ required: true }) liveStream?: any
  @Prop({ required: true }) cameraId: string
  @Prop({ required: true }) expanded!: boolean
  @Prop({ required: true }) cameraDocId: string

  public online: boolean = true

  public get getCameraConfig() {
    return {
      liveStream: this.liveStream,
      cameraId: this.cameraId,
      id: this.cameraDocId
    }
  }

  // listen for event from child component : online
  public onOnline(online: boolean) {
    this.online = online
  }

  public close() {
    this.$emit('close')
  }
}
</script>

<style scoped>
.small-card {
  display: flex;
  flex-direction: column;
}

.small-card nav {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  background-color: rgb(171, 171, 171);
  font-size: 14px;
  padding: 8px;
  top: 0;
  width: 100%;
}

.close-button {
  cursor: pointer;
}
</style>
