import moment from 'moment'
import { Timestamp } from 'firebase-admin/firestore'
import { cameraCollection, firestore } from '@/provider/firebase'

export function getCreatedAt(todo: any): Date {
  let date = new Date()
  try {
    date = new Date(todo.deadline.toDate())
  } catch (error) {
    console.log(error)
  }
  return date
}

export function getDeadline(todo: any): Date {
  let date = new Date()
  try {
    date = new Date(todo.deadline.toDate())
  } catch (error) {
    console.log(error)
  }
  return date
}

export function getCountDown(todo: any) {
  const now = new Date()
  const convertedDeadline = getDeadline(todo)

  // May be a breaking change
  // return Math.floor((convertedDeadline - now) / 1000)
  return Math.floor((+convertedDeadline - +now) / 1000)
}

export function mapToArray(obj: any) {
  if (obj) {
    return Object.entries(obj).map(([key, value]) => ({ key, value }))
  } else {
    return []
  }
}

export function arrayToMap(arr: { key: string; value: string | any }[]) {
  const obj = {}
  arr.map((p) => {
    obj[p.key] = p.value
  })
  return obj
}

export function isValidUrl(url: string) {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export function isValidJson(json: string) {
  try {
    JSON.parse(json)
  } catch (e) {
    return false
  }

  return true
}

export const getUnarmedTimeRange = (unarmedTimeRange: any) => {
  const unarmedStartTime = unarmedTimeRange?.startTime
  const unarmedEndTime = unarmedTimeRange?.endTime
  const unarmedApplicableDays = unarmedTimeRange?.applicableDays || []
  const selectedTimeZone = unarmedTimeRange?.selectedTimeZone
  const isAlwaysUnarmed = unarmedTimeRange?.isAlwaysUnarmed

  const unarmedTimeRangeExists =
    unarmedTimeRange !== undefined &&
    selectedTimeZone !== '' &&
    unarmedStartTime !== '' &&
    unarmedEndTime !== ''

  let isInAlarmTimeRange = false

  if (!isAlwaysUnarmed) {
    const today = moment().format('ddd')

    if (unarmedApplicableDays.includes(today)) {
      if (
        moment(unarmedEndTime, 'HH:mm').isAfter(
          moment(unarmedStartTime, 'HH:mm')
        )
      ) {
        isInAlarmTimeRange = !(
          unarmedTimeRangeExists &&
          moment(moment.utc().format('HH:mm'), 'HH:mm').isBetween(
            moment(unarmedStartTime, 'HH:mm'),
            moment(unarmedEndTime, 'HH:mm')
          )
        )
      } else {
        isInAlarmTimeRange = !(
          unarmedTimeRangeExists &&
          (moment(moment.utc().format('HH:mm'), 'HH:mm').isAfter(
            moment(unarmedStartTime, 'HH:mm')
          ) ||
            moment(moment.utc().format('HH:mm'), 'HH:mm').isBefore(
              moment(unarmedEndTime, 'HH:mm')
            ))
        )
      }
    } else {
      isInAlarmTimeRange = true
    }
  }

  return {
    isInAlarmTimeRange: isInAlarmTimeRange,
    unarmedTimeRangeExists: unarmedTimeRangeExists
  }
}

export const getNodeUnarmedStatus = (
  clientData: any,
  siteData?: any,
  cameraData?: any
) => {
  let unarmedTimeRange = {
    isInAlarmTimeRange: true,
    unarmedTimeRangeExists: false
  }

  if (clientData && clientData.unarmedTimeRange) {
    unarmedTimeRange = getUnarmedTimeRange(clientData?.unarmedTimeRange)
  }
  if (
    siteData &&
    siteData.blockToggles &&
    siteData.blockToggles.unarmedTimeRange
  ) {
    unarmedTimeRange = getUnarmedTimeRange(siteData?.unarmedTimeRange)
  }
  if (
    cameraData &&
    cameraData.blockToggles &&
    cameraData.blockToggles.unarmedTimeRange
  ) {
    unarmedTimeRange = getUnarmedTimeRange(cameraData?.unarmedTimeRange)
  }

  return unarmedTimeRange.isInAlarmTimeRange
}

export const getDateAndTime = (timestamp: any) => {
  if (!timestamp || typeof timestamp.seconds !== 'number') {
    // Handle the case where timestamp is undefined or doesn't have seconds property
    return { date: '', time: '' }
  }

  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  const date = new Date(milliseconds)
  const dateString = moment(date).format('DD/MM/YYYY')
  const timeString = moment(date).format('hh:mm:ss A')
  return { date: dateString, time: timeString }
}

export const getNodeActiveStatus = (
  lastPingAt: Timestamp | any,
  isHardwareDevice: boolean,
  isCamera?: boolean,
  isAuthenticated?: boolean
) => {
  //TODO(node): recheck and optimize this
  if (!isCamera && lastPingAt === undefined) return true
  const activeCondition = isCamera
    ? lastPingAt && isHardwareDevice && isAuthenticated
    : lastPingAt && isHardwareDevice
  if (activeCondition) {
    const THRESHOLD_TIME = 5000 // 5 seconds
    const PING_TIME_INTERVAL = 300000 // 5 minutes
    const MAXIMUM_TIME = THRESHOLD_TIME + PING_TIME_INTERVAL
    const milliseconds =
      lastPingAt._seconds * 1000 + lastPingAt._nanoseconds / 1000000

    const lastPingTime = milliseconds
    const newPingTime = new Date().getTime() + 1000 //add 1 sec padding to avoid clock skew
    const timeDiff = newPingTime - lastPingTime
    if (MAXIMUM_TIME >= timeDiff) {
      return true
    }
  }
  return false
}
export const swapElements = (array: any[], index1: number, index2: number) => {
  ;[array[index1], array[index2]] = [array[index2], array[index1]]
}
export async function checkRecordingLimit(
  sitePath: string,
  isEdgeDeviceRecordingEnabled: boolean
) {
  const siteRef = firestore.doc(sitePath)
  const siteSnapshot = await siteRef.get()
  const siteData = siteSnapshot.data()
  const siteActiveRecordingCamerasLimit =
    siteData?.edgeLimits?.activeRecordingCameraLimit ?? -1
  if (siteActiveRecordingCamerasLimit < 0) {
    return false
  } else {
    // Query cameras which have edge device recording enabled in the site
    const snapshot = await cameraCollection
      .where('site', '==', siteRef)
      .where('isEdgeDeviceRecordingEnabled', '==', true)
      .get()

    // Count the number of matching documents
    const recordingEnabledCamerasCount = snapshot.size
    console.log('recordingEnabledCamerasCount', recordingEnabledCamerasCount)

    // Check if turning on the recording exceeds the limit
    if (
      isEdgeDeviceRecordingEnabled &&
      recordingEnabledCamerasCount >= siteActiveRecordingCamerasLimit
    ) {
      return true
    }
    return false
  }
}
export async function checkArmedLimit(sitePath: string, value: boolean) {
  const siteRef = firestore.doc(sitePath)
  const siteSnapshot = await siteRef.get()
  const siteData = siteSnapshot.data()
  const siteArmedCamerasLimit = siteData?.edgeLimits?.armedCameraLimit ?? -1
  if (siteArmedCamerasLimit < 0) {
    return false
  } else {
    const siteCameraSnapshot = await cameraCollection
      .where('site', '==', siteRef)
      .where('isEdgeDeviceEnabled', '==', true)
      .get()
    const unarmedCameraSnapshot = await cameraCollection
      .where('site', '==', siteRef)
      .where('isEdgeCameraUnarmed', '==', true)
      .where('isEdgeDeviceEnabled', '==', true)
      .get()
    const armedCamerasCount =
      siteCameraSnapshot.size - unarmedCameraSnapshot.size

    // Check if turning on the recording exceeds the limit
    if (value && armedCamerasCount >= siteArmedCamerasLimit) {
      return true
    }
    return false
  }
}

export function isDisarmed(
  config: {
    blockToggles?: Record<string, boolean>
    unarmedTimeRange?: {
      isAlwaysUnarmed: boolean
      startTime?: string
      endTime?: string
      applicableDays?: string[]
      selectedTimeZone?: string
    }
  },
  parentDisarmed?: boolean
): boolean {
  const blockTogglesActive =
    config.blockToggles && config.blockToggles.unarmedTimeRange

  const { isInAlarmTimeRange } = getUnarmedTimeRange(config.unarmedTimeRange)
  if (blockTogglesActive) {
    return !isInAlarmTimeRange
  }
  return parentDisarmed
}
