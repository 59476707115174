<template>
  <div>
    <!-- Camera IDs which throws less Incidents (Last Ten) -->
    <GChart
      type="Sankey"
      :data="lastCameraIdData"
      :settings="sankeyChartSettings"
      :options="incidentCameraIdChartOptions"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    GChart
  }
})
export default class IncidentLastCameraChart extends Vue {
  @Prop() public lastCameraIdData: any[]
  @Getter('currentUser', namespaceUser) private currentUser: any

  public textColors: string = ''

  public mounted() {
    this.textColors =
      this.currentUser.role === 'Administrator' ? 'white' : 'black'

    this.incidentCameraIdChartOptions = {
      sankey: {
        iterations: 0,
        node: {
          interactivity: true,
          colors: ['#514F4B'],
          label: {
            fontName: 'Poppins',
            color: this.textColors,
            fontSize: 12
          }
        },
        link: {
          colorMode: 'gradient',
          colors: ['#514F4B', '#E4E4E5'],
          textStyle: {
            color: 'red'
          }
        }
      },
      tooltip: {
        textStyle: {
          fontName: 'Poppins',
          fontSize: 12
        }
      }
    }
  }

  public sankeyChartSettings = {
    packages: ['sankey']
  }

  public incidentCameraIdChartOptions: any = {}
}
</script>
