import { IClientState, getDefaultState } from './state'

interface ISetClientUsers {
  state: IClientState
  payload: any[]
}

const mutations = {
  setClientConfig(state: { clientConfig: any }, payload: any): void {
    state.clientConfig = payload
  },
  setClientCamera(state: { clientCameras: any }, payload: any): void {
    state.clientCameras = payload
  },
  setClientLiveIsLoading(
    state: { clientLiveIsLoading: any },
    payload: any
  ): void {
    state.clientLiveIsLoading = payload
  },
  setEagleEyeConnect(
    state: IClientState,
    payload: {
      progress: boolean
      message: string
    }
  ): void {
    state.eagleEyeConnect = payload
  },
  setClientUsers(state: IClientState, payload: any[]): void {
    state.clientUsers = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  }
}

export default {
  ...mutations
}
