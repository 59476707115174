var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:".v-container-tab-content",style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[_c('div',{staticClass:"chart-width",staticStyle:{"padding-bottom":"40px"}},[_c('v-row',{staticClass:"d-flex justify-space-between align-items-center justify-content-center"},[_c('v-col',[_c('SummaryData',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime,"userId":_vm.userId,"clientId":_vm.clientId,"siteId":_vm.siteId,"cameraId":_vm.cameraId}})],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between align-items-center justify-content-center mt-4 mx-3 custom-tab"},[(!_vm.userId && _vm.isUserAdmin)?_c('div',{staticClass:"chart-border pa-4",attrs:{"id":"incidents-per-customer-chart"}},[_c('div',{staticClass:"my-2 pa-4 chart-width"},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Incidents Per Customer")]),_c('IncidentsPerCustomerChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]):_vm._e(),_c('div',{staticClass:"pa-4 chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width"},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Incidents Over Time")]),_c('IncidentChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]),_c('div',{staticClass:"chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width",attrs:{"id":"sankey-chart"}},[_c('div',{staticClass:"my-2 pa-4 chart-width",attrs:{"id":"sankey-chart"}},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              })},[_vm._v("Incident Result Distribution")]),_c('SankeyChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])])]),_c('br'),_c('div',{staticClass:"pa-4 chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width"},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Result Distribution")]),_c('ResultDistributionChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]),_c('div',{staticClass:"chart-border pa-4"},[_c('div',{staticClass:"my-2 pa-4 chart-width"},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Number of Filtered Alarms Accumulated Over Time")]),_c('ReductionChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]),(!_vm.cameraId)?_c('div',{staticClass:"chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width",attrs:{"id":"camera-incidents-chart"}},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Number of Unique Camera Incidents Over Time")]),_c('UniqueCameraChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]):_vm._e(),(_vm.isUserAdmin)?_c('div',{staticClass:"chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width",attrs:{"id":"input-type-chart"}},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Incidents by Input Type")]),_c('InputTypeChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]):_vm._e(),(!_vm.cameraId)?_c('div',{staticClass:"chart-border"},[_c('div',{staticClass:"my-2 pa-4 chart-width",attrs:{"id":"incident-sankey-chart"}},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Camera IDs Which Throws Incidents")]),_c('IncidentCameraIdChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])]):_vm._e(),_c('div',{staticClass:"chart-border",attrs:{"id":"weekly-incidents-chart"}},[_c('div',{staticClass:"my-2 pa-4 chart-width"},[_c('div',[_c('span',{staticClass:"chart-header-style",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v("Incidents Per Week")]),_c('WeeklyIncidentChart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }