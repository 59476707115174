<template>
  <v-slide-y-transition>
    <v-system-bar v-if="show" height="30" color="primary">
      <span class="white--text">{{ message }}</span>
    </v-system-bar>
  </v-slide-y-transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TopSlider extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: 'Slide down message' }) message!: string
}
</script>

<style scoped>
.white--text {
  color: white;
}
</style>
