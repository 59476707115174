<template>
  <div
    class="live-view"
    v-bind:class="{
      dark: isDarkMode
    }"
  >
    <WHEPPlayer
      v-if="
        getStreamType === STREAM_TYPES.P2P &&
        getP2PPlayer.player === P2pPlayer.MTX
      "
      :playerType="getP2PPlayer?.playerType ?? playerType.IFRAME"
      :cameraDocId="this.cameraConfig.id"
      :referenceImagePath="cameraConfig?.referenceImage?.path"
    />
    <WebRtcPlayer
      v-if="getStreamType === STREAM_TYPES.PromiseQube"
      :cameraId="cameraId"
      :isAuthenticated="cameraConfig.isAuthenticated"
      :liveStreamQuality="liveStreamQuality"
      :referenceImage="cameraConfig.referenceImage"
      :seekEnabled="seekEnabled"
    />

    <EagleEyePlayer
      v-if="getStreamType === STREAM_TYPES.EagleEye"
      :cameraId="cameraId"
      :cameraConfig="cameraConfig"
    />

    <HeitelStreamPlayer
      v-if="getStreamType === STREAM_TYPES.Heitel"
      :cameraId="cameraId"
      :cameraConfig="cameraConfig"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import WebRtcPlayer from './WebRtcPlayer.vue'
import EagleEyePlayer from './EagleEyePlayer.vue'
import HeitelStreamPlayer from './HeitelStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'
import WHEPPlayer from '@/components/camera/player/WHEPPlayer/WHEPPlayer.vue'
import { Getter } from 'vuex-class'
import { P2pPlayer, P2pPlayerConfig, PlayerType } from '@/utils/Interfaces'

enum StreamTypes {
  EagleEye = 'EagleEye',
  PromiseQube = 'promiseQube',
  Heitel = 'Heitel',
  P2P = 'P2P'
}

const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    WHEPPlayer,
    WebRtcPlayer,
    EagleEyePlayer,
    HeitelStreamPlayer
  }
})
export default class LiveStreamPlayer extends Vue {
  @Prop({ required: true }) public cameraId: string
  @Prop({ required: true }) public cameraConfig: any
  @Prop({ required: false, default: false }) public isSiteHardwareDevice
  @Prop({ required: false, default: false }) public isDarkMode
  @Prop({ required: false, default: LiveStreamQualityType.HIGH })
  public liveStreamQuality
  @Prop({ required: false, default: false }) seekEnabled!: any
  public readonly STREAM_TYPES = StreamTypes
  public readonly P2pPlayer = P2pPlayer
  public readonly playerType = PlayerType

  @Getter('getP2PPlayer', namespaceConfig)
  public getP2PPlayer: P2pPlayerConfig

  public get getStreamType(): StreamTypes {
    if (this.isSiteHardwareDevice) {
      return StreamTypes.PromiseQube
    } else {
      const streamUrl = this.cameraConfig?.liveStream?.url
      const streamType = this.cameraConfig?.liveStream?.type
      if (streamType === 'Heitel') {
        return StreamTypes.Heitel
      } else if (
        streamUrl.includes('eagleeyenetworks') ||
        this.cameraConfig.integrationType === 'EagleEye'
      ) {
        return StreamTypes.EagleEye
      } else {
        return StreamTypes.P2P
      }
    }
  }
}
</script>

<style scoped>
.live-view {
  width: 100%;
  height: 100%;
  flex: 1;
}

.live-view.dark {
  background: #333333;
}

.player-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  line-height: 10px;
  margin-bottom: 10px;
  font-size: 10px;
}
</style>
