<template>
  <div class="card-style">
    <!-- <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        promiseQube Camera
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div> -->

    <div class="pr-3 pl-3 py-4 form">
      <v-row no-gutters class="mb-4">
        <v-col cols="5">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            Configuration Type
          </p>
        </v-col>
        <v-col cols="7">
          <v-select
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="edConfigType"
            label="Select Config Type"
            :items="['Auto', 'Manual']"
            outlined
            hide-details="true"
            :dense="true"
            :disabled="!isWriteEnabled"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <template v-if="edConfigType === 'Auto'">
        <!-- <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Cameras
            </p>
          </v-col>
          <v-col cols="7">
            <v-select
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="detectedCamera"
              label="Select Edge Device"
              :items="detectedCameras"
              item-text="ip"
              outlined
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
            >
              <template #selection="{ item, index }">
                <div
                  v-if="index === 0"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  }"
                >
                  {{ getEdgeDeviceIp(item).slice(0, 27) }}
                </div>
              </template>
              <template #prepend-item>
                <v-card
                  flat
                  width="100%"
                  height="100%"
                  :style="{
                    backgroundColor:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }"
                />
              </template>
              <template #item="{ item }">
                <span
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  }"
                >
                  {{ getEdgeDeviceIp(item) }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row> -->
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              IP
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-if="!isDetectedCamerasLoading"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="edgeDevice.ip"
              outlined
              color="secondary"
              hide-details="true"
              autocomplete="off"
              :dense="true"
              readonly
            ></v-text-field>
            <v-progress-circular
              v-if="isDetectedCamerasLoading"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor,
                marginLeft: '1rem'
              }"
              size="25"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Username
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :class="{
                'authentication-credential-error':
                  isAuthenticationCredentialError
              }"
              :loading="isAuthenticationRequestComplete"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="edgeDevice.username"
              label="Username"
              outlined
              :color="credentialErrorColor"
              hide-details="true"
              autocomplete="off"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="usernameError"
              :error="
                usernameError.length > 0 || isAuthenticationCredentialError
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Password
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :class="{
                'authentication-credential-error':
                  isAuthenticationCredentialError
              }"
              :loading="isAuthenticationRequestComplete"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="edgeDevice.password"
              label="Password"
              outlined
              type="password"
              hide-details="true"
              autocomplete="new-password"
              :color="credentialErrorColor"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="passwordError"
              :error="
                passwordError.length > 0 || isAuthenticationCredentialError
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isAuthenticationCredentialError" no-gutters class="mb-4">
          <v-col cols="5"></v-col>
          <v-col cols="7" class="d-flex align-center">
            <p
              class="text-left d-flex align-center ma-0"
              :style="{ color: '#ffd42a' }"
            >
              <v-icon
                v-if="passwordError !== ''"
                :color="credentialErrorColor"
                class="mr-2"
                >mdi-alert-circle</v-icon
              >
              <span>
                {{ passwordError }}
              </span>
            </p>
          </v-col>
        </v-row>
      </template>

      <template v-if="edConfigType === 'Manual'">
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              RTSP URL
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="rtspUrl"
              label="RTSP URL"
              outlined
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="pathError"
              :error="pathError.length > 0"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <v-row
        no-gutters
        class="d-flex flex-row justify-space-between"
        v-if="isEdgeDeviceEnabled"
      >
      </v-row>
      <!-- <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          Turn on/off promiseQube integration
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            id="alarm-ftp-notification-switch"
            v-model="isEdgeDeviceEnabled"
            text
            class="notification-switch pt-1"
            color="secondary"
            inset
            @change="onToggleEdgeDeiveStatus"
            :disabled="!isWriteEnabled"
          ></v-switch>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceClient = { namespace: 'client' }

enum EdgeDeviceConfigTypes {
  AUTO = 'Auto',
  MANUAL = 'Manual'
}

@Component({
  components: { Button }
})
export default class EdgeDeviceConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() onChange: (data: any) => void
  @Prop() onNavigateToLive: () => void
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() siteId!: string
  @Prop() clientId!: string
  @Prop() userId!: string

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('getClientDefaultKey', namespaceClient)
  public getClientDefaultKey: (payload: {
    userId: string
    clientId: string
  }) => Promise<string>

  public edConfigType: EdgeDeviceConfigTypes = EdgeDeviceConfigTypes.AUTO
  public isExpanded: boolean = false

  public isEdgeDeviceSaving = false

  public isEdgeDeviceEnabled: boolean = false
  public rtspUrl = ''
  public edgeDevice = {
    ip: 'No IP detected',
    path: '',
    username: '',
    password: ''
  }

  public pathError: string = ''
  public nameError: string = ''
  public usernameError: string = ''
  public passwordError: string = ''
  public credentialErrorColor: string = '#ffd42a'

  public detectedCameraIp: string = ''
  public detectedCameras: any = []
  public isDetectedCamerasLoading: boolean = false
  public isAuthenticationCredentialError: boolean = false
  public isAuthenticationRequestComplete: boolean = false

  public originalEdgeDeviceData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isEdgeDeviceEnabled =
        this.configuration?.isEdgeDeviceEnabled ?? false
      this.isExpanded = this.isEdgeDeviceEnabled
      this.rtspUrl = this.configuration?.edgeDevice?.path ?? ''
      if (this.rtspUrl) {
        this.edConfigType = EdgeDeviceConfigTypes.MANUAL
      }

      this.edgeDevice = {
        password: this.configuration?.edgeDevice?.password ?? '',
        username: this.configuration?.edgeDevice?.username ?? '',
        ip: this.configuration?.edgeDevice?.ip ?? 'No IP detected',
        path: this.configuration?.edgeDevice?.path ?? ''
      }
    }

    this.originalEdgeDeviceData = {
      isEdgeDeviceEnabled: this.isEdgeDeviceEnabled,
      rtspUrl: this.configuration.edgeDevice?.path ?? '',
      password: this.configuration.edgeDevice?.password ?? '',
      username: this.configuration.edgeDevice?.username ?? ''
    }
    this.handleAuthenticationResponse()
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isEdgeDeviceEnabled !==
      this.originalEdgeDeviceData.isEdgeDeviceEnabled
    )
  }

  public hasDataChanged(): boolean {
    return this.rtspUrl?.trim() !== this.originalEdgeDeviceData?.rtspUrl
  }

  public haAutoDataChanged(): boolean {
    return (
      this.edgeDevice?.username?.trim() !==
        this.originalEdgeDeviceData?.username ||
      this.edgeDevice?.password?.trim() !==
        this.originalEdgeDeviceData?.password
    )
  }

  @Watch('rtspUrl')
  @Watch('isEdgeDeviceEnabled')
  @Watch('edgeDevice.password')
  @Watch('edgeDevice.username')
  public async onClickEdgeDeviceConfig() {
    if (this.edConfigType === EdgeDeviceConfigTypes.MANUAL) {
      let isError: boolean = false
      this.pathError = ''
      this.nameError = ''

      let edgeDeviceData = null

      if (!this.rtspUrl?.trim()) {
        isError = true
        this.pathError = 'Please add RTSP URL'
      }

      edgeDeviceData = {
        path: this.rtspUrl.trim(),
        password: '',
        username: '',
        ip: '',
        type: 'WEB',
        isError,
        isChanged: isError ? false : this.hasDataChanged()
      }

      await this.onChange(edgeDeviceData)
    } else {
      let isError: boolean = false
      this.usernameError = ''
      this.passwordError = ''

      let edgeDeviceData = null

      if (!this.edgeDevice?.username?.trim()) {
        isError = true
        this.usernameError = 'Please add username'
      }

      if (!this.edgeDevice?.password?.trim()) {
        isError = true
        this.passwordError = 'Please add password'
      }

      if (this.isAuthenticationCredentialError) {
        isError = true
        this.passwordError = 'Incorrect credentials. Please try again.'
      }

      edgeDeviceData = {
        path: '',
        password: this.edgeDevice.password?.trim(),
        username: this.edgeDevice.username?.trim(),
        ip: this.edgeDevice.ip?.trim(),
        type: 'WEB',
        isError,
        isChanged: this.haAutoDataChanged()
      }

      await this.onChange(edgeDeviceData)
    }
  }

  public onClickRouteToLiveCamera() {
    this.onNavigateToLive()
  }

  @Watch('isAuthenticationCredentialError')
  public watchIsAuthenticationCredentialError() {
    if (this.isAuthenticationCredentialError) {
      this.passwordError = 'Incorrect credentials. Please try again.'
    } else {
      this.passwordError = ''
    }
  }

  private handleAuthenticationResponse() {
    if (this.configuration?.isAuthenticationRequestComplete !== undefined) {
      this.isAuthenticationRequestComplete =
        !this.configuration?.isAuthenticationRequestComplete
    }
    if (
      !this.configuration?.isAuthenticated &&
      this.configuration?.isAuthenticationRequestComplete
    ) {
      this.isAuthenticationCredentialError = true
    } else {
      this.isAuthenticationCredentialError = false
    }
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-text-field.error--text.authentication-credential-error /deep/ fieldset {
  border-color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error /deep/ .v-label {
  color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error /deep/ input {
  caret-color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error
  /deep/
  .v-messages__message {
  color: #ffd42b !important;
}

.v-text-field.error--text.authentication-credential-error
  /deep/
  input::-webkit-textfield-decoration-container {
  caret-color: #ffd42b !important;
}
</style>
