export const getDefaultState = () => {
  return {
    numberOfTodosWorkerDid: {
      total: 0
    },
    onlineWorkers: [],
    allWorkerStatus: [],
    registeredWorkers: [],
    currentOnlineWorkers: [],
    downlink: 0,
    uplink: 0,
    workerQueue: null,
    workerStatus: null
  }
}

const state = getDefaultState()

export default {
  ...state
}
