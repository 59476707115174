var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isDataAvailable() && !_vm.isOnlyRedundantReportedDataAvailable())?_c('v-container',{staticClass:"d-flex flex-column py-0 pr-2 pl-0 mb-2",attrs:{"fluid":""}},[_c('p',{staticClass:"ml-4 my-0 text-xs text-left font-weight-thin",staticStyle:{"color":"#b8b8b8","font-size":"0.875rem"}},[_vm._v(" "+_vm._s(_vm.facetName)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column justify-center"},_vm._l((_vm.facets),function(facet,index){return _c('label',{key:facet.field},_vm._l((facet.data),function(facetItem){return _c('label',{key:_vm.getValue(facetItem, facet.type)},[(
              _vm.isShown(facet.field) &&
              _vm.showRedundant(_vm.getValue(facetItem, facet.type), facet.field)
            )?_c('div',{staticClass:"d-flex justify-start align-center input--border py-3 px-3"},[_c('input',{attrs:{"type":"checkbox","id":"checkbox"},domProps:{"value":_vm.getValue(facetItem, facet.type),"checked":_vm.isChecked(_vm.getValue(facetItem, facet.type), index)},on:{"change":function($event){return _vm.$emit('change', { event: $event, facet: facet.field })}}}),_c('span',{staticClass:"px-3",staticStyle:{"font-size":"1.15rem"},style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : ''
              })},[_vm._v(_vm._s(_vm.getDisplayValue(facetItem, facet.type, facet)))])]):_vm._e()])}),0)}),0)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }