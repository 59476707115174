<template>
  <div class="mb-6">
    <div
      v-if="modifiedSections.length > 0"
      class="sticky-bar"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled ? '#FFFFFF' : '#333333'
      }"
      data-test-id="site-config-sticky-bar"
    >
      <p
        :style="{
          color: isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#FFFFFF',
          marginTop: '10px'
        }"
        data-test-id="site-config-sticky-bar-message"
      >
        {{
          `Do you want save the changes to ${modifiedSections.join(
            ', '
          )} config(s)? `
        }}
      </p>
      <div class="sticky-button-group">
        <Button
          btnStyle="outlined"
          btnText="Discard"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled ? '#333333' : '#FFFFFF'
          }"
          :class="{
            'discard-btn-dark': !isDarkModeToggleEnabled
          }"
          @onButtonClick="onDiscardSaveConfigs"
          data-test-id="site-config-sticky-bar-discard-button"
        />
        <Button
          btnText="Save"
          @onButtonClick="onSaveConfigs"
          data-test-id="site-config-sticky-bar-save-button"
        />
      </div>
    </div>
    <v-row>
      <v-col style="padding-right: 50px" class="black--text pt-10 pl-12">
        <v-row class="pt-3 pb-12">
          <BlockHeader :title="'Site Information'" :titleOnly="true" />
          <v-row
            class="card-style black--text d-flex flex-column pl-5 pb-7 pr-4 pt-5"
          >
            <p
              class="font-weight-bold text-left"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlack
              }"
              data-test-id="site-config-site-id"
            >
              Site Id :
              <code
                data-test-id="site-config-site-id-copy"
                class="pointer"
                v-text="siteId"
                @click="onClickCopy(siteId)"
              >
              </code>
            </p>
            <p
              class="font-weight-bold text-left"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlack
              }"
              data-test-id="site-config-created-date"
            >
              Created
              <code
                data-test-id="site-config-created-date-value"
                class="pointer"
                v-text="`on ${siteCreatedDate}, at ${siteCreatedTime}`"
              >
              </code>
            </p>
            <div class="form-item">
              <div>
                <p
                  class="font-weight-bold text-left"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="site-config-site-name"
                >
                  Site Name
                </p>
              </div>
              <v-text-field
                v-model="siteName"
                placeholder="Site Name"
                hide-details="true"
                style="margin-top: -20px; font-size: 15px"
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :readonly="!isWriteEnabled"
                data-test-id="site-config-site-name-input"
              >
                <template v-if="isWriteEnabled" v-slot:append-outer>
                  <v-btn
                    icon
                    v-if="siteName"
                    @click="siteName = ''"
                    title="Clear"
                    data-test-id="site-config-site-name-clear-button"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <p
                v-if="siteActivatedDate && siteActivatedTime"
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
                data-test-id="site-config-last-active"
              >
                Last Active
                <code
                  data-test-id="site-config-last-active-value"
                  class="pointer"
                  v-text="`on ${siteActivatedDate}, at ${siteActivatedTime}`"
                >
                </code>
              </p>
            </div>
            <div class="form-item">
              <div>
                <p
                  class="font-weight-bold text-left"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="site-config-street-address"
                >
                  Site Address
                </p>
              </div>
              <v-text-field
                v-model="streetAddress"
                placeholder="Street"
                hide-details="true"
                style="margin-top: -20px; font-size: 15px"
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :readonly="!isWriteEnabled"
                data-test-id="site-config-street-address-input"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    v-if="streetAddress"
                    @click="streetAddress = ''"
                    title="Clear"
                    data-test-id="site-config-street-address-clear-button"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <template>
                <v-row>
                  <!-- City -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="city"
                      placeholder="City"
                      hide-details="true"
                      class="custom-input"
                      :dark="isUserAdmin && isDarkModeToggleEnabled"
                      :readonly="!isWriteEnabled"
                      data-test-id="site-config-city-input"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          icon
                          v-if="city"
                          @click="city = ''"
                          title="Clear"
                          data-test-id="site-config-city-clear-button"
                        >
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- State -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="state"
                      placeholder="State"
                      hide-details="true"
                      class="custom-input"
                      :dark="isUserAdmin && isDarkModeToggleEnabled"
                      :readonly="!isWriteEnabled"
                      data-test-id="site-config-state-input"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          icon
                          v-if="state"
                          @click="state = ''"
                          title="Clear"
                          data-test-id="site-config-state-clear-button"
                        >
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- Zip Code -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="zipcode"
                      placeholder="Zip code"
                      hide-details="true"
                      class="custom-input zip-country"
                      :dark="isUserAdmin && isDarkModeToggleEnabled"
                      :readonly="!isWriteEnabled"
                      data-test-id="site-config-zipcode-input"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          icon
                          v-if="zipcode"
                          @click="zipcode = ''"
                          title="Clear"
                          data-test-id="site-config-zipcode-clear-button"
                        >
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- Country -->
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="country"
                      :items="countries"
                      placeholder="Country"
                      hide-details=""
                      class="custom-input zip-country"
                      :dark="isUserAdmin && isDarkModeToggleEnabled"
                      :disabled="!isWriteEnabled"
                      data-test-id="site-config-country-input"
                    >
                      <template v-slot:append-outer v-if="isWriteEnabled">
                        <v-btn
                          icon
                          v-if="country"
                          @click="country = ''"
                          title="Clear"
                          data-test-id="site-config-country-clear-button"
                        >
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </template>
            </div>
            <div class="form-item">
              <div>
                <p
                  class="font-weight-bold text-left"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlack
                  }"
                  data-test-id="site-config-sia-account-number"
                >
                  SIA-DC09 Account Number
                </p>
              </div>
              <v-text-field
                v-model="accountNumber"
                placeholder="SIA-DC09 Account Number"
                hide-details="true"
                style="margin-top: -20px; font-size: 15px"
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :readonly="!isWriteEnabled"
                data-test-id="site-config-sia-account-number-input"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    v-if="accountNumber"
                    @click="accountNumber = ''"
                    title="Clear"
                    data-test-id="site-config-sia-account-number-clear-button"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-row>
        </v-row>

        <v-row class="pt-10 pb-12 black--text">
          <BlockHeader
            :title="'Contact'"
            :configuration="configuration"
            :type="'site'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onContactSettingsChange"
            data-test-id="site-config-contact-header"
          />
          <v-row :class="getContentOverlayClass(isContactBlockEnabled)">
            <Contact
              :configuration="configuration"
              :clientConfig="clientConfig"
              :onChange="onContactChange"
              :level="'site'"
              :isWriteEnabled="isWriteEnabled"
              :isContactFieldEnabled="isContactFieldEnabled"
            />
          </v-row>
        </v-row>
        <!-- hiding temporary until Ai team implementation is done -->
        <v-row class="pt-10 pb-12 black--text" v-if="false">
          <BlockHeader :title="'Update Scheduling'" :titleOnly="true" />
          <v-row class="card-component-style">
            <SiteUpdateSchedulingConfiguration
              :configuration="configuration"
              :onChange="onUpdateScheduleChange"
              :onSwitchChange="onIsAutoUpdateChange"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
        </v-row>
        <v-row class="pt-10 pb-12 black--text" v-if="isHardwareDevice">
          <BlockHeader :title="'promiseQube'" :titleOnly="true" />
          <v-row class="card-component-style">
            <SiteEdgeDeviceConfiguration
              :configuration="configuration"
              :isWriteEnabled="isWriteEnabled"
              :onRecordingSwitchChange="onToggleEdgeRecordingStatus"
            />
          </v-row>
        </v-row>

        <v-row class="pt-10 pb-12 black--text" v-if="isHardwareDevice">
          <BlockHeader :title="'Recordings'" :titleOnly="true" />
          <v-row class="card-component-style">
            <EdgeDeviceRecording
              :configuration="configuration"
              :isWriteEnabled="isWriteEnabled"
              :onRecordingSwitchChange="onToggleEdgeRecordingStatus"
              :level="'site'"
            />
          </v-row>
        </v-row>

        <v-row
          class="pt-10 pb-12 black--text"
          v-if="!isDefaultNode && !isHardwareDevice"
        >
          <BlockHeader :title="'Integrations'" :titleOnly="true" />
          <v-row>
            <div class="card-style black--text d-flex flex-column pl-4">
              <div style="display: flex; flex-direction: row; font-size: 15px">
                <div style="flex: 1">
                  <h3 class="font-weight-bold text-left pt-4 pb-3 my-2 mt-2">
                    <p
                      :style="{
                        color:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.lightPrimaryColor
                            : getColors.darkBlack
                      }"
                      data-test-id="site-config-sia-receiver"
                    >
                      SIA DC09 Receiver
                    </p>
                  </h3>
                </div>
                <div class="mt-5 mr-4" v-if="isWriteEnabled">
                  <Button
                    v-bind:disabled="ajaxIntegrationType"
                    color="secondary black--text"
                    @onButtonClick="onClickAddSiaReceiver"
                    btnText="Add Integration"
                    data-test-id="site-config-sia-receiver-button"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: row">
                <div style="flex: 1">
                  <p
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkPrimaryColor
                    }"
                    data-test-id="site-config-sia-ip-address-label"
                    class="text-left tooltip"
                  >
                    Ip Address
                    <code
                      data-test-id="site-config-sia-ip-address-copy"
                      class="pointer"
                      v-text="siaServerAddress"
                      @click="onClickCopy(siaServerAddress)"
                    >
                    </code>
                    <span class="tooltClickToCopy">Click To Copy</span>
                  </p>
                  <p
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkPrimaryColor
                    }"
                    class="text-left tooltip"
                    data-test-id="site-config-sia-port-label"
                  >
                    Port
                    <code
                      data-test-id="site-config-sia-port-copy"
                      class="pointer"
                      v-text="'9245'"
                      @click="onClickCopy('9245')"
                    ></code>
                    <span class="tooltClickToCopy">Click To Copy</span>
                  </p>
                  <p
                    v-if="siaAccountNumber"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkPrimaryColor
                    }"
                    class="text-left tooltip"
                    data-test-id="site-config-sia-account-number-label"
                  >
                    Account Number
                    <code
                      data-test-id="site-config-sia-account-number-copy"
                      class="pointer"
                      v-text="siaAccountNumber"
                      @click="onClickCopy(siaAccountNumber)"
                    ></code>
                    <span class="tooltClickToCopy">Click To Copy</span>
                  </p>
                </div>
              </div>
              <template v-for="(siaReceiver, siaReceiverIndex) in siaReceivers">
                <div :key="siaReceiverIndex">
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        :value="siaReceiver.type"
                        :items="['Ajax']"
                        label="Integration Type"
                        outlined
                        hide-details="true"
                        @change="
                          (value) =>
                            onChangeSiaIntegrationType(value, siaReceiverIndex)
                        "
                        :disabled="!isWriteEnabled"
                        data-test-id="site-config-sia-integration-type-select"
                      >
                        <template #prepend-item>
                          <v-card
                            flat
                            width="100%"
                            height="100%"
                            :style="{
                              backgroundColor:
                                isUserAdmin && isDarkModeToggleEnabled
                                  ? getColors.darkFrameColor
                                  : '',
                              position: 'absolute',
                              top: '0px'
                            }"
                          />
                        </template>
                        <template #item="{ item }">
                          <span
                            :style="{
                              color:
                                isUserAdmin && isDarkModeToggleEnabled
                                  ? getColors.lightPrimaryColor
                                  : '',
                              margin: 'auto',
                              'font-size': '13px'
                            }"
                          >
                            {{ item }}
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="siaReceiver.type === 'Ajax'">
                    <v-col cols="12">
                      <v-text-field
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        class="pr-7"
                        v-model="siaReceiver.hubId"
                        label="Ajax Hub Id"
                        outlined
                        color="secondary"
                        hide-details="true"
                        :disabled="!isWriteEnabled"
                        data-test-id="site-config-sia-hub-id-input"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        class="pr-7"
                        v-model="siaReceiver.username"
                        label="Ajax Username"
                        outlined
                        color="secondary"
                        hide-details="true"
                        :disabled="!isWriteEnabled"
                        data-test-id="site-config-sia-ajex-username-input"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        class="pr-7"
                        type="password"
                        v-model="siaReceiver.password"
                        label="Ajax Password"
                        outlined
                        color="secondary"
                        :disabled="!isWriteEnabled"
                        data-test-id="site-config-sia-ajex-password-input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div
                    style="
                      float: right;
                      padding-bottom: 12px;
                      padding-right: 26px;
                    "
                    v-if="isWriteEnabled"
                  >
                    <Button
                      color="black--text mr-3"
                      @onButtonClick="
                        onClickSaveSiaIntegration(siaReceiverIndex)
                      "
                      btnText="Add"
                      class="mx-4"
                      data-test-id="site-config-sia-integration-save"
                    />
                    <Button
                      color="black--text"
                      @onButtonClick="
                        onClickRemoveSiaIntegration(siaReceiverIndex)
                      "
                      btnText="Delete"
                      btnStyle="delete-button"
                      data-test-id="site-config-sia-integration-delete"
                    />
                  </div>
                </div>
              </template>
            </div>
          </v-row>
          <v-row class="card-component-style">
            <FtpConfiguration
              :configuration="configuration"
              :siteId="siteId"
              :subscriptionKeys="subscriptionKeys"
              :onSwitchChange="onToggleFtpStatus"
              :isWriteEnabled="isWriteEnabled"
            />
          </v-row>
        </v-row>
      </v-col>
      <v-col cols="6" class="black--text pt-7">
        <v-col class="pt-3 pl-7 pb-5 black--text">
          <BlockHeader
            :title="'Alarm Settings'"
            :configuration="configuration"
            :type="'site'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onAlarmSettingsChange"
            data-test-id="site-config-alarm-settings-header"
          />
          <v-row :class="getContentOverlayClass(isAsBlockEnabled)">
            <HumanReviewConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :onSwitchChange="onIsAskCrowdWorkersChange"
              :level="'site'"
              :isWriteEnabled="isWriteEnabled"
              :isAlarmFieldEnabled="isAlarmFieldEnabled"
            />
          </v-row>
          <v-row :class="getContentOverlayClass(isAsBlockEnabled)">
            <AlarmDefinitionConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :onChange="onAlarmDefinitionChange"
              :level="'site'"
              :isWriteEnabled="isWriteEnabled"
              :isAlarmFieldEnabled="isAlarmFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pb-5 pl-7">
          <BlockHeader
            :title="'Disarmed Time Range'"
            :configuration="configuration"
            :type="'site'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onUnarmedTimeRangeSettingsChange"
            data-test-id="site-config-unarmed-time-range-header"
          />
          <v-row :class="getContentOverlayClass(isUtrBlockEnabled)">
            <UnarmedTimeRangeConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :onSwitchChange="onClickIsUnarmedTimeRangeEnabled"
              :onChange="onChangeUnarmedTimeRange"
              :level="'site'"
              :isWriteEnabled="isWriteEnabled"
              :isUnarmedFieldEnabled="isUnarmedFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pb-5 pl-7">
          <BlockHeader
            :title="'Health Check'"
            :configuration="configuration"
            :type="'site'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onHealthCheckSettingChange"
            data-test-id="site-config-health-check-header"
          />
          <v-row :class="getContentOverlayClass(isHcBlockEnabled)">
            <HealthCheckConfiguration
              :configuration="configuration"
              :clientConfig="clientConfig"
              :onSwitchChange="onClickIsHealthCheckNotificationEnabled"
              :onLatestHCSwitchChange="
                onClickIsLatestHealthCheckNotificationEnabled
              "
              :onChange="onClickSaveHcDuration"
              :onTypeChange="onHcTypeChange"
              :level="'site'"
              :isWriteEnabled="isWriteEnabled"
              :isHcFieldEnabled="isHcFieldEnabled"
            />
          </v-row>
        </v-col>

        <v-col class="pt-12 pl-7 pb-16 pr-3">
          <BlockHeader
            :title="'Notifications'"
            :configuration="configuration"
            :type="'site'"
            :isWriteEnabled="isWriteEnabled"
            :onSwitchChange="onNotificationSettingChange"
            data-test-id="site-config-notifications-header"
          />

          <v-row class="pr-6">
            <v-tabs
              v-model="tab"
              :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
              background-color="transparent"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              :class="getContentOverlayClass(isHcBlockEnabled)"
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab
                value="Alarm"
                class="notification-tab"
                data-test-id="site-config-notif-tab"
                >Alarm</v-tab
              >
              <v-tab
                value="HC"
                class="notification-tab"
                data-test-id="site-config-notif-hc-tab"
                >Health Check</v-tab
              >
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              class="notification-tab-items"
              :style="{
                backgroundColor:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkPrimaryColor
                    : getColors.lightPrimaryColor
              }"
            >
              <v-tab-item
                v-for="tabKey in ['Alarm', 'HC']"
                :key="tabKey"
                background-color="transparent"
                class="notification-tab-item"
                data-test-id="site-config-notif-tab-item"
              >
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <EmailNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsEmailNotificationEnabledHc
                        : onClickIsEmailNotificationEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveNotificationEmailHc
                        : onClickSaveNotificationEmail
                    "
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <SiaNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsSiaEnabledHc
                        : onClickIsSiaEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveSiaConfigHc
                        : onClickSaveSiaConfig
                    "
                    :onChangeAccountNotified="
                      isNotificationTypeHc
                        ? updateIsRelatedSiaAccountNotifiedHc
                        : updateIsRelatedSiaAccountNotified
                    "
                    :onChangeHeartbeatEnabled="
                      isNotificationTypeHc
                        ? updateIsSiaHeartbeatEnabledHc
                        : updateIsSiaHeartbeatEnabled
                    "
                    :onChangeRoutineMessageEnabled="
                      isNotificationTypeHc
                        ? updateIsSiaRoutineMessageEnabledHc
                        : updateIsSiaRoutineMessageEnabled
                    "
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <WebhookNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsWebhookEnabledHc
                        : onClickIsWebhookEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveWebhookConfigHc
                        : onClickSaveWebhookConfig
                    "
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                  v-if="tabKey === 'Alarm'"
                >
                  <FtpNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="onClickIsFtpNotificationEnabled"
                    :onChange="onClickSaveFtpConfig"
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <SmtpNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsSmtpNotificationEnabledHc
                        : onClickIsSmtpNotificationEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveSmtpConfigHc
                        : onClickSaveSmtpConfig
                    "
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
                <div
                  :class="getContentOverlayClass(isNotificationBlockEnabled)"
                >
                  <EvalinkNotificationConfiguration
                    :configuration="configuration"
                    :clientConfig="clientConfig"
                    :onSwitchChange="
                      isNotificationTypeHc
                        ? onClickIsEvalinkEnabledHc
                        : onClickIsEvalinkEnabled
                    "
                    :onChange="
                      isNotificationTypeHc
                        ? onClickSaveEvalinkConfigHc
                        : onClickSaveEvalinkConfig
                    "
                    :onChangeDeviceOverrideEnabled="
                      isNotificationTypeHc
                        ? onClickIsEvalinkDeviceOverrideEnabledHc
                        : onClickIsEvalinkDeviceOverrideEnabled
                    "
                    :level="'site'"
                    :isWriteEnabled="isWriteEnabled"
                    :notificationType="parentNotificationType"
                    :isNotificationFieldEnabled="isNotificationFieldEnabled"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :class="modifiedSections.length > 0 ? 'snack-bar-margin' : ''"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
    <InfoButton v-if="!smallScreen" :rows="rows" />
    <v-overlay
      :value="isSavingSiteConfigs || isLoading"
      :class="isSavingSiteConfigs ? 'blur-background' : ''"
    >
      <div>
        <v-progress-circular
          indeterminate
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor,
            marginLeft: '1rem'
          }"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ProgressBar from '../ProgressBar.vue'
import { getNames } from 'country-list'
import Button from '@/components/app/Button.vue'
import { isNodeNameValid } from './../../utils/Validator'
import { DocumentReference } from '@firebase/firestore-types'
import FtpNotificationConfiguration from './FtpNotificationConfiguration.vue'
import AlarmDefinitionConfiguration from './AlarmDefinitionConfiguration.vue'
import UnarmedTimeRangeConfiguration from './UnarmedTimeRangeConfiguration.vue'
import HumanReviewConfiguration from './HumanReviewConfiguration.vue'
import EmailNotificationConfiguration from './EmailNotificationConfiguration.vue'
import SiaNotificationConfiguration from './SiaNotificationConfiguration.vue'
import WebhookNotificationConfiguration from './WebhookNotificationConfiguration.vue'
import EvalinkNotificationConfiguration from './EvalinkNotificationConfiguration.vue'
import HealthCheckConfiguration from './HealthCheckConfiguration.vue'
import BlockHeader from './BlockHeader.vue'
import NotificationInheritHeader from './NotificationInheritHeader.vue'
import InfoButton from '@/views/InfoButton.vue'
import Contact from './Contact.vue'
import FtpConfiguration from './FtpConfiguration.vue'
import SmtpNotificationConfiguration from './SmtpNotificationConfiguration.vue'
import { SiteModifiedConfiguration } from 'configuration'
import { getDateAndTime } from '@/utils/Utils'
import { DISARMED_TIME_RANGE } from '@/utils/Constants'
import SiteEdgeDeviceConfiguration from './SiteEdgeDeviceConfiguration.vue'
import EdgeDeviceRecording from './EdgeDeviceRecording.vue'
import SiteUpdateSchedulingConfiguration from './SiteUpdateSchedulingConfiguration.vue'
import { IUpdateSchedulingConfig } from '@/utils/Interfaces'
import { SIA_SERVER_ADDRESS } from '@/utils/EnvMapping'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceSite = { namespace: 'site' }
const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }

interface EdgeRecordingStatus {
  isEdgeDeviceRecordingEnabled: boolean
  isChanged: boolean
}

@Component({
  components: {
    ProgressBar,
    Button,
    AlarmDefinitionConfiguration,
    UnarmedTimeRangeConfiguration,
    FtpNotificationConfiguration,
    HumanReviewConfiguration,
    HealthCheckConfiguration,
    EmailNotificationConfiguration,
    SiaNotificationConfiguration,
    WebhookNotificationConfiguration,
    EvalinkNotificationConfiguration,
    Contact,
    FtpConfiguration,
    InfoButton,
    BlockHeader,
    SmtpNotificationConfiguration,
    NotificationInheritHeader,
    SiteEdgeDeviceConfiguration,
    EdgeDeviceRecording,
    SiteUpdateSchedulingConfiguration
  }
})
export default class SiteConfiguration extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getLastPingAt', namespaceSite) public lastPingAt!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('bindSiteStatus', namespaceSite) private bindSiteStatus: (arg0: {
    siteId: string
  }) => void
  @Action('updateSiteConfig', namespaceSite) public updateSiteConfig: any
  @Action('updateAlarmDefinitionConfig', namespaceSite)
  public updateAlarmDefinitionConfig: any
  @Action('updateUnarmedTimeRange', namespaceSite)
  public updateUnarmedTimeRange: any

  @Action('updateHumanReviewStatus', namespaceSite)
  public updateHumanReviewStatus
  @Action('updateSiaIntegration', namespaceSite)
  public updateSiaIntegration
  @Action('deleteSiaIntegration', namespaceSite)
  public deleteSiaIntegration
  @Action('updateIsEmailNotificationEnabledStatus', namespaceSite)
  public updateIsEmailNotificationEnabledStatus
  @Action('sendEmail', namespaceUser) public sendEmail
  @Action('updateNotificationEmails', namespaceSite)
  public updateNotificationEmails
  @Action('updateIsWebhookTriggerEnabledStatus', namespaceSite)
  public updateIsWebhookTriggerEnabledStatus
  @Action('updateWebhookTriggerConfig', namespaceSite)
  public updateWebhookTriggerConfig
  @Action('updateIsSiaHeartbeatEnabledStatus', namespaceSite)
  public updateIsSiaHeartbeatEnabledStatus
  @Action('updateIsRelatedSiaAccountNotifiedStatus', namespaceSite)
  public updateIsRelatedSiaAccountNotifiedStatus
  @Action('updateIsSiaRoutineMessageEnabledStatus', namespaceSite)
  public updateIsSiaRoutineMessageEnabledStatus
  @Action('updateIsSiaNotificationEnabledStatus', namespaceSite)
  public updateIsSiaNotificationEnabledStatus
  @Action('updateSiaConfig', namespaceSite) public updateSiaConfig
  @Action('updateIsEvalinkNotificationEnabledStatus', namespaceSite)
  public updateIsEvalinkNotificationEnabledStatus
  @Action('updateIsEvalinkDeviceOverrideEnabledStatus', namespaceSite)
  public updateIsEvalinkDeviceOverrideEnabledStatus
  @Action('updateEvalinkConfig', namespaceSite) public updateEvalinkConfig
  @Action('updateIsHealthCheckNotificationEnabledStatus', namespaceSite)
  public updateIsHealthCheckNotificationEnabledStatus
  @Action('updateHcType', namespaceSite) public updateHcType

  @Action('updateFTPConfig', namespaceSite)
  public updateFTPConfig
  @Action('updateIsFTPNotificationEnabledStatus', namespaceSite)
  public updateIsFTPNotificationEnabledStatus

  @Action('updateSMTPConfig', namespaceSite)
  public updateSMTPConfig
  @Action('updateIsSMTPNotificationEnabledStatus', namespaceSite)
  public updateIsSMTPNotificationEnabledStatus

  @Action('updateContact', namespaceSite) public updateContact
  @Action('updateBlockToggle', namespaceConfig) public updateBlockToggle

  @Prop({ required: true }) configuration!: any
  @Prop({ required: true }) clientConfig!: any
  @Prop({ required: true }) userOrOrgRef!: DocumentReference
  @Prop() changeSiteConfigKey: () => void

  public title = ''
  public type = 'site'
  public blockToggles: any[] = []
  public siteName: string = ''
  public siteId: string = ''
  public siteCreatedDate: string = ''
  public siteCreatedTime: string = ''
  public siteActivatedDate: string = ''
  public siteActivatedTime: string = ''
  public streetAddress: string = ''
  public city: string = ''
  public state: string = ''
  public country: string = ''
  public zipcode: string = ''
  public isSaved: boolean = true
  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public countries = getNames()
  public accountNumber: string = ''
  public edgeVersion: string = ''

  public siaReceivers: any = []
  public addedSiaReceivers: any = []
  public deletedSiaReceivers: any = []

  public subscriptionKeys: string[] = []
  public isFtpIntegrationEnabled: any = null
  public siaServerAddress = SIA_SERVER_ADDRESS
  public siaAccountNumber: string = ''
  public IntegrationType: Array<object>
  public ajaxIntegrationType: boolean = false

  public isWriteEnabled: boolean = false
  public isHardwareDevice: boolean = false

  public rows: any[] = [
    {
      icon: 'mdi-account-multiple',
      text: 'Learn more about site configurations',
      link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/configurations/site-configuration',
      class: 'pr-3 black--text'
    }
  ]

  public get smallScreen() {
    return isCompactView()
  }

  public modifiedSections: string[] = []

  public contactData: any = undefined

  public updateScheduleData: IUpdateSchedulingConfig | null = null

  public isAskCrowdWorkers: any = undefined
  public alarmDefinition: any = undefined

  public isUnarmedTimeRangeEnabled: any = null
  public unarmedTimeRangeData: any = null

  public healthCheckType: any = null
  public healthCheckDuration: any = null
  public isHealthCheckNotificationEnabled: any = null
  public isLatestHealthCheckNotificationEnabled: any = null

  public isEmailNotificationEnabled: any = undefined
  public emailData: any = undefined
  public isEmailNotificationEnabledHc: any = undefined
  public emailDataHc: any = undefined

  public siaData: any = undefined
  public isSiaEnabled: any = undefined
  public isSiaHeartbeatEnabled: any = undefined
  public isSiaRoutineMessageEnabled: any = undefined
  public isRelatedSiaAccountNotified: any = undefined
  public siaDataHc: any = undefined
  public isSiaEnabledHc: any = undefined
  public isSiaHeartbeatEnabledHc: any = undefined
  public isSiaRoutineMessageEnabledHc: any = undefined
  public isRelatedSiaAccountNotifiedHc: any = undefined

  public isWebhookTriggerEnabled: any = undefined
  public webhookData: any = undefined
  public isWebhookTriggerEnabledHc: any = undefined
  public webhookDataHc: any = undefined

  public isFTPNotificationsEnabled: any = undefined
  public ftpData: any = undefined
  public isFTPNotificationsEnabledHc: any = undefined
  public ftpDataHc: any = undefined

  public isSMTPNotificationEnabled: any = undefined
  public smtpData: any = undefined
  public isSMTPNotificationEnabledHc: any = undefined
  public smtpDataHc: any = undefined

  public isEvalinkEnabled: any = undefined
  public isEvalinkDeviceOverrideEnabled: any = undefined
  public evalinkData: any = undefined
  public isEvalinkEnabledHc: any = undefined
  public isEvalinkDeviceOverrideEnabledHc: any = undefined
  public evalinkDataHc: any = undefined

  public siteOriginalData: any = undefined

  public isSavingSiteConfigs: boolean = false
  public isAlarmFieldEnabled: boolean = null
  public isUnarmedFieldEnabled: boolean = null
  public isHcFieldEnabled: boolean = null
  public isContactFieldEnabled: boolean = null
  public isNotificationFieldEnabled: boolean = null
  public isEdgeRecordingEnabled: EdgeRecordingStatus | null = null
  public isLimitExceeded: boolean = false
  public isLoading: boolean = false

  // Tab View
  public tab = null
  public parentNotificationType = 'Alarm'

  get isDefaultNode() {
    return this.configuration?.isDefault === true
  }

  get isAsBlockEnabled() {
    if (this.isAlarmFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.alarmSettings
    }
  }

  get isUtrBlockEnabled() {
    if (this.isUnarmedFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.unarmedTimeRange
    }
  }

  get isHcBlockEnabled() {
    if (this.isHcFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.healthCheck
    }
  }

  get isNotificationBlockEnabled() {
    if (this.isNotificationFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.notifications
    }
  }

  get isContactBlockEnabled() {
    if (this.isContactFieldEnabled) {
      return true
    } else {
      return !!this.configuration?.blockToggles?.contact
    }
  }

  public getContentOverlayClass(isToggleEnabled: boolean) {
    const baseClass = 'card-component-style'

    if (!isToggleEnabled && this.isUserAdmin) {
      return `${baseClass} disable-overlay-admin`
    } else if (!isToggleEnabled) {
      return `${baseClass} disable-overlay-customer`
    }

    return baseClass
  }

  public beforeUnload(e) {
    if (this.modifiedSections.length > 0) {
      const message =
        'You have unsaved changes. Are you sure you want to leave?'
      e.returnValue = message
      return message
    }
  }

  public created() {
    window.addEventListener('beforeunload', this.beforeUnload)
  }

  public destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  }

  public mounted() {
    this.onConfigurationChanged()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isNotificationTypeHc() {
    return this.parentNotificationType === 'HC'
  }

  public validateSiteName() {
    // Remove leading and trailing spaces from the client name
    this.siteName = this.siteName?.trim()
    // Validate the client name here
    const isValid = this.siteName === ''
    return isValid
  }

  @Watch('lastPingAt')
  watchLastPingAt() {
    const lastActivatedDateAndTime = getDateAndTime(this.lastPingAt)
    this.siteActivatedDate = lastActivatedDateAndTime.date
    this.siteActivatedTime = lastActivatedDateAndTime.time
  }

  @Watch('configuration')
  onConfigurationChanged() {
    if (this.configuration) {
      this.isWriteEnabled = this.isUserAdmin
        ? true
        : this.configuration?.isWriteEnabled ?? false
      this.siteName = this.configuration?.name
      this.siteId = this.configuration?.siteId
      this.streetAddress = this.configuration?.address?.streetAddress ?? ''
      this.city = this.configuration?.address?.city ?? ''
      this.state = this.configuration?.address?.state ?? ''
      this.country = this.configuration?.address?.country ?? ''
      this.zipcode = this.configuration?.address?.zipcode ?? ''
      this.accountNumber = this.configuration?.accountNumber ?? ''
      this.subscriptionKeys = this.clientConfig.keys
      this.siaReceivers = this.configuration?.siaReceivers ?? []
      this.siaAccountNumber = this.configuration?.siaAccountNumber ?? ''
      this.ajaxIntegrationType = !!this.configuration?.siaReceivers?.find(
        (receiver: { type: any }) => receiver.type === 'Ajax'
      )
      const dateAndTime = getDateAndTime(this.configuration?.createdAt)
      this.siteCreatedDate = dateAndTime.date
      this.siteCreatedTime = dateAndTime.time
      this.edgeVersion = this.configuration?.edgeVersion ?? ''
      this.isHardwareDevice = this.configuration?.isHardwareDevice ?? false

      const lastActivatedDateAndTime = getDateAndTime(
        this.configuration?.lastPingAt
      )
      this.siteActivatedDate = lastActivatedDateAndTime.date
      this.siteActivatedTime = lastActivatedDateAndTime.time

      this.siteOriginalData = {
        siteName: this.siteName,
        streetAddress: this.streetAddress,
        city: this.city,
        state: this.state,
        country: this.country,
        zipcode: this.zipcode,
        accountNumber: this.accountNumber,
        siaReceivers: [...this.siaReceivers]
      }
      this.bindSiteStatus({ siteId: this.configuration.id })
    }
  }

  public hasSiteDataChanged(): boolean {
    return (
      this.siteName?.trim() !== this.siteOriginalData.siteName ||
      this.streetAddress?.trim() !== this.siteOriginalData.streetAddress ||
      this.city?.trim() !== this.siteOriginalData.city ||
      this.state?.trim() !== this.siteOriginalData.state ||
      this.country !== this.siteOriginalData.country ||
      this.zipcode?.trim() !== this.siteOriginalData.zipcode ||
      this.accountNumber?.trim() !== this.siteOriginalData.accountNumber
    )
  }

  public hasSiaReceiversChanged(): boolean {
    return (
      this.addedSiaReceivers.length > 0 || this.deletedSiaReceivers.length > 0
    )
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied to clipboard`)
  }

  private async popSnackbarMessage(message: string) {
    this.snackbar = true
    this.snackbarMessage = message
  }

  public updateBlockTogglesArray(block: string, value: boolean) {
    const index = this.blockToggles.findIndex((item) => item.block === block)
    if (index > -1) {
      this.blockToggles[index].value = value
    } else {
      this.blockToggles.push({
        block: this.title,
        type: this.type,
        docId: this.configuration.id,
        value
      })
    }
  }
  public async toggleBlockSwitch(): Promise<void> {
    for (const block of this.blockToggles) {
      await this.updateBlockToggle(block)
    }
  }

  public resetSaveState() {
    this.modifiedSections = []

    this.contactData = undefined

    this.isAskCrowdWorkers = undefined
    this.alarmDefinition = undefined

    this.isUnarmedTimeRangeEnabled = null
    this.unarmedTimeRangeData = null

    this.healthCheckType = null
    this.healthCheckDuration = null
    this.isHealthCheckNotificationEnabled = null
    this.isLatestHealthCheckNotificationEnabled = null

    this.isEmailNotificationEnabled = undefined
    this.emailData = undefined
    this.isEmailNotificationEnabledHc = undefined
    this.emailDataHc = undefined

    this.siaData = undefined
    this.isSiaEnabled = undefined
    this.isSiaHeartbeatEnabled = undefined
    this.isSiaRoutineMessageEnabled = undefined
    this.isRelatedSiaAccountNotified = undefined
    this.siaDataHc = undefined
    this.isSiaEnabledHc = undefined
    this.isSiaHeartbeatEnabledHc = undefined
    this.isSiaRoutineMessageEnabledHc = undefined
    this.isRelatedSiaAccountNotifiedHc = undefined

    this.isWebhookTriggerEnabled = undefined
    this.webhookData = undefined
    this.updateScheduleData = undefined
    this.isWebhookTriggerEnabledHc = undefined
    this.webhookDataHc = undefined

    this.isFTPNotificationsEnabled = undefined
    this.ftpData = undefined
    this.isFTPNotificationsEnabledHc = undefined
    this.ftpDataHc = undefined

    this.isSMTPNotificationEnabled = undefined
    this.smtpData = undefined
    this.isSMTPNotificationEnabledHc = undefined
    this.smtpDataHc = undefined

    this.isEvalinkEnabled = undefined
    this.isEvalinkDeviceOverrideEnabled = undefined
    this.evalinkData = undefined
    this.isEvalinkEnabledHc = undefined
    this.isEvalinkDeviceOverrideEnabledHc = undefined
    this.evalinkDataHc = undefined
  }

  public async onDiscardSaveConfigs() {
    try {
      this.changeSiteConfigKey()
      this.resetSaveState()
      this.popSnackbarMessage('Site configs discarded!')
    } catch (error) {
      console.log(error)
      this.popSnackbarMessage('Error discarding site configs!')
    }
  }

  public async onSaveConfigs() {
    this.isSavingSiteConfigs = true

    const siteInformation = await this.processSiteDetails()
    if (this.hasSiteDataChanged() && !siteInformation) {
      this.isSavingSiteConfigs = false
      return
    }

    const modifiedConfiguration: SiteModifiedConfiguration = {
      siteInformation: siteInformation,
      contact: {
        contactPerson: this.contactData?.contactName,
        contactNumber: {
          countryCode: this.contactData?.countryCode,
          countryCallingCode: this.contactData?.countryCallingCode,
          phoneNumber: this.contactData?.phoneNumber
        },
        contactMessage: this.contactData?.message,
        receiveCallNotification: this.contactData?.isCallEnabled,
        receiveSmsNotification: this.contactData?.isSmsEnabled
      },
      edgeDevice: {
        isEdgeDeviceRecordingEnabled:
          this.isEdgeRecordingEnabled?.isEdgeDeviceRecordingEnabled
      },
      updateSchedule: {
        selectedTimeZone: this.updateScheduleData?.selectedTimeZone,
        applicableDays: this.updateScheduleData?.applicableDays,
        updateFrequency: this.updateScheduleData?.updateFrequency,
        updateNumber: this.updateScheduleData?.updateNumber,
        scheduledTime: this.updateScheduleData?.scheduledTime,
        isAutoUpdate: this.updateScheduleData?.isAutoUpdate,
        isScheduledUpdate: this.updateScheduleData?.isScheduledUpdate
      },
      isFtpEnabled: this.isFtpIntegrationEnabled?.isFtpEnabled,
      siaIntegration: {
        siaAddedReceivers: this.addedSiaReceivers,
        siaDeletedReceivers: this.deletedSiaReceivers
      },
      alarmSettings: {
        humanReview: this.isAskCrowdWorkers?.isAskCrowdWorkers,
        alarmDefinition: this.alarmDefinition?.alarmDefinition
      },
      unarmedTimeRange: {
        alwaysUnarmed: this.unarmedTimeRangeData?.isAlwaysUnarmed,
        timeZone: this.unarmedTimeRangeData?.selectedTimeZone,
        applicationDays: this.unarmedTimeRangeData?.applicableDays,
        startTime: this.unarmedTimeRangeData?.startTime,
        endTime: this.unarmedTimeRangeData?.endTime
      },
      healthCheck: {
        type: this.healthCheckType?.hcType,
        notifications:
          this.isHealthCheckNotificationEnabled
            ?.isHealthCheckNotificationEnabled,
        latestHCNotifications:
          this.isLatestHealthCheckNotificationEnabled
            ?.isLatestHealthCheckNotificationEnabled,
        frequency: this.healthCheckDuration?.durationInHours,
        unit: null
      },
      notifications: {
        alarm: {
          email: {
            isOn: this.isEmailNotificationEnabled?.isEmailNotificationEnabled,
            receivingEmails: this.emailData?.notificationEmails
          },
          siaDc09: {
            isOn: this.isSiaEnabled?.isSiaEnabled,
            notifySubFolderAccountNumber:
              this.isRelatedSiaAccountNotified?.isRelatedSiaAccountNotified,
            sendSiaHeartbeatMessage:
              this.isSiaHeartbeatEnabled?.isSiaHeartbeatEnabled,
            sendSiaRoutineMessage:
              this.isSiaRoutineMessageEnabled?.isSiaRoutineMessageEnabled,
            ipAddress: this.siaData?.siaIp,
            port: this.siaData?.siaPort
          },
          webhook: {
            isOn: this.isWebhookTriggerEnabled?.isWebhookTriggerEnabled,
            webhookArray: this.webhookData || []
          },
          ftp: {
            isOn: this.isFTPNotificationsEnabled?.isFTPNotificationsEnabled,
            host: this.ftpData?.host,
            port: this.ftpData?.port,
            username: this.ftpData?.user,
            password: this.ftpData?.password,
            filePath: this.ftpData?.filePath,
            isUploadImageSequence: this.ftpData?.isUploadImageSequence,
            isFtpPathEnabled: this.ftpData?.isFtpPathEnabled
          },
          smtp: {
            isOn: this.isSMTPNotificationEnabled?.isSMTPNotificationEnabled,
            host: this.smtpData?.host,
            port: this.smtpData?.port,
            username: this.smtpData?.user,
            password: this.smtpData?.password,
            receiverEmailAddress: this.smtpData?.receiverAddress
          },
          evalink: {
            isOn: this.isEvalinkEnabled?.isEvalinkEnabled,
            deviceOverride:
              this.isEvalinkDeviceOverrideEnabled
                ?.isEvalinkDeviceOverrideEnabled,
            authorizationKey: this.evalinkData?.Authorization,
            companyId: this.evalinkData?.companyId,
            deviceId: this.evalinkData?.deviceId,
            partition: this.evalinkData?.partition,
            partitionName: this.evalinkData?.partitionName,
            alarmZoneName: this.evalinkData?.alarmZoneName
          }
        },
        healthCheck: {
          email: {
            isOn: this.isEmailNotificationEnabledHc?.isEmailNotificationEnabled,
            receivingEmails: this.emailDataHc?.notificationEmails
          },
          siaDc09: {
            isOn: this.isSiaEnabledHc?.isSiaEnabled,
            notifySubFolderAccountNumber:
              this.isRelatedSiaAccountNotifiedHc?.isRelatedSiaAccountNotified,
            sendSiaHeartbeatMessage:
              this.isSiaHeartbeatEnabledHc?.isSiaHeartbeatEnabled,
            sendSiaRoutineMessage:
              this.isSiaRoutineMessageEnabledHc?.isSiaRoutineMessageEnabled,
            ipAddress: this.siaDataHc?.siaIp,
            port: this.siaDataHc?.siaPort
          },
          webhook: {
            isOn: this.isWebhookTriggerEnabledHc?.isWebhookTriggerEnabled,
            webhookArray: this.webhookDataHc || []
          },
          ftp: {
            isOn: this.isFTPNotificationsEnabledHc?.isFTPNotificationsEnabled,
            host: this.ftpDataHc?.host,
            port: this.ftpDataHc?.port,
            username: this.ftpDataHc?.user,
            password: this.ftpDataHc?.password,
            filePath: this.ftpDataHc?.filePath
          },
          smtp: {
            isOn: this.isSMTPNotificationEnabledHc?.isSMTPNotificationEnabled,
            host: this.smtpDataHc?.host,
            port: this.smtpDataHc?.port,
            username: this.smtpDataHc?.user,
            password: this.smtpDataHc?.password,
            receiverEmailAddress: this.smtpDataHc?.receiverAddress
          },
          evalink: {
            isOn: this.isEvalinkEnabledHc?.isEvalinkEnabled,
            deviceOverride:
              this.isEvalinkDeviceOverrideEnabledHc
                ?.isEvalinkDeviceOverrideEnabled,
            authorizationKey: this.evalinkDataHc?.Authorization,
            companyId: this.evalinkDataHc?.companyId,
            deviceId: this.evalinkDataHc?.deviceId,
            partition: this.evalinkDataHc?.partition,
            partitionName: this.evalinkDataHc?.partitionName,
            alarmZoneName: this.evalinkDataHc?.alarmZoneName
          }
        }
      }
    }
    const payload = {
      docId: this.configuration?.id,
      updatedSiteData: modifiedConfiguration,
      updatedFields: this.modifiedSections
    }

    try {
      this.isLoading = true
      try {
        const result = await this.updateSiteConfig(payload)
        if (result?.status === 'success') {
          this.popSnackbarMessage(result.message)
        } else if (result?.status === 'error') {
          this.popSnackbarMessage(result.message)
        }
      } catch (error) {
        this.popSnackbarMessage('Failed to update site configuration')
      } finally {
        this.isSaved = true
      }

      this.resetSaveState()
      await this.toggleBlockSwitch()
      this.isSavingSiteConfigs = false
      this.isLoading = false
      this.popSnackbarMessage('Site configs successfully saved!')
    } catch (error) {
      this.resetSaveState()
      this.isSavingSiteConfigs = false
      this.popSnackbarMessage('Error saving site configs!')
    }
  }

  @Watch('tab')
  public watchTab() {
    this.parentNotificationType = this.tab === 1 ? 'HC' : 'Alarm'
  }

  // ****************************** INTEGRATIONS ******************************

  public onClickAddSiaReceiver() {
    this.siaReceivers.push({
      type: ''
    })
  }

  public onChangeSiaIntegrationType(value, valueIndex) {
    this.siaReceivers[valueIndex].type = value
  }

  public onClickRemoveSiaIntegration(valueIndex) {
    const deletingReceiver = this.siaReceivers[valueIndex]
    const current = [...this.siaReceivers]
    current.splice(valueIndex, 1)
    this.siaReceivers = current
    this.deletedSiaReceivers.push(deletingReceiver)
  }

  public onClickSaveSiaIntegration(valueIndex) {
    this.IntegrationType = this.siaReceivers.filter(
      (receiver: { type: any }) => receiver.type === 'Ajax'
    )
    if (this.IntegrationType.length === 1) {
      const receiverData: any = this.siaReceivers[valueIndex]
      if (receiverData.type === 'Ajax') {
        if (!receiverData.hubId || receiverData.hubId == '') {
          this.popSnackbarMessage('Ajax Hub Id is required')
          return
        }
        if (receiverData.hubId.length !== 8) {
          this.popSnackbarMessage('Ajax Hub Id should be 8 characters')
          return
        }
        if (!receiverData.username || receiverData.username === '') {
          this.popSnackbarMessage('Ajax Username is required')
          return
        }
        if (!receiverData.password || receiverData.password === '') {
          this.popSnackbarMessage('Ajax Password is required')
          return
        }

        this.addedSiaReceivers.push(receiverData)
      }
    } else {
      this.popSnackbarMessage('You Already Have Ajax integration Type')
      const current = [...this.siaReceivers]
      current.splice(valueIndex, 1)
      this.siaReceivers = current
    }
  }

  // --------------------------- FTP integration update ---------------------------
  public async onToggleFtpStatus(value: boolean, isChanged: boolean) {
    this.isFtpIntegrationEnabled = { isFtpEnabled: value, isChanged }
    this.addToModified('FTP Integration', isChanged)
  }

  @Watch('addedSiaReceivers')
  @Watch('deletedSiaReceivers')
  public watchSiaReceivers() {
    this.addToModified('Sia Integration', this.hasSiaReceiversChanged())
  }

  // ****************************** SITE DETAILS ******************************

  @Watch('siteName')
  @Watch('streetAddress')
  @Watch('city')
  @Watch('state')
  @Watch('country')
  @Watch('zipcode')
  @Watch('accountNumber')
  public async onSiteDataChanged() {
    const isValid = this.validateSiteName()
    if (!isValid) {
      this.addToModified('Site Details', this.hasSiteDataChanged())
    } else {
      this.addToModified('Site Details', false)
    }
  }

  public async processSiteDetails(): Promise<{
    siteName: string
    siteAddress: {
      city: string
      country: string
      state: string
      streetAddress: string
      zipcode: string
    }
    siaDC09AccountNumber: string
  } | null> {
    if (this.hasSiteDataChanged()) {
      this.isSaved = false
      // validate site name
      if (!this.siteName) {
        this.popSnackbarMessage('site name is required')
        this.isSaved = true
        return null
      }

      if (!isNodeNameValid(this.siteName)) {
        this.popSnackbarMessage(
          `Site name should not contain any disallowed special characters!`
        )
        this.isSaved = true
        return null
      }

      const siteAddress = {
        streetAddress: this.streetAddress?.trim(),
        city: this.city?.trim(),
        state: this.state?.trim(),
        country: this.country,
        zipcode: this.zipcode?.trim()
      }
      const payload = {
        name: this.siteName?.trim(),
        address: siteAddress,
        docId: this.configuration?.id,
        accountNumber: this.accountNumber?.trim()
      }

      const siteInformation: {
        siteName: string
        siteAddress: {
          city: string
          country: string
          state: string
          streetAddress: string
          zipcode: string
        }
        siaDC09AccountNumber: string
      } = {
        siteName: payload.name,
        siteAddress: payload.address,
        siaDC09AccountNumber: payload.accountNumber
      }
      return siteInformation
    }
    return null
  }

  // ****************************** CONTACT ******************************

  public async onContactSettingsChange(value: boolean, isChanged: boolean) {
    this.title = 'Contact'
    this.updateBlockTogglesArray(this.title, value)
    this.isContactFieldEnabled = value
    this.addToModified('Contact Settings', isChanged)
  }

  public onContactChange(contactData: any) {
    this.contactData = contactData
    this.addToModified('Contact', this.contactData?.isChanged)
  }

  // --------------------------- Edge Device integration update ---------------------------
  public async onToggleEdgeRecordingStatus(value: boolean, isChanged: boolean) {
    // TODO: check recording limit-implemented the same logic in camera level.but need to check with Ai team

    // this.isLimitExceeded = false
    // const siteDocId = `site/${this.configuration?.id}`
    // const isLimitExceeded = await checkRecordingLimit(siteDocId, value)
    // if (value && isLimitExceeded) {
    //   this.isLimitExceeded = true
    //   this.popSnackbarMessage(
    //     'Recording limit reached. Please turn off another camera recording and try again.'
    //   )
    //   return
    // } else {
    //   this.isEdgeRecordingEnabled = {
    //     isEdgeDeviceRecordingEnabled: value,
    //     isChanged
    //   }
    //   this.addToModifiedForToggles('promiseQube Integration Toggle', isChanged)
    // }
    this.isEdgeRecordingEnabled = {
      isEdgeDeviceRecordingEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Recordings', isChanged)
  }

  // *************************** UPDATE SCHEDULING SETTINGS ***************************
  public onUpdateScheduleChange(updateScheduleData: IUpdateSchedulingConfig) {
    this.updateScheduleData = updateScheduleData
    this.addToModified('Update scheduling', updateScheduleData?.isChanged)
  }
  public async onIsAutoUpdateChange(value: boolean, isChanged: boolean) {
    this.isAskCrowdWorkers = { isAskCrowdWorkers: value, isChanged }
    this.addToModified('Update scheduling', isChanged)
  }

  // *************************** ALARM SETTINGS ***************************
  public async onAlarmSettingsChange(value: boolean, isChanged: boolean) {
    this.title = 'Alarm Settings'
    this.updateBlockTogglesArray(this.title, value)
    this.isAlarmFieldEnabled = value
    this.addToModified('Alarm Settings', isChanged)
  }
  // ---------------------- Human review config update ---------------------
  public async onIsAskCrowdWorkersChange(value: boolean, isChanged: boolean) {
    this.isAskCrowdWorkers = { isAskCrowdWorkers: value, isChanged }
    this.addToModified('Human Review', isChanged)
  }

  // ---------------------- Alarm definition config update ---------------------
  public async onAlarmDefinitionChange(value: string, isChanged: boolean) {
    this.alarmDefinition = { alarmDefinition: value, isChanged }
    this.addToModified('Alarm Definition', isChanged)
  }

  // *************************** UNARMED TIME RANGE ***************************

  public async onUnarmedTimeRangeSettingsChange(
    value: boolean,
    isChanged: boolean
  ) {
    this.title = 'Unarmed Time Range'
    this.updateBlockTogglesArray(this.title, value)
    this.isUnarmedFieldEnabled = value
    this.addToModified('Disarmed TimeRange Settings', isChanged)
  }

  public async onClickIsUnarmedTimeRangeEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isUnarmedTimeRangeEnabled = {
      isUnarmedTimeRangeEnabled: value,
      isChanged
    }
    this.addToModified(DISARMED_TIME_RANGE, isChanged)
  }

  public async onChangeUnarmedTimeRange(unarmedTimeRange: object) {
    this.unarmedTimeRangeData = unarmedTimeRange
    this.addToModified(
      DISARMED_TIME_RANGE,
      this.unarmedTimeRangeData?.isChanged
    )
  }

  // *************************** HEALTH CHECK ***************************

  public async onHealthCheckSettingChange(value: boolean, isChanged: boolean) {
    this.title = 'Health Check'
    this.updateBlockTogglesArray(this.title, value)
    this.isHcFieldEnabled = value
    this.addToModified('HealthCheck Settings', isChanged)
  }
  public async onClickIsHealthCheckNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isHealthCheckNotificationEnabled = {
      isHealthCheckNotificationEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Health Check', isChanged)
  }
  public async onClickIsLatestHealthCheckNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isLatestHealthCheckNotificationEnabled = {
      isLatestHealthCheckNotificationEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Health Check', isChanged)
  }

  public async onClickSaveHcDuration(hcData: any) {
    this.healthCheckDuration = hcData
    this.addToModified('Health Check', this.healthCheckDuration?.isChanged)
  }

  public async onHcTypeChange(hcType: string, isChanged: boolean) {
    this.healthCheckType = { hcType, isChanged }
    this.addToModifiedForToggles('Health Check', isChanged)
  }

  // *************************** NOTIFICATIONS ***************************
  public async onNotificationSettingChange(value: boolean, isChanged: boolean) {
    this.title = 'Notifications'
    this.updateBlockTogglesArray(this.title, value)
    this.isNotificationFieldEnabled = value
    this.addToModified('Notification Settings', isChanged)
  }
  // --------------------------- Email config update ---------------------------
  public async onClickIsEmailNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEmailNotificationEnabled = {
      isEmailNotificationEnabled: value,
      isChanged
    }
    this.addToModified('Email Notifications Toggle', isChanged)
  }

  public async onClickSaveNotificationEmail(emailData: any) {
    this.emailData = emailData
    this.addToModified('Email Notifications', this.emailData?.isChanged)
  }

  // --------------------------- Email config update - Health Check ---------------------------
  public async onClickIsEmailNotificationEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEmailNotificationEnabledHc = {
      isEmailNotificationEnabled: value,
      isChanged
    }
    this.addToModified('Email Notifications Toggle (HC)', isChanged)
  }

  public async onClickSaveNotificationEmailHc(emailData: any) {
    this.emailDataHc = emailData
    this.addToModified('Email Notifications (HC)', this.emailDataHc?.isChanged)
  }

  // --------------------------- Sia config update ---------------------------
  public async onClickIsSiaEnabled(value: boolean, isChanged: boolean) {
    this.isSiaEnabled = { isSiaEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaHeartbeatEnabled(value: boolean, isChanged: boolean) {
    this.isSiaHeartbeatEnabled = { isSiaHeartbeatEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsRelatedSiaAccountNotified(
    value: boolean,
    isChanged: boolean
  ) {
    this.isRelatedSiaAccountNotified = {
      isRelatedSiaAccountNotified: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async updateIsSiaRoutineMessageEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaRoutineMessageEnabled = {
      isSiaRoutineMessageEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle', isChanged)
  }

  public async onClickSaveSiaConfig(siaData: any) {
    this.siaData = siaData
    this.addToModified('Sia Notifications', this.siaData?.isChanged)
  }

  // --------------------------- Sia config update - Health Check ---------------------------
  public async onClickIsSiaEnabledHc(value: boolean, isChanged: boolean) {
    this.isSiaEnabledHc = { isSiaEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsSiaHeartbeatEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaHeartbeatEnabledHc = { isSiaHeartbeatEnabled: value, isChanged }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsRelatedSiaAccountNotifiedHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isRelatedSiaAccountNotifiedHc = {
      isRelatedSiaAccountNotified: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC)', isChanged)
  }

  public async updateIsSiaRoutineMessageEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSiaRoutineMessageEnabledHc = {
      isSiaRoutineMessageEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Sia Notifications Toggle (HC))', isChanged)
  }

  public async onClickSaveSiaConfigHc(siaData: any) {
    this.siaDataHc = siaData
    this.addToModified('Sia Notifications (HC)', this.siaDataHc?.isChanged)
  }

  // --------------------------- Webhook config update ---------------------------
  public async onClickIsWebhookEnabled(value: boolean, isChanged: boolean) {
    this.isWebhookTriggerEnabled = { isWebhookTriggerEnabled: value, isChanged }
    this.addToModified('Webhook Notifications', isChanged)
  }

  public async onClickSaveWebhookConfig(webhookData: any) {
    this.webhookData = webhookData
    this.addToModified('Webhook Notifications', this.webhookData?.isChanged)
  }

  // --------------------------- Webhook config update - Health Check ---------------------------
  public async onClickIsWebhookEnabledHc(value: boolean, isChanged: boolean) {
    this.isWebhookTriggerEnabledHc = {
      isWebhookTriggerEnabled: value,
      isChanged
    }
    this.addToModified('Webhook Notifications (HC)', isChanged)
  }

  public async onClickSaveWebhookConfigHc(webhookData: any) {
    this.webhookDataHc = webhookData
    this.addToModified(
      'Webhook Notifications (HC)',
      this.webhookDataHc?.isChanged
    )
  }

  // --------------------------- FTP config update ---------------------------
  public async onClickIsFtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isFTPNotificationsEnabled = {
      isFTPNotificationsEnabled: value,
      isChanged
    }
    this.addToModified('FTP Notifications', isChanged)
  }

  public async onClickSaveFtpConfig(ftpData: any) {
    this.ftpData = ftpData
    this.addToModified('FTP Notifications', this.ftpData?.isChanged)
  }

  // --------------------------- SMTP config update ---------------------------
  public async onClickIsSmtpNotificationEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSMTPNotificationEnabled = {
      isSMTPNotificationEnabled: value,
      isChanged
    }
    this.addToModified('SMTP Notifications', isChanged)
  }

  public async onClickSaveSmtpConfig(smtpNotificationData: any) {
    this.smtpData = smtpNotificationData
    this.addToModified('SMTP Notifications', this.smtpData?.isChanged)
  }

  // --------------------------- SMTP config update - Health Check ---------------------------
  public async onClickIsSmtpNotificationEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isSMTPNotificationEnabledHc = {
      isSMTPNotificationEnabled: value,
      isChanged
    }
    this.addToModified('SMTP Notifications (HC)', isChanged)
  }

  public async onClickSaveSmtpConfigHc(smtpNotificationData: any) {
    this.smtpDataHc = smtpNotificationData
    this.addToModified('SMTP Notifications (HC)', this.smtpDataHc?.isChanged)
  }

  // --------------------------- Evalink config update ---------------------------
  public async onClickIsEvalinkEnabled(value: boolean, isChanged: boolean) {
    this.isEvalinkEnabled = {
      isEvalinkEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickIsEvalinkDeviceOverrideEnabled(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEvalinkDeviceOverrideEnabled = {
      isEvalinkDeviceOverrideEnabled: value,
      isChanged
    }
    this.addToModifiedForToggles('Evalink Notifications Toggle', isChanged)
  }

  public async onClickSaveEvalinkConfig(evalinkData: any) {
    this.evalinkData = evalinkData
    this.addToModified('Evalink Notifications', this.evalinkData?.isChanged)
  }

  // --------------------------- Evalink config update - Health Check ---------------------------
  public async onClickIsEvalinkEnabledHc(value: boolean, isChanged: boolean) {
    this.isEvalinkEnabledHc = {
      isEvalinkEnabled: value,
      isChanged
    }
    this.addToModified('Evalink Notifications Toggle (HC)', isChanged)
  }

  public async onClickIsEvalinkDeviceOverrideEnabledHc(
    value: boolean,
    isChanged: boolean
  ) {
    this.isEvalinkDeviceOverrideEnabledHc = {
      isEvalinkDeviceOverrideEnabled: value,
      isChanged
    }
    this.addToModified('Evalink Notifications Toggle (HC)', isChanged)
  }

  public async onClickSaveEvalinkConfigHc(evalinkData: any) {
    this.evalinkDataHc = evalinkData
    this.addToModified(
      'Evalink Notifications (HC)',
      this.evalinkDataHc?.isChanged
    )
  }
  public addToModified(name: string, isChanged: boolean) {
    this.modifiedSections = this.modifiedSections.filter(
      (item) => item !== name
    )
    if (isChanged) {
      this.modifiedSections.push(name)
    }
  }

  public changeCounts: { [key: string]: number } = {}

  //when there  are multiple toggles in a section need to use this function for adding to modifiedSections
  public addToModifiedForToggles(name: string, isChanged: boolean) {
    // Increment or decrement the change count for the section
    this.changeCounts[name] =
      (this.changeCounts[name] || 0) + (isChanged ? 1 : -1)
    // Update modifiedSections based on change count
    if (this.changeCounts[name] > 0) {
      if (!this.modifiedSections.includes(name)) {
        this.modifiedSections.push(name)
      }
    } else {
      this.modifiedSections = this.modifiedSections.filter(
        (item) => item !== name
      )
    }
  }
}
</script>

<style scoped>
.custom-input {
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
}
.custom-input.zip-country {
  margin-top: -13px;
}
code {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3c87c0;
  cursor: pointer;
}

.form-item {
  padding-bottom: 30px;
}
.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}
.tooltip {
  text-align: left;
  position: relative;
}
.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.card-component-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid #dadada 1px;
  border-right: solid #dadada 1px;
  border-bottom: solid #dadada 1px;
}

.disable-overlay-customer {
  background-color: #f2f2f2 !important;
}

.disable-overlay-admin {
  background-color: rgba(147, 141, 118, 0.3) !important;
}
.email-row {
  display: flex;
  align-items: center;
}
.left-align-btn {
  text-align: left !important;
}
.snack-bar-margin {
  margin-bottom: 120px;
}
.notification-switch {
  float: left;
  padding-left: 15px;
}
#custom-tab-items {
  background-color: transparent !important;
}

.sticky-bar {
  position: fixed;
  top: 10px;
  right: 10px;
  left: 267px;
  display: flex;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center !important;
  padding: 1em 1em;
  z-index: 10;
}

.discard-btn-dark:hover {
  background-color: #6b580e !important;
  color: #fff;
}

.sticky-button-group {
  display: flex;
  gap: 1em;
}

.blur-background {
  backdrop-filter: blur(3px);
}

.notification-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.notification-tab {
  flex: 1;
  max-width: inherit;
  text-transform: initial;
  width: 100%;
}

.notification-tab-items {
  width: 100%;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
</style>
