var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('div',{staticClass:"d-flex justify-space-between align-center input--border pa-2"},[_c('p',{staticClass:"my-0 font-weight-bold",style:({
      color:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.lightPrimaryColor
          : _vm.getColors.darkBlackColor
    })},[_vm._v(" "+_vm._s(_vm.displayName)+" ")]),(!_vm.isUserDetailsRequired)?_c('div',{staticClass:"d-flex flex-row justify-end"},[(_vm.allowCopy)?_c('v-icon',{staticClass:"mr-2",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlackColor
      }),on:{"click":function($event){_vm.onClickCopy(_vm.getValue(_vm.item))}}},[_vm._v(_vm._s('mdi-content-copy'))]):_vm._e(),_c('p',{staticClass:"my-0 select",class:_vm.getClass(_vm.item),style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlackColor
      })},[_vm._v(" "+_vm._s(_vm.getValue(_vm.item))+" ")])],1):_vm._e(),(_vm.isUserDetailsRequired)?_c('div',{staticClass:"d-flex flex-row justify-end"},[(_vm.isUserAvailable())?_c('Avatar',{attrs:{"width":'24px',"id":_vm.item,"contain":""}}):_c('v-icon',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlackColor
      })},[_vm._v("mdi-account-group-outline ")]),_c('p',{staticClass:"my-0 ml-3 select",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlackColor
      })},[_vm._v(" "+_vm._s(_vm.userValue ? _vm.userValue : 'Loading...')+" ")])],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({attrs:{"text":"","btnText":"Close","btnStyle":"outlined"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}],null,false,794750850),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }