<template>
  <div>
    <div class="param-row mb-3" :dark="darkMode">
      <v-row
        :style="{
          color: darkMode
            ? getColors.lightPrimaryColor
            : getColors.darkBlackColor
        }"
      >
        <v-col cols="6">
          <p class="param-title py-0">Key</p>
        </v-col>
        <v-col cols="6">
          <p class="param-title py-0">Value</p>
        </v-col>
      </v-row>
      <Button
        v-if="isWriteEnabled"
        @onButtonClick="onClickAddParam()"
        elevation="0"
        color="secondary"
        style="width: 100px; color: black"
        btnText="Add"
        :dark="darkMode"
        :disabled="isToggleDisabled"
        data-test-id="add-webhook-param-button"
      />
    </div>
    <v-divider horizontal class="mb-4"></v-divider>
    <template>
      <div
        class="param-row mb-5"
        v-for="(param, paramIndex) in params"
        :key="paramIndex"
        :dark="darkMode"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Key"
              outlined
              hide-details="true"
              :value="param.key"
              @change="(value) => onChangeParam(value, paramIndex, 'key')"
              :disabled="!isWriteEnabled || isToggleDisabled"
              data-test-id="webhook-param-key-input"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Value"
              outlined
              class="mr-4"
              hide-details="true"
              :value="param.value"
              @input="(value) => onChangeParam(value, paramIndex, 'value')"
              data-test-id="webhook-param-value-input"
              :disabled="!isWriteEnabled || isToggleDisabled"
            ></v-text-field>
          </v-col>
        </v-row>

        <Button
          v-if="isWriteEnabled"
          elevation="0"
          @onButtonClick="onClickRemoveParam(paramIndex)"
          style="width: 100px; color: black; background-color: #fff"
          btnText="Delete"
          btnStyle="delete-button"
          :disabled="isToggleDisabled"
          data-test-id="delete-webhook-param-button"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Button from '@/components/app/Button.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const namespaceTodos = { namespace: 'todos' }
const namespaceUser = { namespace: 'user' }
@Component({
  components: {
    Button
  }
})
export default class WebhookParams extends Vue {
  @Getter('getColors', namespaceUser) public getColors!: any
  @Prop() public setParams!: (
    params: { key: string; value: string }[],
    type: string
  ) => void
  @Prop() public type!: string
  @Prop() public currParams: { key: string; value: string }[]
  @Prop() public dark: boolean
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: false }) isToggleDisabled: boolean

  public params: { key: string; value: string }[] = []
  public darkMode: boolean = false

  public mounted() {
    this.params = this.currParams
    this.darkMode = this.dark
  }

  public onClickAddParam() {
    this.params.push({ key: '', value: '' })
  }

  public onClickRemoveParam(fieldIndex: number) {
    const current = [...this.params]
    current.splice(fieldIndex, 1)
    this.params = current
  }

  public onChangeParam(value: string, valueIndex: number, type: string) {
    if (type === 'key') {
      this.params[valueIndex].key = value
    } else if (type === 'value') {
      this.params[valueIndex].value = value
    }
  }

  @Watch('params', { deep: true })
  public watchParams() {
    this.setParams(this.params, this.type)
  }

  @Watch('currParams')
  public watchCurrParams() {
    this.params = this.currParams
  }

  @Watch('dark')
  public watchDark() {
    this.darkMode = this.dark
  }
}
</script>

<style scoped>
.param-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.param-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
  margin: 0px;
}
</style>
