<template>
  <v-container
    class=".v-container-tab-content"
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div class="chart-width" style="padding-bottom: 40px">
      <v-row
        class="d-flex justify-space-between align-items-center justify-content-center"
      >
        <v-col
          ><SummaryData
            :startTime="startTime"
            :endTime="endTime"
            :userId="userId"
            :clientId="clientId"
            :siteId="siteId"
            :cameraId="cameraId"
        /></v-col>
      </v-row>
    </div>

    <v-row
      class="d-flex justify-space-between align-items-center justify-content-center mt-4 mx-3 custom-tab"
    >
      <div
        class="chart-border pa-4"
        v-if="!userId && isUserAdmin"
        id="incidents-per-customer-chart"
      >
        <div class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Incidents Per Customer</span
            >
            <IncidentsPerCustomerChart
              :startTime="startTime"
              :endTime="endTime"
            />
          </div>
        </div>
      </div>

      <div class="pa-4 chart-border">
        <div class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Incidents Over Time</span
            >
            <IncidentChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>

      <div class="chart-border">
        <div id="sankey-chart" class="my-2 pa-4 chart-width">
          <div id="sankey-chart" class="my-2 pa-4 chart-width">
            <div>
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
                class="chart-header-style"
                >Incident Result Distribution</span
              >
              <SankeyChart :startTime="startTime" :endTime="endTime" />
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="pa-4 chart-border">
        <div class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Result Distribution</span
            >
            <ResultDistributionChart
              :startTime="startTime"
              :endTime="endTime"
            />
          </div>
        </div>
      </div>

      <div class="chart-border pa-4">
        <div class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Number of Filtered Alarms Accumulated Over Time</span
            >
            <ReductionChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>

      <div class="chart-border" v-if="!cameraId">
        <div id="camera-incidents-chart" class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Number of Unique Camera Incidents Over Time</span
            >
            <UniqueCameraChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>

      <div class="chart-border" v-if="isUserAdmin">
        <div id="input-type-chart" class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Incidents by Input Type</span
            >
            <InputTypeChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>

      <div class="chart-border" v-if="!cameraId">
        <div id="incident-sankey-chart" class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Camera IDs Which Throws Incidents</span
            >
            <IncidentCameraIdChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>

      <div class="chart-border" id="weekly-incidents-chart">
        <div class="my-2 pa-4 chart-width">
          <div>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="chart-header-style"
              >Incidents Per Week</span
            >
            <WeeklyIncidentChart :startTime="startTime" :endTime="endTime" />
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { Getter } from 'vuex-class'
import SummaryData from '../charts/SummaryData.vue'
import SankeyChart from '../charts/SankeyChart.vue'
import IncidentsPerCustomerChart from '../charts/IncidentsPerCustomerChart.vue'
import ReductionChart from '../charts/ReductionChart.vue'
import IncidentFirstCameraChart from '../charts/IncidentFirstCameraChart.vue'
import IncidentLastCameraChart from '../charts/IncidentLastCameraChart.vue'
import IncidentChart from '../charts/IncidentChart.vue'
import UniqueCameraChart from '../charts/UniqueCameraChart.vue'
import IncidentCameraIdChart from '../charts/IncidentCameraIdChart.vue'
import ResultDistributionChart from '../charts/ResultDistributionChart.vue'
import WeeklyIncidentChart from '../charts/WeeklyIncidentChart.vue'
import InputTypeChart from '../charts/InputTypeChart.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    SummaryData,
    SankeyChart,
    IncidentsPerCustomerChart,
    ReductionChart,
    IncidentFirstCameraChart,
    IncidentLastCameraChart,
    IncidentChart,
    WeeklyIncidentChart,
    IncidentCameraIdChart,
    ResultDistributionChart,
    UniqueCameraChart,
    InputTypeChart
  }
})
export default class GeneralStatistics extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getGlobalTimeRange', namespaceConfig) public globalTimeRange

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public userId: string = ''
  public clientId: string = ''
  public siteId: string = ''
  public cameraId: string = ''

  public startTime = moment().subtract(6, 'h')
  public endTime = moment()

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  mounted() {
    this.watchGlobalTimeRange()
    this.watchParams()
  }

  @Watch('$route.params')
  private watchParams() {
    this.userId = this.$route.params.userId ?? ''
    this.clientId = this.$route.params.clientId ?? ''
    this.siteId = this.$route.params.siteId ?? ''
    this.cameraId = this.$route.params.cameraId ?? ''
  }

  @Watch('globalTimeRange')
  private watchGlobalTimeRange() {
    this.startTime = this.globalTimeRange.startDateTime
    this.endTime = this.globalTimeRange.endDateTime
  }
}
</script>

<style scoped>
* {
  color: #000;
  box-sizing: border-box;
}

.hide {
  visibility: hidden;
}

p {
  color: black;
}

.chart-header {
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 45px;
}

.chart-titles {
  text-align: left;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  padding: 30px 60px 0;
}

.incident-sankey-chart-header {
  display: inline-block;
  text-align: left;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 15px;
  padding: 20px 60px 0;
}
.camera-sankey {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}

.chart-border {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 12px;
}
.vertical-divider {
  border-width: 1px;
}

.chart-width {
  width: 100%;
}

#incident-sankey-chart {
  width: 100%;
}

.hide {
  display: none;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0px !important;
  padding: 0px !important;
  max-width: 100% !important;
}

.custom-tab {
  margin: 0px !important;
}

.chart-header-style {
  display: inline-block;
  text-align: left;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 15px;
  padding: 0px 60px 0;
  width: 100%;
}
</style>
