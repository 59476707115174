<template>
  <v-row
    :style="{
      color:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.lightPrimaryColor
          : getColors.darkPrimaryColor
    }"
  >
    <!-- Incidents Summary Data-->
    <IncidentSummaryBlock :startTime="startTime" :endTime="endTime" />

    <!-- Quota Summery Data-->
    <div v-if="userId && clientId && !siteId && tmpQuotaStatus">
      <QuotaSummaryBlock :userId="userId" :clientId="clientId" />
    </div>

    <!-- Installation Summery Data-->
    <v-row
      v-if="!cameraId"
      class="d-flex justify-space-between align-items-center justify-content-center"
    >
      <v-col class="d-flex justify-space-between flex-wrap">
        <span
          class="summary-title summary-title-promiseqube col-12"
          v-if="isCheckPromiseQube"
          >promiseQube</span
        >
        <span class="summary-title col-12" v-else>Installation Summary</span>

        <v-row class="d-flex justify-center col-xl-12">
          <div v-if="isUserAdmin && !userId" class="installation-summary">
            <InstallSummaryBlock :isUserAdmin="isUserAdmin" :type="'user'" />
          </div>
          <div v-if="!clientId" class="installation-summary">
            <InstallSummaryBlock
              :isUserAdmin="isUserAdmin"
              :type="'client'"
              :userId="userId"
            />
          </div>

          <div v-if="!siteId" class="installation-summary">
            <InstallSummaryBlock
              :isUserAdmin="isUserAdmin"
              :type="'site'"
              :userId="userId"
              :clientId="clientId"
            />
          </div>

          <div v-if="!cameraId" class="installation-summary">
            <InstallSummaryBlock
              :isUserAdmin="isUserAdmin"
              :type="'camera'"
              :userId="userId"
              :clientId="clientId"
              :siteId="siteId"
            />
          </div>
          <div
            v-if="!cameraId && isCheckPromiseQube"
            class="installation-summary"
          >
            <InstallSummaryBlock
              :isUserAdmin="isUserAdmin"
              :type="'camera'"
              :userId="userId"
              :clientId="clientId"
              :siteId="siteId"
              :isPromiseQube="isCheckPromiseQube"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>

    <!-- Usage Summery Data-->
    <v-row
      v-if="!userId && isUserAdmin"
      class="d-flex justify-space-between align-items-center justify-content-center"
    >
      <v-col class="d-flex justify-space-between flex-wrap">
        <span class="summary-title col-12">Usage Summary</span>
        <UsageSummaryBlock :startTime="startTime" :endTime="endTime" />
      </v-col>
    </v-row>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import InstallSummaryBlock from './InstallSummaryBlock.vue'
import UsageSummaryBlock from './UsageSummaryBlock.vue'
import IncidentSummaryBlock from './IncidentSummaryBlock.vue'
import QuotaSummaryBlock from './QuotaSummaryBlock.vue'
import { QUOTA_ENABLED } from '../../utils/Constants'

const namespaceUser = { namespace: 'user' }
const namespaceStatistics = { namespace: 'statistics' }
const namespaceConfig = { namespace: 'config' }
const namespaceSite = { namespace: 'site' }

@Component({
  components: {
    InstallSummaryBlock,
    UsageSummaryBlock,
    IncidentSummaryBlock,
    QuotaSummaryBlock
  }
})
export default class SummaryData extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any
  @Prop() public userId!: string
  @Prop() public clientId!: string
  @Prop() public siteId!: string
  @Prop() public cameraId!: string

  @Getter('siteConfig', namespaceSite) public siteConfig: any
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  // Quota is temporarily disabled until the security issues are fixed.
  public tmpQuotaStatus: boolean = QUOTA_ENABLED

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isSiteLevel() {
    return this.clientId && this.siteId && !this.cameraId
  }

  public get isCheckPromiseQube() {
    if (this.isSiteLevel) {
      return this.siteConfig?.isHardwareDevice
    }
  }
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.incident-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 230px;
}

.installation-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}

.summary-title-promiseqube {
  padding: 12px 30px;
}
</style>
