<template>
  <div class="camera">
    <WHEPClientComponent
      v-if="playerType === PlayerType.WEBRTC"
      ref="videoContainer"
      class="camera-video"
      :whepUrl="whepUrl"
      :streamPath="streamPath"
      :streamPlaceholder="loaderImageLink"
    />
    <WHEPIframe
      v-else-if="playerType === PlayerType.IFRAME"
      class="camera-video"
      :whepUrl="whepUrl"
      :streamPath="streamPath"
      :streamPlaceholder="loaderImageLink"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import WHEPClientComponent from '@/components/camera/player/WHEPPlayer/WHEPClient.vue'
import { getWebRTCStream } from '@/services/api-admin/live-stream/live-stream'
import { PlayerType } from '@/utils/Interfaces'
import WHEPIframe from '@/components/camera/player/WHEPPlayer/WHEPIframe.vue'
import { cameraProvider } from '@/provider/firebase'

@Component({
  components: {
    WHEPClientComponent,
    WHEPIframe
  }
})
export default class WHEPPlayer extends Vue {
  @Prop({ required: true }) public cameraDocId: string
  @Prop({ default: PlayerType.IFRAME }) public playerType: PlayerType
  @Prop({ required: true }) public referenceImagePath: string

  public readonly PlayerType = PlayerType

  public whepUrl: string = ''
  public streamPath: string = ''
  public loaderImageLink = ''

  @Watch('playerType')
  public mounted() {
    console.log(this.playerType)
    this.getWebRTC()

    if (this.referenceImagePath) {
      this.updateImagePlaceholder()
    }
  }

  @Watch('referenceImagePath')
  public async watchCameraConfig() {
    await this.updateImagePlaceholder()
  }

  private async updateImagePlaceholder() {
    if (this.referenceImagePath) {
      const imageURL = (await cameraProvider.fetchDownloadUrl(
        this.referenceImagePath
      )) as string
      this.loaderImageLink = imageURL
    }
  }

  private async getWebRTC() {
    getWebRTCStream(this.cameraDocId)
      .then((response) => {
        this.whepUrl = response.data.serverURL
        this.streamPath = response.data.streamPath
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>

<style scoped>
.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.camera-video {
  width: 100%;
  height: 100%;
}
.hidden {
  display: none;
}
</style>
