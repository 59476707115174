var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isAuthenticatedCamera === false)?_c('div',[_c('NoCamera',{attrs:{"customTitle":"Camera is not authenticated!","customText":"Sorry, but this camera is not authenticated for live streaming. You can connect live streaming to this camera by going to Configs."}})],1):(_vm.isSiteHardwareDevice === true && _vm.isEdgeDeviceEnabled === false)?_c('div',[_c('NoCamera',{attrs:{"customTitle":"Camera is not enabled!","customText":"Sorry, but this camera is not enabled for live streaming. You can enable this camera by going to Configs."}})],1):(
      !(
        (_vm.isLiveStreamEnabled && _vm.isRtspAvailable) ||
        (_vm.isEdgeDeviceEnabled && _vm.isEdgeDeviceAvailable)
      )
    )?_c('div',[(_vm.liveStreamError)?[_vm._v(" "+_vm._s(_vm.liveStreamError)+" ")]:[_c('NoCamera',{attrs:{"nodeType":"camera"}})]],2):_c('div',{staticClass:"camera"},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}}):_c('div',{staticClass:"camera"},[_c('div',{staticClass:"camera"},[_c('LiveStreamPlayer',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"cameraConfig":_vm.cameraConfig,"cameraId":_vm.cameraConfig.id,"isSiteHardwareDevice":_vm.isSiteHardwareDevice,"liveStreamQuality":_vm.liveStreamQuality,"seekEnabled":true}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }