var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.incidentsOverTimeStatus !== 'Ready')?_c('div',{staticClass:"loading-spinner"},[(_vm.incidentsOverTimeStatus === 'Error')?_c('div',[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" There is an error while loading this chart ")])]):_vm._e(),(_vm.incidentsOverTimeStatus === 'Loading')?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_vm._e()]):_vm._e(),(_vm.incidentsOverTimeStatus === 'Ready')?_c('div',{staticClass:"my-2 pa-4"},[(_vm.incidentsOverTimeData.length > 0)?_c('div',[_c('GChart',{key:"incidents-over-time",attrs:{"type":"ColumnChart","data":_vm.modifiedChartData,"options":_vm.chartOptions}})],1):_c('div',[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" There is not enough data to show this chart ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }