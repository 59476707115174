<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <Header route="live" />

    <div
      v-if="
        cameras && cameras.length === 0 && !isLoading && !globalLiveIsLoading
      "
    >
      <NoCamera nodeType="user" />
    </div>

    <v-container fluid class="bg-surface-variant">
      <v-row
        no-gutters
        v-if="cameras && cameras.length !== 0"
        class="layout-per-row"
      >
        <v-col cols="10"></v-col>

        <v-col cols="2" class="text-end">
          <v-select
            v-model="numberOfCameras"
            :items="[1, 2, 3, 4]"
            label="Layout per row"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-select>
        </v-col>
      </v-row>
      <div class="loading" v-if="globalLiveIsLoading">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor
          }"
        >
          Loading Camera List...
        </p>
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>

      <v-row>
        <v-col
          v-for="camera in cameras"
          :key="camera.id"
          style="color: black"
          class="cameras-per-row-lanscape"
          cols="12"
          :sm="12 / numberOfCameras"
        >
          <LiveFrame
            :camera="camera"
            :numberOfItems="numberOfCameras"
            :level="'global'"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import LiveFrame from './LiveFrame.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceUser = { namespace: 'user' }
const namespaceCamera = { namespace: 'camera' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    LiveFrame
  }
})
export default class LiveViewGlobal extends Vue {
  @Prop({
    default: ''
  })
  clientId: string
  userId: string

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getGlobalCameras', namespaceUser) public globalCameras
  @Getter('getGlobalLiveIsLoading', namespaceUser) public globalLiveIsLoading
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public numberOfCameras: number = 4
  public isLoading: boolean = true
  public cameras: any[] | null = null

  public isMounted = false

  private mounted() {
    this.isLoading = true
    this.isMounted = true
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get smallScreen() {
    return isCompactView()
  }

  @Watch('globalCameras')
  public watchGlobalCameras() {
    if (this.globalCameras) {
      console.log('asd', this.globalCameras)
      // Group the cameras by siteId
      const groupedBySite = this.globalCameras.reduce(
        (acc: any, camera: any) => {
          const siteId = camera.siteId
          const siteDocId = camera.siteFirestoreId

          if (!acc[siteDocId]) {
            acc[siteDocId] = {
              siteId,
              cameras: [],
              siteLimit: camera.edgeLiveStreamLimit
            }
          }

          acc[siteDocId].cameras.push(camera)

          return acc
        },
        {} as any
      )

      const finalCameras: any[] = []

      // Process each site group
      Object.values(groupedBySite).forEach((siteGroup: any) => {
        const { cameras, siteLimit } = siteGroup

        // Separate cameras into those that need to follow the limit and sorting logic and those that do not
        const camerasToLimit = cameras.filter(
          (item: any) =>
            item.isSiteHardwareDevice &&
            siteLimit > 0 &&
            item.isAuthenticated !== false &&
            item.isEdgeDeviceEnabled === true
        )

        const otherCameras = cameras.filter(
          (item: any) =>
            !(item.isSiteHardwareDevice && siteLimit > 0) ||
            item.isAuthenticated === false ||
            item.isEdgeDeviceEnabled !== true
        )

        // Sort camerasToLimit based on isEdgeCameraUnarmed
        camerasToLimit.sort((a: any, b: any) => {
          const aUnarmed = a.isEdgeCameraUnarmed
          const bUnarmed = b.isEdgeCameraUnarmed

          if (aUnarmed === false) return -1
          if (bUnarmed === false) return 1
          if (aUnarmed === true) return 1
          if (bUnarmed === true) return -1
          return 0
        })

        // Apply site limits
        const limitedCameras: any[] = []
        let currentSiteLimit = siteLimit

        camerasToLimit.forEach((item: any) => {
          if (currentSiteLimit > 0 && item.isEdgeCameraUnarmed === false) {
            limitedCameras.push(item)
            currentSiteLimit--
          }
        })

        camerasToLimit.forEach((item: any) => {
          if (currentSiteLimit > 0 && item.isEdgeCameraUnarmed === true) {
            limitedCameras.push(item)
            currentSiteLimit--
          }
        })

        // Combine the limited and other cameras
        finalCameras.push(
          ...limitedCameras,
          ...otherCameras.filter((item: any) => {
            if (item.isSiteHardwareDevice) {
              return (
                item.isAuthenticated !== false &&
                item.isEdgeDeviceEnabled === true
              )
            } else {
              return item.isLiveStreamEnabled === true
            }
          })
        )
      })

      this.cameras = finalCameras

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.loading {
  color: black;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.alert {
  padding: 20px;
  background-color: #ff5252;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.id-info {
  background-color: rgb(242, 242, 242);
}
.id-row {
  display: flex;
  align-items: center;
  padding: 4px;
}
.id-label {
  font-weight: bold;
  margin-right: 8px;
}
/* Styles for mobile devices in portrait mode */
@media only screen and (max-width: 480px) and (max-height: 950px) and (orientation: portrait) {
  .page-header {
    display: none;
  }
  .layout-per-row {
    display: none;
  }
}

/* Styles for mobile devices in landscape mode */
@media only screen and (max-width: 950px) and (max-height: 480px) and (orientation: landscape) {
  .page-header {
    display: none;
  }
  .layout-per-row {
    display: none;
  }
  .cameras-per-row-lanscape {
    flex: 0 0 50% !important; /* Forces the column to take up 50% width, equivalent to sm=6 */
    max-width: 50% !important; /* Ensures the max width is also 50% */
  }
}
</style>
