<template><div></div></template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { testDownloadSpeed, testUploadSpeed } from '@/services/speedTest'

@Component({})
export default class RunSpeedTest extends Vue {
  @Prop({ default: false }) runTest!: boolean

  downloadSpeed: number = 0
  uploadSpeed: number = 0
  downloadTestCompleted: boolean = false
  uploadTestCompleted: boolean = false

  @Watch('runTest')
  async onRunTestChanged(value: boolean) {
    if (value) {
      // run this.init(), if not finished in 90 seconds, set downloadTestCompleted and uploadTestCompleted to true and cancel the test
      const timeout = setTimeout(() => {
        this.downloadTestCompleted = true
        this.uploadTestCompleted = true
      }, 90 * 1000)

      await this.init()
    }
  }

  private async init() {
    try {
      this.downloadSpeed = await testDownloadSpeed((currentSpeed: number) => {
        this.downloadSpeed = currentSpeed
      })
    } catch {
      this.downloadTestCompleted = true
    }
    try {
      this.downloadTestCompleted = true
      this.uploadSpeed = await testUploadSpeed((currentSpeed: number) => {
        this.uploadSpeed = currentSpeed
      })
      this.uploadTestCompleted = true
    } catch (e) {
      this.uploadTestCompleted = true
    }
  }

  public async retry() {
    this.resetAll()
    await this.init()
  }

  private resetAll() {
    this.downloadSpeed = 0
    this.uploadSpeed = 0
    this.downloadTestCompleted = false
    this.uploadTestCompleted = false
  }

  @Watch('downloadSpeed')
  private emitDownloadSpeed(value: number) {
    if (value !== 0) {
      this.$emit('download-speed', value.toFixed(2))
    }
  }

  @Watch('uploadSpeed')
  private emitUploadSpeed(value: number) {
    if (value !== 0) {
      this.$emit('upload-speed', value.toFixed(2))
    }
  }

  //   emit test completed event
  @Watch('downloadTestCompleted')
  @Watch('uploadTestCompleted')
  private emitTestCompleted() {
    if (this.downloadTestCompleted && this.uploadTestCompleted) {
      this.$emit('test-completed')
      // reset all
      this.resetAll()
    }
  }
}
</script>

<style scoped>
/* ... */
</style>
