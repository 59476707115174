export interface ISiteState {
  siteConfig: any
  siteCameras: any
  siteUsers: any[]
  lastPingAt: any
}

export const getDefaultState = (): ISiteState => {
  return {
    siteConfig: null,
    siteCameras: null,
    siteUsers: [],
    lastPingAt: null
  }
}

const state = getDefaultState()

export default {
  ...state
}
