var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4"},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"always-unarmed-label"}},[_vm._v(" Always Disarmed ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"always-unarmed-switch","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"text":"","inset":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"always-unarmed-switch"},model:{value:(_vm.isAlwaysUnarmed),callback:function ($$v) {_vm.isAlwaysUnarmed=$$v},expression:"isAlwaysUnarmed"}})],1),_c('div',{staticClass:"card-style"},[_c('v-container',{attrs:{"fluid":"","align":"center"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-1 pr-3 pb-5",style:({
                      color:
                        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? _vm.getColors.lightPrimaryColor
                          : _vm.getColors.darkPrimaryColor
                    }),attrs:{"data-test-id":"time-zone-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkPrimaryColor
                      })},[_vm._v(" mdi-web ")]),_vm._v(" Select Time Zone ")],1)],1),_c('v-col',[_c('v-autocomplete',{staticClass:"text-caption unarmed-input",attrs:{"items":_vm.timeZonesList,"outlined":"","dense":"","append-icon":"mdi-chevron-down","content-class":"elevation-0","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled || _vm.isAlwaysUnarmed,"data-test-id":"time-zone-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                          position: 'absolute',
                          top: '0px',
                          backgroundColor:
                            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.darkFrameColor
                              : ''
                        }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-list-item',{key:item,staticClass:"d-flex align-center justify-center",style:({
                          color:
                            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.lightPrimaryColor
                              : '',
                          backgroundColor:
                            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.darkFrameColor
                              : '',
                          margin: 'auto',
                          'font-size': '13px'
                        }),attrs:{"value":item},on:{"click":function($event){return _vm.handleItemClick(item)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.selectedTimeZone),callback:function ($$v) {_vm.selectedTimeZone=$$v},expression:"selectedTimeZone"}})],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-1 pr-3 pb-5",style:({
                      color:
                        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? _vm.getColors.lightPrimaryColor
                          : _vm.getColors.darkPrimaryColor
                    }),attrs:{"data-test-id":"applicable-days-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkPrimaryColor
                      })},[_vm._v(" mdi-calendar-week-outline ")]),_vm._v(" Applicable Days ")],1)],1),_c('v-col',[_c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(_vm.applicableDays),callback:function ($$v) {_vm.applicableDays=$$v},expression:"applicableDays"}},_vm._l((_vm.daysOfWeek),function(day){return _c('v-btn',{key:day.value,staticClass:"toggle-button-class",style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkPrimaryColor,
                        backgroundColor:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.darkFrameColor
                            : ''
                      }),attrs:{"value":day.value,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled || _vm.isAlwaysUnarmed,"data-test-id":"applicable-days-select"}},[_vm._v(" "+_vm._s(day.short)+" ")])}),1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12"}},[[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-1 pr-3 pb-5",style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkPrimaryColor
                      }),attrs:{"data-test-id":"start-time-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                          color:
                            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.lightPrimaryColor
                              : _vm.getColors.darkPrimaryColor
                        })},[_vm._v(" mdi-clock-time-eight-outline ")]),_vm._v(" Start Time ")],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"text-caption unarmed-input",attrs:{"type":"time","outlined":"","dense":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled || _vm.isAlwaysUnarmed,"data-test-id":"start-time-input"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1)],1)]],2),_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12"}},[[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-1 pr-3 pb-5",style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkPrimaryColor
                      }),attrs:{"data-test-id":"end-time-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                          color:
                            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? _vm.getColors.lightPrimaryColor
                              : _vm.getColors.darkPrimaryColor
                        })},[_vm._v(" mdi-clock-time-four-outline ")]),_vm._v(" End Time ")],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"text-caption unarmed-input",attrs:{"type":"time","outlined":"","dense":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled || _vm.isAlwaysUnarmed,"data-test-id":"end-time-input"},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)],1)]],2)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }