var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4",attrs:{"data-test-id":"health-check-config-notification-title"}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_vm._v(" Health Check Notifications ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"data-test-id":"alarm-health-check-notification-switch","text":"","inset":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},on:{"change":_vm.onClickHealthCheckNotificationEnabled},model:{value:(_vm.isHealthCheckNotificationEnabled),callback:function ($$v) {_vm.isHealthCheckNotificationEnabled=$$v},expression:"isHealthCheckNotificationEnabled"}})],1),_c('div',{staticClass:"card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4 pr-4 pt-3 pb-3"},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isReferenceImageNotAvailable)?_c('v-alert',{staticStyle:{"text-align":"center"},attrs:{"dense":"","outlined":"","type":_vm.refImageNoteWarning,"color":_vm.refImageNoteColor,"width":"100%","data-test-id":"health-check-config-reference-image-alert"}},[_c('p',{staticStyle:{"margin":"0px"}},[_vm._v(_vm._s(_vm.refImageNote))])]):_vm._e(),_c('v-col',{staticClass:"d-flex align-center"},[_c('div',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          }),attrs:{"data-test-id":"health-check-type-label"}},[_vm._v(" Health Check Type ")])]),_c('v-col',[_c('v-select',{staticClass:"text-caption",attrs:{"items":_vm.hcTypes,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"outlined":"","dense":"","hide-details":"","append-icon":"mdi-chevron-down","content-class":"elevation-0","placeholder":"Unit","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"health-check-type-select"},on:{"input":_vm.onChangeHealthCheckType},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.hcType),callback:function ($$v) {_vm.hcType=$$v},expression:"hcType"}})],1)],1)],1),(!_vm.isUserCustomer)?_c('div',{staticClass:"card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4 pr-3 d-flex flex-column align-start"},[_c('div',{style:([
        {
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack,
          'padding-top': '19px',
          'padding-bottom': '19px'
        }
      ]),attrs:{"data-test-id":"health-check-config-frequency-title"}},[_vm._v(" Health Check Frequency ")]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-0 pr-3 pb-5",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"data-test-id":"health-check-config-frequency-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              })},[_vm._v(" mdi-counter ")]),_vm._v(" Frequency ")],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"text-caption",attrs:{"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"outlined":"","dense":"","placeholder":"Frequency","type":"number","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"rules":[_vm.positiveNumberRule],"error-messages":_vm.hcDurationError,"error":_vm.hcDurationError.length > 0,"data-test-id":"health-check-config-frequency-input"},model:{value:(_vm.hcDuration),callback:function ($$v) {_vm.hcDuration=_vm._n($$v)},expression:"hcDuration"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-0 pr-3 pb-5",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"data-test-id":"health-check-config-unit-label"}},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              })},[_vm._v(" mdi-clock-outline ")]),_vm._v(" Unit ")],1)],1),_c('v-col',[_c('v-select',{staticClass:"text-caption",attrs:{"items":_vm.hcUnits,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"outlined":"","dense":"","append-icon":"mdi-chevron-down","content-class":"elevation-0","placeholder":"Unit","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"health-check-config-unit-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                  backgroundColor:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,3327068982),model:{value:(_vm.hcUnit),callback:function ($$v) {_vm.hcUnit=$$v},expression:"hcUnit"}})],1)],1)],1)]):_vm._e(),_c('div',{staticClass:"card-style no-bottom text-subtitle-1 font-weight-bold text-left notification-title pl-4",attrs:{"data-test-id":"latest-health-check-config-notification-title"}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"latest-health-check-title"}},[_vm._v(" Latest Health Check Notifications ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"data-test-id":"alarm-latest-health-check-notification-switch","text":"","inset":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},on:{"change":_vm.onClickLatestHealthCheckNotificationEnabled},model:{value:(_vm.isLatestHealthCheckNotificationEnabled),callback:function ($$v) {_vm.isLatestHealthCheckNotificationEnabled=$$v},expression:"isLatestHealthCheckNotificationEnabled"}})],1),(_vm.level === 'camera')?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-subheader',{staticClass:"text-no-wrap pl-0 pr-3 pb-5",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_c('v-icon',{staticClass:"pr-2 d-none d-sm-flex",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              })},[_vm._v(" mdi-calendar-range ")]),_vm._v(" Performed ")],1)],1),_c('v-col',[_c('p',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlack
            })},[_c('code',{staticClass:"pointer",domProps:{"textContent":_vm._s(_vm.lastHealthCheckAt)}})])])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }