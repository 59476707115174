<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <template v-if="nodeType === 'site'">
      <path
        d="M7.15186 10.4099C6.48881 10.4099 5.85293 10.1465 5.38409 9.67768C4.91525 9.20884 4.65186 8.57295 4.65186 7.90991C4.65186 7.24687 4.91525 6.61099 5.38409 6.14215C5.85293 5.6733 6.48881 5.40991 7.15186 5.40991C7.8149 5.40991 8.45078 5.6733 8.91962 6.14215C9.38846 6.61099 9.65186 7.24687 9.65186 7.90991C9.65186 8.23822 9.58719 8.56331 9.46155 8.86662C9.33592 9.16993 9.15177 9.44553 8.91962 9.67768C8.68748 9.90983 8.41188 10.094 8.10856 10.2196C7.80525 10.3452 7.48016 10.4099 7.15186 10.4099ZM7.15186 0.909912C5.29534 0.909912 3.51486 1.64741 2.20211 2.96016C0.889353 4.27292 0.151855 6.0534 0.151855 7.90991C0.151855 13.1599 7.15186 20.9099 7.15186 20.9099C7.15186 20.9099 14.1519 13.1599 14.1519 7.90991C14.1519 6.0534 13.4144 4.27292 12.1016 2.96016C10.7888 1.64741 9.00837 0.909912 7.15186 0.909912Z"
        fill="#8C8C8C"
      />
      <mask
        id="path-2-outside-1_971_10637"
        maskUnits="userSpaceOnUse"
        x="6.32422"
        y="11.2029"
        width="10"
        height="10"
        fill="black"
      >
        <rect fill="white" x="6.32422" y="11.2029" width="10" height="10" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7866 16.3713L8.32422 18.8337L9.03133 19.5408L11.4937 17.0785L13.9561 19.5408L14.6632 18.8337L12.2008 16.3713L14.6622 13.91L13.9551 13.2029L11.4937 15.6642L9.03234 13.2029L8.32523 13.91L10.7866 16.3713Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7866 16.3713L8.32422 18.8337L9.03133 19.5408L11.4937 17.0785L13.9561 19.5408L14.6632 18.8337L12.2008 16.3713L14.6622 13.91L13.9551 13.2029L11.4937 15.6642L9.03234 13.2029L8.32523 13.91L10.7866 16.3713Z"
        :fill="fillColor"
      />
      <path
        d="M8.32422 18.8337L7.61711 18.1266L6.91001 18.8337L7.61711 19.5408L8.32422 18.8337ZM10.7866 16.3713L11.4937 17.0785L12.2008 16.3713L11.4937 15.6642L10.7866 16.3713ZM9.03133 19.5408L8.32422 20.2479L9.03132 20.955L9.73843 20.2479L9.03133 19.5408ZM11.4937 17.0785L12.2008 16.3713L11.4937 15.6642L10.7866 16.3713L11.4937 17.0785ZM13.9561 19.5408L13.249 20.2479L13.9561 20.955L14.6632 20.2479L13.9561 19.5408ZM14.6632 18.8337L15.3703 19.5408L16.0774 18.8337L15.3703 18.1266L14.6632 18.8337ZM12.2008 16.3713L11.4937 15.6642L10.7866 16.3713L11.4937 17.0785L12.2008 16.3713ZM14.6622 13.91L15.3693 14.6171L16.0764 13.91L15.3693 13.2029L14.6622 13.91ZM13.9551 13.2029L14.6622 12.4958L13.9551 11.7887L13.248 12.4958L13.9551 13.2029ZM11.4937 15.6642L10.7866 16.3713L11.4937 17.0785L12.2008 16.3713L11.4937 15.6642ZM9.03234 13.2029L9.73944 12.4958L9.03234 11.7887L8.32523 12.4958L9.03234 13.2029ZM8.32523 13.91L7.61812 13.2029L6.91102 13.91L7.61812 14.6171L8.32523 13.91ZM9.03133 19.5408L11.4937 17.0785L10.0795 15.6642L7.61711 18.1266L9.03133 19.5408ZM9.73843 18.8337L9.03133 18.1266L7.61711 19.5408L8.32422 20.2479L9.73843 18.8337ZM10.7866 16.3713L8.32422 18.8337L9.73843 20.2479L12.2008 17.7856L10.7866 16.3713ZM14.6632 18.8337L12.2008 16.3713L10.7866 17.7856L13.249 20.2479L14.6632 18.8337ZM13.9561 18.1266L13.249 18.8337L14.6632 20.2479L15.3703 19.5408L13.9561 18.1266ZM11.4937 17.0785L13.9561 19.5408L15.3703 18.1266L12.9079 15.6642L11.4937 17.0785ZM13.9551 13.2029L11.4937 15.6642L12.9079 17.0785L15.3693 14.6171L13.9551 13.2029ZM13.248 13.91L13.9551 14.6171L15.3693 13.2029L14.6622 12.4958L13.248 13.91ZM12.2008 16.3713L14.6622 13.91L13.248 12.4958L10.7866 14.9571L12.2008 16.3713ZM8.32523 13.91L10.7866 16.3713L12.2008 14.9571L9.73944 12.4958L8.32523 13.91ZM9.03234 14.6171L9.73944 13.91L8.32523 12.4958L7.61812 13.2029L9.03234 14.6171ZM11.4937 15.6642L9.03234 13.2029L7.61812 14.6171L10.0795 17.0785L11.4937 15.6642Z"
        :fill="outlineColor"
        mask="url(#path-2-outside-1_971_10637)"
      />
    </template>

    <template v-else-if="nodeType === 'camera'">
      <path
        d="M2.20679 2.94141H5.20679L7.20679 0.941406H13.2068L15.2068 2.94141H18.2068C18.7372 2.94141 19.2459 3.15212 19.621 3.52719C19.9961 3.90227 20.2068 4.41097 20.2068 4.94141V16.9414C20.2068 17.4718 19.9961 17.9805 19.621 18.3556C19.2459 18.7307 18.7372 18.9414 18.2068 18.9414H2.20679C1.67635 18.9414 1.16765 18.7307 0.792573 18.3556C0.417501 17.9805 0.206787 17.4718 0.206787 16.9414V4.94141C0.206787 4.41097 0.417501 3.90227 0.792573 3.52719C1.16765 3.15212 1.67635 2.94141 2.20679 2.94141ZM10.2068 5.94141C8.8807 5.94141 7.60894 6.46819 6.67125 7.40587C5.73357 8.34355 5.20679 9.61532 5.20679 10.9414C5.20679 12.2675 5.73357 13.5393 6.67125 14.4769C7.60894 15.4146 8.8807 15.9414 10.2068 15.9414C11.5329 15.9414 12.8046 15.4146 13.7423 14.4769C14.68 13.5393 15.2068 12.2675 15.2068 10.9414C15.2068 9.61532 14.68 8.34355 13.7423 7.40587C12.8046 6.46819 11.5329 5.94141 10.2068 5.94141ZM10.2068 7.94141C11.0024 7.94141 11.7655 8.25748 12.3281 8.82009C12.8907 9.38269 13.2068 10.1458 13.2068 10.9414C13.2068 11.7371 12.8907 12.5001 12.3281 13.0627C11.7655 13.6253 11.0024 13.9414 10.2068 13.9414C9.41114 13.9414 8.64808 13.6253 8.08547 13.0627C7.52286 12.5001 7.20679 11.7371 7.20679 10.9414C7.20679 10.1458 7.52286 9.38269 8.08547 8.82009C8.64808 8.25748 9.41114 7.94141 10.2068 7.94141Z"
        fill="#8C8C8C"
      />
      <mask
        id="path-2-outside-1_680_8959"
        maskUnits="userSpaceOnUse"
        x="13.8896"
        y="12.475"
        width="10"
        height="10"
        fill="black"
      >
        <rect fill="white" x="13.8896" y="12.475" width="10" height="10" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.352 17.6434L15.8896 20.1058L16.5968 20.8129L19.0591 18.3505L21.5215 20.8129L22.2286 20.1058L19.7662 17.6434L22.2276 15.1821L21.5205 14.475L19.0591 16.9363L16.5978 14.475L15.8907 15.1821L18.352 17.6434Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.352 17.6434L15.8896 20.1058L16.5968 20.8129L19.0591 18.3505L21.5215 20.8129L22.2286 20.1058L19.7662 17.6434L22.2276 15.1821L21.5205 14.475L19.0591 16.9363L16.5978 14.475L15.8907 15.1821L18.352 17.6434Z"
        :fill="fillColor"
      />
      <path
        d="M15.8896 20.1058L15.1825 19.3987L14.4754 20.1058L15.1825 20.8129L15.8896 20.1058ZM18.352 17.6434L19.0591 18.3505L19.7662 17.6434L19.0591 16.9363L18.352 17.6434ZM16.5968 20.8129L15.8896 21.52L16.5968 22.2271L17.3039 21.52L16.5968 20.8129ZM19.0591 18.3505L19.7662 17.6434L19.0591 16.9363L18.352 17.6434L19.0591 18.3505ZM21.5215 20.8129L20.8144 21.52L21.5215 22.2271L22.2286 21.52L21.5215 20.8129ZM22.2286 20.1058L22.9357 20.8129L23.6428 20.1058L22.9357 19.3987L22.2286 20.1058ZM19.7662 17.6434L19.0591 16.9363L18.352 17.6434L19.0591 18.3505L19.7662 17.6434ZM22.2276 15.1821L22.9347 15.8892L23.6418 15.1821L22.9347 14.475L22.2276 15.1821ZM21.5205 14.475L22.2276 13.7679L21.5205 13.0608L20.8134 13.7679L21.5205 14.475ZM19.0591 16.9363L18.352 17.6434L19.0591 18.3505L19.7662 17.6434L19.0591 16.9363ZM16.5978 14.475L17.3049 13.7679L16.5978 13.0608L15.8907 13.7679L16.5978 14.475ZM15.8907 15.1821L15.1836 14.475L14.4764 15.1821L15.1836 15.8892L15.8907 15.1821ZM16.5968 20.8129L19.0591 18.3505L17.6449 16.9363L15.1825 19.3987L16.5968 20.8129ZM17.3039 20.1058L16.5968 19.3987L15.1825 20.8129L15.8896 21.52L17.3039 20.1058ZM18.352 17.6434L15.8896 20.1058L17.3039 21.52L19.7662 19.0577L18.352 17.6434ZM22.2286 20.1058L19.7662 17.6434L18.352 19.0577L20.8144 21.52L22.2286 20.1058ZM21.5215 19.3987L20.8144 20.1058L22.2286 21.52L22.9357 20.8129L21.5215 19.3987ZM19.0591 18.3505L21.5215 20.8129L22.9357 19.3987L20.4733 16.9363L19.0591 18.3505ZM21.5205 14.475L19.0591 16.9363L20.4733 18.3505L22.9347 15.8892L21.5205 14.475ZM20.8134 15.1821L21.5205 15.8892L22.9347 14.475L22.2276 13.7679L20.8134 15.1821ZM19.7662 17.6434L22.2276 15.1821L20.8134 13.7679L18.352 16.2292L19.7662 17.6434ZM15.8907 15.1821L18.352 17.6434L19.7662 16.2292L17.3049 13.7679L15.8907 15.1821ZM16.5978 15.8892L17.3049 15.1821L15.8907 13.7679L15.1836 14.475L16.5978 15.8892ZM19.0591 16.9363L16.5978 14.475L15.1836 15.8892L17.6449 18.3505L19.0591 16.9363Z"
        :fill="outlineColor"
        mask="url(#path-2-outside-1_680_8959)"
      />
    </template>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  MonitoringStatus,
  NodeStatus,
  PromiseQubeNodeStatus
} from '@/utils/NodeStatus/NodeStatus'

enum NodeType {
  Site = 'site',
  Camera = 'camera'
}
@Component
export default class NodeStatusSVG extends Vue {
  @Prop({ default: MonitoringStatus.Armed }) readonly armedStatus!: NodeStatus
  @Prop({ default: false }) readonly isDarkModeEnabled!: boolean
  @Prop({ required: true }) readonly nodeType!: NodeType

  get fillColor(): string {
    const statusColorMap: Record<NodeStatus, string> = {
      [MonitoringStatus.Armed]: '#FF5252', // Red
      [PromiseQubeNodeStatus.Armed_Inactive]: '#FF5252', // Red
      [PromiseQubeNodeStatus.Armed_Active]: '#FF5252',
      [PromiseQubeNodeStatus.Disarmed_Active]: '#4CAF50', // Green
      [MonitoringStatus.Disarmed]: '#4CAF50', // Green
      [PromiseQubeNodeStatus.Disarmed_Inactive]: '#4CAF50', // Green
      [PromiseQubeNodeStatus.Off]: this.isDarkModeEnabled
        ? '#333333' // Dark grey
        : '#F1F1F1', // Light grey
      [PromiseQubeNodeStatus.Unauthenticated]: this.isDarkModeEnabled
        ? '#333333' // Dark grey
        : '#F1F1F1' // Light grey
    }

    return (
      statusColorMap[this.armedStatus] ||
      (this.isDarkModeEnabled
        ? '#333333' // Dark grey
        : '#F1F1F1') // Light grey
    )
  }

  get outlineColor(): string {
    return this.armedStatus === PromiseQubeNodeStatus.Unauthenticated
      ? '#8C8C8C' // Grey
      : this.isDarkModeEnabled
      ? '#333333' // Dark grey
      : '#F1F1F1' // Light grey
  }
}
</script>
