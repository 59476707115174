import {
  DEFAULT_QUOTA,
  DEFAULT_QUOTA_ALERT_PERCENTAGE,
  ChartDataStatus
} from '@/utils/Constants'

export const getDefaultState = () => {
  return {
    summary: {
      totalAlarmCount: 0,
      falseAlarmCount: 0,
      trueAlarmCount: 0,
      reductionPercentage: 0,
      verifiedReductionPercentage: 0,
      riskPercentage: 0
    },
    incidentSummaryStats: {
      labels: [],
      data: []
    },
    confusionStats: {
      missedReductionCount: 0,
      detectionCount: 0,
      reductionCount: 0,
      riskCount: 0,
      totalCount: 0
    },
    labeledStats: {
      labeledDCount: 0,
      unLabeledCount: 0
    },
    incidentStats: {
      labels: [],
      alarmData: [],
      noAlarmData: [],
      unsureData: [],
      allData: []
    },
    incidentCameraStats: {
      labels: [],
      data: []
    },
    incidentWeeklyStats: {
      labels: [],
      data: []
    },
    incidentCameraIdStats: {
      labels: [],
      data: []
    },
    incidentsPerCustomerStats: {
      labels: [],
      data: []
    },
    treeNodes: {},
    routeParams: {
      userId: '',
      clientId: '',
      siteId: '',
      cameraId: ''
    },
    inputTypeStats: {
      labels: [],
      FTP: [],
      Genetec: [],
      SMTP: [],
      Ajax: [],
      Hikvision: [],
      SFTP: [],
      API: [],
      EBUS: [],
      totalInputTypeCount: 0
    },
    sankeyChartStats: {
      alarm: 0,
      noAlarm: 0,
      unsure: 0,
      alarmToAlarm: 0,
      alarmToNoAlarm: 0,
      alarmToUnsure: 0,
      noAlarmToAlarm: 0,
      noAlarmToNoAlarm: 0,
      noAlarmToUnsure: 0,
      unsureToAlarm: 0,
      unsureToNoAlarm: 0,
      unsureToUnsure: 0
    },
    fixedIntervalLabel: '',
    totalAlarmCountPerMonth: 0,
    totalAlarmQuotaPerMonth: {
      isQuotaEnabled: false,
      alarmQuota: DEFAULT_QUOTA,
      quotaAlertPercentage: DEFAULT_QUOTA_ALERT_PERCENTAGE
    },
    usedAlarmQuotaPerMonth: 0,

    incidentsPerCustomerData: null,
    incidentsPerCustomerStatus: ChartDataStatus.LOADING,

    incidentsOverTimeData: null,
    incidentsOverTimeStatus: ChartDataStatus.LOADING,

    incidentsResultDistributionData: null,
    incidentsResultDistributionStatus: ChartDataStatus.LOADING,

    resultDistributionData: null,
    resultDistributionStatus: ChartDataStatus.LOADING,

    filteredAlarmsAccumulatedOverTimeData: null,
    filteredAlarmsAccumulatedOverTimeStatus: ChartDataStatus.LOADING,

    uniqueCameraIncidentsOverTimeData: null,
    uniqueCameraIncidentsOverTimeStatus: ChartDataStatus.LOADING,

    incidentsByInputTypeData: null,
    incidentsByInputTypeStatus: ChartDataStatus.LOADING,

    camerasThrowsIncidentsData: null,
    camerasThrowsIncidentsStatus: ChartDataStatus.LOADING,

    summaryData: null,
    summaryStatus: ChartDataStatus.LOADING,

    usageMetricsData: [
      { type: 'New Sign Ups', count: 0 },
      { type: 'New Cameras', count: 0 },
      { type: 'Active Users', count: 0 }
    ],
    usageMetricsStatus: ChartDataStatus.LOADING,

    incidentWeeklyData: null,
    incidentWeeklyStatus: ChartDataStatus.LOADING
  }
}

const state = getDefaultState()

export default {
  ...state
}
