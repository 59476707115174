<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 70vh;
    "
  >
    <!-- Image -->
    <div style="text-align: center">
      <img
        v-if="isUserAdmin && isDarkModeToggleEnabled"
        src="../../../public/img/no_integrity_dark.svg"
        alt="No Camera SVG"
        style="max-width: 100%; height: auto"
      />
      <img
        v-else
        src="../../../public/img/no_integrity_light.svg"
        alt="No Camera SVG"
        style="max-width: 100%; height: auto"
      />
    </div>

    <!-- Text -->
    <div style="padding-top: 20px; text-align: center; max-width: 500px">
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          fontWeight: 'bold',
          fontSize: '30px',
          textAlign: 'center'
        }"
      >
        {{ `No ${healthCheckType} health checks.` }}
      </p>
      <p
        v-if="isNodeConfigsAvailable"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          textAlign: 'center',
          fontSize: '18px'
        }"
      >
        {{
          `Sorry, but there is no ${healthCheckType} health checks for this ${nodeType} yet. You can configure health check to this ${nodeType} by going to Configs.`
        }}
      </p>
      <p
        v-else
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          textAlign: 'center',
          fontSize: '18px'
        }"
      >
        {{
          `Sorry, but there is no ${healthCheckType} health checks for this yet.`
        }}
      </p>
      <!-- Buttons -->
      <div style="margin-top: 20px; text-align: center">
        <button
          :class="
            isUserAdmin && isDarkModeToggleEnabled
              ? 'refresh-dark-mode'
              : 'refresh-light-mode'
          "
          @click="refresh"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            >mdi-refresh
          </v-icon>
          <!-- Refresh icon -->
          Refresh
        </button>
        <button
          v-if="isNodeConfigsAvailable"
          :class="
            isUserAdmin && isDarkModeToggleEnabled
              ? 'config-dark-mode'
              : 'config-light-mode'
          "
          @click="goConfig"
          style="margin-left: 10px"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            mdi-cog
          </v-icon>
          <!-- Configs button -->
          Configs
        </button>
        <button
          v-else
          :class="'goback-light-mode'"
          @click="goBack"
          style="margin-left: 10px"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            mdi-arrow-left
          </v-icon>
          <!-- Go back button -->
          Go Back
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const namespaceUser = { namespace: 'user' }
import Header from '../Header.vue'
import router from '@/router'

const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Header
  }
})
export default class NoHealthChecks extends Vue {
  @Prop({ default: 'Camera' }) nodeType: string
  @Prop({ default: 'incident' }) healthCheckType: string

  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserWorker() {
    return this.currentUser?.role === 'Worker'
  }

  public get isNodeConfigsAvailable() {
    return (
      this.nodeType === 'Client' ||
      this.nodeType === 'Site' ||
      this.nodeType === 'Camera'
    )
  }

  public goBack() {
    this.$router.go(-1)
  }
  //refresh page
  public refresh() {
    window.location.reload()
  }
  //go back
  public goConfig() {
    // Get the current URL
    const currentUrl = this.$router.currentRoute.fullPath

    // Replace "live" with "configurations"
    const newUrl = currentUrl.replace('/health-check/', '/configurations/')

    // Navigate to the new URL
    router.push(newUrl)
  }
}
</script>

<style scoped>
.refresh-light-mode {
  color: black;
  border-radius: 3px;
  border: 1px solid #ffd42a;
  background: #fff;
  padding: 5px 16px; /* Adjust padding as needed */
}

.refresh-dark-mode {
  color: white;
  border-radius: 3px;
  border: 2px solid #ffd42a;
  background: #333333;
  padding: 5px 16px; /* Adjust padding as needed */
}

.config-light-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.config-dark-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.goback-light-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.image {
  padding: 0%;
}
</style>
