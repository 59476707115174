<template>
  <v-row class="d-flex justify-center col-xl-12">
    <div
      v-for="(value, key) in usageMetricsData"
      :key="key"
      class="usage-summary"
    >
      <v-col
        :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
          isUserAdmin ? 'col-lg-12' : 'col-lg-3'
        } col-xl-2`"
      >
        <p class="text-md-body-1 text-body-2">
          {{ `${value.type}` }}
        </p>
        <div v-if="usageMetricsStatus === 'Loading'" class="loader-wrapper">
          <v-progress-circular
            indeterminate
            color="secondary"
            size="15"
          ></v-progress-circular>
        </div>
        <p v-else class="bold-text text-md-h5 text-xl-h4 text-h6">
          {{ value.count }}
        </p>
      </v-col>
    </div>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }
const namespaceUser = { namespace: 'user' }

@Component
export default class UsageSummaryBlock extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getUsageMetricsData', namespaceStatistics)
  public usageMetricsData: any
  @Getter('getUsageMetricsStatus', namespaceStatistics)
  public usageMetricsStatus: string

  @Action('fetchUsageMetrics', namespaceStatistics) fetchUsageMetrics

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  public fetchChartData = debounce(async () => {
    await this.fetchUsageMetrics({
      startTime: this.startTime,
      endTime: this.endTime
    })
  }, 300)
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}
.usage-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}
</style>
