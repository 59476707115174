import {
  cameraCollection,
  clientCollection,
  siteCollection
} from '@/provider/firebase'
import { set } from 'vue'
import { generateUniqueId } from './Generator'
import { getNodeActiveStatus, getNodeUnarmedStatus } from './Utils'
import EventBus from './EventBus'
import { ListnerParams } from '@/store/modules/nodes/definitions'
import _ from 'lodash'

export enum NodeType {
  CLIENT = 'client',
  SITE = 'site',
  CAMERA = 'camera',
  CUSTOMER = 'customer'
}
export enum NodeOperation {
  ADD = 'added',
  MODIFY = 'modified',
  REMOVE = 'removed'
}
type LoadMore = {
  id: string
  key: string
  name: string
  page: number
  type: string
  nodeType: string
  userFirestoreId?: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  cameraFirestoreId?: string
  clientDocId?: string
  siteDocId?: string
  cameraDocId?: string
  meta?: Meta
}
type AddNode = {
  id: string
  key: string
  name: string
  type: string
  nodeType: string
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  clientId?: string
  siteId?: string
}
export interface BaseNodeFormatParams {
  data: any
  userId: string
  isAdmin?: boolean
}
interface NodeFormatParams extends BaseNodeFormatParams {
  page: number
  size: number
  isUserCustomer: boolean
  isWriteEnabled: boolean
  meta?: Meta
  loadMoreMeta?: any
}
interface SiteFormatParams
  extends Omit<NodeFormatParams, 'isUserCustomer' | 'isWriteEnabled'> {
  client: any
}
interface CameraFormatParams extends SiteFormatParams {
  site: any
}
export type Meta = {
  search: boolean
  level: string
}
type LoadMoreParams = {
  page: number
  type: NodeType
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  cameraFirestoreId?: string
  clientDocId?: string
  siteDocId?: string
  cameraDocId?: string
  meta?: Meta
}
type AddNodeParams = {
  type: NodeType
  userFirestoreId: string
  clientFirestoreId?: string
  siteFirestoreId?: string
  clientDocId?: string
  siteDocId?: string
  cameraDocId?: string
  clientId?: string
  siteId?: string
  meta?: Meta
}
const getLoadMoreEntry = (params: LoadMoreParams) => {
  const {
    page,
    type,
    userFirestoreId,
    clientFirestoreId,
    siteFirestoreId,
    cameraFirestoreId,
    clientDocId,
    siteDocId,
    cameraDocId,
    meta
  } = params
  const uniqueKey = generateUniqueId()
  let id: string

  const loadMore: LoadMore = {
    id: '',
    key: '',
    name: 'See more',
    type,
    userFirestoreId: userFirestoreId,
    page,
    nodeType: 'load',
    meta
  }
  switch (type) {
    case NodeType.CUSTOMER:
      id = userFirestoreId
      break
    case NodeType.CLIENT:
      id = clientDocId || clientFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      loadMore.clientDocId = clientDocId
      break
    case NodeType.SITE:
      id = siteDocId || siteFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      loadMore.siteFirestoreId = siteFirestoreId
      loadMore.clientDocId = clientDocId
      loadMore.siteDocId = siteDocId
      break
    case NodeType.CAMERA:
      id = cameraDocId || cameraFirestoreId
      loadMore.clientFirestoreId = clientFirestoreId
      loadMore.siteFirestoreId = siteFirestoreId
      loadMore.cameraFirestoreId = cameraFirestoreId
      loadMore.clientDocId = clientDocId
      loadMore.siteDocId = siteDocId
      loadMore.cameraDocId = cameraDocId
      break
  }
  loadMore.id = `${uniqueKey}-${type}-load-${id}`
  loadMore.key = `${uniqueKey}-${type}-load-${id}`
  return loadMore
}
const getAddNodeEntry = (params: AddNodeParams) => {
  const {
    type,
    userFirestoreId,
    clientFirestoreId,
    siteFirestoreId,
    clientId,
    siteId,
    clientDocId,
    siteDocId
  } = params
  const uniqueKey = generateUniqueId()
  const addNew: AddNode = {
    id: '',
    key: '',
    name: `Add ${type.charAt(0).toUpperCase() + type.slice(1)}`,
    type,
    userFirestoreId: userFirestoreId,
    nodeType: 'add'
  }
  switch (type) {
    case NodeType.CLIENT:
      addNew.id = `${uniqueKey}-add-${type}-${userFirestoreId}`
      addNew.key = `${uniqueKey}-add-${type}-${userFirestoreId}`
      break
    case NodeType.SITE:
      addNew.id = `${uniqueKey}-add-${type}-${clientDocId}`
      addNew.key = `${uniqueKey}-add-${type}-${clientDocId}`
      addNew.clientFirestoreId = clientFirestoreId
      addNew.clientId = clientId
      break
    case NodeType.CAMERA:
      addNew.id = `${uniqueKey}-add-${type}-${siteDocId}-${clientDocId}`
      addNew.key = `${uniqueKey}-add-${type}-${siteDocId}-${clientDocId}`
      addNew.clientFirestoreId = clientFirestoreId
      addNew.siteFirestoreId = siteFirestoreId
      addNew.clientId = clientId
      addNew.siteId = siteId
      break
  }
  return addNew
}
const getTotalPages = (size: number, total: number) => {
  return Math.ceil(total / size)
}
export const formatCameras = (params: CameraFormatParams, isFresh = false) => {
  const { data, site, client, userId, size, page, isAdmin, loadMoreMeta } =
    params
  const metaData =
    loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CAMERA
      ? loadMoreMeta?.meta
      : {
          level: NodeType.CAMERA,
          search: data.data?.[0]?.meta?.level === NodeType.CAMERA
        }
  const uniqueId = generateUniqueId()
  const cameras = data.data.map((camera) => {
    return {
      ...camera,
      id: camera?.id || camera?._id,
      key: `${uniqueId}-${camera?._id}`,
      isWriteEnabled: isAdmin || camera.writeUsers.includes(userId),
      isNodeArmed: getNodeUnarmedStatus(client, site, camera),
      isActive: getNodeActiveStatus(
        camera.lastPingAt,
        camera.isEdgeDeviceEnabled,
        true,
        camera.isAuthenticated
      ),
      isHardwareDevice: site.isHardwareDevice,
      siteId: site.siteId,
      clientId: client.clientId,
      siteFirestoreId: site.id,
      clientFirestoreId: client.id,
      userFirestoreId: userId
    }
  })
  if (site.isWriteEnabled && (isFresh || page === 1)) {
    cameras.push(
      getAddNodeEntry({
        type: NodeType.CAMERA,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientId: client.clientId,
        clientDocId: client?._id,
        siteFirestoreId: site.id,
        siteDocId: site._id,
        siteId: site.siteId
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    cameras.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CAMERA,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientDocId: client?._id,
        siteFirestoreId: site.id,
        siteDocId: site?._id,
        cameraFirestoreId: cameras?.[0]?.id,
        cameraDocId: cameras?.[0]?._id,
        meta: metaData
      })
    )
  }

  return cameras
}
export const formatSites = (params: SiteFormatParams, isFresh = false) => {
  const { data, client, userId, isAdmin, page, size, loadMoreMeta } = params
  const metaData =
    loadMoreMeta?.meta && loadMoreMeta.type === NodeType.SITE
      ? loadMoreMeta?.meta
      : {
          level: NodeType.SITE,
          search: data.data?.[0]?.meta?.level === NodeType.SITE
        }
  const uniqueId = generateUniqueId()
  const sites = data.data.map((site) => {
    const isWriteEnabled = isAdmin || site.writeUsers.includes(userId)
    return {
      ...site,
      key: `${uniqueId}-${site._id}`,
      id: site?.id || site?._id,
      isWriteEnabled,
      isNodeArmed: getNodeUnarmedStatus(client, site),
      isActive: getNodeActiveStatus(site.lastPingAt, site.isHardwareDevice),
      clientId: client.clientId,
      clientFirestoreId: client.id,
      userFirestoreId: userId,
      children: formatCameras(
        {
          data: { data: site.children, total: site.total, meta: loadMoreMeta },
          client,
          site: { ...site, isWriteEnabled },
          userId,
          size,
          page,
          isAdmin,
          loadMoreMeta
        },
        isFresh
      )
    }
  })
  if (client.isWriteEnabled && page === 1) {
    sites.push(
      getAddNodeEntry({
        type: NodeType.SITE,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientId: client.clientId,
        clientDocId: client?._id
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    sites.push(
      getLoadMoreEntry({
        page,
        type: NodeType.SITE,
        userFirestoreId: userId,
        clientFirestoreId: client.id,
        clientDocId: client._id,
        meta: metaData
      })
    )
  }

  return sites
}
const formatClients = (params: NodeFormatParams) => {
  const {
    data,
    page,
    size,
    userId,
    isAdmin,
    isWriteEnabled,
    isUserCustomer,
    loadMoreMeta
  } = params
  const uniqueId = generateUniqueId()
  const clients = data.data.map((client) => {
    const isWriteEnabled = isAdmin || client.writeUsers.includes(userId)
    return {
      ...client,
      id: client?.id || client?._id,
      key: `${uniqueId}-${client._id}`,
      isNodeArmed: getNodeUnarmedStatus(client),
      isWriteEnabled,
      isDefault: client.name === 'Default',
      userFirestoreId: userId,
      children: formatSites({
        data: { data: client.children, total: client.total },
        client: { ...client, isWriteEnabled },
        userId,
        size,
        page: 1,
        isAdmin,
        loadMoreMeta
      })
    }
  })
  if ((isAdmin || isUserCustomer || isWriteEnabled) && page === 1) {
    clients.push(
      getAddNodeEntry({
        type: NodeType.CLIENT,
        userFirestoreId: userId,
        clientFirestoreId: clients?.[0]?.id,
        clientDocId: clients?.[0]?._id
      })
    )
  }
  if (page < getTotalPages(size, data.total)) {
    const meta =
      loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CLIENT
        ? loadMoreMeta?.meta
        : clients?.[0]?.meta
    clients.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CLIENT,
        userFirestoreId: userId,
        clientFirestoreId: clients?.[0]?.id,
        clientDocId: clients?.[0]?._id,
        meta
      })
    )
  }

  return clients
}
const formatUsers = (params: NodeFormatParams) => {
  const { data, page, size, isUserCustomer, isWriteEnabled, loadMoreMeta } =
    params
  const uniqueId = generateUniqueId()
  const users = data.data.map((user) => {
    return {
      ...user,
      key: `${uniqueId}-${user.id}`,
      isNodeArmed: true,
      userFirestoreId: user.id,
      children: formatClients({
        data: { data: user.children, total: user.total },
        size,
        page: 1,
        userId: user.id,
        isAdmin: true,
        isUserCustomer,
        isWriteEnabled,
        loadMoreMeta
      })
    }
  })
  if (page < getTotalPages(size, data.total)) {
    const meta =
      loadMoreMeta?.meta && loadMoreMeta.type === NodeType.CUSTOMER
        ? loadMoreMeta?.meta
        : users?.[0]?.meta
    users.push(
      getLoadMoreEntry({
        page,
        type: NodeType.CUSTOMER,
        userFirestoreId: users?.[0]?.id,
        meta
      })
    )
  }
  return users
}
export const formatNodeStructure = (params: NodeFormatParams) => {
  const { isAdmin, userId } = params
  if (isAdmin && !userId) {
    return formatUsers(params)
  }
  return formatClients(params)
}

export const removeLoadMoreEntry = (list: any[], loadMoreId: string) => {
  const index = list.findIndex((item) => item.id === loadMoreId)
  if (index > -1) {
    list.splice(index, 1)
  }
}

export const swapAddNewNodeEntry = (list: any[]) => {
  const index = list.findIndex((item) => item.nodeType === 'add')
  if (index > -1) {
    const item = list.splice(index, 1)[0]
    if (list[list.length - 1]?.nodeType === 'load') {
      list.splice(list.length - 1, 0, item)
    } else {
      list.push(item)
    }
  }
}
export const addNewNodeEntryToTree = (list: any[], node: any) => {
  const index = list.findIndex((item) => item.name === 'Default')
  if (index > -1) {
    list.splice(index + 1, 0, node)
  } else {
    list.unshift(node)
  }
}
export const updateNodeEntryInTree = (list: any[], node: any) => {
  const index = list.findIndex((item) => item.id === node.id)
  if (index > -1) {
    list[index] = node
  }
}
export const removeNodeEntryInTree = (list: any[], node: any) => {
  const index = list.findIndex((item) => item.id === node.id)
  if (index > -1) {
    list.splice(index, 1)
  }
}
export const savePaginatedNodesToTheRightPlace = (
  allNodes: any[],
  { userId, siteId, clientId, loadMoreId, data, isAdmin, page, size, type }
) => {
  let clientList = allNodes
  let userIndex
  if (isAdmin) {
    userIndex = allNodes.findIndex((item) => item.id === userId)
    if (userIndex > -1) {
      clientList = allNodes[userIndex].children
    }
  }
  if (clientId) {
    const clientIndex = clientList.findIndex((item) => item._id === clientId)
    const siteList = clientList[clientIndex].children
    if (siteId && type === NodeType.CAMERA) {
      const siteIndex = siteList.findIndex((item) => item._id === siteId)
      const cameraList = siteList[siteIndex].children
      removeLoadMoreEntry(cameraList, loadMoreId)
      const formattedData = formatCameras({
        client: clientList[clientIndex],
        site: siteList[siteIndex],
        data,
        page,
        size,
        userId,
        isAdmin
      })
      cameraList.push(...formattedData)
      swapAddNewNodeEntry(cameraList)
      siteList[siteIndex].children = cameraList
    } else if (type === NodeType.SITE) {
      removeLoadMoreEntry(siteList, loadMoreId)
      const formattedData = formatSites(
        {
          client: clientList[clientIndex],
          data,
          page,
          size,
          userId,
          isAdmin
        },
        true
      )
      siteList.push(...formattedData)
      swapAddNewNodeEntry(siteList)
    }
    clientList[clientIndex].children = siteList
    if (isAdmin && userIndex) {
      allNodes[userIndex].children = clientList
    } else {
      allNodes = clientList
    }
  }
}
const acknowledgeLowerNodesOfArmedStatus = (
  node: any,
  client?: any,
  site?: any
) => {
  if (node.type === NodeType.CAMERA) {
    return {
      ...node,
      isNodeArmed: getNodeUnarmedStatus(client, site, node)
    }
  }
  if (node.type === NodeType.SITE) {
    let children = node?.children
    if (children?.length > 0) {
      children = children.map((cameraItem) =>
        acknowledgeLowerNodesOfArmedStatus(cameraItem, client, node)
      )
    }
    return {
      ...node,
      isNodeArmed: getNodeUnarmedStatus(client, node),
      children
    }
  }
  if (node.type === NodeType.CLIENT) {
    let children = node?.children
    if (children?.length > 0) {
      children = children.map((siteItem) =>
        acknowledgeLowerNodesOfArmedStatus(siteItem, node)
      )
    }
    return {
      ...node,
      children
    }
  }
}
const restructureClient = (params: any, operation: NodeOperation) => {
  const { isAdmin, change, userId, oldValue } = params
  const isWriteEnabled = isAdmin || change.writeUsers.includes(userId)
  let children = []
  if (operation === NodeOperation.MODIFY) {
    children = oldValue.children
  }
  if (operation === NodeOperation.ADD && isWriteEnabled) {
    children.push(
      getAddNodeEntry({
        type: NodeType.SITE,
        userFirestoreId: userId,
        clientFirestoreId: change.id,
        clientId: change.clientId,
        clientDocId: change.id
      })
    )
  }
  const isNodeArmed = getNodeUnarmedStatus(change)
  const uniqueId = generateUniqueId()
  const modifiedClient = {
    _id: change.id,
    key: `${uniqueId}-${change.id}`,
    ...oldValue,
    ...change,
    isNodeArmed,
    isWriteEnabled,
    isDefault: change.name === 'Default',
    userFirestoreId: userId,
    total: 0,
    type: NodeType.CLIENT,
    children
  }
  if (oldValue.isNodeArmed !== isNodeArmed) {
    return acknowledgeLowerNodesOfArmedStatus(modifiedClient)
  }
  return modifiedClient
}
const restructureSite = (params: any, operation: NodeOperation) => {
  const { isAdmin, change, client, userId, oldValue } = params
  const isWriteEnabled = isAdmin || change.writeUsers.includes(userId)
  let children = []
  if (operation === NodeOperation.MODIFY) {
    children = oldValue.children
  }
  const uniqueId = generateUniqueId()
  if (operation === NodeOperation.ADD && isWriteEnabled) {
    // TODO: fix move added site to before 'add site' button
    const addSiteEntry = getAddNodeEntry({
      type: NodeType.CAMERA,
      userFirestoreId: userId,
      clientFirestoreId: client.id,
      clientId: client.clientId,
      siteFirestoreId: change.id,
      siteId: change.siteId,
      siteDocId: change.id,
      clientDocId: client?._id || client?.id
    })
    addNewNodeEntryToTree(children, addSiteEntry)
  }
  const isNodeArmed = getNodeUnarmedStatus(client, change)
  const modifiedSite = {
    ...oldValue,
    ...change,
    _id: change.id,
    clientFirestoreId: client.id,
    clientId: client.clientId,
    key: `${uniqueId}-${change.id}`,
    type: NodeType.SITE,
    writeUsers: change.writeUsers,
    userFirestoreId: userId,
    name: change.name,
    users: change.users,
    unarmedTimeRange: change?.unarmedTimeRange,
    blockToggles: change?.blockToggles,
    isHardwareDevice: change?.isHardwareDevice,
    lastPingAt: change?.lastPingAt,
    createdAt: change.createdAt,
    isWriteEnabled,
    isNodeArmed,
    isActive: getNodeActiveStatus(change.lastPingAt, change.isHardwareDevice),
    children
  }
  if (oldValue.isNodeArmed !== isNodeArmed) {
    return acknowledgeLowerNodesOfArmedStatus(modifiedSite, client)
  }
  return modifiedSite
}
const restructureCamera = (params: any, operation: NodeOperation) => {
  const { isAdmin, change, site, client, userId, oldValue } = params
  let children = []
  if (operation === NodeOperation.MODIFY) {
    children = oldValue.children
  }
  const uniqueId = generateUniqueId()
  return {
    ...oldValue,
    ...change,
    key: `${uniqueId}-${change.id}`,
    _id: change.id,
    isWriteEnabled: isAdmin || change.writeUsers.includes(userId),
    type: NodeType.CAMERA,
    name: change.name,
    id: change.id,
    isNodeArmed: getNodeUnarmedStatus(client, site, change),
    isActive: getNodeActiveStatus(
      change.lastPingAt,
      change.isEdgeDeviceEnabled,
      true,
      change.isAuthenticated
    ),
    isHardwareDevice: site.isHardwareDevice,
    siteId: site.siteId,
    clientId: client.clientId,
    siteFirestoreId: site.id,
    clientFirestoreId: client.id,
    userFirestoreId: userId,
    children
  }
}
const getSitesAndIndexeByClientRef = (change: any, nodes: any) => {
  const clientRef = change?.client
  if (clientRef) {
    const clientDocId = clientRef?.id
    if (clientDocId) {
      const clientIndex = nodes.findIndex((item) => item.id === clientDocId)
      const sites = nodes?.[clientIndex]?.children
      return { clientIndex, sites }
    }
  }
  return { clientIndex: -1, sites: null }
}
const getCamerasAndIndexeBySiteRef = (change: any, sites: any) => {
  const siteRef = change?.site
  if (siteRef) {
    const siteDocId = siteRef?.id
    if (siteDocId) {
      const siteIndex = sites.findIndex((item) => item.id === siteDocId)
      const cameras = sites[siteIndex].children
      return { siteIndex, cameras }
    }
  }
  return { siteIndex: -1, cameras: null }
}
const updateCreatedNodeInfo = (
  { state, commit }: any,
  type: NodeType,
  modifiedItem: any
) => {
  if (
    state?.createNodeStatus?.status === 'added-waiting' &&
    state?.createNodeStatus?.type === type
  ) {
    const createNodeStatus = { ...state.createNodeStatus }
    set(createNodeStatus, 'node', modifiedItem)
    commit('setCreateNodeStatus', createNodeStatus)
  }
}
export const nodeAddOperator = async (type: NodeType, params: any) => {
  const { data, change, isAdmin, userId } = params
  let userIndex
  Object.freeze(data)
  let modifiedItem
  let nodes = [...data]
  if (isAdmin && userId !== '') {
    userIndex = data.findIndex((item) => item.id === userId)
    if (userIndex > -1) {
      nodes = data[userIndex]?.children
    }
  }
  if (type === NodeType.CLIENT) {
    modifiedItem = restructureClient(
      { isAdmin, userId, change, oldValue: {} },
      NodeOperation.ADD
    )
    addNewNodeEntryToTree(nodes, modifiedItem)
  }
  if (type === NodeType.SITE) {
    const { clientIndex, sites } = getSitesAndIndexeByClientRef(change, nodes)
    if (clientIndex > -1) {
      modifiedItem = restructureSite(
        { isAdmin, userId, change, client: nodes[clientIndex], oldValue: {} },
        NodeOperation.ADD
      )
      addNewNodeEntryToTree(sites, modifiedItem)
      nodes[clientIndex].children = sites
    }
  }
  if (type === NodeType.CAMERA) {
    const siteRef = change?.site
    if (siteRef) {
      const siteData = await siteRef.get()
      const siteNode = siteData.data()
      const { clientIndex, sites } = getSitesAndIndexeByClientRef(
        siteNode,
        nodes
      )
      if (clientIndex > -1) {
        const { siteIndex, cameras } = getCamerasAndIndexeBySiteRef(
          change,
          sites
        )
        if (siteIndex > -1) {
          modifiedItem = restructureCamera(
            {
              isAdmin,
              userId,
              change,
              site: sites[siteIndex],
              client: nodes[clientIndex],
              oldValue: {}
            },
            NodeOperation.ADD
          )
          addNewNodeEntryToTree(cameras, modifiedItem)
          nodes[clientIndex].children = sites
        }
      }
    }
  }

  if (isAdmin && userIndex > -1) {
    data[userIndex] = nodes
    return { modifiedList: data, modifiedItem }
  } else {
    return { modifiedList: nodes, modifiedItem }
  }
}
export const nodeModifyOperator = async (type: NodeType, params: any) => {
  const { data, change, isAdmin, userId } = params
  let userIndex
  Object.freeze(data)
  let nodes = [...data]
  if (isAdmin && userId !== '') {
    userIndex = data.findIndex((item) => item.id === userId)
    if (userIndex > -1) {
      nodes = data[userIndex]?.children
    }
  }

  if (type === NodeType.CLIENT) {
    const clientIndex = data.findIndex((item) => item.id === change.id)
    if (clientIndex > -1) {
      const oldValue = _.cloneDeep(nodes[clientIndex])
      const modifiedClient = restructureClient(
        {
          isAdmin,
          userId,
          change,
          oldValue
        },
        NodeOperation.MODIFY
      )
      nodes[clientIndex] = modifiedClient
      // set(nodes, clientIndex, modifiedClient)
    }
  }
  if (type === NodeType.SITE) {
    const { clientIndex, sites } = getSitesAndIndexeByClientRef(change, nodes)
    if (clientIndex > -1) {
      const client = nodes[clientIndex]
      const siteIndex = sites.findIndex((item) => item.id === change.id)
      if (siteIndex > -1) {
        // const oldValue = sites[siteIndex]
        const oldValue = _.cloneDeep(sites[siteIndex])
        const modifiedSite = restructureSite(
          {
            isAdmin,
            userId,
            change,
            client,
            oldValue
          },
          NodeOperation.MODIFY
        )
        set(nodes[clientIndex].children, siteIndex, modifiedSite)
      }
    }
  }
  if (type === NodeType.CAMERA) {
    const siteRef = change?.site
    if (siteRef) {
      const siteData = await siteRef.get()
      const siteNode = siteData.data()
      const { clientIndex, sites } = getSitesAndIndexeByClientRef(
        siteNode,
        nodes
      )
      if (clientIndex > -1) {
        const { siteIndex, cameras } = getCamerasAndIndexeBySiteRef(
          change,
          sites
        )
        if (siteIndex > -1) {
          const cameraIndex = cameras.findIndex((item) => item.id === change.id)
          const oldValue = cameras[cameraIndex]
          const modifiedCamera = restructureCamera(
            {
              isAdmin,
              userId,
              change,
              client: nodes[clientIndex],
              site: sites[siteIndex],
              oldValue
            },
            NodeOperation.MODIFY
          )
          // set(cameras, cameraIndex, modifiedCamera)
          cameras[cameraIndex] = modifiedCamera
          nodes[clientIndex].children[siteIndex].children = cameras
        }
      }
    }
  }
  if (isAdmin && userIndex > -1) {
    data[userIndex] = nodes
    return data
  }
  return nodes
}
export const nodeDeleteOperator = async (type: NodeType, params: any) => {
  const { data, change, isAdmin, userId } = params
  let userIndex
  let nodes = [...data]
  if (isAdmin && userId !== '') {
    userIndex = data.findIndex((item) => item.id === userId)
    if (userIndex > -1) {
      nodes = data[userIndex]?.children
    }
  }
  if (type === NodeType.CLIENT) {
    removeNodeEntryInTree(nodes, change)
  }
  if (type === NodeType.SITE) {
    const { clientIndex, sites } = getSitesAndIndexeByClientRef(change, nodes)
    if (clientIndex > -1) {
      removeNodeEntryInTree(sites, change)
      nodes[clientIndex.children] = sites
    }
  }
  // if (type === NodeType.CAMERA) {
  //   const siteRef = change?.site
  //   if (siteRef) {
  //     const siteDocId = siteRef?.id
  //     const clientIndex = nodes.findIndex((item) =>
  //       item?.children?.some((siteItem) => siteItem?.id === siteDocId)
  //     )
  //     if (clientIndex > -1) {
  //       const sites = nodes[clientIndex].children
  //       const { siteIndex, cameras } = getCamerasAndIndexeBySiteRef(
  //         change,
  //         sites
  //       )
  //       if (siteIndex > -1) {
  //         removeNodeEntryInTree(cameras, change)
  //         nodes[clientIndex].children[siteIndex].children = cameras
  //       }
  //     }
  //     // }
  //   }
  // }
  if (type === NodeType.CAMERA) {
    const siteRef = change?.site
    if (siteRef) {
      const siteData = await siteRef.get()
      if (siteData.exists) {
        const siteNode = siteData.data()
        const { clientIndex, sites } = getSitesAndIndexeByClientRef(
          siteNode,
          nodes
        )
        if (clientIndex > -1) {
          const { siteIndex, cameras } = getCamerasAndIndexeBySiteRef(
            change,
            sites
          )
          if (siteIndex > -1) {
            removeNodeEntryInTree(cameras, change)
            nodes[clientIndex].children[siteIndex].children = cameras
          }
        }
      }
    }
  }
  if (isAdmin && userIndex > -1) {
    data[userIndex] = nodes
    return data
  } else {
    return nodes
  }
}
const getListnerQuery = async (params: ListnerParams, collection: any) => {
  const { userId, initialTimestamp } = params
  let query: any = collection
  if (userId && userId !== '') {
    query = query.where('users', 'array-contains', userId)
  }
  let initialDocCount = 0
  if (initialTimestamp) {
    const initialSnapshot = await query
      .where('createdAt', '<=', initialTimestamp)
      .get()
    initialDocCount = initialSnapshot.size
  }
  return { query, initialDocCount }
}

export const handleClientNodeListner = async (
  params: ListnerParams,
  { commit, state }: any
) => {
  const { isAdmin, userId, isUserCustomer } = params

  const { query: clientQuery, initialDocCount } = await getListnerQuery(
    params,
    clientCollection
  )
  let counter = 0
  const clientSubscription = clientQuery.onSnapshot((clientSnap) => {
    console.log('call of client listner')
    const allNodes = state.nodes
    // const allNodes = _.cloneDeep(state.nodes)
    clientSnap.docChanges().forEach(async (change) => {
      if (change.type === NodeOperation.ADD) {
        if (counter >= initialDocCount) {
          const { modifiedList, modifiedItem } = await nodeAddOperator(
            NodeType.CLIENT,
            {
              isAdmin,
              userId,
              change: { id: change.doc.id, ...change.doc.data() },
              data: allNodes,
              isUserCustomer
            }
          )
          commit('setNodes', modifiedList)
          updateCreatedNodeInfo(
            { state, commit },
            NodeType.CLIENT,
            modifiedItem
          )
        } else {
          counter++
        }
      }
      if (change.type === NodeOperation.MODIFY) {
        const modifiedList = await nodeModifyOperator(NodeType.CLIENT, {
          isAdmin,
          userId,
          change: { id: change.doc.id, ...change.doc.data() },
          data: allNodes,
          isUserCustomer
        })
        commit('setNodes', modifiedList)
      }
      if (change.type === NodeOperation.REMOVE) {
        const modifiedList = await nodeDeleteOperator(NodeType.CLIENT, {
          isAdmin,
          userId,
          change: { id: change.doc.id },
          data: allNodes
        })
        commit('setNodes', modifiedList)
      }
    })
  })
  return clientSubscription
}
export const handleSiteNodeListner = async (
  params: ListnerParams,
  { commit, state }: any
) => {
  const { isAdmin, userId, isUserCustomer } = params

  const { query: siteQuery, initialDocCount } = await getListnerQuery(
    params,
    siteCollection
  )
  let counter = 0
  const subscription = siteQuery.onSnapshot(async (siteSnap) => {
    console.log('call of site listner')
    const allNodes = state.nodes
    siteSnap.docChanges().forEach(async (change) => {
      if (change.type === NodeOperation.ADD) {
        if (counter >= initialDocCount) {
          const { modifiedList, modifiedItem } = await nodeAddOperator(
            NodeType.SITE,
            {
              isAdmin,
              userId,
              change: { id: change.doc.id, ...change.doc.data() },
              data: allNodes,
              isUserCustomer
            }
          )
          commit('setNodes', modifiedList)
          updateCreatedNodeInfo({ state, commit }, NodeType.SITE, modifiedItem)
        } else {
          counter++
        }
      }
      if (change.type === NodeOperation.MODIFY) {
        const modifiedList = await nodeModifyOperator(NodeType.SITE, {
          isAdmin,
          userId,
          change: { id: change.doc.id, ...change.doc.data() },
          data: allNodes
        })

        commit('setNodes', modifiedList)
      }
      if (change.type === NodeOperation.REMOVE) {
        const modifiedList = await nodeDeleteOperator(NodeType.SITE, {
          isAdmin,
          userId,
          change: { id: change.doc.id, ...change.doc.data() },
          data: allNodes
        })
        commit('setNodes', modifiedList)
        EventBus.$emit('tree-update')
      }
    })
  })
  return subscription
}
export const handleCameraNodeListner = async (
  params: ListnerParams,
  { commit, state }: any
) => {
  const { isAdmin, userId, isUserCustomer } = params
  const { query: cameraQuery, initialDocCount } = await getListnerQuery(
    params,
    cameraCollection
  )
  let counter = 0
  const subscription = cameraQuery.onSnapshot(async (cameraSnap) => {
    console.log('call of camera listner ')
    const allNodes = state.nodes
    cameraSnap.docChanges().forEach(async (change) => {
      if (change.type === NodeOperation.ADD) {
        if (counter >= initialDocCount) {
          const { modifiedList, modifiedItem } = await nodeAddOperator(
            NodeType.CAMERA,
            {
              isAdmin,
              userId,
              change: { id: change.doc.id, ...change.doc.data() },
              data: allNodes,
              isUserCustomer
            }
          )
          commit('setNodes', modifiedList)
          updateCreatedNodeInfo(
            { state, commit },
            NodeType.CAMERA,
            modifiedItem
          )
        } else {
          counter++
        }
      }
      if (change.type === NodeOperation.MODIFY) {
        const modifiedList = await nodeModifyOperator(NodeType.CAMERA, {
          isAdmin,
          userId,
          change: { id: change.doc.id, ...change.doc.data() },
          data: allNodes
        })

        commit('setNodes', modifiedList)
        // EventBus.$emit('tree-update')
      }
      if (change.type === NodeOperation.REMOVE) {
        const modifiedList = await nodeDeleteOperator(NodeType.CAMERA, {
          isAdmin,
          userId,
          change: { id: change.doc.id, ...change.doc.data() },
          data: allNodes
        })
        commit('setNodes', modifiedList)
        // EventBus.$emit('tree-update')
      }
    })
  })
  return subscription
}
// Function to create a counter that tracks listener triggers
// export const createFnCounter = (expectedCount: number, callback) => {
//   let count = 0
//   return (snapshot) => {
//     count += snapshot.docChanges().length
//     if (count >= expectedCount) {
//       // Initial payload is complete, start handling updates
//       callback(snapshot)
//     }
//   }
// }
