const getters = {
  todos: (state) => {
    return state.todos
  },
  todosWorkerSpace: (state) => {
    return state.todosWorkerSpace
  },
  getTodoById: (state) => (docId) => {
    return state.todos.filter((todo) => todo.id === docId)[0]
  },
  getFinalResultAssessment: (state) => {
    return state.finalAssessment
  },
  getTodosByCameraId: (state) => {
    return state.todosByCameraId
  },
  getDownloadUrl: (state) => {
    return state.downloadUrl
  },
  getUploadedTask: (state) => {
    return state.uploadedTask
  },
  getCurrentTodoCameraRef: (state) => {
    return state.currentTodoCameraRef
  },
  getLastTodoByCameraRef: (state) => {
    return state.lastTodoByCameraRef
  },
  getIncidentHealthChecks: (state) => {
    return state.incidentHealthChecks
  },
  getIncidentHealthChecksLoading: (state) => {
    return state.incidentHealthChecksLoading
  },
  getIncidentHcLoadMoreLoading: (state) => {
    return state.incidentHcLoadMoreLoading
  },
  getHasMoreIncidentHealthChecks: (state) => {
    return state.hasMoreIncidentHealthChecks
  }
}

export default {
  ...getters
}
