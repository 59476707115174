import { IRouteState, ISnapshotState } from './state'

const mutations = {
  setRoute(state: any, routeSnapshot: IRouteState) {
    state.route = routeSnapshot
  }
}

export default {
  ...mutations
}
