var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.getTotalAlarmQuotaPerMonth.isQuotaEnabled)?_c('v-row',{staticClass:"d-flex justify-space-between align-items-center justify-content-center"},[_c('v-col',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('span',{staticClass:"summary-title col-12"},[_vm._v("Monthly Quota Summary")]),_c('v-row',{staticClass:"d-flex justify-center col-xl-12"},[_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("Allocated Quota")]),_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(_vm.getTotalAlarmQuotaPerMonth.alarmQuota)+" ")])])],1),_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("Used Quota")]),_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(_vm.getTotalAlarmCountPerMonth)+" ")])])],1),_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("Used Quota Percentage")]),_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(( (_vm.getTotalAlarmCountPerMonth / _vm.getTotalAlarmQuotaPerMonth.alarmQuota) * 100 ).toFixed(2))+"% ")])])],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }