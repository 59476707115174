import { clientCollection, functions } from '@/provider/firebase'

export default {
  namespaced: true,
  async updateClientConfig(payload: {
    docId: string
    config: string
    value: string
  }) {
    const { docId, config, value } = payload
    // find the document with the docId
    // set if not exists; update if exists
    try {
      const client = await clientCollection.doc(docId).get()

      if (!client.exists) {
        return {
          status: 'error',
          message: 'Something went wrong or client does not exists'
        }
      } else {
        const clientData = client.data()
        const checkClientName = await clientCollection
          .where('name', '==', value)
          .where('belongsTo', '==', clientData.belongsTo)
          .get()

        const isClientNameExist =
          checkClientName.docs.length > 1 ||
          (checkClientName.docs.length === 1 &&
            checkClientName.docs[0]?.id !== docId)

        if (isClientNameExist) {
          return {
            status: 'error',
            message: 'Same client name exists in the same user!'
          }
        } else {
          await client.ref.update({
            [config]: value
          })
          return {
            status: 'success',
            message: 'Client configuration updated successfully!'
          }
        }
      }
    } catch (error) {
      return {
        status: 'error',
        message: error as string
      }
    }
  },
  async generateSiaReceiver(uid: string, data: any) {
    try {
      return functions.httpsCallable('generateSiaReceiver')({
        clientId: uid,
        data
      })
    } catch (error) {
      console.error('Error generating sia receiver', error)
    }
  }
}
