<template>
  <div
    style="padding-top: 15px"
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div v-if="isUserAdmin" data-test-id="approve-options-container">
      <v-card-title
        class="text-h6"
        style="font-weight: 700"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
      >
        Approve Options
      </v-card-title>
      <v-checkbox
        v-model="isApproved"
        label="Approve"
        color="secondary"
        class="pl-4 checkbox"
        @change="reportHandler('approval')"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
        :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
        data-test-id="approval-checkbox"
      >
      </v-checkbox>
    </div>

    <div data-test-id="report-options-container">
      <v-card-title
        class="text-h6"
        style="font-weight: 700"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
      >
        Report Options
      </v-card-title>

      <v-checkbox
        v-if="
          alarmDefinition === 'person' || alarmDefinition === 'person_vehicle'
        "
        v-model="isPersonReported"
        label="Person"
        class="pl-4 checkbox"
        @change="reportHandler('person')"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : 'black'
        }"
        :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
        data-test-id="person-checkbox"
      >
      </v-checkbox>
      <v-checkbox
        v-if="
          alarmDefinition === 'vehicle' || alarmDefinition === 'person_vehicle'
        "
        v-model="isVehicleReported"
        label="Vehicle Movement"
        color="secondary"
        class="pl-4 checkbox"
        @change="reportHandler('vehicle')"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
        :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
        data-test-id="vehicle-checkbox"
      >
      </v-checkbox>
    </div>

    <div v-if="isUserAdmin" data-test-id="unsure-options-container">
      <v-card-title
        class="text-h6"
        style="font-weight: 700"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
      >
        Unsure Options
      </v-card-title>
      <v-checkbox
        v-model="isUnsure"
        label="Unsure"
        color="secondary"
        class="pl-4 checkbox"
        @change="reportHandler('unsure')"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
        :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
        data-test-id="unsure-checkbox"
      >
      </v-checkbox>
    </div>

    <v-card-actions
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <Button
        btnText="Close"
        btnStyle="outlined"
        @onButtonClick="setActionDialog(false)"
        style="border: 1px solid #ffd42a"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : ''
        }"
        class="close-btn"
        data-test-id="close-button"
      />
      <Button
        btnText="Save"
        @onButtonClick="onSave"
        data-test-id="save-button"
      />
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { userCollection } from '@/provider/firebase/index'
import Button from '@/components/app/Button.vue'

const namespaceTodos = { namespace: 'todos' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Button }
})
export default class ReportButtonHandlerAnalytics extends Vue {
  @Action('reportTodo', namespaceTodos) public reportTodo: ({}) => void
  @Action('getTodoById', namespaceTodos) public getTodoById: ({}) => void

  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any

  @Prop() public todoId!: string
  @Prop() public alarmDefinition!: string
  @Prop() public setActionDialog: (item: any) => void

  public isPersonReported: boolean = false
  public isVehicleReported: boolean = false
  public isApproved: boolean = false
  public isUnsure: boolean = false
  public isUserAdmin: boolean = false

  private currentTodo: any

  private async mounted() {
    this.isUserAdmin = this.currentUser.role == 'Administrator'

    this.currentTodo = await this.getTodoById(this.todoId)

    this.isPersonReported = this.currentTodo?.report?.person ? true : false
    this.isVehicleReported = this.currentTodo?.report?.vehicle ? true : false
    this.isApproved = this.currentTodo?.report?.approved ? true : false
    this.isUnsure = this.currentTodo?.report?.unsure ? true : false
  }

  private approvedHandler(isAnApprovalEvent: boolean) {
    if (!isAnApprovalEvent) {
      if (this.isPersonReported || this.isVehicleReported || this.isUnsure) {
        this.isApproved = false
      } else {
        this.isApproved = this.isUserAdmin ? true : false
      }
    } else {
      this.isPersonReported = false
      this.isVehicleReported = false
      this.isUnsure = false
    }
  }

  private unsureHandler(isAnUnsureEvent: boolean) {
    if (isAnUnsureEvent) {
      this.isPersonReported = false
      this.isVehicleReported = false
      if (this.isUnsure) {
        this.isApproved = false
      }
    } else {
      if (this.isPersonReported || this.isVehicleReported) {
        this.isUnsure = false
      }
    }
  }

  public reportHandler(reportClass: string) {
    if (reportClass === 'approval') {
      this.approvedHandler(true)
    }
    if (reportClass === 'person' || reportClass === 'vehicle') {
      this.approvedHandler(false)
      this.unsureHandler(false)
    }
    if (reportClass === 'unsure') {
      this.approvedHandler(false)
      this.unsureHandler(true)
    }
  }

  public async onSave() {
    this.reportTodo({
      todoId: this.todoId,
      person: this.isPersonReported,
      vehicle: this.isVehicleReported,
      approved: this.isApproved,
      unsure: this.isUnsure,
      lastUpdated: new Date(),
      user: userCollection.doc(this.currentUser?.id)
    })

    this.setActionDialog(false)
  }
}
</script>

<style scoped>
.checkbox {
  margin-top: -10px;
}

::v-deep .checkbox .v-label,
.v-subheader {
  font-size: 16px !important;
  font-weight: 500;
}

.close-btn:hover {
  background-color: transparent !important;
}
</style>
