var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"card-style notification-data d-flex justify-between align-center pb-0 pt-0"},[_c('v-col',[_c('v-row',{staticClass:"card-style notification-data d-flex justify-between align-center pb-0 pt-0",class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? 'notification-data-admin'
          : 'notification-data'},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticStyle:{"text-align":"left","margin":"0px"},style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v(" Notification Type ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"text-caption",attrs:{"items":_vm.notificationTypes,"item-text":"name","item-value":"value","disabled":!_vm.isWriteEnabled,"outlined":"","dense":"","append-icon":"mdi-chevron-down","content-class":"elevation-0","placeholder":"Notification Type","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"hide-details":"true"},on:{"change":_vm.onNotificationTypeChange},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.localNotificationType),callback:function ($$v) {_vm.localNotificationType=$$v},expression:"localNotificationType"}})],1)],1),_c('v-row',{staticClass:"card-style notification-data d-flex justify-between align-center pb-0 pt-0",class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? 'notification-data-admin'
          : 'notification-data'},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticStyle:{"text-align":"left","margin":"0px"},style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          })},[_vm._v(" Inherit Alarm Notification Data ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-end align-center"},[_c('v-tooltip',{attrs:{"left":"","open-on-click":false,"open-on-focus":false,"nudge-left":"6","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.darkPrimaryColor
                }),attrs:{"size":"15"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant-circle ")])]}}])},[_c('span',{staticClass:"mx-2 white--text"},[_vm._v("ON: health check notification configs are inherited by alarm notification configs"),_c('br'),_vm._v(" OFF: health check notification configs use its own data")])]),_c('v-switch',{staticClass:"pl-2",attrs:{"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"color":"secondary","inset":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"small":true},on:{"change":_vm.toggleNotificationInheritSwitch},model:{value:(_vm.isNotificationInheritToggleEnabled),callback:function ($$v) {_vm.isNotificationInheritToggleEnabled=$$v},expression:"isNotificationInheritToggleEnabled"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }