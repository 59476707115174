var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ]},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack,
        margin: '1rem 0 1rem 0'
      }),attrs:{"data-test-id":"eagle-eye-title"}},[_vm._v(" Eagle Eye ")]),(!_vm.isCameraLevel)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"1rem"}},[(!_vm.getEagleEyeConnect.progress && _vm.eenClientId)?_c('Button',{class:['elevation-0'],staticStyle:{"width":"8rem"},attrs:{"disabled":!_vm.isWriteEnabled,"color":"secondary black--text elevation-0","btnText":_vm.isConnectedToEagleEye ? 'Reconnect' : 'Connect',"title":'Connect to Eagle Eye',"data-test-id":"eagle-eye-connect-button"},on:{"onButtonClick":_vm.onClickConnectEagleEye}},[_c('v-icon',{attrs:{"slot":"icon"},slot:"icon"},[_vm._v(" mdi-plus-circle-outline ")])],1):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24","color":"primary"}})],1):_vm._e()]),_c('div',[(_vm.getEagleEyeConnect.message)?_c('div',{staticClass:"py-5 bottom-border",style:({ color: _vm.getEagleEyeConnect.error ? 'red' : 'green' }),attrs:{"data-test-id":"eagle-eye-connect-message"}},[_vm._v(" "+_vm._s(_vm.getEagleEyeConnect.message)+" ")]):_vm._e(),(_vm.isConnectedToEagleEye)?[_c('div',_vm._l((_vm.filteredEagleEyeAccounts),function(account,index){return _c('div',{key:index},[(_vm.isAboutToExpire(account.expires))?_c('v-alert',{staticStyle:{"margin":"1rem 1rem 0 1rem","font-size":"small"},attrs:{"icon":"$error","type":"error","density":"compact","title":`Eagle Eye account ${account.vms_account} expires on ${account.expires}`,"data-test-id":"eagle-eye-account-alert"}},[_vm._v(_vm._s(_vm.expireMessage(account.vms_account, account.expires)))]):_vm._e(),_c('div',{staticClass:"py-5 pl-4 d-flex pr-3 bottom-border"},[_c('div',{staticClass:"d-flex align-start flex-column",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlackColor,
                flex: 1
              }),attrs:{"data-test-id":"eagle-eye-account-details-div"}},[_c('p',{staticClass:"mb-2",attrs:{"data-test-id":"eagle-eye-account-name-lable"}},[_vm._v(" Name: "+_vm._s(account.vms_account)+" ")]),_c('p',{attrs:{"data-test-id":"eagle-eye-account-vms-label"}},[_vm._v(" VMs Account: "+_vm._s(account.vms_account)+" ")]),_c('p',{attrs:{"data-test-id":"eagle-eye-account-expire-label"}},[_vm._v(" Expires: "),_c('span',{style:({
                    color: account?.isTokenValid === false ? 'red' : ''
                  })},[_vm._v(" "+_vm._s(account?.isTokenValid === false ? 'Expired' : new Date(account.expires).toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', minute: '2-digit', hour: '2-digit', second: '2-digit' }))+" ")])])]),_c('div',{staticClass:"d-flex align-end flex-column"},[(
                  account?.isTokenValid === undefined || account?.isTokenValid
                )?_c('Button',{staticClass:"elevation-0 mb-2",attrs:{"btnText":_vm.isCameraLevel ? 'Refresh Camera' : 'Refresh Cameras',"btnStyle":"default","disabled":!_vm.isWriteEnabled,"data-test-id":"eagle-eye-refresh-button"},on:{"onButtonClick":function($event){return _vm.onClickRefreshEENCameras(account)}}}):_vm._e(),(!_vm.isCameraLevel)?_c('Button',{staticClass:"elevation-0",attrs:{"btnText":"Disconnect","btnStyle":"delete","disabled":!_vm.isWriteEnabled,"data-test-id":"eagle-eye-disconnect-button"},on:{"onButtonClick":function($event){return _vm.deleteEENAccount(account.vms_account)}}}):_vm._e()],1)])],1)}),0)]:_vm._e()],2),(_vm.getEagleEyeConnect.progress)?_c('v-overlay',{attrs:{"absolute":false}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }