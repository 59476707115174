import { NodeType } from '@/utils/NodeHelper'
import { Timestamp } from 'firebase-admin/firestore'

export enum CreateNodeMetaDataStatus {
  ADDED_WAITING = 'added-waiting',
  ADDED_COMPLETED = 'added-completed'
}

export interface CreateNodeMetaData {
  nodeId: string
  status: CreateNodeMetaDataStatus
  type: NodeType
  node?: any
}

export interface ListnerParams {
  isAdmin: boolean
  isUserCustomer: boolean
  userId?: string
  initialTimestamp?: Timestamp
}

export interface BaseNodeFetchParams {
  size: number
  isUserCustomer: boolean
  isWriteEnabled: boolean
  isAdmin: boolean
  userId?: string
  search?: string
  refresh?: boolean
  boostIds?: any
  loadMoreMeta?: any
  boostSecondaryId?: boolean
}

export interface PaginatedNodeFetchParams
  extends Omit<BaseNodeFetchParams, 'isUserCustomer' | 'isWriteEnabled'> {
  searchCamera?: boolean
}
