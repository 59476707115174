import { render, staticRenderFns } from "./LinkExpiration.vue?vue&type=template&id=75e971e2&scoped=true"
import script from "./LinkExpiration.vue?vue&type=script&lang=ts"
export * from "./LinkExpiration.vue?vue&type=script&lang=ts"
import style0 from "./LinkExpiration.vue?vue&type=style&index=0&id=75e971e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75e971e2",
  null
  
)

export default component.exports