<template>
  <div>
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        Live Stream
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        data-test-id="live-stream-expand-icon"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>

    <!-- FTP config data-->
    <div v-if="isExpanded" class="pr-3 pl-3 py-4 form">
      <v-row no-gutters v-if="isExpanded" class="mb-4">
        <v-col cols="5">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
          >
            Stream Type
          </p>
        </v-col>
        <v-col cols="7">
          <v-select
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="liveStreamType"
            label="Select Stream Type"
            :items="['RTSP', 'Heitel']"
            outlined
            hide-details="true"
            :dense="true"
            :disabled="!isWriteEnabled"
            data-test-id="live-stream-type-select"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <template v-if="liveStreamType === 'RTSP'">
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              RTSP Url
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="rtspUrl"
              label="RTSP Url"
              outlined
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="rtspUrlError"
              :error="rtspUrlError.length > 0"
              data-test-id="rtsp-link-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="isExpanded && liveStreamType === 'Heitel'">
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Server Url
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="heitelData.url"
              label="Server Url - (eg: http://<servername>.com)"
              outlined
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="serverUrlError"
              :error="serverUrlError.length > 0"
              data-test-id="server-url-heitel-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Username
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="heitelData.username"
              label="Username"
              outlined
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="usernameError"
              :error="usernameError.length > 0"
              data-test-id="username-heitel-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="5">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="text-left tooltip"
            >
              Password
            </p>
          </v-col>
          <v-col cols="7">
            <v-text-field
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              v-model="heitelData.password"
              label="Password"
              outlined
              type="password"
              color="secondary"
              hide-details="true"
              :dense="true"
              :disabled="!isWriteEnabled"
              :error-messages="passwordError"
              :error="passwordError.length > 0"
              data-test-id="password-heitel-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <v-row
        no-gutters
        class="d-flex flex-row justify-space-between"
        v-if="isLiveStreamEnabled"
      >
        <v-col cols="6" class="d-flex justify-end">
          <v-btn
            v-if="hasValidLiveStream"
            depressed
            color="secondary black--text"
            style="border-radius: 25px"
            @click="onClickRouteToLiveCamera()"
          >
            <span
              style="
                width: 10px;
                height: 10px;
                border-radius: 50px;
                margin-right: 5px;
                background-color: var(--v-error-base);
              "
            />
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="mb-0"
            >
              Open Live
            </p>
          </v-btn>
        </v-col>
      </v-row>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
        >
          Turn on/off live stream
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            id="alarm-ftp-notification-switch"
            v-model="isLiveStreamEnabled"
            text
            class="notification-switch pt-1"
            color="secondary"
            inset
            @change="onToggleLiveStreamStatus"
            :disabled="!isWriteEnabled"
            data-test-id="live-stream-switch"
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Button }
})
export default class LiveStreamConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop() onNavigateToLive: () => void
  @(Prop({ default: false })!) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public isLiveStreamSaving = false

  public isLiveStreamEnabled: boolean = false
  public liveStreamType = ''
  public rtspUrl = ''
  public heitelData = {
    url: '',
    username: '',
    password: ''
  }

  public rtspUrlError: string = ''
  public serverUrlError: string = ''
  public usernameError: string = ''
  public passwordError: string = ''

  public originalLiveStreamData: any = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isLiveStreamEnabled = this.configuration.isLiveStreamEnabled ?? false
      this.isExpanded = this.isLiveStreamEnabled
      this.liveStreamType = this.configuration.liveStream?.type ?? ''
      this.rtspUrl =
        this.liveStreamType === 'RTSP'
          ? this.configuration.liveStream?.url ?? ''
          : ''
      this.heitelData = {
        url:
          this.liveStreamType === 'Heitel'
            ? this.configuration.liveStream?.url ?? ''
            : '',
        password: this.configuration.liveStream?.password ?? '',
        username: this.configuration.liveStream?.username ?? ''
      }

      this.originalLiveStreamData = {
        isLiveStreamEnabled: this.isLiveStreamEnabled,
        rtspUrl: this.rtspUrl,
        url: this.heitelData.url,
        username: this.heitelData.username,
        password: this.heitelData.password
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isLiveStreamEnabled !==
      this.originalLiveStreamData.isLiveStreamEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.heitelData.username?.trim() !==
        this.originalLiveStreamData.username ||
      this.heitelData.password?.trim() !==
        this.originalLiveStreamData.password ||
      this.heitelData.url?.trim() !== this.originalLiveStreamData.url ||
      this.rtspUrl?.trim() !== this.originalLiveStreamData.rtspUrl
    )
  }

  public onToggleLiveStreamStatus(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  @Watch('liveStreamType')
  @Watch('rtspUrl')
  @Watch('heitelData.url')
  @Watch('heitelData.username')
  @Watch('heitelData.password')
  @Watch('isLiveStreamEnabled')
  public async onClickSaveLiveStreamConfig() {
    let liveStreamData = null
    let isError: boolean = false
    this.rtspUrlError = ''
    this.serverUrlError = ''
    this.usernameError = ''
    this.passwordError = ''

    if (this.liveStreamType === 'RTSP' && !this.rtspUrl) {
      isError = true
      this.rtspUrlError = 'Please fill all the required fields'
    }

    if (this.liveStreamType === 'Heitel') {
      if (!this.heitelData.url) {
        isError = true
        this.serverUrlError = 'Url required'
      }
      if (!this.heitelData.username) {
        isError = true
        this.usernameError = 'Username required'
      }
      if (!this.heitelData.password) {
        isError = true
        this.passwordError = 'Password required'
      }
    }

    if (this.liveStreamType === 'RTSP') {
      liveStreamData = {
        type: this.liveStreamType,
        url: this.rtspUrl?.trim()
      }
    } else if (this.liveStreamType === 'Heitel') {
      liveStreamData = {
        type: this.liveStreamType,
        url: this.heitelData.url?.trim(),
        username: this.heitelData.username?.trim(),
        password: this.heitelData.password?.trim()
      }
    }

    liveStreamData = {
      ...liveStreamData,
      isError,
      isChanged: isError
        ? false
        : this.hasDataChanged() || this.hasSwitchChanged()
    }

    await this.onChange(liveStreamData)
  }

  public onClickRouteToLiveCamera() {
    this.onNavigateToLive()
  }

  public get hasValidLiveStream() {
    if (this.configuration?.liveStream?.type === 'RTSP') {
      if (this.configuration?.liveStream?.url) {
        return true
      }
    } else if (this.configuration?.liveStream?.type === 'Heitel') {
      if (
        this.configuration?.liveStream?.url &&
        this.configuration?.liveStream?.username &&
        this.configuration?.liveStream?.password
      ) {
        return true
      }
    }
    return false
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
