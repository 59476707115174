<template>
  <div class="forgot-password-alert">
    <v-img
      class="mail-icon"
      :src="imageUrl"
      lazy-src="../../../public/img/mail_letter.svg"
      alt="forgot-password"
    />
    <br />
    <h3><strong>Password Reset Requested</strong></h3>
    <br />
    <p>
      Your password reset has been successfully recieved.
      <strong>Please check your email address</strong> for futher instructions
      on how to reset your password. <br />If you do not receive an email within
      the next few minutes, please check your spam or junk folder.
    </p>
    <div v-if="showResendBtn">
      <Button
        class="black--text left-align-button resend-btn"
        @onButtonClick="countdown > 0 ? null : reSendEmail()"
        btnStyle="outlined-grey"
        >{{
          countdown > 0
            ? `Resend in ${countdown}`
            : `Didn't receive an email? Click here to resend`
        }}
      </Button>
    </div>
    <Button
      class="text-subtitle-1 try-diff-email-btn"
      @onButtonClick="tryDiffEmail()"
      btnText="Try a different email"
      btnStyle="outlined"
      data-test-id="forgot-password-btn"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Button from '@/components/app/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class ForgotPasswordAlert extends Vue {
  @Prop() tryDiffEmail!: () => void
  @Prop() showResendBtn!: boolean
  @Prop() forgotPassword!: () => void
  //   @Prop() countdown!: number

  public imageUrl = require('../../../public/img/mail_letter.svg')

  public countdown: number = 0

  public reSendEmail() {
    this.countdown = 15
    const intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--
      } else {
        clearInterval(intervalId)
      }
    }, 1000)
    this.forgotPassword()
  }
}
</script>

<style scoped>
.forgot-password-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.resend-btn {
  font-size: 0.75rem;
}
.try-diff-email-btn {
  margin-top: 0.5vh;
  margin-bottom: 3vh;
}
.mail-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}
</style>
