var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[_c('material-dialog'),(_vm.isNavigationEnabled && !_vm.isUserWorker)?_c('Navigation'):_vm._e(),(_vm.isNotFound)?_c('div',[_c('v-main',[_c('NotFound')],1)],1):_c('core-view'),(_vm.isUpdateAlertVisible)?_c('UpdateAlert'):_vm._e(),(!_vm.smallScreen)?_c('v-footer',{staticStyle:{"display":"flex","justify-content":"space-between","z-index":"10"},style:({
      backgroundColor:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.darkPrimaryColor
          : _vm.getColors.lightPrimaryColor
    }),attrs:{"app":"","fixed":""}},[_c('span',{style:({
        color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
      })},[_vm._v(" © promiseQ GmbH "+_vm._s(new Date().getFullYear())+" - "),_c('router-link',{attrs:{"to":"/imprint","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('button',{attrs:{"href":href},on:{"click":navigate}},[_c('b',[_vm._v("Imprint")])])]}}],null,false,3368455489)})],1),(!_vm.isUserAdmin)?_c('a',{staticClass:"clickable-span",style:({
        color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
      }),attrs:{"href":"https://promiseqknowledgebase.super.site/e7d137d115fd4a219068b8d34be4d771","target":"_blank"}},[_vm._v(" "+_vm._s(this.displayVersion)+" ")]):_c('a',{staticClass:"clickable-span",style:({
        color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
      }),attrs:{"href":"https://promiseqadmindocuments.super.site","target":"_blank"}},[_vm._v(" "+_vm._s(this.displayVersion)+" ")]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text text-subtitle-2",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}],null,false,3077241588),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1):_vm._e(),(_vm.smallScreen)?_c('v-footer',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","z-index":"10","padding":"5px 15px"},style:({
      backgroundColor:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#938D76' : '#DCDCDC'
    }),attrs:{"app":"","fixed":""}},[_c('span',{style:({
        color: '#000000',
        fontSize: '10px'
      })},[_vm._v(" © promiseQ GmbH "+_vm._s(new Date().getFullYear())+" - "),_c('router-link',{attrs:{"to":"/imprint","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('button',{staticStyle:{"background":"none","border":"none","padding":"0","font-weight":"bold","font-size":"10px","cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Imprint ")])]}}],null,false,516860828)})],1),(!_vm.isUserAdmin)?_c('a',{staticClass:"clickable-span",style:({
        color: '#000000',
        fontSize: '10px'
      }),attrs:{"href":"https://promiseqknowledgebase.super.site/e7d137d115fd4a219068b8d34be4d771","target":"_blank"}},[_vm._v(" "+_vm._s(this.displayVersion)+" ")]):_c('a',{staticClass:"clickable-span",style:({
        color: '#000000',
        fontSize: '10px'
      }),attrs:{"href":"https://promiseqadmindocuments.super.site","target":"_blank"}},[_vm._v(" "+_vm._s(this.displayVersion)+" ")]),_c('v-snackbar',{staticStyle:{"position":"absolute","bottom":"50px"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text text-subtitle-2",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}],null,false,3077241588),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1):_vm._e(),_c('SnackBar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }