import {
  Timestamp,
  firestore,
  rtdb,
  functions,
  statusCollection,
  userCollection,
  workerQueueCollection
} from '@/provider/firebase'
import router from '@/router'
import { firestoreAction } from 'vuexfire'
import axios from 'axios'
import { workerStatisticsQuery } from '../../../utils/ELKQueries'
import NetworkSpeed from 'network-speed'

let workerListener = null
let internetSpeedCalculatorInterval
let timeoutId
let currentDownloadSpeed = '0'
let currentUploadSpeed = '0'

const actions = {
  async setWorkerReady(
    { commit },
    data: { workerId: string; isWorkerReady: boolean; isLoggingOut?: boolean }
  ): Promise<void> {
    const { workerId, isWorkerReady, isLoggingOut } = data
    try {
      const state = isWorkerReady ? 'online' : 'offline'
      const last_changed = Timestamp.now().toMillis()
      const rtdbRef = rtdb.ref(`/status/${workerId}`)

      await Promise.all([
        await userCollection.doc(workerId).update({ isWorkerReady }),
        await firestore.collection('status').doc(workerId).set({ state }),
        await rtdbRef.set({ state, last_changed })
      ])

      if (isWorkerReady) {
        router.push('/worker-space-person')
      } else if (!isLoggingOut) {
        router.push('/worker-home')
      }
    } catch (error) {
      console.error(`Error updating worker online status: ${error}`)
    }
  },
  setWorkerOffline({ commit }, workerId: string) {
    try {
      const rtdbRef = rtdb.ref(`/status/${workerId}`)
      rtdbRef.set({
        state: 'offline',
        last_changed: Timestamp.now().toMillis()
      })
    } catch (error) {
      console.error(`Error updating worker offline status: ${error}`)
    }
  },
  bindRegisteredWorkers({ commit, dispatch }) {
    commit('user/setIsLoadingUsers', true, { root: true })
    let workers = []
    const fetchDataFromElastic = functions.httpsCallable('fetchDataFromElastic')

    if (workerListener !== null) {
      workerListener()
    }
    workerListener = userCollection
      .where('role', '==', 'Worker')
      .onSnapshot(async (snapShot) => {
        workers = await Promise.all(
          snapShot.docs.map(async (worker) => {
            const query = workerStatisticsQuery(worker.id)
            const querySnapshot = await statusCollection.get()
            const statusList = querySnapshot.docs.map((doc) => {
              if (doc.id == worker.id) {
                const statusChange = doc.data().state
                return statusChange
              }
            })
            const statusChange = statusList.toLocaleString().replace(/,/g, '')

            try {
              const response = await fetchDataFromElastic(query)
              const completedTodoCount = response.data.hits['total'].value ?? 0
              return {
                todoCount: completedTodoCount,
                workerStatus: statusChange,
                workerActions: worker.data().id,
                ...worker.data()
              }
            } catch (error) {
              return {
                workerActions: worker.data().id,
                ...worker.data()
              }
            }
          })
        )
        commit('setRegisteredWorkers', workers)
        commit('user/setIsLoadingUsers', false, { root: true })
      })

    dispatch('bindAllWorkerStatus')
  },
  unBindRegisteredWorkers() {
    if (workerListener) {
      workerListener()
    }
  },
  bindAllWorkerStatus: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('allWorkerStatus', statusCollection, {
      maxRefDepth: 0,
      wait: true,
      serialize: (doc) => {
        return Object.defineProperty(doc.data(), 'id', {
          value: doc.id
        })
      }
    })
  }),
  unBindAllWorkerStatus: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('allWorkerStatus')
  }),
  bindOnlineWorkers: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'onlineWorkers',
      statusCollection.where('state', '==', 'online'),
      {
        maxRefDepth: 0,
        wait: true,
        serialize: (doc) => {
          return Object.defineProperty(doc.data(), 'id', {
            value: doc.id
          })
        }
      }
    )
  }),
  bindCurrentOnlineWorkers: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'currentOnlineWorkers',
      statusCollection.where('state', '==', 'online'),
      {
        maxRefDepth: 0,
        wait: true,
        serialize: (doc) => {
          return Object.defineProperty(doc.data(), 'id', {
            value: doc.id
          })
        }
      }
    )
  }),
  bindWorkerQueue: firestoreAction(({ bindFirestoreRef }, payload) => {
    return bindFirestoreRef('workerQueue', workerQueueCollection.doc(payload), {
      maxRefDepth: 0,
      wait: true
    })
  }),
  unbindWorkerQueue: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('workerQueue')
  }),
  bindWorkerStatus: firestoreAction(({ bindFirestoreRef }, payload) => {
    return bindFirestoreRef('workerStatus', statusCollection.doc(payload), {
      wait: true
    })
  }),
  unbindWorkerStatus: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('workerStatus')
  }),
  subscribeToInternetSpeedCalculator({ commit, state }) {
    const testNetworkSpeed = new NetworkSpeed()
    // This is a test server need to change to increase upload accuracy.
    const options = {
      hostname: 'httpbin.org',
      port: null,
      path: '/post',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    function runUploadTest(size) {
      testNetworkSpeed
        .checkUploadSpeed(options, size)
        .then((s) => {
          currentUploadSpeed = (+s.mbps * 8).toFixed(2)
        })
        .catch((e) => {
          commit('getUplinkSpeed', state.uplink)
        })
    }

    function runDownloadTest(resource: string, signal) {
      const startTime = performance.now()
      return fetch(resource, { cache: 'no-store', signal })
        .then((response) => response.arrayBuffer())
        .then((data) => {
          const endTime = performance.now()
          const duration = (endTime - startTime) / 1000
          const bitsLoaded = data.byteLength * 8
          const megabitsloaded = bitsLoaded / 1000000
          const speedMbps = (megabitsloaded / duration).toFixed(2)
          currentDownloadSpeed = speedMbps
        })
    }
    internetSpeedCalculatorInterval = setInterval(() => {
      const controller = new AbortController()
      const signal = controller.signal
      // Fetch requests of different file sizes
      runDownloadTest(
        'https://upload.wikimedia.org/wikipedia/commons/0/0f/Grosser_Panda.JPG', // 1KB
        signal
      )
        .then(() => {
          runUploadTest(500)
        })
        .then(() => {
          runDownloadTest(
            'https://upload.wikimedia.org/wikipedia/commons/0/0f/Grosser_Panda.JPG', //50KB
            signal
          )
            .then(() => {
              runUploadTest(50000)
            })
            .then(() => {
              runDownloadTest(
                'https://upload.wikimedia.org/wikipedia/commons/b/b9/Pizigani_1367_Chart_1MB.jpg', // 1MB
                signal
              ).then(() => {
                runDownloadTest(
                  'https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg', // 5MB
                  signal
                ).then(() => {
                  runDownloadTest(
                    'https://upload.wikimedia.org/wikipedia/commons/e/e6/Clocktower_Panorama_20080622_20mb.jpg', // 20MB
                    signal
                  ).finally(() => {
                    runUploadTest(5000000)
                  })
                })
              })
            })
        })

      timeoutId = setTimeout(() => {
        controller.abort()
      }, 2000)
      commit('getDownloadSpeed', currentDownloadSpeed)
      commit('getUplinkSpeed', currentUploadSpeed)
      if (timeoutId) {
        // clearTimeout(timeoutId)
      }
    }, 20000)
  },
  unSubscribeFromInternetSpeedCalculator() {
    if (internetSpeedCalculatorInterval) {
      clearInterval(internetSpeedCalculatorInterval)
    }
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  },
  forceLogoutWorker(
    { state }: any,
    payload: {
      workerId: string
    }
  ): Promise<boolean> {
    return functions
      .httpsCallable('forceLogoutWorker')({
        userId: payload.workerId
      })
      .then((response) => {
        return response?.data
      })
      .catch(() => false)
  }
}

export default {
  ...actions
}
