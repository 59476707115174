<template>
  <v-main></v-main>
</template>
<script>
export default {
  name: 'Logout',

  created() {
    this.$store.dispatch('user/logout', false)
  }
}
</script>
