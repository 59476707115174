import { render, staticRenderFns } from "./GeneralStatistics.vue?vue&type=template&id=6523a3db&scoped=true"
import script from "./GeneralStatistics.vue?vue&type=script&lang=ts"
export * from "./GeneralStatistics.vue?vue&type=script&lang=ts"
import style0 from "./GeneralStatistics.vue?vue&type=style&index=0&id=6523a3db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6523a3db",
  null
  
)

export default component.exports