<template>
  <v-layout>
    <v-snackbar
      color="#333333"
      :timeout="timeout"
      v-model="snackbar"
      height="48px"
      :multi-line="multiLine"
      :data-ci-snackbar="text"
    >
      <div class="snackbar-content">
        <span class="snack-text">{{ text }}</span>
        <Button
          @onButtonClick="closeSnack()"
          btnText="Close"
          class="secondary black--text"
        />
      </div>
    </v-snackbar>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import Button from '@/components/app/Button.vue'
import EventBus from '@/utils/EventBus'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'
import SnackBarError, { SnackBarColor } from '@/components/SnackBarError'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class SnackBar extends Vue {
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('currentUser', namespaceUser) public currentUser: any
  public snackbar: boolean = false
  public timeout: number = 6000
  public mode: string = ''
  public color: SnackBarColor = ''
  public text: string = ''
  public multiLine: boolean = false

  public created() {
    EventBus.$on('spreadSnack', this.spreadSnack)
  }

  public spreadSnack(snack: SnackBarError): void {
    this.snackbar = true
    this.timeout = snack.timeout
    this.mode = snack.mode
    this.color = snack.color
    this.text = snack.text
    this.multiLine = snack.multiLine
  }

  public closeSnack() {
    this.snackbar = false
  }

  public beforeDestroy() {
    EventBus.$off('spreadSnack', this.spreadSnack)
  }

  public get smallScreen() {
    return isCompactView()
  }
  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isDarkMode() {
    return this.isDarkModeToggleEnabled && this.isUserAdmin
  }
}
</script>

<style scoped>
.snackbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.snack-text {
  margin-right: 16px;
}
</style>
