<template>
  <div class="camera">
    <div
      v-if="isAuthenticated === true"
      style="
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        overflow: hidden;
      "
    >
      <div
        class="video-container"
        :style="{
          display: isLoading || isErrorFound ? 'none' : 'flex'
        }"
      >
        <div id="video" class="video-element">
          <video
            :id="videoElementId"
            @timeupdate="onTimeUpdate"
            autoplay
            muted
            loop
            playsinline
            @playing="handleOnVideoPlay"
          ></video>
        </div>
      </div>
      <div v-if="isLoading || isErrorFound" class="loading-wrapper">
        <div class="image-wrapper">
          <div class="img-overlay"></div>
          <img ref="imageSource" id="source" :src="loaderImageLink" />
        </div>
        <div class="loading-content blur-backdrop">
          <div
            v-if="isStreamUnavailable || isErrorFound"
            :style="{
              color: isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
            }"
          >
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
              }"
              >mdi-information</v-icon
            >
            {{ statusMessage }}
          </div>
          <div v-if="!(isStreamUnavailable || isErrorFound)">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
            <div
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled ? 'white' : 'black'
              }"
            >
              Loading...
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="seekEnabled" id="video-controls">
      <Button
        @onButtonClick="onPlaySelect()"
        btnText="Play"
        btnStyle="default"
      />
      <Button
        @onButtonClick="onPauseSelect()"
        btnText="Pause"
        btnStyle="default"
      />
      <Button
        @onButtonClick="onStreamSelect()"
        :btnText="streamSelectButtonTxt"
        btnStyle="default"
      />
      <input type="range" :id="seekBarElementId" value="0" @change="onChange" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import Button from '@/components/app/Button.vue'
import { Action, Getter } from 'vuex-class'
import {
  SIGNALING_SERVER,
  MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS,
  MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS,
  ICE_SERVERS,
  WS_HELLO,
  WS_OFFER_REQUEST,
  WS_SESSION_OK,
  LiveStreamQualityType,
  WebSocketCloseEventCode,
  WS_STREAM_UNAVAILABLE,
  WS_SEEK_EXCEEDED_FILE_DURATION,
  StreamMode,
  PROMISEQUBE_VIDEO_DURATION,
  PROMISEQUBE_SEEK_ENABLED,
  WS_SEEK_COMPLETED
} from '@/utils/Constants'
import { cameraProvider } from '@/provider/firebase'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class WebRtcPlayer extends Vue {
  @Prop({ required: false }) cameraId!: any
  @Prop({ required: false, default: true }) isAuthenticated!: any
  @Prop({ required: false, default: LiveStreamQualityType.HIGH })
  liveStreamQuality!: LiveStreamQualityType
  @Prop({ required: false, default: null })
  referenceImage!: any
  @Prop({ required: false, default: false }) seekEnabled!: any

  @Action('updateCameraSessionId', namespaceCamera)
  public updateCameraSessionId
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public peerConnection: any = null
  public uuid: string = ''
  public serverConnection: any = null
  public localVideo: any = null
  public remoteVideo: any = null
  public callCreateTriggered: boolean = false
  public makingOffer: boolean = false
  public isSettingRemoteAnswerPending: boolean = false
  public isLoading: boolean = true
  public isErrorFound: boolean = false
  public isStreamUnavailable: boolean = false
  public politeOffer: boolean = true
  public serverRestartAttempts = MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS
  public restartTimeoutRef = null
  public initialRestartTimeoutDelayInSeconds = 14
  public statusMessage: string = ''
  public streamSelectButtonTxt = 'Live'
  public streamingMode = StreamMode.LIVE
  public lastStreamingMode = StreamMode.LIVE
  public seekTime = 0
  public previousSeekTime = 0
  public streamStartTime = 0
  public streamReferenceTime = 0
  public streamReferencePercentage = 0
  public streamChangeRequested = false
  public loaderImageLink = ''
  public uniqueElementID = this.createUUID()
  public userChanging = false

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  peerConnectionConfig: RTCConfiguration = {
    // iceTransportPolicy: 'relay', //TODO: Can be set when TURN is stable
    iceServers: ICE_SERVERS
  }

  public get videoElementId() {
    return `web-rtc-video-${this.uniqueElementID}`
  }

  public get seekBarElementId() {
    return `seek-bar-${this.uniqueElementID}`
  }

  public mounted() {
    if (this.isAuthenticated) {
      this.initiateConnection()
    }
  }

  public onPlaySelect() {
    this.remoteVideo.play()
    this.serverConnection.send(
      JSON.stringify({
        play: ''
      })
    )
  }

  public onChange(event) {
    if (this.seekEnabled) {
      this.userChanging = true
      this.isStreamUnavailable = false

      const seekTime =
        (PROMISEQUBE_VIDEO_DURATION / 100) * parseFloat(event.target.value)
      this.remoteVideo.currentTime = seekTime

      if (this.streamingMode === StreamMode.LIVE) {
        this.seekTime =
          Date.now() -
          PROMISEQUBE_VIDEO_DURATION *
            10 *
            (100 - parseFloat(event.target.value))
      } else {
        this.seekTime =
          this.streamReferenceTime +
          PROMISEQUBE_VIDEO_DURATION *
            10 *
            (parseFloat(event.target.value) - this.streamReferencePercentage)
      }
      this.streamReferenceTime = this.seekTime
      this.streamReferencePercentage = parseFloat(event.target.value)
      this.lastStreamingMode = this.streamingMode
      this.streamingMode = StreamMode.RECORDING
      this.handleSeeking()

      // Reset the flag after a short delay
      setTimeout(() => (this.userChanging = false), 1000)
    }
  }

  public handleSeeking() {
    if (
      this.lastStreamingMode === StreamMode.LIVE &&
      this.streamingMode === StreamMode.RECORDING
    ) {
      this.isLoading = true
      this.remoteVideo.pause()
      this.initiateConnection()
    } else if (
      this.lastStreamingMode === StreamMode.RECORDING &&
      this.streamingMode === StreamMode.RECORDING
    ) {
      this.remoteVideo.pause()
      this.serverConnection.send(
        JSON.stringify({
          seek: this.seekTime
        })
      )
    } else if (
      this.lastStreamingMode === StreamMode.RECORDING &&
      this.streamingMode === StreamMode.UNAVAILABLE
    ) {
      this.initiateConnection()
    } else if (
      this.lastStreamingMode === StreamMode.UNAVAILABLE &&
      this.streamingMode === StreamMode.RECORDING
    ) {
      this.initiateConnection()
    } else if (
      this.lastStreamingMode === StreamMode.UNAVAILABLE &&
      this.streamingMode === StreamMode.UNAVAILABLE
    ) {
      this.initiateConnection()
    }
  }

  public onTimeUpdate() {
    if (this.userChanging) {
      return
    }

    if (this.streamingMode === StreamMode.LIVE) {
      this.seekToTime(100)
    } else {
      const currentPosition =
        this.streamReferencePercentage +
        (this.remoteVideo.currentTime / PROMISEQUBE_VIDEO_DURATION) * 100
      this.seekToTime(currentPosition)
    }
  }

  public onPauseSelect() {
    this.remoteVideo.pause()
    this.serverConnection.send(
      JSON.stringify({
        pause: ''
      })
    )
  }

  public onStreamSelect() {
    this.streamingMode = StreamMode.LIVE
    this.seekTime = null
    this.seekToTime(100)

    this.initiateConnection()
  }

  public seekToTime(value: number) {
    const seekBar = document.getElementById(
      this.seekBarElementId
    ) as HTMLInputElement
    seekBar.value = value.toString()
  }

  @Watch('referenceImage')
  public async watchReferenceImage() {
    const imageURL = (await cameraProvider.fetchDownloadUrl(
      this.referenceImage?.path
    )) as string
    this.loaderImageLink = imageURL
  }

  @Watch('isAuthenticated')
  public watchIsAuthenticated() {
    if (this.isAuthenticated) {
      this.initiateConnection()
    }
  }

  @Watch('streamingMode')
  public async watchStreamingMode() {
    this.streamChangeRequested = true
    if (this.serverConnection) {
      await this.serverConnection.close()
    }
  }

  public async startRequestedStream() {
    if (
      this.peerConnection.connectionState === 'closed' &&
      this.streamChangeRequested &&
      this.streamingMode === StreamMode.RECORDING
    ) {
      this.initiateConnection()
      this.streamChangeRequested = false
    }
  }

  public handleOnVideoPlay() {
    if (this.isLoading) {
      this.isLoading = false
      this.statusMessage = ''
    }
  }

  public beforeDestroy() {
    this.serverConnection.close()
    this.peerConnection.close()
  }

  public async initiateConnection() {
    this.isErrorFound = false
    this.isStreamUnavailable = false
    console.log('Establishing RTC Connection!')
    this.statusMessage = 'Establishing RTC Connection!'

    this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig)
    this.uuid = this.createUUID()

    this.remoteVideo = document.getElementById(this.videoElementId)

    this.serverConnection = new WebSocket(SIGNALING_SERVER)
    this.serverConnection.addEventListener('open', (event) => {
      console.log('Opening signal server connection')
      this.statusMessage = 'Opening signal server connection!'
      this.serverConnection.send(`${WS_HELLO} ${this.uuid}`)
      if (this.cameraId !== '') {
        this.updateCameraSessionId({
          cameraId: this.cameraId,
          sessoinId: this.uuid,
          liveStreamQuality: this.liveStreamQuality,
          seekTime:
            this.streamingMode !== StreamMode.LIVE ? this.seekTime : null
        })
      }

      // restart timeout on hanging connections
      this.scheduleRestart()
    })
    this.serverConnection.addEventListener('error', this.onServerError)
    this.serverConnection.addEventListener('message', this.onServerMessage)
    this.serverConnection.addEventListener('close', this.onServerClose)
  }

  private scheduleRestart() {
    if (this.serverRestartAttempts > 0) {
      const delay =
        Math.pow(
          2,
          MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS +
            1 -
            this.serverRestartAttempts
        ) *
          1000 +
        this.initialRestartTimeoutDelayInSeconds * 1000

      this.restartTimeoutRef = setTimeout(() => {
        this.restartConnection()
      }, delay)
    } else {
      console.log('Max attempts reached. Please check your connection.')
      this.statusMessage = 'The live stream is no longer available!'
      this.isLoading = false
      this.isErrorFound = true
    }
  }

  public async onServerError(event) {
    console.log('Server connection error')
    console.log(event)
  }

  public async onServerClose(event) {
    console.log('Closing signalling server connection', event.code)

    if (this.peerConnection) {
      this.peerConnection.close()
      if (this.streamingMode !== StreamMode.UNAVAILABLE) {
        this.startRequestedStream()
        if (this.restartTimeoutRef) clearTimeout(this.restartTimeoutRef)
      }
    }

    if (event.code == WebSocketCloseEventCode.CLOSED_FOR_RESTART) {
      this.initiateConnection()
    } else if (event.code == WebSocketCloseEventCode.ABNORMAL_CLOSURE) {
      this.restartConnection(true)
    } else if (event.code == WebSocketCloseEventCode.NORMAL_CLOSURE) {
      this.isLoading = true
      this.statusMessage = 'Waiting for the remote to be back online!'
    } else if (event.code == WebSocketCloseEventCode.INTERNAL_ERROR) {
      this.isLoading = true
      this.restartConnection(true)
    } else {
      this.isLoading = true
      if (this.streamChangeRequested) return
      this.statusMessage = 'Unexpected error!'
    }
    this.callCreateTriggered = false
  }

  public async onServerMessage(event) {
    switch (event.data) {
      case WS_HELLO:
        return
      case WS_SESSION_OK:
        if (!this.callCreateTriggered) {
          this.createCall()
        }
        return
      case WS_OFFER_REQUEST:
        // The peer wants us to set up and then send an offer
        if (!this.callCreateTriggered) this.createCall()
        return
      case WS_STREAM_UNAVAILABLE:
        // The peer wants us to set up and then send an offer
        this.statusMessage =
          'The stream is not available for the selected time.'
        this.isLoading = true
        this.isStreamUnavailable = true
        this.lastStreamingMode = this.streamingMode
        this.streamingMode = StreamMode.UNAVAILABLE
        return
      case WS_SEEK_EXCEEDED_FILE_DURATION:
        // The peer wants us to set up and then send an offer
        this.statusMessage =
          'The stream is not available for the selected time.'
        this.isLoading = true
        this.isErrorFound = false
        this.lastStreamingMode = this.streamingMode
        this.streamingMode = StreamMode.RECORDING
        // restart the ice candidates
        this.peerConnection.restartIce()
        // this.initiateConnection()
        this.serverConnection.close(WebSocketCloseEventCode.CLOSED_FOR_RESTART)
        return
      case WS_SEEK_COMPLETED:
        this.remoteVideo?.play()
        return
      default:
        if (event.data.startsWith('ERROR')) {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', event.data)
          return
        }
        // Handle incoming JSON SDP and ICE messages
        let msg
        try {
          msg = JSON.parse(event.data)
        } catch (e) {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', e)
          return
        }

        // Incoming JSON signals the beginning of a call
        if (!this.callCreateTriggered) this.createCall()

        if (msg.sdp != null) {
          this.onIncomingSDP(msg.sdp)
        } else if (msg.ice != null) {
          this.onIncomingICE(msg.ice)
        } else {
          this.isLoading = true
          this.statusMessage = 'Unexpected error!'
          console.log('Unexpected error!', msg)
        }
    }
  }

  public async createCall() {
    if (!this.peerConnection) return
    this.callCreateTriggered = true
    const sendChannel = this.peerConnection.createDataChannel('label', null)
    sendChannel.onopen = (event) => {
      console.log('dataChannel.OnOpen', event)
    }
    sendChannel.onmessage = (event) => {
      sendChannel.send('Hi! (from browser)')
    }
    sendChannel.onclose = (event) => {
      console.log('dataChannel.OnClose', event)
    }
    this.peerConnection.ondatachannel = (event) => {
      let receiveChannel = event.channel
      receiveChannel.onopen = (event) => {
        console.log('dataChannel.OnOpen', event)
      }
      receiveChannel.onmessage = (event) => {
        console.log('dataChannel.OnMessage:', event, event.data.type)

        sendChannel.send('Hi! (from browser)')
      }
      receiveChannel.onclose = (event) => {
        console.log('dataChannel.OnClose', event)
        this.restartConnection()
      }
    }

    this.peerConnection.ontrack = async ({ track, streams }) => {
      this.isErrorFound = false
      this.statusMessage = 'Stream received!'
      this.remoteVideo = document.getElementById(this.videoElementId)
      this.remoteVideo.srcObject = streams[0]
      this.remoteVideo.play()
    }

    this.peerConnection.onicecandidate = (event) => {
      // We have a candidate, send it to the remote party with the
      this.statusMessage = 'ICE candidate sharing!'
      if (event.candidate == null) {
        console.log('ICE Candidate was null, done')
        return
      }
      this.serverConnection.send(JSON.stringify({ ice: event.candidate }))
    }

    this.peerConnection.oniceconnectionstatechange = (event) => {
      console.log(
        'oniceconnectionstatechange',
        this.peerConnection.iceConnectionState
      )
      if (this.peerConnection.iceConnectionState == 'connected') {
        console.log('ICE gathering complete')
        this.statusMessage = 'ICE gathering complete!'
        clearTimeout(this.restartTimeoutRef) // clear restarting process if connection established
        this.serverRestartAttempts =
          MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS
      }
      if (this.peerConnection.iceConnectionState == 'failed') {
        console.log('ICE gathering Error!')
        this.restartConnection()
      }
      if (this.peerConnection.iceConnectionState == 'disconnected') {
        this.statusMessage =
          'Disconnected from the remote peer. Awaiting for reconnection!'
        this.serverRestartAttempts =
          MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS
        this.restartConnection(true)
      }
    }

    // let the "negotiationneeded" event trigger offer generation
    this.peerConnection.onnegotiationneeded = async () => {}
  }

  public async onIncomingSDP(sdp) {
    try {
      // An offer may come in while we are busy processing SRD(answer).
      // In this case, we will be in "stable" by the time the offer is processed
      // so it is safe to chain it on our Operations Chain now.
      this.statusMessage = 'Handling the session description protocol!'
      const readyForOffer =
        !this.makingOffer &&
        (this.peerConnection.signalingState == 'stable' ||
          this.isSettingRemoteAnswerPending)
      const offerCollision =
        !this.politeOffer && sdp.type == 'offer' && !readyForOffer

      if (offerCollision) {
        return
      }
      this.isSettingRemoteAnswerPending = sdp.type == 'answer'
      this.peerConnection.setRemoteDescription(sdp).then(() => {
        this.isSettingRemoteAnswerPending = false
        if (sdp.type == 'offer') {
          this.peerConnection.setLocalDescription().then(() => {
            let desc = this.peerConnection.localDescription
            console.log('Got local description: ' + JSON.stringify(desc))
            this.serverConnection.send(JSON.stringify({ sdp: desc }))
            if (this.peerConnection.iceConnectionState == 'connected') {
              console.log(
                'SDP ' + desc.type + ' sent, ICE connected, all looks OK'
              )
            }
          })
        }
      })
    } catch (err) {
      this.isErrorFound = true
    }
  }

  public async onIncomingICE(ice) {
    const candidate = new RTCIceCandidate(ice)
    this.peerConnection.addIceCandidate(candidate).catch(this.setError)
  }

  public setError(text) {
    console.error(text)
  }

  public async restartConnection(hardRestart = false) {
    // Restarting is not required for UNAVAILABLE and RECORDING modes.
    const unsupportedStreamingModes = [
      StreamMode.UNAVAILABLE,
      StreamMode.RECORDING
    ]
    if (unsupportedStreamingModes.includes(this.streamingMode)) return

    if (this.serverRestartAttempts <= 0) {
      this.statusMessage = 'The live stream is no longer available!'
      this.isLoading = false
      this.isErrorFound = true
      return
    }

    this.statusMessage = 'Restarting RTC Connection!'

    this.isLoading = true
    --this.serverRestartAttempts

    this.statusMessage = `Attempting to reconnect!`

    if (hardRestart) {
      await this.sleep(5000) // sleep in-between retries
      this.initiateConnection()
      return
    }

    // restart the ice candidates
    this.peerConnection.restartIce()

    // close and auto-restart connection
    this.serverConnection.close(WebSocketCloseEventCode.CLOSED_FOR_RESTART)
  }

  sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  public createUUID(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}.${
      this.cameraId
    }`
  }
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* add a semi-transparent black background */
}

.overlay i {
  font-size: 5rem;
  color: white;
}

.offline {
  filter: grayscale(100%);
  /* apply a gray filter to the image */
}

.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.unauthenticated-overlay {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 80px);
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.video-element {
  width: 100%;
  height: 100%;
  flex: 1;
  max-height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#video-controls {
  background-color: #1e293b;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

#video-controls button,
#video-controls input[type='range'] {
  margin: 0 5px;
  border-radius: 10px;
}

button {
  padding: 10px;
  border: none;
  background-color: #0f172a;
  color: #ffffff;
  cursor: pointer;
  font-size: 1vw;
}

button[id='mute-btn'] {
  height: 35px;
  width: 35px;
  padding: 2px;
}

button[id='speed-btn'] {
  height: 35px;
  width: 45px;
  padding: 2px;
}

button:hover {
  background-color: #020617;
}

input[type='range'] {
  width: 90%;
}

video {
  display: block;
  max-width: 100%;
}

.loading-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

.image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  position: relative;
}

.image-wrapper img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
  min-height: 0;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.loading-content {
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blur-backdrop {
  backdrop-filter: blur(3px);
}
</style>
