import { BACKEND_API_URL, BACKEND_EMULATOR } from '@/utils/EnvMapping'
import { Endpoint } from '../constants'

const emulatorUrls = {
  [Endpoint.Admin]: 'http://localhost:7777',
  [Endpoint.ThreatDetect]: 'http://localhost:3000'
}

const productionUrls = {
  [Endpoint.Admin]: `${BACKEND_API_URL}/admin`,
  [Endpoint.ThreatDetect]: `${BACKEND_API_URL}`
}

export const backendURL = (endpoint: Endpoint = Endpoint.Admin): string => {
  const urls = BACKEND_EMULATOR === 'true' ? emulatorUrls : productionUrls
  const url = urls[endpoint]
  if (!url) {
    throw new Error(`Invalid endpoint: ${endpoint}`)
  }

  return url
}
