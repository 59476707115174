var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header-component-container",class:{ 'navigation-shrink': _vm.getIsToggleMenuEnabled }},[_c('div',{staticClass:"container--outline",class:_vm.minified
        ? 'container--outline-minified'
        : _vm.smallScreen
        ? 'container--outline-smallscreen'
        : '',staticStyle:{"height":"80px"}},[_c('v-row',{staticClass:"header-row",class:_vm.minified ? 'header-row-minified' : '',style:({
        backgroundColor: _vm.minified
          ? _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor
          : null
      })},[_c('v-col',{staticClass:"header-col"},[(_vm.showHamBurgerMenu)?_c('v-btn',{staticClass:"menu-toggle-btn",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlackColor
          }),attrs:{"icon":""},on:{"click":_vm.toggleMenu}},[_c('v-icon',[_vm._v("mdi-menu-"+_vm._s(_vm.getIsToggleMenuEnabled ? 'close' : 'open'))])],1):_vm._e(),_c('v-row',{staticClass:"header-breadcrumbs-vrow"},[(_vm.showBreadcrumb)?_c('v-breadcrumbs',{class:_vm.minified
                ? 'breadcrumbs-container-minified'
                : _vm.smallScreen
                ? 'breadcrumbs-container-smallscreen'
                : '',style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlackColor
            }),attrs:{"items":_vm.breadcrumbList,"large":true,"data-test-id":"breadcrumbs-container"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.darkBlackColor
                })},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function({ item }){return [(!_vm.isCurrentPage(item))?_c('a',{staticClass:"breadcrumb-item",class:[
                  _vm.smallScreen ? 'breadcrumb-item-smallscreen' : '',
                  _vm.smallScreen && _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? 'breadcrumb-item-smallscreen-darkmode'
                    : ''
                ],style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.darkBlackColor
                }),on:{"click":function($event){return _vm.onClickBreadCrumb(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('span',{class:[
                  _vm.smallScreen ? 'breadcrumb-item-smallscreen' : '',
                  _vm.smallScreen && _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? 'breadcrumb-item-smallscreen-darkmode'
                    : ''
                ]},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,false,2140357725)}):(!_vm.minified && !_vm.smallScreen)?_c('v-progress-circular',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlackColor,
              marginLeft: '1rem',
              width: '12px'
            }),attrs:{"indeterminate":"","rounded":"","color":"primary"}}):_vm._e()],1)],1),(!_vm.minified && !_vm.smallScreen)?_c('v-col',{staticClass:"header-refresh-timerange-col"},[_c('v-row',{staticClass:"header-refresh-timerange-row",attrs:{"no-gutters":""}},[(_vm.route === 'alarms')?_c('RefreshDropDown'):_vm._e(),(
              _vm.route === 'alarms' ||
              _vm.route === 'statistics' ||
              _vm.route === 'healthCheck'
            )?_c('TimeRangeSelector'):_vm._e()],1)],1):_vm._e(),(!_vm.minified && _vm.smallScreen)?_c('v-col',{class:_vm.route === _vm.routePath.Alarms
            ? 'header-refresh-timerange-col-mobile'
            : 'header-refresh-timerange-col'},[(_vm.currentRouteInfo)?_c('InfoButton',{attrs:{"rows":_vm.currentRouteInfo,"is-alarms-route":_vm.route === _vm.routePath.Alarms}}):_vm._e(),_c('v-row',{staticClass:"header-refresh-timerange-row",attrs:{"no-gutters":""}},[(_vm.route === _vm.routePath.Alarms)?_c('RefreshDropDown'):_vm._e(),(
              _vm.route === _vm.routePath.Alarms ||
              _vm.route === _vm.routePath.Statistics ||
              _vm.route === _vm.routePath.HealthCheck
            )?_c('TimeRangeSelector'):_vm._e()],1)],1):_vm._e(),_c('v-col',{staticClass:"header-btn-vcol"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"header-btn-progress-vcol"},[(_vm.isLoading && !_vm.minified)?_c('div',{staticClass:"loading-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_vm._e(),(!_vm.isLoading && (!_vm.smallScreen || _vm.minified))?_c('v-btn-toggle',{attrs:{"mandatory":"","tile":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},_vm._l((_vm.buttonLabels),function(label,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"text-xl-body-2 text-md-caption",class:`${
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? 'admin-header-btn'
                        : 'header-btn'
                    } ${
                      _vm.minified || _vm.getIsToggleMenuEnabled
                        ? 'header-btn-minified'
                        : ''
                    }`,attrs:{"active-class":"header-btn--selected","disabled":_vm.isBtnDisabled[index]},on:{"click":_vm.buttonActions[index]}},on),[_c('v-icon',{class:_vm.minified ? 'icon-minified' : '',style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? _vm.getColors.lightPrimaryColor
                            : _vm.getColors.darkBlackColor
                      })},[_vm._v(_vm._s(_vm.buttonIcons[index]))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(label))])])}),1):_vm._e()],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }