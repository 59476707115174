export function isNodeNameValid(str: string): boolean {
  if (str?.length === 0) return false
  const format = /^[a-zA-Z0-9+\-_:.\s]+$/
  return format.test(str)
}
export function isNodeIdValid(str: string): boolean {
  if (str?.length === 0) return false
  const format = /^[a-zA-Z0-9+\-_:.\s]+$/
  return format.test(str)
}
