<template>
  <div>
    <div v-if="incidentsByInputTypeStatus !== 'Ready'" class="loading-spinner">
      <div v-if="incidentsByInputTypeStatus === 'Error'">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is an error while loading this chart
        </p>
      </div>
      <div v-if="incidentsByInputTypeStatus === 'Loading'">
        <v-progress-circular indeterminate color="secondary" />
      </div>
    </div>
    <div v-if="incidentsByInputTypeStatus === 'Ready'" class="my-2 pa-4">
      <div v-if="incidentsByInputTypeData.length > 1" style="width: 100%">
        <GChart
          key="incidents-by-input-type"
          type="AreaChart"
          :data="modifiedChartData"
          :options="inputTypeChartOptions"
        />
      </div>
      <div v-else>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is not enough data to show this chart
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { inputTypeChartOptions } from '../../utils/Charts'
import { GChart } from 'vue-google-charts/legacy'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceStatistics = { namespace: 'statistics' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    GChart
  }
})
export default class InputTypeChart extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('getFiLabel', namespaceStatistics) public xAxisLabel: string
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getIncidentsByInputTypeData', namespaceStatistics)
  public incidentsByInputTypeData: any[]
  @Getter('getIncidentsByInputTypeStatus', namespaceStatistics)
  public incidentsByInputTypeStatus: string

  @Action('fetchIncidentsByInputTypeData', namespaceStatistics)
  fetchIncidentsByInputTypeData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('$route.params')
  public fetchChartData = debounce(async () => {
    await this.fetchIncidentsByInputTypeData({
      startTime: this.startTime,
      endTime: this.endTime,
      ...this.$route.params
    })
  }, 300)

  public get modifiedChartData(): any[] {
    return [
      [
        'Time',
        'FTP',
        'Genetec',
        'SMTP',
        'Ajax',
        'Hikvision',
        'SFTP',
        'WebApp API',
        'EBUS',
        'Sandbox',
        'Backend API'
      ],
      ...this.incidentsByInputTypeData
    ]
  }

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors?.lightPrimaryColor
      : this.getColors?.darkBlackColor
  }

  public get inputTypeChartOptions(): any {
    return inputTypeChartOptions({
      hAxisTitle: this.xAxisLabel,
      vAxisTitle: 'Count',
      textColors: this.textColors
    })
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
