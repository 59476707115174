const getters = {
  clientConfig: (state: { clientConfig: any[] }) => {
    return state.clientConfig
  },
  clientOwner: (state: any) => {
    return state.clientOwner
  },
  getEagleEyeConnect: (state: {
    eagleEyeConnect: { progress: boolean; message: string }
  }) => {
    return state.eagleEyeConnect
  },
  getClientCameras: (state: any) => {
    return state.clientCameras
  },
  getClientLiveIsLoading: (state: any) => {
    return state.clientLiveIsLoading
  },
  getClientUsers: (state, any) => {
    return state.clientUsers
  }
}

export default {
  ...getters
}
