import firebase from 'firebase/compat/app'
import {
  configCollection,
  clientCollection,
  siteCollection,
  cameraCollection
} from '@/provider/firebase'
import {
  DEFAULT_QUOTA,
  DEFAULT_QUOTA_ALERT_PERCENTAGE
} from '@/utils/Constants'

export default {
  namespaced: true,
  async addNewAcceptedSubscriptionKey(clientId: string, key: string) {
    try {
      const configDocRef = configCollection.doc('config')

      const subCollectionRef = configDocRef
        .collection('acceptedSubscriptionKeys')
        .doc(clientId)
      const subCollectionSnapshot = await subCollectionRef.get()

      if (subCollectionSnapshot.exists) {
        await subCollectionRef.update({
          acceptedSubscriptionKeys:
            firebase.firestore.FieldValue.arrayUnion(key)
        })
      } else {
        await subCollectionRef.set({
          acceptedSubscriptionKeys: [key]
        })
      }

      console.log('Key added/updated in sub-collection successfully.')
    } catch (error) {
      console.error('Error adding/updating subscription key: ', error)
    }
  },
  async removeAcceptedSubscriptionKey(clientId: string, key: string) {
    try {
      const configDocRef = configCollection.doc('config')
      const subCollectionRef = configDocRef
        .collection('acceptedSubscriptionKeys')
        .doc(clientId)

      const subCollectionSnapshot = await subCollectionRef.get()

      if (subCollectionSnapshot.exists) {
        const subCollectionData = subCollectionSnapshot.data()
        if (
          subCollectionData &&
          Array.isArray(subCollectionData.acceptedSubscriptionKeys)
        ) {
          const updatedKeys = subCollectionData.acceptedSubscriptionKeys.filter(
            (k) => k !== key
          )
          await subCollectionRef.update({
            acceptedSubscriptionKeys: updatedKeys
          })
        } else {
          console.log('Sub-collection data or keys array not found.')
        }
      } else {
        console.log('Sub-collection document not found.')
      }
    } catch (error) {
      console.error('Error removing subscription key: ', error)
    }
  },
  async checkKeyAvailability(key: string) {
    try {
      const configDocRef = configCollection.doc('config')
      const subCollectionRef = configDocRef.collection(
        'acceptedSubscriptionKeys'
      )

      // Query for any documents that contain the 'acceptedSubscriptionKeys' field with the specified key
      const querySnapshot = await subCollectionRef
        .where('acceptedSubscriptionKeys', 'array-contains', key)
        .limit(1)
        .get()

      // If the querySnapshot is empty, no documents contain the key, thus the key is available
      return querySnapshot.empty
    } catch (error) {
      console.error('Error checking key availability: ', error)
      throw error
    }
  },
  async getAllocatedQuota(clientId: string) {
    let isQuotaEnabled = false
    let alarmQuota = DEFAULT_QUOTA
    let quotaAlertPercentage = DEFAULT_QUOTA_ALERT_PERCENTAGE
    try {
      const clientSnap = await clientCollection
        .where('clientId', '==', clientId)
        .get()

      if (!clientSnap.empty) {
        const client = clientSnap.docs[0].data()
        isQuotaEnabled = client?.isQuotaEnabled ?? false
        alarmQuota = client?.alarmQuota ?? DEFAULT_QUOTA
        quotaAlertPercentage =
          client?.quotaAlertPercentage ?? DEFAULT_QUOTA_ALERT_PERCENTAGE
      }
    } catch (error) {
      throw new Error('Error getting allocated quota: ' + error)
    }

    return { isQuotaEnabled, alarmQuota, quotaAlertPercentage }
  },
  async updateBlockToggleDb(
    blockName: string,
    targetType: string,
    docId: string,
    value: boolean
  ) {
    const collectionMap = {
      client: clientCollection,
      site: siteCollection,
      camera: cameraCollection
    }

    if (!(targetType in collectionMap)) {
      throw new Error(`Invalid targetType: ${targetType}`)
    }

    const targetCollection = collectionMap[targetType]

    const updateField = `blockToggles.${blockName
      .replace(/\s+/g, '')
      .replace(/^(.)/, (match) => match.toLowerCase())
      .replace(/(\s.)/g, (match) => match.toUpperCase().replace(/\s+/g, ''))}`

    try {
      return await targetCollection.doc(docId).update({ [updateField]: value })
    } catch (error) {
      console.error(`Error updating ${blockName} toggle:`, error)
    }
  },
  async updateNotificationInheritToggleDb(
    targetType: string,
    docId: string,
    value: boolean
  ) {
    const collectionMap = {
      client: clientCollection,
      site: siteCollection,
      camera: cameraCollection
    }

    if (!(targetType in collectionMap)) {
      throw new Error(
        `Invalid targetType in updateNotificationInheritToggleDb: ${targetType}`
      )
    }

    const targetCollection = collectionMap[targetType]

    try {
      return await targetCollection
        .doc(docId)
        .update({ isNotificationDataInherited: value })
    } catch (error) {
      console.error(
        `Error updating updateNotificationInheritToggleDb toggle for ${targetType} - ${docId} :`,
        error
      )
    }
  }
}
