<template>
  <v-container class="container">
    <div v-if="isLoading" class="loading-spinner" style="height: 97vh">
      <v-progress-circular indeterminate color="secondary" />
    </div>
    <v-flex v-if="lastTodoByCameraRef && !isLoading">
      <v-row justify="space-between">
        <v-col cols="6" class="reference">
          <v-row justify="center">
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              class="text-body-1 font-weight-bold"
              >Reference picture</span
            >
          </v-row>
          <v-row
            v-if="currCameraRef && !!referenceImageUrl"
            justify="center"
            class="mr-1"
          >
            <v-img
              :src="referenceImageUrl"
              lazy-src="../../../public/img/lazy-load-frame.svg"
              @load="onRefImageLoad"
              alt="reference-image"
              class="reference-image"
              style="width: 100%"
            />
          </v-row>
          <v-row v-else justify="center" align="center" style="height: 100%">
            <v-col v-if="isRefImgLoading">
              <v-progress-circular
                indeterminate
                color="secondary"
              ></v-progress-circular>
            </v-col>
            <v-col v-else>
              <v-chip class="ma-2" color="error" variant="outlined">
                No reference image
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="incident">
          <v-row justify="center">
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              class="text-body-1 font-weight-bold"
              >Incident</span
            >
          </v-row>
          <v-flex
            v-if="lastTodoByCameraRef"
            justify="center"
            class="ml-1"
            style="width: 100%"
          >
            <SimpleGalleryElement
              :mediaPath="mediaFilePath"
              :mediaType="lastTodoByCameraRef.fileMetadata.mediaType"
              :aspectRatio="1.4"
              :onMediaLoad="onIncidentLoad"
            />
          </v-flex>
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <v-col cols="12">
          <v-row>
            <span
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              class="text-body-1 font-weight-bold"
              >Report options</span
            >
          </v-row>
          <v-row class="pt-2">
            <v-card width="100%" :style="adminCardStyle" outlined>
              <v-row class="pl-5 pr-5 pt-5" dense>
                <v-col cols="8">
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(item, index) in deviationOptions"
                      v-bind:key="index"
                    >
                      <v-row>
                        <v-col class="d-flex flex-column justify-center"
                          ><div class="d-flex justify-start py-2 px-1">
                            <input
                              type="checkbox"
                              :checked="isTicked(item)"
                              @change="handleFeedback(item)"
                              :disabled="!isDecisionEnabled"
                              :style="{
                                borderColor: !isDecisionEnabled
                                  ? 'grey'
                                  : '#ffd42a'
                              }"
                            />
                            <span
                              @click="handleFeedback(item)"
                              class="px-2"
                              :style="{
                                color: !isDecisionEnabled
                                  ? 'grey'
                                  : isUserAdmin && isDarkModeToggleEnabled
                                  ? getColors.lightPrimaryColor
                                  : getColors.darkBlackColor
                              }"
                              >{{ item }}</span
                            >
                          </div></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-textarea
                    :dark="isUserAdmin && isDarkModeToggleEnabled"
                    dense
                    v-model="comment"
                    :prepend-inner-icon="comment != '' ? '' : 'mdi-pencil'"
                    outlined
                    placeholder="Add a comment"
                    :disabled="selectedFeedback.length === 0"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters class="ml-5 mr-5 mb-5" justify="end">
                <v-col cols="1">
                  <button
                    :class="'key key-custom-spacebar key-spacebar'"
                    @click="onSendCameraHealthComment"
                    style="width: 100px"
                    :disabled="selectedFeedback.length === 0"
                  >
                    <v-icon dark>mdi-send</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <!-- Snackbar message -->
      <v-snackbar v-model="snackbar">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <Button
            text
            v-bind="attrs"
            @onButtonClick="snackbar = false"
            btnText="Close"
            btnStyle="outlined"
            class="secondary black--text"
          />
        </template>
      </v-snackbar>
      <!-- End of Snackbar message -->
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SimpleGalleryElement from '../events/SimpleGalleryElement.vue'
import { Action, Getter, Mutation } from 'vuex-class'
const namespaceTodos = { namespace: 'todos' }
const namespaceCamera = { namespace: 'camera' }
const namespaceNotifications = { namespace: 'notifications' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config', name: 'config' }

@Component({
  components: {
    SimpleGalleryElement
  }
})
export default class WorkerCameraHealthCheck extends Vue {
  @Prop() public currentHcDueCam!: any
  @Prop() public isUserAdmin: boolean
  @Prop() public setCurrentHcDueCam: (camera: any) => void

  @Getter('getLastTodoByCameraRef', namespaceTodos) public lastTodoByCameraRef
  @Getter('isNotificationEnabled', namespaceNotifications)
  public isNotificationEnabled: boolean
  @Getter('getReferenceImageUrl', namespaceCamera) public refImgUrl
  @Getter('getCurrentTodoCameraRef', namespaceTodos) public currentTodoCameraRef
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Mutation('setCurrentTodoCameraRef', namespaceTodos)
  public setCurrentTodoCameraRef

  @Action('fetchReferenceImageFromCameraRef', namespaceCamera)
  public fetchReferenceImageFromCameraRef
  @Action('fetchReferenceImageFromPath', namespaceCamera)
  public fetchReferenceImageFromRawUrl
  @Action('fetchLastTodoByCameraRef', namespaceTodos)
  public fetchLastTodoByCameraRef
  @Action('updateCamHcDueStatus', namespaceCamera) public updateCamHcDueStatus
  @Action('updateInvalidCamHcDueStatus', namespaceCamera)
  public updateInvalidCamHcDueStatus
  @Action('setHealthCheckData', namespaceTodos) public setHealthCheckData

  public mediaUrl: string = null
  public referenceImageUrl: string = ''
  public comment: string = ''
  public selectedFeedback: string[] = []
  public notificationSound = new Audio('/assets/audio/notification.mp3')

  public isRefImgLoading = false

  //ToDo: Add required deviation option here
  public deviationOptions = [
    'Incident Deviation',
    'Reference Deviation',
    'No Deviation'
  ]

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public currCameraRef: any = null
  public isLoading: boolean = false

  public isIncidentLoaded: boolean = false
  public isRefImageLoaded: boolean = false

  get isDecisionEnabled() {
    return this.isIncidentLoaded && this.isRefImageLoaded
  }

  get mediaFilePath() {
    // if unarmed return the original file path
    if (this.lastTodoByCameraRef?.isUnarmed) {
      return this.lastTodoByCameraRef?.originalFile.path
    }
    // else return the processed file path
    return this.lastTodoByCameraRef?.processedFile.path
  }

  public async mounted() {
    this.isLoading = true
    this.isIncidentLoaded = false
    this.isRefImageLoaded = false
    await this.fetchLastTodoByCameraRef(this.currentHcDueCam.id)
    if (this.lastTodoByCameraRef) {
      this.playNotificationSound()
      this.currCameraRef = this.lastTodoByCameraRef?.cameraRef
      this.setRefImgUrl()
    }
    this.isLoading = false
  }

  public updated() {
    this.setRefImgUrl()
  }

  public async onSendCameraHealthComment() {
    const data = {
      deviation: this.selectedFeedback,
      reportMsg: this.comment,
      referenceImage: this.currentHcDueCam.referenceImage,
      createdAt: new Date()
    }

    try {
      await Promise.all([
        this.setHealthCheckData({
          todoId: this.lastTodoByCameraRef.id,
          healthCheckData: data
        }),
        this.updateCamHcDueStatus({
          camera: this.currCameraRef,
          status: false
        })
      ])

      this.popSnackbarMessage('Health check done successfully')

      if (this.currCameraRef?.id === this.currentHcDueCam?.id) {
        this.setCurrentHcDueCam(null)
      }
    } catch (error) {
      console.error('Error during health check submission:', error)
      this.popSnackbarMessage('Error during health check submission')
    }
  }

  public getImageId() {
    this.$emit('activeImage')
  }

  @Watch('currentHcDueCam')
  private async setRefImgUrl() {
    this.isRefImgLoading = true
    if (this.lastTodoByCameraRef && this.lastTodoByCameraRef?.cameraRef) {
      this.currCameraRef = this.lastTodoByCameraRef.cameraRef
      await this.getCameraData()
      if (this.currentHcDueCam?.referenceImage) {
        await this.fetchReferenceImageFromRawUrl(
          this.currentHcDueCam.referenceImage.path
        )
      }
      if (this.refImgUrl) {
        this.referenceImageUrl = this.refImgUrl
      }
    } else {
      // Set helath check due status to false for invalid cameras.
      if (this.currentHcDueCam?.healthCheckType === 'Incident')
        await this.updateInvalidCamHcDueStatus({
          camera: this.currentHcDueCam,
          status: false
        })
    }
    this.isRefImgLoading = false
  }

  public onRefImageLoad() {
    this.isRefImageLoaded = true
  }

  public onIncidentLoad(isLoaded: boolean) {
    this.isIncidentLoaded = isLoaded
  }

  private playNotificationSound() {
    try {
      if (this.isNotificationEnabled) this.notificationSound.play()
    } catch (error) {
      console.log(error)
    }
  }

  private async getCameraData() {
    if (this.currCameraRef) {
      this.setCurrentTodoCameraRef(this.currCameraRef)
      await this.fetchReferenceImageFromCameraRef(this.currCameraRef)
    }
  }

  public handleFeedback(value: string) {
    const isChecked = !this.isTicked(value)
    if (isChecked) {
      if (value === 'No Deviation') {
        this.selectedFeedback = []
      } else {
        this.selectedFeedback = this.selectedFeedback.filter((obj) => {
          return obj !== 'No Deviation'
        })
      }
      this.selectedFeedback.push(value)
    } else {
      this.selectedFeedback = this.selectedFeedback.filter((obj) => {
        return obj !== value
      })
    }
  }

  public isTicked(item: string) {
    return this.selectedFeedback.includes(item)
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  get adminCardStyle() {
    if (this.isUserAdmin && this.isDarkModeToggleEnabled) {
      return {
        backgroundColor: this.getColors.darkPrimaryColor,
        borderColor: this.getColors.darkFrameColor
      }
    }
  }
}
</script>
<style scoped>
.container {
  color: black !important;
  text-align: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}
.incident {
  min-height: 300px;
}
.reference {
  min-height: 300px;
}
.incident-image {
  max-height: 500px;
}
.reference-image {
  max-height: 500px;
}

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
  -webkit-transition: box-shadow 200ms;
  -moz-transition: box-shadow 200ms;
  border-radius: 3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}

.key {
  display: inline-block;
  user-select: none;
  width: 100%;
  height: 40px;
  border: 4px;
  border-radius: 0.3rem;
}

.key-custom-spacebar {
  font-size: 13px;
}

.key-spacebar {
  background: #4dd053;
}

.key-spacebar:disabled {
  background-color: #ccc;
}
</style>
