<template>
  <div class="text-center" style="z-index: 10001">
    <v-menu top :offset-y="offset">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="account-menu-button"
          depressed
          :color="!isDarkModeToggleEnabled ? '#e5e5e5' : ''"
          v-bind="attrs"
          v-on="on"
          title="Account"
        >
          <v-icon>mdi-account</v-icon>
          Account
        </v-btn>
      </template>

      <v-list :dark="isDarkModeToggleEnabled">
        <v-list-item
          @click="item.action"
          v-for="(item, index) in navigationMenuItems"
          :key="index"
          :disabled="item.disabled"
          :title="item.tooltip"
        >
          <v-list-item-icon>
            <v-icon
              :color="!isDarkModeToggleEnabled ? item.color : ''"
              :disabled="item.disabled"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
            :disabled="item.disabled"
            :color="!isDarkModeToggleEnabled ? item.color : ''"
            class="menu-item"
            >{{ item.text }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class NavigationAccountMenu extends Vue {
  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Prop({
    default: false,
    type: Boolean
  })
  public offset: boolean

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public navigationMenuItems = [
    {
      disabled: false,
      text: 'Settings',
      value: 'settings',
      icon: 'mdi-account',
      tooltip: 'Account settings',
      color: 'black',
      action: () => {
        const currentRoute = `/${window.location.pathname.split('/')[1]}`
        if (currentRoute !== '/user-settings') {
          this.$router.push({ name: 'CustomerManagement' })
        }
      }
    },
    {
      disabled: false,
      text: 'Logout',
      value: 'logout',
      icon: 'mdi-exit-to-app',
      tooltip: 'Logout from account',
      color: 'black',
      action: () => {
        this.$router.push({ name: 'Logout' })
      }
    }
  ]
}
</script>

<style scoped>
.menu-item:hover {
  background-color: #ffe88e !important;
  color: black !important;
}
.menu-item:active {
  background-color: #ffd42a !important;
}
.v-list {
  text-align: left;
}
.account-menu-button {
  position: absolute;
  bottom: 0;
  height: 40px;
  margin: 5px;
  width: 96%;
  text-transform: capitalize;
}
</style>
