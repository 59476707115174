var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visibility && _vm.totalAlarmQuotaPerMonth.isQuotaEnabled)?_c('div',{staticClass:"card-style black--text d-flex flex-column pl-5 pt-5 pr-5",style:(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
      ? _vm.getColors.lightPrimaryColor
      : _vm.getColors.darkBlackColor)},[_c('v-alert',{staticStyle:{"text-align":"center"},attrs:{"dense":"","outlined":"","color":'#ffd42a',"type":'info',"width":"100%"}},[_c('p',{staticStyle:{"margin":"0px","font-size":"medium","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.quotaTopic)+" ")])]),_c('p',{staticClass:"text-left",style:({
      fontSize: 'small',
      color:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.lightPrimaryColor
          : _vm.getColors.darkBlackColor
    })},[_vm._v(" "+_vm._s(_vm.quotaBody)+" ")]),_c('div',{staticClass:"pb-2"},[_c('v-progress-linear',{attrs:{"height":"10","color":"yellow","value":_vm.calculateProgress(),"rounded":""}})],1),_c('p',{staticClass:"text-left",style:({
      fontSize: 'small',
      fontWeight: 'bold',
      color:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.lightPrimaryColor
          : _vm.getColors.darkBlackColor
    })},[_vm._v(" "+_vm._s(_vm.totalAlarmCountPerMonth)+" / "+_vm._s(_vm.totalAlarmQuotaPerMonth.alarmQuota)+" incidents ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }