<template>
  <v-container
    fluid
    v-if="isDataAvailable() && !isOnlyRedundantReportedDataAvailable()"
    class="d-flex flex-column py-0 pr-2 pl-0 mb-2"
  >
    <p
      class="ml-4 my-0 text-xs text-left font-weight-thin"
      style="color: #b8b8b8; font-size: 0.875rem"
    >
      {{ facetName }}
    </p>
    <v-row no-gutters>
      <v-col class="d-flex flex-column justify-center">
        <label v-for="(facet, index) in facets" :key="facet.field">
          <label
            v-for="facetItem in facet.data"
            :key="getValue(facetItem, facet.type)"
          >
            <div
              v-if="
                isShown(facet.field) &&
                showRedundant(getValue(facetItem, facet.type), facet.field)
              "
              class="d-flex justify-start align-center input--border py-3 px-3"
            >
              <input
                type="checkbox"
                :value="getValue(facetItem, facet.type)"
                :checked="isChecked(getValue(facetItem, facet.type), index)"
                @change="$emit('change', { event: $event, facet: facet.field })"
                id="checkbox"
              />
              <span
                class="px-3"
                style="font-size: 1.15rem"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : ''
                }"
                >{{ getDisplayValue(facetItem, facet.type, facet) }}</span
              >
            </div>
          </label>
        </label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({ components: {} })
export default class MultiSearchFacet extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop() public facetName: string
  @Prop() public checked: any[]
  @Prop() public facets: any[]
  @Prop({ default: false }) public isAdmin: boolean

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public isChecked(value: string, index: number) {
    return this.checked[index] && this.checked[index].includes(value)
  }

  public getValue(facetItem, type) {
    return type === 'range' ? facetItem.value.name : facetItem.value
  }

  public getDisplayValue(facetItem, type, facet) {
    const alteredReportedValues = {
      report__person: 'person',
      report__vehicle: 'vehicle',
      report__approved: 'approved',
      report__unsure: 'unsure'
    }

    let displayValue = type === 'range' ? facetItem.value.name : facetItem.value
    if (facet.field === 'finalresult__vehiclemovement') {
      // TODO - There are some results that are still show finalresult__vehiclemovement true/false even after migration
      if (displayValue === 'true' || displayValue === 'false') {
        return 'vehicle ' + displayValue
      }
      return displayValue === 'alarm'
        ? 'vehicle'
        : displayValue === 'unsure'
        ? 'vehicle unsure'
        : 'no vehicle'
    }
    if (facet.field === 'finalresult__personassessment') {
      return displayValue === 'alarm'
        ? 'person'
        : displayValue === 'unsure'
        ? 'person unsure'
        : 'no person'
    }
    if (alteredReportedValues[facet.field]) {
      return alteredReportedValues[facet.field]
    }
    return displayValue
  }

  public isDataAvailable() {
    let dataAvailable = false
    for (let index = 0; index < this.facets.length; index++) {
      const facet = this.facets[index]
      dataAvailable = dataAvailable || Boolean(facet['data'].length)
    }
    return dataAvailable
  }

  public isShown(facet: string) {
    /** This function is to hide unsure and approved report classes from the customer */
    const adminViewOnly = ['report__unsure', 'report__approved']
    if (this.isAdmin) {
      return true
    } else {
      if (adminViewOnly.includes(facet)) {
        return false
      } else {
        return true
      }
    }
  }

  public showRedundant(value: string, facet: string) {
    /** This function is to hide false values of report classes(person,vehicle,approved, and unsure) from the display*/
    const redundantValues = [
      'report__person',
      'report__vehicle',
      'report__approved',
      'report__unsure'
    ]
    if (redundantValues.includes(facet) && value === 'false') {
      return false
    } else {
      return true
    }
  }

  public isOnlyRedundantReportedDataAvailable() {
    /** This  function is to hide Report title from customer when the customer only have incidents with report classes approved and unsure   */

    const redundantFacets = ['report__approved', 'report__unsure']
    if (this.facetName != 'Report') {
      return false
    }
    if (this.isAdmin) {
      return false
    } else {
      for (let i = 0; i < this.facets.length; i++) {
        const facet = this.facets[i]
        for (let j = 0; j < facet.data.length; j++) {
          if (
            facet.data[j].value === 'true' &&
            !redundantFacets.includes(facet.field)
          ) {
            return false
          }
        }
      }
    }
    return true
  }

  private mounted() {}
}
</script>

<style scoped>
.input--border {
  border: 1px solid #e1dede !important;
  border-radius: 8px;
}

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  -webkit-border-radius: 2px;
  border-radius: 3px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}
</style>
