<template>
  <div :class="smallScreen ? 'media-container-mobile' : 'media-container'">
    <div class="badge-container">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="unarmedStatus === 'true'"
            v-on="on"
            v-bind="attrs"
            class="status-badge unarmed-status-badge"
          >
            disarmed
          </div>
        </template>
        <span>Disarmed</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="isErrorIncident"
            v-on="on"
            v-bind="attrs"
            class="status-badge error-status-badge"
          >
            error
          </div>
        </template>
        <span>This is an error incident</span>
      </v-tooltip>
    </div>
    <div v-if="src">
      <v-lazy
        :options="{ threshold: 0.5 }"
        transition="fade-transition"
        :width="styles.width"
      >
        <img
          ref="image"
          v-if="type === 'image' || thumbnailType === 'image'"
          :src="src"
          @load="init"
          :class="smallScreen ? 'snapshot-media-mobile' : 'snapshot-media'"
          :style="`width: ${styles.width}; height: ${styles.height};  max-height: 27.5rem`"
          loading="lazy"
        />
        <video
          ref="video"
          v-else-if="type === 'video'"
          @loadedmetadata="init"
          :class="smallScreen ? 'snapshot-media-mobile' : 'snapshot-media'"
          :style="`width: ${styles.width}; height: ${styles.height}; max-height: 27.5rem`"
          :controls="controls"
          :poster="`/img/blankIncident.png`"
          preload="auto"
        >
          <source :src="`${src}#t=0.1`" />
        </video>
      </v-lazy>

      <canvas ref="canvas"></canvas>
    </div>
    <div
      :class="smallScreen ? 'snapshot-media-mobile' : 'snapshot-media'"
      v-else
      :style="`width: ${styles.width}; height: ${styles.height}`"
    >
      <div v-if="error">
        <v-alert
          :value="true"
          :dismissible="false"
          :color="
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor
          "
          :class="smallScreen ? 'thumbnail-size-mobile' : ''"
          :style="isUserAdmin && isDarkModeToggleEnabled ? 'color: white' : ''"
        >
          {{ error.message }}
        </v-alert>
      </div>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component
export default class Overlay extends Vue {
  @Prop() public src: string
  @Prop() public type: 'image' | 'video'
  @Prop() public thumbnailType?: string
  @Prop({ default: () => true }) public draw: boolean
  @Prop({ default: () => [] }) public excludedZones: any[]
  @Prop({ default: '#008000' }) public color: string
  @Prop({ default: 0.5 }) public opacity: number
  @Prop({ default: false }) public showWarning: boolean
  @Prop({ default: false }) public isPending: boolean
  @Prop({
    default: () => {
      return {
        width: isCompactView() ? '100px' : '180px',
        height: isCompactView() ? '65px' : '150px'
      }
    }
  })
  public styles: {
    width?: string
    height?: string
  }

  @Prop({ default: '' })
  public unarmedStatus: string

  @Prop({ default: false }) public isErrorIncident: boolean

  @Prop({
    default: () => {
      return { width: 0, height: 0 }
    }
  })
  public cameraMetadata: {
    width: number
    height: number
  }

  @Prop({ default: true }) controls: boolean
  @Prop({ default: false }) public loadOnMount: boolean

  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('currentUser', namespaceUser) public currentUser: { role: string }
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public metadata: {
    width: number
    height: number
  } = {
    width: 0,
    height: 0
  }

  public regions: { points: number[] }[] = []
  public isAspectRatioMatching: boolean | 'not-set' = 'not-set'
  public error: { message: string } | null = null
  public loading: boolean = false

  private createNewRegion() {
    return {
      points: []
    }
  }

  public async mounted() {
    if (this.isPending) {
      return
    }
    this.loading = true
    // watch for 10 seconds if src is not set set error
    setTimeout(() => {
      if (!this.src) {
        this.error = { message: `Thumbnail is not available` }
        this.loading = false
      }
    }, 10000)
    if (this.loadOnMount) {
      await this.init()
    }
  }

  public get smallScreen() {
    return isCompactView()
  }

  private convertToExcludedZones() {
    const excludedZones: any[] = []
    for (const region of this.excludedZones) {
      const newRegion = this.createNewRegion()
      for (const point of region.coordinates[0]) {
        // multiply with image width and height to get the actual coordinates
        newRegion.points.push(
          point[0] * this.metadata.width,
          point[1] * this.metadata.height
        )
      }
      excludedZones.push(newRegion)
    }
    return excludedZones
  }

  // on ExcludedZones change, redraw the canvas
  @Watch('excludedZones')
  private async onExcludedZonesChange() {
    await this.init()
  }

  @Watch('src')
  private async onSrcChange() {
    await this.init()
  }

  public async init() {
    this.loading = true
    try {
      await this.drawShapes()
    } catch (error) {
      if (error instanceof Error) {
        this.error = { message: error.message }
      } else {
        this.error = { message: 'An unknown error occurred' }
      }
    } finally {
      this.loading = false
    }
  }

  public async drawShapes() {
    const canvas = this.$refs.canvas as HTMLCanvasElement
    let media: HTMLImageElement | HTMLVideoElement
    if (this.type === 'image' || this.thumbnailType === 'image') {
      media = this.$refs.image as HTMLImageElement
    } else {
      media = this.$refs.video as HTMLVideoElement
    }

    // Immediately return if draw is false
    if (!this.draw) {
      return
    }

    // Immediately return if media is not loaded or canvas is not available
    if (!media || !canvas) {
      return
    }

    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    if (media instanceof HTMLVideoElement) {
      this.metadata.width = media.videoWidth
      this.metadata.height = media.videoHeight
    } else {
      this.metadata.width = media.width
      this.metadata.height = media.height
    }

    canvas.width = this.metadata.width
    canvas.height = this.metadata.height

    this.regions = this.convertToExcludedZones()
    for (const region of this.regions) {
      const points = region.points

      if (points.length > 0) {
        ctx.beginPath()
        ctx.moveTo(points[0], points[1])

        for (let i = 2; i < points.length; i += 2) {
          ctx.lineTo(points[i], points[i + 1])
        }

        ctx.closePath()
        ctx.fillStyle = this.color
        ctx.globalAlpha = this.opacity
        ctx.fill()

        // TODO : Add text "Excluded Zone", but it should fit in the zone;font size should be dynamic; text should be centered; orientation should be dynamic
      }
    }
  }
}
</script>

<style scoped>
.media-container {
  position: relative;
  display: inline-block;
}

.media-container-mobile {
  position: relative;
  display: inline-block;
}

.badge-container {
  position: absolute;
  right: 5px;
  bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-badge {
  border-radius: 20px;
  padding: 0 5px 1px 5px;
  font-size: 10px;
  margin-left: 2px;
}

.unarmed-status-badge {
  background-color: #4ed053;
}

.error-status-badge {
  background-color: #ff0000;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
  /* Able to click through */
  pointer-events: none;
  /* always show the canvas on top of the media */
  z-index: 0;
  /* do not overflow the media */
  width: 100%;
}

.aspect-ratio-indicator {
  /* position on the top right corner */
  position: absolute;
  top: 0;
  right: 0;
  /* make it small */
  font-size: 0.5rem;
  padding: 0.25rem;
  border-radius: 0 0 0 0.5rem;
  /* suitable color for background according to the foreground color of red */
  background-color: rgba(255, 0, 0, 0.25);
  z-index: 2;
}

.thumbnail-size-mobile {
  height: 58px;
  width: 100px;
  font-size: 12px;
}

.snapshot-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.snapshot-media-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
}
/* Set the position of the video controls to be relative */
video::-webkit-media-controls {
  position: relative;
}

/* Set the z-index of the video controls to be higher than the overlay */
video::-webkit-media-controls {
  z-index: 1;
}
</style>
