<template>
  <div
    :class="{ 'dark-mode': isUserAdmin && isDarkModeToggleEnabled }"
    class="flex-container"
  >
    <Button
      @onButtonClick="logout()"
      btnText="Logout"
      btnStyle="outlined"
      class="logout-btn"
      :class="`${getBtnStyle()}`"
      data-test-id="logout-btn"
    >
      <template #icon>
        <v-icon
          class="logout-btn-icon"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
          >mdi-logout-variant
        </v-icon>
      </template>
    </Button>
    <Button
      @onButtonClick="goToAccSettings()"
      btnText="Settings"
      btnStyle="outlined"
      class="settings-btn"
      :class="getBtnStyle()"
    >
      <template #icon>
        <v-icon
          size="20"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
          >mdi-account</v-icon
        >
      </template>
    </Button>
  </div>
</template>

<script lang="ts">
const namespaceUser = { namespace: 'user' }
const namespaceWorker = { namespace: 'worker' }
const namespaceConfig = { namespace: 'config' }

import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import Avatar from '@/components/user/Avatar.vue'

@Component({
  components: {
    Avatar,
    Button
  }
})
export default class AccountAvatar extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any

  @Action('setWorkerReady', namespaceWorker) public setWorkerReady

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserWorker() {
    return this.currentUser?.role === 'Worker'
  }

  public getBackgroundColor(): string {
    return this.isUserAdmin && this.isDarkModeToggleEnabled
      ? this.getColors.darkPrimaryColor
      : this.getColors.lightPrimaryColor
  }

  public async logout(): Promise<any> {
    if (this.isUserWorker) {
      const workerId: string = this.currentUser?.id
      await this.setWorkerReady({
        workerId,
        isWorkerReady: false,
        isLoggingOut: true
      })
    }
    return this.$router.push({ name: 'Logout' })
  }

  public goToAccSettings() {
    const curRoute = `/${window.location.pathname.split('/')[1]}`
    if (curRoute !== '/user-settings') {
      this.$router.push('/user-settings')
    }
  }

  public getBtnStyle() {
    return this.isUserAdmin && this.isDarkModeToggleEnabled
      ? 'button-text dark-mode'
      : ''
  }
}
</script>

<style lang="scss">
.button-text {
  color: white !important;
}

.dark-mode .logout-btn:hover,
.dark-mode .settings-btn:hover {
  background-color: #6b580e !important;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.logout-btn {
  margin-right: 8px !important;
  flex-grow: 1;
}
.settings-btn {
  flex-grow: 1;
}
.logout-btn-icon {
  font-size: 20px !important;
}
.settings-btn-icon {
  font-size: 20px !important;
}

@media screen and (min-width: 700px) and (max-width: 1200px) and (orientation: landscape) {
  .logout-btn {
    min-width: 100px !important;
    height: 50px !important;
    padding: 10px 20px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }

  .settings-btn {
    min-width: 124px !important;
    height: 50px !important;
    padding: 10px 20px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 1200px) and (orientation: portrait) {
  .logout-btn {
    width: 100px !important;
    height: 50px !important;
    padding: 10px 20px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }

  .settings-btn {
    width: 124px !important;
    height: 50px !important;
    padding: 10px 20px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
}
</style>
