<template>
  <div :width="width" v-html="returnAvatar"></div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { createAvatar } from '@dicebear/core'
import { botttsNeutral } from '@dicebear/collection'

@Component
export default class Avatar extends Vue {
  @Prop() private id!: string
  @Prop() private width: string
  @Prop() private size: number

  get returnAvatar() {
    const avatar = createAvatar(botttsNeutral, {
      seed: this.id ? this.id : 'test',
      size: this.size ? this.size : 48,
      radius: 5
    })
    const svg = avatar.toString()

    return svg
  }
}
</script>
