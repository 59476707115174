<template>
  <div
    class="card-style"
    style="
      padding-right: 19px;
      padding-left: 19px;
      padding-top: 19px;
      padding-bottom: 19px;
    "
  >
    <div class="form-item">
      <div>
        <p
          class="font-weight-bold text-left"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="contact-person-label"
        >
          Contact Person
        </p>
      </div>
      <v-text-field
        v-model="contactName"
        placeholder="Contact Person"
        hide-details="true"
        style="margin-top: -20px; font-size: 15px"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        :readonly="!isWriteEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        data-test-id="contact-person-input"
      />
    </div>
    <div class="form-item" style="padding-top: 20px">
      <div>
        <p
          class="font-weight-bold text-left"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="contact-number-label"
        >
          Contact Number
        </p>
      </div>
      <VuePhoneNumberInput
        v-model="contactNo"
        :defaultCountryCode="defaultCountryCode"
        placeholder="Contact Number"
        hide-details="true"
        @change="(value) => onChangeContactNo(value)"
        @update="onPhoneNumberUpdate"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        :readonly="!isWriteEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        type="tel"
        autocomplete="tel"
        :error-messages="contactNoError"
        :error="contactNoError.length > 0"
        data-test-id="contact-number-input"
      />

      <div>
        <p
          class="font-weight-bold text-left pt-4 mb-1"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="contact-message-label"
        >
          Message (Optional)
        </p>
      </div>
      <v-textarea
        dense
        v-model="message"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        outlined
        :placeholder="messagePlaceholder"
        :readonly="!isWriteEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        data-test-id="contact-message-input"
      ></v-textarea>

      <v-switch
        v-model="isCallEnabled"
        :disabled="!contactNo || isToggleDisabled"
        label="Receive call notifications"
        color="secondary"
        inset
        hide-details
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="contact-call-switch"
      ></v-switch>
      <v-switch
        v-model="isSmsEnabled"
        :disabled="!contactNo || isToggleDisabled"
        label="Receive SMS notifications"
        color="secondary"
        inset
        hide-details
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="contact-sms-switch"
      ></v-switch>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

interface PhoneNumberInputUpdateData {
  countryCallingCode: `${number}`
  countryCode: string
  nationalNumber: string
  isValid?: boolean
}

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button,
    VuePhoneNumberInput
  }
})
export default class Contact extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onChange: (value: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() isContactFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public contactName: string = ''
  public contactNo: string = ''
  // field to save contact number info
  public countryCallingCode: `+${number}` | null = null
  public phoneNumber: string = ''
  public countryCode: string = ''
  public message: string = ''
  public isValid: boolean = false
  public isCallEnabled: boolean = false
  public isSmsEnabled: boolean = false

  public messagePlaceholder: string =
    'Ex: Hello, promiseQ has detected a potential irregularity at one of your guarded sites, with a high probability of being genuine.'

  public originalContactData: any = null
  public contactNoError: string = ''

  //handling phone number input
  public onPhoneNumberUpdate(data: PhoneNumberInputUpdateData) {
    if (data.countryCallingCode) {
      this.countryCallingCode = ('+' + data.countryCallingCode) as `+${number}`
    }
    if (data.nationalNumber) {
      this.phoneNumber = data.nationalNumber
    }
    if (data.countryCode) {
      this.countryCode = data.countryCode
    }
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isContactFieldEnabled === null
      ? this.configuration?.blockToggles?.contact !== true
      : this.isContactFieldEnabled !== true && this.level !== 'client'
  }

  public mounted() {
    this.watchConfiguration()
  }
  public get defaultCountryCode() {
    if (!this.isToggleDisabled) {
      if (
        this.configuration?.contact?.countryCode &&
        this.configuration?.contact?.phoneNumber !== null
      ) {
        return this.configuration?.contact?.countryCode
      } else return 'DE'
    } else {
      if (this.level === 'site') {
        if (
          this.clientConfig?.contact?.countryCode &&
          this.clientConfig?.contact?.phoneNumber !== null
        ) {
          return this.clientConfig?.contact?.countryCode
        } else return 'DE'
      }
      if (this.level === 'camera') {
        if (
          this.siteConfig?.contact?.countryCode &&
          this.siteConfig?.blockToggles?.contact &&
          this.siteConfig?.contact?.phoneNumber !== null
        ) {
          return this.siteConfig?.contact?.countryCode
        } else {
          if (
            this.clientConfig?.contact?.countryCode &&
            this.clientConfig?.contact?.phoneNumber !== null
          ) {
            return this.clientConfig?.contact?.countryCode
          } else return 'DE'
        }
      }
    }
  }

  @Watch('configuration')
  @Watch('isContactFieldEnabled')
  private watchConfiguration() {
    let showingConfig: any
    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.contact
          ? this.siteConfig
          : this.clientConfig
      }
    }
    if (showingConfig) {
      this.contactName = showingConfig?.contact?.contactName ?? ''
      this.contactNo = showingConfig?.contact?.phoneNumber ?? ''
      this.countryCode = showingConfig?.contact?.countryCode ?? ''
      this.countryCallingCode = showingConfig?.contact?.countryCallingCode ?? ''
      this.message = showingConfig?.contact?.message ?? ''
      this.isCallEnabled = showingConfig?.contact?.isCallEnabled ?? false
      this.isSmsEnabled = showingConfig?.contact?.isSmsEnabled ?? false

      if (!this.contactNo) {
        this.isCallEnabled = false
        this.isSmsEnabled = false
      }

      this.originalContactData = {
        contactName: this.contactName,
        countryCallingCode: this.countryCallingCode,
        countryCode: this.countryCode,
        phoneNumber: this.contactNo,
        message: this.message,
        isCallEnabled: this.isCallEnabled,
        isSmsEnabled: this.isSmsEnabled
      }
    }
  }

  public hasDataChanged(): boolean {
    if (this.contactNo === null) {
      this.contactNo = ''
    }
    return (
      this.contactName?.trim() !== this.originalContactData.contactName ||
      this.countryCallingCode !== this.originalContactData.countryCallingCode ||
      this.contactNo !== this.originalContactData.phoneNumber ||
      this.message?.trim() !== this.originalContactData.message ||
      this.isCallEnabled !== this.originalContactData.isCallEnabled ||
      this.isSmsEnabled !== this.originalContactData.isSmsEnabled
    )
  }

  public onChangeContactNo(value: string) {
    if (!value) {
      this.isCallEnabled = false
      this.isSmsEnabled = false
    }
  }

  @Watch('contactName')
  @Watch('contactNo')
  @Watch('countryCallingCode')
  @Watch('phoneNumber')
  @Watch('message')
  @Watch('isCallEnabled')
  @Watch('isSmsEnabled')
  public onContactSave() {
    if (!this.isToggleDisabled) {
      let isError: boolean = false
      this.contactNoError = ''
      if (this.contactNo) {
        isError = true
        this.contactNoError = 'Please add contact number'
      }

      this.onChange({
        contactName: this.contactName?.trim(),
        countryCode: this.countryCode,
        countryCallingCode: this.countryCallingCode,
        phoneNumber: this.contactNo,
        message: this.message?.trim(),
        isCallEnabled: this.isCallEnabled,
        isSmsEnabled: this.isSmsEnabled,
        isError,
        isChanged: this.hasDataChanged()
      })
    }
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}
.toggle-buttons {
  margin-bottom: 20px;
}

.v-btn {
  min-width: 14px;
  height: 14px;
  padding: 0 4px;
  font-size: 12px;
}

.toggle-button-class {
  color: black;
  background-color: white;
  height: 30px !important;
  min-width: 35px !important;
}

.v-btn--active {
  color: black !important;
  background-color: #ffd42a !important;
}
</style>
