export function findBreadcrumbNodes(node, childId) {
  if (node.id === childId) {
    return [{ id: node.id, name: node.name }]
  }

  if (node.children && node.children.length) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i]
      const lineage = findBreadcrumbNodes(child, childId)
      if (lineage) {
        return [{ id: node.id, name: node.name }, ...lineage]
      }
    }
  }

  return null
}
