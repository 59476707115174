import firebase from 'firebase/compat/app'
import Vue from 'vue'

class apm {
  public initRouter(router: any) {
    let routerChangeSpan
    router.beforeEach((to, from, next) => {
      const currentUser = firebase.auth().currentUser
      const transaction = Vue.prototype.$apm.getCurrentTransaction()
      routerChangeSpan = Vue.prototype.$apm.startSpan('Changing Path')

      if (
        transaction !== undefined &&
        ['route-change', 'page-load'].includes(transaction.type)
      ) {
        transaction.name = to.path
        Vue.prototype.$apm.addLabels({
          from: from.path,
          to: to.path
        })
      }
      if (currentUser) {
        Vue.prototype.$apm.setUserContext({
          id: currentUser?.uid,
          email: currentUser?.email
        })
      }
      next()
    })

    router.afterEach(() => {
      if (routerChangeSpan) {
        routerChangeSpan.end()
      }
    })

    router.onError(() => {
      if (routerChangeSpan) {
        routerChangeSpan.end()
      }
    })
  }
}

export default new apm()
