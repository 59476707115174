<template>
  <div class="big-card">
    <nav>
      <span style="display: flex; align-items: center">
        <span class="mx-3">
          <v-chip
            v-if="result === 'unsure'"
            color="yellow"
            class="my-1 al-def-chip bg-yellow"
          >
            Unsure
          </v-chip>
          <v-chip v-if="result === 'alarm'" class="my-1 al-def-chip bg-red">
            Alarm
          </v-chip>
          <v-chip
            v-if="result === 'no-alarm'"
            class="my-1 al-def-chip bg-green"
          >
            No Alarm
          </v-chip>
        </span>
        <span class="mx-3">{{ incidentDate }}</span>
        <span class="mx-3">{{ incidentTime }}</span>
      </span>
      <v-icon v-if="expanded" class="pr-lg-2 close-button" @click="close"
        >mdi-arrow-collapse</v-icon
      >
      <v-icon v-else class="pr-lg-2">mdi-arrow-expand</v-icon>
    </nav>
    <div class="img-container">
      <div>
        <Overlay
          :src="incidentMediaURL"
          :type="mediaType"
          :opacity="0.5"
          :controls="true"
          :excludedZones="excludedZonesArray"
          :cameraMetadata="cameraMetadata"
          :loadOnMount="true"
          :showWarning="showWarning"
          :styles="{
            width: '100%',
            height: '100%'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Overlay from '@/components/excluded-zones/Overlay.vue'

@Component({
  components: {
    Overlay
  }
})
export default class IncidentView extends Vue {
  @Prop({ required: true }) incidentData: any
  @Prop({ required: true }) expanded!: boolean
  @Prop({ required: true }) excludedZones!: any
  @Prop({ required: true }) cameraMetadata: {
    width: number
    height: number
  }
  @Prop({ default: true }) showWarning: boolean

  public incidentId: string = ''

  public result: string = ''
  public incidentDate: string = ''
  public incidentTime: string = ''
  public incidentMediaURL: string = ''
  public mediaType: 'image' | 'video' = 'image'
  public excludedZonesArray: any = []

  private async mounted() {
    this.incidentId = this.incidentData.incidentId
    this.mediaType = this.incidentData.mediaType
    this.result = this.incidentData.finalResult
    const timeStamp = new Date(
      this.incidentData.createdAt._seconds * 1000
    ).toLocaleString()
    this.incidentDate = timeStamp.split(',')[0] // date
    this.incidentTime = timeStamp.split(',')[1] // time

    if (this.incidentData?.isUnarmed) {
      this.incidentMediaURL = this.incidentData?.originalMediaUrl
    } else {
      this.incidentMediaURL = this.incidentData.mediaUrl
    }

    try {
      this.excludedZonesArray = JSON.parse(this.excludedZones)
    } catch (e) {
      this.excludedZonesArray = []
    }
  }

  public close() {
    this.$emit('close')
  }
}
</script>

<style scoped>
.big-card {
  display: flex;
  flex-direction: column;
}

.big-card nav {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  background-color: rgb(171, 171, 171);
  font-size: 14px;
  padding: 8px;
  top: 0;
  width: 100%;
}

.al-def-chip {
  border-radius: 5px !important;
  float: left !important;
}

.bg-red {
  background-color: #ff6b6b !important;
}

.bg-green {
  background-color: #4ed053 !important;
}

.bg-yellow {
  background-color: #ffd42b !important;
}
.close-button {
  cursor: pointer;
}
</style>
