import axios from 'axios'

const TEST_FILE_URLS = [
  {
    size: 511_855,
    url: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Dahlia_x_hybrida.jpg'
  },
  {
    size: 1_093_957,
    url: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Pizigani_1367_Chart_1MB.jpg'
  },
  {
    size: 5_245_329,
    url: 'https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg'
  },
  {
    size: 18_462_554,
    url: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Clocktower_Panorama_20080622_20mb.jpg'
  }
]

function getUploadFileSizesForConnectionType(connectionType) {
  switch (connectionType) {
    case '4g':
      return [5, 2, 1]
    case '3g':
      return [2, 1, 0.5]
    case '2g':
      return [0.5, 0.1, 0.01]
    case '2g-slow':
      return [0.1, 0.01, 0.001]
    default:
      return [5, 2, 1]
  }
}

let connectionType: any
let TEST_FILE_SIZES: number[]
try {
  connectionType =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection
  TEST_FILE_SIZES = getUploadFileSizesForConnectionType(
    connectionType.effectiveType
  )
} catch (err) {
  console.log('This browser does not support the Network Information API')
  TEST_FILE_SIZES = [5, 2, 1]
}

const TEST_REPEAT_COUNT = 1 // Increase the number of test runs
const TEST_DURATION_LIMIT = 45_000 // 45 seconds

function debounce(
  func: { (progressEvent: any): void; (progressEvent: any): void; apply?: any },
  wait: number
) {
  let timeout: any
  return (...args: any) => {
    // @ts-ignore
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

async function testDownloadSpeed(onProgress) {
  const results = []
  let totalDuration = 0
  let totalSize = 0

  const startTime = performance.now()
  for (let url of TEST_FILE_URLS) {
    for (let i = 0; i < TEST_REPEAT_COUNT; i++) {
      const maxTime = Math.max(
        TEST_DURATION_LIMIT - (performance.now() - startTime),
        0
      )
      let lastSpeed = 0

      try {
        const response = await axios.get(url.url, {
          responseType: 'blob',
          params: { nocache: Math.random() },
          onDownloadProgress: debounce((progressEvent) => {
            if (!progressEvent.loaded) return
            const duration = (performance.now() - startTime) / 1000
            const fileSizeInBits = progressEvent.loaded * 8
            const speed = fileSizeInBits / (duration * 1000 * 1000)
            lastSpeed = speed
            onProgress(speed)
          }, 250),
          timeout: maxTime
        })

        const duration = (performance.now() - startTime) / 1000
        const fileSize = response.headers['content-length'] || url.size
        totalSize += fileSize
        totalDuration += duration
        const speed = (fileSize * 8) / (duration * 1000 * 1000)
        results.push(speed)
        lastSpeed = speed
      } catch (error) {
        console.error('Error during download speed test:', error)
      } finally {
        if (lastSpeed > 0) {
          onProgress(lastSpeed)
        }
      }
    }
  }

  const averageSpeed = (totalSize * 8) / (totalDuration * 1000 * 1000)
  onProgress(averageSpeed)
  return averageSpeed
}

async function testUploadSpeed(onProgress) {
  const results = []
  let totalDuration = 0
  let totalSize = 0

  const startTime = performance.now()
  for (let size of TEST_FILE_SIZES) {
    for (let i = 0; i < TEST_REPEAT_COUNT; i++) {
      const maxTime = Math.max(
        TEST_DURATION_LIMIT - (performance.now() - startTime),
        0
      )
      let lastSpeed = 0

      const data = new ArrayBuffer(size * 1024 * 1024)
      try {
        await axios.post('https://httpbin.org/post', data, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          params: { nocache: Math.random() },
          onUploadProgress: debounce((progressEvent) => {
            if (!progressEvent.loaded) return
            const duration = (performance.now() - startTime) / 1000
            const fileSizeInBits = progressEvent.loaded * 8
            const speed = fileSizeInBits / (duration * 1000 * 1000)
            lastSpeed = speed
            onProgress(speed)
          }, 250),
          timeout: maxTime
        })

        const duration = (performance.now() - startTime) / 1000
        const fileSize = size * 1024 * 1024
        totalSize += fileSize
        totalDuration += duration
        const speed = (fileSize * 8) / (duration * 1000 * 1000)
        results.push(speed)
        lastSpeed = speed
      } catch (error) {
        console.error('Error during upload speed test:', error)
      } finally {
        if (lastSpeed > 0) {
          onProgress(lastSpeed)
        }
      }
    }
  }

  const averageSpeed = (totalSize * 8) / (totalDuration * 1000 * 1000)
  onProgress(averageSpeed)
  return averageSpeed
}

export { testDownloadSpeed, testUploadSpeed }
