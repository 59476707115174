<template>
  <v-container class="container">
    <v-row justify="space-between">
      <v-col cols="6" class="reference">
        <v-row justify="center">
          <span
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
            class="text-body-1 font-weight-bold"
            >Reference picture</span
          >
        </v-row>
        <v-row
          v-if="currCameraRef && !!referenceImageUrl"
          justify="center"
          class="mr-1"
        >
          <v-img
            :src="referenceImageUrl"
            lazy-src="../../../public/img/lazy-load-frame.svg"
            @load="onRefImageLoad"
            alt="reference-image-live-health-check"
            class="reference-image"
            style="width: 100%"
          />
        </v-row>
        <v-row v-else justify="center" align="center" style="height: 100%">
          <v-col v-if="isRefImgLoading">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-col>
          <v-col v-else>
            <v-chip class="ma-2" color="error" variant="outlined">
              No reference image
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="incident">
        <v-row justify="center">
          <span
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
            class="text-body-1 font-weight-bold"
            >Live Stream</span
          >
        </v-row>
        <v-row
          justify="center"
          class="ml-1"
          style="height: 100%; width: 100%; max-height: 500px"
        >
          <LiveStreamPlayer
            :cameraId="currentHcDueCam.id"
            :cameraConfig="currentHcDueCam"
            :isSiteHardwareDevice="isSiteHardwareDevice"
            class="reference-image"
            style="width: 100%"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pt-6">
      <v-col cols="12">
        <v-row>
          <span
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
            class="text-body-1 font-weight-bold"
            >Report options</span
          >
        </v-row>
        <v-row class="pt-2">
          <v-card width="100%" outlined :style="adminCardStyle">
            <v-row class="pl-5 pr-5 pt-5" dense>
              <v-col cols="8">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="(item, index) in deviationOptions"
                    v-bind:key="index"
                  >
                    <v-row>
                      <v-col class="d-flex flex-column justify-center"
                        ><div class="d-flex justify-start py-2 px-1">
                          <input
                            type="checkbox"
                            :checked="isTicked(item)"
                            @change="handleFeedback(item)"
                            :disabled="!isDecisionEnabled"
                            :style="{
                              borderColor: !isDecisionEnabled
                                ? 'grey'
                                : '#ffd42a'
                            }"
                          />
                          <span
                            @click="handleFeedback(item)"
                            class="px-2"
                            :style="{
                              color: !isDecisionEnabled
                                ? 'grey'
                                : isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.lightPrimaryColor
                                : getColors.darkBlackColor
                            }"
                            >{{ item }}</span
                          >
                        </div></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-textarea
                  :dark="isUserAdmin && isDarkModeToggleEnabled"
                  dense
                  v-model="comment"
                  :prepend-inner-icon="comment != '' ? '' : 'mdi-pencil'"
                  outlined
                  placeholder="Add a comment"
                  :disabled="selectedFeedback.length === 0"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-5 mr-5 mb-5" justify="end">
              <v-col cols="1">
                <button
                  :class="'key key-custom-spacebar key-spacebar'"
                  @click="onSendCameraHealthComment"
                  style="width: 100px"
                  :disabled="selectedFeedback.length === 0"
                >
                  <v-icon dark>mdi-send</v-icon>
                </button>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          btnStyle="outlined"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import LiveStreamPlayer from '../camera/player/LiveStreamPlayer.vue'

const namespaceTodos = { namespace: 'todos' }
const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config', name: 'config' }

@Component({
  components: {
    LiveStreamPlayer
  }
})
export default class WorkerCameraHealthCheckLive extends Vue {
  @Prop() public currentHcDueCam!: any
  @Prop() public setCurrentHcDueCam: (camera: any) => void
  @Prop({ required: false, default: false }) isSiteHardwareDevice
  @Prop() public isUserAdmin: boolean

  @Action('fetchReferenceImageFromCameraRef', namespaceCamera)
  public fetchReferenceImageFromCameraRef
  @Action('fetchReferenceImageFromPath', namespaceCamera)
  public fetchReferenceImageFromRawUrl
  @Action('updateCamHcDueStatus', namespaceCamera) public updateCamHcDueStatus
  @Action('addLiveHealthCheck', namespaceCamera) public addLiveHealthCheck
  @Getter('getReferenceImageUrl', namespaceCamera) public refImgUrl
  @Getter('getCurrentTodoCameraRef', namespaceTodos) public currentTodoCameraRef
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public mediaUrl: string = null
  public referenceImageUrl: string = ''
  public comment: string = ''
  public selectedFeedback: string[] = []

  public isRefImgLoading = false

  //ToDo: Add required deviation option here
  public deviationOptions = [
    'Live Stream Deviation',
    'Reference Deviation',
    'No Deviation'
  ]

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public currCameraRef: any = null
  public currCameraDocId: any = null
  public currCameraId: any = null

  public liveStream: any = null

  // TODO: modify love stream player to get this value
  public isLiveStreamLoaded: boolean = true
  public isRefImageLoaded: boolean = false

  get isDecisionEnabled() {
    return true
    //This part is commented to enable action controls in every time, in case of refImage and liveStrea is not loaded,
    // the worker can not move to the next item in the queue.
    // TODO: remove this and implment a proper stratergy to solve this
    // return this.isLiveStreamLoaded && this.isRefImageLoaded
  }

  public onOnline(online: boolean) {
    console.log('The camera is online')
  }

  public async mounted() {
    this.setCameraDetails()
    this.setRefImgUrl()
  }

  public onRefImageLoad() {
    this.isRefImageLoaded = true
  }

  public setCameraDetails() {
    this.liveStream = this.currentHcDueCam?.liveStream
    this.currCameraDocId = this.currentHcDueCam?.id
    this.currCameraId = this.currentHcDueCam?.cameraId
    this.currCameraRef = `camera/${this.currCameraDocId}`
  }

  public async onSendCameraHealthComment() {
    const data: any = {
      camera: this.currCameraDocId,
      deviation: this.selectedFeedback,
      reportMsg: this.comment,
      referenceImage: this.currentHcDueCam.referenceImage,
      createdAt: new Date()
    }

    // Include edge data
    if (this.isSiteHardwareDevice) {
      data.edgeDevice = this.currentHcDueCam.edgeDevice
    } else {
      data.liveStream = this.liveStream
    }

    try {
      await Promise.all([
        this.addLiveHealthCheck(data),
        this.updateCamHcDueStatus({
          camera: this.currCameraRef,
          status: false
        })
      ])

      this.popSnackbarMessage('Health check done successfully')

      if (this.currCameraDocId === this.currentHcDueCam?.id) {
        this.setCurrentHcDueCam(null)
      }
    } catch (error) {
      console.error('Error during health check submission:', error)
      this.popSnackbarMessage('Error during health check submission')
    }
  }

  @Watch('currentHcDueCam')
  private async setRefImgUrl() {
    this.isRefImgLoading = true
    this.setCameraDetails()
    await this.getCameraData()

    if (this.currentHcDueCam?.referenceImage) {
      await this.fetchReferenceImageFromRawUrl(
        this.currentHcDueCam.referenceImage.path
      )
      this.referenceImageUrl = this.refImgUrl
    } else {
      this.referenceImageUrl = null
    }
    this.isRefImgLoading = false
  }

  private async getCameraData() {
    if (this.currCameraRef) {
      await this.fetchReferenceImageFromCameraRef(this.currCameraRef)
    }
  }

  public handleFeedback(value: string) {
    const isChecked = !this.isTicked(value)
    if (isChecked) {
      if (value === 'No Deviation') {
        this.selectedFeedback = []
      } else {
        this.selectedFeedback = this.selectedFeedback.filter((obj) => {
          return obj !== 'No Deviation'
        })
      }
      this.selectedFeedback.push(value)
    } else {
      this.selectedFeedback = this.selectedFeedback.filter((obj) => {
        return obj !== value
      })
    }
  }

  public isTicked(item: string) {
    return this.selectedFeedback.includes(item)
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  get adminCardStyle() {
    if (this.isUserAdmin && this.isDarkModeToggleEnabled) {
      return {
        backgroundColor: this.getColors.darkPrimaryColor,
        borderColor: this.getColors.darkFrameColor
      }
    }
  }
}
</script>
<style scoped>
.container {
  color: black !important;
  text-align: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}
.incident {
  min-height: 300px;
}
.reference {
  min-height: 300px;
}
.incident-image {
  max-height: 500px;
}
.reference-image {
  max-height: 500px;
}

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
  -webkit-transition: box-shadow 200ms;
  -moz-transition: box-shadow 200ms;
  border-radius: 3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}

.key {
  display: inline-block;
  user-select: none;
  width: 100%;
  height: 40px;
  border: 4px;
  border-radius: 0.3rem;
}

.key-custom-spacebar {
  font-size: 13px;
}

.key-spacebar {
  background: #4dd053;
}

.key-spacebar:disabled {
  background-color: #ccc;
}
</style>
