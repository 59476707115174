const getters = {
  loginUser: (state) => {
    return state.loginUser
  },
  currentUser: (state) => {
    return state.currentUser
  },
  getCurrentUserIsAskCrowdWorkers: (state) => {
    if (state.currentUser.isAskCrowdWorkers) {
      return state.currentUser.isAskCrowdWorkers
    }
    return false
  },
  messageUser: (state) => {
    return state.messageUser
  },
  createdUser: (state) => {
    return state.createdUser
  },
  getSelectedUser: (state) => {
    return state.selectedUser
  },
  allUser: (state) => {
    return state.allUser
  },
  isLoadingUsers: (state) => {
    return state.isLoadingUsers
  },
  permissionUser: (state) => {
    return state.permissionUser
  },
  getAllUsersIsBind: (state) => {
    return state.allUsersIsBind
  },
  getColors: (state) => {
    return state.colors
  },
  getEagleEyeAccounts: (state) => {
    return state.eagleEyeAccounts
  },
  getEagleEyeConnect: (state: {
    eagleEyeConnect: { progress: boolean; message: string }
  }) => {
    return state.eagleEyeConnect
  },
  getIsInitialUser: (state) => {
    return state.isInitialUser
  },
  getGlobalCameras: (state: any) => {
    return state.globalCameras
  },
  getGlobalLiveIsLoading: (state: any) => {
    return state.globalLiveIsLoading
  }
}

export default {
  ...getters
}
