import moment from 'moment'
import { includes, map } from 'lodash'
import { clientCollection } from '@/provider/firebase'

export enum Labels {
  'alarm' = 'Alarm',
  'no-alarm' = 'No-Alarm',
  'unsure' = 'Unsure', // Final assessment Unsure
  'total-incidents' = 'Total-Incidents'
}

export const getPercentage = (value: number) => {
  if (value) {
    if (value > 0 && value < 1) {
      return parseFloat(value.toFixed(2))
    } else {
      return parseFloat(value.toFixed(0))
    }
  }
  return 0
}

export const getReduction = (
  falseAlarmCount: number,
  totalAlarmCount: number
) => {
  if (falseAlarmCount === 0 && totalAlarmCount === 0) {
    return 0
  }
  const falseFraction = falseAlarmCount / totalAlarmCount
  return getPercentage(falseFraction * 100)
}

export const formatQuotaSummary = (data: any) => {
  let totalAlarmCountPerMonth = 0
  if (data?.aggregations?.['types_count']?.value) {
    totalAlarmCountPerMonth = data.aggregations['types_count'].value
  }
  return {
    totalAlarmCountPerMonth
  }
}

export const countNodes = async (treeItems, counts) => {
  await new Promise((resolve) => setTimeout(resolve, 1000))
  for (const node of treeItems) {
    if (node.nodeType !== 'normal') {
      continue
    }

    if (node.name === 'Default') {
      if (node.children) {
        countNodes(node.children, counts)
      }
      continue
    }

    if (node.children) {
      countNodes(node.children, counts)
    }

    if (node.type === 'camera') {
      counts.cameras++
    } else if (node.type === 'site') {
      counts.sites++
    } else if (node.type === 'client') {
      counts.clients++
    } else if (node.type === 'customer') {
      counts.customers++
    }
  }
}

export const getFixedIntervalMin = (startTime: any, endTime: any) => {
  let fixedIntervalMin = 90
  const timeDiff = moment
    .duration(moment(endTime).diff(moment(startTime)))
    .asHours()

  if (timeDiff > 240) {
    fixedIntervalMin = 1440
  } else if (timeDiff > 167) {
    fixedIntervalMin = 720
  } else if (timeDiff > 75) {
    fixedIntervalMin = 360
  } else if (timeDiff >= 24) {
    fixedIntervalMin = 180
  } else {
    fixedIntervalMin = 90
  }

  return fixedIntervalMin
}

export const convertMinutesToReadable = (minutes: number): string => {
  if (minutes <= 0) {
    return '0 minutes'
  }

  const days = Math.floor(minutes / 1440)
  const hours = Math.floor((minutes % 1440) / 60)
  const remainingMinutes = minutes % 60

  const parts = []

  if (days > 0) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`)
  }

  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
  }

  if (remainingMinutes > 0) {
    parts.push(`${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`)
  }

  return parts.join(' ')
}
