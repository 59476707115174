<template>
  <div class="card-style">
    <div
      :class="[
        { active: isExpanded },
        'text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="smtp-expand-title"
      >
        SMTP
      </div>
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="smtp-expand-icon"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>

    <div v-if="isExpanded" class="pr-3 pl-4 py-4 form">
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-host-label"
          >
            Host:
            <code
              class="pointer"
              v-text="'smtp.promiseq.com'"
              @click="onClickCopy('smtp.promiseq.com')"
              data-test-id="smtp-host-click-to-copy"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-port-label"
          >
            Port:
            <code
              class="pointer"
              v-text="'587'"
              data-test-id="smtp-port-click-to-copy"
              @click="onClickCopy('587')"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-username-label"
          >
            Username:
            <code
              class="pointer"
              data-test-id="smtp-username-click-to-copy"
              v-html="projectId"
              @click="onClickCopy(`${projectId}`)"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col class="d-flex align-center">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-password-label"
          >
            Password:
          </p>
          <v-select
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            style="max-width: 50%; height: 40px"
            v-model="selectedSmtpPassword"
            class="text-caption ml-1"
            append-icon="mdi-chevron-down"
            content-class="elevation-0"
            :items="subscriptionKeys"
            outlined
            hide-details="true"
            dense
            data-test-id="smtp-password-select"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
          <v-btn
            :ripple="false"
            text
            icon
            class="ml-1"
            @click="onClickCopy(selectedSmtpPassword)"
            data-test-id="smtp-password-copy-btn"
          >
            <v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              class="list-custom-icon"
              >{{ 'mdi-content-copy' }}</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters class="mb-4">
        <v-col class="d-flex">
          <div class="text-left tooltip" style="display: flex">
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              data-test-id="smtp-sender-address-label"
              style="margin-right: 5px"
              class="mb-0"
            >
              Sender Address:
            </p>
            <div
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              <code
                data-test-id="smtp-sender-address-click-to-copy"
                class="pointer"
                v-text="getSmtpSenderAddress"
                @click="onClickCopy(getSmtpSenderAddress)"
              ></code>
              <span class="tooltip-copy-text">Click To Copy</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-receiver-address-label"
          >
            Receiver Address:
            <code
              data-test-id="smtp-receiver-address-click-to-copy"
              class="pointer"
              v-text="getSmtpReceiverAddress"
              @click="onClickCopy(getSmtpReceiverAddress)"
            />
            <span class="tooltip-copy-text">Click To Copy</span>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip"
            data-test-id="smtp-security-label"
          >
            Security
            <code class="pointer" data-test-id="smtp-security-tls">TLS</code>
            &
            <code class="pointer" data-test-id="smtp-security-starttls"
              >STARTTLS</code
            >
          </p>
        </v-col>
      </v-row>

      <v-row no-gutters class="mb-4 regex-patterns-block" v-if="isUserAdmin">
        <v-col cols="12" class="mb-4 regex-patterns-header">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            class="text-left tooltip font-weight-bold"
            data-test-id="regex-patterns-label"
          >
            Regex Patterns
          </p>
        </v-col>

        <v-col>
          <v-row no-gutters>
            <v-col>
              <p
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
                class="text-left"
                data-test-id="regex-pattern-label-email-subject"
              >
                Email Subject
              </p>
            </v-col>

            <v-col cols="12" class="mb-4">
              <v-text-field
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                label="Pattern"
                outlined
                :error-messages="
                  isInvalidBodyRegexPattern ? 'Invalid Regex Pattern' : ''
                "
                v-model="subjectRegexPattern"
                :error="isInvalidSubjectRegexPattern"
                data-test-id="regex-pattern-input-email-subject-pattern"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                label="Example Subject"
                outlined
                hide-details="true"
                persistent-hint
                v-model="exampleEmailSubject"
                data-test-id="regex-pattern-input-example-email-subject"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <p
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
                data-test-id="regex-pattern-label-email-body"
                class="text-left"
              >
                Email Body
              </p>
            </v-col>

            <v-col cols="12" class="mb-4">
              <v-textarea
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                label="Pattern"
                outlined
                v-model="bodyRegexPattern"
                :hide-details="!isInvalidBodyRegexPattern"
                :error-messages="
                  isInvalidBodyRegexPattern ? 'Invalid Regex Pattern' : ''
                "
                :error="isInvalidBodyRegexPattern"
                data-test-id="regex-pattern-input-email-body-pattern"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-textarea
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                label="Example Body"
                outlined
                hide-details="true"
                v-model="exampleEmailBody"
                data-test-id="regex-pattern-input-example-email-body"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <p
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlackColor
                }"
                class="text-left red--text"
                data-test-id="regex-pattern-warning-msg-email-body"
              >
                *Example Email Content is required to match the pattern parts
                with Camera Id and Site Id
              </p>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-select
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :items="matchedAllRegexParts"
                label="Site Id"
                outlined
                hide-details="true"
                v-model="selectedSiteRegexPart"
                :disabled="!isWriteEnabled"
                return-object
                data-test-id="regex-pattern-input-site-id-select"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item.value }} - {{ item.type }}
                  </span>
                </template>
                <template #selection="{ item }">
                  {{ item.value }} - {{ item.type }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :dark="isUserAdmin && isDarkModeToggleEnabled"
                :items="matchedAllRegexParts"
                label="Camera Id"
                outlined
                hide-details="true"
                v-model="selectedCameraRegexPart"
                return-object
                :disabled="!isWriteEnabled"
                data-test-id="regex-pattern-input-camera-id-select"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item.value }} - {{ item.type }}
                  </span>
                </template>

                <template #selection="{ item }">
                  {{ item.value }} - {{ item.type }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="smtp-switch-label"
        >
          Turn on/off SMTP integration
        </div>
        <div>
          <v-switch
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            id="alarm-ftp-notification-switch"
            v-model="isSmtpEnabled"
            text
            class="notification-switch pt-1"
            color="secondary"
            inset
            @change="onToggleSmtpStatus"
            :disabled="!isWriteEnabled"
            data-test-id="smtp-switch"
          ></v-switch>
        </div>
      </div>
    </div>
    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text text-subtitle-2"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import { PROJECT_ID } from '@/utils/EnvMapping'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: { Button }
})
export default class SmtpConfiguration extends Vue {
  @Prop() configuration: any
  @Prop() cameraId: string
  @Prop() subscriptionKeys: string[]
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: false }) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isExpanded: boolean = false

  public senderAddress = 'notify@promiseq.com'
  public projectId: string = PROJECT_ID
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public selectedSmtpPassword = ''

  public passwordLength = 8

  public isSmtpEnabled: boolean = false

  public originalSmtpData: any = null

  public subjectRegexPattern = ''
  public bodyRegexPattern = ''

  public exampleEmailSubject = ''
  public exampleEmailBody = ''

  public matchedRegexSubjectParts = []
  public matchedRegexBodyParts = []
  public matchedAllRegexParts = []

  public isInvalidBodyRegexPattern = false
  public isInvalidSubjectRegexPattern = false

  public selectedSiteRegexPart = null
  public selectedCameraRegexPart = null

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  get getSmtpSenderAddress() {
    return 'notify@promiseq.com'
  }

  get getSmtpReceiverAddress() {
    return `${this.projectId}@promiseq.com`
  }

  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isSmtpEnabled = this.configuration.isSmtpEnabled ?? false
      this.isExpanded = this.isSmtpEnabled
      this.subjectRegexPattern =
        this.configuration.smtpData?.subjectRegexPattern ?? ''
      this.bodyRegexPattern =
        this.configuration.smtpData?.bodyRegexPattern ?? ''
      this.exampleEmailSubject =
        this.configuration.smtpData?.exampleEmailSubject ?? ''
      this.exampleEmailBody =
        this.configuration.smtpData?.exampleEmailBody ?? ''
      this.selectedSiteRegexPart =
        this.configuration.smtpData?.selectedSiteRegexPart ?? null
      this.selectedCameraRegexPart =
        this.configuration.smtpData?.selectedCameraRegexPart ?? null

      this.originalSmtpData = {
        subjectRegexPattern: this.subjectRegexPattern,
        bodyRegexPattern: this.bodyRegexPattern,
        exampleEmailSubject: this.exampleEmailSubject,
        exampleEmailBody: this.exampleEmailBody,
        selectedSiteRegexPart: this.selectedSiteRegexPart,
        selectedCameraRegexPart: this.selectedCameraRegexPart,
        isSmtpEnabled: this.isSmtpEnabled
      }
    }
  }

  @Watch('subscriptionKeys')
  public watchSubscriptionKeys() {
    if (this.subscriptionKeys && this.subscriptionKeys.length > 0) {
      this.selectedSmtpPassword = this.subscriptionKeys.at(0)
    }
  }

  @Watch('subjectRegexPattern')
  @Watch('exampleEmailSubject')
  public watchSubjectRegexPattern() {
    if (this.subjectRegexPattern.length > 0) {
      try {
        this.matchedRegexSubjectParts =
          this.exampleEmailSubject.match(
            new RegExp(this.subjectRegexPattern)
          ) ?? []
        this.isInvalidSubjectRegexPattern = false
      } catch (error) {
        this.matchedRegexSubjectParts = []
        this.isInvalidSubjectRegexPattern = true
      }
    } else {
      this.matchedRegexSubjectParts = []
      this.isInvalidSubjectRegexPattern = false
    }
  }

  @Watch('bodyRegexPattern')
  @Watch('exampleEmailBody')
  public watchBodyRegexPattern() {
    if (this.bodyRegexPattern.length > 0) {
      try {
        this.matchedRegexBodyParts =
          this.exampleEmailBody.match(new RegExp(this.bodyRegexPattern)) ?? []
        this.isInvalidBodyRegexPattern = false
      } catch (error) {
        this.matchedRegexBodyParts = []
        this.isInvalidBodyRegexPattern = true
      }
    } else {
      this.matchedRegexBodyParts = []
      this.isInvalidBodyRegexPattern = false
    }
  }

  @Watch('matchedRegexSubjectParts')
  @Watch('matchedRegexBodyParts')
  public watchMatchedRegexParts() {
    // Differentiate the matched regex parts into Subject and Body
    // Removing 1st element as it is a duplicated of the example content
    const subjectParts = this.matchedRegexSubjectParts
      .map((part) => {
        return { type: 'Subject', value: part }
      })
      .slice(1)

    const bodyParts = this.matchedRegexBodyParts
      .map((part) => {
        return { type: 'Body', value: part }
      })
      .slice(1)

    this.matchedAllRegexParts = [...subjectParts, ...bodyParts]
    this.refreshSelectedParts()
  }

  public refreshSelectedParts() {
    if (
      !this.matchedAllRegexParts.some(
        (item) => item.value === this.selectedSiteRegexPart?.value
      )
    ) {
      this.selectedSiteRegexPart = null
    }

    if (
      !this.matchedAllRegexParts.some(
        (item) => item.value === this.selectedCameraRegexPart?.value
      )
    ) {
      this.selectedCameraRegexPart = null
    }
  }

  public hasDataChanged(): boolean {
    return (
      this.subjectRegexPattern?.trim() !==
        this.originalSmtpData.subjectRegexPattern?.trim() ||
      this.bodyRegexPattern?.trim() !==
        this.originalSmtpData.bodyRegexPattern?.trim() ||
      this.exampleEmailSubject?.trim() !==
        this.originalSmtpData.exampleEmailSubject?.trim() ||
      this.exampleEmailBody?.trim() !==
        this.originalSmtpData.exampleEmailBody?.trim() ||
      this.selectedSiteRegexPart !==
        this.originalSmtpData.selectedSiteRegexPart ||
      this.selectedCameraRegexPart !==
        this.originalSmtpData.selectedCameraRegexPart
    )
  }

  public hasSwitchChanged(): boolean {
    return this.isSmtpEnabled !== this.originalSmtpData.isSmtpEnabled
  }

  public onToggleSmtpStatus(value) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }

  @Watch('selectedSiteRegexPart')
  @Watch('selectedCameraRegexPart')
  public watchSelectedRegexPart() {
    const smtpData = {
      subjectRegexPattern: this.subjectRegexPattern,
      bodyRegexPattern: this.bodyRegexPattern,
      exampleEmailSubject: this.exampleEmailSubject,
      exampleEmailBody: this.exampleEmailBody,
      selectedSiteRegexPart: this.selectedSiteRegexPart,
      selectedCameraRegexPart: this.selectedCameraRegexPart,
      isChanged:
        this.isInvalidBodyRegexPattern || this.isInvalidSubjectRegexPattern
          ? false
          : this.hasDataChanged() || this.hasSwitchChanged()
    }

    this.onChange(smtpData)
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
  margin-bottom: 0;
}

.tooltip .tooltip-copy-text {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

.tooltip .tooltip-copy-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltip-copy-text {
  visibility: visible;
  opacity: 1;
}

.pointer {
  cursor: pointer;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.regex-patterns-block {
  border-top: 1px solid #dadada;
  padding-top: 15px;
}

.regex-patterns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
