<template>
  <div
    :class="isOptionalHcOn ? 'gallery-element-health-check' : 'gallery-element'"
  >
    <div>
      <div
        :class="
          isOptionalHcOn
            ? 'gallery-container-health-check'
            : 'gallery-container'
        "
      >
        <div id="gallery-element-container" class="gallery-element-container">
          <v-progress-circular
            indeterminate
            v-if="!isTodoLoaded"
            color="amber"
            class="loader"
          ></v-progress-circular>
          <div v-if="mediaUrl !== null" class="carousel">
            <div
              :class="
                isOptionalHcOn
                  ? 'img-magnifier-container gallery-element-wrapper-health-check'
                  : 'img-magnifier-container gallery-element-wrapper'
              "
              @mousewheel="changeZoomLevel"
              @click="changeZoomLevel"
            >
              <div
                id="img-magnifier-glass"
                :style="
                  isMagnifierEnabled
                    ? 'visibility : visible'
                    : 'visibility : hidden'
                "
              >
                {{ currentZoomLevel }}x
              </div>
              <img
                id="todo-image"
                :class="
                  isOptionalHcOn
                    ? 'image-wrapper-health-check'
                    : 'image-wrapper'
                "
                @load="handleImageLoad"
                :contain="true"
                :src="currentImage"
                alt="todo-image"
              />
            </div>
            <div
              :class="
                isOptionalHcOn
                  ? 'progress-bar-wrapper-health-check'
                  : 'progress-bar-wrapper'
              "
            >
              <WorkerTodoTimer :timeStamp="timeStamp" />
              <div class="progress-bar"></div>
              <div
                v-if="todosByCameraId.length == 2"
                class="dividers divider"
                v-bind:style="{ border: borderColor }"
              ></div>
              <div
                v-if="todosByCameraId.length == 3"
                class="dividers divider-1"
                v-bind:style="{ border: borderColor1 }"
              ></div>
              <div
                v-if="todosByCameraId.length == 3"
                class="dividers divider-2"
                v-bind:style="{ border: borderColor2 }"
              ></div>
              <div
                class="moving-progress-bar"
                v-bind:style="{ width: width + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import WorkerTodoTimer from '../worker-space/WorkerTodoTimer.vue'

const namespaceTodos = { namespace: 'todos' }
@Component({
  components: {
    WorkerTodoTimer
  }
})
export default class Carousel extends Vue {
  @Prop() public todo!: any
  @Prop() public isOptionalHcOn!: any
  @Prop() public viewPortSize!: number
  @Prop() public isTodoLoaded: boolean
  @Prop() public isUserAdmin: boolean
  @Prop() public onTodoLoad: (isVisible: boolean) => void
  @Prop() public isMagnifierEnabled: boolean
  @Prop() public todosByCameraId: any[]
  @Prop() public selectedImage!: number
  @Prop() public isPaused: boolean
  @Prop() public timeStamp: { minutes: number; seconds: string }

  @Action('getDownloadUrl', namespaceTodos) public getDownloadUrl
  @Getter('getDownloadUrl', namespaceTodos) public downloadUrl

  private DEFAULT_ZOOM_LEVEL = 2
  private MAX_ZOOM_LEVEL = 5
  private ZOOM_INCREMENT = 1
  public currentZoomLevel = this.DEFAULT_ZOOM_LEVEL

  public currentImage: string = null
  public width: number = 0
  public borderColor: string = ''
  public borderColor1: string = ''
  public borderColor2: string = ''
  private index: number = 0
  private progressBarCount: number = 0
  private interval: any = null
  private videoContinuePoint: number = null
  public mediaUrl: string = null

  public mounted() {
    this.getMediaUrl()
    this.handleCarousel()
  }

  public async getMediaUrl() {
    if (this.todo.originalFile) {
      await this.getDownloadUrl(this.todo.originalFile.path)
      this.mediaUrl = this.downloadUrl
    } else {
      this.mediaUrl = this.todo.url
    }
  }
  public handleCarousel() {
    this.currentImage = this.todosByCameraId[this.index].originalFile.url

    if (this.todosByCameraId.length == 3) {
      this.interval = setInterval(() => {
        if (!this.isPaused) {
          this.progressBarCount = this.progressBarCount + 0.5
          this.width = this.progressBarCount
          if (this.progressBarCount >= 100 / 3) {
            this.index = 1
            this.borderColor1 = '2px solid #ffd42a'
          }
          if (this.progressBarCount >= 200 / 3) {
            this.index = 2
            this.borderColor2 = '2px solid #ffd42a'
          }
          if (this.progressBarCount >= 100) {
            this.index = 0
            this.progressBarCount = 0
            this.borderColor1 = '2px solid #ffffff'
            this.borderColor2 = '2px solid #ffffff'
          }
          this.currentImage = this.todosByCameraId[this.index].originalFile.url
        }
      }, 50)
    }
    if (this.todosByCameraId.length == 2) {
      this.interval = setInterval(() => {
        if (!this.isPaused) {
          this.progressBarCount = this.progressBarCount + 0.5
          this.width = this.progressBarCount
          if (this.progressBarCount >= 100 / 2) {
            this.index = 1
            this.borderColor = '2px solid #ffd42a'
          }
          if (this.progressBarCount >= 100) {
            this.index = 0
            this.progressBarCount = 0
            this.borderColor = '2px solid #ffffff'
          }
          this.currentImage = this.todosByCameraId[this.index].originalFile.url
        }
      }, 50)
    }
  }

  @Watch('isPaused')
  public watchIsPaused() {
    if (!this.isPaused) {
      this.videoContinuePoint = this.progressBarCount
      clearInterval(this.interval)
      this.progressBarCount = this.videoContinuePoint
      this.handleCarousel()
    }
  }
  @Watch('currentImage')
  public watchCurrentImage() {
    this.$emit('activeImage', this.index)
  }

  @Watch('todosByCameraId')
  public watchTodo() {
    clearInterval(this.interval)
    this.index = 0
    this.progressBarCount = 0
    this.handleCarousel()
  }

  @Watch('selectedImage')
  public watchSelectedImage() {
    if (this.todosByCameraId.length == 3) {
      if (this.selectedImage == 0) {
        this.index = 0
        this.progressBarCount = 0
      } else if (this.selectedImage == 1) {
        this.index = 1
        this.progressBarCount = 100 / 3
      } else if (this.selectedImage == 2) {
        this.index = 2
        this.progressBarCount = 200 / 3
      }
    } else {
      if (this.selectedImage == 0) {
        this.index = 0
        this.progressBarCount = 0
      } else if (this.selectedImage == 1) {
        this.index = 1
        this.progressBarCount = 100 / 2
      }
    }
  }

  public changeZoomLevel(event) {
    if (event.type === 'click') {
      if (this.currentZoomLevel == this.MAX_ZOOM_LEVEL) {
        this.currentZoomLevel = this.DEFAULT_ZOOM_LEVEL
      } else {
        this.currentZoomLevel += this.ZOOM_INCREMENT
      }
    } else {
      if (event.deltaY < 0 && this.currentZoomLevel < this.MAX_ZOOM_LEVEL) {
        this.currentZoomLevel += this.ZOOM_INCREMENT
      } else if (
        event.deltaY > 0 &&
        this.currentZoomLevel > this.DEFAULT_ZOOM_LEVEL
      ) {
        this.currentZoomLevel -= this.ZOOM_INCREMENT
      }
    }

    this.magnify(this.currentZoomLevel)
  }

  public handleImageLoad() {
    this.onTodoLoad(true)
    this.magnify(this.currentZoomLevel)
  }

  private magnify(zoom: number) {
    let img, glass: HTMLElement, w: number, h: number, bw: number
    img = document.getElementById('todo-image')

    glass = document.getElementById('img-magnifier-glass')
    glass.setAttribute('class', 'img-magnifier-glass')

    // Insert magnifier glass:
    img.parentElement.insertBefore(glass, img)

    // Set background properties for the magnifier glass:
    glass.style.backgroundImage = "url('" + img.src + "')"
    glass.style.backgroundRepeat = 'no-repeat'
    glass.style.backgroundSize =
      img.width * zoom + 'px ' + img.height * zoom + 'px'
    bw = 3
    w = glass.offsetWidth / 2
    h = glass.offsetHeight / 2

    glass.addEventListener('mousemove', moveMagnifier)
    img.addEventListener('mousemove', moveMagnifier)

    function moveMagnifier(e) {
      let pos: { x: number; y: number }, x: number, y: number
      e.preventDefault()
      // Get the cursor's x and y positions:
      pos = getCursorPos(e)
      x = pos.x
      y = pos.y
      // Prevent the magnifier glass from being positioned outside the image:
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom
      }
      if (x < w / zoom) {
        x = w / zoom
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom
      }
      if (y < h / zoom) {
        y = h / zoom
      }
      // Set the position of the magnifier glass:
      glass.style.left = x - w + 'px'
      glass.style.top = y - h + 'px'
      // Display what the magnifier glass "sees":
      glass.style.backgroundPosition =
        '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px'
    }

    function getCursorPos(e) {
      let a,
        x = 0,
        y = 0
      e = e || window.event
      // Get the x and y positions of the image:
      a = img.getBoundingClientRect()
      // Calculate the cursor's x and y coordinates, relative to the image:
      x = e.pageX - a.left
      y = e.pageY - a.top
      // Consider any page scrolling:
      x = x - window.pageXOffset
      y = y - window.pageYOffset
      return { x, y }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.img-magnifier-container {
  position: relative;
  z-index: 5;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #ffd42a;
  cursor: none;
  border-radius: 50%;
  /* Set the size of the magnifier glass: */
  width: 160px;
  height: 160px;
}

.gallery-element-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gallery-element-wrapper {
  height: 98%;
  width: 100%;
}
.gallery-element-wrapper-health-check {
  width: 100%;
}
.gallery-container {
  display: flex;
  height: calc(100vh - 220px);
}
.gallery-container-health-check {
  display: flex;
  height: 100%;
}
.gallery-element {
  width: 100vw;
  height: calc(100vh - 220px);
}
.gallery-element-health-check {
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
}

.actions-wrapper {
  width: 100%;
  display: flex;
}

.worker-align {
  justify-content: flex-end;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.image-wrapper-health-check {
  width: 100%;
  position: relative;
  z-index: -1;
}

.progress-bar-wrapper {
  width: 100%;
  position: relative;
}
.progress-bar-wrapper-health-check {
  top: -16px;
  width: 100%;
  position: relative;
}
.progress-bar {
  height: 10px;
  background-color: rgb(236, 236, 236);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.moving-progress-bar {
  position: absolute;
  top: 0;
  background-color: #ffd42a;
  height: 10px;
  max-width: 100%;
  z-index: 5;
}

.dividers {
  width: 8px;
  background-color: rgb(7, 7, 7);
  height: 22px;
  position: absolute;
  top: -6px;
  z-index: 6;
  border: 2px solid rgb(241, 241, 241);
  border-radius: 5px;
}
.divider {
  left: calc(50%);
}
.divider-1 {
  left: calc(100% * 1 / 3);
}
.divider-2 {
  left: calc(100% * 2 / 3);
}

.deadline-timer-container {
  bottom: 23px;
}
</style>
