<template>
  <v-row
    class="card-style notification-data d-flex justify-between align-center pb-0 pt-0"
  >
    <v-col>
      <v-row
        class="card-style notification-data d-flex justify-between align-center pb-0 pt-0"
        :class="
          isUserAdmin && isDarkModeToggleEnabled
            ? 'notification-data-admin'
            : 'notification-data'
        "
      >
        <v-col cols="6">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
            style="text-align: left; margin: 0px"
          >
            Notification Type
          </p>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="localNotificationType"
            :items="notificationTypes"
            item-text="name"
            item-value="value"
            :disabled="!isWriteEnabled"
            @change="onNotificationTypeChange"
            outlined
            dense
            append-icon="mdi-chevron-down"
            content-class="elevation-0"
            class="text-caption"
            placeholder="Notification Type"
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            hide-details="true"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item.name }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row
        class="card-style notification-data d-flex justify-between align-center pb-0 pt-0"
        :class="
          isUserAdmin && isDarkModeToggleEnabled
            ? 'notification-data-admin'
            : 'notification-data'
        "
      >
        <v-col cols="6">
          <p
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
            style="text-align: left; margin: 0px"
          >
            Inherit Alarm Notification Data
          </p>
        </v-col>

        <v-col cols="6">
          <v-row class="d-flex justify-end align-center">
            <v-tooltip
              left
              :open-on-click="false"
              :open-on-focus="false"
              nudge-left="6"
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="15"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkPrimaryColor
                  }"
                  >mdi-information-variant-circle
                </v-icon>
              </template>

              <span class="mx-2 white--text"
                >ON: health check notification configs are inherited by alarm
                notification configs<br />
                OFF: health check notification configs use its own data</span
              >
            </v-tooltip>
            <v-switch
              :disabled="isToggleDisabled || !isWriteEnabled"
              v-model="isNotificationInheritToggleEnabled"
              color="secondary"
              @change="toggleNotificationInheritSwitch"
              inset
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :small="true"
              class="pl-2"
            ></v-switch>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {}
})
export default class NotificationInheritHeader extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('updateNotificationInheritToggle', namespaceConfig)
  public updateNotificationInheritToggle

  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop({ default: null }) configuration!: any
  @Prop({ default: '' }) type!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string

  public isNotificationInheritToggleEnabled: boolean = false

  public localNotificationType: string = 'Alarm'
  public notificationTypes: { name: string; value: string }[] = [
    { name: 'Alarm', value: 'Alarm' },
    { name: 'Health Check', value: 'HC' }
  ]

  public mounted() {
    this.localNotificationType = this.notificationType
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return (
      this.configuration?.blockToggles?.notifications !== true &&
      this.type !== 'client'
    )
  }

  @Watch('notificationType')
  public onNotificationTypePropChange(newVal: string) {
    this.localNotificationType = newVal
  }

  public onNotificationTypeChange() {
    this.$emit('update:notificationType', this.localNotificationType)
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.type === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.type === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      const currentConfig: boolean =
        showingConfig?.isNotificationDataInherited ?? true
      this.isNotificationInheritToggleEnabled = currentConfig
    }
  }

  public async toggleNotificationInheritSwitch(value: boolean) {
    await this.updateNotificationInheritToggle({
      type: this.type,
      docId: this.configuration.id,
      value
    })
  }
}
</script>

<style scoped>
.notification-data {
  background-color: #e5e5e5 !important;
}
.notification-data-admin {
  background-color: #938d76 !important;
}
</style>
