<template>
  <div v-if="selectedElements.length > 0">
    <v-flex v-if="this.numberOfAllElements">
      Total elements: {{ numberOfAllElements }}
      <a class="bulk-actions__link ml-2 mr-1" @click="emitSelectionEvent(true)"
        >Select</a
      >
      |
      <a class="bulk-actions__link ml-1" @click="emitSelectionEvent(false)"
        >Deselect</a
      >
    </v-flex>
    <div class="bulk-actions__bar">
      <div class="bulk-actions__heading-label">
        <h4>{{ selectedElements.length }} selected</h4>
      </div>

      <div class="gt-media-item m-delete-video">
        <v-btn
          color="black"
          large
          text
          v-for="action in bulkActions"
          :key="action.event"
          @click="emitBulkActionEvent(action.eventName)"
          :disabled="action.disabled"
          :data-ci-bulk-action="action.label"
        >
          <v-icon class="icon-position ma-1">{{ action.icon }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DataTable extends Vue {
  @Prop() private selectedElements!: object[]
  @Prop() private bulkActions!: object[]
  @Prop() private numberOfAllElements!: number

  public emitSelectionEvent(value: boolean) {
    this.$emit('selectAll', value)
  }

  public emitBulkActionEvent(event: string) {
    this.$emit(event)
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../variables.styl';
.bulk-actions__bar {
    margin-bottom: 3px;
    border-radius: 0.15rem;
    padding-left: 20px !important;
    & .bulk-actions__heading-label {
        max-width: 200px;
        flex-grow: 1;
    }
    background-color: $hagl-pmq-yellow;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
}
.bulk-actions__link {
    color: $hagl-pmq-yellow;
}
</style>

<style lang="stylus"></style>
