import { functions } from '@/provider/firebase'

export default {
  namespaced: true,
  fetchDataFromElastic(payload) {
    return functions
      .httpsCallable('fetchDataFromElastic')(payload)
      .then((res) => res)
      .catch((err) => {
        console.log(err)
        return null
      })
  }
}
