var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',[_c('v-flex',[_c('div',{staticClass:"elevation-2 card-style pl-0"},[_c('div',{staticClass:"pa-0"},[_c('v-treeview',{staticClass:"pl-3 pr-1",attrs:{"id":"subscriptionKeyTree","items":_vm.subscriptionKeyList,"item-key":"name","disabled":_vm.isRemovingKey,"data-test-id":"subscription-key-tree"},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('div',{staticClass:"subscription-key-row px-0 py-1"},[_c('div',{style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.darkBlackColor
                }),attrs:{"data-test-id":"subscription-key-label"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"row-icon-container"},[(item.name == 'Subscription Keys')?_c('v-text-field',{staticStyle:{"padding":"5px","margin":"5px","width":"150px"},attrs:{"disabled":!_vm.isWriteEnabled,"id":"subscription-key-length","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"type":"number","placeholder":"Length","min":"8","max":"32","hide-details":"true","data-test-id":"subscription-key-length-input"},model:{value:(_vm.subscriptionKeyLength),callback:function ($$v) {_vm.subscriptionKeyLength=$$v},expression:"subscriptionKeyLength"}}):_vm._e(),(item.name == 'Subscription Keys')?_c('v-btn',{staticClass:"button",style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : _vm.getColors.darkBlackColor
                  }),attrs:{"disabled":!_vm.isWriteEnabled,"ripple":false,"text":"","icon":"","data-cy":"subscriptionKey-create","data-test-id":"subscription-key-create-button"}},[(_vm.isKeyGenerating)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"20","data-test-id":"subscription-key-create-progress"}}):_c('v-icon',{staticClass:"list-custom-icon",attrs:{"disabled":!_vm.isWriteEnabled,"title":"Create a new subscription Key"},on:{"click":function($event){return _vm.onClickAddKey()}}},[_vm._v(_vm._s('mdi-key-plus'))])],1):_vm._e(),(item.name !== 'Subscription Keys' && !item.default)?_c('v-btn',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : _vm.getColors.darkBlackColor
                  }),attrs:{"ripple":false,"text":"","icon":"","disabled":_vm.isRemovingKey,"data-cy":"subscriptionKey-remove","data-test-id":"subscription-key-remove-button"}},[_c('v-icon',{staticClass:"list-custom-icon",on:{"click":function($event){return _vm.onClickRemoveKey(item.name)}}},[_vm._v(_vm._s('mdi-key-remove'))])],1):_vm._e(),(item.name !== 'Subscription Keys')?_c('v-btn',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : _vm.getColors.darkBlackColor
                  }),attrs:{"ripple":false,"text":"","icon":"","data-test-id":"subscription-key-copy-button"}},[(_vm.hasdefaultKey)?_c('v-icon',{staticClass:"list-custom-icon",on:{"click":function($event){return _vm.onClickCopy(item.name)}}},[_vm._v(_vm._s('mdi-content-copy'))]):_vm._e()],1):_vm._e()],1)])]}}])})],1)])]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text text-subtitle-2",attrs:{"text":"","btnText":"Close","data-test-id":"subscription-key-snackbar-close-button"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }