<template>
  <div>
    <div v-if="incidentsPerCustomerStatus !== 'Ready'" class="loading-spinner">
      <div v-if="incidentsPerCustomerStatus === 'Error'">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is an error while loading this chart
        </p>
      </div>
      <div v-if="incidentsPerCustomerStatus === 'Loading'">
        <v-progress-circular indeterminate color="secondary" />
      </div>
    </div>
    <div v-if="incidentsPerCustomerStatus === 'Ready'" class="my-2 pa-4">
      <div v-if="incidentsPerCustomerData.length > 0">
        <GChart
          key="incidents-per-customer-chart"
          type="ColumnChart"
          :data="modifiedChartData"
          :options="chartOptions"
        />
      </div>
      <div v-else>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is not enough data to show this chart
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { chartOptions } from '../../utils/Charts'
import { GChart } from 'vue-google-charts/legacy'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }

@Component({
  components: {
    GChart
  }
})
export default class IncidentsPerCustomerChart extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getIncidentsPerCustomerData', namespaceStatistics)
  public incidentsPerCustomerData: any[]
  @Getter('getIncidentsPerCustomerStatus', namespaceStatistics)
  public incidentsPerCustomerStatus: string

  @Action('fetchIncidentsPerCustomersData', namespaceStatistics)
  fetchIncidentsPerCustomersData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  public fetchChartData = debounce(async () => {
    await this.fetchIncidentsPerCustomersData({
      startTime: this.startTime,
      endTime: this.endTime
    })
  }, 300)

  public get modifiedChartData(): any[] {
    return [
      ['Customer Subscription Keys', 'Incidents count'],
      ...this.incidentsPerCustomerData
    ]
  }

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkBlackColor
  }

  public get seriesColor(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkChartSeriesColor
  }

  public get chartOptions(): any {
    return chartOptions({
      hAxisTitle: 'Customer Emails',
      vAxisTitle: 'Number of incidents',
      textColors: this.textColors,
      seriesColor: this.seriesColor
    })
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
