<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div class="d-flex justify-left align-center pl-2">
      <Button
        @onButtonClick="goBack()"
        :class="['back-button', getBtnStyle()]"
        :btnStyle="'default pl-1'"
      >
        <span class="v-btn__content">
          <v-icon>mdi-menu-left</v-icon>
          <div
            :style="{ color: isDarkModeToggleEnabled ? 'white' : 'black' }"
            class="text-body-2"
          >
            Back
          </div>
        </span>
      </Button>
    </div>

    <v-row class="pl-2">
      <v-col cols="7" class="pr-1 pb-6">
        <SimpleGalleryElement
          v-if="isTypeIncident"
          :mediaPath="selectedHealthCheck.processedFile.path"
          :mediaType="selectedHealthCheck.mediaType"
          :isThumbnail="false"
        />
        <HealthCheckLiveStreamPlayer
          v-if="!isTypeIncident"
          :selectedHealthCheck="selectedHealthCheck"
          :liveStreamQuality="liveStreamQuality"
        />
        <p
          v-if="!isTypeIncident"
          class="text-left pt-4"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          <span class="bold-high-text">Live Stream:&nbsp;</span>
          <span>{{ selectedHealthCheck.healthCheck.liveStream.url }}</span>
        </p>
        <div class="d-flex justify-left align-center pt-2">
          <p
            class="mb-0 pr-3 bold-high-text"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            Status:
          </p>
          <v-btn
            v-for="deviation in selectedHealthCheck.healthCheck.deviation"
            :key="deviation"
            class="py-0 px-2 mr-1"
            style="
              text-transform: none;
              color: #ffffff;
              height: 25px;
              font-size: 12px;
              box-shadow: none;
            "
            :style="`background-color: ${
              deviation === 'No Deviation' ? '#4dd053' : '#ff5252'
            }`"
          >
            {{ deviation }}
          </v-btn>
        </div>
        <div v-if="selectedHealthCheck.healthCheck.reportMsg !== ''">
          <p
            class="text-left bold-high-text mb-0 mt-3"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            Comment
          </p>
          <div
            class="px-2 py-2"
            style="border: 1px solid #c4c4c4 !important; border-radius: 5px"
          >
            <p
              class="text-left mb-0"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              {{ selectedHealthCheck.healthCheck.reportMsg }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="5" class="pl-1 pr-5">
        <v-img
          :src="referenceImageUrl"
          lazy-src="../../../public/img/lazy-load-frame.svg"
          alt="todo-image-incident-health-check"
        ></v-img>
        <p
          v-if="referenceImageUrl"
          class="text-left bold-high-text mb-0 py-3"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          Reference image
        </p>
        <p
          class="text-left mb-0"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          <span class="bold-med-text">Check datetime:</span>
          <span class="text-detail-grey">
            {{
              getFormattedDate(selectedHealthCheck.healthCheck.createdAt)
            }}</span
          >
        </p>
        <p
          class="text-left mb-0"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          <span class="bold-med-text" style="display: inline">Location:</span>
          <span class="text-detail-grey">
            <HealthCheckBreadCrumb
              :selectedHealthCheck="selectedHealthCheck"
              :configLevel="configLevel"
          /></span>
        </p>
        <p
          class="text-left mb-0"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          <span class="bold-med-text">Camera ID:</span>
          <span class="text-detail-grey">
            {{ cameraId }}
          </span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import SimpleGalleryElement from '../events/SimpleGalleryElement.vue'
import HealthCheckBreadCrumb from './HealthCheckBreadCrumb.vue'
import HealthCheckLiveStreamPlayer from './HealthCheckLiveStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'
import Button from '../app/Button.vue'
import moment from 'moment'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceHealthCheck = { namespace: 'liveHealthCheck' }

@Component({
  components: {
    SimpleGalleryElement,
    HealthCheckLiveStreamPlayer,
    Button,
    HealthCheckBreadCrumb
  }
})
export default class HealthCheckDetailed extends Vue {
  @Prop() public selectedHealthCheck!: any
  @Prop() public configLevel!: string

  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('fetchReferenceImageFromPath', namespaceCamera)
  public fetchReferenceImageFromRawUrl
  @Action('fetchCameraId', namespaceHealthCheck)
  private fetchCameraId

  public referenceImageUrl: string = ''
  public isCamIdLoading: boolean = false
  public cameraId: string = ''
  public liveStreamQuality = LiveStreamQualityType.HIGH

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isTypeIncident() {
    return 'mediaType' in this.selectedHealthCheck
  }

  public async mounted() {
    await this.setRefImageUrl()
    await this.fetchCameraIdForHealthCheck()
  }

  public goBack() {
    this.$emit('update:selectedHealthCheck', null)
  }

  public getFormattedDate(dateTime: any) {
    const convertedDate = new Date(
      dateTime.seconds * 1000 + dateTime.nanoseconds / 1000000
    )
    return moment(convertedDate).format('DD/MM/YY HH:mm')
  }

  public async setRefImageUrl() {
    this.referenceImageUrl = await this.fetchReferenceImageFromRawUrl(
      this.selectedHealthCheck.healthCheck.referenceImage.path
    )
  }

  public async fetchCameraIdForHealthCheck() {
    this.isCamIdLoading = true
    this.cameraId = await this.fetchCameraId({
      cameraRef: this.selectedHealthCheck.cameraRef
    })
    this.isCamIdLoading = false
  }
  public getBtnStyle() {
    if (this.isUserAdmin && this.isDarkModeToggleEnabled) {
      return 'dark-mode-button'
    } else {
      return ''
    }
  }
}
</script>

<style scoped>
.bold-med-text {
  font-weight: 500;
}

.back-button {
  margin: 10px 0;
  color: black;
  background-color: #e5e5e5 !important;
  padding: 0 18px 0 12px !important;
}
.dark-mode-button {
  background-color: #6f6c61 !important;
  color: white !important;
}

.dark-mode-button svg path {
  fill: white !important;
}

.bold-high-text {
  font-weight: 700;
}

.text-detail-grey {
  color: #c4c4c4;
}
</style>
