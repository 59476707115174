<template>
  <div class="camera">
    <img
      :id="videoElementId"
      width="100%"
      height="100%"
      src=""
      :class="{
        'd-flex': !isLoading,
        'd-none': isLoading
      }"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Prop, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {}
})
export default class HeitelStreamPlayer extends Vue {
  @Prop({ required: true }) cameraId: string
  @Prop({ required: true }) cameraConfig: any

  @Action('bindHeitelStream', namespaceCamera) private bindHeitelStream
  @Action('unbindHeitelStream', namespaceCamera) private unbindHeitelStream

  @Getter('getHeitelImage', namespaceCamera) private getHeitelImage

  public isLoading = true

  get videoElementId() {
    return `heitel-video-${this.cameraId}`
  }

  public async mounted() {
    this.watchCameraConfig()
  }

  public beforeDestroy() {
    this.unbindHeitelStream()
  }

  @Watch('cameraConfig')
  @Watch('cameraId')
  public watchCameraConfig() {
    this.isLoading = true
    if (this.cameraConfig && this.cameraId) {
      this.initHeitelStream()
    }
  }

  @Watch('getHeitelImage')
  public watchGetHeitelImage() {
    if (this.getHeitelImage) {
      try {
        document
          .getElementById(this.videoElementId)
          .setAttribute('src', `data:image/png;base64,${this.getHeitelImage}`)
        this.isLoading = false
      } catch (error) {}
    }
  }

  public initHeitelStream() {
    this.bindHeitelStream({
      cameraId: this.cameraId,
      ...this.cameraConfig.liveStream
    })
  }
}
</script>
