<template>
  <v-layout>
    <v-flex>
      <div class="elevation-2 card-style pl-0">
        <div class="pa-0">
          <v-treeview
            id="subscriptionKeyTree"
            :items="subscriptionKeyList"
            item-key="name"
            class="pl-3 pr-1"
            :disabled="isRemovingKey"
            data-test-id="subscription-key-tree"
          >
            <template v-slot:label="{ item }">
              <div class="subscription-key-row px-0 py-1">
                <div
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlackColor
                  }"
                  data-test-id="subscription-key-label"
                >
                  {{ item.name }}
                </div>
                <div class="row-icon-container">
                  <v-text-field
                    :disabled="!isWriteEnabled"
                    id="subscription-key-length"
                    style="padding: 5px; margin: 5px; width: 150px"
                    v-if="item.name == 'Subscription Keys'"
                    :dark="
                      isUserAdmin && isDarkModeToggleEnabled ? true : false
                    "
                    type="number"
                    placeholder="Length"
                    min="8"
                    max="32"
                    v-model="subscriptionKeyLength"
                    hide-details="true"
                    data-test-id="subscription-key-length-input"
                  ></v-text-field>
                  <v-btn
                    v-if="item.name == 'Subscription Keys'"
                    :disabled="!isWriteEnabled"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlackColor
                    }"
                    :ripple="false"
                    text
                    icon
                    class="button"
                    data-cy="subscriptionKey-create"
                    data-test-id="subscription-key-create-button"
                  >
                    <!-- progress bar -->
                    <v-progress-circular
                      v-if="isKeyGenerating"
                      indeterminate
                      color="primary"
                      width="2"
                      size="20"
                      data-test-id="subscription-key-create-progress"
                    ></v-progress-circular>
                    <v-icon
                      v-else
                      :disabled="!isWriteEnabled"
                      class="list-custom-icon"
                      @click="onClickAddKey()"
                      title="Create a new subscription Key"
                      >{{ 'mdi-key-plus' }}</v-icon
                    >
                  </v-btn>
                  <v-btn
                    v-if="item.name !== 'Subscription Keys' && !item.default"
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlackColor
                    }"
                    :ripple="false"
                    text
                    icon
                    :disabled="isRemovingKey"
                    data-cy="subscriptionKey-remove"
                    data-test-id="subscription-key-remove-button"
                  >
                    <v-icon
                      class="list-custom-icon"
                      @click="onClickRemoveKey(item.name)"
                      >{{ 'mdi-key-remove' }}</v-icon
                    >
                  </v-btn>
                  <v-btn
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : getColors.darkBlackColor
                    }"
                    v-if="item.name !== 'Subscription Keys'"
                    :ripple="false"
                    text
                    icon
                    data-test-id="subscription-key-copy-button"
                  >
                    <v-icon
                      v-if="hasdefaultKey"
                      class="list-custom-icon"
                      @click="onClickCopy(item.name)"
                      >{{ 'mdi-content-copy' }}</v-icon
                    >
                  </v-btn>
                </div>
              </div>
            </template>
          </v-treeview>
        </div>
      </div>
    </v-flex>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text text-subtitle-2"
          data-test-id="subscription-key-snackbar-close-button"
        />
      </template>
    </v-snackbar>
    <!-- End of Snackbar message -->
  </v-layout>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { generateSubscriptionKey } from '../utils/Generator'

import Button from '@/components/app/Button.vue'

const namespaceClient = { namespace: 'client' }
const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }

@Component({
  components: { Button }
})
export default class ClientSubscriptionKeys extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('clientConfig', namespaceClient) public clientConfig: any
  @Action('updateKeys', namespaceClient) public updateKeys
  @Action('removeKey', namespaceClient) public removeKey
  @Action('addNewSubscriptionKey', namespaceConfig) public addNewSubscriptionKey
  @Action('removeSubscriptionKey', namespaceConfig) public removeSubscriptionKey
  @Action('checkKeyAvailability', namespaceConfig)
  public checkKeyAvailability: (key: string) => Promise<boolean>

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public get themeColor(): string {
    if (this.currentUser.role === 'Administrator') {
      return this.getColors.lightPrimaryColor
    } else {
      return this.getColors.darkBlackColor
    }
  }
  public isUserAdmin: boolean = false
  public configuration: any

  private subscriptionKeys: string[] = []
  public subscriptionKeyList: any[] = []
  public defaultKey: string = ''
  public hasdefaultKey: boolean = true

  public isUpdatedInitialSetupDetails: boolean = false

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public isKeyGenerating: boolean = false
  public subscriptionKeyLength: number = null
  public isRemovingKey: boolean = false

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  private async mounted() {
    this.isUserAdmin = this.currentUser?.role === 'Administrator'
    this.syncSetupDetails()
  }

  @Watch('clientConfig')
  private async syncSetupDetails() {
    if (this.clientConfig) {
      this.updateSetupDetails(this.clientConfig)
      this.hasdefaultKey = false
      if (this.clientConfig.defaultKey !== undefined) {
        this.hasdefaultKey = true
      }
    }
  }
  private constructSubscriptionKeyList(defaultKey: any, keyList: any) {
    this.subscriptionKeyList = []
    this.subscriptionKeys = keyList ? keyList : []
    this.defaultKey = defaultKey ? defaultKey : ''

    let subscriptionKeyObject = []
    this.subscriptionKeys.forEach((eachKey) => {
      if (eachKey !== this.defaultKey)
        subscriptionKeyObject.push({ name: eachKey })
    })
    subscriptionKeyObject.push({ name: this.defaultKey, default: true })
    this.subscriptionKeyList.push({
      id: 1,
      name: 'Subscription Keys',
      children: subscriptionKeyObject
    })
  }

  private async generateUniqueKey(keyLength: number, retryCount = 5) {
    let newKey = generateSubscriptionKey(keyLength)
    if (await this.checkKeyAvailability(newKey)) {
      return newKey
    } else {
      if (retryCount > 0) {
        return this.generateUniqueKey(keyLength, retryCount - 1)
      } else {
        console.error('Error generating unique key')
      }
    }
  }

  public async onClickAddKey() {
    try {
      if (this.subscriptionKeyLength) {
        if (this.subscriptionKeyLength < 8) {
          this.popSnackbarMessage(`Subscription key length must be at least 8`)
        } else if (this.subscriptionKeyLength > 32) {
          this.popSnackbarMessage(
            `Maximum length of the Subscription key is 32`
          )
        } else {
          this.isKeyGenerating = true
          const newSubscriptionKey: string = await this.generateUniqueKey(
            this.subscriptionKeyLength
          )

          this.updateKeys({
            clientId: this.clientConfig.id,
            key: newSubscriptionKey
          })
          this.addNewSubscriptionKey({
            clientId: this.clientConfig.id,
            key: newSubscriptionKey
          })
          this.popSnackbarMessage(`${newSubscriptionKey} created.`)
        }
      } else {
        this.popSnackbarMessage(`Please enter subscription key length`)
      }
    } catch (error) {
      console.error('Error adding subscription key: ', error)
    } finally {
      this.isKeyGenerating = false
    }
  }

  public onClickCopy(text: string) {
    navigator.clipboard.writeText(text)
    this.popSnackbarMessage(`${text} copied.`)
  }

  private updateSetupDetails(setupData: any) {
    if (setupData) {
      const { defaultKey, keys } = setupData
      this.constructSubscriptionKeyList(defaultKey, keys)
    }
  }

  public async onClickRemoveKey(subscriptionKey: string) {
    if (this.isRemovingKey) {
      this.popSnackbarMessage(`Please wait for the previous action to finish`)
      return
    }

    this.isRemovingKey = true

    try {
      await this.removeKey({
        clientId: this.clientConfig.id,
        key: subscriptionKey
      })

      await this.removeSubscriptionKey({
        clientId: this.clientConfig.id,
        key: subscriptionKey
      })

      this.popSnackbarMessage(`${subscriptionKey} removed.`)
    } catch (error) {
      console.error('Error removing subscription key: ', error)
    } finally {
      this.isRemovingKey = false
    }
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.expansion-panel {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.toggle .list-custom-icon {
  text-align: center;
  float: right;
  margin-left: 7px;
}
.pointer {
  cursor: pointer;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.subscription-key-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 1.05rem;
  font-weight: bold;
}

.row-icon-container {
  display: flex;
  align-items: center;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}
.custom-width-col {
  flex: 0 0 45%;
  max-width: 45%;
}
.header-text {
  font-family: 'Poppins', sans-serif !important;
}
@media only screen and (min-width: 1900px) {
  .container-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}
/* use :deep(<inner-selector>)  since ::v-deep deprecated*/
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
::v-deep #subscriptionKeyTree .v-treeview-node__root {
  padding-right: 12px !important;
}
::v-deep #subscriptionKeyTree .v-treeview-node__root .v-icon {
  max-width: 12px !important;
}
::v-deep #subscriptionKeyTree .v-treeview-node__root .v-treeview-node__content {
  margin-left: 4px !important;
  margin-bottom: -2px !important ;
}
::v-deep .v-expansion-panel-content {
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.15);
}

::v-deep .v-expansion-panel-header__icon .v-icon {
  font-size: 40px;
}

.font-weight-normal {
  font-weight: 400;
}

.toggle-switch {
  margin-top: 0;
  height: 30px;
}
</style>
