var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"padding-top":"15px"},style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[(_vm.isUserAdmin)?_c('div',{attrs:{"data-test-id":"approve-options-container"}},[_c('v-card-title',{staticClass:"text-h6",staticStyle:{"font-weight":"700"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      })},[_vm._v(" Approve Options ")]),_c('v-checkbox',{staticClass:"pl-4 checkbox",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      }),attrs:{"label":"Approve","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"approval-checkbox"},on:{"change":function($event){return _vm.reportHandler('approval')}},model:{value:(_vm.isApproved),callback:function ($$v) {_vm.isApproved=$$v},expression:"isApproved"}})],1):_vm._e(),_c('div',{attrs:{"data-test-id":"report-options-container"}},[_c('v-card-title',{staticClass:"text-h6",staticStyle:{"font-weight":"700"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      })},[_vm._v(" Report Options ")]),(
        _vm.alarmDefinition === 'person' || _vm.alarmDefinition === 'person_vehicle'
      )?_c('v-checkbox',{staticClass:"pl-4 checkbox",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : 'black'
      }),attrs:{"label":"Person","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"person-checkbox"},on:{"change":function($event){return _vm.reportHandler('person')}},model:{value:(_vm.isPersonReported),callback:function ($$v) {_vm.isPersonReported=$$v},expression:"isPersonReported"}}):_vm._e(),(
        _vm.alarmDefinition === 'vehicle' || _vm.alarmDefinition === 'person_vehicle'
      )?_c('v-checkbox',{staticClass:"pl-4 checkbox",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      }),attrs:{"label":"Vehicle Movement","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"vehicle-checkbox"},on:{"change":function($event){return _vm.reportHandler('vehicle')}},model:{value:(_vm.isVehicleReported),callback:function ($$v) {_vm.isVehicleReported=$$v},expression:"isVehicleReported"}}):_vm._e()],1),(_vm.isUserAdmin)?_c('div',{attrs:{"data-test-id":"unsure-options-container"}},[_c('v-card-title',{staticClass:"text-h6",staticStyle:{"font-weight":"700"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      })},[_vm._v(" Unsure Options ")]),_c('v-checkbox',{staticClass:"pl-4 checkbox",style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      }),attrs:{"label":"Unsure","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"unsure-checkbox"},on:{"change":function($event){return _vm.reportHandler('unsure')}},model:{value:(_vm.isUnsure),callback:function ($$v) {_vm.isUnsure=$$v},expression:"isUnsure"}})],1):_vm._e(),_c('v-card-actions',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('Button',{staticClass:"close-btn",staticStyle:{"border":"1px solid #ffd42a"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : ''
      }),attrs:{"btnText":"Close","btnStyle":"outlined","data-test-id":"close-button"},on:{"onButtonClick":function($event){return _vm.setActionDialog(false)}}}),_c('Button',{attrs:{"btnText":"Save","data-test-id":"save-button"},on:{"onButtonClick":_vm.onSave}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }