import { getDefaultState } from './state'

const mutations = {
  setNotificationStatus(state: any, notificationStatus: boolean) {
    state.isNotificationEnabled = notificationStatus
  },
  setWorkerSpaceHealthCheckNotificationStatus(
    state: any,
    notificationStatus: boolean
  ) {
    state.isWorkerSpaceHealthCheckNotificationEnabled = notificationStatus
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  }
}

export default {
  ...mutations
}
