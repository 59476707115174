<template>
  <div>
    <div v-if="isLiveDataLoading" class="loader-wrapper">
      <v-progress-circular
        indeterminate
        color="secondary"
        size="25"
      ></v-progress-circular>
    </div>
    <LiveStreamPlayer
      v-if="!isLiveDataLoading"
      :cameraConfig="cameraConfig"
      :cameraId="cameraDocId"
      :isSiteHardwareDevice="isSiteHardwareDevice"
      :liveStreamQuality="liveStreamQuality"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import LiveStreamPlayer from '../camera/player/LiveStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'

const namespaceHealthCheck = { namespace: 'liveHealthCheck' }

@Component({
  components: { LiveStreamPlayer }
})
export default class HealthCheckLiveStreamPlayer extends Vue {
  @Prop() public selectedHealthCheck!: any
  @Prop({ required: false, default: LiveStreamQualityType.HIGH })
  public liveStreamQuality

  @Action('fetchCameraData', namespaceHealthCheck) private fetchCameraData

  public isLiveDataLoading: boolean = true
  public cameraConfig: any = {}
  public cameraDocId: string = ''
  public isSiteHardwareDevice: boolean = false

  public async mounted() {
    await this.fetchHealthCheckData()
  }

  public async fetchHealthCheckData() {
    this.isLiveDataLoading = true
    const result = await this.fetchCameraData({
      cameraRef: this.selectedHealthCheck.cameraRef
    })
    this.cameraDocId = result.docId
    this.isSiteHardwareDevice = result.isSiteHardwareDevice
    const overrideKey = this.isSiteHardwareDevice ? 'edgeDevice' : 'liveStream'
    this.cameraConfig = {
      ...result.data,
      [overrideKey]: this.selectedHealthCheck.healthCheck[overrideKey]
    }
    this.isLiveDataLoading = false
  }
}
</script>

<style scoped>
.bold-med-text {
  font-weight: 500;
}

.bold-high-text {
  font-weight: 700;
}

.text-detail-grey {
  color: #c4c4c4;
}
</style>
