<template>
  <div class="container">
    <div class="content">
      <h1>Eagle Eye ⟺ promiseQ</h1>
      <div v-if="response">
        <p :class="`${responseType}-style`">{{ response }}</p>
        <Button
          @onButtonClick="closeWindow()"
          :btnText="buttonContent"
          :btnStyle="responseType === 'error' ? 'delete' : 'default'"
        ></Button>
      </div>
      <div v-else>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { functions } from '@/provider/firebase'
import Button from '@/components/app/Button.vue'
import { Mutation } from 'vuex-class'

const connectEagleEye = functions.httpsCallable('connectEagleEye')

const namespaceClient = { namespace: 'client' }

enum ResponseType {
  ERROR = 'error',
  SUCCESS = 'success',
  DEFAULT = 'default'
}

@Component({
  components: { Button }
})
export default class EagleEye extends Vue {
  @Mutation('setEagleEyeConnect', namespaceClient)
  public setEagleEyeConnect: any

  private readonly redirectUri = `${window.location.origin}/eagle-eye`

  public response: string | null = null
  public responseType: ResponseType = ResponseType.DEFAULT
  public buttonContent: string = 'Close this window'

  private async mounted() {
    const code = this.$route.query.code
    if (code) {
      // remove code from url and remove this component from the history
      this.$router.replace({ name: 'EagleEyeConnect' })
      await this.sendCode(code.toString())
    } else {
      this.showError('Unauthorized or invalid request', 'Close this window')
    }
  }

  public closeWindow() {
    window.close()
  }

  private async sendCode(code: string) {
    try {
      // send code to the backend using Firebase onCall method and wait for the response
      const result: any = await connectEagleEye({
        code,
        redirect_uri: this.redirectUri
      })
      this.showSuccess(result.data?.message, 'Close this window')

      this.setEagleEyeConnect({
        progress: false,
        message: this.response,
        error: false
      })
    } catch (error) {
      this.showError(
        (error as any).message === 'internal'
          ? 'Failed to connect. Please try again later'
          : (error as any).message,
        (error as any).message.includes('already connected')
          ? 'Close this window'
          : 'Close this window to try again'
      )

      this.setEagleEyeConnect({
        progress: false,
        message: this.response,
        error: true
      })
    }
  }

  private showError(message: string, buttonContent: string) {
    this.response = message
    this.responseType = ResponseType.ERROR
    this.buttonContent = buttonContent
  }

  private showSuccess(message: string, buttonContent: string) {
    this.response = message
    this.responseType = ResponseType.SUCCESS
    this.buttonContent = buttonContent
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 600px;
}

* {
  box-sizing: border-box;
  color: #000000;
}

h1 {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.error-style {
  color: red;
}

.success-style {
  color: green;
}
</style>
