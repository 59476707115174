<template>
  <div>
    <div
      v-if="camerasThrowsIncidentsStatus !== 'Ready'"
      class="loading-spinner"
    >
      <div v-if="camerasThrowsIncidentsStatus === 'Error'">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is an error while loading this chart
        </p>
      </div>
      <div v-if="camerasThrowsIncidentsStatus === 'Loading'">
        <v-progress-circular indeterminate color="secondary" />
      </div>
    </div>
    <div v-if="camerasThrowsIncidentsStatus === 'Ready'" class="my-2 pa-4">
      <div v-if="camerasThrowsIncidentsData.length > 1">
        <GChart
          :key="currentUser.role"
          type="Sankey"
          :data="camerasThrowsIncidentsData"
          :settings="incidentCameraIdChartSettings"
          :options="incidentCameraIdChartOptions"
        />
      </div>
      <div v-else>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is not enough data to show this chart
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }

@Component({
  components: {
    GChart
  }
})
export default class IncidentCameraIdChart extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getCamerasThrowsIncidentsData', namespaceStatistics)
  public camerasThrowsIncidentsData: any[]
  @Getter('getCamerasThrowsIncidentsStatus', namespaceStatistics)
  public camerasThrowsIncidentsStatus: string

  @Action('fetchCamerasThrowsIncidentsData', namespaceStatistics)
  fetchCamerasThrowsIncidentsData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('$route.params')
  public fetchChartData = debounce(async () => {
    await this.fetchCamerasThrowsIncidentsData({
      startTime: this.startTime,
      endTime: this.endTime,
      ...this.$route.params
    })
  }, 300)

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkBlackColor
  }

  public get incidentCameraIdChartOptions(): any {
    return {
      sankey: {
        iterations: 0,
        node: {
          interactivity: true,
          colors: [this.getColors.darkFrameColor],
          label: {
            color: this.textColors,
            fontName: 'Poppins',
            fontSize: 12
          }
        },
        link: {
          colorMode: 'gradient',
          colors: [
            this.getColors.darkFrameColor,
            this.getColors.darkLowStrokeColor
          ],
          textStyle: {
            color: 'red'
          }
        }
      },
      tooltip: {
        textStyle: {
          fontName: 'Poppins',
          fontSize: 12
        }
      }
    }
  }

  public incidentCameraIdChartSettings = {
    packages: ['sankey']
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
