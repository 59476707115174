<template>
  <div>
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="always-unarmed-label"
      >
        Always Disarmed
      </div>
      <v-switch
        id="always-unarmed-switch"
        v-model="isAlwaysUnarmed"
        :disabled="isToggleDisabled || !isWriteEnabled"
        text
        inset
        class="notification-switch"
        color="secondary"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="always-unarmed-switch"
      ></v-switch>
    </div>
    <div class="card-style">
      <v-container fluid align="center">
        <v-row align="center">
          <v-col cols="12">
            <v-row align="center">
              <v-col cols="12" class="d-flex justify-start align-center">
                <v-row no-gutters>
                  <v-col>
                    <v-subheader
                      class="text-no-wrap pl-1 pr-3 pb-5"
                      :style="{
                        color:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.lightPrimaryColor
                            : getColors.darkPrimaryColor
                      }"
                      data-test-id="time-zone-label"
                    >
                      <v-icon
                        class="pr-2 d-none d-sm-flex"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkPrimaryColor
                        }"
                      >
                        mdi-web
                      </v-icon>
                      Select Time Zone
                    </v-subheader>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="selectedTimeZone"
                      :items="timeZonesList"
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                      content-class="elevation-0"
                      class="text-caption unarmed-input"
                      :dark="isUserAdmin && isDarkModeToggleEnabled"
                      :disabled="
                        isToggleDisabled || !isWriteEnabled || isAlwaysUnarmed
                      "
                      data-test-id="time-zone-select"
                    >
                      <template #prepend-item>
                        <v-card
                          flat
                          width="100%"
                          height="100%"
                          :style="{
                            position: 'absolute',
                            top: '0px',
                            backgroundColor:
                              isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.darkFrameColor
                                : ''
                          }"
                        />
                      </template>
                      <template #item="{ item }">
                        <v-list-item
                          class="d-flex align-center justify-center"
                          :key="item"
                          :value="item"
                          :style="{
                            color:
                              isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.lightPrimaryColor
                                : '',
                            backgroundColor:
                              isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.darkFrameColor
                                : '',
                            margin: 'auto',
                            'font-size': '13px'
                          }"
                          @click="handleItemClick(item)"
                        >
                          {{ item }}
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <v-row no-gutters>
                  <v-col>
                    <v-subheader
                      class="text-no-wrap pl-1 pr-3 pb-5"
                      :style="{
                        color:
                          isUserAdmin && isDarkModeToggleEnabled
                            ? getColors.lightPrimaryColor
                            : getColors.darkPrimaryColor
                      }"
                      data-test-id="applicable-days-label"
                    >
                      <v-icon
                        class="pr-2 d-none d-sm-flex"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkPrimaryColor
                        }"
                      >
                        mdi-calendar-week-outline
                      </v-icon>
                      Applicable Days
                    </v-subheader>
                  </v-col>
                  <v-col>
                    <v-btn-toggle v-model="applicableDays" multiple>
                      <v-btn
                        v-for="day in daysOfWeek"
                        :key="day.value"
                        :value="day.value"
                        :disabled="
                          isToggleDisabled || !isWriteEnabled || isAlwaysUnarmed
                        "
                        class="toggle-button-class"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkPrimaryColor,
                          backgroundColor:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.darkFrameColor
                              : ''
                        }"
                        data-test-id="applicable-days-select"
                      >
                        {{ day.short }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <template>
                  <v-row no-gutters>
                    <v-col>
                      <v-subheader
                        class="text-no-wrap pl-1 pr-3 pb-5"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkPrimaryColor
                        }"
                        data-test-id="start-time-label"
                      >
                        <v-icon
                          class="pr-2 d-none d-sm-flex"
                          :style="{
                            color:
                              isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.lightPrimaryColor
                                : getColors.darkPrimaryColor
                          }"
                        >
                          mdi-clock-time-eight-outline
                        </v-icon>
                        Start Time
                      </v-subheader>
                    </v-col>
                    <v-col>
                      <v-text-field
                        class="text-caption unarmed-input"
                        v-model="startTime"
                        type="time"
                        outlined
                        dense
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        :disabled="
                          isToggleDisabled || !isWriteEnabled || isAlwaysUnarmed
                        "
                        data-test-id="start-time-input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <template>
                  <v-row no-gutters>
                    <v-col>
                      <v-subheader
                        class="text-no-wrap pl-1 pr-3 pb-5"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkPrimaryColor
                        }"
                        data-test-id="end-time-label"
                      >
                        <v-icon
                          class="pr-2 d-none d-sm-flex"
                          :style="{
                            color:
                              isUserAdmin && isDarkModeToggleEnabled
                                ? getColors.lightPrimaryColor
                                : getColors.darkPrimaryColor
                          }"
                        >
                          mdi-clock-time-four-outline
                        </v-icon>
                        End Time
                      </v-subheader>
                    </v-col>
                    <v-col>
                      <v-text-field
                        class="text-caption unarmed-input"
                        v-model="endTime"
                        type="time"
                        outlined
                        dense
                        :dark="isUserAdmin && isDarkModeToggleEnabled"
                        :disabled="
                          isToggleDisabled || !isWriteEnabled || isAlwaysUnarmed
                        "
                        data-test-id="end-time-input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import momentTZ from 'moment-timezone'
import moment from 'moment'
import { Getter } from 'vuex-class'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({})
export default class UnarmedTimeRangeConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (value: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() isUnarmedFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isAlwaysUnarmed: boolean = false
  public isUnarmedTimeRangeEnabled = false
  public dndCheck = false
  public dndSwitch: boolean
  timeZonesList: Array<string> = momentTZ.tz.names()

  public unarmedTimeRangeExists: boolean = false
  public selectedTimeZone: string = 'Europe/Berlin'
  public startTime: string = '08:00'
  public endTime: string = '17:00'
  public weekdays: string[] = moment.weekdaysShort()
  applicableDays: any = []
  daysOfWeek = [
    { value: 'Sun', short: 'S' },
    { value: 'Mon', short: 'M' },
    { value: 'Tue', short: 'T' },
    { value: 'Wed', short: 'W' },
    { value: 'Thu', short: 'T' },
    { value: 'Fri', short: 'F' },
    { value: 'Sat', short: 'S' }
  ]
  private unArmedTimeRange: any = {
    selectedTimeZone: 'Europe/Berlin',
    startTime: '08:00',
    endTime: '17:00',
    applicableDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
  }

  public originaUtrData: any = null

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isUnarmedFieldEnabled === null
      ? this.configuration?.blockToggles?.unarmedTimeRange !== true
      : this.isUnarmedFieldEnabled !== true && this.level !== 'client'
  }

  public mounted() {
    this.watchConfiguration()
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('isUnarmedFieldEnabled')
  private watchConfiguration() {
    let showingConfig: any
    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.unarmedTimeRange
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      const defaultUtr = {
        selectedTimeZone: '',
        startTime: '',
        endTime: '',
        applicableDays: [],
        isAlwaysUnarmed: false
      }

      if (showingConfig?.unarmedTimeRange) {
        this.unArmedTimeRange = showingConfig.unarmedTimeRange

        if (this.unArmedTimeRange?.startTime == '') {
          this.isUnarmedTimeRangeEnabled = false
          if (this.unArmedTimeRange?.isAlwaysUnarmed) {
            this.isAlwaysUnarmed = true
            this.isUnarmedTimeRangeEnabled = false
          } else {
            this.isAlwaysUnarmed = false
          }
        } else {
          this.startTime =
            moment
              .utc(this.unArmedTimeRange.startTime, 'HH:mm')
              .tz(this.unArmedTimeRange.selectedTimeZone)
              .format('HH:mm') || '08:00'
          this.endTime =
            moment
              .utc(this.unArmedTimeRange.endTime, 'HH:mm')
              .tz(this.unArmedTimeRange.selectedTimeZone)
              .format('HH:mm') || '17:00'
        }
      } else {
        this.unArmedTimeRange = defaultUtr
        this.isUnarmedTimeRangeEnabled = false
        this.isAlwaysUnarmed = false
      }

      this.applicableDays = this.unArmedTimeRange?.applicableDays || []

      this.selectedTimeZone =
        this.unArmedTimeRange.selectedTimeZone || 'Europe/Berlin'
      this.isUnarmedTimeRangeEnabled = true

      this.originaUtrData = {
        isUnarmedTimeRangeEnabled: this.isUnarmedTimeRangeEnabled,
        startTime: this.startTime,
        endTime: this.endTime,
        selectedTimeZone: this.selectedTimeZone,
        applicableDays: [...this.applicableDays],
        isAlwaysUnarmed: this.isAlwaysUnarmed
      }
    }
  }
  public hasSwitchChanged(): boolean {
    return (
      this.isUnarmedTimeRangeEnabled !==
      this.originaUtrData.isUnarmedTimeRangeEnabled
    )
  }
  public handleItemClick(item: string) {
    this.selectedTimeZone = item
  }
  public hasDataChanged(): boolean {
    return (
      this.startTime !== this.originaUtrData?.startTime ||
      this.endTime !== this.originaUtrData?.endTime ||
      (this.selectedTimeZone !== this.originaUtrData?.selectedTimeZone &&
        this.selectedTimeZone !== '' &&
        this.selectedTimeZone !== null) ||
      JSON.stringify(this.applicableDays) !==
        JSON.stringify(this.originaUtrData?.applicableDays) ||
      this.isAlwaysUnarmed !== this.originaUtrData?.isAlwaysUnarmed
    )
  }

  @Watch('applicableDays')
  @Watch('startTime')
  @Watch('endTime')
  @Watch('selectedTimeZone')
  private watchTimeRange() {
    if (!this.isToggleDisabled) {
      this.onTimeRangeChange(true)
    }
  }

  // public onClickUnarmedTimeRangeEnabled(value: boolean) {
  //   if (!this.isToggleDisabled) {
  //     this.onSwitchChange(value, this.hasSwitchChanged())
  //     if (value) {
  //       this.onTimeRangeChange(true)
  //     } else {
  //       this.onTimeRangeChange(false)
  //     }
  //   }
  // }

  @Watch('isAlwaysUnarmed')
  private watchAlwaysUnarmed() {
    if (!this.isToggleDisabled) {
      if (this.isAlwaysUnarmed) {
        this.onAlwaysUnarmedChange(true)
      } else {
        this.onAlwaysUnarmedChange(false)
      }
    }
  }

  public onAlwaysUnarmedChange(value: boolean) {
    let message = ''
    let unarmedTimeRangeDataObj
    if (value) {
      this.isUnarmedTimeRangeEnabled = false
      this.unArmedTimeRange = {
        selectedTimeZone: '',
        startTime: '',
        endTime: '',
        applicableDays: []
      }
      unarmedTimeRangeDataObj = {
        ...this.unArmedTimeRange,
        isAlwaysUnarmed: true
      }
      message = 'Successfully turned on the always unarmed'
    } else {
      if ('isAlwaysUnarmed' in this.unArmedTimeRange) {
        unarmedTimeRangeDataObj = {
          ...this.unArmedTimeRange,
          isAlwaysUnarmed: false
        }
      } else {
        unarmedTimeRangeDataObj = {
          ...this.unArmedTimeRange
        }
      }
      message = 'Turned off the always unarmed'
    }
    let shouldUpdate = false
    if (this.level !== 'client') {
      shouldUpdate = !this.configuration?.unarmedTimeRange
    }
    unarmedTimeRangeDataObj = {
      ...unarmedTimeRangeDataObj,
      isChanged: shouldUpdate || this.hasDataChanged()
    }
    this.onChange(unarmedTimeRangeDataObj)
  }

  public onTimeRangeChange(value) {
    let message = ''
    let unarmedTimeRange = {}
    if (value) {
      unarmedTimeRange = {
        startTime: moment
          .tz(this.startTime, 'HH:mm', this.selectedTimeZone)
          .utc()
          .format('HH:mm'),
        endTime: moment
          .tz(this.endTime, 'HH:mm', this.selectedTimeZone)
          .utc()
          .format('HH:mm'),
        applicableDays: this.applicableDays,
        selectedTimeZone: this.selectedTimeZone
      }
      message = `Successfully turned on the unarmed time range for ${
        this.applicableDays
      } | ${this.changeTimeFormat(this.startTime)} - ${this.changeTimeFormat(
        this.endTime
      )} on ${this.selectedTimeZone} time zone`
    } else {
      unarmedTimeRange = {
        startTime: '',
        endTime: '',
        applicableDays: [],
        selectedTimeZone: ''
      }
      message = 'Turned off the unarmed time range for alarms'
    }

    unarmedTimeRange = { ...unarmedTimeRange, isChanged: this.hasDataChanged() }
    this.onChange(unarmedTimeRange)
  }

  public changeTimeFormat(time: string) {
    let newFormat = moment(time, ['HH:mm']).format('h:mm A')
    return newFormat
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}

.unarmed-input {
  height: 40px !important;
}
.toggle-buttons {
  margin-bottom: 20px;
}

.v-btn {
  min-width: 14px;
  height: 14px;
  padding: 0 4px;
  font-size: 12px;
}

.toggle-button-class {
  color: black;
  background-color: white;
  height: 30px !important;
  min-width: 35px !important;
}

.v-btn--active {
  color: black !important;
  background-color: #ffd42a !important;
}
</style>
