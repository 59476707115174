var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],attrs:{"data-test-id":"email-expand-bar"},on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"email-expand-title"}},[_vm._v(" Email "),(_vm.notificationType === 'HC')?_c('span',[_vm._v("(HC)")]):_vm._e()]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pb-3 form d-flex flex-column"},[_c('div',{staticClass:"text-h7 text-left py-3"},[_c('div',{staticClass:"pl-3",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlackColor
        }),attrs:{"data-test-id":"receiving-emails-title"}},[_vm._v(" Receiving Emails ")])]),_c('div',[_vm._l((_vm.notificationEmails),function(notificationEmail,emailIndex){return _c('div',{key:emailIndex,staticClass:"receiving-email-row mb-4 pt-0 pl-3"},[_c('v-row',{staticClass:"email-row"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Email","outlined":"","color":"secondary","hide-details":"true","value":notificationEmail.email,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"receiving-email-input"},on:{"input":(value) => _vm.onChangeEmail(value, emailIndex)}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"email-btn",staticStyle:{"float":"right","color":"none","width":"35px","height":"45px","margin-left":"7px","margin-right":"14px","margin-top":"4px","margin-bottom":"4px"},attrs:{"elevation":"0","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"remove-receiving-email-button"},on:{"click":function($event){return _vm.onClickRemoveEmail(emailIndex)}}},[_c('v-icon',[_vm._v(_vm._s('mdi-close-circle'))])],1)],1)],1)],1)})],2),_c('div',{staticClass:"pb-3 pl-3"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"10","xl":"2"}},[_c('v-btn',{staticClass:"left-align-btn no-shadow",attrs:{"block":"","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"add-receiving-email-button"},on:{"click":_vm.onClickAddEmail}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s('mdi-plus-circle'))]),_c('span',{staticClass:"align-middle"},[_vm._v("Add Email")])],1)],1)],1)],1),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-3"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"alarm-email-notification-lable"}},[_vm._v(" Turn on/off email notifications ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"alarm-email-notification-switch","text":"","inset":"","color":"secondary","disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"alarm-email-notification-switch"},on:{"change":_vm.onIsEmailNotificationEnabled},model:{value:(_vm.isEmailNotificationEnabled),callback:function ($$v) {_vm.isEmailNotificationEnabled=$$v},expression:"isEmailNotificationEnabled"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }