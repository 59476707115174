<template>
  <div>
    <div v-if="isThumbnail">
      <v-img
        v-if="!isThumbnailNotFound"
        :src="mediaUrl"
        lazy-src="../../../public/img/lazy-load-frame.svg"
        @load="onImageLoad"
        :aspect-ratio="aspectRatio"
        alt="todo-image-incident-health-check"
      ></v-img>
      <video
        v-if="mediaType === 'video' && isThumbnailNotFound"
        :src="mediaUrl"
        @canplaythrough="onVideoLoad"
        id="todo-video-incident-health-check"
        class="video-wrapper"
        autoplay
        muted
        loop
      />
    </div>
    <div v-else>
      <v-img
        v-if="mediaType === 'image'"
        :src="mediaUrl"
        lazy-src="../../../public/img/lazy-load-frame.svg"
        @load="onImageLoad"
        :aspect-ratio="aspectRatio"
        alt="todo-image-incident-health-check"
      ></v-img>
      <video
        v-if="mediaType === 'video'"
        :src="mediaUrl"
        @canplaythrough="onVideoLoad"
        id="todo-video-incident-health-check"
        class="video-wrapper"
        autoplay
        muted
        loop
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

const namespaceTodos = { namespace: 'todos' }
@Component({
  components: {}
})
export default class SimpleGalleryElement extends Vue {
  @Prop() public mediaType!: string
  @Prop() public mediaPath!: string
  @Prop({ default: '1.6' }) public aspectRatio?: string
  @Prop() public onMediaLoad?: (isMediaLoaded: boolean) => void
  @Prop() public isThumbnail?: boolean

  @Action('getDownloadUrl', namespaceTodos) public getDownloadUrl

  public isImageLoaded: boolean = false
  public isVideoLoaded: boolean = false
  public mediaUrl: string = ''
  public isThumbnailNotFound: boolean = false

  public async mounted() {
    this.isImageLoaded = false
    this.isVideoLoaded = false
    await this.getMediaUrl()
  }

  private async getMediaUrl() {
    try {
      if (this.mediaPath) {
        if (this.mediaType === 'video' && this.isThumbnail) {
          const fileName = this.mediaPath.split('/').pop()
          const baseName = fileName?.replace(/\.[^/.]+$/, '') // Remove any file extension
          const thumbnailPath = `thumbnails/thumbnail_${baseName}.jpeg`
          this.mediaUrl = await this.getDownloadUrl(thumbnailPath)
          if (!this.mediaUrl) {
            this.isThumbnailNotFound = true
            //if thumbnail not found, get the video url
            this.mediaUrl = await this.getDownloadUrl(this.mediaPath)
          }
        } else {
          this.mediaUrl = await this.getDownloadUrl(this.mediaPath)
        }
      } else {
        console.error('File path does not exist: Simple gallery element')
      }
    } catch (error) {
      console.error(
        'Error fetching media URL for simple gallery element:',
        error
      )
    }
  }

  public onImageLoad() {
    this.isImageLoaded = true
  }

  public onVideoLoad() {
    this.isVideoLoaded = true
  }

  @Watch('isImageLoaded')
  @Watch('isVideoLoaded')
  @Watch('mediaType')
  public isMediaLoaded() {
    if (this.onMediaLoad) {
      if (this.mediaType === 'image') {
        this.onMediaLoad(this.isImageLoaded)
      }
      if (this.mediaType === 'video') {
        this.onMediaLoad(this.isVideoLoaded)
      }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

video {
  object-fit: fill;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 98%;
  z-index: 0;
}
</style>
