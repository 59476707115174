import { render, staticRenderFns } from "./ForgotPasswordAlert.vue?vue&type=template&id=57d7e6ef&scoped=true"
import script from "./ForgotPasswordAlert.vue?vue&type=script&lang=ts"
export * from "./ForgotPasswordAlert.vue?vue&type=script&lang=ts"
import style0 from "./ForgotPasswordAlert.vue?vue&type=style&index=0&id=57d7e6ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d7e6ef",
  null
  
)

export default component.exports