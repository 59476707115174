import firebase from 'firebase/compat/app'
import { HttpsCallableResult } from '@firebase/functions-types'
import { DocumentReference } from '@firebase/firestore-types'
import {
  cameraCollection,
  clientCollection,
  siteCollection,
  userCollection,
  todosCollection,
  liveHealthCheckCollection,
  firestore,
  cameraProvider,
  eagleEyeAccountCollection
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import { formatCameraLocations } from '@/utils/MapUtils'
import { store } from '@/store'
import { CameraModifiedConfiguration } from 'configuration'
import { DISARMED_TIME_RANGE } from '@/utils/Constants'

let liveStreamInterval = null

const actions = {
  bindCameraUsers: async (
    { commit, state }: any,
    payload: { userId: string; userIds: string[] }
  ) => {
    commit('user/setIsLoadingUsers', true, { root: true })
    const writeUsers = state.cameraConfig.writeUsers ?? []
    const overrideUsers = state.cameraConfig.overrideUsers ?? []
    const filteredUserIds = payload.userIds.filter(
      (userId) => userId !== payload.userId
    )
    const users = await Promise.all(
      filteredUserIds?.map(async (userId) => {
        const userSnap = await userCollection.doc(userId).get()
        return {
          ...userSnap.data(),
          isWriteEnabled: writeUsers.includes(userId),
          isOverrideEnabled: overrideUsers.includes(userId)
        }
      })
    )
    commit('setCameraUsers', users)
    commit('user/setIsLoadingUsers', false, { root: true })
  },
  async updateCameraConfig(
    { commit, dispatch }: any,
    payload: {
      docId: string
      updatedCameraData: CameraModifiedConfiguration
      updatedFields: string[]
    }
  ) {
    if (payload?.updatedFields.includes('Camera Information')) {
      if (payload.updatedCameraData.cameraInformation) {
        const cameraData = {
          location: payload.updatedCameraData.cameraInformation.location,
          name: payload.updatedCameraData.cameraInformation.cameraName,
          zone: payload.updatedCameraData.cameraInformation.siaZone
        }
        await cameraCollection.doc(payload.docId).update(cameraData)
      }
    }
    if (payload?.updatedFields.includes('Alarm Definition')) {
      await dispatch('updateAlarmDefinitionConfig', {
        cameraId: payload.docId,
        alarmDefinitionConfig:
          payload.updatedCameraData.alarmSettings.alarmDefinition
      })
    }
    if (payload?.updatedFields.includes('Contact')) {
      const contact = {
        contactName: payload.updatedCameraData.contact.contactPerson,
        countryCallingCode:
          payload.updatedCameraData.contact.contactNumber.countryCallingCode,
        countryCode:
          payload.updatedCameraData.contact.contactNumber.countryCode,
        isCallEnabled:
          payload.updatedCameraData.contact.receiveCallNotification,
        isSmsEnabled: payload.updatedCameraData.contact.receiveSmsNotification,
        message: payload.updatedCameraData.contact.contactMessage,
        phoneNumber: payload.updatedCameraData.contact.contactNumber.phoneNumber
      }

      await dispatch('updateContact', {
        cameraId: payload.docId,
        contact
      })
    }
    if (payload?.updatedFields.includes('Live Stream Integration')) {
      const liveStreamData = {
        type: payload.updatedCameraData.liveStream?.liveStreamData.type,
        url: payload.updatedCameraData.liveStream?.liveStreamData.url,
        username:
          payload.updatedCameraData.liveStream?.liveStreamData.username || '',
        password:
          payload.updatedCameraData.liveStream?.liveStreamData.password || ''
      }
      await dispatch('updateLiveStream', {
        cameraId: payload.docId,
        liveStream: liveStreamData
      })
      if (payload.updatedCameraData.liveStream.isOn !== null) {
        await dispatch('updateLiveStreamStatus', {
          cameraId: payload.docId,
          isLiveStreamEnabled: payload.updatedCameraData.liveStream.isOn
        })
      }
    }
    if (
      payload?.updatedFields.includes('promiseQube Integration') ||
      payload?.updatedFields.includes('promiseQube Integration Toggle')
    ) {
      const edgeDeviceData: any = {
        type: payload.updatedCameraData.edgeDevice.edgeDeviceData?.type ?? '',
        ip: payload.updatedCameraData.edgeDevice.edgeDeviceData?.ip ?? '',
        path: payload.updatedCameraData.edgeDevice.edgeDeviceData?.path ?? '',
        username:
          payload.updatedCameraData.edgeDevice.edgeDeviceData?.username ?? '',
        password:
          payload.updatedCameraData.edgeDevice.edgeDeviceData?.password ?? ''
      }
      try {
        await dispatch('updateEdgeDevice', {
          cameraId: payload.docId,
          edgeDevice: edgeDeviceData,
          isCredentialChanged: payload?.updatedFields.includes(
            'promiseQube Integration'
          )
        })
      } catch (error) {
        console.error('error', error)
      }
      if (payload.updatedCameraData.edgeDevice.isOn !== undefined) {
        await dispatch('updateEdgeDeviceStatus', {
          cameraId: payload.docId,
          isEdgeDeviceEnabled: payload.updatedCameraData.edgeDevice.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('Recordings')) {
      if (
        payload.updatedCameraData.edgeDevice.isEdgeDeviceRecordingEnabled !==
        undefined
      ) {
        await dispatch('updateEdgeDeviceRecordingStatus', {
          cameraId: payload.docId,
          isEdgeDeviceRecordingEnabled:
            payload.updatedCameraData.edgeDevice.isEdgeDeviceRecordingEnabled
        })
      }
    }
    if (payload?.updatedFields.includes('FTP Integration')) {
      await dispatch('updateFtpEnabledStatus', {
        cameraId: payload.docId,
        isFtpEnabled: payload.updatedCameraData.isFtpEnabled
      })
    }
    if (payload?.updatedFields.includes('SMTP Integration')) {
      await dispatch('updateSmtpEnabledStatus', {
        cameraId: payload.docId,
        isSmtpEnabled: payload.updatedCameraData.isSmtpEnabled
      })
    }
    if (payload?.updatedFields.includes('Human Review')) {
      await dispatch('updateHumanReviewStatus', {
        cameraId: payload.docId,
        isHumanReviewEnabled:
          payload.updatedCameraData.alarmSettings.humanReview
      })
    }
    if (payload?.updatedFields.includes('Health Check')) {
      if (payload.updatedCameraData.healthCheck.notifications != null) {
        await dispatch('updateIsHealthCheckNotificationEnabledStatus', {
          cameraId: payload.docId,
          isHealthCheckNotificationEnabled:
            payload.updatedCameraData.healthCheck.notifications
        })
      }
      if (
        payload.updatedCameraData.healthCheck?.latestHCNotifications != null
      ) {
        await dispatch('updateIsLatestHealthCheckNotificationEnabledStatus', {
          cameraId: payload.docId,
          isLatestHealthCheckNotificationEnabled:
            payload.updatedCameraData.healthCheck?.latestHCNotifications
        })
      }
      // Todo: fix this
      if (payload.updatedCameraData.healthCheck.frequency) {
        await dispatch('updateHcDuration', {
          cameraId: payload.docId,
          healthCheckDuration: payload.updatedCameraData.healthCheck.frequency
        })
      }
      if (payload.updatedCameraData.healthCheck.type) {
        await dispatch('updateHcType', {
          cameraId: payload.docId,
          healthCheckType: payload.updatedCameraData.healthCheck.type
        })
      }
    }

    if (
      payload?.updatedFields.includes('Email Notifications') ||
      payload?.updatedFields.includes('Email Notifications Toggle')
    ) {
      if (
        typeof payload.updatedCameraData.notifications.alarm.email.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEmailNotificationEnabledStatus', {
          cameraId: payload.docId,
          isEmailNotificationEnabled:
            payload.updatedCameraData.notifications.alarm.email.isOn
        })
      }
      if (payload.updatedCameraData.notifications.alarm.email.receivingEmails) {
        await dispatch('updateNotificationEmails', {
          cameraId: payload.docId,
          notificationEmails:
            payload.updatedCameraData.notifications.alarm.email.receivingEmails
        })
      }
    }
    if (
      payload?.updatedFields.includes('Sia Notifications') ||
      payload?.updatedFields.includes('Sia Notifications Toggle')
    ) {
      if (
        typeof payload.updatedCameraData.notifications.alarm.siaDc09.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsSiaNotificationEnabledStatus', {
          cameraId: payload.docId,
          isSiaEnabled:
            payload.updatedCameraData.notifications.alarm.siaDc09.isOn
        })
      }
      if (
        payload.updatedCameraData.notifications.alarm.siaDc09
          .notifySubFolderAccountNumber != null
      ) {
        await dispatch('updateIsRelatedSiaAccountNotifiedStatus', {
          cameraId: payload.docId,
          isRelatedSiaAccountNotified:
            payload.updatedCameraData.notifications.alarm.siaDc09
              .notifySubFolderAccountNumber
        })
      }
      if (
        payload.updatedCameraData.notifications.alarm.siaDc09
          .sendSiaHeartbeatMessage != null
      ) {
        await dispatch('updateIsSiaHeartbeatEnabledStatus', {
          cameraId: payload.docId,
          isSiaHeartbeatEnabled:
            payload.updatedCameraData.notifications.alarm.siaDc09
              .sendSiaHeartbeatMessage
        })
      }
      if (
        payload.updatedCameraData.notifications.alarm.siaDc09
          .sendSiaRoutineMessage != null
      ) {
        await dispatch('updateIsSiaRoutineMessageEnabledStatus', {
          cameraId: payload.docId,
          isSiaRoutineMessageEnabled:
            payload.updatedCameraData.notifications.alarm.siaDc09
              .sendSiaRoutineMessage
        })
      }
      const siaData = {
        siaIp: payload.updatedCameraData.notifications.alarm.siaDc09.ipAddress,
        siaPort: payload.updatedCameraData.notifications.alarm.siaDc09.port
      }
      await dispatch('updateSiaConfig', {
        cameraId: payload.docId,
        siaData
      })
    }
    if (payload?.updatedFields.includes('Webhook Notifications')) {
      const webhookTriggerData = {
        method:
          payload.updatedCameraData.notifications.alarm.webhook.webhookArray
            .method,
        name: payload.updatedCameraData.notifications.alarm.webhook.webhookArray
          .name,
        url: payload.updatedCameraData.notifications.alarm.webhook.webhookArray
          .url
      }
      if (
        payload.updatedCameraData.notifications.alarm.webhook.webhookArray
          .urlParams
      ) {
        webhookTriggerData['urlParams'] =
          payload.updatedCameraData.notifications.alarm.webhook.webhookArray.urlParams
      }

      if (
        payload.updatedCameraData.notifications.alarm.webhook.webhookArray
          .headerParams
      ) {
        webhookTriggerData['headerParams'] =
          payload.updatedCameraData.notifications.alarm.webhook.webhookArray.headerParams
      }

      // Add content to webhookTriggerData if it exists
      if (
        payload.updatedCameraData.notifications.alarm.webhook.webhookArray
          .content
      ) {
        webhookTriggerData['content'] =
          payload.updatedCameraData.notifications.alarm.webhook.webhookArray.content
      }
      await dispatch('addWebhookTriggerConfig', {
        cameraId: payload.docId,
        webhookTriggerData
      })

      const docRef = cameraCollection.doc(payload.docId)
      const doc = await docRef.get()

      if (doc.exists) {
        const data = doc.data()

        // Check if webhookTriggerData and urlParams exist
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.urlParams &&
          webhookTriggerData['urlParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: payload.docId,
            webhookTriggerData: { urlParams: webhookTriggerData['urlParams'] }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: payload.docId,
            webhookTriggerData: { urlParams: {} }
          })
        }
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.headerParams &&
          webhookTriggerData['headerParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: payload.docId,
            webhookTriggerData: {
              headerParams: webhookTriggerData['headerParams']
            }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: payload.docId,
            webhookTriggerData: { headerParams: {} }
          })
        }
      }

      if (
        typeof payload.updatedCameraData.notifications.alarm.webhook.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsWebhookTriggerEnabledStatus', {
          cameraId: payload.docId,
          isWebhookTriggerEnabled:
            payload.updatedCameraData.notifications.alarm.webhook.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('FTP Notifications')) {
      try {
        if (
          typeof payload.updatedCameraData.notifications.alarm.ftp.isOn ===
          'boolean'
        ) {
          await dispatch('updateIsFTPNotificationEnabledStatus', {
            cameraId: payload.docId,
            isFTPEnabled: payload.updatedCameraData.notifications.alarm.ftp.isOn
          })
        }
        const ftpData = {
          filePath: payload.updatedCameraData.notifications.alarm.ftp.filePath,
          host: payload.updatedCameraData.notifications.alarm.ftp.host,
          password: payload.updatedCameraData.notifications.alarm.ftp.password,
          port: payload.updatedCameraData.notifications.alarm.ftp.port,
          user: payload.updatedCameraData.notifications.alarm.ftp.username,
          isUploadImageSequence:
            payload.updatedCameraData.notifications.alarm.ftp
              .isUploadImageSequence,
          isFtpPathEnabled:
            payload.updatedCameraData.notifications.alarm.ftp.isFtpPathEnabled
        }
        await dispatch('updateFTPConfig', {
          cameraId: payload.docId,
          ftpData
        })
      } catch (error) {
        console.log('Error updating FTP Notifications: ', error)
      }
    }
    if (payload?.updatedFields.includes('SMTP Notifications')) {
      try {
        if (
          typeof payload.updatedCameraData.notifications.alarm.smtp.isOn ===
          'boolean'
        ) {
          await dispatch('updateIsSMTPNotificationEnabledStatus', {
            cameraId: payload.docId,
            isSMTPNotificationEnabled:
              payload.updatedCameraData.notifications.alarm.smtp.isOn
          })
        }
        const smtpNotificationData = {
          receiverAddress:
            payload.updatedCameraData.notifications.alarm.smtp
              .receiverEmailAddress,
          host: payload.updatedCameraData.notifications.alarm.smtp.host,
          password: payload.updatedCameraData.notifications.alarm.smtp.password,
          port: payload.updatedCameraData.notifications.alarm.smtp.port,
          user: payload.updatedCameraData.notifications.alarm.smtp.username
        }
        await dispatch('updateSMTPConfig', {
          cameraId: payload.docId,
          smtpNotificationData
        })
      } catch (error) {
        console.log('Error updating SMTP Notifications: ', error)
      }
    }
    if (
      payload?.updatedFields.includes('Evalink Notifications') ||
      payload?.updatedFields.includes('Evalink Notifications Toggle')
    ) {
      if (
        typeof payload.updatedCameraData.notifications.alarm.evalink.isOn ===
        'boolean'
      ) {
        await dispatch('updateIsEvalinkNotificationEnabledStatus', {
          cameraId: payload.docId,
          isEvalinkEnabled:
            payload.updatedCameraData.notifications.alarm.evalink.isOn
        })
      }
      const evalinkData = {
        alarmZoneName:
          payload.updatedCameraData.notifications.alarm.evalink.alarmZoneName,
        Authorization:
          payload.updatedCameraData.notifications.alarm.evalink
            .authorizationKey,
        companyId:
          payload.updatedCameraData.notifications.alarm.evalink.companyId,
        deviceId:
          payload.updatedCameraData.notifications.alarm.evalink.deviceId,
        isDeviceOverrideEnabled:
          payload.updatedCameraData.notifications.alarm.evalink
            .deviceOverride || false,
        partition:
          payload.updatedCameraData.notifications.alarm.evalink.partition,
        partitionName:
          payload.updatedCameraData.notifications.alarm.evalink.partitionName
      }
      await dispatch('updateEvalinkConfig', {
        cameraId: payload.docId,
        evalinkData
      })
    }

    if (
      payload?.updatedFields.includes('Email Notifications (HC)') ||
      payload?.updatedFields.includes('Email Notifications Toggle (HC)')
    ) {
      if (
        typeof payload.updatedCameraData.notifications.healthCheck.email
          .isOn === 'boolean'
      ) {
        await dispatch('updateIsEmailNotificationEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isEmailNotificationEnabled:
            payload.updatedCameraData.notifications.healthCheck.email.isOn
        })
      }
      if (
        payload.updatedCameraData.notifications.healthCheck.email
          .receivingEmails
      ) {
        await dispatch('updateNotificationEmails', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          notificationEmails:
            payload.updatedCameraData.notifications.healthCheck.email
              .receivingEmails
        })
      }
    }
    if (
      payload?.updatedFields.includes('Sia Notifications (HC)') ||
      payload?.updatedFields.includes('Sia Notifications Toggle (HC)')
    ) {
      if (
        typeof payload.updatedCameraData.notifications.healthCheck.siaDc09
          .isOn === 'boolean'
      ) {
        await dispatch('updateIsSiaNotificationEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isSiaEnabled:
            payload.updatedCameraData.notifications.healthCheck.siaDc09.isOn
        })
      }
      if (
        payload.updatedCameraData.notifications.healthCheck.siaDc09
          .notifySubFolderAccountNumber != null
      ) {
        await dispatch('updateIsRelatedSiaAccountNotifiedStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isRelatedSiaAccountNotified:
            payload.updatedCameraData.notifications.healthCheck.siaDc09
              .notifySubFolderAccountNumber
        })
      }
      if (
        payload.updatedCameraData.notifications.healthCheck.siaDc09
          .sendSiaHeartbeatMessage != null
      ) {
        await dispatch('updateIsSiaHeartbeatEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isSiaHeartbeatEnabled:
            payload.updatedCameraData.notifications.healthCheck.siaDc09
              .sendSiaHeartbeatMessage
        })
      }
      if (
        payload.updatedCameraData.notifications.healthCheck.siaDc09
          .sendSiaRoutineMessage != null
      ) {
        await dispatch('updateIsSiaRoutineMessageEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isSiaRoutineMessageEnabled:
            payload.updatedCameraData.notifications.healthCheck.siaDc09
              .sendSiaRoutineMessage
        })
      }
      const siaData = {
        siaIp:
          payload.updatedCameraData.notifications.healthCheck.siaDc09.ipAddress,
        siaPort:
          payload.updatedCameraData.notifications.healthCheck.siaDc09.port
      }
      await dispatch('updateSiaConfig', {
        cameraId: `${payload.docId}/notificationData/healthCheck`,
        siaData
      })
    }
    if (payload?.updatedFields.includes('Webhook Notifications (HC)')) {
      const webhookTriggerData = {
        method:
          payload.updatedCameraData.notifications.healthCheck.webhook
            .webhookArray.method,
        name: payload.updatedCameraData.notifications.healthCheck.webhook
          .webhookArray.name,
        url: payload.updatedCameraData.notifications.healthCheck.webhook
          .webhookArray.url
      }
      if (
        payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray
          .urlParams
      ) {
        webhookTriggerData['urlParams'] =
          payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray.urlParams
      }

      if (
        payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray
          .headerParams
      ) {
        webhookTriggerData['headerParams'] =
          payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray.headerParams
      }

      // Add content to webhookTriggerData if it exists
      if (
        payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray
          .content
      ) {
        webhookTriggerData['content'] =
          payload.updatedCameraData.notifications.healthCheck.webhook.webhookArray.content
      }
      await dispatch('addWebhookTriggerConfig', {
        cameraId: `${payload.docId}/notificationData/healthCheck`,
        webhookTriggerData
      })

      const docRef = cameraCollection.doc(
        `${payload.docId}/notificationData/healthCheck`
      )
      const doc = await docRef.get()

      if (doc.exists) {
        const data = doc.data()

        // Check if webhookTriggerData and urlParams exist
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.urlParams &&
          webhookTriggerData['urlParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { urlParams: webhookTriggerData['urlParams'] }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { urlParams: {} }
          })
        }
        if (
          data.webhookTriggerData &&
          data.webhookTriggerData.headerParams &&
          webhookTriggerData['headerParams']
        ) {
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: {
              headerParams: webhookTriggerData['headerParams']
            }
          })
        } else {
          //need to delete the whole urlParams object if it is not present in the payload
          await dispatch('updateWebhookTriggerConfig', {
            cameraId: `${payload.docId}/notificationData/healthCheck`,
            webhookTriggerData: { headerParams: {} }
          })
        }
      }

      if (
        typeof payload.updatedCameraData.notifications.healthCheck.webhook
          .isOn === 'boolean'
      ) {
        await dispatch('updateIsWebhookTriggerEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isWebhookTriggerEnabled:
            payload.updatedCameraData.notifications.healthCheck.webhook.isOn
        })
      }
    }
    if (payload?.updatedFields.includes('SMTP Notifications (HC)')) {
      if (
        payload.updatedCameraData.notifications.healthCheck.smtp.isOn !==
        undefined
      ) {
        await dispatch('updateIsSMTPNotificationEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isSMTPNotificationEnabled:
            payload.updatedCameraData.notifications.healthCheck.smtp.isOn
        })
      }
      const smtpNotificationData = {
        receiverAddress:
          payload.updatedCameraData.notifications.healthCheck.smtp
            .receiverEmailAddress,
        host: payload.updatedCameraData.notifications.healthCheck.smtp.host,
        password:
          payload.updatedCameraData.notifications.healthCheck.smtp.password,
        port: payload.updatedCameraData.notifications.healthCheck.smtp.port,
        user: payload.updatedCameraData.notifications.healthCheck.smtp.username
      }
      await dispatch('updateSMTPConfig', {
        cameraId: `${payload.docId}/notificationData/healthCheck`,
        smtpNotificationData
      })
    }
    if (
      payload?.updatedFields.includes('Evalink Notifications (HC)') ||
      payload?.updatedFields.includes('Evalink Notifications Toggle (HC)')
    ) {
      if (
        payload.updatedCameraData.notifications.healthCheck.evalink.isOn !==
        undefined
      ) {
        await dispatch('updateIsEvalinkNotificationEnabledStatus', {
          cameraId: `${payload.docId}/notificationData/healthCheck`,
          isEvalinkEnabled:
            payload.updatedCameraData.notifications.healthCheck.evalink.isOn
        })
      }
      const evalinkData = {
        alarmZoneName:
          payload.updatedCameraData.notifications.healthCheck.evalink
            .alarmZoneName,
        Authorization:
          payload.updatedCameraData.notifications.healthCheck.evalink
            .authorizationKey,
        companyId:
          payload.updatedCameraData.notifications.healthCheck.evalink.companyId,
        deviceId:
          payload.updatedCameraData.notifications.healthCheck.evalink.deviceId,
        isDeviceOverrideEnabled:
          payload.updatedCameraData.notifications.healthCheck.evalink
            .deviceOverride || false,
        partition:
          payload.updatedCameraData.notifications.healthCheck.evalink.partition,
        partitionName:
          payload.updatedCameraData.notifications.healthCheck.evalink
            .partitionName
      }
      await dispatch('updateEvalinkConfig', {
        cameraId: `${payload.docId}/notificationData/healthCheck`,
        evalinkData
      })
    }

    if (payload?.updatedFields.includes(DISARMED_TIME_RANGE)) {
      const unarmedTimeRange = {
        applicableDays:
          payload.updatedCameraData.unarmedTimeRange.applicationDays,
        endTime: payload.updatedCameraData.unarmedTimeRange.endTime,
        isAlwaysUnarmed:
          payload.updatedCameraData.unarmedTimeRange.alwaysUnarmed || false,
        selectedTimeZone: payload.updatedCameraData.unarmedTimeRange.timeZone,
        startTime: payload.updatedCameraData.unarmedTimeRange.startTime
      }
      await dispatch('updateUnarmedTimeRange', {
        cameraId: payload.docId,
        unarmedTimeRange
      })
    }

    await dispatch('bindHcNotificationData', { cameraId: payload.docId })

    if (!payload.docId) {
      throw new Error('Missing payload')
    }
  },

  removeRTSPurl: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        cameraURL: string
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        liveCamera: firebase.firestore.FieldValue.delete()
      })
    }
  ),
  removeReferenceImage: firestoreAction(
    (
      { commit }: any,
      payload: {
        cameraId: string
        path: string
      }
    ) => {
      commit('setReferenceImageUrl', null)
      return cameraCollection.doc(payload.cameraId).update({
        referenceImage: null
      })
      // .then(() => {
      //   if (payload.path && payload.path !== '') {
      //     return storage.ref(payload.path).delete()
      //   }
      // })
    }
  ),
  updateRTSPCameras: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        cameraURL: string
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        liveCamera: payload.cameraURL
      })
    }
  ),
  updateReferenceImage: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        referenceImage: { url: string; path: string }
      }
    ) => {
      payload.referenceImage['updatedAt'] =
        firebase.firestore.FieldValue.serverTimestamp()
      return cameraCollection.doc(payload.cameraId).update({
        referenceImage: payload.referenceImage,
        isReferenceImageLoading: false,
        isReferenceImageLoadingError: false
      })
    }
  ), // here to update the docs for extracting the image from edge device
  extractImageFromEdgeDevice: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        isReferenceImageLoading: true,
        extractingImageFromEdgeDeviceAt:
          firebase.firestore.FieldValue.serverTimestamp()
      })
    }
  ),
  fetchReferenceImageFromCameraRef: firestoreAction(
    async (
      { commit }: any,
      cameraRefPath: firebase.firestore.DocumentReference
    ) => {
      if (!cameraRefPath) {
        commit('setReferenceImageUrl', null)
        return
      }

      try {
        let cameraSnapshot

        if (cameraRefPath instanceof firebase.firestore.DocumentReference) {
          cameraSnapshot = cameraRefPath
        } else {
          cameraSnapshot = firestore.doc(cameraRefPath)
        }
        const cameraDoc = await cameraSnapshot.get()

        if (!cameraDoc.exists) {
          commit('setReferenceImageUrl', null)
          return
        }

        let url = null

        const rawUrl = cameraDoc.data()?.referenceImage?.path
        if (rawUrl) {
          url = await cameraProvider.fetchDownloadUrl(rawUrl)
        }

        commit('setReferenceImageUrl', url)
      } catch (error) {
        console.error(error)
      }
    }
  ),
  fetchReferenceImageFromPath: firestoreAction(
    async ({ commit }: any, filePath) => {
      commit('setReferenceImageUrl', null)
      let url: string | null = null
      try {
        url = (await cameraProvider.fetchDownloadUrl(
          filePath
        )) as unknown as string
        commit('setReferenceImageUrl', url)
      } catch (error) {
        commit('setReferenceImageUrl', null)
      }

      return url
    }
  ),
  updateExcludedZones: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        excludedZones: any[]
        metadata: {
          width: number
          height: number
        }
      }
    ) => {
      // set if not exist or update if exist
      return cameraCollection.doc(payload.cameraId).set(
        {
          zones: {
            excludedZones: payload.excludedZones,
            metadata: payload.metadata
          }
        },
        { merge: true }
      )
    }
  ),
  updateCameraSessionId: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        sessoinId: string
        location: {
          lat: number
          lng: number
        }
        liveStreamQuality: string
        seekTime?: number
      }
    ) => {
      // set if not exist or update if exist
      return cameraCollection.doc(payload.cameraId).update({
        'promiseQube.lastUpdatedSessionId': payload.sessoinId,
        'promiseQube.liveStreamQuality': payload.liveStreamQuality,
        'promiseQube.seekTime': payload.seekTime
      })
    }
  ),
  updateCameraLocation: firestoreAction(async (context, payload) => {
    try {
      const { cameraId, location } = payload
      const { lat, lng } = location

      // Ensure lat and lng are numbers
      const latitude = Number(lat)
      const longitude = Number(lng)

      if (isNaN(latitude) || isNaN(longitude)) {
        throw new Error('Latitude and longitude must be valid numbers')
      }
      await cameraCollection.doc(cameraId).set(
        {
          location: {
            lat: latitude,
            lng: longitude
          }
        },
        { merge: true }
      )

      return 'Location updated successfully'
    } catch (error) {
      console.error('Error updating location:', error)
      throw new Error('Failed to update location')
    }
  }),
  getCameraDataFromCameraId: firestoreAction(
    async (
      context,
      payload: Array<{
        cameraId: string
        incidentId: string
      }>
    ) => {
      try {
        const cameraList = await Promise.all(
          payload.map(async (camera) => {
            const incident = await todosCollection.doc(camera.incidentId).get()
            if (incident.exists) {
              const cameraRef = incident.data()?.cameraRef
              if (cameraRef) {
                const cameraDoc = await cameraRef.get()
                if (cameraDoc.exists) {
                  return {
                    cameraDocId: cameraDoc.id,
                    cameraId: cameraDoc.data().cameraId,
                    excludedZones: cameraDoc.data()?.zones?.excludedZones || [],
                    metadata: cameraDoc.data()?.zones?.metadata || {}
                  }
                }
              }
            }
          })
        )

        return cameraList
      } catch (error) {
        return []
      }
    }
  ),
  bindCamera: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: {
        clientId: string
        siteId: string
        cameraId: string
        userId: string
      }
    ) => {
      store.commit('config/setIsNotFound', false)
      const clientSnap = await clientCollection
        .where('clientId', '==', payload.clientId)
        .get()
      if (clientSnap.empty) {
        store.commit('config/setIsNotFound', true)
        console.log("Something went wrong or client doesn't exists")
        return
      }

      const siteSnap = await siteCollection
        .where('siteId', '==', payload.siteId)
        .where('client', '==', clientSnap.docs.at(0).ref)
        .get()

      if (siteSnap.empty) {
        console.log("Something went wrong or site doesn't exist")
        store.commit('config/setIsNotFound', true)
        return
      }

      const cameraSnap = await cameraCollection
        .where('cameraId', '==', payload.cameraId)
        .where('site', '==', siteSnap.docs.at(0).ref)
        .get()

      if (cameraSnap.empty) {
        store.commit('config/setIsNotFound', true)
        return
      }

      const healthCheckNotificationDataRef = cameraSnap.docs
        .at(0)
        .ref.collection('notificationData')
        .doc('healthCheck')
      const healthCheckNotificationDataSnap =
        await healthCheckNotificationDataRef.get()

      let healthCheckNotificationData = {}
      if (healthCheckNotificationDataSnap.exists) {
        healthCheckNotificationData = healthCheckNotificationDataSnap.data()
      }

      return bindFirestoreRef('cameraConfig', cameraSnap.docs.at(0).ref, {
        maxRefDepth: 0,
        wait: true,
        serialize: (doc: { data: () => any; id: any }) => {
          const users = doc.data().users ?? []
          const writeUsers = doc.data().writeUsers ?? []
          const overrideUsers = doc.data().overrideUsers ?? []

          const siteData = siteSnap.docs.at(0).data()
          const clientData = clientSnap.docs.at(0).data()
          const isSiteHardwareDevice = siteData?.isHardwareDevice
          return {
            ...doc.data(),
            id: doc.id,
            siteId: siteData?.siteId,
            clientId: clientData?.clientId,
            isDefault: clientData?.type === 'Default',
            isWriteEnabled: writeUsers.includes(payload.userId),
            isOverrideEnabled: overrideUsers.includes(payload.userId),
            isAccessEnabled: users.includes(payload.userId),
            isSiteHardwareDevice,
            notificationData: {
              healthCheck: healthCheckNotificationData
            }
          }
        }
      })
    }
  ),
  //Bind camera status subcollection
  bindCameraStatus: firestoreAction(
    async (
      { bindFirestoreRef }: { bindFirestoreRef: any },
      payload: { cameraId: string }
    ) => {
      const ref = cameraCollection
        .doc(payload.cameraId)
        .collection('status')
        .doc('current')

      return bindFirestoreRef('cameraStatus', ref, {
        wait: true
      })
    }
  ),
  bindHcNotificationData: firestoreAction(
    async (
      { state, bindFirestoreRef, commit }: any,
      payload: {
        cameraId: string
      }
    ) => {
      const ref = cameraCollection
        .doc(payload.cameraId)
        .collection('notificationData')
        .doc('healthCheck')
      return bindFirestoreRef(
        `cameraConfig.notificationData.healthCheck`,
        ref,
        {
          wait: true
        }
      )
    }
  ),
  bindLiveCamera: firestoreAction(
    ({ state, bindFirestoreRef, commit }: any, payload: any) => {
      if (!payload) {
        return
      }
      return bindFirestoreRef('liveCamera', cameraCollection.doc(payload), {
        maxRefDepth: 0,
        wait: true,
        serialize: (doc) => {
          return Object.defineProperty(doc.data(), 'id', {
            value: doc.id
          })
        }
      })
    }
  ),
  fetchCameraLocation: firestoreAction(
    async ({ commit }: any, payload: any) => {
      if (!payload?.params) {
        return
      }
      const cameraLocationArray = await formatCameraLocations(payload)

      const filteredLocations = cameraLocationArray?.filter((camera) => {
        const { location } = camera
        if (location?.latLng && location.latLng[0] && location.latLng[1]) {
          return true
        }
        return false
      })
      commit('setCameraLocationList', filteredLocations)
    }
  ),
  updateSmtpEnabledStatus: firestoreAction(
    (
      { state }: any,
      payload: {
        cameraId: string
        isSmtpEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isSmtpEnabled: payload.isSmtpEnabled
        },
        { merge: true }
      )
    }
  ),
  updateFtpEnabledStatus: firestoreAction(
    (
      { state }: any,
      payload: {
        cameraId: string
        isFtpEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        isFtpEnabled: payload.isFtpEnabled
      })
    }
  ),
  updateIsFTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isFTPEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isFTPNotificationsEnabled: payload.isFTPEnabled
        },
        { merge: true }
      )
    }
  ),
  updateFTPConfig: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        ftpData: any
      }
    ) => {
      // Remove undefined fields from ftpData object
      const filteredFTPData = Object.fromEntries(
        Object.entries(payload.ftpData).map(([key, value]) => [
          key,
          value !== undefined ? value : ''
        ])
      )

      return cameraCollection.doc(payload.cameraId).set(
        {
          ftpData: filteredFTPData
        },
        { merge: true }
      )
    }
  ),
  updateCamHcDueStatus: firestoreAction(
    (
      context,
      payload: {
        camera: any
        status: boolean
      }
    ) => {
      const { camera, status } = payload
      let cameraRef

      if (camera instanceof firebase.firestore.DocumentReference) {
        cameraRef = camera
      } else {
        cameraRef = firestore.doc(camera)
      }

      return cameraRef.update({ isHealthCheckDue: status })
    }
  ),
  updateInvalidCamHcDueStatus: firestoreAction(
    async (
      context,
      payload: {
        camera: any
        status: boolean
      }
    ) => {
      const { camera, status } = payload
      let cameraRef

      const cameraSnap = await cameraCollection
        .where('cameraId', '==', payload.camera.cameraId)
        .get()

      if (cameraSnap.empty) {
        store.commit('config/setIsNotFound', true)
        return
      }

      cameraRef = cameraSnap.docs.at(0).ref

      return cameraRef.update({ isHealthCheckDue: status })
    }
  ),
  addLiveHealthCheck: firestoreAction(async (context, payload: any) => {
    try {
      const cameraRef = cameraCollection.doc(payload.camera)
      const camera = await cameraRef.get()
      const siteRef = camera.data().site
      const site = await siteRef.get()
      const clientRef = site.data().client

      const liveHealthCheckData = {
        ...payload,
        camera: cameraRef,
        site: siteRef,
        client: clientRef
      }

      const result = await liveHealthCheckCollection.add(liveHealthCheckData)
      return result
    } catch (error) {
      console.error('Failed to add live health check:', error)
      throw error
    }
  }),
  updateHcDuration: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        healthCheckDuration: number
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        healthCheckDuration: payload.healthCheckDuration
      })
    }
  ),
  updateHcType: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        healthCheckType: string
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        healthCheckType: payload.healthCheckType
      })
    }
  ),
  bindHcDueCameras: firestoreAction(({ bindFirestoreRef }) => {
    // here Using WithConverter, the id field is re-attached to the camera item list.
    // Because sometimes the id field is not there or takes time to be populate.
    // When using Vuexfire to bind Firestore data to Vuex state, there can be a brief delay before the data
    // is fully available in the state. This delay is due to the asynchronous nature of Firestore queries and the binding process.
    return bindFirestoreRef(
      'hcDueCameras',
      cameraCollection
        .where('isHealthCheckDue', '==', true)
        .orderBy('createdAt', 'desc')
        .limit(10)
        .withConverter({
          toFirestore(camera) {
            return camera
          },
          fromFirestore(snapshot, options) {
            const data = snapshot.data(options)
            return {
              id: snapshot.id,
              ...data
            }
          }
        }),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  unbindHcDueCameras: firestoreAction(({ unbindFirestoreRef, commit }) => {
    commit('setHcDueCameras', null)
    return unbindFirestoreRef('hcDueCameras')
  }),
  updateLiveStreamStatus: firestoreAction(
    (context, payload: { cameraId: string; isLiveStreamEnabled: boolean }) => {
      if (payload.isLiveStreamEnabled) {
        return cameraCollection.doc(payload.cameraId).update({
          isLiveStreamEnabled: payload.isLiveStreamEnabled,
          isEdgeDeviceEnabled: false,
          isReferenceImageLoadingError: false,
          isReferenceImageLoading: true
        })
      } else {
        return cameraCollection.doc(payload.cameraId).update({
          isLiveStreamEnabled: payload.isLiveStreamEnabled,
          isReferenceImageLoadingError: false,
          isReferenceImageLoading: true
        })
      }
    }
  ),
  updateEdgeDeviceStatus: firestoreAction(
    (context, payload: { cameraId: string; isEdgeDeviceEnabled: boolean }) => {
      if (payload.isEdgeDeviceEnabled) {
        return cameraCollection.doc(payload.cameraId).update({
          isEdgeDeviceEnabled: payload.isEdgeDeviceEnabled,
          isLiveStreamEnabled: false,
          isReferenceImageLoading: true,
          extractingImageFromEdgeDeviceAt:
            firebase.firestore.FieldValue.serverTimestamp()
        })
      } else {
        return cameraCollection.doc(payload.cameraId).update({
          isEdgeDeviceEnabled: payload.isEdgeDeviceEnabled
        })
      }
    }
  ),
  updateLiveStream: firestoreAction(
    (context, payload: { cameraId: string; liveStream: any }) => {
      return cameraCollection.doc(payload.cameraId).update({
        liveStream: payload.liveStream
      })
    }
  ),
  updateEdgeDevice: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        edgeDevice: any
        isCredentialChanged: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        edgeDevice: payload.edgeDevice,
        isAuthenticationRequestComplete: !payload.isCredentialChanged
      })
    }
  ),
  updateEdgeDeviceRecordingStatus: firestoreAction(
    (
      context,
      payload: { cameraId: string; isEdgeDeviceRecordingEnabled: boolean }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        isEdgeDeviceRecordingEnabled: payload.isEdgeDeviceRecordingEnabled
      })
    }
  ),
  updateSiaConfig: firestoreAction(
    (context, payload: { cameraId: string; siaData: any }) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          siaData: payload.siaData
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isSiaEnabled: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isSiaEnabled: payload.isSiaEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaHeartbeatEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isSiaHeartbeatEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isSiaHeartbeatEnabled: payload.isSiaHeartbeatEnabled
        },
        { merge: true }
      )
    }
  ),
  updateIsRelatedSiaAccountNotifiedStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isRelatedSiaAccountNotified: boolean
      }
    ) => {
      cameraCollection.doc(payload.cameraId).set(
        {
          isRelatedSiaAccountNotified: payload.isRelatedSiaAccountNotified
        },
        { merge: true }
      )
    }
  ),
  updateIsSiaRoutineMessageEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isSiaRoutineMessageEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isSiaRoutineMessageEnabled: payload.isSiaRoutineMessageEnabled
        },
        { merge: true }
      )
    }
  ),
  async bindHeitelStream(
    { state, commit }: any,
    payload: {
      cameraId: string
      url: string
      username: string
      password: string
    }
  ): Promise<void> {
    let session = null
    let cameraNo = null
    if (liveStreamInterval) {
      window.clearInterval(liveStreamInterval)
    }
    try {
      // do the first request to get the cameraNo and session
      const initialResponse: any = await cameraProvider.getHeitelImage(
        {
          ...payload,
          session,
          cameraNo
        },
        3000,
        3
      )

      if (initialResponse.data.session && initialResponse.data.cameraNo) {
        session = initialResponse.data.session
        cameraNo = initialResponse.data.cameraNo
        commit('setHeitelImage', initialResponse.data.image)
        liveStreamInterval = window.setInterval(async () => {
          await cameraProvider
            .getHeitelImage(
              {
                ...payload,
                session,
                cameraNo
              },
              3000
            )
            .then((response: any) => {
              if (liveStreamInterval) {
                session = response.data.session ?? null
                cameraNo = response.data.cameraNo ?? null
                commit('setHeitelImage', response.data.image)
              }
            })
            .catch((err) => {
              window.clearInterval(liveStreamInterval)
              const message =
                err.code === 'NOT_FOUND'
                  ? 'Requested camera not found'
                  : err.message ??
                    'Something went wrong. Refresh the page or try again later.'
              throw new Error(message)
            })
        }, 2000)
      }
    } catch (err) {
      const error = err as any
      const message =
        error.code === 'NOT_FOUND'
          ? 'Requested camera not found'
          : error.message ??
            'Something went wrong. Refresh the page or try again later.'

      throw new Error(message)
    }
  },
  unbindHeitelStream({ state, commit }: any) {
    commit('setHeitelError', null)
    commit('setHeitelImage', null)
    if (liveStreamInterval) {
      window.clearInterval(liveStreamInterval)
      liveStreamInterval = null
    }
  },
  updateHumanReviewStatus: firestoreAction(
    (context, payload: { cameraId: string; isHumanReviewEnabled: boolean }) => {
      return cameraCollection.doc(payload.cameraId).update({
        isAskCrowdWorkers: payload.isHumanReviewEnabled
      })
    }
  ),
  updateIsEmailNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isEmailNotificationEnabled: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isEmailNotificationEnabled: payload.isEmailNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateNotificationEmails: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        notificationEmails: { email: string }[]
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          notificationEmails: payload.notificationEmails
        },
        { merge: true }
      )
    }
  ),
  updateIsEvalinkNotificationEnabledStatus(
    context,
    payload: {
      cameraId: string
      isEvalinkEnabled: any
    }
  ): Promise<void> {
    return cameraProvider.changeIsEvalinkNotificationEnabledStatus(
      payload.cameraId,
      payload.isEvalinkEnabled
    )
  },
  updateIsEvalinkDeviceOverrideEnabledStatus(
    context,
    payload: {
      cameraId: string
      isEvalinkDeviceOverrideEnabled: any
    }
  ): Promise<void> {
    return cameraProvider.changeIsEvalinkDeviceOverrideEnabledStatus(
      payload.cameraId,
      payload.isEvalinkDeviceOverrideEnabled
    )
  },
  updateEvalinkConfig(
    context,
    payload: {
      cameraId: string
      evalinkData: any
    }
  ): Promise<void> {
    return cameraProvider.addEvalinkConfig(
      payload.cameraId,
      payload.evalinkData
    )
  },
  async updateIsWebhookTriggerEnabledStatus(
    context,
    payload: {
      cameraId: string
      isWebhookTriggerEnabled: any
    }
  ): Promise<void> {
    return cameraProvider.changeIsWebhookTriggerEnabledStatus(
      payload.cameraId,
      payload.isWebhookTriggerEnabled
    )
  },
  addWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        webhookTriggerData: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          webhookTriggerData: payload.webhookTriggerData
        },
        { merge: true }
      )
    }
  ),
  updateWebhookTriggerConfig: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        webhookTriggerData: any
      }
    ) => {
      // Update only urlParams if it exists in the payload
      const updateData = {}
      if (payload.webhookTriggerData.urlParams) {
        updateData['webhookTriggerData.urlParams'] =
          payload.webhookTriggerData.urlParams
      }
      if (payload.webhookTriggerData.headerParams) {
        updateData['webhookTriggerData.headerParams'] =
          payload.webhookTriggerData.headerParams
      }

      return cameraCollection.doc(payload.cameraId).update(updateData)
    }
  ),
  updateSiaIntegration(
    context,
    payload: {
      cameraId: string
      data: any
    }
  ): Promise<HttpsCallableResult> {
    return cameraProvider.generateSiaReceiver(payload.cameraId, payload.data)
  },
  updateIsHealthCheckNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isHealthCheckNotificationEnabled: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        isHealthCheckNotificationEnabled:
          payload.isHealthCheckNotificationEnabled
      })
    }
  ),
  updateIsLatestHealthCheckNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isLatestHealthCheckNotificationEnabled: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        isLatestHealthCheckNotificationEnabled:
          payload.isLatestHealthCheckNotificationEnabled
      })
    }
  ),
  updateAlarmDefinitionConfig: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        alarmDefinitionConfig: string
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        alarmDefinitionConfig: payload.alarmDefinitionConfig
      })
    }
  ),
  updateUnarmedTimeRange: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        unarmedTimeRange: object
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        unarmedTimeRange: payload.unarmedTimeRange
      })
    }
  ),
  updateContact: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        contact: object
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).update({
        contact: payload.contact
      })
    }
  ),

  updateIsSMTPNotificationEnabledStatus: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        isSMTPNotificationEnabled: boolean
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          isSMTPNotificationEnabled: payload.isSMTPNotificationEnabled
        },
        { merge: true }
      )
    }
  ),
  updateSMTPConfig: firestoreAction(
    (
      context,
      payload: {
        cameraId: string
        smtpNotificationData: any
      }
    ) => {
      return cameraCollection.doc(payload.cameraId).set(
        {
          smtpNotificationData: payload.smtpNotificationData
        },
        { merge: true }
      )
    }
  ),

  // set camera doc id
  setCameraDocId({ commit }: any, payload: string) {
    commit('setCameraDocId', payload)
  },

  // set previous camera reference image is deleted
  setPreviousCameraReferenceImageIsDeleted({ commit }: any, payload: boolean) {
    commit('setPreDeleteReferenceImage', payload['isDeleted'])
  },

  async getIsSiteHardwareDevice({ commit }: any, payload: any) {
    if (!payload.site === undefined) {
      return false
    }
    const siteDoc = await firestore.doc(payload.site).get()
    const site = siteDoc.exists ? siteDoc.data() : null

    const isSiteHardwareDevice = site?.isHardwareDevice
      ? site.isHardwareDevice
      : false

    return isSiteHardwareDevice
  },

  getHcTypeByCamDocId: firestoreAction(async ({ commit }, camDocId) => {
    if (!camDocId) {
      commit('setHealthCheckType', 'Incident')
      return
    }

    let healthCheckType = 'Incident'

    try {
      // Fetching camera data
      const cameraDoc = await cameraCollection.doc(camDocId).get()
      const camera = cameraDoc.exists ? cameraDoc.data() : null

      if (camera?.blockToggles?.healthCheck) {
        healthCheckType = camera?.healthCheckType || 'Incident'
      } else {
        // Fetching site data
        const siteDoc = camera?.site ? await camera.site.get() : null
        const site = siteDoc && siteDoc.exists ? siteDoc.data() : null

        if (site?.blockToggles?.healthCheck) {
          healthCheckType = site?.healthCheckType || 'Incident'
        } else {
          // Fetching client data
          const clientDoc = site?.client ? await site.client.get() : null
          const client = clientDoc && clientDoc.exists ? clientDoc.data() : null

          healthCheckType = client?.healthCheckType || 'Incident'
        }
      }

      commit('setHealthCheckType', healthCheckType)
    } catch (error) {
      commit('setHealthCheckType', 'Incident')
    }
  }),
  async getEagleEyeConfig(
    { state, commit }: any,
    payload: {
      cameraDocId: string
    }
  ): Promise<{
    accessToken: string
    baseUrl: string
  }> {
    try {
      const cameraDoc = await cameraCollection.doc(payload.cameraDocId).get()
      if (cameraDoc.exists) {
        const siteRef: DocumentReference = cameraDoc.data().site
        const siteDoc = await siteRef.get()
        if (siteDoc.exists) {
          const siteId = siteDoc.data().siteId
          const eagleEye = await eagleEyeAccountCollection.doc(siteId).get()
          if (eagleEye.exists) {
            const eagleEyeAccount = eagleEye.data()
            return {
              accessToken: eagleEyeAccount.auth.access_token,
              baseUrl: `https://${eagleEyeAccount.baseUrl}`
            }
          }
        }
      }

      return null
    } catch (error) {
      return null
    }
  },
  async lastIntegrityCheckHcDate(
    { state, commit }: any,
    { cameraDocId, hcType }: { cameraDocId: string; hcType: string }
  ) {
    try {
      const cameraDoc = await cameraCollection.doc(cameraDocId).get()
      if (!cameraDoc.exists) {
        throw new Error('Camera document not found')
      }

      const cameraRef = cameraDoc.ref
      let latestHealthCheck

      if (hcType === 'Incident') {
        latestHealthCheck = await todosCollection
          .where('cameraRef', '==', cameraRef)
          .orderBy('healthCheck.createdAt', 'desc')
          .limit(1)
          .get()
      } else {
        latestHealthCheck = await liveHealthCheckCollection
          .where('camera', '==', cameraRef)
          .orderBy('createdAt', 'desc')
          .limit(1)
          .get()
      }

      if (latestHealthCheck.empty) {
        return null
      } else {
        const healthCheckData = latestHealthCheck.docs[0].data()

        const createdAt =
          hcType === 'Incident'
            ? healthCheckData.healthCheck.createdAt
            : healthCheckData.createdAt

        return createdAt
      }
    } catch (error) {
      console.error('Error fetching health check data:', error)
      return null
    }
  }
}

export default {
  ...actions
}
function commit(arg0: string, updatedAt: any) {
  throw new Error('Function not implemented.')
}
