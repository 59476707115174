<template>
  <div>
    <v-dialog max-width="600px" text-xs-right v-model="isDialog" persistent>
      <v-card
        style="padding: 10px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor,
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
      >
        <v-card :style="{ backgroundColor: error ? '#FF4444' : '#4BB543' }">
          <v-card-title class="text-subtitle-1" :style="{ color: 'white' }">
            <span v-if="!error"
              >Successfully added the collaborator and email has been sent</span
            >
            <span v-else>An Error Occured during adding the collaborator</span>
          </v-card-title>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
    // Avatar
  }
})
export default class SuccesMessageDialog extends Vue {
  @Getter('currentUser', namespaceUser)
  public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  public isDialog: boolean = true
  public userRoles = []

  get error() {
    return this.$store.getters.error
  }
  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  mounted() {
    this.$store.dispatch('clearError')
  }
}
</script>

<style scoped>
.close-btn:hover {
  background-color: transparent !important;
}
</style>
