import {
  CLOUD_FUNCTION_REGION,
  EMULATE_FUNCTIONS,
  PROJECT_ID
} from '@/utils/EnvMapping'

export const cloudFunctionsURL = () => {
  if (EMULATE_FUNCTIONS === 'true') {
    return `http://localhost:5000/${PROJECT_ID}/${CLOUD_FUNCTION_REGION}/getIncidentObservation`
  } else {
    return `https://${CLOUD_FUNCTION_REGION}-${PROJECT_ID}.cloudfunctions.net`
  }
}

export enum Endpoint {
  Admin = 'admin',
  ThreatDetect = 'threat-detect'
}
