<template>
  <div class="sui-sorting">
    <div class="sui-sorting__label">Sort by: Created At</div>
    <select
      name="createdat"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option value="asc">Asc</option>
      <option value="desc">Desc</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>
