<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div>
      <div class="container" v-if="error" id="canvas-container">
        <ProgressBar :loading="!isExcludedZonesSaved"></ProgressBar>

        <div id="controls" v-if="!viewOnly">
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="resetBtn"
            @click="reset"
            title="Reset current drawing and sync with server"
            data-test-id="reset-region"
          >
            Reset
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="clearBtn"
            @click="clearCanvas"
            :disabled="points.length <= 0"
            title="Clear current drawing"
            data-test-id="clear-region"
          >
            Clear
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="undoBtn"
            @click="undo"
            :disabled="points.length <= 0"
            title="Undo last point"
            data-test-id="undo-region"
          >
            Undo
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="redoBtn"
            @click="redo"
            :disabled="redoPoints.length <= 0"
            title="Redo last point"
            data-test-id="redo-region"
          >
            Redo
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="clearAllBtn"
            @click="clearAll"
            :disabled="regions.length <= 0"
            title="Clear all drawings"
            data-test-id="clear-all-regions"
          >
            Clear All
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="completeRegion"
            @click="completeRegion"
            :disabled="points.length <= 0"
            title="Complete current polygon"
            data-test-id="complete-region"
          >
            Complete Region
          </button>
          <!-- <v-select
            id="colorSelect"
            v-model="color"
            :items="colorList"
            :menu-props="{ maxHeight: '400' }"
            dense
            dense-menu
            style="margin: 0 10px"
          >
          </v-select> -->
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="saveAllBtn"
            @click="saveAll"
            :disabled="!isReadyToSave"
            v-if="regions.length > 1"
            title="Save all drawings"
            data-test-id="save-all-regions"
          >
            Save All
          </button>
          <button
            class="header-btn mx-1 text-xl-body-2 text-md-caption"
            id="save"
            @click="saveAll"
            :disabled="!isReadyToSave"
            v-else
            :title="
              regions.length === 1
                ? 'Save drawing'
                : 'Remove all drawings and save'
            "
            data-test-id="save-excluded-zone"
          >
            {{ regions.length === 1 ? 'Save' : 'Remove all & save' }}
          </button>
        </div>
        <div id="regions" ref="regions"></div>
      </div>
      <div v-else class="error-container">
        <h3
          style="
            color: red;
            text-align: center;
            margin-top: 20px;
            font-size: 20px;
          "
        >
          {{ error.message }}
        </h3>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          btnStyle="outlined"
          class="region-close-btn"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import p5 from 'p5'
import { Action } from 'vuex-class'
import ProgressBar from '../ProgressBar.vue'
import Button from '@/components/app/Button.vue'
import { Getter } from 'vuex-class'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    ProgressBar,
    Button
  }
})
export default class ExcludedZones extends Vue {
  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop() public referenceImage: string
  @Prop({ default: false }) public viewOnly: boolean
  @Prop({ default: () => [] }) public readonly existingRegions: any[]
  @Prop({
    default: {
      width: 0,
      height: 0
    }
  })
  public readonly metadata: {
    width: number
    height: number
  }
  @Prop({ default: '' }) public cameraDocId: string

  @Action('updateExcludedZones', namespaceCamera)
  private updateExcludedZones: any

  private image: p5.Image
  private p5: p5
  public regions: { points: p5.Vector[] }[] = []
  public points: p5.Vector[] = []
  public redoPoints: p5.Vector[] = []
  public regionCompleted: boolean = false
  // public colorList: string[] = ['Red', 'Blue', 'Green', 'Yellow', 'Pink']
  public color: string = 'green'

  public error: { message: string } | {} = {}
  public loading: boolean = false
  public isExcludedZonesSaved: boolean = true
  public isReadyToSave: boolean = false

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  private createNewRegion() {
    return {
      points: []
    }
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.init()
    this.regions = this.convertToExcludedZones()
  }

  private convertToExcludedZones() {
    const excludedZones: any[] = []

    for (const region of this.existingRegions) {
      const newRegion = this.createNewRegion()
      for (const point of region.coordinates[0]) {
        // multiply with image width and height to get the actual coordinates
        newRegion.points.push(
          new p5.Vector(
            point[0] * this.metadata.width,
            point[1] * this.metadata.height
          )
        )
      }
      excludedZones.push(newRegion)
    }

    return excludedZones
  }

  public async init() {
    // if #canvas-viewer is not available, create it and append to #canvas-container as first child
    if (!document.getElementById('canvas-viewer')) {
      const canvasContainer = document.getElementById('canvas-container')
      const canvasViewer = document.createElement('div')
      canvasViewer.setAttribute('id', 'canvas-viewer')
      canvasContainer.insertBefore(canvasViewer, canvasContainer.firstChild)
    }

    this.p5 = new p5(
      (p) => {
        p.preload = () => {
          this.image = p.loadImage(
            this.referenceImage,
            () => {
              this.loading = false
            },
            () => {
              this.loading = false
              this.error = {
                message: 'Error loading image'
              }
            }
          )
        }

        p.setup = () => {
          // TODO : keep aspect ratio respect to max-width 720
          // const canvas = p.createCanvas(
          //   720,
          //   (720 * this.image.height) / this.image.width
          // )

          const canvas = p.createCanvas(this.image.width, this.image.height)
          // if landscape
          if (this.image.width > this.image.height) {
            canvas.style('width', '100%')
            canvas.style('height', 'auto')
          }
          // if portrait
          else {
            canvas.style('width', 'auto')
            canvas.style('height', '80vh')
          }

          canvas.mousePressed(() => {
            this.addPoint()
          })
        }

        p.draw = () => {
          p.background(255)
          if (this.image) {
            p.image(this.image, 0, 0, p.width, p.height)
          }
          p.strokeWeight(4)
          p.stroke(this.color)
          for (let i = 0; i < this.points.length - 1; i++) {
            p.line(
              this.points[i].x,
              this.points[i].y,
              this.points[i + 1].x,
              this.points[i + 1].y
            )
          }
          p.fill(this.color)
          for (let i = 0; i < this.points.length; i++) {
            p.ellipse(this.points[i].x, this.points[i].y, 5, 5)
          }

          // draw regions as shapes
          // fill opacity
          for (const region of this.regions) {
            p.fill(0, 128, 0, 50)
            p.beginShape()
            for (const point of region.points) {
              p.vertex(point.x, point.y)
            }
            p.endShape(p.CLOSE)
          }
        }
      },
      // if this.$refs['canvas-viewer'] exists, then it will be used as the parent element for the canvas
      this.$refs['canvas-viewer']
        ? (this.$refs['canvas-viewer'] as HTMLElement)
        : ('canvas-viewer' as any)
    )
  }

  public addPoint() {
    // use two decimal places
    this.points.push(
      new p5.Vector(Math.round(this.p5.mouseX), Math.round(this.p5.mouseY))
    )

    if (this.redoPoints.length > 0) {
      this.redoPoints = []
    }

    this.isReadyToSave = false
  }

  public reset() {
    this.points = []
    this.redoPoints = []
    this.regionCompleted = false
    this.isExcludedZonesSaved = true
    this.regions = this.convertToExcludedZones()
  }

  public clearCanvas() {
    this.points = []
    this.redoPoints = []
  }
  public undo() {
    if (this.points.length > 0) {
      this.redoPoints.push(this.points.pop()!)
    }
    this.regionCompleted = false
  }
  public redo() {
    if (this.redoPoints.length > 0) {
      this.points.push(this.redoPoints.pop()!)
    }
    this.regionCompleted = false
  }

  public clearAll() {
    this.regions = []
    this.points = []
    this.redoPoints = []
    // isReadyToSave is set to true , in order to remove all the excluded zones
    this.isReadyToSave = true
  }

  private saveRegion() {
    this.regions.push({ points: this.points })
    this.points = []
    this.isReadyToSave = true
  }

  public completeRegion() {
    if (this.points.length >= 3) {
      this.points.push(this.points[0])
      this.regionCompleted = true
      this.saveRegion()
    } else {
      this.points = []
      this.redoPoints = []
      this.regionCompleted = false
    }
  }

  public async addRegion() {
    this.regions.push({
      points: this.points
    })
    this.points = []
    this.regionCompleted = false
  }

  public async saveAll() {
    try {
      this.isReadyToSave = false

      // convert regions to data structure
      //  Data structure for regions :
      //  https://www.notion.so/promiseq/Working-with-Masked-Areas-f5b6df7e962f433499c215c68b9661d8?pvs=4#aabe62fd3e2d448087ca8eb6dd21f4db
      const regions = this.regions.map((region) => {
        return {
          type: 'Polygon',
          coordinates: [
            region.points.map((point) => {
              // save as percentage of image width and height to 3 decimal places
              return [
                Math.round((point.x / this.image.width) * 1000) / 1000,
                Math.round((point.y / this.image.height) * 1000) / 1000
              ]
            })
          ]
        }
      })

      this.isExcludedZonesSaved = false

      this.snackbar = true
      this.snackbarMessage = 'Saving regions...'
      // save regions to server
      await this.updateExcludedZones({
        cameraId: this.cameraDocId,
        excludedZones: JSON.stringify(regions),
        metadata: {
          width: this.image.width,
          height: this.image.height
        }
      })
      this.snackbar = true
      this.snackbarMessage = 'Regions saved successfully'
    } catch (e) {
      this.error = {
        message: 'Error saving regions, please try again'
      }
    } finally {
      this.isExcludedZonesSaved = true
      this.isReadyToSave = true
    }
  }

  public beforeDestroy() {
    this.p5.remove()

    // remove all regions canvases
    while ((this.$refs.regions as any).children.length > 0) {
      ;(this.$refs.regions as any).children[0].remove()
    }

    // remove canvas-viewer if it exists
    if (this.$refs['canvas-viewer']) {
      ;(this.$refs['canvas-viewer'] as any).remove()
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  border: 2px solid #ffd42a;
  border-radius: 4px;
  contain: content;
  inline-size: fit-content;
  max-width: 90% !important;
  /* max-height: 90vh !important; */
}

#canvas-viewer {
  cursor: crosshair;
}

#controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: 100%;
  min-width: 80px;
  padding: 10px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.header-btn {
  background-color: #ffe88e !important;
  color: black !important;
  border-radius: 4px !important;
  text-transform: unset !important;
  border-color: #ffe88e !important;
  border-width: 0px !important;
}

.header-btn:hover {
  background-color: #ffd42a !important;
  border-color: #ffd42a !important;
}

.header-btn:active {
  background-color: #ffd42a !important;
  border-color: #ffd42a !important;
}

.header-btn::before {
  background-color: #ffd42a !important;
}

.header-btn--selected {
  background-color: #ffd42a !important;
  color: black !important;
  opacity: 1 !important;
}

.tr-button {
  background-color: grey;
}

.btn-danger {
  background-color: #f44336 !important;
}

.btn-danger:hover {
  background-color: #ef5350 !important;
}

.btn-danger:active {
  background-color: #e53935 !important;
}

.region-close-btn {
  background-color: #ffd42a !important;
}
</style>
