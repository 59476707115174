var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-flex',{staticClass:"pt-1 ml-1",attrs:{"row":""}},[_c('Header',{staticStyle:{"font-size":"1.25rem","padding-top":"18px"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      }),attrs:{"bannerTitle":'Manage Admin list',"data-test-id":"manage-admin-list-title"},scopedSlots:_vm._u([{key:"headerActionItems",fn:function(){return [_c('v-flex',{staticClass:"user-management__sticky-header"},[_c('v-flex',{attrs:{"row":""}},[_c('v-col',[_c('v-text-field',{staticStyle:{"width":"300px","margin-left":"auto"},attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","label":"Search","data-test-id":"search-admin-input","single-line":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-spacer'),_c('v-col',[_c('div',{attrs:{"text-xs-right":""}},[_c('NewUser',{attrs:{"isWriteEnabled":_vm.isWriteEnabled,"handleCreateUser":_vm.handleCreateUser,"userRole":'Administrator'}})],1)])],1)],1)]},proxy:true}])})],1),_c('v-data-table',_vm._b({staticStyle:{"padding":"0 28px"},style:({
      backgroundColor:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.darkPrimaryColor
          : _vm.getColors.lightPrimaryColor
    }),attrs:{"value":_vm.selectedUsers,"headers":_vm.headers,"items":_vm.users,"search":_vm.searchText,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItems
    },"must-sort":"","items-per-page":10,"show-select":_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"admin-table","item-class":() => 'result-item'},on:{"input":_vm.handleSelectionChanged},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('Avatar',{staticStyle:{"padding-top":"6px"},attrs:{"id":item.id}})]}},{key:"item.workerActions",fn:function({ item }){return [_c('Button',{staticClass:"secondary black--text",attrs:{"disabled":item.workerStatus !== 'online'},on:{"onButtonClick":function($event){return _vm.handleForceLogoutWorker(item.id)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-logout-variant ")])]},proxy:true}],null,true)})]}}])},'v-data-table',_vm.$attrs,false),[(_vm.isLoading)?_c('template',{slot:"no-data"},[_c('tr',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)]):_c('template',{slot:"no-data"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99"}},[_vm._v(" No data available ")])])]),_c('template',{slot:"no-results"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{staticClass:"gt-table--center",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99","data-test-id":"search-no-results-message"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchText)+"\" returned no results. ")])])]),(_vm.showExtraFooterWithNoData)?_c('template',{slot:"footer"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99"}},[_vm._v(" No data available ")])])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }