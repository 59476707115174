import {
  liveHealthCheckCollection,
  liveHealthCheckProvider,
  firestore
} from '@/provider/firebase'
import firebase from 'firebase/compat/app'
import { firestoreAction } from 'vuexfire'

const PAGE_SIZE_HC: number = 12

const actions = {
  bindAllLiveHealthChecks: async (
    { commit, state }: any,
    payload: {
      startDate: Date
      endDate: Date
      filters: string[]
      isLoadMore: boolean
    }
  ) => {
    try {
      const { startDate, endDate, filters, isLoadMore } = payload

      if (!state.lastVisibleLiveHealthCheck || !isLoadMore) {
        commit('setLiveHealthChecks', [])
        commit('setLastVisibleLiveHealthCheck', null)
        commit('setLiveHealthChecksLoading', true)
        commit('setHasMoreLiveHealthChecks', true)
      } else {
        commit('setLiveHcLoadMoreLoading', true)
      }

      const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate)
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate)

      let query = liveHealthCheckCollection
        .where('createdAt', '>=', startTimestamp)
        .where('createdAt', '<=', endTimestamp)

      const filteredQuery =
        await liveHealthCheckProvider.applyHealthCheckFilters(query, filters)

      let finalQuery = filteredQuery
        .orderBy('createdAt', 'desc')
        .limit(PAGE_SIZE_HC)

      if (state.lastVisibleLiveHealthCheck) {
        finalQuery = finalQuery.startAfter(state.lastVisibleLiveHealthCheck)
      }

      const snapshot = await finalQuery.get()

      const docs = new Map<string, firebase.firestore.QueryDocumentSnapshot>()
      snapshot.forEach((doc) => {
        docs.set(doc.id, doc)
      })

      const mergedDocs = Array.from(docs.values())

      const healthCheckDocuments = []
      for (const doc of mergedDocs) {
        const data = doc.data()
        const { client, site, camera, ...rest } = data
        healthCheckDocuments.push({
          id: doc.id,
          healthCheck: rest,
          clientRef: client,
          siteRef: site,
          cameraRef: camera
        })
      }

      if (healthCheckDocuments.length > 0) {
        commit('appendLiveHealthChecks', healthCheckDocuments)
        commit(
          'setLastVisibleLiveHealthCheck',
          snapshot.docs[snapshot.docs.length - 1]
        )
      }

      commit(
        'setHasMoreLiveHealthChecks',
        snapshot.docs.length === PAGE_SIZE_HC
      )
      commit('setLiveHcLoadMoreLoading', false)
      commit('setLiveHealthChecksLoading', false)
    } catch (error) {
      console.error('Error binding all live health checks:', error)
    }
  },
  bindNodeLiveHealthChecks: async (
    { commit, state }: any,
    payload: {
      userId: string
      nodeType: string | null
      nodeId: string | null
      startDate: Date
      endDate: Date
      filters: string[]
      isLoadMore: boolean
    }
  ) => {
    try {
      const {
        userId,
        nodeType,
        nodeId,
        startDate,
        endDate,
        filters,
        isLoadMore
      } = payload

      if (!state.lastVisibleLiveHealthCheck || !isLoadMore) {
        commit('setLiveHealthChecks', [])
        commit('setLastVisibleLiveHealthCheck', null)
        commit('setLiveHealthChecksLoading', true)
        commit('setHasMoreLiveHealthChecks', true)
      } else {
        commit('setLiveHcLoadMoreLoading', true)
      }
      const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate)
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate)

      let query = liveHealthCheckCollection
        .where('createdAt', '>=', startTimestamp)
        .where('createdAt', '<=', endTimestamp)
        .orderBy('createdAt', 'desc')
        .limit(PAGE_SIZE_HC + 1)

      const filteredQuery =
        await liveHealthCheckProvider.applyHealthCheckFilters(query, filters)

      let healthCheckDocuments = []

      let { cameraRefs } = await liveHealthCheckProvider.getAccessedCameraRefs(
        userId,
        nodeType,
        nodeId
      )

      const docCollection = []
      const lastVisibleLiveHealthChecks = []
      const lastVisibleLiveHealthChecksState =
        state.lastVisibleLiveHealthCheck ?? []

      // Process queries in batch
      while (cameraRefs.length) {
        const batch = cameraRefs.splice(0, 30).map((ref) => firestore.doc(ref))

        let finalQuery = filteredQuery

        if (lastVisibleLiveHealthChecksState.length > 0) {
          const startAfterDoc = lastVisibleLiveHealthChecksState.shift()
          if (startAfterDoc) {
            finalQuery = finalQuery.startAfter(startAfterDoc)
          }
        }

        let docs = []
        docs = await finalQuery
          .where('camera', 'in', [...batch])
          .get()
          .then((results) =>
            results.docs.map((doc) => {
              const { client, site, camera, ...rest } = doc.data()
              return {
                id: doc.id,
                healthCheck: rest,
                clientRef: client,
                siteRef: site,
                cameraRef: camera,
                docRef: doc
              }
            })
          )

        docCollection.push(docs)
      }

      healthCheckDocuments = docCollection.flat().sort((a, b) => {
        if (a.healthCheck.createdAt.seconds > b.healthCheck.createdAt.seconds)
          return -1
        return 1
      })

      const hasMoreHealthChecks: boolean =
        healthCheckDocuments.length > PAGE_SIZE_HC

      healthCheckDocuments = healthCheckDocuments.slice(0, PAGE_SIZE_HC)

      // update last document reference for each batch
      docCollection.forEach((collection) => {
        if (collection.length < 1) {
          lastVisibleLiveHealthChecks.push({})
        }

        for (let index = 0; index < collection.length; index++) {
          const element = collection[index]

          if (healthCheckDocuments.findIndex((e) => e.id == element.id) < 0) {
            if (index == 0) {
              lastVisibleLiveHealthChecks.push({})
              break
            }
            lastVisibleLiveHealthChecks.push(collection[index - 1].docRef)
            break
          }

          // if document includes all the elements
          if (index == collection.length - 1) {
            lastVisibleLiveHealthChecks.push(collection[index].docRef)
          }
        }
      })

      if (healthCheckDocuments.length > 0) {
        commit('appendLiveHealthChecks', healthCheckDocuments)
        commit('setLastVisibleLiveHealthCheck', lastVisibleLiveHealthChecks)
      }

      commit('setHasMoreLiveHealthChecks', hasMoreHealthChecks)
      commit('setLiveHcLoadMoreLoading', false)
      commit('setLiveHealthChecksLoading', false)
    } catch (error) {
      console.error(`Error binding node live health checks: `, error)
    }
  },
  fetchBreadCrumbForHealthCheck: async (
    { commit }: any,
    payload: {
      nodeLevel: 'Global' | 'Collaborator' | 'Client' | 'Site' | 'Camera'
      cameraRef: firebase.firestore.DocumentReference
      siteRef: firebase.firestore.DocumentReference
      clientRef: firebase.firestore.DocumentReference
    }
  ) => {
    const { nodeLevel, cameraRef, siteRef, clientRef } = payload
    return await liveHealthCheckProvider.getNodeNames(
      nodeLevel,
      cameraRef,
      siteRef,
      clientRef
    )
  },
  fetchCameraId: async (
    { commit }: any,
    payload: {
      cameraRef: firebase.firestore.DocumentReference
    }
  ) => {
    const { cameraRef } = payload
    return await liveHealthCheckProvider.getCameraId(cameraRef)
  },
  fetchCameraData: async (
    { commit }: any,
    payload: {
      cameraRef: firebase.firestore.DocumentReference
    }
  ) => {
    const { cameraRef } = payload
    return await liveHealthCheckProvider.getCameraData(cameraRef)
  },
  bindLastLiveHealthCheck: firestoreAction(
    async (
      { bindFirestoreRef },
      payload: {
        userId: string
        nodeType: string
        nodeId: string
        siteId?: string
        clientId?: string
      }
    ) => {
      const { nodeId, nodeType, userId, siteId, clientId } = payload

      let query = liveHealthCheckCollection.orderBy('createdAt', 'desc')

      const { cameraRefs, siteRef, clientRef } =
        await liveHealthCheckProvider.getAccessedCameraRefs(
          userId,
          nodeType,
          nodeId,
          clientId,
          siteId
        )

      if (clientRef) {
        query = query.where('client', '==', clientRef)
      }

      if (siteRef) {
        query = query.where('site', '==', siteRef)
      }

      if (cameraRefs && cameraRefs.length == 1) {
        let cameraDocReference = firestore.doc(cameraRefs[0])
        query = query.where('camera', '==', cameraDocReference)
      }

      query = query.limit(1)

      return bindFirestoreRef('lastLiveHealthCheck', query, {
        maxRefDepth: 0,
        wait: true
      })
    }
  ),
  unbindLastLiveHealthCheck: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('lastLiveHealthCheck')
  })
}

export default {
  ...actions
}
