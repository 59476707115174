<template>
  <div style="display: none">
    <div>Environment Configuration</div>
    <div
      class="card-style"
      style="
        padding-right: 19px;
        padding-left: 19px;
        padding-top: 19px;
        padding-bottom: 19px;
      "
    >
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            AI Endpoint
          </p>
        </div>
        <v-text-field
          v-model="aiEndpoint"
          placeholder="AI Endpoint"
          hide-details="true"
          style="margin-top: -20px; font-size: 15px"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
        />
      </div>
      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            SIA Server
          </p>
        </div>
        <v-text-field
          v-model="siaServer"
          placeholder="SIA Server"
          hide-details="true"
          style="margin-top: -20px; font-size: 15px"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
        />
      </div>

      <div class="form-item">
        <div>
          <p
            class="font-weight-bold text-left"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
          >
            Default Deadline
          </p>
        </div>
        <v-text-field
          type="number"
          v-model="deadline"
          placeholder="45"
          hide-details="true"
          style="margin-top: -20px; font-size: 15px"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
        />
      </div>
      <v-row>
        <v-col cols="9">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Blip Endpoint
              </p>
            </div>
            <v-text-field
              v-model="blipEndpoint"
              placeholder="Blip Endpoint"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isBlipEnabled"
              label="Enable Blip"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Similarity Search Endpoint
              </p>
            </div>
            <v-text-field
              v-model="simSearchEndpoint"
              placeholder="Similarity Search Endpoint"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isSimSearchEnabled"
              label="Enable Similarity Search"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isObjectIdsEnabled"
              label="Enable Object IDs"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Redis Server
              </p>
            </div>
            <v-text-field
              v-model="redisServer"
              placeholder="Redis Server"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Redis Port
              </p>
            </div>
            <v-text-field
              v-model="redisPort"
              placeholder="Redis Port"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Redis Password
              </p>
            </div>
            <v-text-field
              type="password"
              v-model="redisPassword"
              placeholder="Redis Password"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isRedisCachingEnabled"
              label="Enable Redis Caching"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="9">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Route Traffic Endpoint
              </p>
            </div>
            <v-text-field
              v-model="routeTrafficEndpoint"
              placeholder="Route Traffic Endpoint"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isRouteTrafficEnabled"
              label="Enable Route Traffic"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                Web App Processing Library
              </p>
            </div>
            <v-select
              v-model="webAppProcessLibrary"
              :items="Object.values(webAppProcessingLibraries)"
              hide-details
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-item" style="padding-top: 20px">
            <v-switch
              v-model="isWebAppMediaProcessingEnabled"
              label="Enable Media Processing"
              color="secondary"
              inset
              hide-details
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ Hostname
              </p>
            </div>
            <v-text-field
              v-model="rabbitMQ.HOSTNAME"
              placeholder="RabbitMQ Hostname"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ Port
              </p>
            </div>
            <v-text-field
              v-model="rabbitMQ.PORT"
              placeholder="RabbitMQ Port"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ Username
              </p>
            </div>
            <v-text-field
              v-model="rabbitMQ.USERNAME"
              placeholder="RabbitMQ Username"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ Password
              </p>
            </div>
            <v-text-field
              type="password"
              v-model="rabbitMQ.PASSWORD"
              placeholder="RabbitMQ Password"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ VHOST
              </p>
            </div>
            <v-text-field
              v-model="rabbitMQ.VHOST"
              placeholder="RabbitMQ VHOST"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
        <v-col cols="4">
          <div class="form-item" style="padding-top: 20px">
            <div>
              <p
                class="font-weight-bold text-left"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlack
                }"
              >
                RabbitMQ Alarms Exchange
              </p>
            </div>
            <v-text-field
              v-model="rabbitMQ.ALARMS_EXCHANGE"
              placeholder="RabbitMQ Alarms Exchange"
              hide-details="true"
              style="margin-top: -20px; font-size: 15px"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

enum WebAppProcessingLibrary {
  canvas = 'canvas',
  svg = 'svg'
}

@Component({
  components: {
    Button
  }
})
export default class EnvironmentConfiguration extends Vue {
  @Action('getConfigFromFirestore', namespaceConfig)
  public getConfigFromFirestore!: any
  @Getter('getConfig', namespaceConfig) public config: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public deadline: number = 0

  public aiEndpoint: string = ''
  public siaServer: string = ''

  public blipEndpoint: string = ''
  public isBlipEnabled: boolean = false

  public simSearchEndpoint: string = ''
  public isSimSearchEnabled: boolean = false
  public isObjectIdsEnabled: boolean = false

  public redisServer: string = ''
  public redisPort: string = ''
  public redisPassword: string = ''
  public isRedisCachingEnabled: boolean = false

  public routeTrafficEndpoint: string = ''
  public isRouteTrafficEnabled: boolean = false

  public webAppProcessLibrary: WebAppProcessingLibrary =
    WebAppProcessingLibrary.canvas
  public isWebAppMediaProcessingEnabled: boolean = false
  public webAppProcessingLibraries = WebAppProcessingLibrary

  public rabbitMQ = {
    ALARMS_EXCHANGE: '',
    CONFIG_MSG_QUEUE: '',
    CONFIG_MSG_ROUTING_KEY: '',
    HOSTNAME: '',
    PASSWORD: '',
    PORT: '',
    USERNAME: '',
    VHOST: ''
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    // await this.getConfigFromFirestore()
  }

  // @Watch('config')
  private onConfigChange() {
    this.deadline = this.config?.deadline

    this.aiEndpoint = this.config?.aiEndpoint
    this.siaServer = this.config?.siaServer

    this.blipEndpoint = this.config?.blipEndpoint
    this.isBlipEnabled = this.config?.isBlipEnabled

    this.simSearchEndpoint = this.config?.simSearchEndpoint
    this.isSimSearchEnabled = this.config?.isSimSearchEnabled
    this.isObjectIdsEnabled = this.config?.isObjectIdsEnabled

    this.redisServer = this.config?.redisServer
    this.redisPort = this.config?.redisPort
    this.redisPassword = this.config?.redisPassword
    this.isRedisCachingEnabled = this.config?.isRedisCachingEnabled

    this.routeTrafficEndpoint = this.config?.routeTrafficEndpoint
    this.isRouteTrafficEnabled = this.config?.isRouteTrafficEnabled

    this.webAppProcessLibrary = this.config?.webAppProcessLibrary
    this.isWebAppMediaProcessingEnabled =
      this.config?.isWebAppMediaProcessingEnabled

    this.rabbitMQ = this.config?.rabbitMQ
  }
}
</script>

<style scoped></style>
