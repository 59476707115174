import firebase from 'firebase/compat/app'
import {
  cameraCollection,
  clientCollection,
  siteCollection,
  userCollection
} from '@/provider/firebase'

export const formatCameraLocations = async (payload: any) => {
  if (payload.params.cameraId) {
    return getCameraLocation(payload.params)
  }
  if (payload.params.siteId) {
    return getSiteCameraLocations(payload.params)
  }
  if (payload.params.clientId) {
    return getClientCameraLocations(payload.params)
  }
  if (payload.params.userId) {
    return getUserCameraLocations(payload.params)
  }
  if (payload.role === 'Administrator') {
    return getAdminCameraLocations()
  }
}

const pushCameraLocationIntoArray = (cameraDoc: any) => {
  if (!cameraDoc?.location) {
    return null
  }
  if (cameraDoc.location?.lat == '' || cameraDoc.location?.lng == '') {
    return null
  }

  const locationArray = []
  locationArray.push(cameraDoc.location.lat)
  locationArray.push(cameraDoc.location.lng)

  return locationArray
}

const getAdminCameraLocations = async () => {
  const cameraSnapshot = await cameraCollection.get()
  const locationArray = cameraSnapshot.docs
    .map((doc) => {
      const data = doc.data()
      return pushCameraLocationIntoArray(data)
    })
    .filter(Boolean)

  return locationArray
}

const getCameraLocation = async (payload: {
  userId: string
  clientId: string
  siteId: string
  cameraId: string
}) => {
  const client = await clientCollection
    .where('clientId', '==', payload.clientId)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (client.empty) {
    console.log("Something went wrong or the client doesn't exist")
    return []
  }

  const clientDocRef = client.docs[0].ref

  const site = await siteCollection
    .where('siteId', '==', payload.siteId)
    .where('client', '==', clientDocRef)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (site.empty) {
    console.log("Something went wrong or the site doesn't exist")
    return []
  }

  const siteDocRef = site.docs[0].ref

  const cameraSnapshot = await cameraCollection
    .where('cameraId', '==', payload.cameraId)
    .where('site', '==', siteDocRef)
    .where('users', 'array-contains', payload.userId)
    .get()

  const locationArray = cameraSnapshot.docs
    .map((doc) => {
      const data = doc.data()
      return pushCameraLocationIntoArray(data)
    })
    .filter(Boolean)

  return locationArray
}
const getSiteCameraLocations = async (payload: {
  userId: string
  clientId: string
  siteId: string
}) => {
  const client = await clientCollection
    .where('clientId', '==', payload.clientId)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (client.empty) {
    return
  }

  const site = await siteCollection
    .where('siteId', '==', payload.siteId)
    .where('client', '==', client.docs[0].ref)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (site.empty) {
    return
  }

  const cameraSnapshot = await cameraCollection
    .where('site', '==', site.docs[0].ref)
    .where('users', 'array-contains', payload.userId)
    .get()

  const locationArray = cameraSnapshot.docs
    .map((doc) => {
      const data = doc.data()
      return pushCameraLocationIntoArray(data)
    })
    .filter(Boolean)

  return locationArray
}
const getClientCameraLocations = async (payload: {
  userId: string
  clientId: string
}) => {
  const client = await clientCollection
    .where('clientId', '==', payload.clientId)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (client.empty) {
    return
  }

  const clientSites = await siteCollection
    .where('client', '==', client.docs[0].ref)
    .where('users', 'array-contains', payload.userId)
    .get()

  if (clientSites.empty) {
    console.log("Something went wrong or clientSites doesn't exist")
    return
  }

  const locationArray = []
  const siteDocs = clientSites.docs
  const promises = siteDocs.map(async (siteDoc) => {
    const siteDocRef = siteDoc.ref
    const cameraSnapshot = await cameraCollection
      .where('site', '==', siteDocRef)
      .where('users', 'array-contains', payload.userId)
      .get()
    const docs = cameraSnapshot.docs
    const entryPromises = docs.map(async (doc) => {
      const data = doc.data()
      const entry = pushCameraLocationIntoArray(data)
      if (entry) {
        return entry
      }
    })
    return Promise.all(entryPromises)
  })

  const entries = await Promise.all(promises)
  const finalLocationArray = []
  locationArray.push(...entries.flat())
  locationArray.forEach((entry) => {
    if (entry) {
      finalLocationArray.push(entry)
    }
  })
  return finalLocationArray
}

const getUserCameraLocations = async (payload: { userId: string }) => {
  const cameraSnapshot = await cameraCollection
    .where('users', 'array-contains', payload.userId)
    .get()

  const locationArray = cameraSnapshot.docs
    .map((doc) => {
      const data = doc.data()
      return pushCameraLocationIntoArray(data)
    })
    .filter(Boolean)

  return locationArray
}
