var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","height":"70vh"}},[_c('div',{staticStyle:{"text-align":"center"}},[(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled)?_c('img',{staticStyle:{"max-width":"100%","height":"auto"},attrs:{"src":require("../../../public/img/no_integrity_dark.svg"),"alt":"No Camera SVG"}}):_c('img',{staticStyle:{"max-width":"100%","height":"auto"},attrs:{"src":require("../../../public/img/no_integrity_light.svg"),"alt":"No Camera SVG"}})]),_c('div',{staticStyle:{"padding-top":"20px","text-align":"center","max-width":"500px"}},[_c('p',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor,
        fontWeight: 'bold',
        fontSize: '30px',
        textAlign: 'center'
      })},[_vm._v(" "+_vm._s(`No ${_vm.healthCheckType} health checks.`)+" ")]),(_vm.isNodeConfigsAvailable)?_c('p',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor,
        textAlign: 'center',
        fontSize: '18px'
      })},[_vm._v(" "+_vm._s(`Sorry, but there is no ${_vm.healthCheckType} health checks for this ${_vm.nodeType} yet. You can configure health check to this ${_vm.nodeType} by going to Configs.`)+" ")]):_c('p',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor,
        textAlign: 'center',
        fontSize: '18px'
      })},[_vm._v(" "+_vm._s(`Sorry, but there is no ${_vm.healthCheckType} health checks for this yet.`)+" ")]),_c('div',{staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('button',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? 'refresh-dark-mode'
            : 'refresh-light-mode',on:{"click":_vm.refresh}},[_c('v-icon',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"size":"22"}},[_vm._v("mdi-refresh ")]),_vm._v(" Refresh ")],1),(_vm.isNodeConfigsAvailable)?_c('button',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? 'config-dark-mode'
            : 'config-light-mode',staticStyle:{"margin-left":"10px"},on:{"click":_vm.goConfig}},[_c('v-icon',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.darkPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"size":"22"}},[_vm._v(" mdi-cog ")]),_vm._v(" Configs ")],1):_c('button',{class:'goback-light-mode',staticStyle:{"margin-left":"10px"},on:{"click":_vm.goBack}},[_c('v-icon',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.darkPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"size":"22"}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Go Back ")],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }