import { ISnapshotState } from './state'

const getters = {
  getRouteSnapshot: (state: ISnapshotState) => {
    return state.route
  }
}

export default {
  ...getters
}
