<template>
  <div class="deadline-timer-container">
    <div class="deadline-timer">
      <span>{{ timeStamp.minutes }}</span>
      <span>:</span>
      <span>{{ timeStamp.seconds }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WorkerTodoTimer extends Vue {
  @Prop() public timeStamp: { minutes: number; seconds: string }
}
</script>

<style scoped>
.deadline-timer-container {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 5;
  bottom: 30px;
}
.deadline-timer {
  background: #ffffff;
  position: relative;
  width: 5%;
  border-radius: 0.3rem;
  font-weight: bold;
  font-style: normal;
  color: black;
  height: 30px;
  min-width: 50px;
}
</style>
