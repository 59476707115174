var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card-style text-subtitle-1 font-weight-bold text-left pl-4 py-4"},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"alarm-definitions-title"}},[_vm._v(" Alarm Definitions ")]),_c('div',{staticClass:"text-subtitle-2 notification-title pl-4"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        }),attrs:{"data-test-id":"person-detection-label"}},[_vm._v(" Person Detection"),_c('v-icon',{staticClass:"menu-icon",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" mdi-walk ")])],1),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"person-detection-switch","disabled":_vm.isPersonDetectionSwitchDisabled ||
          _vm.isToggleDisabled ||
          !_vm.isWriteEnabled,"text":"","inset":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"person-detection-switch"},model:{value:(_vm.isPersonDetectionEnabled),callback:function ($$v) {_vm.isPersonDetectionEnabled=$$v},expression:"isPersonDetectionEnabled"}})],1),_c('div',{staticClass:"text-subtitle-2 notification-title pl-4"},[_c('div',{staticClass:"text-subtitle-2",staticStyle:{"display":"inline-flex","align-items":"center"},style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        }),attrs:{"data-test-id":"vehicle-movement-label"}},[_vm._v(" Vehicle/Moving Vehicle detection "),_c('div',{staticClass:"ml-2"},[(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled)?_c('div',{staticClass:"menu-icon"},[_c('img',{attrs:{"src":'/img/vehicle_movement_white.svg',"alt":"Icon"}})]):_c('div',{staticClass:"menu-icon"},[_c('img',{attrs:{"src":'/img/vehicle_movement _black.svg',"alt":"Icon"}})]),_c('v-icon',{staticClass:"menu-icon",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-slash-forward ")]),_c('v-icon',{staticClass:"menu-icon",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" mdi-car-hatchback ")])],1)]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"vehicle-detection-switch","disabled":_vm.isVehicleDetectionSwitchDisabled ||
          _vm.isToggleDisabled ||
          !_vm.isWriteEnabled,"text":"","inset":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"vehicle-detection-switch"},on:{"change":_vm.onVehicleAlarmDefinitionChange},model:{value:(_vm.isVehicleDetectionEnabled),callback:function ($$v) {_vm.isVehicleDetectionEnabled=$$v},expression:"isVehicleDetectionEnabled"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }