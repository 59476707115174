import { RTCIceServerExtended } from './interfaces'

export const unquoteCredential = (v: string): string => JSON.parse(`"${v}"`)

export const linkToIceServers = (links: string | null): RTCIceServer[] => {
  return links
    ? links.split(', ').map((link) => {
        const m = RegExp(
          /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
        ).exec(link)

        const ret: RTCIceServerExtended = {
          urls: [m?.[1] || '']
        }

        if (m?.[3] !== undefined) {
          ret.username = unquoteCredential(m[3])
          ret.credential = unquoteCredential(m[4] || '')
          ret.credentialType = 'password'
        }

        return ret
      })
    : []
}

export const parseOffer = (
  offer: string
): {
  iceUfrag: string
  icePwd: string
  medias: string[]
} => {
  const ret = {
    iceUfrag: '',
    icePwd: '',
    medias: [] as string[]
  }

  for (const line of offer.split('\r\n')) {
    if (line.startsWith('m=')) {
      ret.medias.push(line.slice('m='.length))
    } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
      ret.iceUfrag = line.slice('a=ice-ufrag:'.length)
    } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
      ret.icePwd = line.slice('a=ice-pwd:'.length)
    }
  }

  return ret
}

export const generateSdpFragment = (
  offerData: { iceUfrag: string; icePwd: string; medias: string[] },
  candidates: RTCIceCandidate[]
): string => {
  const candidatesByMedia: { [key: number]: RTCIceCandidate[] } = {}
  for (const candidate of candidates) {
    const mid = candidate.sdpMLineIndex
    if (candidatesByMedia[mid] === undefined) {
      candidatesByMedia[mid] = []
    }
    candidatesByMedia[mid].push(candidate)
  }

  let frag =
    'a=ice-ufrag:' +
    offerData.iceUfrag +
    '\r\n' +
    'a=ice-pwd:' +
    offerData.icePwd +
    '\r\n'

  let mid = 0

  for (const media of offerData.medias) {
    if (candidatesByMedia[mid] !== undefined) {
      frag += 'm=' + media + '\r\n' + 'a=mid:' + mid + '\r\n'

      for (const candidate of candidatesByMedia[mid]) {
        frag += 'a=' + candidate.candidate + '\r\n'
      }
    }
    mid++
  }

  return frag
}
