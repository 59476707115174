<template>
  <div>
    <!-- Check if camera is not authenticated -->
    <div v-if="isAuthenticatedCamera === false">
      <NoCamera
        customTitle="Camera is not authenticated!"
        customText="Sorry, but this camera is not authenticated for live streaming. You can connect live streaming to this camera by going to Configs."
      />
    </div>

    <!-- Check if camera is turned off -->
    <div
      v-else-if="isSiteHardwareDevice === true && isEdgeDeviceEnabled === false"
    >
      <NoCamera
        customTitle="Camera is not enabled!"
        customText="Sorry, but this camera is not enabled for live streaming. You can enable this camera by going to Configs."
      />
    </div>

    <!-- If camera is authenticated, check for other conditions -->
    <div
      v-else-if="
        !(
          (isLiveStreamEnabled && isRtspAvailable) ||
          (isEdgeDeviceEnabled && isEdgeDeviceAvailable)
        )
      "
    >
      <template v-if="liveStreamError">
        {{ liveStreamError }}
      </template>
      <template v-else>
        <NoCamera nodeType="camera" />
      </template>
    </div>

    <!-- If camera is authenticated and other conditions are met, show camera -->
    <div v-else class="camera">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="amber"
      ></v-progress-circular>
      <div v-else class="camera">
        <div class="camera">
          <LiveStreamPlayer
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            :cameraConfig="cameraConfig"
            :cameraId="cameraConfig.id"
            :isSiteHardwareDevice="isSiteHardwareDevice"
            :liveStreamQuality="liveStreamQuality"
            :seekEnabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import LiveStreamPlayer from '../player/LiveStreamPlayer.vue'
import { LiveStreamQualityType } from '@/utils/Constants'
import NoCamera from '../../../components/error-pages/NoCamera.vue'

const namespaceCamera = { namespace: 'camera' }
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    LiveStreamPlayer,
    NoCamera
  }
})
export default class LiveViewCamera extends Vue {
  @Prop({
    default: ''
  })
  cameraId: string
  @Prop({
    default: false
  })
  isSiteHardwareDevice: boolean

  @Getter('cameraConfig', namespaceCamera) public cameraConfig
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getLiveStreamError', namespaceCamera) public liveStreamError
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isRtspAvailable = false
  public isEdgeDeviceAvailable = false
  public isMounted = false

  public isLoading = true

  public rtspUrl = ''
  public cameraDocId = ''
  public liveStreamType: string = null
  public isLiveStreamEnabled: boolean = false
  public isEdgeDeviceEnabled: boolean = false
  public liveStreamQuality = LiveStreamQualityType.HIGH

  public get isAuthenticatedCamera() {
    return this.cameraConfig?.isAuthenticated !== false
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public mounted() {
    this.$store.commit('camera/setLiveStreamError', null)
    this.isRtspAvailable = false
    this.isEdgeDeviceAvailable = false
    this.isLoading = true
    this.isMounted = true
    this.watchCameraConfig()
  }

  public beforeDestroy() {
    this.$store.commit('camera/setLiveStreamError', null)
  }

  @Watch('isAuthenticated')
  public watchIsAuthenticated(newValue: boolean, oldValue: boolean) {
    // Do nothing here if you only want to observe changes
  }

  @Watch('liveStreamError')
  private watchLiveStreamError() {
    if (this.liveStreamError && this.isMounted) {
      this.isLoading = false
    }
  }

  @Watch('cameraConfig')
  @Watch('isSiteHardwareDevice')
  private watchCameraConfig() {
    this.isLoading = true
    this.isRtspAvailable = false
    this.isEdgeDeviceAvailable = false
    this.isMounted = true

    if (!this.cameraConfig) {
      this.isLoading = false
      return
    }

    this.cameraDocId = this.cameraConfig.id
    this.liveStreamType = this.cameraConfig.liveStream?.type ?? ''
    this.isLiveStreamEnabled = this.cameraConfig.isLiveStreamEnabled ?? false
    this.isEdgeDeviceEnabled = this.cameraConfig.isEdgeDeviceEnabled ?? false

    if (this.isSiteHardwareDevice) {
      if (
        this.cameraConfig?.isAuthenticated &&
        this.isEdgeDeviceEnabled &&
        this.cameraConfig?.edgeDevice
      ) {
        this.isEdgeDeviceAvailable = true
      }
    } else if (
      this.isLiveStreamEnabled &&
      ['RTSP', 'Heitel'].includes(this.liveStreamType) &&
      this.liveStreamError === null
    ) {
      this.isRtspAvailable = true
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.camera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 116px);
  width: 100%;
}

.unauthenticated-overlay {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  color: black;
  font-size: 1rem;
}
.alert {
  padding: 20px;
  background-color: #ff5252;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
</style>
