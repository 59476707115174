import { getDefaultState } from './state'

const mutations = {
  setSummary: (state, payload) => {
    state.summary = {
      totalAlarmCount: payload.totalAlarmCount,
      falseAlarmCount: payload.falseAlarmCount,
      trueAlarmCount: payload.trueAlarmCount,
      reductionPercentage: payload.reductionPercentage,
      verifiedReductionPercentage: payload.verifiedReductionPercentage,
      riskPercentage: payload.riskPercentage
    }
    state.incidentSummaryStats = {
      labels: payload.incidentLabels,
      data: payload.incidentData
    }
  },
  setConfusionStats: (state, payload) => {
    state.confusionStats = payload
  },
  setLabeledStats: (state, payload) => {
    state.labeledStats = payload
  },
  setIncidentStats: (state, payload) => {
    state.incidentStats = payload
  },
  setIncidentCameraStats: (state, payload) => {
    state.incidentCameraStats = payload
  },
  setIncidentWeeklyStats: (state, payload) => {
    state.incidentWeeklyStats = payload
  },
  setIncidentCameraIdStats: (state, payload) => {
    state.incidentCameraIdStats = payload
  },
  setIncidentsPerCustomerStats: (state, payload) => {
    state.incidentsPerCustomerStats = payload
  },
  setInputTypeStats: (state, payload) => {
    state.inputTypeStats = payload
  },
  setRouteParams: (state, payload) => {
    state.routeParams = payload
  },
  setTreeNodes: (state, payload) => {
    state.treeNodes = payload
  },
  setFiLabel: (state, payload) => {
    state.fixedIntervalLabel = payload
  },
  setSankeyChartStats: (state, payload) => {
    state.sankeyChartStats = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setTotalAlarmCountPerMonth: (state, payload) => {
    state.totalAlarmCountPerMonth = payload
  },
  setTotalAlarmQuotaPerMonth: (state, payload) => {
    state.totalAlarmQuotaPerMonth = payload
  },
  setUsedAlarmQuotaPerMonth: (state, payload) => {
    state.usedAlarmQuotaPerMonth = payload
  },

  setIncidentsCustomerData: (state, payload) => {
    state.incidentsPerCustomerData = payload
  },
  setIncidentsCustomerStatus: (state, payload) => {
    state.incidentsPerCustomerStatus = payload
  },

  setIncidentsOverTimeData: (state, payload) => {
    state.incidentsOverTimeData = payload
  },
  setIncidentsOverTimeStatus: (state, payload) => {
    state.incidentsOverTimeStatus = payload
  },

  setIncidentsResultDistributionData: (state, payload) => {
    state.incidentsResultDistributionData = payload
  },
  setIncidentsResultDistributionStatus: (state, payload) => {
    state.incidentsResultDistributionStatus = payload
  },

  setResultDistributionData: (state, payload) => {
    state.resultDistributionData = payload
  },
  setResultDistributionStatus: (state, payload) => {
    state.resultDistributionStatus = payload
  },

  setFilteredAlarmsAccumulatedOverTimeData: (state, payload) => {
    state.filteredAlarmsAccumulatedOverTimeData = payload
  },
  setFilteredAlarmsAccumulatedOverTimeStatus: (state, payload) => {
    state.filteredAlarmsAccumulatedOverTimeStatus = payload
  },

  setUniqueCameraIncidentsOverTimeData: (state, payload) => {
    state.uniqueCameraIncidentsOverTimeData = payload
  },
  setUniqueCameraIncidentsOverTimeStatus: (state, payload) => {
    state.uniqueCameraIncidentsOverTimeStatus = payload
  },

  setIncidentsByInputTypeData: (state, payload) => {
    state.incidentsByInputTypeData = payload
  },
  setIncidentsByInputTypeStatus: (state, payload) => {
    state.incidentsByInputTypeStatus = payload
  },

  setCamerasThrowsIncidentsData: (state, payload) => {
    state.camerasThrowsIncidentsData = payload
  },
  setCamerasThrowsIncidentsStatus: (state, payload) => {
    state.camerasThrowsIncidentsStatus = payload
  },

  setSummaryData: (state, payload) => {
    state.summaryData = payload
  },
  setSummaryStatus: (state, payload) => {
    state.summaryStatus = payload
  },

  setUsageMetricsData: (state, payload) => {
    state.usageMetricsData = payload
  },
  setUsageMetricsStatus: (state, payload) => {
    state.usageMetricsStatus = payload
  },

  setIncidentWeeklyData(state, payload) {
    state.incidentWeeklyData = payload
  },
  setIncidentWeeklyStatus(state, payload) {
    state.incidentWeeklyStatus = payload
  }
}

export default {
  ...mutations
}
