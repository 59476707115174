<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
        title="More options"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(option, i) in optionsList"
        :key="i"
        class="menu-item"
      >
        <v-list-item-title
          class="menu-item-title"
          @click="option.action(data)"
          >{{ option.text }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
import { Getter } from 'vuex-class'

@Component
export default class Menu extends Vue {
  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop() public optionsList!: {
    text: string
    value: string
    action: () => void
  }[]

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  @Prop({ default: () => [] }) public data!: any
}
</script>

<style>
.menu-item:hover {
  background-color: #ffe88e !important;
  color: black !important;
}
.menu-item:active {
  background-color: #ffd42a !important;
}
</style>
