import {
  cameraCollection,
  clientCollection,
  configCollection,
  configProvider,
  functions,
  siteCollection,
  userCollection
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import axios from 'axios'
import { findBreadcrumbNodes } from '@/utils/Navigation'
import { getNodeUnarmedStatus, swapElements } from '@/utils/Utils'
import moment from 'moment'
import { P2pPlayer, P2pPlayerConfig, PlayerType } from '@/utils/Interfaces'
import {
  DocumentReference,
  DocumentSnapshot,
  QuerySnapshot
} from 'firebase-admin/firestore'

let timeFilterInterval

let subscriptions = {
  user: null,
  client: null,
  site: null,
  camera: null
}

const cameraSnapshotListeners: (() => void)[] = []
const siteSnapshotListeners: (() => void)[] = []

const actions = {
  addNewSubscriptionKey({ commit }, payload: any) {
    return configProvider.addNewAcceptedSubscriptionKey(
      payload.clientId,
      payload.key
    )
  },
  removeSubscriptionKey({ commit }, payload: any) {
    return configProvider.removeAcceptedSubscriptionKey(
      payload.clientId,
      payload.key
    )
  },
  toggleDarkMode({ commit, state }) {
    const newValue = !state.isDarkModeToggleEnabled
    commit('setDarkModeToggle', newValue)
    localStorage.setItem('darkModeToggle', JSON.stringify(newValue))
  },
  async checkKeyAvailability({ commit }, key: string) {
    return await configProvider.checkKeyAvailability(key)
  },
  bindConfig: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('config', configCollection.doc('config'))
  }),
  unbindConfig: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('config')
  }),
  subscribeToGlobalTimeFilter({ commit, state }) {
    if (timeFilterInterval) {
      clearInterval(timeFilterInterval)
    }
    timeFilterInterval = setInterval(() => {
      commit('setGlobalTimeRange', {
        startDateTime: state.globalTimeRange.startDateTime,
        endDateTime: moment()
      })
    }, state.autoRefreshSettings.autoRefreshPeriod)
  },
  unSubscribeFromGlobalTimeFilter({ commit, state }) {
    if (timeFilterInterval) {
      clearInterval(timeFilterInterval)
    }
    commit('setGlobalTimeRange', {
      startDateTime: state.globalTimeRange.startDateTime,
      endDateTime: moment()
    })
  },
  loadBreadcrumb(
    { commit },
    payload: {
      tree: any
      childId: string
      userId: string
      isAdmin: boolean
    }
  ) {
    let filteredTreeNodes = payload.tree

    if (payload.userId && payload.isAdmin) {
      filteredTreeNodes = filteredTreeNodes.filter(
        (item) => item.type === 'customer' && item.id === payload.userId
      )
    }

    const breadcrumb = []
    const nodeArray = findBreadcrumbNodes(
      { children: filteredTreeNodes },
      payload.childId
    )

    nodeArray?.forEach((node: any) => {
      if (node?.name) {
        breadcrumb.push({
          text: node.name,
          disabled: false
        })
      }
    })
    commit('setBreadcrumbList', breadcrumb)
  },
  async checkServiceStatus({ commit }: any) {
    try {
      const response = await axios.get('http://localhost:4400/emulators')
      const status = {
        functions: response.data.functions !== undefined
      }

      commit('setServiceStatus', status)
    } catch (error) {
      commit('setServiceStatus', {
        functions: false
      })
    }
  },
  async getDeadline({ commit }: any) {
    try {
      return (await configCollection.doc('config').get()).data()?.deadline ?? 60
    } catch (error) {
      console.error('Error adding camera keys: ', error)
    }
  },

  async getEENClientId({ commit }: any) {
    try {
      return (
        (await configCollection.doc('eagleEye').get()).data()?.clientId ?? ''
      )
    } catch (error) {
      console.error('Error reading eagle eye client id: ', error)
    }
  },
  async updateBlockToggle({ commit }: any, payload: any) {
    const { block, type, docId, value } = payload
    return configProvider.updateBlockToggleDb(block, type, docId, value)
  },
  async updateNotificationInheritToggle({ commit }: any, payload: any) {
    const { type, docId, value } = payload
    return configProvider.updateNotificationInheritToggleDb(type, docId, value)
  },
  ascendAbsentCustomerWhenLoadedFromURL({ commit, state }: any, params: any) {
    const { customerId, limit, nodesPerPage } = params
    if (customerId && state?.treeCustomers) {
      const customerIndex = state.treeCustomers.findIndex(
        (item) => item.id === customerId
      )
      if (
        limit.customers === nodesPerPage &&
        customerIndex > -1 &&
        customerIndex > limit.customers
      ) {
        swapElements(state.treeCustomers, 0, customerIndex)
        commit('setTreeCustomers', { customers: state.treeCustomers })
      }
    }
  },
  bindAllNodes(
    { commit }: any,
    payload: {
      userId?: string
      isRefresh: boolean
      isAdmin: boolean
    }
  ) {
    if (
      !payload.isRefresh &&
      subscriptions.client &&
      subscriptions.site &&
      subscriptions.camera
    ) {
      if (payload.isAdmin && subscriptions.user) {
        return
      } else {
        return
      }
    }

    if (payload.isRefresh) {
      Object.keys(subscriptions).map((key) => {
        if (subscriptions[key]) {
          subscriptions[key]()
        }
      })
    }
    if (payload.isAdmin === true) {
      const userSubscription = userCollection
        .where('role', '==', 'Customer')
        .where('isApproved', '==', true)
        .orderBy('created', 'desc')
        .onSnapshot((userSnap) => {
          const users = []
          userSnap.docs.forEach((userDoc) => {
            const userData = {
              key: userDoc.id,
              id: userDoc.id,
              name: userDoc.data().display_name ?? '',
              companyName: userDoc.data().companyName ?? '',
              email: userDoc.data().email ?? '',
              type: 'customer',
              userFirestoreId: userDoc.id,
              children: [],
              isNodeArmed: true
            }
            users.push(userData)
          })
          commit('setTreeCustomers', { customers: users })
        })
      subscriptions.user = userSubscription
    }

    let clientQuery: any = clientCollection
    if (payload.userId && payload.userId !== '') {
      clientQuery = clientQuery.where('users', 'array-contains', payload.userId)
    }
    // Client Fetching
    const clientSubscription = clientQuery
      .orderBy('createdAt', 'asc')
      .onSnapshot((clientSnap) => {
        const clients = []
        clientSnap.docs.forEach((clientDoc) => {
          const writeUsers = clientDoc.data().writeUsers ?? []
          const isWriteEnabled =
            payload.isAdmin ?? writeUsers.includes(payload.userId)
          const isDefault = clientDoc.data().type === 'Default'
          const clientData = {
            id: clientDoc.id,
            key: payload.userId + clientDoc.id,
            itemId: clientDoc.data().clientId,
            name: clientDoc.data().name,
            type: 'client',
            nodeType: 'normal',
            clientFirestoreId: clientDoc.id,
            children: [],
            users: clientDoc.data().users ?? [],
            unarmedTimeRange: clientDoc.data().unarmedTimeRange,
            isNodeArmed: getNodeUnarmedStatus(clientDoc.data()),
            isWriteEnabled: isWriteEnabled,
            isDefault: isDefault
          }
          clients.push(clientData)
        })
        commit('setTreeClients', {
          userDocId: payload.userId,
          clients: clients
        })
      })

    subscriptions.client = clientSubscription

    // Site Fetching
    let siteQuery: any = siteCollection
    if (payload.userId && payload.userId !== '') {
      siteQuery = siteQuery.where('users', 'array-contains', payload.userId)
    }

    const siteSubscription = siteQuery
      .orderBy('createdAt', 'asc')
      .onSnapshot((siteSnap: QuerySnapshot) => {
        const sites = []
        siteSnap.docs.forEach((siteDoc) => {
          const writeUsers = siteDoc.data().writeUsers ?? []
          const isWriteEnabled =
            payload.isAdmin ?? writeUsers.includes(payload.userId)

          const siteData = {
            id: siteDoc.id,
            key: payload.userId + siteDoc.id,
            itemId: siteDoc.data().siteId,
            name: siteDoc.data().name,
            type: 'site',
            clientFirestoreId: siteDoc.data()?.client?.id,
            siteFirestoreId: siteDoc.id,
            nodeType: 'normal',
            children: [],
            users: siteDoc.data().users ?? [],
            blockToggles: siteDoc.data().blockToggles,
            unarmedTimeRange: siteDoc.data().unarmedTimeRange,
            isWriteEnabled: isWriteEnabled,
            isHardwareDevice: siteDoc.data().isHardwareDevice
          }
          sites.push(siteData)
        })
        if (sites.length === siteSnap.docs.length) {
          commit('setTreeSites', {
            userDocId: payload.userId,
            sites: sites
          })
        }
      })

    subscriptions.site = siteSubscription

    // Camera Fetching
    let cameraQuery: any = cameraCollection
    if (payload.userId && payload.userId !== '') {
      cameraQuery = cameraQuery.where('users', 'array-contains', payload.userId)
    }

    const cameraSubscription = cameraQuery
      .orderBy('createdAt', 'asc')
      .onSnapshot(async (cameraSnap: any) => {
        let cameras = []
        for (const cameraDoc of cameraSnap.docs) {
          const cameraData = cameraDoc.data()
          const writeUsers = cameraData.writeUsers ?? []
          const isWriteEnabled =
            payload.isAdmin ?? writeUsers.includes(payload.userId)

          const processedCameraData = {
            key: payload.userId + cameraDoc.id,
            id: cameraDoc.id,
            itemId: cameraData.cameraId,
            name: cameraData.name,
            type: 'camera',
            siteFirestoreId: cameraData.site.id,
            cameraFirestoreId: cameraDoc.id,
            nodeType: 'normal',
            users: cameraData.users ?? [],
            blockToggles: cameraData.blockToggles,
            unarmedTimeRange: cameraData.unarmedTimeRange,
            isWriteEnabled: isWriteEnabled,
            isAuthenticated: cameraData.isAuthenticated,
            isEdgeDeviceEnabled: cameraData.isEdgeDeviceEnabled,
            state: cameraDoc.data().state,
            isEdgeCameraUnarmed: cameraData.isEdgeCameraUnarmed
          }

          cameras.push(processedCameraData)
        }
        commit('setTreeCameras', {
          userDocId: payload.userId,
          cameras: cameras
        })
      })
    subscriptions.camera = cameraSubscription
  },
  unbindAllNodes() {
    if (subscriptions) {
      Object.keys(subscriptions).map((key) => {
        if (subscriptions[key]) {
          subscriptions[key]()
        }
      })
    }
  },
  async createTreeNode(
    { commit }: any,
    payload: {
      userId: string
      clientId?: string
      siteId?: string
      cameraId?: string
      clientName?: string
      siteName?: string
      cameraName?: string
      type: 'Client' | 'Camera' | 'Site'
    }
  ): Promise<boolean> {
    const createTreeNode = functions.httpsCallable('createTreeNode')
    return createTreeNode(payload)
      .then((res) => res.data)
      .catch((err) => ({ status: 'error', message: err.message }))
  },
  async deleteTreeNode(
    { commit }: any,
    payload: {
      clientId?: string
      siteId?: string
      cameraId?: string
      type: 'Client' | 'Camera' | 'Site'
    }
  ): Promise<boolean> {
    const deleteTreeNode = functions.httpsCallable('deleteTreeNode')
    return deleteTreeNode(payload)
      .then((res) => res.data)
      .catch((err) => ({ status: 'error', message: err.message }))
  },
  async getVersionNumberFromFirestore({ commit }) {
    try {
      const versionDoc = await configCollection.doc('webApp').get()

      const versionNumber = versionDoc.data()?.version ?? ''

      commit('setVersionInfo', versionNumber)

      return versionNumber
    } catch (error) {
      console.error('Error retrieving version number: ', error)
      commit('setVersionInfo', null)
      return ''
    }
  },
  async getConfigFromFirestore({ commit }) {
    try {
      const versionDoc = await configCollection.doc('config').get()

      const config = versionDoc.data()

      commit('setConfig', config)
    } catch (error) {
      console.error('Error retrieving version number: ', error)
      commit('setConfig', {})
    }
  },
  async getPromiseQubeVersion({ commit }) {
    try {
      const versionDoc = await configCollection.doc('promiseQube').get()

      const versionNumber = versionDoc.data()?.version ?? ''

      commit('setPromiseQubeVersion', versionNumber)

      return versionNumber
    } catch (error) {
      console.error('Error retrieving version number: ', error)
      commit('setPromiseQubeVersion', null)
      return ''
    }
  },
  async getP2PPlayer({ commit }): Promise<P2pPlayerConfig> {
    const defaultPlayerConfig: P2pPlayerConfig = {
      player: P2pPlayer.MTX,
      playerType: PlayerType.WEBRTC
    }

    try {
      const playerDocument = await configCollection.doc('config').get()
      const player: P2pPlayerConfig =
        playerDocument.data()?.p2pPlayer ?? defaultPlayerConfig

      commit('setP2PPlayer', player)
      return player
    } catch (error) {
      console.error('Error retrieving version number: ', error)
      commit('setP2PPlayer', defaultPlayerConfig)
      return defaultPlayerConfig
    }
  }
}

export default {
  ...actions
}
