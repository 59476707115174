var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","height":"100%","padding-left":"24px"}},[_c('div',{staticStyle:{"flex":"1","display":"flex","flex-direction":"column","align-items":"flex-start","justify-content":"center","height":"80vh"}},[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor,
          fontWeight: 'bold',
          fontSize: '20px'
        })},[_vm._v(" Sorry! You are not authorized. ")]),_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor,
          textAlign: 'left'
        })},[_vm._v(" We can't verify that you are authorized to access this page. Please check your credentials (e.g. password, username) or contact the administrator. ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('button',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? 'refresh-dark-mode'
              : 'refresh-light-mode',on:{"click":_vm.refresh}},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"size":"22"}},[_vm._v("mdi-refresh ")]),_vm._v(" Refresh ")],1),_c('button',{class:'homepage-light-mode',staticStyle:{"margin-left":"20px"},on:{"click":_vm.goHome}},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.darkPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"size":"22"}},[_vm._v("mdi-web ")]),_vm._v(" Homepage ")],1)])]),_c('div',{staticStyle:{"width":"60%","min-width":"420px","height":"auto","margin-top":"16px"}},[(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled)?_c('img',{staticStyle:{"max-width":"100%","height":"auto"},attrs:{"src":require("../../../public/img/401_dark.svg"),"alt":"401 SVG"}}):_c('img',{staticStyle:{"max-width":"100%","height":"auto"},attrs:{"src":require("../../../public/img/401_light.svg"),"alt":"401 SVG"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }