export const getDefaultState = () => {
  return {
    isNotificationEnabled: false,
    isWorkerSpaceHealthCheckNotificationEnabled: false
  }
}

const state = getDefaultState()
export default {
  ...state
}
