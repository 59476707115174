<template>
  <v-container
    fluid
    v-if="facet.data.length"
    class="d-flex flex-column py-0 pr-2 pl-0 mb-2"
  >
    <p
      class="ml-4 my-0 text-xs text-left font-weight-thin"
      style="color: #b8b8b8; font-size: 0.875rem"
    >
      {{ facetName }}
    </p>
    <v-row no-gutters>
      <v-col class="d-flex flex-column justify-center">
        <label
          v-for="facetItem in facet.data"
          :key="getValue(facetItem, facet.type)"
        >
          <div
            class="d-flex justify-start align-center input--border py-3 px-3"
          >
            <input
              type="checkbox"
              :value="getValue(facetItem, facet.type)"
              :checked="isChecked(getValue(facetItem, facet.type))"
              @change="$emit('change', { event: $event, facet: facet.field })"
              id="checkbox"
            />
            <span
              class="px-3"
              style="font-size: 1.15rem"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
              >{{ getDisplayValue(facetItem, facet.type) }}</span
            >
          </div>
        </label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

type FacetObject = {
  type: string
  field: string
  data: any[]
}

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({ components: {} })
export default class SearchFacetNew extends Vue {
  @Action('bindAllUser', namespaceUser) public bindAllUser
  @Getter('allUser', namespaceUser) public users: any
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Prop() public facetName: string
  @Prop() public facet: FacetObject
  @Prop() public checked: any[]

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public isChecked(value) {
    return this.checked && this.checked.includes(value)
  }

  public getValue(facetItem, type) {
    return type === 'range' ? facetItem.value.name : facetItem.value
  }

  public getDisplayValue(facetItem, type) {
    if (this.facetName == 'User') {
      const correspondingUser = this.users.find(
        (user) => user.id == facetItem.value
      )
      if (correspondingUser) {
        return correspondingUser.email
      }
    } else if (this.facetName == 'Reviewed By') {
      if (facetItem.value == 'true') {
        return 'AI'
      } else return 'AI + Human'
    } else if (this.facetName == 'Final Result') {
      if (facetItem.value == 'alarm') {
        return 'true alarm'
      } else if (facetItem.value == 'no-alarm') {
        return 'false alarm'
      } else return 'unidentified alarm'
    }
    return type === 'range' ? facetItem.value.name : facetItem.value
  }

  private mounted() {
    if (this.facetName == 'User') {
      this.bindAllUser()
    }
  }
}
</script>

<style scoped>
.input--border {
  border: 1px solid #e1dede !important;
  border-radius: 8px;
}

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  -webkit-border-radius: 2px;
  border-radius: 3px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}
</style>
