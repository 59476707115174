<template>
  <div>
    <div
      v-if="isUserAdmin"
      class="card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="human-review-switch-title"
      >
        Human Review
      </div>
      <v-switch
        id="human-review-switch"
        v-model="isAskCrowdWorkers"
        text
        inset
        class="notification-switch"
        color="secondary"
        @change="onIsAskCrowdWorkersChange"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        data-test-id="human-review-switch"
      ></v-switch>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({})
export default class HumanReviewConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() isAlarmFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public isAskCrowdWorkers: boolean = false

  public originalHumanReviewData: any = null

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isToggleDisabled() {
    return this.isAlarmFieldEnabled === null
      ? this.configuration?.blockToggles?.alarmSettings !== true
      : this.isAlarmFieldEnabled !== true && this.level !== 'client'
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('isAlarmFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.alarmSettings
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      this.isAskCrowdWorkers = showingConfig?.isAskCrowdWorkers || false

      this.originalHumanReviewData = {
        isAskCrowdWorkers: this.isAskCrowdWorkers
      }
    }
  }

  public hasSwitchChanged(): boolean {
    return (
      this.isAskCrowdWorkers !== this.originalHumanReviewData.isAskCrowdWorkers
    )
  }

  public onIsAskCrowdWorkersChange(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border-top: solid #dadada 1px;
  background-color: transparent;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
</style>
