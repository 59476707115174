<template>
  <v-row
    class="card-style block-header d-flex justify-between align-center pb-0 pt-0"
    :class="
      isUserAdmin && isDarkModeToggleEnabled
        ? 'block-header-admin'
        : 'block-header'
    "
  >
    <v-col cols="6" :class="titleOnly ? 'py-5' : ''">
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlackColor
        }"
        data-test-id="block-header-title"
        style="text-align: left; margin: 0px"
      >
        {{ title }}
        <span
          class="text-subtitle-1-disabled font-weight-bold"
          :style="{ color: '#c4c4c4' }"
        >
          {{ isPromiseQubeCameraInfo ? edgeDeviceStatus : '' }}</span
        >
      </p>
    </v-col>

    <v-col cols="6" v-if="!titleOnly">
      <v-row class="d-flex justify-end align-center">
        <v-tooltip
          v-if="!isPromiseQubeCameraInfo"
          left
          :open-on-click="false"
          :open-on-focus="false"
          nudge-left="6"
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              data-test-id="block-header-tooltip"
              v-on="on"
              v-bind="attrs"
              size="15"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              >mdi-information-variant-circle
            </v-icon>
          </template>

          <span
            class="mx-2 white--text"
            data-test-id="block-header-tooltip-text"
            >ON: activate these configs<br />
            OFF: activate upper level configs</span
          >
        </v-tooltip>
        <v-switch
          :disabled="!isWriteEnabled || isToggleDisabled"
          v-model="isBlockToggleEnabled"
          color="secondary"
          @change="onSettingsChange"
          inset
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          :small="true"
          class="pl-2"
          data-test-id="block-header-switch"
        ></v-switch>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {}
})
export default class BlockHeader extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Prop({ required: true }) title: string
  @Prop({ default: null }) configuration!: any
  @Prop({ default: '' }) type!: string
  @Prop({ default: false }) titleOnly!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() isLimitExceeded!: boolean

  public isBlockToggleEnabled: boolean = false
  public edgeDeviceStatus: string = ''
  public isToggleDisabled: boolean = false

  public mounted() {
    this.watchConfiguration()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isPromiseQubeCameraInfo() {
    return this.title === 'promiseQube Camera Info'
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration && !this.titleOnly) {
      let currentConfig: boolean = false
      if (this.title === 'Alarm Settings') {
        currentConfig = this.configuration?.blockToggles?.alarmSettings
      } else if (this.title === 'Disarmed Time Range') {
        currentConfig = this.configuration?.blockToggles?.unarmedTimeRange
      } else if (this.title === 'Health Check') {
        currentConfig = this.configuration?.blockToggles?.healthCheck
      } else if (this.title === 'Notifications') {
        currentConfig = this.configuration?.blockToggles?.notifications
      } else if (this.title === 'Contact') {
        currentConfig = this.configuration?.blockToggles?.contact
      } else if (this.isPromiseQubeCameraInfo) {
        this.isToggleDisabled = !this.configuration?.isAuthenticated
        this.edgeDeviceStatus = this.configuration?.isEdgeDeviceEnabled
          ? 'on'
          : 'off'

        currentConfig =
          this.configuration?.blockToggles?.edge ??
          this.configuration?.isEdgeDeviceEnabled
      } else if (this.title === 'Recordings') {
        currentConfig = this.configuration?.blockToggles?.recordings
      }

      this.isBlockToggleEnabled = currentConfig
    }
  }

  @Watch('isLimitExceeded')
  public watchLimitExceeded() {
    if (this.isLimitExceeded) {
      this.isBlockToggleEnabled = this.configuration?.isEdgeDeviceEnabled
    }
  }

  public hasSwitchChanged(): boolean {
    // check each of the header object value with the object originalHeaderSettings with conditional statements
    if (this.title === 'Alarm Settings') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.alarmSettings || false)
      )
    }
    if (this.title === 'Disarmed Time Range') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.unarmedTimeRange || false)
      )
    }
    if (this.title === 'Health Check') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.healthCheck || false)
      )
    }
    if (this.title === 'Notifications') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.notifications || false)
      )
    }
    if (this.title === 'Contact') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.contact || false)
      )
    }
    if (this.isPromiseQubeCameraInfo) {
      const isEdgeEnabled =
        ((this.configuration?.blockToggles?.edge ?? true) &&
          this.configuration.isEdgeDeviceEnabled) ??
        false

      return this.isBlockToggleEnabled !== isEdgeEnabled
    }
    if (this.title === 'Recordings') {
      return (
        this.isBlockToggleEnabled !==
        (this.configuration?.blockToggles?.recordings || false)
      )
    }
    return (
      this.isBlockToggleEnabled !==
      this.configuration?.blockToggles?.alarmSettings
    )
  }

  public onSettingsChange(value: boolean) {
    this.onSwitchChange(value, this.hasSwitchChanged())
  }
}
</script>

<style scoped>
.block-header {
  background-color: #e5e5e5 !important;
}
.block-header-admin {
  background-color: #938d76 !important;
}
</style>
