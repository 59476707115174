import {
  cameraCollection,
  clientCollection,
  siteCollection
} from '@/provider/firebase'
import firebase from 'firebase/compat/app'

/* eslint-disable */
interface AccessedCameraRefsResult {
  cameraRefs: string[]
  siteRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  clientRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
}

export default {
  namespaced: true,
  async applyHealthCheckFilters(
    baseQuery: firebase.firestore.Query,
    filters: string[]
  ) {
    let finalQuery: firebase.firestore.Query

    if (filters.length === 0) {
      finalQuery = baseQuery
    } else {
      finalQuery = baseQuery.where('deviation', 'array-contains-any', filters)
    }

    return finalQuery
  },
  async getAccessedCameraRefs(
    userId: string,
    nodeType: string,
    nodeId: string,
    clientId?: string,
    siteId?: string
  ): Promise<AccessedCameraRefsResult> {
    let cameraRefs: string[] = []
    let siteRef: firebase.firestore.DocumentReference = null
    let clientRef: firebase.firestore.DocumentReference = null

    let query: firebase.firestore.Query

    switch (nodeType) {
      case null:
        query = cameraCollection.where('users', 'array-contains', userId)
        break
      case 'clientId':
        clientRef = (
          await clientCollection.where('clientId', '==', nodeId).get()
        ).docs[0].ref
        const sites = await siteCollection
          .where('client', '==', clientRef)
          .get()
        for (const siteDoc of sites.docs) {
          query = cameraCollection
            .where('site', '==', siteDoc.ref)
            .where('users', 'array-contains', userId)
          const cameras = await query.get()
          cameras.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
        }
        return { cameraRefs, siteRef, clientRef }
      case 'siteId':
        const siteSnapshot = await siteCollection
          .where('siteId', '==', nodeId)
          .where('users', 'array-contains', userId)
          .get()
        if (!siteSnapshot.empty) {
          for (const siteDoc of siteSnapshot.docs) {
            const siteRef = siteDoc.ref
            query = cameraCollection
              .where('site', '==', siteRef)
              .where('users', 'array-contains', userId)
            const siteCameras = await query.get()
            siteCameras.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
          }
          return { cameraRefs, siteRef, clientRef }
        } else {
          console.warn(`No site found with siteId: ${nodeId}`)
        }
        break
      case 'cameraId':
        query = cameraCollection
          .where('cameraId', '==', nodeId)
          .where('users', 'array-contains', userId)
        break
      default:
        throw new Error(`Unsupported nodeType: ${nodeType}`)
    }

    if (query) {
      const result = await query.get()
      result.docs.forEach((doc) => cameraRefs.push(doc.ref.path))
    }

    return { cameraRefs, siteRef, clientRef }
  },
  async getNodeNames(
    nodeLevel: 'Global' | 'Collaborator' | 'Client' | 'Site' | 'Camera',
    cameraRef: firebase.firestore.DocumentReference,
    siteRef: firebase.firestore.DocumentReference,
    clientRef: firebase.firestore.DocumentReference
  ): Promise<string> {
    const names = []

    try {
      if (
        ['Collaborator', 'Client', 'Site', 'Camera', 'Global'].includes(
          nodeLevel
        )
      ) {
        if (
          ['Global', 'Collaborator', 'Client', 'Site'].includes(nodeLevel) &&
          cameraRef
        ) {
          const cameraQ = await cameraRef.get()
          names.push(cameraQ.data()?.name || 'Unknown Camera')
        }
        if (
          ['Global', 'Collaborator', 'Client'].includes(nodeLevel) &&
          siteRef
        ) {
          const siteQ = await siteRef.get()
          names.unshift(siteQ.data()?.name || 'Unknown Site')
        }
        let clientQ: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
        if (['Global', 'Collaborator'].includes(nodeLevel) && clientRef) {
          clientQ = await clientRef.get()
          names.unshift(clientQ.data()?.name || 'Unknown Client')
        }
        if (nodeLevel === 'Global' && clientRef) {
          const userRef = clientQ.data()?.belongsTo
          const userQ = await userRef.get()
          names.unshift(userQ.data()?.display_name || 'Unknown User')
        }
      }
    } catch (error) {
      console.error('Error fetching node names:', error)
      return 'Error fetching names'
    }

    return names.join(' > ')
  },
  async getCameraId(
    cameraRef: firebase.firestore.DocumentReference
  ): Promise<string> {
    try {
      const cameraQ = await cameraRef.get()
      return cameraQ.data()?.cameraId || 'Unknown Camera'
    } catch (error) {
      console.error('Error fetching camera Id:', error)
      return 'Error camera Id'
    }
  },
  async getCameraData(cameraRef: firebase.firestore.DocumentReference) {
    try {
      const cameraQ = await cameraRef.get()
      const siteQ = await cameraQ.data().site.get()
      return {
        docId: cameraQ.id,
        data: cameraQ.data(),
        isSiteHardwareDevice: siteQ.data().isHardwareDevice ?? false
      }
    } catch (error) {
      console.error('Error fetching camera data:', error)
      return 'Error camera data'
    }
  }
}
