var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.versionChanged && _vm.$root.env !== 'development')?_c('v-card',{staticClass:"refresh-container",attrs:{"elevation":"0"}},[_c('div',{staticClass:"notification-body"},[_c('div',{staticClass:"notification-button"},[_c('h4',{staticClass:"text-center"},[_vm._v("An Update is available")]),_c('p',{staticClass:"text-center"},[_vm._v(" Please, save all current work and click on “Update” button below. You can also accept these updates by refreshing your browser at any time. ")]),_c('p',{staticClass:"text-center"},[_vm._v(" Avoiding updates may become the reason for errors. ")])]),_c('div',{staticClass:"refresh-button text-center"},[_c('v-btn',{style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlackColor,
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkBlackColor
              : _vm.getColors.lightPrimaryColor,
          textTransform: 'capitalize'
        }),attrs:{"text":"","outlined":""},on:{"click":_vm.reloadApp}},[_vm._v(" Update ")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }