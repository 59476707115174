<template>
  <v-sheet
    :elevation="`${dragover && 5}`"
    tabindex="0"
    title="Click to grab a file from your device"
    style="cursor: pointer"
    min-height="250"
    min-width="280"
    :class="`dropzone pa-2 m-a ${dragover && 'ondrop'}`"
  >
    <input
      id="fileUpload"
      type="file"
      :accept="acceptedAcceptString"
      :multiple="multiple"
      style="display: none"
    />
    <v-row class="mt-4">
      <span
        class="ps-2 pe-2 text-center"
        style="font-size: 24px; min-width: 10px"
        v-if="!dragover"
        >Drag and drop or click to upload file!</span
      >
      <span class="text-h6" v-if="dragover">Drop here!</span>
    </v-row>
    <v-row class="mb-3">
      <v-icon v-if="!dragover" size="75" style="color: #ffd42a"
        >mdi-tray-arrow-down</v-icon
      >
      <v-icon v-if="dragover" size="75" style="color: #ffd42a"
        >mdi-book-plus</v-icon
      >
    </v-row>
    <v-row v-if="!dragover" style="color: #8c8c8c">
      <span class="ps-1 pe-1 pb-5 text-center">{{ acceptedList }} </span>
    </v-row>
    <!-- Snackbar message -->
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          class="secondary black--text text-subtitle-2 no-uppercase"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-sheet>
</template>
<script lang="ts">
import EventBus from '@/utils/EventBus'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class DragAndDrop extends Vue {
  // === PUBLIC PROPERTIES === //
  @Prop({ default: false }) public multiple: boolean
  public dragover: boolean = false
  public acceptedTypes: string[] = [
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/mpg',
    'video/dav',
    'video/mpeg'
  ]

  public acceptedFileExtensions: string[] = ['.mkv']
  public acceptedList: string =
    'Images (.jpg, .png) or Video (.mp4, .mpg, .mkv)'
  public snackbar: boolean = false
  public snackbarMessage: string = ''

  get acceptedAcceptString(): string {
    return [...this.acceptedTypes, ...this.acceptedFileExtensions].join(',')
  }
  mounted() {
    // to register listeners, we have to know the
    // html elements
    const dropzone = this.$el
    const fileupload = this.$el.firstElementChild as HTMLElement

    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners

      dropzone.addEventListener('dragenter', (e) => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('dragleave', (e) => {
        e.preventDefault()
        this.dragover = false
      })
      dropzone.addEventListener('dragover', (e) => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('drop', (e) => {
        e.preventDefault()
        const dragevent = e as DragEvent
        if (!this.multiple && dragevent.dataTransfer?.files?.length > 1) {
          this.dragover = false
          this.popSnackbarMessage('Multiple files are not allowed')
        } else {
          this.fileSelected(dragevent.dataTransfer.files[0])
        }
      })

      //   register event listener for keyboard usage
      dropzone.addEventListener('click', (e) => {
        if (fileupload) {
          fileupload.click()
        }
      })

      dropzone.addEventListener('keypress', (e) => {
        e.preventDefault()
        const keyEvent = e as KeyboardEvent
        if (keyEvent.key === 'Enter') {
          if (fileupload) fileupload.click()
        }
      })

      // register listeners on the file input
      if (fileupload) {
        fileupload.addEventListener('change', (e) => {
          const target = e.target as HTMLInputElement
          if (target.files) {
            this.fileSelected(target.files[0])
          }
        })
      }
    }
  }

  // === EMITS === //
  fileSelected(selectedFile: File) {
    this.dragover = false

    // Extract the file extension
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase()

    const isAcceptedMimeType = this.acceptedTypes.includes(selectedFile.type)

    // Check if the file's extension is accepted
    const isAcceptedExtension = this.acceptedFileExtensions.includes(
      `.${fileExtension}`
    )

    if (isAcceptedMimeType) {
      EventBus.$emit('fileSelected', selectedFile)
    } else if (isAcceptedExtension) {
      EventBus.$emit('fileSelected', selectedFile)
    } else {
      alert(`Unsupported file type! (${selectedFile.type || fileExtension})`)
    }
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.dropzone {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.4s all ease-in-out;
}

.dropzone:hover {
  background-color: rgba(190, 190, 190, 0.3);
}

.ondrop {
  background-color: rgba(190, 190, 190, 0.3);
}
</style>
