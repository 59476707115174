const getters = {
  getNumberOfTodosWorkerDid: (state) => {
    return state.numberOfTodosWorkerDid
  },
  getRegisteredWorkers: (state) => {
    return state.registeredWorkers
  },
  getOnlineWorkers: (state) => {
    return state.onlineWorkers
  },
  getCurrentOnlineWorkers: (state) => {
    return state.currentOnlineWorkers
  },
  getAllWorkerStatus: (state) => {
    return state.allWorkerStatus
  },
  getDownloadSpeed: (state) => {
    return state.downlink
  },
  getUploadSpeed: (state) => {
    return state.uplink
  },
  getWorkerQueue: (state) => {
    return state.workerQueue
  },
  getWorkerStatus: (state) => {
    return state.workerStatus
  }
}

export default {
  ...getters
}
