<template>
  <div>
    <span
      v-if="isOnline && !message && !showCountdown"
      style="color: #ffffff; font-weight: bold"
    >
      <v-icon color="white">mdi-download</v-icon>
      {{ enableSpeedTest ? downloadSpeedValue : '--' }} Mbps
      <v-icon color="white">mdi-upload</v-icon>
      {{ enableSpeedTest ? uploadSpeedValue : '--' }} Mbps
    </span>
    <span v-if="message" style="color: #ffffff">
      {{ message }}
    </span>
    <span v-if="showCountdown && isOnline" style="color: #ffffff !important">
      {{ countdownMessage }}
    </span>
    <!-- Commented out the Run now and Later buttons
    <span v-if="showControls && isOnline" class="controllers">
      <v-btn color="primary" @click="runNow" small>Run now</v-btn>
      <v-btn
        color="secondary"
        @click="postpone"
        small
        style="color: black"
        :disabled="postponeCount >= 4"
        >Later ({{ postponeCount }}/4)</v-btn
      >
    </span>
    -->
    <span v-if="!isOnline" style="color: #ffffff">
      No internet connection
    </span>
    <span v-if="isOnline" class="toggle-switch">
      <v-switch
        title="Enable/disable speed test"
        v-model="enableSpeedTest"
        color="secondary"
        inset
        @change="toggleSpeedTest"
      ></v-switch>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class RoutineTest extends Vue {
  @Prop({ default: 0 }) downloadSpeed!: number
  @Prop({ default: 0 }) uploadSpeed!: number
  @Prop({ default: 'completed' }) testStatus!: 'completed' | 'pending'

  private ROUTINE = 20 * 60 * 1000 // 20 minutes

  public isOnline: boolean = false
  public routineTestTimer: any = null
  public enableSpeedTest: boolean = false

  public showCountdown = false
  // Commented out the showControls property
  // public showControls = false
  public countdownMessage = ''
  public message = ''
  public countdown = 5
  public postponeCount = 0
  public countdownTimer: any = null

  get isCountdownActive(): boolean {
    // Updated the condition to not include showControls
    return this.showCountdown && this.isOnline
  }

  get downloadSpeedValue(): number {
    return this.downloadSpeed || 0
  }

  get uploadSpeedValue(): number {
    return this.uploadSpeed || 0
  }

  mounted() {
    this.isOnline = navigator.onLine
    window.addEventListener('offline', this.handleOffline)
    window.addEventListener('online', this.handleOnline)
    if (this.enableSpeedTest) {
      this.emitEvent()
    }
  }

  beforeDestroy() {
    clearTimeout(this.routineTestTimer)
    clearInterval(this.countdownTimer)
    window.removeEventListener('offline', this.handleOffline)
    window.removeEventListener('online', this.handleOnline)
  }

  handleOffline() {
    this.isOnline = false
    clearTimeout(this.routineTestTimer)
    clearInterval(this.countdownTimer)
  }

  handleOnline() {
    this.isOnline = true
    if (this.enableSpeedTest) {
      this.scheduleRoutineTest()
    }
  }

  private scheduleRoutineTest() {
    clearTimeout(this.routineTestTimer)
    clearInterval(this.countdownTimer)
    if (!this.enableSpeedTest) return
    this.routineTestTimer = setTimeout(() => {
      this.startCountdown(60) // Start the countdown with 60 seconds
    }, this.ROUTINE - 60 * 1000) // Schedule the countdown 1 minute before the test
  }

  toggleSpeedTest() {
    if (this.enableSpeedTest) {
      this.emitEvent()
    } else {
      clearTimeout(this.routineTestTimer)
      clearInterval(this.countdownTimer)
      this.showCountdown = false
      this.message = ''
      this.$emit('disable-speed-test')
    }
  }

  startCountdown(seconds: number) {
    this.message = ''
    this.showCountdown = true
    this.countdown = seconds
    this.updateCountdownMessage()
    this.countdownTimer = setInterval(() => {
      this.countdown -= 1
      this.updateCountdownMessage()
      if (this.countdown <= 0) {
        this.emitEvent()
        this.showCountdown = false
        this.message = ''
      }
    }, 1000)
  }

  updateCountdownMessage() {
    this.message = `Test starting in ${this.countdown} second${
      this.countdown > 1 ? 's' : ''
    }`
  }

  emitEvent() {
    clearInterval(this.countdownTimer)
    this.showCountdown = false
    this.$emit('run-test')
    this.scheduleRoutineTest()
  }

  @Watch('testStatus')
  onSpeedTestStatusChange() {
    if (this.testStatus === 'completed') {
      clearInterval(this.countdownTimer)
      this.showCountdown = false
      this.scheduleRoutineTest()

      setTimeout(() => {
        this.showCountdown = false
        this.saveData(this.uploadSpeed, this.downloadSpeed)
        if (this.enableSpeedTest) {
          // Show message
          this.message = `Last result: ${this.downloadSpeed} Mbps download, ${
            this.uploadSpeed
          } Mbps upload at ${new Date().toLocaleString()}`
        }
        this.scheduleRoutineTest()
      }, 5000)
    }
  }

  private loadSpeedData() {
    const data = localStorage.getItem('speedTestData')
    return data ? JSON.parse(data) : []
  }

  private saveData(uploadSpeed: number, downloadSpeed: number): void {
    let connection: any
    let type: string
    try {
      connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection
      type = connection ? connection.effectiveType : 'unknown'
    } catch (e) {
      type = 'unknown'
    }
    const timestamp = new Date()
    const newData = { uploadSpeed, downloadSpeed, type, timestamp }
    const data = this.loadSpeedData()
    data.push(newData)
    localStorage.setItem('speedTestData', JSON.stringify(data))
  }
}
</script>

<style scoped>
.controllers > * {
  margin-left: 10px;
}
.toggle-switch {
  margin-left: 15px;
  display: inline-block;
}
</style>
