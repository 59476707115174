<template>
  <!-- Webhook Notification Configuration Component -->
  <div>
    <!-- Webhook Header Section -->
    <div
      :class="[
        { active: isExpanded },
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
      @click="processExpansion()"
      data-test-id="webhook-expand-bar"
    >
      <!-- Webhook Title -->
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
        data-test-id="webhook-expand-title"
      >
        Webhook <span v-if="notificationType === 'HC'">(HC)</span>
      </div>
      <!-- Chevron Icon for Expansion -->
      <v-icon
        size="30"
        class="chevron-padding"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
      >
    </div>

    <!-- Webhook Configuration Section -->

    <div v-if="isExpanded" class="form pr-3 py-4 pl-3">
      <!-- Name Input Field -->
      <v-text-field
        v-model="wtName"
        label="Name"
        outlined
        color="secondary"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :error-messages="wtNameError"
        :error="wtNameError.length > 0"
        data-test-id="webhook-config-name-input"
      ></v-text-field>
      <!-- URL Input Field -->
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="wtUrl"
            label="URL"
            outlined
            color="secondary"
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            :disabled="isToggleDisabled || !isWriteEnabled"
            :error-messages="wtUrlError"
            :error="wtUrlError.length > 0"
            data-test-id="webhook-config-url-input"
          ></v-text-field>
        </v-col>

        <!-- HTTP Method Dropdown -->
        <v-col cols="3">
          <v-select
            v-model="selectedWtMethod"
            :items="methodList"
            outlined
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            :disabled="isToggleDisabled || !isWriteEnabled"
            data-test-id="webhook-config-method-select"
          >
            <!-- Dropdown Styling -->
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <!-- Dropdown Item Styling -->
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- Webhook Configuration Tabs -->
      <v-tabs
        v-model="wtTabs"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        background-color="transparent"
      >
        <v-tab data-test-id="webhook-config-url-params-tab"
          >URL PARAMETERS</v-tab
        >
        <v-tab data-test-id="webhook-config-headers-tab">HEADERS</v-tab>
        <v-tab
          v-if="!(selectedWtMethod === 'GET' || selectedWtMethod === 'DELETE')"
          data-test-id="webhook-config-content-tab"
          >Content</v-tab
        >
      </v-tabs>
      <!-- Tab Content -->

      <v-tabs-items
        v-model="wtTabs"
        class="mt-5"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
        id="custom-tab-items"
        :style="{
          backgroundColor: isToggleDisabled
            ? 'transparent'
            : isUserAdmin && isDarkModeToggleEnabled
            ? getColors.darkPrimaryColor
            : 'transparent'
        }"
        data-test-id="webhook-config-tabs-items"
      >
        <!-- URL Parameters Tab -->
        <v-tab-item>
          <WebhookParams
            :currParams="wtUrlParams"
            :setParams="setWebhookParams"
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            type="urlParams"
            :isToggleDisabled="isToggleDisabled"
            :isWriteEnabled="isWriteEnabled"
          />
        </v-tab-item>

        <!-- Headers Tab -->
        <v-tab-item>
          <WebhookParams
            :currParams="wtHeaderParams"
            :setParams="setWebhookParams"
            type="headerParams"
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            :isToggleDisabled="isToggleDisabled"
            :isWriteEnabled="isWriteEnabled"
          />
        </v-tab-item>

        <!-- Content Tab (if not GET or DELETE method) -->
        <v-tab-item
          v-if="!(selectedWtMethod === 'GET' || selectedWtMethod === 'DELETE')"
        >
          <v-textarea
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            v-model="wtContent"
            outlined
            name="input-7-4"
            label="JSON Content"
            :disabled="!isWriteEnabled || isToggleDisabled"
            :error-messages="wtContentError"
            :error="wtContentError.length > 0"
            data-test-id="webhook-config-content-input"
          ></v-textarea>
        </v-tab-item>
      </v-tabs-items>

      <!-- Webhook Notifications Toggle -->
      <div class="font-weight-bold text-left notification-title pl-1">
        <div
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack
          }"
          data-test-id="webhook-config-notification-title"
        >
          Turn on/off webhook notifications
        </div>
        <v-switch
          id="alarm-webhook-notification-switch"
          v-model="isWebhookTriggerEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          :disabled="isToggleDisabled || !isWriteEnabled"
          :dark="isUserAdmin && isDarkModeToggleEnabled"
          @change="onClickIsWebgookTriggerEnabled"
          data-test-id="alarm-webhook-notification-switch"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Importing necessary libraries and components
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import WebhookParams from '@/components/events/WebhookParams.vue'
import { mapToArray, arrayToMap, isValidUrl, isValidJson } from '@/utils/Utils'

// Setting up Vuex namespace
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

// Vue Component Declaration
@Component({
  components: {
    Button,
    WebhookParams
  }
})
export default class WebhookNotificationConfiguration extends Vue {
  // Props
  @Prop() configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (data: any) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: 'Alarm' }) notificationType!: string
  @Prop() isNotificationFieldEnabled!: boolean

  // Vuex Getters
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  // Initial Data
  public methodList: Array<string> = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

  // Webhook Configuration Data

  public isExpanded: boolean = false
  public isWebhookTriggerEnabled: boolean = false
  public wtName: string = ''
  public wtUrl: string = ''
  public wtContent: string = ''
  public selectedWtMethod: string = 'GET'
  public wtTabs = null
  public wtUrlParams: { key: string; value: string | any }[] = []
  public wtHeaderParams: { key: string; value: string | any }[] = []

  // Validation Error Messages
  public wtNameError: string = ''
  public wtUrlError: string = ''
  public wtContentError: string = ''

  public originalWebhookData: any = null

  // Lifecycle Hook: Mounted
  public mounted() {
    this.watchConfiguration()
  }
  // Computed Property: Check if the user is an administrator
  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  // Computed Property: Check if toggle is disabled
  public get isToggleDisabled() {
    return this.isNotificationFieldEnabled === null
      ? this.configuration?.blockToggles?.notifications !== true
      : this.isNotificationFieldEnabled !== true && this.level !== 'client'
  }

  // Method: Handle expansion/collapse of the Webhook configuration
  public processExpansion() {
    this.isExpanded = !this.isExpanded
  }

  get healthCheckNotification() {
    return this.configuration?.notificationData?.healthCheck
  }

  // Watcher: Watch for changes in the 'configuration' prop
  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('notificationType')
  @Watch('healthCheckNotification')
  @Watch('isNotificationFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    // Check if toggle is not disabled
    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      // Check the level and set the showingConfig accordingly
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.notifications
          ? this.siteConfig
          : this.clientConfig
      }
    }

    // If showingConfig exists, update the component data
    if (showingConfig) {
      let webhookTriggerData = {}
      if (this.notificationType === 'HC') {
        webhookTriggerData =
          showingConfig?.notificationData?.healthCheck?.webhookTriggerData
        this.isWebhookTriggerEnabled =
          showingConfig?.notificationData?.healthCheck?.isWebhookTriggerEnabled
      } else {
        webhookTriggerData = showingConfig.webhookTriggerData
        this.isWebhookTriggerEnabled = showingConfig.isWebhookTriggerEnabled
      }

      this.isExpanded = this.isWebhookTriggerEnabled
      this.wtName = webhookTriggerData ? webhookTriggerData['name'] : ''
      this.wtUrl = webhookTriggerData ? webhookTriggerData['url'] : ''
      this.selectedWtMethod = webhookTriggerData
        ? webhookTriggerData['method']
        : 'GET'
      this.wtContent = webhookTriggerData ? webhookTriggerData['content'] : ''
      this.wtUrlParams = webhookTriggerData
        ? mapToArray(webhookTriggerData['urlParams'])
        : []
      this.wtHeaderParams = webhookTriggerData
        ? mapToArray(webhookTriggerData['headerParams'])
        : []

      // Save the original data for future comparison
      this.originalWebhookData = {
        isWebhookTriggerEnabled: this.isWebhookTriggerEnabled,
        wtName: this.wtName,
        wtUrl: this.wtUrl,
        selectedWtMethod: this.selectedWtMethod,
        wtContent: this.wtContent,
        wtUrlParams: [...this.wtUrlParams],
        wtHeaderParams: [...this.wtHeaderParams]
      }
    }
  }

  // Method: Check if the switch status has changed

  public hasSwitchChanged(): boolean {
    return (
      this.isWebhookTriggerEnabled !==
      this.originalWebhookData.isWebhookTriggerEnabled
    )
  }

  // Method: Check if any data has changed
  public hasDataChanged(): boolean {
    return (
      this.wtName?.trim() !== this.originalWebhookData.wtName ||
      this.wtUrl !== this.originalWebhookData.wtUrl ||
      this.selectedWtMethod !== this.originalWebhookData.selectedWtMethod ||
      this.wtContent !== this.originalWebhookData.wtContent ||
      JSON.stringify(this.wtUrlParams) !==
        JSON.stringify(this.originalWebhookData.wtUrlParams) ||
      JSON.stringify(this.wtHeaderParams) !==
        JSON.stringify(this.originalWebhookData.wtHeaderParams)
    )
  }
  // Method: Set the Webhook parameters based on type (urlParams or headerParams)
  public setWebhookParams(
    params: { key: string; value: string }[],
    type: string
  ) {
    if (type === 'urlParams') {
      this.wtUrlParams = params
    } else if (type === 'headerParams') {
      this.wtHeaderParams = params
    }
  }
  // Method: Handle the change in the 'isWebhookTriggerEnabled' switch
  public onClickIsWebgookTriggerEnabled(value) {
    this.onSwitchChange(
      value,
      this.hasSwitchChanged() !== null ? this.hasSwitchChanged() : false
    )
  }

  // Watchers: Watch for changes in specified data properties

  @Watch('isWebhookTriggerEnabled', { deep: true })
  @Watch('wtName', { deep: true })
  @Watch('wtUrl', { deep: true })
  @Watch('selectedWtMethod', { deep: true })
  @Watch('wtUrlParams', { deep: true })
  @Watch('wtHeaderParams', { deep: true })
  @Watch('wtContent', { deep: true })
  // Method: Handle saving the Webhook configuration
  public onClickSaveWebhookConfig() {
    let wtData = {}
    let isError: boolean = false
    this.wtNameError = ''
    this.wtUrlError = ''
    this.wtContentError = ''

    // Validation: Check if 'Webhook Name' field is not empty

    if (!this.wtName?.trim()) {
      this.wtNameError = ' Webhook trigger name is required!'
      isError = true
    }

    // Validation: Check if 'Webhook URL' field' is not empty and is a valid URL
    if (this.wtUrl) {
      if (!isValidUrl(this.wtUrl)) {
        this.wtUrlError = 'Webhook trigger url should be a valid url!'
        isError = true
      }
    } else {
      this.wtUrlError = 'Webhook trigger url is required!'
      isError = true
    }

    // Prepare Webhook Data based on input values

    wtData = {
      name: this.wtName?.trim(),
      method: this.selectedWtMethod,
      url: this.wtUrl
    }

    // Filter out empty key-value pairs in Webhook Url parameters
    const filteredUrlParams = this.wtUrlParams.filter(
      (item) => item.key !== '' && item.value !== ''
    )

    // If there are valid Webhook Url parameters, add them to Webhook Data
    if (filteredUrlParams.length > 0) {
      wtData = { ...wtData, urlParams: arrayToMap(filteredUrlParams) }
    }

    // Validate URL parameters for empty key-value pairs
    const emptyUrlParams = this.wtUrlParams.some(
      (param) => param.key?.trim() === '' || param.value?.trim() === ''
    )

    if (emptyUrlParams) {
      isError = true
    }

    // Validate header parameters for empty key-value pairs
    const emptyHeaderParams = this.wtHeaderParams.some(
      (param) => param.key?.trim() === '' || param.value?.trim() === ''
    )

    if (emptyHeaderParams) {
      isError = true
    }

    // Filter out empty key-value pairs in header parameters
    const filteredHeaderParams = this.wtHeaderParams.filter(
      (item) => item.key !== '' && item.value !== ''
    )

    // If there are valid header parameters, add them to Webhook Data
    if (filteredHeaderParams.length > 0) {
      wtData = {
        ...wtData,
        headerParams: arrayToMap(filteredHeaderParams)
      }
    }
    // Check if content is required for non-GET and non-DELETE methods
    if (
      !(
        this.selectedWtMethod === 'GET' || this.selectedWtMethod === 'DELETE'
      ) &&
      this.wtContent
    ) {
      // Validate if Webhook Content is a valid JSON string
      if (!isValidJson(this.wtContent)) {
        this.wtTabs = 2
        this.wtContentError =
          'Webhook trigger content should be a valid json string'
        isError = true
      } else {
        this.wtContent = JSON.stringify(JSON.parse(this.wtContent), null, 2)
      }

      // If valid, add Webhook Content to Webhook Data
      wtData = { ...wtData, content: this.wtContent }
    }

    // Final Webhook Data object with error status and change status
    wtData = {
      ...wtData,
      isError,
      isChanged: isError
        ? false
        : this.hasDataChanged() || this.hasSwitchChanged()
    }

    // Trigger the 'onChange' prop with Webhook Data
    this.onChange(wtData)
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}

.chevron-padding {
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
