var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"d-flex justify-space-between align-items-center justify-content-center"},[_c('v-col',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('span',{staticClass:"summary-title col-12"},[_vm._v("Incidents Summary")]),_c('v-row',{staticClass:"d-flex justify-center col-xl-12"},[_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("Incidents received")]),(_vm.summaryStatus === 'Loading')?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":"15"}})],1):_vm._e(),(_vm.summaryStatus === 'Ready')?_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(_vm.summaryData.totalCount)+" ")]):_vm._e()])],1),_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("False alarms filtered")]),(_vm.summaryStatus === 'Loading')?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":"15"}})],1):_vm._e(),(_vm.summaryStatus === 'Ready')?_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(_vm.summaryData.noAlarmCount)+" ")]):_vm._e()])],1),_c('div',{staticClass:"incident-summary"},[_c('v-col',{class:`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
            _vm.isUserAdmin ? 'col-lg-12' : 'col-lg-3'
          } col-xl-2`},[_c('p',{staticClass:"text-md-body-1 text-body-2"},[_vm._v("Reduction")]),(_vm.summaryStatus === 'Loading')?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":"15"}})],1):_vm._e(),(_vm.summaryStatus === 'Ready')?_c('p',{staticClass:"bold-text text-md-h5 text-xl-h4 text-h6"},[_vm._v(" "+_vm._s(_vm.summaryData.reduction ? `${_vm.summaryData.reduction}%` : `-`)+" ")]):_vm._e()])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }