<template>
  <div class="text-center" :class="smallScreen ? 'text-center-mobile' : ''">
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <Overlay
            :src="processedUrl"
            :type="item?.filemetadata__mediatype"
            :opacity="0.5"
            :styles="{ width: '100%' }"
            :excludedZones="excludedZones"
            :cameraMetadata="cameraMetadata"
            :showWarning="showIndicator"
          />
        </div>
      </template>

      <Overlay
        :src="processedUrl"
        :type="item?.filemetadata__mediatype"
        :opacity="0.5"
        :styles="{ width: '100%' }"
        :excludedZones="excludedZones"
        :cameraMetadata="cameraMetadata"
        :showWarning="false"
      />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Overlay from '../excluded-zones/Overlay.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'
import { USE_LOCAL_STORAGE } from '@/utils/EnvMapping'

type SnapshotModalResponse = {
  [x: string]: any
  url: string
  processedfile__url?: string
  originalFileName: string
  processedfile__path?: string
  originalfile__path?: string
  filemetadata__mediatype: string
  finalresult__personassessment: string
  finalresult__vehiclemovement: string
}

const namespaceTodos = { namespace: 'todos' }
const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {
    Overlay
  }
})
export default class SnapshotModalForAlarmsBig extends Vue {
  @Prop() item: SnapshotModalResponse
  @Prop() isPending: boolean
  @Action('getDownloadUrl', namespaceTodos) public getDownloadUrl
  @Action('getLocalMediaUrl', namespaceTodos) public getLocalMediaUrl
  @Action('fetchZoneByIncident', namespaceTodos)
  private fetchZoneByIncident: any
  @Getter('getDownloadUrl', namespaceTodos) public downloadUrl

  public dialog: boolean = false
  public processedUrl: string = ''
  public excludedZones: any[] = []
  public cameraMetadata: {
    width: number
    height: number
  } = {
    width: 0,
    height: 0
  }
  public showIndicator: boolean = true

  public async mounted() {
    if (this.isPending) {
      this.processedUrl =
        this.item?.processedfile__url ?? this.item.originalfile__url
      return
    }

    this.downloadFileUrl()

    if (this.item?.id) {
      const zones = await this.fetchZoneByIncident(this.item.id)

      if (zones) {
        try {
          this.excludedZones =
            typeof zones?.excludedZones === 'string'
              ? JSON.parse(zones.excludedZones)
              : zones.excludedZones

          this.cameraMetadata = {
            width: zones.metadata.width,
            height: zones.metadata.height
          }
        } catch (e) {}
      }
    }
  }
  public get smallScreen() {
    return isCompactView()
  }

  private async downloadLocalFile() {
    if (this.item?.processedfile__path && this.item?.originalfile__path) {
      let relUrl: string
      if (
        this.item?.finalresult__personassessment !== 'alarm' &&
        this.item?.finalresult__vehiclemovement !== 'alarm'
      ) {
        relUrl = this.item?.originalfile__path
      } else {
        relUrl = this.item?.processedfile__path
      }
      await this.getLocalMediaUrl(relUrl)
      if (this.item?.filemetadata__mediatype === 'image') {
        this.processedUrl = `data:image/jpg;base64,${this.downloadUrl}`
      } else {
        this.processedUrl = `data:video/mp4;base64,${this.downloadUrl}`
      }
    }
  }

  private async downloadRemoteFile() {
    if (this.item?.processedfile__path && this.item?.originalfile__path) {
      let relUrl: string
      if (
        this.item?.finalresult__personassessment == 'no-alarm' &&
        this.item?.finalresult__vehiclemovement == 'no-alarm'
      ) {
        relUrl = this.item?.originalfile__path
      } else {
        relUrl = this.item?.processedfile__path
      }
      await this.getDownloadUrl(relUrl)
      this.processedUrl = this.downloadUrl
    } else {
      this.processedUrl = this.item?.processedfile__url
    }
  }

  private async downloadFileUrl() {
    if (this.item) {
      if (USE_LOCAL_STORAGE && this.item?.processedfile__islocal === 'true') {
        await this.downloadLocalFile()
      } else {
        await this.downloadRemoteFile()
      }
    }
  }
}
</script>

<style scoped>
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center-mobile {
  width: 100vw;
  padding-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
