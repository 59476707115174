<template>
  <v-row
    class="d-flex justify-space-between align-items-center justify-content-center"
  >
    <v-col class="d-flex justify-space-between flex-wrap">
      <span class="summary-title col-12">Incidents Summary</span>
      <v-row class="d-flex justify-center col-xl-12">
        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">Incidents received</p>
            <div v-if="summaryStatus === 'Loading'" class="loader-wrapper">
              <v-progress-circular
                indeterminate
                color="secondary"
                size="15"
              ></v-progress-circular>
            </div>
            <p
              v-if="summaryStatus === 'Ready'"
              class="bold-text text-md-h5 text-xl-h4 text-h6"
            >
              {{ summaryData.totalCount }}
            </p>
          </v-col>
        </div>

        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">False alarms filtered</p>
            <div v-if="summaryStatus === 'Loading'" class="loader-wrapper">
              <v-progress-circular
                indeterminate
                color="secondary"
                size="15"
              ></v-progress-circular>
            </div>
            <p
              v-if="summaryStatus === 'Ready'"
              class="bold-text text-md-h5 text-xl-h4 text-h6"
            >
              {{ summaryData.noAlarmCount }}
            </p>
          </v-col>
        </div>

        <div class="incident-summary">
          <v-col
            :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
              isUserAdmin ? 'col-lg-12' : 'col-lg-3'
            } col-xl-2`"
          >
            <p class="text-md-body-1 text-body-2">Reduction</p>
            <div v-if="summaryStatus === 'Loading'" class="loader-wrapper">
              <v-progress-circular
                indeterminate
                color="secondary"
                size="15"
              ></v-progress-circular>
            </div>
            <p
              v-if="summaryStatus === 'Ready'"
              class="bold-text text-md-h5 text-xl-h4 text-h6"
            >
              {{ summaryData.reduction ? `${summaryData.reduction}%` : `-` }}
            </p>
          </v-col>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }

@Component({
  components: {}
})
export default class IncidentSummaryBlock extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getSummaryData', namespaceStatistics)
  public summaryData: any
  @Getter('getSummaryStatus', namespaceStatistics)
  public summaryStatus: string

  @Action('fetchSummaryData', namespaceStatistics)
  fetchSummaryData

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('$route.params')
  public fetchChartData = debounce(async () => {
    await this.fetchSummaryData({
      startTime: this.startTime,
      endTime: this.endTime,
      ...this.$route.params
    })
  }, 300)
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.incident-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 230px;
}

.installation-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}
</style>
