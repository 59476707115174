import { P2pPlayerConfig } from '@/utils/Interfaces'

const getters = {
  config: (state) => {
    return state.config
  },
  allClientConfig: (state: any) => {
    return state.clientType
  },
  getGlobalTimeRange: (state) => {
    if (state.globalTimeRange) {
      return state.globalTimeRange
    }
    return false
  },
  getIsNavigationUpdated: (state) => {
    if (state.isNavigationUpdated) {
      return state.isNavigationUpdated
    }
    return false
  },
  getAutoRefreshSettings: (state) => {
    return state.autoRefreshSettings
  },
  getIsAlarmRefreshEnabled: (state) => {
    return state.isAlarmRefreshEnabled
  },
  getServiceStatus: (state) => {
    return state.serviceStatus
  },
  getBreadcrumbList: (state) => {
    return state.breadcrumbList
  },
  getIsTreeViewLoading: (state) => {
    return state.isTreeViewLoading
  },
  getShowPendingIncidents: (state: { showPendingIncidents: boolean }) => {
    return state.showPendingIncidents
  },
  getTreeClients: (state) => {
    return state.treeClients
  },
  getTreeSites: (state) => {
    return state.treeSites
  },
  getTreeCameras: (state) => {
    return state.treeCameras
  },
  getTreeCustomers: (state) => {
    return state.treeCustomers
  },
  getisDarkModeToggleEnabled: (state) => {
    return state.isDarkModeToggleEnabled
  },
  getIsNotFound: (state) => {
    return state.isNotFound
  },
  getConfig: (state) => {
    return state.config
  },
  getIsToggleMenuEnabled: (state) => {
    return state.isToggleMenuEnabled
  },
  getVersionInfo: (state: { webAppVersion: string | null }) => {
    return state.webAppVersion
  },
  getPromiseQubeVersion: (state: { promiseQubeVersion: string | null }) => {
    return state.promiseQubeVersion
  },
  getP2PPlayer: (state: { p2pPlayer: P2pPlayerConfig }): P2pPlayerConfig => {
    return state.p2pPlayer
  }
}

export default {
  ...getters
}
