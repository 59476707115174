<template>
  <div>
    <!-- Card for Auto Update -->
    <div class="card-style text-subtitle-1 font-weight-bold text-left pl-4">
      <div class="notification-title">
        <div :style="{ color: getTextColor }" data-test-id="auto-update-label">
          Auto update
          <span
            class="toggle-status"
            :style="{ color: '#c4c4c4', marginLeft: '8px' }"
          >
            {{ isAutoUpdate ? 'On' : 'Off' }}
          </span>
        </div>
        <v-switch
          v-model="isAutoUpdate"
          :disabled="!isWriteEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          :dark="isDarkMode"
          data-test-id="auto-update-switch"
          @change="onToggleAutoUpdate"
        />
      </div>

      <!-- Information Alert -->
      <v-alert
        v-if="isAutoUpdate"
        dense
        outlined
        type="info"
        color="#ffd42a"
        width="98%"
        style="text-align: center; margin-top: 12px"
        data-test-id="health-check-config-reference-image-alert"
      >
        <template v-slot:prepend>
          <v-icon color="#ffd42a">mdi-information</v-icon>
        </template>
        <p
          style="margin: 5px"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : '#000000'
          }"
        >
          {{ refImageNote }}
        </p>
      </v-alert>
    </div>

    <!-- Card for Scheduled Update -->
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left"
      :style="{ color: getSubheaderColor }"
      :class="{
        'disabled-background':
          !(isUserAdmin && isDarkModeToggleEnabled) && isScheduleUpdateDisabled
      }"
    >
      <div class="notification-title pl-4">
        <div
          :style="{ color: getTextColor }"
          data-test-id="scheduled-update-label"
        >
          Scheduled Update
          <span class="toggle-status" :style="{ color: '#c4c4c4' }">
            {{ isScheduledUpdate ? 'On' : 'Off' }}
          </span>
        </div>
        <v-switch
          v-model="isScheduledUpdate"
          :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
          text
          inset
          class="notification-switch"
          color="secondary"
          :dark="isDarkMode"
          data-test-id="scheduled-update-switch"
          @change="onToggleScheduledUpdate"
        />
      </div>

      <!-- Settings Section (appears when Scheduled Update is enabled) -->
      <v-container v-if="isScheduledUpdate">
        <v-row>
          <!-- Select Time Zone -->
          <v-col cols="6">
            <v-subheader
              class="text-no-wrap"
              :style="{ color: getSubheaderColor }"
              data-test-id="time-zone-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{ color: getSubheaderColor }"
                >mdi-web</v-icon
              >
              Select time zone
            </v-subheader>
          </v-col>
          <v-col cols="6" align="right">
            <v-autocomplete
              v-model="selectedTimeZone"
              :items="timeZonesList"
              outlined
              dense
              append-icon="mdi-chevron-down"
              content-class="elevation-0"
              class="text-caption schedule-input right-aligned-input"
              :dark="isDarkMode"
              :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
              data-test-id="time-zone-select"
              style="max-width: 200px"
            />
          </v-col>

          <!-- Update Each -->
          <v-col cols="6">
            <v-subheader
              class="text-no-wrap"
              :style="{ color: getSubheaderColor }"
              data-test-id="update-frequency-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{ color: getSubheaderColor }"
              >
                mdi-calendar-clock
              </v-icon>
              Update Each
            </v-subheader>
          </v-col>
          <v-col cols="6" class="d-flex justify-end align-items-center">
            <v-text-field
              v-model="updateNumber"
              type="number"
              outlined
              dense
              class="text-caption schedule-input small-input"
              :dark="isDarkMode"
              :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
              data-test-id="update-number-input"
              style="margin-right: 5px"
            />
            <v-select
              v-model="updateFrequency"
              :items="frequencies"
              outlined
              dense
              append-icon="mdi-chevron-down"
              class="text-caption schedule-input"
              :dark="isDarkMode"
              :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
              data-test-id="update-frequency-select"
              style="max-width: 90px; margin-left: 10px"
            />
          </v-col>

          <!-- Applicable Days -->
          <v-col cols="6">
            <v-subheader
              class="text-no-wrap"
              :style="{ color: getSubheaderColor }"
              data-test-id="applicable-days-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{ color: getSubheaderColor }"
                >mdi-calendar-month-outline</v-icon
              >
              Applicable Days
            </v-subheader>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn-toggle
              v-model="applicableDays"
              multiple
              class="applicable-days-toggle"
              :style="{ backgroundColor: 'transparent' }"
            >
              <v-btn
                v-for="day in daysOfWeek"
                :key="day.value"
                :value="day.value"
                :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
                class="toggle-button-class"
                :style="{ color: getSubheaderColor }"
                :dark="isDarkMode"
                data-test-id="applicable-days-select"
                outlined
              >
                {{ day.short }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- Scheduled Time -->
          <v-col cols="6">
            <v-subheader
              class="text-no-wrap"
              :style="{ color: getSubheaderColor }"
              data-test-id="scheduled-time-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{ color: getSubheaderColor }"
                :dark="isDarkMode"
                >mdi-clock-outline</v-icon
              >
              Scheduled Time
            </v-subheader>
          </v-col>
          <v-col cols="6" align="right">
            <v-text-field
              class="text-caption schedule-input right-aligned-input"
              v-model="scheduledTime"
              type="time"
              outlined
              dense
              :dark="isDarkMode"
              :disabled="isScheduleUpdateDisabled || !isWriteEnabled"
              data-test-id="scheduled-time-input"
              style="max-width: 140px"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import momentTZ from 'moment-timezone'
import { Getter } from 'vuex-class'
import { UpdateFrequency, IUpdateSchedulingConfig } from '@/utils/Interfaces'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({})
export default class SiteUpdateSchedulingConfiguration extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop()
  configuration: any
  @Prop() onChange: (value: any) => void
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled!: boolean

  public isAutoUpdate: boolean = false
  public isScheduledUpdate: boolean = false
  public selectedTimeZone: string = 'Europe/Berlin'
  public updateFrequency: UpdateFrequency.Week
  public updateNumber: number = 1
  public applicableDays: any = []
  public scheduledTime: string = '08:00'

  public refImageNote: string =
    'Auto update will happen when the updates are available and there is an internet connection'

  public timeZonesList: Array<string> = momentTZ.tz.names()
  public frequencies = ['week', 'month']
  public daysOfWeek = [
    { value: 'Sun', short: 'S' },
    { value: 'Mon', short: 'M' },
    { value: 'Tue', short: 'T' },
    { value: 'Wed', short: 'W' },
    { value: 'Thu', short: 'T' },
    { value: 'Fri', short: 'F' },
    { value: 'Sat', short: 'S' }
  ]
  private originalUpdateSchedulingConfig: IUpdateSchedulingConfig = {
    selectedTimeZone: 'Europe/Berlin',
    applicableDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    updateFrequency: UpdateFrequency.Week,
    updateNumber: 1,
    scheduledTime: '08:00',
    isAutoUpdate: false,
    isScheduledUpdate: false
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  get isDarkMode() {
    return this.isUserAdmin && this.isDarkModeToggleEnabled
  }

  get isScheduleUpdateDisabled() {
    return this.isAutoUpdate
  }
  get isAutoUpdateOn() {
    return this.isAutoUpdate
  }

  get getTextColor() {
    return this.isDarkMode
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkBlack
  }

  get getSubheaderColor() {
    return this.isDarkMode
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkPrimaryColor
  }
  public mounted() {
    this.watchConfiguration()
  }

  @Watch('configuration')
  public watchConfiguration() {
    if (this.configuration) {
      this.isAutoUpdate = this.configuration?.promiseQube?.isAutoUpdate ?? false
      this.isScheduledUpdate =
        this.configuration?.promiseQube?.isScheduledUpdate ?? false
      this.selectedTimeZone =
        this.configuration?.promiseQube?.selectedTimeZone ?? 'Europe/Berlin'
      this.updateFrequency =
        this.configuration?.promiseQube?.updateFrequency ?? 'week'
      this.updateNumber = this.configuration?.promiseQube?.updateNumber ?? 1
      this.applicableDays =
        this.configuration?.promiseQube?.applicableDays ?? null
      this.scheduledTime =
        this.configuration?.promiseQube?.scheduledTime ?? '08:00'

      this.originalUpdateSchedulingConfig = {
        selectedTimeZone: this.selectedTimeZone,
        applicableDays: this.applicableDays,
        updateFrequency: this.updateFrequency,
        updateNumber: this.updateNumber,
        scheduledTime: this.scheduledTime,
        isAutoUpdate: this.isAutoUpdate,
        isScheduledUpdate: this.isScheduledUpdate
      }
    }
  }
  public hasDataChanged(): boolean {
    return (
      this.isAutoUpdate !== this.originalUpdateSchedulingConfig?.isAutoUpdate ||
      this.isScheduledUpdate !==
        this.originalUpdateSchedulingConfig?.isScheduledUpdate ||
      this.selectedTimeZone !==
        this.originalUpdateSchedulingConfig?.selectedTimeZone ||
      this.scheduledTime !==
        this.originalUpdateSchedulingConfig?.scheduledTime ||
      this.updateFrequency !==
        this.originalUpdateSchedulingConfig?.updateFrequency ||
      this.updateNumber !== this.originalUpdateSchedulingConfig?.updateNumber ||
      JSON.stringify(this.applicableDays) !==
        JSON.stringify(this.originalUpdateSchedulingConfig?.applicableDays)
    )
  }
  @Watch('selectedTimeZone')
  @Watch('scheduledTime')
  @Watch('updateFrequency')
  @Watch('updateNumber')
  @Watch('isScheduledUpdate')
  @Watch('isAutoUpdate')
  @Watch('applicableDays')
  public onClickSaveScheduleConfig() {
    const data = {
      selectedTimeZone: this.selectedTimeZone,
      applicableDays: this.applicableDays,
      updateFrequency: this.updateFrequency,
      updateNumber: this.updateNumber,
      scheduledTime: this.scheduledTime,
      isAutoUpdate: this.isAutoUpdate,
      isScheduledUpdate: this.isScheduledUpdate,
      isChanged: this.hasDataChanged()
    }

    this.onChange(data)
  }

  public onToggleAutoUpdate(value: boolean) {
    this.isAutoUpdate = value
  }

  public onToggleScheduledUpdate(value: boolean) {
    this.isScheduledUpdate = value
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.disabled-background {
  background-color: #f2f2f2 !important;
}

.v-subheader {
  padding: 0 16px 0 0px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.v-text-field {
  align-content: right;
  justify-content: right;
  text-align: right !important;
}
.schedule-input {
  height: 40px !important;
  width: 100%;
}

.small-input {
  max-width: 50px !important;
}

.right-aligned-input {
  max-width: 132px !important;
  text-align: center !important;
}

.applicable-days-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn:not(.v-btn--active) {
  border: 2px solid #dadada !important;
}

.toggle-button-class.v-btn {
  margin-left: 5px;
  border-radius: 4px !important;
  height: 40px !important;
  min-width: 40px !important;
  padding: 0px !important;
  font-weight: 800 !important;
}

.toggle-button-class.v-btn--active {
  border: 2px solid #ffd42a !important;
  padding: 0px !important;
}

.v-btn:before {
  background-color: transparent !important;
}
</style>
