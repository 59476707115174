const getters = {
  isNotificationEnabled: (state: { isNotificationEnabled: boolean }) => {
    return state.isNotificationEnabled
  },
  getWorkerSpaceIsHealthCheckNotificationEnabled: (state: {
    isWorkerSpaceHealthCheckNotificationEnabled: boolean
  }) => {
    return state.isWorkerSpaceHealthCheckNotificationEnabled
  }
}

export default {
  ...getters
}
