<template>
  <div>
    <div
      :class="raw ? '' : 'display-container'"
      v-if="error"
      :id="containerId"
      v-show="!loading"
    >
      <div id="regions" ref="regions"></div>
    </div>
    <div :class="{ 'loader-container': loading, hidden: !loading }">
      <ProgressBar :loading="loading"></ProgressBar>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import p5 from 'p5'
import ProgressBar from '../ProgressBar.vue'
import { Action, Getter } from 'vuex-class'

const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {
    ProgressBar
  }
})
export default class DisplayDrawing extends Vue {
  @Prop() public referenceImage: string
  @Prop({ default: false }) public viewOnly: boolean
  @Prop({ default: () => [] }) public readonly existingRegions: any[]
  @Prop({ default: '' }) public cameraDocId: string
  @Prop({ default: false }) public raw: boolean
  @Prop({ default: 'display-canvas-viewer' }) public canvasId: string
  @Prop({ default: 'display-canvas-container' }) public containerId: string
  @Prop({
    default: () => {
      return {
        width: 0,
        height: 0
      }
    }
  })
  public readonly metadata: {
    width: number
    height: number
  }
  @Getter('getCameraDocId', namespaceCamera)
  public cameraID: string
  @Getter('getPreDeleteReferenceImage', namespaceCamera)
  public preDeleteReferenceImage: string
  @Action('setCameraDocId', namespaceCamera) public setCameraDocId: any
  @Action('setPreviousCameraReferenceImageIsDeleted', namespaceCamera)
  public setPreviousCameraReferenceImageIsDeleted: any

  private image: p5.Image
  public regions: { points: p5.Vector[] }[] = []
  public points: p5.Vector[] = []
  public color: string = 'green'

  public error: { message: string } | {} = {}
  public loading: boolean = true
  public isExcludedZonesSaved: boolean = true
  public linkChanged: boolean = false
  public typeReferenceImage: string = ''
  public imageLink: string = ''

  @Watch('existingRegions')
  private watchExistingRegions() {
    this.regions = this.convertToExcludedZones()
  }

  private createNewRegion() {
    return {
      points: []
    }
  }

  public mounted() {
    this.regions = this.convertToExcludedZones()
    this.watchReferenceImage()
  }

  private convertToExcludedZones() {
    const excludedZones: any[] = []

    for (const region of this.existingRegions) {
      const newRegion = this.createNewRegion()
      for (const point of region.coordinates[0]) {
        // multiply with image width and height to get the actual coordinates
        newRegion.points.push(
          new p5.Vector(
            point[0] * this.metadata.width,
            point[1] * this.metadata.height
          )
        )
      }
      excludedZones.push(newRegion)
    }

    return excludedZones
  }

  @Watch('referenceImage')
  public watchReferenceImage() {
    // if refrence image is changed, need to check the conditions below
    if (this.typeReferenceImage === '') {
      this.typeReferenceImage = this.getUrlType(this.referenceImage)
      this.imageLink = this.referenceImage

      // if cameraID is equal to '', then it means that the user is in the configuration page for the first time
      if (this.cameraID === '') {
        this.setCameraDocId(this.cameraDocId)
        this.init()
      }

      // if cameraID is not equal to '', then it means that the user has already been in the configuration page for same camera
      else if (this.cameraID === this.cameraDocId) {
        this.init()
      }

      // if cameraID is not equal to '', then it means that the user has already been in the configuration page for different camera
      else {
        this.setCameraDocId(this.cameraDocId)
        if (this.preDeleteReferenceImage) {
          this.setPreviousCameraReferenceImageIsDeleted({
            isDeleted: false
          })
          this.init()
        } else {
          this.setPreviousCameraReferenceImageIsDeleted({
            isDeleted: false
          })
          if (this.loading) {
            try {
              setTimeout(() => {
                if (this.loading) {
                  this.init()
                }
              }, 2000)
            } catch (e) {
              console.log(e)
            }
          }
        }
      }
    } else if (
      this.typeReferenceImage !== this.getUrlType(this.referenceImage) &&
      this.typeReferenceImage === 'Blob URL'
    ) {
      this.typeReferenceImage = this.getUrlType(this.referenceImage)
    } else if (
      this.imageLink !== this.referenceImage &&
      this.typeReferenceImage === 'Data URL'
    ) {
      this.typeReferenceImage = this.getUrlType(this.referenceImage)
      this.imageLink = this.referenceImage
      this.init()
    } else {
      this.init()
    }
  }

  public init() {
    try {
      const canvasContainer = document.getElementById(this.containerId)
      const existingCanvasViewer = document.getElementById(this.canvasId)

      if (existingCanvasViewer) {
        canvasContainer.removeChild(existingCanvasViewer)
      }

      const canvasViewer = document.createElement('div')
      canvasViewer.setAttribute('id', this.canvasId)
      canvasContainer.insertBefore(canvasViewer, canvasContainer.firstChild)

      new p5(
        (p) => {
          p.preload = () => {
            this.image = p.loadImage(
              this.referenceImage,
              () => {
                this.loading = false
              },
              () => {
                this.loading = true
                this.error = {
                  message: 'Error loading image'
                }
              }
            )
          }

          p.setup = () => {
            // TODO: keep aspect ratio respect to max-width 720
            // const canvas = p.createCanvas(
            //   465,
            //   (420 * this.image.height) / this.image.width
            // )

            const canvas = p.createCanvas(this.image.width, this.image.height)
            // style canvas to width: 465px, height: 300px
            // if landscape
            if (this.image.width > this.image.height) {
              canvas.style('width', '100%')
              canvas.style('height', 'auto')
            }
            // if portrait
            else {
              canvas.style('width', 'auto')
              canvas.style('height', '40vh')
            }
          }

          p.draw = () => {
            p.background(255)
            if (this.image) {
              p.image(this.image, 0, 0, p.width, p.height)
            }
            p.strokeWeight(4)
            p.stroke(this.color)
            for (let i = 0; i < this.points.length - 1; i++) {
              p.line(
                this.points[i].x,
                this.points[i].y,
                this.points[i + 1].x,
                this.points[i + 1].y
              )
            }
            p.fill(this.color)
            for (const element of this.points) {
              p.ellipse(element.x, element.y, 5, 5)
            }

            // draw regions as shapes
            // fill opacity
            for (const region of this.regions) {
              p.fill(0, 128, 0, 50)
              p.beginShape()
              for (const point of region.points) {
                p.vertex(point.x, point.y)
              }
              p.endShape(p.CLOSE)
            }
          }
        },
        // if this.$refs['display-canvas-viewer'] exists, then it will be used as the parent element for the canvas
        this.$refs[this.canvasId]
          ? (this.$refs[this.canvasId] as HTMLElement)
          : (this.canvasId as any)
      )
    } catch (e) {
      console.log(e)
    }
  }

  // The function to get the type of the reference image url (blob, data, or unknown)
  private getUrlType(url: string) {
    const lowercasedUrl = url.toLowerCase()
    if (lowercasedUrl.startsWith('blob:')) {
      return 'Blob URL'
    } else if (lowercasedUrl.startsWith('data:')) {
      return 'Data URL'
    } else {
      return 'Unknown URL type'
    }
  }
}
</script>

<style scoped>
.display-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #ffd42a;
  padding: 5px;
  border-radius: 4px;
  contain: content;
  inline-size: fit-content;
  width: 100% !important;
  height: 100% !important;
}

.loader-container {
  display: center;
  flex-direction: column;
  padding: 5px;
  width: 450px;
  height: 20px;
  contain: content;
}

.hidden {
  display: none;
}

#display-canvas-viewer {
  cursor: crosshair;
}
</style>
