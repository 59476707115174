var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"none"}},[_c('div',[_vm._v("Environment Configuration")]),_c('div',{staticClass:"card-style",staticStyle:{"padding-right":"19px","padding-left":"19px","padding-top":"19px","padding-bottom":"19px"}},[_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          })},[_vm._v(" AI Endpoint ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"AI Endpoint","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.aiEndpoint),callback:function ($$v) {_vm.aiEndpoint=$$v},expression:"aiEndpoint"}})],1),_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          })},[_vm._v(" SIA Server ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"SIA Server","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.siaServer),callback:function ($$v) {_vm.siaServer=$$v},expression:"siaServer"}})],1),_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          })},[_vm._v(" Default Deadline ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"type":"number","placeholder":"45","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.deadline),callback:function ($$v) {_vm.deadline=$$v},expression:"deadline"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Blip Endpoint ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Blip Endpoint","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.blipEndpoint),callback:function ($$v) {_vm.blipEndpoint=$$v},expression:"blipEndpoint"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Blip","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isBlipEnabled),callback:function ($$v) {_vm.isBlipEnabled=$$v},expression:"isBlipEnabled"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Similarity Search Endpoint ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Similarity Search Endpoint","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.simSearchEndpoint),callback:function ($$v) {_vm.simSearchEndpoint=$$v},expression:"simSearchEndpoint"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Similarity Search","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isSimSearchEnabled),callback:function ($$v) {_vm.isSimSearchEnabled=$$v},expression:"isSimSearchEnabled"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Object IDs","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isObjectIdsEnabled),callback:function ($$v) {_vm.isObjectIdsEnabled=$$v},expression:"isObjectIdsEnabled"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Redis Server ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Redis Server","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.redisServer),callback:function ($$v) {_vm.redisServer=$$v},expression:"redisServer"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Redis Port ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Redis Port","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.redisPort),callback:function ($$v) {_vm.redisPort=$$v},expression:"redisPort"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Redis Password ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"type":"password","placeholder":"Redis Password","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.redisPassword),callback:function ($$v) {_vm.redisPassword=$$v},expression:"redisPassword"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Redis Caching","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isRedisCachingEnabled),callback:function ($$v) {_vm.isRedisCachingEnabled=$$v},expression:"isRedisCachingEnabled"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Route Traffic Endpoint ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Route Traffic Endpoint","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.routeTrafficEndpoint),callback:function ($$v) {_vm.routeTrafficEndpoint=$$v},expression:"routeTrafficEndpoint"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Route Traffic","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isRouteTrafficEnabled),callback:function ($$v) {_vm.isRouteTrafficEnabled=$$v},expression:"isRouteTrafficEnabled"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" Web App Processing Library ")])]),_c('v-select',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"items":Object.values(_vm.webAppProcessingLibraries),"hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.webAppProcessLibrary),callback:function ($$v) {_vm.webAppProcessLibrary=$$v},expression:"webAppProcessLibrary"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('v-switch',{attrs:{"label":"Enable Media Processing","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.isWebAppMediaProcessingEnabled),callback:function ($$v) {_vm.isWebAppMediaProcessingEnabled=$$v},expression:"isWebAppMediaProcessingEnabled"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ Hostname ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"RabbitMQ Hostname","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.HOSTNAME),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "HOSTNAME", $$v)},expression:"rabbitMQ.HOSTNAME"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ Port ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"RabbitMQ Port","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.PORT),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "PORT", $$v)},expression:"rabbitMQ.PORT"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ Username ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"RabbitMQ Username","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.USERNAME),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "USERNAME", $$v)},expression:"rabbitMQ.USERNAME"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ Password ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"type":"password","placeholder":"RabbitMQ Password","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.PASSWORD),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "PASSWORD", $$v)},expression:"rabbitMQ.PASSWORD"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ VHOST ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"RabbitMQ VHOST","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.VHOST),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "VHOST", $$v)},expression:"rabbitMQ.VHOST"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlack
              })},[_vm._v(" RabbitMQ Alarms Exchange ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"RabbitMQ Alarms Exchange","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.rabbitMQ.ALARMS_EXCHANGE),callback:function ($$v) {_vm.$set(_vm.rabbitMQ, "ALARMS_EXCHANGE", $$v)},expression:"rabbitMQ.ALARMS_EXCHANGE"}})],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }