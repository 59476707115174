<template>
  <v-card
    class="mx-16 px-2 d-flex justify-space-around"
    min-width="6vw"
    elevation="0"
    align-content="center"
    outlined
    shaped
    v-if="isAskCrowdWorkers"
    color="card"
  >
    <div class="avatars">
      <Avatar
        v-for="worker in onlineUsers.slice(0, 5)"
        :key="worker.id"
        :width="'35px'"
        :id="worker.id"
        class="avatar"
      ></Avatar>
    </div>
    <span class="robot">
      <span v-if="onlineUsers.length > 5" class="moreWorkers"
        >+{{ onlineUsers.length - 5 }}</span
      >
      <v-icon v-if="onlineUsers.length == 0" class="account"
        >mdi-account-multiple-plus</v-icon
      >
      <v-icon class="robot-icon">mdi-robot</v-icon>
    </span>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Avatar from './user/Avatar.vue'
const namespaceConfig = { namespace: 'config' }
const namespaceStatus = { namespace: 'status' }
const namespaceWorker = { namespace: 'worker' }
const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    Avatar
  }
})
export default class AssessmentModeIndicator extends Vue {
  @Action('bindConfig', namespaceConfig) public bindConfig: () => any
  @Action('unbindConfig', namespaceConfig) public unbindConfig: () => void
  @Getter('getCurrentUserIsAskCrowdWorkers', namespaceUser)
  private isAskCrowdWorkers: boolean
  @Getter('getOnlineWorkers', namespaceWorker) public onlineUsers
  @Action('bindStatus', namespaceStatus) public bindStatus
  @Action('bindOnlineWorkers', namespaceWorker) public bindOnlineWorkers

  public mounted() {
    this.bindConfig()
    this.bindStatus()
    this.bindOnlineWorkers()
  }

  public beforeDestroy() {
    this.unbindConfig()
  }
}
</script>

<style scoped>
.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-top: 5%;
}

.account {
  margin-right: 12px;
}

.avatar {
  position: relative;
  overflow: hidden;
  width: 75px;
  align-items: center;
}

.avatar:not(:last-child) {
  margin-left: -58px;
}

.robot {
  margin-left: -40px;
}

.robot-icon {
  margin-bottom: 6px;
}

.moreWorkers {
  font-size: 0.8rem;
  margin-right: 10px;
}
</style>
