var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-style"},[_c('div',{staticClass:"pr-3 pl-3 py-4 form"},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Configuration Type ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-select',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Select Config Type","items":['Auto', 'Manual'],"outlined":"","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.edConfigType),callback:function ($$v) {_vm.edConfigType=$$v},expression:"edConfigType"}})],1)],1),(_vm.edConfigType === 'Auto')?[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" IP ")])]),_c('v-col',{attrs:{"cols":"7"}},[(!_vm.isDetectedCamerasLoading)?_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"outlined":"","color":"secondary","hide-details":"true","autocomplete":"off","dense":true,"readonly":""},model:{value:(_vm.edgeDevice.ip),callback:function ($$v) {_vm.$set(_vm.edgeDevice, "ip", $$v)},expression:"edgeDevice.ip"}}):_vm._e(),(_vm.isDetectedCamerasLoading)?_c('v-progress-circular',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkBlackColor,
              marginLeft: '1rem'
            }),attrs:{"size":"25","indeterminate":""}}):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Username ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{class:{
              'authentication-credential-error':
                _vm.isAuthenticationCredentialError
            },attrs:{"loading":_vm.isAuthenticationRequestComplete,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Username","outlined":"","color":_vm.credentialErrorColor,"hide-details":"true","autocomplete":"off","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.usernameError,"error":_vm.usernameError.length > 0 || _vm.isAuthenticationCredentialError},model:{value:(_vm.edgeDevice.username),callback:function ($$v) {_vm.$set(_vm.edgeDevice, "username", $$v)},expression:"edgeDevice.username"}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" Password ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{class:{
              'authentication-credential-error':
                _vm.isAuthenticationCredentialError
            },attrs:{"loading":_vm.isAuthenticationRequestComplete,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Password","outlined":"","type":"password","hide-details":"true","autocomplete":"new-password","color":_vm.credentialErrorColor,"dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.passwordError,"error":_vm.passwordError.length > 0 || _vm.isAuthenticationCredentialError},model:{value:(_vm.edgeDevice.password),callback:function ($$v) {_vm.$set(_vm.edgeDevice, "password", $$v)},expression:"edgeDevice.password"}})],1)],1),(_vm.isAuthenticationCredentialError)?_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"7"}},[_c('p',{staticClass:"text-left d-flex align-center ma-0",style:({ color: '#ffd42a' })},[(_vm.passwordError !== '')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.credentialErrorColor}},[_vm._v("mdi-alert-circle")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.passwordError)+" ")])],1)])],1):_vm._e()]:_vm._e(),(_vm.edConfigType === 'Manual')?[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text-left tooltip",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(" RTSP URL ")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"RTSP URL","outlined":"","color":"secondary","hide-details":"true","dense":true,"disabled":!_vm.isWriteEnabled,"error-messages":_vm.pathError,"error":_vm.pathError.length > 0},model:{value:(_vm.rtspUrl),callback:function ($$v) {_vm.rtspUrl=$$v},expression:"rtspUrl"}})],1)],1)]:_vm._e(),(_vm.isEdgeDeviceEnabled)?_c('v-row',{staticClass:"d-flex flex-row justify-space-between",attrs:{"no-gutters":""}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }