export const getDefaultState = () => {
  return {
    liveHealthChecks: [],
    liveHealthChecksLoading: false,
    liveHcLoadMoreLoading: false,
    lastVisibleLiveHealthCheck: null,
    hasMoreLiveHealthChecks: false,
    lastLiveHealthCheck: null
  }
}

const state = getDefaultState()

export default {
  ...state
}
