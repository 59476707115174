export interface WHEPClientConfig {
  videoElement: HTMLVideoElement
  whepUrl: string
  streamPath: string
  restartDelay?: number
  updateStatus: (StreamStatus) => void
}

export interface RTCIceServerExtended extends RTCIceServer {
  credentialType?: string
}

export enum WHEPClientStatus {
  DISCONNECTED = 'disconnected',
  FAILED = 'failed',
  CONNECTED = 'connected',
  COMPLETED = 'completed',
  CHECKING = 'checking',
  CLOSED = 'closed',
  NEW = 'new'
}

export enum StreamStatus {
  FAILED = 'failed',
  LOADING = 'loading',
  CONNECTED = 'connected'
}
