<template>
  <div ref="videoContainer" class="video-container">
    <div
      v-if="streamStatus !== 'connected'"
      class="image-overlay"
      :style="placeHolder()"
    ></div>
    <div class="loading-content blur-backdrop">
      <div v-if="streamStatus === 'loading'" class="status">
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
        <p>Loading...</p>
      </div>
      <div v-if="streamStatus === 'failed'" class="error-status">
        <p>The Livestream cannot be loaded</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { WHEPClient } from '@/services/whep-client/whep-client'
import { StreamStatus } from '@/services/whep-client/interfaces'

@Component
export default class WHEPClientComponent extends Vue {
  private streamStatus: StreamStatus = StreamStatus.LOADING
  private client: WHEPClient | null = null

  @Prop({ required: true }) whepUrl: string
  @Prop({ required: true }) streamPath: string
  @Prop({ required: false }) streamPlaceholder: string

  private initVideoElement(container: HTMLElement): void {
    const video = document.createElement('video') as HTMLVideoElement
    // set video dimensions
    video.style.width = '100%'
    video.style.height = '100%'

    video.id = 'video'

    // Set video attributes
    video.controls = true
    video.muted = true
    video.autoplay = true
    video.playsInline = true

    container.appendChild(video)

    const updateStatus = (status: StreamStatus) => {
      this.streamStatus = status
    }

    // Initialize the WHEPClient with the video element and status element
    const client = new WHEPClient({
      videoElement: video,
      whepUrl: this.whepUrl,
      streamPath: this.streamPath,
      restartDelay: 5000, // 5 seconds
      updateStatus: updateStatus
    })

    this.client = client
  }

  @Watch('whepUrl')
  onWhepUrlChange() {
    if (!this.whepUrl) return
    this.initVideoElement(this.$refs.videoContainer as HTMLElement)
    this.client?.start()
  }

  placeHolder() {
    return {
      backgroundImage: `url(${this.streamPlaceholder})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  }

  beforeDestroy() {
    this.client?.stop()
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  background: black;
  width: 100%;
  height: 100%;
}

.status,
.error-status {
  color: white;
  font-size: 1em;
  text-align: center;
  padding: 16px;
}

.loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  z-index: 4;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: black;
}

.blur-backdrop {
  backdrop-filter: blur(3px);
}

video {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
