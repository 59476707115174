import { getDefaultState } from './state'

const mutations = {
  setNumberOfTodosWorkerDid(
    state: { numberOfTodosWorkerDid },
    payload: any
  ): void {
    state.numberOfTodosWorkerDid = payload
  },
  setRegisteredWorkers(state: { registeredWorkers }, payload: any): void {
    state.registeredWorkers = payload
  },
  setWorkerQueue(state: { workerQueue }, payload: any): void {
    state.workerQueue = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  getDownloadSpeed(state: { downlink }, payload: any): void {
    state.downlink = payload
  },
  getUplinkSpeed(state: { uplink }, payload: any): void {
    state.uplink = payload
  }
}

export default {
  ...mutations
}
