var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      })},[_vm._v(" FTP/SFTP ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","data-test-id":"ftp-smtp-expand-icon","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pr-3 pl-3 py-4 form"},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" FTP Host: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"ftp-host-copy"},domProps:{"textContent":_vm._s('ftp.promiseq.com')},on:{"click":function($event){return _vm.onClickCopy('ftp.promiseq.com')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" SFTP Host: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"sftp-host-copy"},domProps:{"textContent":_vm._s('sftp.promiseq.com')},on:{"click":function($event){return _vm.onClickCopy('sftp.promiseq.com')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" FTP Port: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"ftp-port-copy"},domProps:{"textContent":_vm._s('21')},on:{"click":function($event){return _vm.onClickCopy('21')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" SFTP Port: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"sftp-port-copy"},domProps:{"textContent":_vm._s('22')},on:{"click":function($event){return _vm.onClickCopy('22')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" FTP Mode: "),_c('code',{domProps:{"textContent":_vm._s('PASSIVE')}})])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Username: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"ftp-username-copy"},domProps:{"textContent":_vm._s(_vm.projectId)},on:{"click":function($event){return _vm.onClickCopy(`${_vm.projectId}`)}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Password: ")]),_c('v-select',{staticClass:"text-caption ml-1",staticStyle:{"max-width":"50%","height":"40px"},attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"append-icon":"mdi-chevron-down","content-class":"elevation-0","items":_vm.subscriptionKeys,"outlined":"","hide-details":"true","dense":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1732385462),model:{value:(_vm.selectedFtpPassword),callback:function ($$v) {_vm.selectedFtpPassword=$$v},expression:"selectedFtpPassword"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"ripple":false,"text":"","icon":""},on:{"click":function($event){return _vm.onClickCopy(_vm.selectedFtpPassword)}}},[_c('v-icon',{staticClass:"list-custom-icon",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(_vm._s('mdi-content-copy'))])],1)],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Path: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"ftp-path-copy"},domProps:{"textContent":_vm._s(_vm.getId)},on:{"click":function($event){return _vm.onClickCopy(_vm.getId)}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        })},[_vm._v(" Turn on/off FTP integration ")]),_c('div',[_c('v-switch',{staticClass:"notification-switch pt-1",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"id":"alarm-ftp-notification-switch","text":"","color":"secondary","inset":"","data-test-id":"ftp-smtp-on-off-switch","disabled":!_vm.isWriteEnabled},on:{"change":_vm.onToggleFtpStatus},model:{value:(_vm.isFtpEnabled),callback:function ($$v) {_vm.isFtpEnabled=$$v},expression:"isFtpEnabled"}})],1)])],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text text-subtitle-2",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }