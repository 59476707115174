<template>
  <div
    :style="{
      backgroundColor:
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkPrimaryColor
          : getColors.lightPrimaryColor
    }"
  >
    <div v-if="cameras && cameras.length === 0 && !clientLiveIsLoading">
      <NoCamera nodeType="client" />
    </div>

    <v-container fluid>
      <v-row
        no-gutters
        v-if="cameras && cameras.length !== 0"
        class="layout-per-row"
      >
        <!-- Empty v-col to take up remaining space and push v-select to the right -->
        <v-col cols="10"></v-col>

        <!-- v-col wrapping the v-select, aligned to the right -->
        <v-col cols="2" class="text-end">
          <v-select
            v-model="numberOfCameras"
            :items="[1, 2, 3, 4]"
            label="Layout per row"
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-select>
        </v-col>
      </v-row>
      <div class="loading" v-if="clientLiveIsLoading">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor
          }"
        >
          Loading Camera List...
        </p>
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>
      <div v-else>
        <v-row v-for="site in cameras" :key="site.siteId">
          <v-col cols="12" class="pt-3 pb-0">
            <p
              class="mt-3 mb-0"
              style="text-align: left; font-weight: 600; font-size: 16px"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor,
                fontSize: smallScreen ? '14px' : '16px'
              }"
            >
              {{ site.siteName }}
            </p>
          </v-col>
          <v-col
            v-for="camera in site.cameras"
            :key="camera.id"
            style="color: black"
            class="cameras-per-row-lanscape"
            cols="12"
            :sm="12 / numberOfCameras"
          >
            <LiveFrame
              :camera="camera"
              :numberOfItems="numberOfCameras"
              :level="'client'"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import NoCamera from '../../../components/error-pages/NoCamera.vue'
import LiveFrame from './LiveFrame.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'

const namespaceUser = { namespace: 'user' }
const namespaceClient = { namespace: 'client' }
const namespaceCamera = { namespace: 'camera' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    NoCamera,
    LiveFrame
  }
})
export default class LiveViewClient extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getClientCameras', namespaceClient) public clientCameras
  @Getter('getClientLiveIsLoading', namespaceClient) public clientLiveIsLoading
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public numberOfCameras: number = 4
  public isLoading: boolean = true
  public cameras: any[] | null = null

  public isMounted = false

  public get isUserAdmin() {
    return this.currentUser.role === 'Administrator'
  }

  public get smallScreen() {
    return isCompactView()
  }

  private mounted() {
    this.isLoading = true
    this.isMounted = true
  }

  @Watch('clientCameras')
  private watchClientCameras() {
    if (this.clientCameras) {
      // Filter the clientCameras based on the given criteria
      const filteredCameras = this.clientCameras.filter((item: any) => {
        if (item.isSiteHardwareDevice) {
          return (
            item.isAuthenticated !== false && item.isEdgeDeviceEnabled === true
          )
        } else {
          return item.isLiveStreamEnabled === true
        }
      })

      // Group the filtered cameras by siteId
      const groupedBySite = filteredCameras.reduce((acc: any, camera: any) => {
        const siteId = camera.siteId
        const siteName = camera.siteName
        const siteLimit = camera.edgeLiveStreamLimit
        const siteDocId = camera.siteFirestoreId

        if (!acc[siteDocId]) {
          acc[siteDocId] = {
            siteId,
            siteName,
            cameras: [],
            siteLimit: siteLimit
          }
        }

        acc[siteDocId].cameras.push(camera)

        return acc
      }, {} as any)

      // Process each site group
      Object.values(groupedBySite).forEach((siteGroup: any) => {
        // Sort cameras based on isEdgeCameraUnarmed property
        siteGroup.cameras.sort((a: any, b: any) => {
          const aUnarmed = a.isEdgeCameraUnarmed
          const bUnarmed = b.isEdgeCameraUnarmed

          if (aUnarmed === false) return -1
          if (bUnarmed === false) return 1
          if (aUnarmed === true) return 1
          if (bUnarmed === true) return -1
          return 0
        })

        // Apply site limit if available
        if (siteGroup.siteLimit > 0) {
          // Ensure we take cameras with `isEdgeCameraUnarmed` set to false first, then true
          let unarmedFalseCameras = siteGroup.cameras.filter(
            (camera: any) => camera.isEdgeCameraUnarmed === false
          )
          let unarmedTrueCameras = siteGroup.cameras.filter(
            (camera: any) => camera.isEdgeCameraUnarmed === true
          )
          // Concatenate the cameras based on priority
          let prioritizedCameras =
            unarmedFalseCameras.concat(unarmedTrueCameras)
          // Apply the site limit after prioritization
          siteGroup.cameras = prioritizedCameras.slice(0, siteGroup.siteLimit)
        }
      })

      this.cameras = Object.values(groupedBySite).map((site: any) => ({
        siteId: site.siteId,
        siteName: site.siteName,
        cameras: site.cameras
      }))
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
body {
  overflow: hidden;
}
.loading {
  color: black;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.alert {
  padding: 20px;
  background-color: #ff5252;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}

.camera-name {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
}
</style>

<style>
.grid-item-wrapper {
  width: 100%;
  height: 190px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-wrapper img,
.grid-item-wrapper video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover !important;
  object-position: center !important;
}

/* Styles for mobile devices in portrait mode */
@media only screen and (max-width: 480px) and (max-height: 950px) and (orientation: portrait) {
  .page-header {
    display: none;
  }
  .layout-per-row {
    display: none;
  }
}

/* Styles for mobile devices in landscape mode */
@media only screen and (max-width: 950px) and (max-height: 480px) and (orientation: landscape) {
  .page-header {
    display: none;
  }
  .layout-per-row {
    display: none;
  }
  .cameras-per-row-lanscape {
    flex: 0 0 50% !important; /* Forces the column to take up 50% width, equivalent to sm=6 */
    max-width: 50% !important; /* Ensures the max width is also 50% */
  }
}
</style>
