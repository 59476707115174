import { adminApi } from '@/services/backendServices/signedRequest'

export const createIncidentObservation = async (
  params?: Record<string, string>
) => {
  return await adminApi.request({
    method: 'PUT',
    url: `/incident-observation/${params.incidentId}`
  })
}
