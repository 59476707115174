<template>
  <div>
    <div class="my-2 pa-4">
      <!-- Camera IDs which throws more Incidents (first Ten) -->
      <GChart
        :key="currentUser.role"
        type="Sankey"
        :data="firstCameraIdData"
        :settings="sankeyChartSettings"
        :options="incidentCameraIdChartOptions"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    GChart
  }
})
export default class IncidentFirstCameraChart extends Vue {
  @Prop() public firstCameraIdData: any[]
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  public get textColors(): string {
    return this.currentUser.role === 'Administrator' &&
      this.isDarkModeToggleEnabled
      ? this.getColors.lightPrimaryColor
      : this.getColors.darkBlackColor
  }

  public get incidentCameraIdChartOptions(): any {
    return {
      sankey: {
        iterations: 0,
        node: {
          interactivity: true,
          colors: ['#514F4B'],
          label: {
            fontName: 'Poppins',
            color: this.textColors,
            fontSize: 12
          }
        },
        link: {
          colorMode: 'gradient',
          colors: ['#514F4B', '#E4E4E5'],
          textStyle: {
            color: 'red'
          }
        }
      },
      tooltip: {
        textStyle: {
          fontName: 'Poppins',
          fontSize: 12
        }
      }
    }
  }

  public sankeyChartSettings = {
    packages: ['sankey']
  }
}
</script>
