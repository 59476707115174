<template>
  <div>
    <v-system-bar
      class="system-bar"
      height="30"
      :color="
        workerStatus && workerStatus.state === 'online'
          ? 'green darken-1'
          : 'red darken-1'
      "
    >
      <span><NetworkInfo /></span>

      <span>
        <RoutineTest
          :downloadSpeed="downloadSpeedTest"
          :uploadSpeed="uploadSpeedTest"
          :testStatus="speedTestStatus"
          @run-test="onRunTest"
          @disable-speed-test="() => (speedTestStatus = 'completed')"
        />
        <RunSpeedTest
          :run-test="startTest"
          @test-completed="onTestCompleted"
          @download-speed="onDownloadSpeed"
          @upload-speed="onUploadSpeed"
        />
      </span>
    </v-system-bar>
    <v-progress-linear
      v-if="speedTestStatus === 'pending'"
      color="green"
      indeterminate
    ></v-progress-linear>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import NetworkInfo from '@/components/capability-evaluation/NetworkInfo.vue'
import RoutineTest from '@/components/capability-evaluation/RoutineTest.vue'
import RunSpeedTest from '@/components/capability-evaluation/RunSpeedTest.vue'

const namespaceUser = { namespace: 'user' }
const namespaceWorker = { namespace: 'worker' }

@Component({
  components: {
    NetworkInfo,
    RoutineTest,
    RunSpeedTest
  }
})
export default class WorkerSpeedTest extends Vue {
  @Prop() public timeStamp: { minutes: number; seconds: string }

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getWorkerStatus', namespaceWorker) public workerStatus
  @Getter('getDownloadSpeed', namespaceWorker) public getDownloadSpeed
  @Getter('getUploadSpeed', namespaceWorker) public getUploadSpeed

  @Action('bindWorkerStatus', namespaceWorker) public bindWorkerStatus
  @Action('unbindWorkerStatus', namespaceWorker) public unbindWorkerStatus
  @Action('subscribeToInternetSpeedCalculator', namespaceWorker)
  public subscribeToInternetSpeedCalculator
  @Action('unSubscribeFromInternetSpeedCalculator', namespaceWorker)
  public unSubscribeFromInternetSpeedCalculator

  public uploadSpeed: number = 0
  public downloadSpeed: number = 0
  public startTest: boolean = false
  public downloadSpeedTest: number = 0
  public uploadSpeedTest: number = 0
  public speedTestStatus: 'pending' | 'completed' | null = null

  get isWorker() {
    return this.currentUser?.role === 'Worker'
  }

  mounted() {
    this.internetSpeedTestInit()
  }

  private internetSpeedTestInit() {
    //Internet speed Actions and Getters
    // this.subscribeToInternetSpeedCalculator()
    this.setDownloadSpeed()
    this.setUploadSpeed()
  }

  @Watch('currentUser')
  public async watchCurrentUser() {
    if (this.currentUser) {
      if (this.isWorker) {
        this.bindWorkerStatus(this.currentUser?.id)
      }
    }
  }

  @Watch('getDownloadSpeed')
  public setDownloadSpeed() {
    const downloadSpeed = this.getDownloadSpeed
    this.downloadSpeed = downloadSpeed
  }

  @Watch('getUploadSpeed')
  public setUploadSpeed() {
    const uploadSpeed = this.getUploadSpeed
    this.uploadSpeed = uploadSpeed
  }

  public onRunTest() {
    this.startTest = true
    this.speedTestStatus = 'pending'
  }

  public onDownloadSpeed(speed: number) {
    this.downloadSpeedTest = speed
  }

  public onUploadSpeed(speed: number) {
    this.uploadSpeedTest = speed
  }

  public onTestCompleted() {
    this.startTest = false
    this.speedTestStatus = 'completed'
  }

  public beforeDestroy() {
    this.unbindWorkerStatus()
    this.unSubscribeFromInternetSpeedCalculator()
  }
}
</script>

<style scoped>
.system-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
