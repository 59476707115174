export const PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID
export const FIREBASE_CONFIGURATION_FILE =
  process.env.VUE_APP_FIREBASE_CONFIGURATION_FILE
export const CLOUD_FUNCTION_REGION = process.env.VUE_APP_CLOUD_FUNCTION_REGION
export const AUTH_DOMAIN = process.env.VUE_APP_AUTH_DOMAIN

export const CLOUD_BUCKET_PREFIX = process.env.VUE_APP_CLOUD_BUCKET_PREFIX

export const EAGLE_EYE_AUTH_URL = process.env.VUE_APP_EAGLE_EYE_AUTH_URL

export const SIA_SERVER_ADDRESS = process.env.VUE_APP_SIA_SERVER_ADDRESS

export const GEOCODING_API_KEY = process.env.VUE_APP_GEOCODING_API_KEY

export const BACKEND_API_URL = process.env.VUE_APP_BACKEND_API_URL

export const GIT_TAG = process.env.VUE_APP_GIT_TAG
export const NODE_ENV = process.env.NODE_ENV
export const BASE_URL = process.env.BASE_URL
export const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS

export const USE_LOCAL_STORAGE = process.env.VUE_APP_USE_LOCAL_STORAGE
export const BACKEND_EMULATOR = process.env.VUE_APP_BACKEND_EMULATOR

export const EMULATE_FUNCTIONS = process.env.VUE_APP_EMULATE_FUNCTIONS
export const EMULATE_FIRESTORE = process.env.VUE_APP_EMULATE_FIRESTORE
export const EMULATE_AUTH = process.env.VUE_APP_EMULATE_AUTH
export const EMULATE_DATABASE = process.env.VUE_APP_EMULATE_DATABASE
export const EMULATE_STORAGE = process.env.VUE_APP_EMULATE_STORAGE
