<template>
  <v-container fluid class="d-flex flex-column py-0 pl-0">
    <v-text-field
      v-model="values"
      outlined
      item-color="white"
      :background-color="
        isUserAdmin && isDarkModeToggleEnabled ? getColors.darkSectionColor : ''
      "
      :label="searchText"
      :placeholder="searchText"
      solo
      :color="
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkLowFrameColor
          : ''
      "
    >
      <template #prepend-item>
        <v-card
          flat
          width="100%"
          height="100%"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkFrameColor
                : '',
            position: 'absolute',
            top: '0px'
          }"
        />
      </template>
    </v-text-field>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component
export default class TextSearch extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop() public checked: string[]
  @Prop() public text: string
  @Prop() public searchText: string

  public values: string = ''

  // Watcher for the 'text' prop
  @Watch('text')
  onTextChanged(newText: string, oldText: string) {
    this.values = newText
  }

  // Watcher for the 'values'
  @Watch('values')
  onValueChanged(newText: string, oldText: string) {
    this.$emit('change', { event: newText })
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  private mounted() {
    this.values = this.text
  }

  public setFilter(event: string[]) {
    this.$emit('change', { event: event })
  }
}
</script>

<style scoped>
input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  -webkit-border-radius: 2px;
  border-radius: 3px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}

/* Remove box shadow due to v-autocomplete solo  */
:deep(.v-input__control > .v-input__slot) {
  box-shadow: none !important;
}
/* Remove message display text field of v-autocomplete */
:deep(.v-input__control > .v-text-field__details) {
  display: none;
}
</style>
