<template>
  <div>
    <div
      :class="[
        'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
      ]"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack,
          margin: '1rem 0 1rem 0'
        }"
        data-test-id="eagle-eye-title"
      >
        Eagle Eye
      </div>
      <div
        style="display: flex; align-items: center; margin: 1rem"
        v-if="!isCameraLevel"
      >
        <Button
          v-if="!getEagleEyeConnect.progress && eenClientId"
          :disabled="!isWriteEnabled"
          color="secondary black--text elevation-0"
          :btnText="isConnectedToEagleEye ? 'Reconnect' : 'Connect'"
          :class="['elevation-0']"
          :title="'Connect to Eagle Eye'"
          style="width: 8rem"
          @onButtonClick="onClickConnectEagleEye"
          data-test-id="eagle-eye-connect-button"
          ><v-icon slot="icon"> mdi-plus-circle-outline </v-icon></Button
        >
        <v-progress-circular
          v-else
          indeterminate
          size="24"
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <div>
      <div
        :style="{ color: getEagleEyeConnect.error ? 'red' : 'green' }"
        class="py-5 bottom-border"
        v-if="getEagleEyeConnect.message"
        data-test-id="eagle-eye-connect-message"
      >
        {{ getEagleEyeConnect.message }}
      </div>

      <template v-if="isConnectedToEagleEye">
        <div>
          <!-- Display alerts for accounts that are about to expire -->
          <div
            v-for="(account, index) in filteredEagleEyeAccounts"
            :key="index"
          >
            <v-alert
              v-if="isAboutToExpire(account.expires)"
              icon="$error"
              style="margin: 1rem 1rem 0 1rem; font-size: small"
              type="error"
              density="compact"
              :title="`Eagle Eye account ${account.vms_account} expires on ${account.expires}`"
              data-test-id="eagle-eye-account-alert"
              >{{
                expireMessage(account.vms_account, account.expires)
              }}</v-alert
            >

            <!-- Display account details -->
            <div class="py-5 pl-4 d-flex pr-3 bottom-border">
              <div
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkBlackColor,
                  flex: 1
                }"
                data-test-id="eagle-eye-account-details-div"
                class="d-flex align-start flex-column"
              >
                <p class="mb-2" data-test-id="eagle-eye-account-name-lable">
                  Name: {{ account.vms_account }}
                </p>
                <p data-test-id="eagle-eye-account-vms-label">
                  VMs Account: {{ account.vms_account }}
                </p>
                <p data-test-id="eagle-eye-account-expire-label">
                  Expires:
                  <span
                    :style="{
                      color: account?.isTokenValid === false ? 'red' : ''
                    }"
                  >
                    {{
                      account?.isTokenValid === false
                        ? 'Expired'
                        : new Date(account.expires).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            minute: '2-digit',
                            hour: '2-digit',
                            second: '2-digit'
                          })
                    }}
                  </span>
                </p>
              </div>

              <div class="d-flex align-end flex-column">
                <Button
                  v-if="
                    account?.isTokenValid === undefined || account?.isTokenValid
                  "
                  class="elevation-0 mb-2"
                  :btnText="
                    isCameraLevel ? 'Refresh Camera' : 'Refresh Cameras'
                  "
                  btnStyle="default"
                  @onButtonClick="onClickRefreshEENCameras(account)"
                  :disabled="!isWriteEnabled"
                  data-test-id="eagle-eye-refresh-button"
                >
                </Button>

                <Button
                  v-if="!isCameraLevel"
                  class="elevation-0"
                  btnText="Disconnect"
                  btnStyle="delete"
                  @onButtonClick="deleteEENAccount(account.vms_account)"
                  :disabled="!isWriteEnabled"
                  data-test-id="eagle-eye-disconnect-button"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <v-overlay v-if="getEagleEyeConnect.progress" :absolute="false">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import { functions } from '@/provider/firebase'
import { EAGLE_EYE_AUTH_URL } from '@/utils/EnvMapping'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

const deleteEENAccountFunction = functions.httpsCallable('deleteEENAccount')
const refreshEENcameras = functions.httpsCallable('refreshEENcameras')
const refreshEENcamera = functions.httpsCallable('refreshEENcamera')

@Component({
  components: {
    Button
  }
})
export default class EagleEyeConfiguration extends Vue {
  @Prop() configuration: any
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() isCameraLevel?: boolean
  @Action('syncCurrentUser', namespaceUser) public syncCurrentUser
  @Action('updateClientForEagleEye', namespaceUser)
  public updateClientForEagleEye: (payload: {
    clientId: string
    userId: string
  }) => Promise<void>
  @Action('autoClearClientUserDoc', namespaceUser)
  public autoClearClientUserDoc: (userId: string) => Promise<void>

  @Action('syncConnectedEENAccounts', namespaceUser)
  public syncConnectedEENAccounts
  @Action('getEENClientId', namespaceConfig) public getEENClientId
  @Mutation('removeEagleEyeAccount', namespaceUser)
  public removeEagleEyeAccount: (vms_account: string) => Promise<void>

  @Getter('getEagleEyeAccounts', namespaceUser)
  public getEagleEyeAccounts: any[]
  @Getter('getEagleEyeConnect', namespaceUser) public getEagleEyeConnect: {
    progress: boolean
    message: string
    error: boolean
  }
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Mutation('setEagleEyeConnect', namespaceUser)
  public setEagleEyeConnect: any

  public filteredEagleEyeAccounts: {
    vms_account: string
    expires: Date
    name: string
    isTokenValid?: boolean
  }[] = []
  public isConnectedToEagleEye = false

  public snackbar: boolean = false
  public snackbarMessage: string = ''

  public async mounted() {
    this.syncForUpdates()
    this.setEagleEyeConnect({
      progress: false,
      message: ''
    })
    this.eenClientId = await this.getEENClientId()
  }
  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public isAboutToExpire(expires: Date) {
    // if already expired, return false
    if (new Date().getTime() > expires.getTime()) {
      return true
    }
    // if expires in 7 days or less, return true
    const diff = Math.abs(new Date().getTime() - expires.getTime())
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    return diffDays <= 7
  }

  public expireMessage(vms_account: string, expires: Date) {
    // if already expired,
    if (new Date().getTime() > expires.getTime()) {
      return `Eagle Eye account ${vms_account} has expired and broken the integration. Please connect again to continue using the integration.`
    }
    // if expires in 7 days or less
    const diff = Math.abs(new Date().getTime() - expires.getTime())
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    if (diffDays <= 7) {
      return `Eagle Eye account ${vms_account} will expire in ${diffDays} days. Please connect again to avoid any disruption.`
    }
  }

  @Watch('getEagleEyeAccounts')
  private watchEagleEyeAccounts() {
    this.filteredEagleEyeAccounts = this.isCameraLevel
      ? this.getEagleEyeAccounts?.filter(
          (item) => this.configuration?.siteId === item?.vms_account
        )
      : this.getEagleEyeAccounts
    this.isConnectedToEagleEye = this.getEagleEyeAccounts.length > 0
  }

  @Watch('selectedUserId')
  @Watch('clientId')
  private async watchClientChange() {
    await this.updateEagleEyeComponent()
  }

  private async syncForUpdates() {
    this.syncConnectedEENAccounts(this.$route.params?.clientId)
    this.syncCurrentUser(this.currentUser.id)
  }

  private readonly redirectUri = `${window.location.origin}/eagle-eye`
  public eenClientId: string | null = null

  private addEENAccount() {
    const queryParams = {
      scope: 'vms.all',
      client_id: this.eenClientId,
      response_type: 'code',
      redirect_uri: this.redirectUri
    }
    const url = new URL(EAGLE_EYE_AUTH_URL)
    Object.keys(queryParams).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    )

    // open new window not a new tab and middle of the screen
    const width = 500
    const height = 600
    const left = (window.screen.width - width) / 2 + window.screenLeft
    const top = (window.screen.height - height) / 2 + window.screenTop

    const newWindow = window.open(
      url.toString(),
      '_blank',
      `width=${width},height=${height},left=${left},top=${top}`
    )

    let intervalId: any

    // check if the window was successfully opened
    if (newWindow === null || typeof newWindow === 'undefined') {
      this.popSnackbarMessage(
        'Popup blocked. Please allow popups for this website'
      )
    } else {
      // Start an interval to check whether the window is closed
      intervalId = setInterval(async () => {
        if (newWindow.closed) {
          if (this.getEagleEyeConnect.progress) {
            await this.setEagleEyeConnect({
              progress: false,
              message: '',
              error: false
            })
            this.updateEagleEyeComponent()
          }
          clearInterval(intervalId)
          this.autoClearClientUserDoc(this.currentUser.id)
          this.syncCurrentUser(this.currentUser.id)
        }
      }, 500) // Check every 500ms
    }
  }

  private async updateEagleEyeComponent() {
    await this.syncConnectedEENAccounts(this.$route.params?.clientId)
  }

  public async deleteEENAccount(vms_account: string) {
    try {
      this.popSnackbarMessage(`Deleting Eagle Eye account ${vms_account}...`)
      await deleteEENAccountFunction({
        vms_account,
        clientId: this.$route.params?.clientId
      })
      await this.removeEagleEyeAccount(vms_account)
      this.popSnackbarMessage(`Eagle Eye account ${vms_account} disconnected`)
    } catch (error) {
      this.popSnackbarMessage((error as any).message)
    } finally {
      await this.updateEagleEyeComponent()
    }
  }

  public async onClickRefreshEENCameras(account: { vms_account: string }) {
    const cameraNoun = this.isCameraLevel ? 'camera' : 'cameras'
    this.popSnackbarMessage(`Refreshing Eagle Eye ${cameraNoun} ...`)
    try {
      if (this.isCameraLevel) {
        await refreshEENcamera({
          vms_account: account.vms_account,
          clientId: this.$route.params.clientId,
          cameraId: this.configuration.cameraId
        })
      } else {
        await refreshEENcameras({
          vms_account: account.vms_account,
          clientId: this.$route.params.clientId
        })
      }
      this.popSnackbarMessage(`Eagle Eye ${cameraNoun} refreshed successfully`)
    } catch (error) {
      this.popSnackbarMessage((error as any).message)
    }
  }

  public get themeColor(): string {
    if (this.currentUser.role === 'Administrator') {
      return this.getColors.lightPrimaryColor
    } else {
      return this.getColors.darkBlackColor
    }
  }

  public async onClickConnectEagleEye() {
    this.popSnackbarMessage('Connecting to Eagle Eye')
    this.setEagleEyeConnect({
      progress: true,
      message: 'Connecting to Eagle Eye ...'
    })
    this.updateClientForEagleEye({
      clientId: this.$route.params.clientId,
      userId: this.currentUser.id
    })
    this.addEENAccount()
  }

  private popSnackbarMessage(message: string) {
    this.snackbar = false
    this.snackbar = true
    this.snackbarMessage = message
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
  background-color: transparent;
}

.bottom-border {
  border-bottom: solid #dadada 1px;
}

.card-style.active {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
</style>
