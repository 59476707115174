import {
  todosCollection,
  todosProvider,
  functions,
  storage,
  workerQueueCollection,
  firestore
} from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { createIncidentObservation } from '@/services/api-admin/incident-observation/incident-observation'

const RECENT_LIMIT: number = 10
const PAGE_SIZE_HC: number = 12

const actions = {
  async getFinalResult({ commit }: any, id: string): Promise<any> {
    try {
      const response = await todosProvider.getFinalResult(id)
      return response
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  setWorkerAssessment: firestoreAction(
    (
      context,
      payload: {
        workerId: any
        vehicleMovement: 'alarm' | 'no-alarm' | 'unsure'
        personAssessment: 'alarm' | 'no-alarm' | 'unsure'
        docId: string
      }
    ) => {
      const { workerId, vehicleMovement, personAssessment, docId } = payload

      const timestamp = firebase.firestore.Timestamp.now()
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

      let finalAssessment: 'alarm' | 'no-alarm' | 'unsure' = 'no-alarm'
      switch (true) {
        case personAssessment === 'alarm' || vehicleMovement === 'alarm':
          finalAssessment = 'alarm'
          break
        case personAssessment === 'unsure' || vehicleMovement === 'unsure':
          finalAssessment = 'unsure'
          break
        default:
          finalAssessment = 'no-alarm'
      }

      const worker = {
        updatedAt: serverTimestamp,
        workerId,
        isDisplayed: true,
        personAssessment,
        vehicleMovement,
        displayedAt: serverTimestamp
      }

      const finalResult = {
        personAssessment,
        vehicleMovement,
        finalAssessment,
        updatedAt: serverTimestamp
      }

      const value = {
        completed: true,
        flag: 'completed',
        completedAt: serverTimestamp,
        completedDate: moment(timestamp.toDate()).format('YYYY-MM-DD'),
        worker,
        finalResult,
        isAIOnly: false
      }
      return todosCollection.doc(docId).set(value, { merge: true })
    }
  ),
  removeTodoFromQueue: firestoreAction(
    (context, payload: { workerId: string; docId: string }) => {
      return workerQueueCollection.doc(payload.docId).update({
        todos: firebase.firestore.FieldValue.arrayRemove(payload.docId)
      })
    }
  ),
  setWorker: firestoreAction(
    (context, payload: { workerId: string; docId: string }) => {
      const worker = {
        isDisplayed: true,
        workerId: payload.workerId,
        displayedAt: firebase.firestore.FieldValue.serverTimestamp()
      }
      return todosCollection.doc(payload.docId).set({ worker }, { merge: true })
    }
  ),
  reportTodo: firestoreAction(
    (
      context,
      payload: {
        todoId: string
        person: boolean
        vehicle: boolean
        approved: boolean
        unsure: boolean
        lastUpdated: Date
        user: any
      }
    ) => {
      const { todoId, ...reportDetails } = payload
      const isLabeled =
        reportDetails.approved ||
        reportDetails.person ||
        reportDetails.vehicle ||
        reportDetails.unsure
      if (isLabeled) {
        return todosCollection.doc(payload.todoId).update({
          report: reportDetails,
          isLabeled: isLabeled
        })
      } else {
        return todosCollection.doc(payload.todoId).update({
          report: {},
          isLabeled: isLabeled
        })
      }
    }
  ),
  setCompleted: firestoreAction((context, payload: string) => {
    return todosCollection.doc(payload).update({
      flag: 'completed',
      completed: true
    })
  }),
  setCompletedAt: firestoreAction((context, payload: string) => {
    try {
      return todosCollection.doc(payload).update({
        completed: true,
        completedAt: firebase.firestore.Timestamp.now()
      })
    } catch (error) {
      console.error('Error in setCompletedAt', error)
      throw error
    }
  }),
  setDisagreed({ commit }: any, todo: any): void {
    todosProvider
      .setDisagreed(todo)
      .then()
      .catch((error) => {
        console.error(error)
      })
  },
  setFlag({ commit }: any, todo: any): void {
    todosProvider
      .setFlag(todo)
      .then()
      .catch((error) => {
        console.error(error)
      })
  },
  bindTodos: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef(
      'todos',
      todosCollection
        .where('completed', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(RECENT_LIMIT),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  bindTodosSelective: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef(
      'todosWorkerSpace',
      todosCollection
        .where('completed', '==', false)
        .where('aiResult.finalAiResult', '==', 'unsure')
        .where('deadline', '>', new Date())
        .orderBy('deadline', 'asc')
        .orderBy('createdAt', 'asc')
        .limit(RECENT_LIMIT),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  unbindTodos: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('todos')
  }),
  async getTodoById({ commit }: any, id: string): Promise<any> {
    try {
      const response = await todosProvider.getTodoById(id)
      return response
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  async getTodosByCameraRef({ commit }: any, todo: any): Promise<any> {
    try {
      const { cameraId, fileMetadata } = todo || {}
      if (!cameraId || fileMetadata?.mediaType === 'video') {
        commit('setTodosByCameraId', [])
      } else {
        const todosByCameraId =
          await todosProvider.getLastThreeTodosByCameraRef(todo)
        commit('setTodosByCameraId', todosByCameraId.reverse())
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  },
  async fetchLastTodoByCameraRef(
    { commit }: any,
    cameraDocId: string
  ): Promise<any> {
    const todo = await todosProvider.fetchLastTodoByCameraRef(cameraDocId)
    commit('setLastTodoByCameraRef', todo)
  },
  async getDownloadUrl(
    { commit }: any,
    payload: string | { url: string; fallback?: string }
  ): Promise<string | null> {
    commit('setDownloadUrl', null)
    let ref: string | null = null
    let fallbackURL: string | undefined

    // Determine ref and fallbackURL based on payload type
    if (typeof payload === 'string') {
      ref = payload
      if (!ref) {
        console.error('No reference provided for download URL')
        commit('setDownloadUrl', null)
        return null
      }
    } else {
      ref = payload.url
      fallbackURL = payload.fallback
    }

    try {
      const url = await todosProvider.fetchDownloadUrl(ref, 5 * 1000, 2)
      commit('setDownloadUrl', url)
      return url
    } catch (error) {
      console.error('Failed to fetch download URL:', error)
      if (fallbackURL) {
        const url = await todosProvider
          .fetchDownloadUrl(fallbackURL, 5 * 1000, 2)
          .catch(() => {})
        commit('setDownloadUrl', url)
        return fallbackURL
      } else {
        commit('setDownloadUrl', null)
        return null
      }
    }
  },
  async uploadFromSandbox({ commit }: any, data: any): Promise<any> {
    const uploadFromSandbox = functions.httpsCallable('uploadFromSandbox')
    return await uploadFromSandbox(data)
  },
  async uploadTask({ commit }: any, payload: any): Promise<any> {
    commit('setUploadedTask', null)
    let uploadedTask = storage.ref().child(payload.path).put(payload.file, {
      contentType: payload.file.type
    })
    commit('setUploadedTask', uploadedTask)
  },
  async setHealthCheckData(
    { commit }: any,
    payload: { todoId: string; healthCheckData: any }
  ): Promise<void> {
    const { todoId, healthCheckData } = payload
    await todosProvider.setHeathCheck(todoId, healthCheckData)
  },
  async getLocalMediaUrl({ commit }: any, filePath: any) {
    commit('setDownloadUrl', null)
    const response = await todosProvider.fetchLocalMediaUrl(filePath)
    commit('setDownloadUrl', response.data.media)
  },
  bindAllIncidentHealthChecks: async (
    { commit, state }: any,
    payload: {
      startDate: Date
      endDate: Date
      filters: string[]
      isLoadMore: boolean
    }
  ) => {
    try {
      const { startDate, endDate, filters, isLoadMore } = payload

      if (!state.lastVisibleIncidentHealthCheck || !isLoadMore) {
        commit('setIncidentHealthChecks', [])
        commit('setLastVisibleIncidentHealthCheck', null)
        commit('setIncidentHealthChecksLoading', true)
        commit('setHasMoreIncidentHealthChecks', true)
      } else {
        commit('setIncidentHcLoadMoreLoading', true)
      }

      const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate)
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate)

      let query = todosCollection
        .where('healthCheck.createdAt', '>=', startTimestamp)
        .where('healthCheck.createdAt', '<=', endTimestamp)

      const filteredQuery = await todosProvider.applyHealthCheckFilters(
        query,
        filters
      )

      let finalQuery = filteredQuery
        .orderBy('healthCheck.createdAt', 'desc')
        .limit(PAGE_SIZE_HC)

      if (state.lastVisibleIncidentHealthCheck) {
        finalQuery = finalQuery.startAfter(state.lastVisibleIncidentHealthCheck)
      }

      const snapshot = await finalQuery.get()
      const healthCheckDocuments = []
      snapshot.forEach((doc) => {
        const docData = doc.data()
        // change the media path if document is Unarmed
        const isUnarmed = docData.isUnarmed

        const data = {
          id: doc.id,
          healthCheck: docData.healthCheck,
          processedFile: isUnarmed
            ? docData.originalFile
            : docData.processedFile,
          mediaType: docData.fileMetadata.mediaType,
          clientRef: docData.clientRef,
          siteRef: docData.siteRef,
          cameraRef: docData.cameraRef
        }
        healthCheckDocuments.push(data)
      })
      if (healthCheckDocuments.length > 0) {
        commit('appendIncidentHealthChecks', healthCheckDocuments)
        commit(
          'setLastVisibleIncidentHealthCheck',
          snapshot.docs[snapshot.docs.length - 1]?.data()
        )
      }

      commit(
        'setHasMoreIncidentHealthChecks',
        snapshot.docs.length > PAGE_SIZE_HC
      )
      commit('setIncidentHcLoadMoreLoading', false)
      commit('setIncidentHealthChecksLoading', false)
    } catch (error) {
      console.error('Error binding all incident health checks:', error)
    }
  },
  bindNodeIncidentHealthChecks: async (
    { commit, state }: any,
    payload: {
      userId: string
      nodeType: string
      nodeId: string
      startDate: Date
      endDate: Date
      filters: string[]
      isLoadMore: boolean
    }
  ) => {
    try {
      const {
        userId,
        nodeType,
        nodeId,
        startDate,
        endDate,
        filters,
        isLoadMore
      } = payload

      if (!state.lastVisibleIncidentHealthCheck || !isLoadMore) {
        commit('setIncidentHealthChecks', [])
        commit('setLastVisibleIncidentHealthCheck', null)
        commit('setIncidentHealthChecksLoading', true)
        commit('setHasMoreIncidentHealthChecks', true)
      } else {
        commit('setIncidentHcLoadMoreLoading', true)
      }

      const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate)
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate)

      let query = todosCollection
        .where('healthCheck.createdAt', '>=', startTimestamp)
        .where('healthCheck.createdAt', '<=', endTimestamp)
        .orderBy('healthCheck.createdAt', 'desc')
        .limit(PAGE_SIZE_HC + 1)

      const filteredQuery = await todosProvider.applyHealthCheckFilters(
        query,
        filters
      )

      const permittedCameraRefPaths = await todosProvider.getAccessedCameraRefs(
        userId,
        nodeType,
        nodeId
      )

      let healthCheckDocuments = []

      const docCollection = []
      const lastVisibleIncidentHealthChecks = []
      const lastVisibleIncidentHealthChecksState =
        state.lastVisibleIncidentHealthCheck ?? []

      while (permittedCameraRefPaths.length) {
        const batch = permittedCameraRefPaths
          .splice(0, 15)
          .map((ref) => firestore.doc(ref))

        let finalQuery = filteredQuery

        if (lastVisibleIncidentHealthChecksState.length > 0) {
          const startAfterDoc = lastVisibleIncidentHealthChecksState.shift()
          if (startAfterDoc) {
            finalQuery = finalQuery.startAfter(startAfterDoc)
          }
        }

        let docs = []
        docs = await finalQuery
          .where('cameraRef', 'in', [...batch])
          .get()
          .then((results) =>
            results.docs.map((doc) => {
              const {
                healthCheck,
                originalFile,
                processedFile,
                fileMetadata,
                clientRef,
                siteRef,
                cameraRef,
                isUnarmed
              } = doc.data()

              return {
                id: doc.id,
                healthCheck: healthCheck,
                processedFile: isUnarmed ? originalFile : processedFile,
                mediaType: fileMetadata.mediaType,
                clientRef: clientRef,
                siteRef: siteRef,
                cameraRef: cameraRef,
                docRef: doc
              }
            })
          )

        docCollection.push(docs)
      }

      healthCheckDocuments = docCollection.flat().sort((a, b) => {
        if (a.healthCheck.createdAt.seconds > b.healthCheck.createdAt.seconds)
          return -1
        return 1
      })

      const hasMoreHealthChecks: boolean =
        healthCheckDocuments.length > PAGE_SIZE_HC

      healthCheckDocuments = healthCheckDocuments.slice(0, PAGE_SIZE_HC)

      docCollection.forEach((collection) => {
        if (collection.length < 1) {
          lastVisibleIncidentHealthChecks.push({})
        }

        for (let index = 0; index < collection.length; index++) {
          const element = collection[index]

          if (healthCheckDocuments.findIndex((e) => e.id == element.id) < 0) {
            if (index == 0) {
              lastVisibleIncidentHealthChecks.push({})
              break
            }
            lastVisibleIncidentHealthChecks.push(collection[index - 1].docRef)
            break
          }

          // if document includes all the elements
          if (index == collection.length - 1) {
            lastVisibleIncidentHealthChecks.push(collection[index].docRef)
          }
        }
      })

      if (healthCheckDocuments.length > 0) {
        commit('appendIncidentHealthChecks', healthCheckDocuments)
        commit(
          'setLastVisibleIncidentHealthCheck',
          lastVisibleIncidentHealthChecks
        )
      }
      commit('setHasMoreIncidentHealthChecks', hasMoreHealthChecks)
      commit('setIncidentHcLoadMoreLoading', false)
      commit('setIncidentHealthChecksLoading', false)
    } catch (error) {
      console.error(`Error binding node incident health checks: `, error)
    }
  },
  async fetchZoneByIncident({ commit }: any, incidentId: string) {
    return await todosProvider.getZonesForIncident(incidentId)
  },
  async createIncidentObservationLink({ commit }: any, incidentId: string) {
    return await createIncidentObservation({ incidentId })
  }
}

export default {
  ...actions
}
