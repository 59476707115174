var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-style"},[_c('div',{class:[
      { active: _vm.isExpanded },
      'text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"smtp-expand-title"}},[_vm._v(" SMTP ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"smtp-expand-icon"}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pr-3 pl-4 py-4 form"},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-host-label"}},[_vm._v(" Host: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-host-click-to-copy"},domProps:{"textContent":_vm._s('smtp.promiseq.com')},on:{"click":function($event){return _vm.onClickCopy('smtp.promiseq.com')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-port-label"}},[_vm._v(" Port: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-port-click-to-copy"},domProps:{"textContent":_vm._s('587')},on:{"click":function($event){return _vm.onClickCopy('587')}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-username-label"}},[_vm._v(" Username: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-username-click-to-copy"},domProps:{"innerHTML":_vm._s(_vm.projectId)},on:{"click":function($event){return _vm.onClickCopy(`${_vm.projectId}`)}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-password-label"}},[_vm._v(" Password: ")]),_c('v-select',{staticClass:"text-caption ml-1",staticStyle:{"max-width":"50%","height":"40px"},attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"append-icon":"mdi-chevron-down","content-class":"elevation-0","items":_vm.subscriptionKeys,"outlined":"","hide-details":"true","dense":"","data-test-id":"smtp-password-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                backgroundColor:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.darkFrameColor
                    : '',
                position: 'absolute',
                top: '0px'
              }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : '',
                margin: 'auto',
                'font-size': '13px'
              })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1732385462),model:{value:(_vm.selectedSmtpPassword),callback:function ($$v) {_vm.selectedSmtpPassword=$$v},expression:"selectedSmtpPassword"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"ripple":false,"text":"","icon":"","data-test-id":"smtp-password-copy-btn"},on:{"click":function($event){return _vm.onClickCopy(_vm.selectedSmtpPassword)}}},[_c('v-icon',{staticClass:"list-custom-icon",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_vm._v(_vm._s('mdi-content-copy'))])],1)],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"text-left tooltip",staticStyle:{"display":"flex"}},[_c('p',{staticClass:"mb-0",staticStyle:{"margin-right":"5px"},style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"data-test-id":"smtp-sender-address-label"}},[_vm._v(" Sender Address: ")]),_c('div',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-sender-address-click-to-copy"},domProps:{"textContent":_vm._s(_vm.getSmtpSenderAddress)},on:{"click":function($event){return _vm.onClickCopy(_vm.getSmtpSenderAddress)}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-receiver-address-label"}},[_vm._v(" Receiver Address: "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-receiver-address-click-to-copy"},domProps:{"textContent":_vm._s(_vm.getSmtpReceiverAddress)},on:{"click":function($event){return _vm.onClickCopy(_vm.getSmtpReceiverAddress)}}}),_c('span',{staticClass:"tooltip-copy-text"},[_vm._v("Click To Copy")])])])],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left tooltip",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"smtp-security-label"}},[_vm._v(" Security "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-security-tls"}},[_vm._v("TLS")]),_vm._v(" & "),_c('code',{staticClass:"pointer",attrs:{"data-test-id":"smtp-security-starttls"}},[_vm._v("STARTTLS")])])])],1),(_vm.isUserAdmin)?_c('v-row',{staticClass:"mb-4 regex-patterns-block",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4 regex-patterns-header",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left tooltip font-weight-bold",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"data-test-id":"regex-patterns-label"}},[_vm._v(" Regex Patterns ")])]),_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              }),attrs:{"data-test-id":"regex-pattern-label-email-subject"}},[_vm._v(" Email Subject ")])]),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Pattern","outlined":"","error-messages":_vm.isInvalidBodyRegexPattern ? 'Invalid Regex Pattern' : '',"error":_vm.isInvalidSubjectRegexPattern,"data-test-id":"regex-pattern-input-email-subject-pattern"},model:{value:(_vm.subjectRegexPattern),callback:function ($$v) {_vm.subjectRegexPattern=$$v},expression:"subjectRegexPattern"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Example Subject","outlined":"","hide-details":"true","persistent-hint":"","data-test-id":"regex-pattern-input-example-email-subject"},model:{value:(_vm.exampleEmailSubject),callback:function ($$v) {_vm.exampleEmailSubject=$$v},expression:"exampleEmailSubject"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkPrimaryColor
              }),attrs:{"data-test-id":"regex-pattern-label-email-body"}},[_vm._v(" Email Body ")])]),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Pattern","outlined":"","hide-details":!_vm.isInvalidBodyRegexPattern,"error-messages":_vm.isInvalidBodyRegexPattern ? 'Invalid Regex Pattern' : '',"error":_vm.isInvalidBodyRegexPattern,"data-test-id":"regex-pattern-input-email-body-pattern"},model:{value:(_vm.bodyRegexPattern),callback:function ($$v) {_vm.bodyRegexPattern=$$v},expression:"bodyRegexPattern"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Example Body","outlined":"","hide-details":"true","data-test-id":"regex-pattern-input-example-email-body"},model:{value:(_vm.exampleEmailBody),callback:function ($$v) {_vm.exampleEmailBody=$$v},expression:"exampleEmailBody"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"text-left red--text",style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : _vm.getColors.darkBlackColor
              }),attrs:{"data-test-id":"regex-pattern-warning-msg-email-body"}},[_vm._v(" *Example Email Content is required to match the pattern parts with Camera Id and Site Id ")])]),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"items":_vm.matchedAllRegexParts,"label":"Site Id","outlined":"","hide-details":"true","disabled":!_vm.isWriteEnabled,"return-object":"","data-test-id":"regex-pattern-input-site-id-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                    backgroundColor:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  })},[_vm._v(" "+_vm._s(item.value)+" - "+_vm._s(item.type)+" ")])]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.value)+" - "+_vm._s(item.type)+" ")]}}],null,false,8691845),model:{value:(_vm.selectedSiteRegexPart),callback:function ($$v) {_vm.selectedSiteRegexPart=$$v},expression:"selectedSiteRegexPart"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"items":_vm.matchedAllRegexParts,"label":"Camera Id","outlined":"","hide-details":"true","return-object":"","disabled":!_vm.isWriteEnabled,"data-test-id":"regex-pattern-input-camera-id-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                    backgroundColor:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  })},[_vm._v(" "+_vm._s(item.value)+" - "+_vm._s(item.type)+" ")])]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.value)+" - "+_vm._s(item.type)+" ")]}}],null,false,8691845),model:{value:(_vm.selectedCameraRegexPart),callback:function ($$v) {_vm.selectedCameraRegexPart=$$v},expression:"selectedCameraRegexPart"}})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"font-weight-bold text-left notification-title pl-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"smtp-switch-label"}},[_vm._v(" Turn on/off SMTP integration ")]),_c('div',[_c('v-switch',{staticClass:"notification-switch pt-1",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"id":"alarm-ftp-notification-switch","text":"","color":"secondary","inset":"","disabled":!_vm.isWriteEnabled,"data-test-id":"smtp-switch"},on:{"change":_vm.onToggleSmtpStatus},model:{value:(_vm.isSmtpEnabled),callback:function ($$v) {_vm.isSmtpEnabled=$$v},expression:"isSmtpEnabled"}})],1)])],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text text-subtitle-2",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }