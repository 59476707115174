var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-style",staticStyle:{"padding-right":"19px","padding-left":"19px","padding-top":"19px","padding-bottom":"19px"}},[_c('div',{staticClass:"form-item"},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"contact-person-label"}},[_vm._v(" Contact Person ")])]),_c('v-text-field',{staticStyle:{"margin-top":"-20px","font-size":"15px"},attrs:{"placeholder":"Contact Person","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"readonly":!_vm.isWriteEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"contact-person-input"},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}})],1),_c('div',{staticClass:"form-item",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('p',{staticClass:"font-weight-bold text-left",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"contact-number-label"}},[_vm._v(" Contact Number ")])]),_c('VuePhoneNumberInput',{attrs:{"defaultCountryCode":_vm.defaultCountryCode,"placeholder":"Contact Number","hide-details":"true","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"readonly":!_vm.isWriteEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"type":"tel","autocomplete":"tel","error-messages":_vm.contactNoError,"error":_vm.contactNoError.length > 0,"data-test-id":"contact-number-input"},on:{"change":(value) => _vm.onChangeContactNo(value),"update":_vm.onPhoneNumberUpdate},model:{value:(_vm.contactNo),callback:function ($$v) {_vm.contactNo=$$v},expression:"contactNo"}}),_c('div',[_c('p',{staticClass:"font-weight-bold text-left pt-4 mb-1",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"contact-message-label"}},[_vm._v(" Message (Optional) ")])]),_c('v-textarea',{attrs:{"dense":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"outlined":"","placeholder":_vm.messagePlaceholder,"readonly":!_vm.isWriteEnabled,"disabled":_vm.isToggleDisabled || !_vm.isWriteEnabled,"data-test-id":"contact-message-input"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-switch',{attrs:{"disabled":!_vm.contactNo || _vm.isToggleDisabled,"label":"Receive call notifications","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"contact-call-switch"},model:{value:(_vm.isCallEnabled),callback:function ($$v) {_vm.isCallEnabled=$$v},expression:"isCallEnabled"}}),_c('v-switch',{attrs:{"disabled":!_vm.contactNo || _vm.isToggleDisabled,"label":"Receive SMS notifications","color":"secondary","inset":"","hide-details":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"contact-sms-switch"},model:{value:(_vm.isSmsEnabled),callback:function ($$v) {_vm.isSmsEnabled=$$v},expression:"isSmsEnabled"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }