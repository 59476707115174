var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px","text-xs-right":"","persistent":""},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',{staticStyle:{"padding":"10px"},style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor,
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      })},[_c('v-card',{style:({ backgroundColor: _vm.error ? '#FF4444' : '#4BB543' })},[_c('v-card-title',{staticClass:"text-subtitle-1",style:({ color: 'white' })},[(!_vm.error)?_c('span',[_vm._v("Successfully added the collaborator and email has been sent")]):_c('span',[_vm._v("An Error Occured during adding the collaborator")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }