<template>
  <div class="gallery-element">
    <div class="px-2">
      <div class="gallery-container">
        <div id="gallery-element-container" class="gallery-element-container">
          <v-progress-circular
            indeterminate
            v-if="!isTodoLoaded"
            color="amber"
            class="loader"
          ></v-progress-circular>
          <div
            id="gallery-element-video-wrapper"
            class="gallery-element-wrapper gallery-element-video-wrapper"
            v-if="isMediaVideo && mediaUrl !== null"
          >
            <video
              id="todo-video"
              class="video-wrapper"
              autoplay
              muted
              loop
              @loadstart="setPlaybackSpeed(1.75)"
              @canplaythrough="onTodoLoad(true)"
              @timeupdate="setCurrentTime()"
            >
              <source :src="mediaUrl" :contain="true" type="video/mp4" />
            </video>
            <v-progress-linear
              class="video-progress-bar"
              rounded
              height="15px"
              background-color="#FFFFFF"
              color="#FFD42A"
              :value="currentTime"
            ></v-progress-linear>
            <WorkerTodoTimer :timeStamp="timeStamp" />
          </div>
          <div v-if="!isMediaVideo && mediaUrl !== null">
            <div
              class="img-magnifier-container gallery-element-wrapper"
              @mousewheel="changeZoomLevel"
              @click="changeZoomLevel"
            >
              <div
                id="img-magnifier-glass"
                :style="
                  isMagnifierEnabled
                    ? 'visibility : visible'
                    : 'visibility : hidden'
                "
              >
                {{ currentZoomLevel }}x
              </div>
              <img
                id="todo-image"
                class="image-wrapper"
                @load="handleImageLoad"
                :contain="true"
                :src="mediaUrl"
                alt="todo-image"
              />
              <WorkerTodoTimer :timeStamp="timeStamp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import WorkerTodoTimer from '../worker-space/WorkerTodoTimer.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceTodos = { namespace: 'todos' }
@Component({
  components: {
    WorkerTodoTimer
  }
})
export default class GalleryElement extends Vue {
  @Prop() public todo!: any
  @Prop() public viewPortSize!: number
  @Prop() public mode!: String
  @Prop() public isTodoLoaded: boolean
  @Prop() public isUserAdmin: boolean
  @Prop() public onTodoLoad: (isVisible: boolean) => void
  @Prop() public onFirstLoop: (isVideoLooped: boolean) => void
  @Prop() public isMagnifierEnabled: boolean
  @Prop() public timeStamp: { minutes: number; seconds: string }
  @Prop() public todosByCameraId!: any[]
  @Prop() public selectedImage!: number

  @Action('getDownloadUrl', namespaceTodos) public getDownloadUrl
  @Getter('getDownloadUrl', namespaceTodos) public downloadUrl

  private DEFAULT_ZOOM_LEVEL = 2
  private MAX_ZOOM_LEVEL = 5
  private ZOOM_INCREMENT = 1
  public currentZoomLevel = this.DEFAULT_ZOOM_LEVEL
  public mediaUrl: string = null
  public index: number = 0
  public currentImage: string = null

  public currentTime: number = 0
  public isMediaVideo: boolean =
    this.todo?.mediaType === 'video' ||
    this.todo?.fileMetadata?.mediaType === 'video'

  public async mounted() {
    await this.getMediaUrl()
  }

  @Watch('currentImage')
  public watchCurrentImage() {
    this.$emit('activeImage', this.index)
  }

  @Watch('index')
  public watchIndex() {
    if (this.todosByCameraId && this.todosByCameraId.length) {
      this.mediaUrl = this.todosByCameraId[this.index].originalFile.url
      this.currentImage = this.mediaUrl
    }
  }

  @Watch('selectedImage')
  public watchSelectedImage() {
    this.index = this.selectedImage
  }

  private async getMediaUrl() {
    try {
      const filePath =
        this.todo?.fileMetadata?.mediaType === 'video' &&
        this.todo?.processedFile
          ? this.todo.processedFile.path
          : this.todo.originalFile?.path

      if (filePath) {
        await this.getDownloadUrl(filePath)
        this.mediaUrl = this.downloadUrl
      } else {
        console.error('File path does not exist:')
      }

      if (this.todosByCameraId) {
        this.currentImage = this.mediaUrl
        this.index = this.todosByCameraId.length - 1
      }
    } catch (error) {
      console.error('Error fetching media URL:', error)
      this.mediaUrl = null
    }
  }

  public changeZoomLevel(event) {
    if (event.type === 'click') {
      if (this.currentZoomLevel == this.MAX_ZOOM_LEVEL) {
        this.currentZoomLevel = this.DEFAULT_ZOOM_LEVEL
      } else {
        this.currentZoomLevel += this.ZOOM_INCREMENT
      }
    } else {
      if (event.deltaY < 0 && this.currentZoomLevel < this.MAX_ZOOM_LEVEL) {
        this.currentZoomLevel += this.ZOOM_INCREMENT
      } else if (
        event.deltaY > 0 &&
        this.currentZoomLevel > this.DEFAULT_ZOOM_LEVEL
      ) {
        this.currentZoomLevel -= this.ZOOM_INCREMENT
      }
    }

    this.magnify(this.currentZoomLevel)
  }

  public handleImageLoad() {
    this.onTodoLoad(true)
    this.magnify(this.currentZoomLevel)
  }

  public setCurrentTime() {
    try {
      const video = document.getElementById('todo-video') as HTMLVideoElement
      this.currentTime = (video.currentTime / video.duration) * 100
    } catch {}
  }

  public setPlaybackSpeed(rate: number) {
    const video = document.getElementById('todo-video') as HTMLVideoElement
    video.playbackRate = rate
  }

  @Watch('currentTime')
  public watchCurrentTime() {
    try {
      if (this.isMediaVideo) {
        const video = document.getElementById('todo-video') as HTMLVideoElement
        const wrapper = document.getElementById(
          'gallery-element-video-wrapper'
        ) as HTMLElement
        const container = document.getElementById(
          'gallery-element-container'
        ) as HTMLElement
        const aspectRatio = video.videoWidth / video.videoHeight
        if (container.offsetHeight <= container.offsetWidth) {
          wrapper.style.width =
            String(Math.floor(container.offsetHeight * aspectRatio)) + 'px'
        } else {
          wrapper.style.width = String(container.offsetWidth) + 'px'
        }
        if (video.currentTime >= video.duration - 0.5) {
          this.onFirstLoop(true)
        }
      }
    } catch {}
  }

  private magnify(zoom: number) {
    let img, glass: HTMLElement, w: number, h: number, bw: number
    img = document.getElementById('todo-image')

    glass = document.getElementById('img-magnifier-glass')
    glass.setAttribute('class', 'img-magnifier-glass')

    // Insert magnifier glass:
    img.parentElement.insertBefore(glass, img)

    // Set background properties for the magnifier glass:
    glass.style.backgroundImage = "url('" + img.src + "')"
    glass.style.backgroundRepeat = 'no-repeat'
    glass.style.backgroundSize =
      img.width * zoom + 'px ' + img.height * zoom + 'px'
    bw = 3
    w = glass.offsetWidth / 2
    h = glass.offsetHeight / 2

    glass.addEventListener('mousemove', moveMagnifier)
    img.addEventListener('mousemove', moveMagnifier)

    function moveMagnifier(e) {
      let pos: { x: number; y: number }, x: number, y: number
      e.preventDefault()
      // Get the cursor's x and y positions:
      pos = getCursorPos(e)
      x = pos.x
      y = pos.y
      // Prevent the magnifier glass from being positioned outside the image:
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom
      }
      if (x < w / zoom) {
        x = w / zoom
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom
      }
      if (y < h / zoom) {
        y = h / zoom
      }
      // Set the position of the magnifier glass:
      glass.style.left = x - w + 'px'
      glass.style.top = y - h + 'px'
      // Display what the magnifier glass "sees":
      glass.style.backgroundPosition =
        '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px'
    }

    function getCursorPos(e) {
      let a,
        x = 0,
        y = 0
      e = e || window.event
      // Get the x and y positions of the image:
      a = img.getBoundingClientRect()
      // Calculate the cursor's x and y coordinates, relative to the image:
      x = e.pageX - a.left
      y = e.pageY - a.top
      // Consider any page scrolling:
      x = x - window.pageXOffset
      y = y - window.pageYOffset
      return { x, y }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.img-magnifier-container {
  position: relative;
  z-index: 1;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #ffd42a;
  cursor: none;
  border-radius: 50%;
  /* Set the size of the magnifier glass: */
  width: 160px;
  height: 160px;
}

.gallery-element-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}

video {
  object-fit: fill;
}

.gallery-element-video-wrapper {
  position: relative;
}
.gallery-element-wrapper {
  height: 98%;
  width: 100%;
}

.gallery-container {
  display: flex;
  height: calc(100vh - 220px);
}

.gallery-element {
  width: 100vw;
  height: calc(100vh - 220px);
}

.v-chip {
  font-size: 1.2em;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
}

.actions-wrapper {
  width: 100%;
  display: flex;
}

.worker-align {
  justify-content: flex-end;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 98%;
  z-index: 0;
}

.video-progress-bar {
  position: absolute;
  bottom: 6%;
  z-index: index 1;
}
</style>
