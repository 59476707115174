export const getDefaultState = () => {
  return {
    currentUser: null,
    messageUser: null,
    loginUser: null,
    allUser: [],
    permissionUser: [],
    eagleEyeAccounts: [],
    isOrgNameDialogVisible: true,
    clientOwner: null,
    eagleEyeConnect: {
      progress: false,
      message: null
    },
    globalLiveIsLoading: true,
    globalCameras: null,
    isInitialUser: false,
    isLoadingUsers: true,
    colors: {
      darkPrimaryColor: '#333333',
      darkSectionColor: '#938D76',
      darkFrameColor: '#514F4B',
      darkSecondaryColor: '#FFD42A',
      darkLowStrokeColor: '#C4C4C4',
      darkChartSeriesColor: '#787878',
      darkStrokeColor: '#E5E5E5',
      darkLowSecondaryColor: '#FFE789',
      darkLowFrameColor: '#E5E3D4',
      darkHighFrameColor: '#1e1e1e',
      darkBlackColor: '#000000',
      darkButtonsGreyColor: '#6F6C61',
      darkTreeItemsColor: '#2C3E50',

      lightPrimaryColor: '#FFFFFF',
      lightTreeItemsColor: '#757575',

      lightSectionColor: '#F2F2F2'
    }
  }
}

const state = getDefaultState()

export default {
  ...state
}
