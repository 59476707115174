<template>
  <v-col
    :class="`d-flex flex-column col-12 col-md-12 col-lg-12 col-xl-12 ${
      isUserAdmin ? 'col-lg-12' : 'col-lg-3'
    } col-xl-2`"
  >
    <p v-if="isQubeEnabled" class="text-md-body-1 text-body-2">
      {{ `Active ${type.charAt(0).toUpperCase() + type.slice(1)}s` }}
    </p>
    <p v-else class="text-md-body-1 text-body-2">
      {{ `${type.charAt(0).toUpperCase() + type.slice(1)}s` }}
    </p>
    <div v-if="isLoading" class="loader-wrapper">
      <v-progress-circular
        indeterminate
        color="secondary"
        size="15"
      ></v-progress-circular>
    </div>
    <p v-if="isQubeEnabled && !isLoading" class="text-md-h5 text-xl-h4 text-h6">
      {{ activeNodeCount }}
    </p>
    <p v-else-if="!isLoading" class="bold-text text-md-h5 text-xl-h4 text-h6">
      {{ nodeCount }}
    </p>
  </v-col>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { Getter } from 'vuex-class'

const namespaceStatistics = { namespace: 'statistics' }
const namespaceSite = { namespace: 'site' }

@Component({
  components: {}
})
export default class InstallSummaryBlock extends Vue {
  @Prop() public isUserAdmin!: boolean
  @Prop() public type!: 'user' | 'client' | 'site' | 'camera'
  @Prop() public userId?: string
  @Prop() public clientId?: string
  @Prop() public siteId?: string
  @Prop() public cameraId?: string
  @Prop() public isPromiseQube?: boolean

  @Action('fetchNodeCount', namespaceStatistics) fetchNodeCount
  @Action('fetchActiveNodeCount', namespaceStatistics) fetchActiveNodeCount

  @Getter('siteConfig', namespaceSite) public siteConfig: any

  public nodeCount: number = 0
  public activeNodeCount: number = 0
  public isLoading: boolean = false

  async mounted() {
    await this.fetchCount()
    await this.fetchActiveCount()
  }

  private get combinedWatchers() {
    return {
      userId: this.userId,
      clientId: this.clientId,
      siteId: this.siteId,
      cameraId: this.cameraId
    }
  }

  @Watch('type')
  @Watch('combinedWatchers')
  public async fetchCount() {
    this.isLoading = true
    this.nodeCount = await this.fetchNodeCount({
      type: this.type,
      ...this.$route.params
    })
    this.isLoading = false
  }

  @Watch('type')
  @Watch('combinedWatchers')
  public async fetchActiveCount() {
    this.isLoading = true
    this.activeNodeCount = await this.fetchActiveNodeCount({
      type: this.type,
      ...this.$route.params
    })
    this.isLoading = false
  }

  public get isSiteLevel() {
    return this.clientId && this.siteId && !this.cameraId
  }

  public get isCheckQube() {
    if (this.isSiteLevel) {
      return this.siteConfig?.isHardwareDevice
    }
  }

  public get isQubeEnabled() {
    return this.isPromiseQube && this.isCheckQube
  }
}
</script>

<style>
.bold-text {
  font-size: 2.5rem;
  font-weight: bolder;
}

.incident-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 230px;
}

.installation-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c4c4c4 1px;
  border-radius: 6px;
  margin: 10px;
  height: 140px;
  width: 140px;
}

.summary-title {
  align-items: center;
  font-weight: bold;
}
</style>
