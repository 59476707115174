const getters = {
  getLiveHealthChecks: (state) => {
    return state.liveHealthChecks
  },
  getLiveHealthChecksLoading: (state) => {
    return state.liveHealthChecksLoading
  },
  getLiveHcLoadMoreLoading: (state) => {
    return state.liveHcLoadMoreLoading
  },
  getHasMoreLiveHealthChecks: (state) => {
    return state.hasMoreLiveHealthChecks
  },
  getLastLiveHealthCheck: (state) => {
    return state.lastLiveHealthCheck
  }
}

export default {
  ...getters
}
