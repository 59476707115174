<template>
  <div class="pt-10 pl-10">
    <!-- <v-row>
      <v-col md="6" sm="12">
        <v-row class="black--text pb-12">
          <v-col cols="12">
            <BlockHeader :title="'Integrations'" :titleOnly="true" />
            <v-row class="card-component-style">
              <HikVisionConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickHikCentralEnabled"
                :onChange="onClickSaveHikCentralConfig"
                :onChangeAcknowledgementStatus="
                  onClickHikCentralAcknowledgeEnabledStatus
                "
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <GenetecConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickGenetecEnabled"
                :onChange="onClickSaveGenetecConfig"
                :onChangeAcknowledgementStatus="
                  onClickGenetecAcknowledgeEnabledStatus
                "
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <EagleEyeConfiguration
                :configuration="configuration"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text pb-12">
          <v-col cols="12">
            <BlockHeader :title="'Contact'" :titleOnly="true" />
            <v-row class="card-component-style">
              <Contact
                :configuration="configuration"
                :onChange="onContactChange"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" sm="12">
        <v-row class="black--text" style="width: 100%">
          <v-col>
            <v-row class="black--text d-flex pl-3 pr-3 py-3">
              <BlockHeader :title="'Alarm Settings'" :titleOnly="true" />
              <v-row class="card-component-style">
                <HumanReviewConfiguration
                  :configuration="configuration"
                  :onSwitchChange="onIsAskCrowdWorkersChange"
                  :level="'global'"
                  :isWriteDisabled="isWriteDisabled"
                />
              </v-row>
              <v-row class="card-component-style">
                <AlarmDefinitionConfiguration
                  :configuration="configuration"
                  :onChange="onAlarmDefinitionChange"
                  :level="'global'"
                  :isWriteDisabled="isWriteDisabled"
                />
              </v-row>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text pt-7 pb-12">
          <v-col cols="12">
            <BlockHeader :title="'Unarmed Time Range'" :titleOnly="true" />
            <v-row class="card-component-style">
              <UnarmedTimeRangeConfiguration
                :configuration="configuration"
                :onChange="onChangeUnarmedTimeRange"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text pt-7 pb-12">
          <v-col cols="12">
            <BlockHeader :title="'Health Check'" :titleOnly="true" />
            <v-row class="card-component-style">
              <HealthCheckConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsHealthCheckNotificationEnabled"
                :onChange="onClickSaveHcDuration"
                :onTypeChange="onHcTypeChange"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text pt-7 pb-12">
          <v-col cols="12">
            <BlockHeader :title="'Notifications'" :titleOnly="true" />
            <v-row class="card-component-style">
              <EmailNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsEmailNotificationEnabled"
                :onChange="onClickSaveNotificationEmail"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <SiaNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsSiaEnabled"
                :onChange="onClickSaveSiaConfig"
                :onChangeAccountNotified="updateIsRelatedSiaAccountNotified"
                :onChangeHeartbeatEnabled="updateIsSiaHeartbeatEnabled"
                :onChangeRoutineMessageEnabled="
                  updateIsSiaRoutineMessageEnabled
                "
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>

            <v-row class="card-component-style">
              <WebhookNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsWebhookEnabled"
                :onChange="onClickSaveWebhookConfig"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>

            <v-row class="card-component-style">
              <FtpNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsFtpNotificationEnabled"
                :onChange="onClickSaveFtpConfig"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <SmtpNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsSmtpNotificationEnabled"
                :onChange="onClickSaveSmtpConfig"
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
            <v-row class="card-component-style">
              <EvalinkNotificationConfiguration
                :configuration="configuration"
                :onSwitchChange="onClickIsEvalinkEnabled"
                :onChange="onClickSaveEvalinkConfig"
                :onChangeDeviceOverrideEnabled="
                  onClickIsEvalinkDeviceOverrideEnabled
                "
                :level="'global'"
                :isWriteDisabled="isWriteDisabled"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
        />
      </template>
    </v-snackbar>
    <InfoButton :rows="rows" /> -->
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ProgressBar from '../ProgressBar.vue'
import Button from '@/components/app/Button.vue'
import moment from 'moment'
import WebhookParams from '@/components/events/WebhookParams.vue'
import FtpNotificationConfiguration from './FtpNotificationConfiguration.vue'
import AlarmDefinitionConfiguration from './AlarmDefinitionConfiguration.vue'
import UnarmedTimeRangeConfiguration from './UnarmedTimeRangeConfiguration.vue'
import HumanReviewConfiguration from './HumanReviewConfiguration.vue'
import EmailNotificationConfiguration from './EmailNotificationConfiguration.vue'
import SiaNotificationConfiguration from './SiaNotificationConfiguration.vue'
import WebhookNotificationConfiguration from './WebhookNotificationConfiguration.vue'
import EvalinkNotificationConfiguration from './EvalinkNotificationConfiguration.vue'
import GenetecConfiguration from './GenetecConfiguration.vue'
import EagleEyeConfiguration from './EagleEyeConfiguration.vue'
import HikVisionConfiguration from './HikVisionConfiguration.vue'
import HealthCheckConfiguration from './HealthCheckConfiguration.vue'
import BlockHeader from './BlockHeader.vue'
import InfoButton from '@/views/InfoButton.vue'
import Contact from './Contact.vue'
import { userCollection } from '@/provider/firebase'
import SmtpNotificationConfiguration from './SmtpNotificationConfiguration.vue'

const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    ProgressBar,
    Button,
    WebhookParams,
    AlarmDefinitionConfiguration,
    UnarmedTimeRangeConfiguration,
    FtpNotificationConfiguration,
    HumanReviewConfiguration,
    EmailNotificationConfiguration,
    SiaNotificationConfiguration,
    WebhookNotificationConfiguration,
    EvalinkNotificationConfiguration,
    HikVisionConfiguration,
    GenetecConfiguration,
    EagleEyeConfiguration,
    HealthCheckConfiguration,
    Contact,
    InfoButton,
    BlockHeader,
    SmtpNotificationConfiguration
  }
})
export default class AllClientConfiguration extends Vue {
  // @Getter('getColors', namespaceUser) public getColors!: any
  // @Getter('currentUser', namespaceUser) public currentUser: any
  // @Action('bindAllUser', namespaceUser) public bindAllUser
  // @Action('unbindAllUser', namespaceUser) public unbindAllUser
  // @Action('updateIsAskCrowdWorkersStatus', namespaceUser)
  // public updateIsAskCrowdWorkersStatus
  // @Action('updateEvalinkConfig', namespaceUser) public updateEvalinkConfig
  // @Action('updateIsEvalinkNotificationEnabledStatus', namespaceUser)
  // public updateIsEvalinkNotificationEnabledStatus
  // @Action('updateIsEvalinkDeviceOverrideEnabledStatus', namespaceUser)
  // public updateIsEvalinkDeviceOverrideEnabledStatus
  // @Action('updateIsWebhookTriggerEnabledStatus', namespaceUser)
  // public updateIsWebhookTriggerEnabledStatus
  // @Action('updateWebhookTriggerConfig', namespaceUser)
  // public updateWebhookTriggerConfig
  // @Action('updateSiaConfig', namespaceUser) public updateSiaConfig
  // @Action('updateIsSiaNotificationEnabledStatus', namespaceUser)
  // public updateIsSiaNotificationEnabledStatus
  // @Action('updateIsSiaHeartbeatEnabledStatus', namespaceUser)
  // public updateIsSiaHeartbeatEnabledStatus
  // @Action('updateIsRelatedSiaAccountNotifiedStatus', namespaceUser)
  // public updateIsRelatedSiaAccountNotifiedStatus
  // @Action('updateIsSiaRoutineMessageEnabledStatus', namespaceUser)
  // public updateIsSiaRoutineMessageEnabledStatus
  // @Action('sendEmail', namespaceUser) public sendEmail
  // @Action('updateIsEmailNotificationEnabledStatus', namespaceUser)
  // public updateIsEmailNotificationEnabledStatus
  // @Action('updateNotificationEmails', namespaceUser)
  // public updateNotificationEmails
  // @Action('updateUnarmedTimeRange', namespaceUser) public updateUnarmedTimeRange
  // @Action('updateAlarmDefinitionConfig', namespaceUser)
  // public updateAlarmDefinitionConfig
  // @Action('updateFTPConfig', namespaceUser) public updateFTPConfig
  // @Action('updateIsFTPNotificationEnabledStatus', namespaceUser)
  // public updateIsFTPNotificationEnabledStatus
  // @Action('updateSMTPConfig', namespaceUser) public updateSMTPConfig
  // @Action('updateIsSMTPNotificationEnabledStatus', namespaceUser)
  // public updateIsSMTPNotificationEnabledStatus
  // @Action('configureHikCentral', namespaceUser)
  // private configureHikCentral
  // @Action('updateHikCentralConfig', namespaceUser)
  // public updateHikCentralConfig
  // @Action('updateHikCentralAcknowledgementEnabledStatus', namespaceUser)
  // public updateHikCentralAcknowledgementEnabledStatus
  // @Action('updateHikCentralNotificationEnabledStatus', namespaceUser)
  // public updateHikCentralNotificationEnabledStatus
  // @Action('updateGenetecEnabledStatus', namespaceUser)
  // public updateGenetecEnabledStatus
  // @Action('updateGenetecConfig', namespaceUser)
  // public updateGenetecConfig
  // @Action('updateGenetecAcknowledgementEnabledStatus', namespaceUser)
  // public updateGenetecAcknowledgementEnabledStatus
  // @Action('updateHcDuration', namespaceUser) public updateHcDuration
  // @Action('updateHcType', namespaceUser) public updateHcType
  // @Action('updateIsHealthCheckNotificationEnabledStatus', namespaceUser)
  // public updateIsHealthCheckNotificationEnabledStatus
  // @Action('updateContact', namespaceUser) public updateContact
  // @Prop({ type: Object, required: true }) configuration!: any
  // @Prop({ required: true }) userId!: string
  // @Prop({ required: true }) userOrOrg!: any
  // @Prop({ required: true }) hasRootUser!: boolean
  // public isUserAdmin: boolean = false
  // private userDocumentId: string
  // public snackbar: boolean = false
  // public snackbarMessage: string = ''
  // public rows: any[] = [
  //   {
  //     icon: 'mdi-file-upload',
  //     text: 'Learn more about settings',
  //     link: 'https://promiseqknowledgebase.super.site/list-of-promiseq-handbooks/video-intelligence-platform-handbook/configurations',
  //     class: 'pr-3 black--text'
  //   }
  // ]
  // public async mounted() {
  //   this.isUserAdmin = this.currentUser?.role === 'Administrator'
  //   await this.bindAllUser()
  //   this.watchUserOrOrgRef()
  // }
  // public beforeDestroy() {
  //   this.unbindAllUser()
  // }
  // public get themeColor(): string {
  //   if (this.currentUser.role === 'Administrator') {
  //     return this.getColors.lightPrimaryColor
  //   } else {
  //     return this.getColors.darkBlackColor
  //   }
  // }
  // get getRootUser() {
  //   return this.$store.state.user?.rootUser
  // }
  // get isWriteDisabled() {
  //   let isDisabled = false
  //   if (this.currentUser?.isGlobalWrite == false) {
  //     isDisabled = true
  //   }
  //   return isDisabled
  // }
  // @Watch('currentUser')
  // public async watchCurrentUser() {
  //   if (this.currentUser && this.isUserAdmin === undefined) {
  //     window.location.reload()
  //   }
  // }
  // @Watch('userOrOrg')
  // public watchUserOrOrgRef() {
  //   this.userDocumentId = this.userOrOrg?.id
  // }
  // private async getUserEmail() {
  //   const currUser = await userCollection.doc(this.userId).get()
  //   return currUser.data()?.email
  // }
  // private popSnackbarMessage(message: string) {
  //   this.snackbar = false
  //   this.snackbar = true
  //   this.snackbarMessage = message
  // }
  // public changeTimeFormat(time: string) {
  //   let newFormat = moment(time, ['HH:mm']).format('h:mm A')
  //   return newFormat
  // }
  // public async onIsAskCrowdWorkersChange(value) {
  //   const userEmail = await this.getUserEmail()
  //   var text
  //   if (value) {
  //     text = 'Human review has been enabled!'
  //   } else {
  //     text = 'Human review has been disabled!'
  //   }
  //   this.updateIsAskCrowdWorkersStatus({
  //     currentUserId: this.userDocumentId,
  //     isAskCrowdWorkers: value
  //   })
  //   this.popSnackbarMessage(text)
  //   return this.sendEmail({
  //     useremail: userEmail,
  //     subject: 'Setup Notification from promiseQ',
  //     text: text
  //   })
  // }
  // public async onClickSaveHcDuration(durationInHours: any) {
  //   await this.updateHcDuration({
  //     currentUserId: this.userDocumentId,
  //     healthCheckDuration: durationInHours
  //   })
  //   this.popSnackbarMessage('Health check frequency saved successfully!')
  // }
  // public async onHcTypeChange(hcType: string) {
  //   await this.updateHcType({
  //     currentUserId: this.userDocumentId,
  //     healthCheckType: hcType
  //   })
  //   this.popSnackbarMessage('Health check type updated successfully!')
  // }
  // public async onClickIsHealthCheckNotificationEnabled(value: boolean) {
  //   await this.updateIsHealthCheckNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isHealthCheckNotificationEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('Health Check notifications have been enabled!')
  //   } else {
  //     this.popSnackbarMessage('Health Check notifications have been disabled!')
  //   }
  // }
  // public onContactChange(contactData: any) {
  //   this.updateContact({
  //     currentUserId: this.userDocumentId,
  //     contact: contactData
  //   })
  // }
  // //watch for evalink start
  // public onClickIsEvalinkEnabled(value) {
  //   if (value) {
  //     this.popSnackbarMessage('Evalink Notification have been enabled!')
  //   } else {
  //     this.popSnackbarMessage('Evalink Notification have been disabled!')
  //   }
  //   this.updateIsEvalinkNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isEvalinkEnabled: value
  //   })
  // }
  // public onClickIsEvalinkDeviceOverrideEnabled(value) {
  //   if (value) {
  //     this.popSnackbarMessage('Evalink device override have been enabled!')
  //   } else {
  //     this.popSnackbarMessage('Evalink device override have been disabled!')
  //   }
  //   this.updateIsEvalinkDeviceOverrideEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isEvalinkDeviceOverrideEnabled: value
  //   })
  // }
  // public onClickSaveEvalinkConfig(evalinkData: any) {
  //   this.updateEvalinkConfig({
  //     currentUserId: this.userDocumentId,
  //     evalinkData: evalinkData
  //   })
  //   this.updateIsEvalinkNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isEvalinkEnabled: true
  //   })
  //   this.popSnackbarMessage('Successfully updated Evalink configuration')
  // }
  // public onClickIsWebhookEnabled(value: boolean) {
  //   if (value) {
  //     this.popSnackbarMessage('Webhook trigger has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('Webhook trigger has been disabled!')
  //   }
  //   this.updateIsWebhookTriggerEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isWebhookTriggerEnabled: value
  //   })
  // }
  // public async onClickSaveWebhookConfig(webhookData: any) {
  //   await this.updateWebhookTriggerConfig({
  //     currentUserId: this.userDocumentId,
  //     webhookTriggerData: webhookData
  //   })
  //   await this.updateIsWebhookTriggerEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isWebhookTriggerEnabled: true
  //   })
  //   this.popSnackbarMessage(
  //     'Successfully updated webhook trigger configuration'
  //   )
  // }
  // public onClickIsSiaEnabled(value: boolean) {
  //   if (value) {
  //     this.popSnackbarMessage('SIA Notification has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('SIA Notification has been disabled!')
  //   }
  //   this.updateIsSiaNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isSiaEnabled: value
  //   })
  // }
  // public async updateIsSiaHeartbeatEnabled(value: boolean) {
  //   await this.updateIsSiaHeartbeatEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isSiaHeartbeatEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('SIA Heartbeat has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('SIA Heartbeat has been disabled!')
  //   }
  // }
  // public async updateIsRelatedSiaAccountNotified(value: boolean) {
  //   await this.updateIsRelatedSiaAccountNotifiedStatus({
  //     currentUserId: this.userDocumentId,
  //     isRelatedSiaAccountNotified: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage(
  //       'Notifications will be sent to the corresponding account number!'
  //     )
  //   } else {
  //     this.popSnackbarMessage(
  //       'Notifications will be sent to the default account number!'
  //     )
  //   }
  // }
  // public async updateIsSiaRoutineMessageEnabled(value: boolean) {
  //   await this.updateIsSiaRoutineMessageEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isSiaRoutineMessageEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('SIA Routine Message has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('SIA Routine Message has been disabled!')
  //   }
  // }
  // public onClickSaveSiaConfig(siaData: any) {
  //   this.updateSiaConfig({
  //     currentUserId: this.userDocumentId,
  //     siaData: siaData
  //   })
  //   this.updateIsSiaNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isSiaEnabled: true
  //   })
  //   this.popSnackbarMessage('Successfully updated SIA configuration')
  // }
  // public async onClickIsEmailNotificationEnabled(value: boolean) {
  //   var text: string
  //   const userEmail = await this.getUserEmail()
  //   await this.updateIsEmailNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isEmailNotificationEnabled: value
  //   })
  //   if (value) {
  //     text = 'Email Notification have been enabled!'
  //     this.popSnackbarMessage('Email Notification have been enabled!')
  //   } else {
  //     text = 'Email Notification have been disabled!'
  //     this.popSnackbarMessage('Email Notification have been disabled!')
  //   }
  //   return this.sendEmail({
  //     useremail: userEmail,
  //     subject: 'Alarm Notification from promiseQ',
  //     text: text
  //   })
  // }
  // public async onClickSaveNotificationEmail(emailData: any) {
  //   await this.updateNotificationEmails({
  //     currentUserId: this.userDocumentId,
  //     notificationEmails: emailData?.notificationEmails
  //   })
  //   this.popSnackbarMessage('Successfully updated Receiving Emails')
  // }
  // public async onClickSaveFtpConfig(ftpData: any) {
  //   await this.updateFTPConfig({
  //     currentUserId: this.userDocumentId,
  //     ftpData: ftpData
  //   })
  //   this.popSnackbarMessage('Successfully updated FTP configuration')
  // }
  // public async onClickIsFtpNotificationEnabled(value: boolean) {
  //   await this.updateIsFTPNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isFTPEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('FTP Notification has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('FTP Notification has been disabled!')
  //   }
  // }
  // public async onClickIsSmtpNotificationEnabled(value: boolean) {
  //   await this.updateIsSMTPNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isSMTPNotificationEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('SMTP Notification has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('SMTP Notification has been disabled!')
  //   }
  // }
  // public async onClickSaveSmtpConfig(smtpNotificationData: any) {
  //   await this.updateSMTPConfig({
  //     currentUserId: this.userDocumentId,
  //     smtpNotificationData: smtpNotificationData
  //   })
  //   this.popSnackbarMessage('Successfully updated SMTP configuration')
  // }
  // public async onClickHikCentralEnabled(value: boolean, hikCentralData: any) {
  //   await this.updateHikCentralNotificationEnabledStatus({
  //     currentUserId: this.configuration.id,
  //     isHikCentralEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('HikCentral has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('HikCentral has been disabled!')
  //   }
  //   const response = await this.configureHikCentral({
  //     ...hikCentralData,
  //     userId: this.userDocumentId,
  //     isEnabled: value
  //   })
  //   if (response) {
  //     this.popSnackbarMessage(
  //       `Successfully ${value ? 'subscribed' : 'unsubscribed'} to HikVison HCP`
  //     )
  //   } else {
  //     this.popSnackbarMessage(
  //       `Failure while ${
  //         value ? 'subscribing' : 'unsubscribing'
  //       } to HikVison HCP`
  //     )
  //   }
  // }
  // public async onClickHikCentralAcknowledgeEnabledStatus(value) {
  //   await this.updateHikCentralAcknowledgementEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isAcknowledgementEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage(
  //       'HikCentral Automatic Event Acknowledgement has been enabled!'
  //     )
  //   } else {
  //     this.popSnackbarMessage(
  //       'HikCentral Automatic Event Acknowledgement has been disabled'
  //     )
  //   }
  // }
  // public async onClickSaveHikCentralConfig(hikCentralData: any) {
  //   await this.updateHikCentralConfig({
  //     currentUserId: this.userDocumentId,
  //     hikCentralData: hikCentralData
  //   })
  //   await this.updateHikCentralNotificationEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isHikCentralEnabled: true
  //   })
  //   this.popSnackbarMessage('Successfully updated HikVison HCP configuration')
  //   const response = await this.configureHikCentral({
  //     ...hikCentralData,
  //     userId: this.userDocumentId,
  //     isEnabled: true
  //   })
  //   if (response) {
  //     this.popSnackbarMessage(`Successfully subscribed to HikVison HCP`)
  //   } else {
  //     this.popSnackbarMessage(`Failure while subscribing to HikVison HCP`)
  //   }
  // }
  // public async onClickGenetecEnabled(value) {
  //   await this.updateGenetecEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isGenetecEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage('Genetec has been enabled!')
  //   } else {
  //     this.popSnackbarMessage('Genetec has been disabled!')
  //   }
  // }
  // public async onClickGenetecAcknowledgeEnabledStatus(value) {
  //   await this.updateGenetecAcknowledgementEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isAcknowledgementEnabled: value
  //   })
  //   if (value) {
  //     this.popSnackbarMessage(
  //       'Genetec Automatic Event Acknowledgement has been enabled!'
  //     )
  //   } else {
  //     this.popSnackbarMessage(
  //       'Genetec Automatic Event Acknowledgement has been disabled'
  //     )
  //   }
  // }
  // public async onClickSaveGenetecConfig(gentecData: any) {
  //   await this.updateGenetecConfig({
  //     currentUserId: this.userDocumentId,
  //     genetecData: gentecData
  //   })
  //   await this.updateGenetecEnabledStatus({
  //     currentUserId: this.userDocumentId,
  //     isGenetecEnabled: true
  //   })
  //   this.popSnackbarMessage('Successfully updated Genetec configuration')
  // }
  // public async onAlarmDefinitionChange(alarmDefinition: string) {
  //   await this.updateAlarmDefinitionConfig({
  //     currentUserId: this.userDocumentId,
  //     alarmDefinitionConfig: alarmDefinition
  //   })
  // }
  // public async onChangeUnarmedTimeRange(unarmedTimeRange: object) {
  //   if (this.userDocumentId) {
  //     await this.updateUnarmedTimeRange({
  //       currentUserId: this.userDocumentId,
  //       unarmedTimeRange: unarmedTimeRange
  //     })
  //   }
  // }
}
</script>

<style scoped>
.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-component-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid #dadada 1.5px;
  border-right: solid #dadada 1.5px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border: solid #dadada 1px;
}

/* To have the same border color of v-text-field in focus and unfocused state */
.form .v-text-field--outlined >>> fieldset {
  border-color: #ffd42a;
}

::v-deep .checkbox .v-label,
.v-subheader {
  font-size: 1.1rem !important;
  font-weight: 500;
  color: #000;
}

.tooltip {
  text-align: left;
  position: relative;
}

.tooltip .tooltClickToCopy {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltClickToCopy::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltClickToCopy {
  visibility: visible;
  opacity: 1;
}
.receiving-email-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu-icon {
  float: right;
  padding-left: 10px;
  color: #000;
  bottom: -3px;
}
.ai-human-ind {
  text-align: left;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 12px;
}

@media only screen and (min-width: 1900px) {
  .container-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>
