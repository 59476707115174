import { DocumentReference } from '@firebase/firestore-types'
import {
  cameraCollection,
  clientCollection,
  siteCollection
} from '@/provider/firebase'

export function generateUniqueId(): string {
  const pattern: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xx'
  return pattern.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const generateSubscriptionKey = (
  length: number,
  existingPasswords: string[] = []
): string => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%&ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let password = ''

  for (var i = 0; i < length; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length)
    password += chars.substring(randomNumber, randomNumber + 1)
  }

  if (!existingPasswords.includes(password)) {
    return password
  }

  return generateSubscriptionKey(length, existingPasswords)
}
