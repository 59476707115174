var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{staticClass:"my-menu",attrs:{"bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"split-btn"},[_c('v-btn',{staticClass:"main-btn",class:{
            'admin-btn': _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
            'main-btn-mobile': _vm.smallScreen
          },attrs:{"outlined":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : ''
            }),attrs:{"data-test-id":"refresh-button"}},[_vm._v("mdi-refresh")]),_c('p',{staticClass:"ma-0 d-lg-flex text-subtitle-2",style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : ''
            })},[_c('span',{staticClass:"refresh-text"},[_vm._v("   "+_vm._s(_vm.refreshButtonText)+"   ")])])],1),_c('v-btn',_vm._g({staticClass:"actions-btn",class:{
            'admin-actions-btn': _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
            'actions-btn-mobile': _vm.smallScreen
          },style:({
            pointerEvents: 'auto'
          }),attrs:{"outlined":"","data-test-id":"auto-refresh-drop-down-button"}},on),[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : ''
            })},[_vm._v("mdi-pencil")])],1)],1)]}}]),model:{value:(_vm.isRefreshSettingsMenuOpen),callback:function ($$v) {_vm.isRefreshSettingsMenuOpen=$$v},expression:"isRefreshSettingsMenuOpen"}},[_c('v-card',{staticClass:"refresh-cards",style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor
      }),attrs:{"width":"210px"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"text-subtitle-2",staticStyle:{"text-align":"left","font-weight":"bold"},style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : ''
              })},[_vm._v(" Auto refresh ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"refresh-switch",attrs:{"inset":"","color":"secondary","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"data-test-id":"refresh-switch"},on:{"change":function($event){return _vm.updatePeriodicRefresh('toggle')}},model:{value:(_vm.isRefreshEnabledLocal),callback:function ($$v) {_vm.isRefreshEnabledLocal=$$v},expression:"isRefreshEnabledLocal"}})],1)],1),_c('v-divider',{attrs:{"horizontal":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false}}),_c('v-row',{staticClass:"refresh-dropdown-row",attrs:{"no-gutters":"","title":"Set the refresh interval for the dashboard. Minimum refresh interval is 20 seconds."}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-2",staticStyle:{"text-align":"left","font-weight":"bold"},style:({
                color:
                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? _vm.getColors.lightPrimaryColor
                    : ''
              })},[_vm._v(" Refresh each ")])])],1),_c('v-row',{staticClass:"refresh-dropdown-input",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-start",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"1","type":"number","min":"1","max":"59","disabled":!_vm.isRefreshEnabledLocal,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.darkFrameColor
                  : '',"data-test-id":"refresh-interval-value"},model:{value:(_vm.refreshIntervalValue),callback:function ($$v) {_vm.refreshIntervalValue=$$v},expression:"refreshIntervalValue"}})],1),_c('v-col',{staticClass:"d-flex flex-row justify-start",attrs:{"cols":"7"}},[_c('v-select',{attrs:{"dense":"","items":_vm.timeUnit,"outlined":"","disabled":!_vm.isRefreshEnabledLocal,"append-icon":'mdi-chevron-down',"responsive":{ xs: 12, sm: 2 },"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.darkFrameColor
                  : '',"data-test-id":"refresh-interval-unit"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                    backgroundColor:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  })},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.refreshIntervalUnit),callback:function ($$v) {_vm.refreshIntervalUnit=$$v},expression:"refreshIntervalUnit"}})],1)],1),_c('v-row',{staticClass:"refresh-dropdown-button",attrs:{"dense":""}},[_c('v-col',[_c('Button',{staticClass:"ma-2 lower-case selected text-subtitle-2",attrs:{"color":"black","ripple":false,"btnText":"Apply","disabled":!_vm.isRefreshEnabledLocal,"data-test-id":"apply-button"},on:{"onButtonClick":function($event){return _vm.updatePeriodicRefresh('settings')}}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }