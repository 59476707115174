<template>
  <div
    class="container-no-camera"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 70vh;
    "
  >
    <!-- Image -->
    <div style="text-align: center">
      <img
        v-if="isUserAdmin && isDarkModeToggleEnabled"
        src="../../../public/img/nocamera_dark.svg"
        alt="No Camera SVG"
        class="dark-mode-image"
        style="max-width: 100%; height: auto"
      />
      <img
        v-else
        src="../../../public/img/nocamera_light.svg"
        alt="No Camera SVG"
        class="light-mode-image"
        style="max-width: 100%; height: auto"
      />
    </div>

    <!-- Text -->
    <div style="padding-top: 20px; text-align: center; max-width: 500px">
      <p
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          fontWeight: 'bold',
          fontSize: '30px',
          textAlign: 'center'
        }"
      >
        {{ customTitle ? customTitle : "The Live stream doesn't exist." }}
      </p>
      <p
        v-if="nodeType === 'camera'"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          textAlign: 'center',
          fontSize: '18px'
        }"
      >
        {{
          customText
            ? customText
            : `Sorry, but there is no live streaming for this ${nodeType} yet. You can connect live streaming to this ${nodeType} by going to Configs.`
        }}
      </p>
      <p
        v-else
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor,
          textAlign: 'center',
          fontSize: '18px'
        }"
      >
        {{
          customText
            ? customText
            : `Sorry, but there is no live streaming for this ${nodeType} yet. You can connect live streaming for each camera from the Configs page under camera node.`
        }}
      </p>
      <!-- Buttons -->
      <div style="margin-top: 20px; text-align: center">
        <button
          :class="
            isUserAdmin && isDarkModeToggleEnabled
              ? 'refresh-dark-mode'
              : 'refresh-light-mode'
          "
          @click="refresh"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkPrimaryColor
            }"
            >mdi-refresh
          </v-icon>
          <!-- Refresh icon -->
          Refresh
        </button>
        <button
          v-if="nodeType === 'camera'"
          :class="
            isUserAdmin && isDarkModeToggleEnabled
              ? 'config-dark-mode'
              : 'config-light-mode'
          "
          @click="goConfig"
          style="margin-left: 10px"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            mdi-cog
          </v-icon>
          <!-- Configs button -->
          Configs
        </button>
        <button
          v-else
          :class="'goback-light-mode'"
          @click="goBack"
          style="margin-left: 10px"
        >
          <v-icon
            size="22"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.darkPrimaryColor
                  : getColors.darkPrimaryColor
            }"
          >
            mdi-arrow-left
          </v-icon>
          <!-- Go back button -->
          Go Back
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const namespaceUser = { namespace: 'user' }
import Header from '../Header.vue'
import router from '@/router'

const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Header
  }
})
export default class NoCamera extends Vue {
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Prop({ default: 'node' }) nodeType: string
  @Prop({}) customText: string
  @Prop({}) customTitle: string

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }
  public get isUserWorker() {
    return this.currentUser?.role === 'Worker'
  }

  public goBack() {
    this.$router.go(-1)
  }
  //refresh page
  public refresh() {
    window.location.reload()
  }
  //go back
  public goConfig() {
    // Get the current URL
    const currentUrl = this.$router.currentRoute.fullPath

    // Replace "live" with "configurations"
    const newUrl = currentUrl.replace('/live/', '/configurations/')

    // Navigate to the new URL
    router.push(newUrl)
  }
}
</script>

<style scoped>
.refresh-light-mode {
  color: black;
  border-radius: 3px;
  border: 1px solid #ffd42a;
  background: #fff;
  padding: 5px 16px; /* Adjust padding as needed */
}

.refresh-dark-mode {
  color: white;
  border-radius: 3px;
  border: 2px solid #ffd42a;
  background: #333333;
  padding: 5px 16px; /* Adjust padding as needed */
}

.config-light-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.config-dark-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.goback-light-mode {
  color: black;
  border: 2px solid #ffd42a;
  border-radius: 3px;
  background: #ffd42a;
  padding: 5px 16px; /* Adjust padding as needed */
}

.image {
  padding: 0%;
}

.container-no-camera {
  transition: transform 0.3s ease;
}

@media only screen and (max-width: 480px) and (max-height: 950px) and (orientation: portrait) {
  .container-no-camera {
    padding-top: 80px;
    transform: scale(0.8); /* Scale down to 80% */
  }
}

/* Styles for mobile devices in landscape mode */
@media only screen and (max-width: 950px) and (max-height: 480px) and (orientation: landscape) {
  .container-no-camera {
    padding-top: 20px;
    display: flex;
    flex-direction: row !important;
    transform: scale(0.8); /* Scale down to 80% */
  }
  .dark-mode-image {
    padding-right: 40px;
  }
  .light-mode-image {
    padding-right: 40px;
  }
}
</style>
