const getters = {
  getNodes: (state: any) => {
    return state.nodes
  },
  getCreateNodeStatus: (state: any) => {
    return state.createNodeStatus
  }
}

export default {
  ...getters
}
