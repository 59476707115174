var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.filteredAlarmsAccumulatedOverTimeStatus !== 'Ready')?_c('div',{staticClass:"loading-spinner"},[(_vm.filteredAlarmsAccumulatedOverTimeStatus === 'Error')?_c('div',[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" There is an error while loading this chart ")])]):_vm._e(),(_vm.filteredAlarmsAccumulatedOverTimeStatus === 'Loading')?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_vm._e()]):_vm._e(),(_vm.filteredAlarmsAccumulatedOverTimeStatus === 'Ready')?_c('div',{staticClass:"my-2 pa-4"},[(_vm.filteredAlarmsAccumulatedOverTimeData.length > 1)?_c('div',[_c('GChart',{key:"reduction-chart",attrs:{"type":"AreaChart","data":_vm.modifiedChartData,"options":_vm.reductionChartOptions}})],1):_c('div',[_c('p',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" There is not enough data to show this chart ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }