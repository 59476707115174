import {
  getCompleteTreeViewNodes,
  getTreeViewSiteAndCameraNodes
} from '../../../services/api-admin/nodes/nodes'
import {
  formatNodeStructure,
  handleCameraNodeListner,
  handleClientNodeListner,
  handleSiteNodeListner,
  NodeType,
  removeLoadMoreEntry,
  savePaginatedNodesToTheRightPlace,
  swapAddNewNodeEntry
} from '@/utils/NodeHelper'
import {
  BaseNodeFetchParams,
  ListnerParams,
  PaginatedNodeFetchParams
} from './definitions'

let subscriptions = {
  user: null,
  client: null,
  site: null,
  camera: null
}

const actions = {
  async fetchCompleteTreeViewNodes(
    { commit, state },
    payload: BaseNodeFetchParams
  ) {
    const {
      loadMoreMeta,
      size,
      search,
      isAdmin,
      isUserCustomer,
      isWriteEnabled,
      refresh,
      boostIds,
      userId,
      boostSecondaryId
    } = payload
    const page = loadMoreMeta?.page ? loadMoreMeta?.page + 1 : 1
    const from = (page - 1) * size
    let searchText = search
    if (loadMoreMeta?.meta !== undefined && search && search !== '') {
      searchText = loadMoreMeta?.meta?.search ? search : ''
    }
    let params = {
      size,
      from,
      'user-id': userId,
      search: searchText,
      boostSecondaryId
    }
    if (boostIds) {
      params = { ...params, ...boostIds }
    }
    try {
      const response = await getCompleteTreeViewNodes(params)
      const data = formatNodeStructure({
        data: response.data,
        userId,
        page,
        size,
        isAdmin,
        isUserCustomer,
        isWriteEnabled,
        loadMoreMeta
      })
      const loadMoreId = loadMoreMeta?.id
      if (state.nodes.length > 0 && page > 1 && !refresh) {
        const allNodes = [...state.nodes]
        if (userId && isAdmin) {
          const userIndex = allNodes.findIndex((item) => item.id === userId)
          if (userIndex > -1) {
            const clientList = allNodes[userIndex].children
            if (loadMoreId) {
              removeLoadMoreEntry(clientList, loadMoreId)
            }
            clientList.push(...data)
            swapAddNewNodeEntry(clientList)
            allNodes[userIndex].children = clientList
            commit('setNodes', allNodes)
          }
        } else {
          if (loadMoreId) {
            removeLoadMoreEntry(allNodes, loadMoreId)
          }
          const list = [...allNodes, ...data]
          swapAddNewNodeEntry(list)
          commit('setNodes', list)
        }
      } else {
        commit('setNodes', data)
      }
    } catch (error) {
      console.log('Error in fetchCompleteTreeViewNodes', error)
    }
  },
  async fetchTreeViewSiteAndCameraNodes(
    { commit, state },
    payload: PaginatedNodeFetchParams
  ) {
    const { size, search, isAdmin, boostIds, loadMoreMeta, boostSecondaryId } =
      payload

    const clientId = loadMoreMeta?.clientDocId || loadMoreMeta.clientFirestoreId
    const siteId = loadMoreMeta?.siteDocId || loadMoreMeta?.siteFirestoreId
    const type = loadMoreMeta.type
    const userId = loadMoreMeta?.userFirestoreId
    loadMoreMeta?.type === NodeType.CLIENT
      ? loadMoreMeta.userFirestoreId
      : undefined
    const page = loadMoreMeta?.page ? loadMoreMeta?.page + 1 : 1
    const from = (page - 1) * size
    let searchText = search
    if (loadMoreMeta?.meta !== undefined && search && search !== '') {
      searchText = loadMoreMeta?.meta?.search ? search : ''
    }
    let params = {
      size,
      from,
      'user-id': userId,
      'client-id': clientId,
      'site-id': siteId,
      search: searchText,
      boostSecondaryId
    }
    if (boostIds) {
      params = { ...params, ...boostIds }
    }
    if (type === 'camera') {
      delete params['client-id']
    } else if (type === 'site') {
      delete params['site-id']
    }
    try {
      const response = await getTreeViewSiteAndCameraNodes(params)
      if (state.nodes.length > 0) {
        const allNodes = [...state.nodes]
        const loadMoreId = loadMoreMeta?.id
        savePaginatedNodesToTheRightPlace(allNodes, {
          userId,
          siteId,
          clientId,
          isAdmin,
          page,
          size,
          loadMoreId,
          data: response.data,
          type
        })
        commit('setNodes', allNodes)
      }
    } catch (error) {
      console.log('Error in fetchTreeViewSiteAndCameraNodes', error)
    }
  },
  async listenTofirestoreNodeChanges(
    { commit, state }: any,
    params: ListnerParams
  ) {
    subscriptions.client = await handleClientNodeListner(params, {
      commit,
      state
    })
    subscriptions.site = await handleSiteNodeListner(params, {
      commit,
      state
    })
    subscriptions.camera = await handleCameraNodeListner(params, {
      commit,
      state
    })
  },
  unsubscribeNodes() {
    if (subscriptions) {
      Object.keys(subscriptions).map((key) => {
        if (subscriptions[key]) {
          subscriptions[key]()
        }
      })
    }
  }
}

export default {
  ...actions
}
