export default class SnackBarError {
  public color: SnackBarColor = ''
  public text: string = ''
  public snackbar: boolean = false
  public mode: string = 'vertical'
  public timeout: number = 3000
  public multiLine: boolean = false

  constructor(
    color: SnackBarColor,
    text: string,
    snackbar: boolean = true,
    timeout: number = 3000,
    multiLine: boolean = false
  ) {
    this.color = color
    this.text = text
    this.snackbar = snackbar
    this.timeout = timeout
    this.multiLine = multiLine
  }
}

export type SnackBarColor = '' | 'success' | 'info' | 'error' | 'warning'
