export const getDefaultState = () => {
  return {
    nodes: [],
    createNodeStatus: undefined
  }
}

const state = getDefaultState()

export default {
  ...state
}
