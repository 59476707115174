<template>
  <v-card
    class="refresh-container"
    v-if="versionChanged && $root.env !== 'development'"
    elevation="0"
  >
    <div class="notification-body">
      <div class="notification-button">
        <h4 class="text-center">An Update is available</h4>
        <p class="text-center">
          Please, save all current work and click on “Update” button below. You
          can also accept these updates by refreshing your browser at any time.
        </p>
        <p class="text-center">
          Avoiding updates may become the reason for errors.
        </p>
      </div>
      <div class="refresh-button text-center">
        <v-btn
          @click="reloadApp"
          text
          outlined
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor,
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkBlackColor
                : getColors.lightPrimaryColor,
            textTransform: 'capitalize'
          }"
        >
          Update
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { auth } from '@/provider/firebase'
import { GIT_TAG } from '@/utils/EnvMapping'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config', name: 'config' }

@Component
export default class UpdateAlert extends Vue {
  @Getter('currentUser', namespaceUser) private currentUser!: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('getVersionNumberFromFirestore', namespaceConfig)
  private getVersionNumberFromFirestore!: () => Promise<string>

  public versionChanged: boolean = false
  private newVersion: string = ''
  private currentVersion: string = ''

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  private async checkVersionFromFirebase(): Promise<void> {
    try {
      this.newVersion = await this.getVersionNumberFromFirestore()
      this.currentVersion = localStorage.getItem('version')
      if (!this.currentVersion || this.currentVersion === '0.0.0') {
        this.currentVersion = GIT_TAG
        localStorage.setItem('version', this.newVersion)
        this.currentVersion = this.newVersion
      }
      if (this.newVersion !== this.currentVersion) {
        this.showUpdateAlert()
      }
    } catch (error) {
      console.error('Error checking version from Firebase: ', error)
    }
  }

  private showUpdateAlert(): void {
    this.versionChanged = true
  }

  public reloadApp(): void {
    localStorage.setItem('version', this.newVersion)
    this.versionChanged = false
    auth.signOut()

    if ('caches' in window) {
      caches
        .keys()
        .then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.open(cacheName).then((cache) => {
              cache.delete('/')
            })
          })
        })
        .catch((error) => {
          console.error('Error clearing cache:', error)
        })
    }
    window.location.href += '?random=' + Math.random()
  }

  private created(): void {
    this.checkVersionFromFirebase()
  }
}
</script>

<style lang="stylus" scoped>
@import '../../variables.styl';

.refresh-container {
  width: 250px;
  height: 240px;
  position: fixed;
  bottom: 52px;
  right: 60px;
  padding: 15px;
  background-color: $hagl-pmq-yellow;
  border-radius: 8px 8px 0 8px;
  color: black;
  z-index: 1000;
}
.refresh-container h4 {
  font-weight: bold;
  font-size: 14px; /* Reduced font size to 14px */
}

.refresh-container p {
  font-size: 14px;
  margin-bottom: 8px;
}
.refresh-container:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid $hagl-pmq-yellow;
  border-top: 10px solid $hagl-pmq-yellow;
  border-bottom: 10px solid transparent;
  border-radius: 0 0 6px 0;
  left: auto;
  right: 0px;
  bottom: -18px;
}
</style>
