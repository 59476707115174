import { HttpStatusCode } from 'axios'
import { StreamStatus, WHEPClientConfig, WHEPClientStatus } from './interfaces'
import { linkToIceServers, parseOffer, generateSdpFragment } from './utils'

export class WHEPClient {
  private video: HTMLVideoElement
  private pc: RTCPeerConnection | null = null
  private restartTimeout: number | null = null
  private eTag: string = ''
  private queuedCandidates: RTCIceCandidate[] = []
  private offerData: {
    iceUfrag: string
    icePwd: string
    medias: string[]
  } | null = null
  private resourceUrl: string = ''
  private streamError = false

  private readonly whepUrl: string
  private readonly streamPath: string
  private readonly restartDelay: number
  private observer: IntersectionObserver
  private updateStreamStatus: (StreamStatus) => void

  constructor(config: WHEPClientConfig) {
    this.video = config.videoElement
    this.whepUrl = config.whepUrl
    this.streamPath = config.streamPath
    this.updateStreamStatus = config.updateStatus
    this.restartDelay = config.restartDelay || 2000 // Default to 2 seconds
    // Set up the IntersectionObserver to monitor visibility
    this.observer = new IntersectionObserver(
      this.handleVisibilityChange.bind(this),
      {
        threshold: 0.1 // Trigger when at least 10% of the video is visible
      }
    )
    this.observer.observe(this.video)
  }

  private handleVisibilityChange(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        this.stop()
      } else {
        this.start()
      }
    })
  }
  private updateStatus(text: string): void {
    if (text === 'Connected') {
      this.updateStreamStatus(StreamStatus.CONNECTED)
    } else {
      if (this.streamError) {
        this.updateStreamStatus(StreamStatus.FAILED)
      } else {
        this.updateStreamStatus(StreamStatus.LOADING)
      }
    }
  }

  public start(): void {
    this.updateStatus('Connecting to the stream...')
    fetch(new URL(this.streamPath, this.whepUrl), {
      method: 'OPTIONS'
    })
      .then((res) => {
        this.updateStatus('Preparing the connection...')
        this.onIceServers(res)
      })
      .catch((err) => {
        console.error('Error fetching connection details:', err)
        this.updateStatus('Unable to connect. Retrying...')
        this.scheduleRestart()
      })
  }

  public stop(): void {
    this.updateStatus('Stream stopped')
    if (this.restartTimeout !== null) {
      window.clearTimeout(this.restartTimeout)
      this.restartTimeout = null
    }

    if (this.pc !== null) {
      this.pc.close()
      this.pc = null
    }
  }

  private onIceServers(res: Response): void {
    const iceServers = linkToIceServers(res.headers.get('Link'))
    this.pc = new RTCPeerConnection({ iceServers })

    this.pc.addTransceiver('video', { direction: 'sendrecv' })
    this.pc.addTransceiver('audio', { direction: 'sendrecv' })

    this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt)
    this.pc.oniceconnectionstatechange = () => this.onConnectionState()
    this.pc.ontrack = (evt) => {
      if (evt.streams[0]) {
        this.video.srcObject = evt.streams[0]
        this.updateStatus('Connection established')
      }
    }

    this.updateStatus('Connecting to the server...')
    this.pc.createOffer().then((offer) => this.onLocalOffer(offer))
  }

  private onLocalOffer(offer: RTCSessionDescriptionInit): void {
    this.updateStatus('Setting up the stream...')
    const offerSdp = offer.sdp || ''
    this.offerData = parseOffer(offerSdp)

    this.pc?.setLocalDescription(offer).catch((err) => {
      console.error('Error setting up the stream:', err)
      this.updateStatus('Failed to set up the stream. Retrying...')
      this.scheduleRestart()
    })

    fetch(new URL(this.streamPath, this.whepUrl), {
      method: 'POST',
      headers: { 'Content-Type': 'application/sdp' },
      body: offerSdp
    })
      .then((res) => {
        if (res.status !== HttpStatusCode.Created) {
          throw new Error('Unexpected response from the server')
        }
        this.resourceUrl = res.headers.get('Location') || ''
        this.eTag = res.headers.get('ETag') || ''
        return res.text()
      })
      .then((sdp) => {
        this.updateStatus('Stream is available, connecting...')
        this.onRemoteAnswer(
          new RTCSessionDescription({
            type: 'answer',
            sdp
          })
        )
      })
      .catch((err) => {
        console.error('Error setting up the stream:', err)
        this.updateStatus('Failed to start the stream. Retrying...')
        this.scheduleRestart()
      })
  }

  private onRemoteAnswer(answer: RTCSessionDescriptionInit): void {
    if (this.restartTimeout !== null) {
      return
    }

    this.pc?.setRemoteDescription(answer).catch((err) => {
      console.error('Error processing server response:', err)
      this.updateStatus('Connection lost. Retrying...')
      this.scheduleRestart()
    })

    if (this.queuedCandidates.length > 0) {
      this.sendLocalCandidates(this.queuedCandidates)
      this.queuedCandidates = []
    }
  }

  private onLocalCandidate(evt: RTCPeerConnectionIceEvent): void {
    if (this.restartTimeout !== null) {
      return
    }

    if (evt.candidate) {
      if (!this.eTag) {
        this.queuedCandidates.push(evt.candidate)
      } else {
        this.sendLocalCandidates([evt.candidate])
      }
    }
  }

  private sendLocalCandidates(candidates: RTCIceCandidate[]): void {
    if (!this.resourceUrl || !this.eTag) {
      console.error('Unable to send connection details')
      this.updateStatus('Connection lost. Retrying...')
      this.scheduleRestart()
      return
    }

    fetch(new URL(this.resourceUrl, this.whepUrl), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/trickle-ice-sdpfrag',
        'If-Match': this.eTag
      },
      body: generateSdpFragment(this.offerData, candidates)
    })
      .then((res) => {
        if (res.status !== HttpStatusCode.NoContent) {
          throw new Error('Unexpected response from the server')
        }
      })
      .catch((err) => {
        console.error('Error sending connection details:', err)
        this.updateStatus('Connection lost. Retrying...')
        this.scheduleRestart()
      })
  }

  private onConnectionState(): void {
    if (this.restartTimeout !== null) {
      return
    }

    switch (this.pc?.iceConnectionState) {
      case WHEPClientStatus.DISCONNECTED:
      case WHEPClientStatus.FAILED:
        this.updateStatus('Connection lost. Retrying...')
        this.scheduleRestart()
        break
      case WHEPClientStatus.CONNECTED:
        this.updateStatus('Connected')
        break
      case WHEPClientStatus.COMPLETED:
        this.updateStatus('Stream is stable')
        break
      case WHEPClientStatus.CHECKING:
        this.updateStatus('Verifying connection...')
        break
      case WHEPClientStatus.CLOSED:
        this.updateStatus('Connection closed')
        break
      case WHEPClientStatus.NEW:
        this.updateStatus('Starting connection...')
        break
      default:
        this.updateStatus('Connection failed')
        this.streamError = true
    }
  }

  private scheduleRestart(): void {
    if (this.restartTimeout !== null) {
      return
    }

    this.updateStatus('Attempting to reconnect...')
    if (this.pc !== null) {
      this.pc.close()
      this.pc = null
    }

    this.restartTimeout = window.setTimeout(() => {
      this.restartTimeout = null
      this.start()
    }, this.restartDelay)

    this.eTag = ''
    this.queuedCandidates = []
    this.resourceUrl = ''
  }
}
