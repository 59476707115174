<template>
  <div class="text-center" style="z-index: 10001">
    <v-menu top :offset-y="offset">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
          title="More actions"
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlackColor
          }"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list :dark="isDarkModeToggleEnabled">
        <v-list-item
          @click="item.action"
          v-for="(item, index) in navigationMenuItems"
          :key="index"
          :disabled="item.disabled"
          :title="item.tooltip"
        >
          <v-list-item-icon>
            <v-icon
              :color="!isDarkModeToggleEnabled ? item.color : ''"
              :disabled="item.disabled"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
            :color="!isDarkModeToggleEnabled ? item.color : ''"
            :disabled="item.disabled"
            class="menu-item"
            >{{ item.text }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
import { Getter, Mutation } from 'vuex-class'

interface NodeInformation {
  type: 'client' | 'site' | 'camera'
  userFirestoreId: string
  itemId?: string
  clientId?: string
  siteId?: string
}

@Component
export default class NavigationMenu extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  public offset: boolean

  @Prop()
  public nodeInformation: NodeInformation

  @Prop({
    default: false,
    type: Boolean
  })
  public showHamBurgerMenu: boolean

  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Mutation('setIsToggleMenuEnabled', namespaceConfig)
  public setIsToggleMenuEnabled: (value: boolean) => void

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public navigationMenuItems = [
    {
      disabled: true,
      text: 'Move',
      value: 'move',
      icon: 'mdi-arrow-left-bottom-bold',
      tooltip: 'Move tree node to another location',
      color: 'black',
      action: () => {
        console.log('Move')
      }
    },
    {
      disabled: false,
      text: 'Edit',
      value: 'edit',
      icon: 'mdi-pencil',
      tooltip: 'Edit tree node',
      color: 'black',
      action: this.navigateToConfigPage
    }
    // ,
    // {
    //   disabled: false,
    //   text: 'Unlink',
    //   value: 'unlink',
    //   icon: 'mdi-account-remove',
    //   tooltip: 'Delete tree node',
    //   color: 'red',
    //   action: () => {
    //     alert('Please user a desktop browser to unlink/delete tree nodes')
    //   }
    // }
  ]

  private navigateToConfigPage(): void {
    const { type, userFirestoreId, itemId, clientId, siteId } =
      this.nodeInformation

    let routerPath: string

    switch (type) {
      case 'client':
        routerPath = `/configurations/${userFirestoreId}/${itemId}`
        break
      case 'site':
        routerPath = `/configurations/${userFirestoreId}/${clientId}/${itemId}`
        break
      case 'camera':
        routerPath = `/configurations/${userFirestoreId}/${clientId}/${siteId}/${itemId}`
        break
    }

    if (this.$route.path !== routerPath) {
      this.showHamBurgerMenu && this.setIsToggleMenuEnabled(true)
      this.$router.push({ path: routerPath })
    }
  }
}
</script>

<style scoped>
.menu-item:hover {
  background-color: #ffe88e !important;
  color: black !important;
}
.menu-item:active {
  background-color: #ffd42a !important;
}
.v-list {
  text-align: left;
}
</style>
