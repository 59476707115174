var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-flex',{staticClass:"pt-1 ml-1",attrs:{"row":""}},[_c('Header',{staticStyle:{"font-size":"1.25rem","padding-top":"18px"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      }),attrs:{"bannerTitle":'Manage Requests List',"data-test-id":"manage-requests-list-title"},scopedSlots:_vm._u([{key:"headerActionItems",fn:function(){return [_c('v-flex',{staticClass:"user-management__sticky-header"},[_c('v-flex',{attrs:{"row":""}},[_c('v-col',[_c('v-text-field',{staticStyle:{"width":"300px","margin-left":"auto"},attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","label":"Search","data-test-id":"search-request-input","single-line":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1)]},proxy:true}])})],1),_c('v-data-table',{staticStyle:{"padding":"0 28px"},style:({
      backgroundColor:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.darkPrimaryColor
          : _vm.getColors.lightPrimaryColor
    }),attrs:{"headers":_vm.userHeaders,"items":_vm.users,"search":_vm.searchText,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItems
    },"must-sort":"","items-per-page":10,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"loading":_vm.isProcessing,"data-test-id":"request-table","item-class":() => 'result-item'},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":"","color":"secondary"}})]},proxy:true},{key:"item.id",fn:function({ item }){return [_c('Avatar',{staticStyle:{"padding-top":"6px"},attrs:{"id":item.id}})]}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"button-container"},[_c('Button',{class:[
            'text-subtitle-2',
            'approve-btn',
            {
              'approve-btn-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
            }
          ],attrs:{"text":"","btnText":"Approve","btnStyle":"outlined","data-test-id":"approve-user-button"},on:{"onButtonClick":function($event){return _vm.openConfirmDialog(item.id, true)}}}),_c('Button',{class:[
            'text-subtitle-2',
            'reject-btn',
            {
              'reject-btn-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
            }
          ],attrs:{"text":"","btnText":"Reject","btnStyle":"outlined","data-test-id":"reject-user-button"},on:{"onButtonClick":function($event){return _vm.openConfirmDialog(item.id, false)}}})],1)]}}])},[(_vm.isLoading)?_c('template',{slot:"no-data"},[_c('tr',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)]):_c('template',{slot:"no-data"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99"}},[_vm._v(" No data available ")])])]),_c('template',{slot:"no-results"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{staticClass:"gt-table--center",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99","data-test-id":"search-no-results-message"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchText)+"\" returned no results. ")])])])],2),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',{staticStyle:{"padding":"10px"},style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor
      }),attrs:{"data-test-id":"request-action-dialog"}},[_c('v-toolbar',{staticClass:"secondary elevation-0",attrs:{"dark":""}},[_c('v-toolbar-title',{staticStyle:{"color":"black"},attrs:{"data-ci-user-management-deletion-confirm-header":"true"}},[_vm._v(" Confirm "+_vm._s(_vm.approveDialog ? 'Approve' : 'Reject')+" ")])],1),_c('v-card-text',{staticClass:"pt-5",style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkPrimaryColor
        })},[_vm._v(" Are you sure you want to "+_vm._s(_vm.approveDialog ? 'approve' : 'reject')+" this user? ")]),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('Button',{class:[
            'text-subtitle-2',
            'cancel-btn',
            {
              'cancel-btn-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
            }
          ],style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          }),attrs:{"text":"","btnText":"Cancel","btnStyle":"outlined","data-test-id":"request-cancel-action-btn"},on:{"onButtonClick":function($event){_vm.confirmDialog = false
            _vm.approveDialog = false}}}),(_vm.approveDialog)?_c('Button',{staticClass:"text-subtitle-2",attrs:{"loading":_vm.isActionProcessing,"elevation":"0","btnText":"Approve","data-test-id":"request-approve-action-btn"},on:{"onButtonClick":function($event){return _vm.approveUser(_vm.selectedUserId)}}}):_vm._e(),(!_vm.approveDialog)?_c('Button',{staticClass:"text-subtitle-2",attrs:{"loading":_vm.isActionProcessing,"elevation":"0","btnText":"Reject","data-test-id":"request-reject-action-btn"},on:{"onButtonClick":function($event){return _vm.rejectUser(_vm.selectedUserId)}}}):_vm._e()],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }