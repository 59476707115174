import moment, { Moment } from 'moment'
import { PROJECT_ID } from './EnvMapping'

export const alarmCountsForQuota = (
  startTime: Moment,
  endTime: Moment,
  subscriptionKeys: string[],
  userId?: string,
  clientId?: string,
  siteId?: string,
  cameraId?: string,
  authorizedUsers?: any,
  isUserAdmin?: boolean
) => {
  const filters: any[] = [
    {
      match_phrase: {
        environment: PROJECT_ID
      }
    }
  ]
  if (subscriptionKeys.length > 0) {
    const subscriptionKeyFilters = subscriptionKeys.map((subscriptionKey) => ({
      term: { 'subscriptionKey.keyword': subscriptionKey }
    }))

    filters.push({
      bool: {
        should: subscriptionKeyFilters,
        minimum_should_match: 1
      }
    })
  }

  if (clientId) {
    filters.push({ term: { 'clientId.keyword': clientId } })
  }

  filters.push({
    range: {
      createdAt: {
        format: 'strict_date_optional_time',
        gte: moment(startTime).toISOString(),
        lte: moment(endTime).toISOString()
      }
    }
  })

  const query = {
    index: 'managed-promiseq-data-warehouse-alias',
    aggs: {
      types_count: { value_count: { field: 'alarmId.keyword' } }
    },
    size: 0,
    query: {
      bool: {
        must: filters,
        should: [],
        must_not: [
          {
            term: { 'incidentEventData.isUnarmed': 'true' }
          }
        ]
      }
    }
  }

  if (authorizedUsers) {
    applyFiltersForAuthorizedTreeView(
      authorizedUsers,
      query.query.bool.must,
      userId,
      clientId,
      siteId,
      cameraId,
      isUserAdmin
    )
  }

  return query
}

export const workerStatisticsQuery = (workerId: string) => {
  const query = {
    index: `enterprise-search-engine-${PROJECT_ID}`,
    query: {
      bool: {
        filter: [
          {
            match: {
              worker__workerid: workerId
            }
          }
        ]
      }
    }
  }

  return query
}

export const filterForTreeView = (
  filters: any[],
  clientId?: string,
  siteId?: string,
  cameraId?: string
) => {
  if (cameraId) {
    filters.push({
      term: {
        'cameraId.keyword': cameraId
      }
    })
  }
  if (siteId) {
    filters.push({
      term: {
        'siteId.keyword': siteId
      }
    })
  }
  if (clientId) {
    filters.push({
      term: {
        'clientId.keyword': clientId
      }
    })
  }
}

export const filterForTreeViewEntSearchEng = (
  filters: any[],
  clientId?: string,
  siteId?: string,
  cameraId?: string
) => {
  if (cameraId) {
    filters.push({
      term: {
        'cameraid.enum': cameraId
      }
    })
  }
  if (siteId) {
    filters.push({
      term: {
        'siteid.enum': siteId
      }
    })
  }
  if (clientId) {
    filters.push({
      term: {
        'clientid.enum': clientId
      }
    })
  }
}

export const applyFiltersForAuthorizedTreeView = (
  authorizedUsers: any,
  filters: any[],
  userId?: string,
  clientId?: string,
  siteId?: string,
  cameraId?: string,
  isUserAdmin?: boolean
) => {
  if (Array.isArray(authorizedUsers)) {
    const clientIds: string[] = []
    const siteIds: string[] = []
    const cameraIds: string[] = []
    //Add filters for the admins
    if (isUserAdmin) {
      // If only camera is selected, check if it's authorized camera
      if (cameraId) {
        const authorizedCameras: string[] = []
        authorizedUsers.forEach((user: any) => {
          // Check if userId matches user.id
          if (user.id === userId) {
            user.children.forEach((client: any) => {
              client.sites.forEach((site: any) => {
                site.cameras.forEach((camera: any) => {
                  authorizedCameras.push(camera.cameraId)
                })
              })
            })
          }
        })
        if (authorizedCameras.includes(cameraId)) {
          filters.push({
            term: {
              'cameraId.keyword': cameraId
            }
          })
        }
      }
      // If only User is selected, check its authorized camera
      if (userId && !clientId && !siteId && !cameraId) {
        const authorizedUser = authorizedUsers.find(
          (user: any) => user.id === userId
        )
        if (authorizedUser) {
          authorizedUser.children.forEach((client: any) => {
            client.sites.forEach((site: any) => {
              site.cameras.forEach((camera: any) => {
                // Collecting all cameraIds
                cameraIds.push(camera.cameraId)
              })
            })
          })
        }
      }
      // If client is selected, check its authorized camera
      if (userId && clientId && !siteId && !cameraId) {
        const authorizedUser = authorizedUsers.find(
          (user: any) => user.id === userId
        )
        if (authorizedUser) {
          authorizedUser.children.forEach((client: any) => {
            if (client.clientId === clientId) {
              client.sites.forEach((site: any) => {
                siteIds.push(site.siteId)
                site.cameras
                  .filter((camera: any) => camera.siteId === site.siteId)
                  .forEach((camera: any) => {
                    cameraIds.push(camera.cameraId)
                  })
              })
            }
          })
        }
      }
      // If site is selected, check its authorized camera
      if (userId && clientId && siteId && !cameraId) {
        authorizedUsers.forEach((user: any) => {
          if (user.id === userId) {
            // Check if userId matches user.id
            user.children.forEach((client: any) => {
              if (client.clientId === clientId) {
                // Check if clientId matches client.clientId
                client.sites.forEach((site: any) => {
                  if (site.siteId === siteId) {
                    // Check if siteId matches site.siteId
                    site.cameras.forEach((camera: any) => {
                      cameraIds.push(camera.cameraId)
                    })
                  }
                })
              }
            })
          }
        })
      }
    } else {
      //Add filters for the customer
      if (cameraId) {
        const authorizedCameras: string[] = []
        authorizedUsers.forEach((user) => {
          user.sites.forEach((site: any) => {
            site.cameras.forEach((camera: any) => {
              authorizedCameras.push(camera.cameraId)
            })
          })
        })
        if (authorizedCameras.includes(cameraId)) {
          filters.push({
            term: {
              'cameraId.keyword': cameraId
            }
          })
        }
      }
      if (clientId && !siteId && !cameraId) {
        const authorizedUser = authorizedUsers.find(
          (user: any) => user.clientId === clientId
        )
        if (authorizedUser) {
          authorizedUser.sites.forEach((site: any) => {
            siteIds.push(site.siteId)
            site.cameras.forEach((camera: any) => {
              cameraIds.push(camera.cameraId)
            })
          })
        }
      }
      if (clientId && siteId && !cameraId) {
        authorizedUsers.forEach((user: any) => {
          if (user.clientId === clientId) {
            user.sites.forEach((site: any) => {
              if (site.siteId === siteId) {
                site.cameras.forEach((camera: any) => {
                  cameraIds.push(camera.cameraId)
                })
              }
            })
          }
        })
      }
    }
    if (cameraIds.length > 0) {
      filters.push({
        terms: {
          'cameraId.keyword': cameraIds
        }
      })
    }
    if (siteIds.length > 0) {
      filters.push({
        terms: {
          'siteId.keyword': siteIds
        }
      })
    } else if (siteId) {
      filters.push({
        term: {
          'siteId.keyword': siteId
        }
      })
    }
    if (clientIds.length > 0) {
      filters.push({
        terms: {
          'clientId.keyword': clientIds
        }
      })
    } else if (clientId) {
      filters.push({
        term: {
          'clientId.keyword': clientId
        }
      })
    }
  }
}
