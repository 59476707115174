<template>
  <div class="text-center">
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-text
          >Please insert your email address to get the reset
          instructions.</v-card-text
        >
        <v-text-field
          v-model="email"
          prepend-icon="mdi-account"
          name="email"
          label="Email"
          type="text"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary black--text"
            text
            @click="sendAPasswordResetEmail"
          >
            Reset
          </v-btn>
        </v-card-actions>
        <v-alert v-model="successRestore" type="success"
          >A recovery link to change your password was send to your email
          account!</v-alert
        >
        <v-alert v-model="failureRestore" type="error"
          >Please enter a valid email address before you can restore the
          password!</v-alert
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from '@/utils/EventBus'

export default {
  data() {
    return {
      dialog: false,
      successRestore: false,
      failureRestore: false,
      email: 'your@mail.dev'
    }
  },
  created() {
    const vm = this
    EventBus.$on('dialog', (value) => {
      vm.dialog = value
    })
  },
  beforeDestroy() {
    const vm = this
    EventBus.$off('dialog', (value) => {
      vm.dialog = value
    })
  },
  methods: {
    sendAPasswordResetEmail: () => {
      if (/\S+@\S+\.\S+/.test(this.email)) {
        this.dialog = false
        this.failureRestore = false
        this.successRestore = true

        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {})
          .catch((error) => {
            alert(error)
            this.successRestore = false
          })
      } else {
        this.failureRestore = true
        this.successRestore = false
      }
    }
  }
}
</script>
