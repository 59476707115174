<template>
  <v-col cols="5">
    <v-menu
      bottom
      offset-y
      :close-on-content-click="false"
      class="my-menu"
      v-model="isRefreshSettingsMenuOpen"
    >
      <template v-slot:activator="{ on }">
        <div class="split-btn">
          <v-btn
            outlined
            class="main-btn"
            :class="{
              'admin-btn': isUserAdmin && isDarkModeToggleEnabled,
              'main-btn-mobile': smallScreen
            }"
            @click="refresh()"
            ><v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
              data-test-id="refresh-button"
              >mdi-refresh</v-icon
            >
            <p
              class="ma-0 d-lg-flex text-subtitle-2"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
            >
              <span class="refresh-text">
                &nbsp; {{ refreshButtonText }} &nbsp;
              </span>
            </p></v-btn
          >
          <v-btn
            :style="{
              pointerEvents: 'auto'
            }"
            outlined
            v-on="on"
            class="actions-btn"
            :class="{
              'admin-actions-btn': isUserAdmin && isDarkModeToggleEnabled,
              'actions-btn-mobile': smallScreen
            }"
            data-test-id="auto-refresh-drop-down-button"
            ><v-icon
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : ''
              }"
              >mdi-pencil</v-icon
            ></v-btn
          >
        </div>
      </template>

      <v-card
        class="refresh-cards"
        width="210px"
        :style="{
          backgroundColor:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor
        }"
      >
        <v-container>
          <v-row no-gutters>
            <v-col cols="8">
              <p
                style="text-align: left; font-weight: bold"
                class="text-subtitle-2"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : ''
                }"
              >
                Auto refresh
              </p>
            </v-col>
            <v-col cols="4" class="">
              <v-switch
                class="refresh-switch"
                v-model="isRefreshEnabledLocal"
                @change="updatePeriodicRefresh('toggle')"
                inset
                color="secondary"
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                data-test-id="refresh-switch"
              />
            </v-col>
          </v-row>
          <v-divider
            horizontal
            :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
          ></v-divider>
          <v-row
            no-gutters
            class="refresh-dropdown-row"
            title="Set the refresh interval for the dashboard. Minimum refresh interval is 20 seconds."
          >
            <v-col cols="12">
              <p
                style="text-align: left; font-weight: bold"
                class="text-subtitle-2"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : ''
                }"
              >
                Refresh each
              </p>
            </v-col>
          </v-row>
          <v-row dense class="refresh-dropdown-input">
            <v-col cols="5" class="d-flex flex-row justify-start">
              <v-text-field
                dense
                outlined
                placeholder="1"
                type="number"
                v-model="refreshIntervalValue"
                min="1"
                max="59"
                :disabled="!isRefreshEnabledLocal"
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                data-test-id="refresh-interval-value"
              ></v-text-field>
            </v-col>
            <v-col cols="7" class="d-flex flex-row justify-start">
              <v-select
                dense
                v-model="refreshIntervalUnit"
                :items="timeUnit"
                outlined
                :disabled="!isRefreshEnabledLocal"
                :append-icon="'mdi-chevron-down'"
                :responsive="{ xs: 12, sm: 2 }"
                :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                :background-color="
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.darkFrameColor
                    : ''
                "
                data-test-id="refresh-interval-unit"
              >
                <template #prepend-item>
                  <v-card
                    flat
                    width="100%"
                    height="100%"
                    :style="{
                      backgroundColor:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.darkFrameColor
                          : '',
                      position: 'absolute',
                      top: '0px'
                    }"
                  />
                </template>
                <template #item="{ item }">
                  <span
                    :style="{
                      color:
                        isUserAdmin && isDarkModeToggleEnabled
                          ? getColors.lightPrimaryColor
                          : '',
                      margin: 'auto',
                      'font-size': '13px'
                    }"
                  >
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="refresh-dropdown-button">
            <v-col>
              <Button
                class="ma-2 lower-case selected text-subtitle-2"
                color="black"
                :ripple="false"
                @onButtonClick="updatePeriodicRefresh('settings')"
                btnText="Apply"
                :disabled="!isRefreshEnabledLocal"
                data-test-id="apply-button"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </v-col>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import moment from 'moment'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button
  }
})
export default class RefreshDropDown extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getIsAlarmRefreshEnabled', namespaceConfig) public isRefreshEnabled
  @Getter('getAutoRefreshSettings', namespaceConfig) public autoRefreshSettings
  @Getter('getGlobalTimeRange', namespaceConfig) public globalTimeRange: any

  @Mutation('setGlobalTimeRange', namespaceConfig) public setGlobalTimeRange

  @Action('subscribeToGlobalTimeFilter', namespaceConfig)
  private subscribeToGlobalTimeFilter
  @Action('unSubscribeFromGlobalTimeFilter', namespaceConfig)
  private unSubscribeFromGlobalTimeFilter
  @Action('updateUserRefreshEnabled', namespaceUser)
  public updateUserRefreshEnabled
  @Action('updateRefreshSettings', namespaceUser) public updateRefreshSettings

  public isRefreshEnabledLocal: boolean = false
  public timeUnit = ['min', 'sec', 'hour']
  public timeInput = ''
  public refreshIntervalValue: number = 1
  public refreshIntervalUnit: string = 'sec'
  private refreshIntervalInMilliseconds: number = 10000
  public refreshButtonText: string = 'Refresh'
  public isRefreshSettingsMenuOpen: boolean = false

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    // this.watchAutoRefreshSettings()
    // this.watchRouteQuery()
    this.onRefreshChange()
    this.onRefreshSettingsChange()
  }

  public refresh() {
    this.setGlobalTimeRange({
      startDateTime: this.globalTimeRange.startDateTime,
      endDateTime: moment()
    })
  }

  public get smallScreen() {
    return isCompactView()
  }

  @Watch('isRefreshEnabled')
  public onRefreshChange() {
    this.isRefreshEnabledLocal = this.isRefreshEnabled
    if (this.isRefreshEnabled) {
      this.subscribeToGlobalTimeFilter()
    } else {
      this.unSubscribeFromGlobalTimeFilter()
    }
    this.updateRefreshButtonText()
  }

  @Watch('autoRefreshSettings')
  @Watch('route')
  public onRefreshSettingsChange() {
    this.refreshIntervalValue = this.autoRefreshSettings.value ?? 10
    this.refreshIntervalUnit = this.autoRefreshSettings.unit ?? 'sec'
    this.refreshIntervalInMilliseconds =
      this.autoRefreshSettings.autoRefreshPeriod ?? 10000

    if (this.isRefreshEnabled) {
      this.subscribeToGlobalTimeFilter()
    } else {
      this.unSubscribeFromGlobalTimeFilter()
    }

    this.updateRefreshButtonText()
  }

  // Update the refresh settings to collection
  public async onUpdateRefreshSettings(
    type: 'toggle' | 'settings' | 'both' = 'both'
  ) {
    if (type === 'toggle' || type === 'both') {
      await this.updateToggle()
    }

    if (type === 'settings' || type === 'both') {
      await this.updateSettings()
    }
  }

  public updatePeriodicRefresh(type: 'toggle' | 'settings' | 'both' = 'both') {
    this.applyRefreshSettings()
    this.onUpdateRefreshSettings(type)
    this.refresh()
    this.closeMenu()
  }

  private closeMenu() {
    this.isRefreshSettingsMenuOpen = false
  }

  public applyRefreshSettings() {
    let periodicRefreshInMilliseconds = 1000 * 60

    if (this.refreshIntervalValue && this.refreshIntervalUnit) {
      switch (this.refreshIntervalUnit) {
        case 'sec':
          // minimum refresh interval is 20 seconds
          if (this.refreshIntervalValue < 20) {
            this.refreshIntervalValue = 20
          }
          periodicRefreshInMilliseconds = this.refreshIntervalValue * 1000

          break
        case 'min':
          periodicRefreshInMilliseconds = this.refreshIntervalValue * 60 * 1000
          break
        case 'hour':
          periodicRefreshInMilliseconds =
            this.refreshIntervalValue * 60 * 60 * 1000
          break
      }
    }

    this.refreshIntervalInMilliseconds = periodicRefreshInMilliseconds
  }

  public updateRefreshButtonText() {
    if (this.isRefreshEnabled) {
      this.refreshButtonText =
        'Each ' + this.refreshIntervalValue + ' ' + this.refreshIntervalUnit
    } else {
      this.refreshButtonText = 'Refresh'
    }
  }

  public beforeDestroy() {
    this.unSubscribeFromGlobalTimeFilter()
  }

  // @Watch('$route.query')
  // private async watchRouteQuery() {
  //   const refreshPeriodQueryParam = this.$route.query['refresh-period']

  //   if (refreshPeriodQueryParam) {
  //     if (this.isRefreshEnabled) {
  //       if (
  //         Number(refreshPeriodQueryParam) !==
  //         Number(this.autoRefreshSettings.autoRefreshPeriod)
  //       ) {
  //         await this.updateSettings(refreshPeriodQueryParam)
  //       }
  //     } else {
  //       await this.updateToggle(true)
  //       if (
  //         Number(refreshPeriodQueryParam) !==
  //         Number(this.autoRefreshSettings.autoRefreshPeriod)
  //       ) {
  //         await this.updateSettings(refreshPeriodQueryParam)
  //       }
  //     }
  //   } else {
  //     await this.updateToggle(false)
  //   }
  // }

  // @Watch('isRefreshEnabled')
  // @Watch('autoRefreshSettings')
  // private watchAutoRefreshSettings() {
  //   if (this.isRefreshEnabled) {
  //     const refreshPeriodQueryParam = this.$route.query['refresh-period']
  //     if (!refreshPeriodQueryParam) {
  //       this.$router.replace({
  //         query: {
  //           'refresh-period': this.autoRefreshSettings.autoRefreshPeriod
  //         }
  //       })
  //     } else {
  //       if (
  //         Number(refreshPeriodQueryParam) !==
  //         Number(this.autoRefreshSettings.autoRefreshPeriod)
  //       ) {
  //         this.$router.replace({
  //           query: {
  //             'refresh-period': this.autoRefreshSettings.autoRefreshPeriod
  //           }
  //         })
  //       }
  //     }
  //   } else {
  //     const { 'refresh-period': refreshPeriod, ...queryWithoutRefresh } =
  //       this.$route.query
  //     this.$router.replace({
  //       query: queryWithoutRefresh
  //     })
  //   }
  // }

  public async updateSettings(
    autoRefreshPeriod: any = this.refreshIntervalInMilliseconds
  ) {
    const currentSettings = {
      value: this.refreshIntervalValue,
      unit: this.refreshIntervalUnit,
      autoRefreshPeriod
    }
    await this.updateRefreshSettings({
      currentUserId: this.currentUser.id,
      autoRefreshSettings: currentSettings
    })
  }

  public async updateToggle(toggleValue: boolean = this.isRefreshEnabledLocal) {
    await this.updateUserRefreshEnabled({
      currentUserId: this.currentUser.id,
      isAlarmRefreshEnabled: toggleValue
    })
  }
}
</script>

<style>
.main-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 2px !important;
  text-transform: unset !important;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  height: 40px !important;
}
.main-btn-mobile {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 2px !important;
  text-transform: unset !important;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  height: 40px !important;
  left: 150px;
}
.admin-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 2px !important;
  text-transform: unset !important;
  background-color: #938d76 !important;
  border-color: #938d76 !important;
  height: 40px !important;
}
.actions-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 !important;
  min-width: 35px !important;
  margin-left: -0.5px;
  border-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  height: 40px !important;
}

.actions-btn-mobile {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 !important;
  min-width: 35px !important;
  margin-left: -0.5px;
  border-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  height: 40px !important;
  left: 150px;
}
.admin-actions-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 !important;
  min-width: 35px !important;
  margin-left: -0.5px;
  background-color: #333333;
  border: 2px solid #938d76;
  height: 40px !important;
}
.split-btn {
  display: inline-block;
  min-width: 210px;
  /* margin-left: -25px; */
}
.auto-refresh-menu {
  align-items: 'center' !important;
}

.my-menu .v-menu__content {
  box-shadow: none;
  top: 90px !important;
  background-color: brown;
}

.refresh-cards {
  height: 180px !important;
}

.refresh-dropdown-row {
  height: 20px;
}
.refresh-dropdown-input {
  height: 44px;
}
.refresh-dropdown-button {
  height: 20px;
}

.refresh-switch {
  position: relative;
  margin-right: 10px;
  height: 10px;
  margin-top: -4px;
  left: 20px;
}

@media screen and (max-width: 1280px) {
  .refresh-text {
    display: none;
  }

  .main-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 2px !important;
    text-transform: unset !important;
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    height: 40px !important;
    padding-left: 3px !important;
    min-width: 40px !important;
  }
}
</style>
