import { getDefaultState } from './state'

const mutations = {
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setLiveHealthChecks: (state, payload: any) => {
    state.liveHealthChecks = payload
  },
  setLiveHealthChecksLoading: (state, payload: any) => {
    state.liveHealthChecksLoading = payload
  },
  setLiveHcLoadMoreLoading: (state, payload: any) => {
    state.liveHcLoadMoreLoading = payload
  },
  appendLiveHealthChecks(state, newHealthChecks: any) {
    const existingIds = new Set(state.liveHealthChecks.map((item) => item.id))
    const uniqueNewHealthChecks = newHealthChecks.filter(
      (item) => !existingIds.has(item.id)
    )
    state.liveHealthChecks = state.liveHealthChecks.concat(
      uniqueNewHealthChecks
    )
  },
  setLastVisibleLiveHealthCheck: (state, payload: any) => {
    state.lastVisibleLiveHealthCheck = payload
  },
  setHasMoreLiveHealthChecks: (state, payload: any) => {
    state.hasMoreLiveHealthChecks = payload
  }
}

export default {
  ...mutations
}
