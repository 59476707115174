export interface IRouteState {
  userId: string
  path: string
  nodeRef: {
    id: string
    type: string
    parentId?: string
    grandParentId?: string
    userId?: string
  }
}

export interface ISnapshotState {
  route: IRouteState
}

export const getDefaultState = (): ISnapshotState => {
  return {
    route: {
      userId: '',
      path: '',
      nodeRef: {
        id: '',
        type: ''
      }
    }
  }
}

const state = getDefaultState()

export default {
  ...state
}
