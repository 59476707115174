<template>
  <div v-if="isValidSrc" class="small-card">
    <nav>
      <span>Reference Picture</span>
      <v-icon v-if="expanded" class="pr-lg-2 close-button" @click="close"
        >mdi-arrow-collapse</v-icon
      >
      <v-icon v-else class="pr-lg-2">mdi-arrow-expand</v-icon>
    </nav>
    <div class="loading-container" v-if="!imageSrc">
      <v-progress-circular indeterminate color="amber"></v-progress-circular>
    </div>
    <Overlay
      v-if="imageSrc"
      :src="imageSrc"
      type="image"
      :opacity="0.5"
      :controls="false"
      :excludedZones="excludedZonesArray"
      :showWarning="false"
      :styles="{
        width: '100%',
        height: '100%'
      }"
    />
  </div>

  <div v-else class="small-card">
    <nav>
      <span>There is No Reference Picture Available</span>
    </nav>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DisplayDrawing from '@/components/excluded-zones/DisplayDrawing.vue'
import Overlay from '@/components/excluded-zones/Overlay.vue'
import { Action } from 'vuex-class'

const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {
    DisplayDrawing,
    Overlay
  }
})
export default class ReferenceView extends Vue {
  @Prop({ required: true }) src!: string
  @Prop() fallbackSrc!: string
  @Prop({ required: true }) cameraDocId!: string
  @Prop({ required: true }) excludedZones!: any
  @Prop({ required: true }) expanded!: boolean
  @Prop({ default: 'reference-viewer' }) public canvasId: string
  @Prop({ default: 'reference-container' }) public containerId: string

  @Action('fetchReferenceImageFromPath', namespaceCamera)
  public fetchReferenceImage: any

  public excludedZonesArray: any = []
  public imageSrc = ''
  public isValidSrc: boolean = true

  mounted() {
    try {
      this.excludedZonesArray = JSON.parse(this.excludedZones)
    } catch (e) {}

    this.loadReferenceImage()
  }

  async loadReferenceImage() {
    const refImageUrl = await this.fetchReferenceImage(this.src)

    // If generating the signed url fails, use the pre signed url as fallback
    this.imageSrc = refImageUrl ?? this.fallbackSrc

    this.checkSrcValidity()
  }

  checkSrcValidity() {
    if (this.imageSrc) {
      const img = new Image()
      img.src = this.imageSrc
      img.onload = () => {
        this.isValidSrc = true
      }
      img.onerror = () => {
        this.isValidSrc = false
      }
    } else {
      this.isValidSrc = false
    }
  }

  public close() {
    this.$emit('close')
  }
}
</script>

<style scoped>
.small-card {
  display: flex;
  flex-direction: column;
}

.small-card nav {
  display: flex;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  background-color: rgb(171, 171, 171);
  font-size: 14px;
  padding: 8px;
  width: 100%;
}

.close-button {
  cursor: pointer;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
</style>
