var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"camera"},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}}):_vm._e(),(_vm.state.previewImageSrc)?_c('div',[_c('img',{class:{
        hidden: _vm.isLoading,
        'camera-video': !_vm.isLoading
      },attrs:{"src":_vm.state.previewImageSrc}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.state.previewImageSrc),expression:"!state.previewImageSrc"}]},[_c('video',{class:{
        hidden: _vm.isLoading,
        'camera-video': !_vm.isLoading
      },attrs:{"id":_vm.videoElementId,"controls":"","autoplay":"","playsinline":"","muted":""},domProps:{"muted":true}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }