var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticStyle:{"padding":"20px"},style:({
        backgroundColor:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.darkPrimaryColor
            : _vm.getColors.lightPrimaryColor
      })},[_c('div',[_c('h3',{staticClass:"mb-5",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkPrimaryColor
          })},[_vm._v(" Add a new "+_vm._s(_vm.nodeType)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":`${_vm.capitalize(_vm.nodeType)} Name`,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.nodeName),callback:function ($$v) {_vm.nodeName=$$v},expression:"nodeName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":`${_vm.capitalize(_vm.nodeType)} ID (optional)`,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled},model:{value:(_vm.nodeId),callback:function ($$v) {_vm.nodeId=$$v},expression:"nodeId"}})],1)],1),(_vm.isSaving)?_c('v-progress-circular',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.lightTreeItemsColor
          }),attrs:{"indeterminate":""}}):_vm._e(),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('Button',{class:[
              {
                'cancel-btn-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
              }
            ],style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            }),attrs:{"text":"","btnText":"Cancel","btnStyle":"outlined"},on:{"onButtonClick":_vm.closeDialog}}),_c('Button',{staticClass:"elevation-0 save-btn",style:(`background-color: ${_vm.getColors.darkSecondaryColor}`),attrs:{"btnText":"Save"},on:{"onButtonClick":_vm.addNode}})],1)],1)])],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('Button',_vm._b({staticClass:"secondary black--text",attrs:{"text":"","btnText":"Close","btnStyle":"outlined"},on:{"onButtonClick":function($event){_vm.snackbar = false}}},'Button',attrs,false))]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }