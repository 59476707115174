<template>
  <div
    :class="{ 'navigation-shrink': getIsToggleMenuEnabled }"
    class="header-component-container"
  >
    <div
      class="container--outline"
      :class="
        minified
          ? 'container--outline-minified'
          : smallScreen
          ? 'container--outline-smallscreen'
          : ''
      "
      style="height: 80px"
    >
      <v-row
        class="header-row"
        :class="minified ? 'header-row-minified' : ''"
        :style="{
          backgroundColor: minified
            ? isUserAdmin && isDarkModeToggleEnabled
              ? getColors.darkPrimaryColor
              : getColors.lightPrimaryColor
            : null
        }"
      >
        <v-col class="header-col">
          <!-- Menu Open/Close Button -->
          <v-btn
            icon
            class="menu-toggle-btn"
            @click="toggleMenu"
            v-if="showHamBurgerMenu"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlackColor
            }"
          >
            <v-icon
              >mdi-menu-{{ getIsToggleMenuEnabled ? 'close' : 'open' }}</v-icon
            >
          </v-btn>
          <v-row class="header-breadcrumbs-vrow">
            <v-breadcrumbs
              :class="
                minified
                  ? 'breadcrumbs-container-minified'
                  : smallScreen
                  ? 'breadcrumbs-container-smallscreen'
                  : ''
              "
              v-if="showBreadcrumb"
              :items="breadcrumbList"
              :large="true"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor
              }"
              data-test-id="breadcrumbs-container"
            >
              <template v-slot:divider>
                <v-icon
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlackColor
                  }"
                  >mdi-chevron-right</v-icon
                >
              </template>
              <template v-slot:item="{ item }">
                <a
                  v-if="!isCurrentPage(item)"
                  class="breadcrumb-item"
                  :class="[
                    smallScreen ? 'breadcrumb-item-smallscreen' : '',
                    smallScreen && isUserAdmin && isDarkModeToggleEnabled
                      ? 'breadcrumb-item-smallscreen-darkmode'
                      : ''
                  ]"
                  @click="onClickBreadCrumb(item)"
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : getColors.darkBlackColor
                  }"
                >
                  {{ item.text }}
                </a>
                <span
                  v-else
                  :class="[
                    smallScreen ? 'breadcrumb-item-smallscreen' : '',
                    smallScreen && isUserAdmin && isDarkModeToggleEnabled
                      ? 'breadcrumb-item-smallscreen-darkmode'
                      : ''
                  ]"
                >
                  {{ item.text }}
                </span>
              </template>
            </v-breadcrumbs>

            <!-- loader -->
            <v-progress-circular
              v-else-if="!minified && !smallScreen"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlackColor,
                marginLeft: '1rem',
                width: '12px'
              }"
              indeterminate
              rounded
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-col>
        <v-col
          class="header-refresh-timerange-col"
          v-if="!minified && !smallScreen"
        >
          <v-row class="header-refresh-timerange-row" no-gutters>
            <RefreshDropDown v-if="route === 'alarms'" />
            <TimeRangeSelector
              v-if="
                route === 'alarms' ||
                route === 'statistics' ||
                route === 'healthCheck'
              "
            />
          </v-row>
        </v-col>

        <v-col
          :class="
            route === routePath.Alarms
              ? 'header-refresh-timerange-col-mobile'
              : 'header-refresh-timerange-col'
          "
          v-if="!minified && smallScreen"
        >
          <InfoButton
            v-if="currentRouteInfo"
            :rows="currentRouteInfo"
            :is-alarms-route="route === routePath.Alarms"
          />

          <v-row class="header-refresh-timerange-row" no-gutters>
            <RefreshDropDown v-if="route === routePath.Alarms" />
            <TimeRangeSelector
              v-if="
                route === routePath.Alarms ||
                route === routePath.Statistics ||
                route === routePath.HealthCheck
              "
            />
          </v-row>
        </v-col>

        <v-col class="header-btn-vcol">
          <v-row no-gutters>
            <v-col class="header-btn-progress-vcol">
              <div v-if="isLoading && !minified" class="loading-spinner">
                <v-progress-circular indeterminate color="secondary" />
              </div>
              <v-btn-toggle
                v-if="!isLoading && (!smallScreen || minified)"
                v-model="toggle_exclusive"
                mandatory
                tile
              >
                <v-tooltip
                  v-for="(label, index) in buttonLabels"
                  :key="index"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="text-xl-body-2 text-md-caption"
                      :class="`${
                        isUserAdmin && isDarkModeToggleEnabled
                          ? 'admin-header-btn'
                          : 'header-btn'
                      } ${
                        minified || getIsToggleMenuEnabled
                          ? 'header-btn-minified'
                          : ''
                      }`"
                      active-class="header-btn--selected"
                      @click="buttonActions[index]"
                      :disabled="isBtnDisabled[index]"
                      v-on="on"
                    >
                      <v-icon
                        :class="minified ? 'icon-minified' : ''"
                        :style="{
                          color:
                            isUserAdmin && isDarkModeToggleEnabled
                              ? getColors.lightPrimaryColor
                              : getColors.darkBlackColor
                        }"
                        >{{ buttonIcons[index] }}</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{ label }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import moment from 'moment'
import Button from '@/components/app/Button.vue'
import RefreshDropDown from '../time-filter/RefreshDropdown.vue'
import TimeRangeSelector from '../time-filter/TimeRangeSelector.vue'
import Avatar from '@/components/user/Avatar.vue'
import { isCompactView } from '@/utils/ResponsiveDesign/ResponsiveDesign'
import InfoButton from '@/views/InfoButton.vue'
import {
  infoButtonData,
  Route,
  InfoButtonData,
  InfoButtonRow
} from '@/utils/infoButtonData'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button,
    RefreshDropDown,
    TimeRangeSelector,
    Avatar,
    InfoButton
  }
})
export default class ClientHeader extends Vue {
  @Prop({ type: String, required: true }) readonly route!: Route
  @Prop({ default: false }) public minified: boolean

  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getIsAlarmRefreshEnabled', namespaceConfig)
  public isRefreshEnabled: boolean
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getBreadcrumbList', namespaceConfig) public breadcrumbList
  @Getter('allClientConfig', namespaceConfig) public allClientConfig
  @Getter('getIsToggleMenuEnabled', namespaceConfig)
  public getIsToggleMenuEnabled

  @Action('syncCurrentUser', namespaceUser) public syncCurrentUser
  @Mutation('setIsToggleMenuEnabled', namespaceConfig)
  public setIsToggleMenuEnabled: (value: boolean) => void

  public toggle_exclusive: any = []

  public userId: string = ''
  public clientId: string = ''
  private siteId: string = ''
  public cameraId: string = ''

  public isLoading: boolean = false
  public isTabletOrMobile: boolean = false
  public isLandscapeTablet: boolean = false
  public isPortraitTablet: boolean = false
  private infoButtonData: InfoButtonData = infoButtonData
  public routePath = Route
  // Add index in sequence once every page is available
  public links: any = {
    healthCheck: { index: 0, route: '/health-check' },
    alarms: { index: 1, route: '/alarms' },
    live: { index: 2, route: '/live' },
    maps: { index: 3, route: '/maps' },
    statistics: { index: 4, route: '/statistics' },
    configurations: { index: 5, route: '/configurations' },
    userManagement: { index: 6, route: '/user-management' }
  }

  get currentRouteInfo(): InfoButtonRow[] | null {
    return this.infoButtonData[this.route] || null
  }

  public buttonLabels = [
    'Health Check',
    'Alarms',
    'Live',
    'Maps',
    'Statistics',
    'Configurations',
    'User Management'
  ]

  public buttonIcons = [
    'mdi-alert-circle',
    'mdi-alarm-light',
    'mdi-video',
    'mdi-map-marker',
    'mdi-chart-areaspline',
    'mdi-cog',
    'mdi-account-cog'
  ]

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  mounted() {
    this.isLoading = true
    this.updateHeaderButtons()
    this.syncForUpdates()
    if (this.route && this.links[this.route]) {
      this.toggle_exclusive = this.links[this.route]?.index ?? 1
    }
    this.watchParams()
    this.isLoading = false
    // Initialize properties
    this.updateScreenProperties()

    // Add event listener for window resize
    window.addEventListener('resize', this.updateScreenProperties)
  }

  beforeDestroy() {
    // Remove event listener when component is destroyed
    window.removeEventListener('resize', this.updateScreenProperties)
  }

  public get showBreadcrumb() {
    if (this.smallScreen) {
      // check weather the url and it has /live show the breadcrumb othervise not
      return this.route == 'live'
    }
    if (!this.minified && this.breadcrumbList.length > 0 && !this.smallScreen) {
      return true
    }
  }

  public get showHamBurgerMenu() {
    return (
      isCompactView() ||
      (this.isTabletOrMobile &&
        (this.isLandscapeTablet || this.isPortraitTablet))
    )
  }

  public get smallScreen() {
    return isCompactView()
  }

  @Watch('breadcrumbList')
  private watchBreadcrumbList() {
    this.updateHeaderButtons()
  }
  public updateScreenProperties() {
    this.isTabletOrMobile = this.detectTabletOrMobile()
    this.isLandscapeTablet = this.detectLandscapeTablet()
    this.isPortraitTablet = this.detectPortraitTablet()
  }

  public detectTabletOrMobile() {
    return window.innerWidth <= 1200
  }
  public detectLandscapeTablet() {
    return (
      window.innerWidth >= 700 &&
      window.innerWidth <= 1200 &&
      window.innerHeight < window.innerWidth
    )
  }

  public detectPortraitTablet() {
    return (
      window.innerWidth >= 700 &&
      window.innerWidth <= 1200 &&
      window.innerHeight > window.innerWidth
    )
  }

  public toggleMenu() {
    if (this.getIsToggleMenuEnabled) {
      this.setIsToggleMenuEnabled(false)
    } else {
      this.setIsToggleMenuEnabled(true)
    }
  }

  private updateHeaderButtons() {
    if (!this.isUserAdmin) {
      this.links = {
        sandbox: { index: 0, route: '/sandbox' },
        healthCheck: { index: 1, route: '/health-check' },
        alarms: { index: 2, route: '/alarms' },
        live: { index: 3, route: '/live' },
        maps: { index: 4, route: '/maps' },
        statistics: { index: 5, route: '/statistics' },
        configurations: { index: 6, route: '/configurations' },
        userManagement: { index: 7, route: '/user-management' }
      }
      this.buttonLabels = [
        'Sandbox',
        'Health Check',
        'Alarms',
        'Live',
        'Maps',
        'Statistics',
        'Configurations',
        'User Management'
      ]
      this.buttonIcons = [
        'mdi-image-search',
        'mdi-alert-circle',
        'mdi-alarm-light',
        'mdi-video',
        'mdi-map-marker',
        'mdi-chart-areaspline',
        'mdi-cog',
        'mdi-account-cog'
      ]
    } else {
      this.links = {
        workerspace: { index: 0, route: '/worker-space-person' },
        healthCheck: { index: 1, route: '/health-check' },
        alarms: { index: 2, route: '/alarms' },
        live: { index: 3, route: '/live' },
        maps: { index: 4, route: '/maps' },
        statistics: { index: 5, route: '/statistics' },
        configurations: { index: 6, route: '/configurations' },
        userManagement: { index: 7, route: '/user-management' }
      }
      this.buttonLabels = [
        'Workerspace',
        'Health Check',
        'Alarms',
        'Live',
        'Maps',
        'Statistics',
        'Configurations',
        'User Management'
      ]
      this.buttonIcons = [
        'mdi-account-tie',
        'mdi-alert-circle',
        'mdi-alarm-light',
        'mdi-video',
        'mdi-map-marker',
        'mdi-chart-areaspline',
        'mdi-cog',
        'mdi-account-cog'
      ]
    }
    this.toggle_exclusive = this.links[this.route]?.index ?? 1
  }

  @Watch('$route.params')
  private watchParams() {
    this.userId = this.$route.params.userId ?? ''
    this.clientId = this.$route.params.clientId ?? ''
    this.siteId = this.$route.params.siteId ?? ''
    this.cameraId = this.$route.params.cameraId ?? ''
    this.updateHeaderButtons()
  }

  get getCurrentUser() {
    return this.$store.state.user.currentUser
  }

  public onClickBreadCrumb(item: any) {
    const level = this.breadcrumbList.indexOf(item)
    const page = this.$router.currentRoute.name
    if (this.isUserAdmin) {
      if (level === 2) {
        this.$router.push({
          name: page,
          params: {
            userId: this.userId,
            clientId: this.clientId,
            siteId: this.siteId
          },
          query: {
            ...this.$route.query
          }
        })
      }
      if (level === 1) {
        this.$router.push({
          name: page,
          params: {
            userId: this.userId,
            clientId: this.clientId
          },
          query: {
            ...this.$route.query
          }
        })
      }
      if (level === 0) {
        this.$router.push({
          name: page,
          params: {
            userId: this.userId
          },
          query: {
            ...this.$route.query
          }
        })
      }
    } else {
      if (level === 1) {
        this.$router.push({
          name: page,
          params: {
            userId: this.userId,
            clientId: this.clientId,
            siteId: this.siteId
          },
          query: {
            ...this.$route.query
          }
        })
      }
      if (level === 0) {
        this.$router.push({
          name: page,
          params: {
            userId: this.userId,
            clientId: this.clientId
          },
          query: {
            ...this.$route.query
          }
        })
      }
    }
  }

  public isCurrentPage(item: any) {
    const level = this.breadcrumbList.indexOf(item)
    return level === this.breadcrumbList.length - 1 ? true : false
  }

  public capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  get buttonActions() {
    if (!this.isUserAdmin) {
      return [
        () => this.navigateTo('sandbox'),
        () => this.navigateTo('healthCheck'),
        () => this.navigateTo('alarms'),
        () => this.navigateTo('live'),
        () => this.navigateTo('maps'),
        () => this.navigateTo('statistics'),
        () => this.navigateTo('configurations'),
        () => this.navigateTo('userManagement')
      ]
    } else {
      return [
        () => this.$router.push('/worker-space-person'),
        () => this.navigateTo('healthCheck'),
        () => this.navigateTo('alarms'),
        () => this.navigateTo('live'),
        () => this.navigateTo('maps'),
        () => this.navigateTo('statistics'),
        () => this.navigateTo('configurations'),
        () => this.navigateTo('userManagement')
      ]
    }
  }

  get isBtnDisabled() {
    if (!this.isUserAdmin) {
      return [false, false, false, false, false, false, false, false]
    } else if (this.allClientConfig === 'customer') {
      return [false, false, false, false, false, false, false, true]
    } else if (this.allClientConfig === 'global') {
      return [false, false, false, false, false, false, true, false]
    } else {
      return [false, false, false, false, false, false, false, false]
    }
  }

  public navigateTo(
    routeName:
      | 'sandbox'
      | 'alarms'
      | 'healthCheck'
      | 'live'
      | 'statistics'
      | 'configurations'
      | 'maps'
      | 'userManagement'
  ) {
    const pageName = this.capitalizeFirstLetter(routeName)
    if (this.userId === '') {
      this.$router.push(this.links[routeName].route)
    } else {
      let params = {}
      if (this.cameraId) {
        params = {
          userId: this.userId,
          clientId: this.clientId,
          siteId: this.siteId,
          cameraId: this.cameraId
        }
      } else if (this.siteId) {
        params = {
          userId: this.userId,
          clientId: this.clientId,
          siteId: this.siteId
        }
      } else if (this.clientId) {
        params = {
          userId: this.userId,
          clientId: this.clientId
        }
      } else {
        params = {
          userId: this.userId
        }
      }

      this.$router.push({
        name: pageName,
        params
      })
    }
  }

  public changeTimeFormat(time: string) {
    let newFormat = moment(time, ['HH:mm']).format('h:mm A')
    return newFormat
  }

  public disableFutureDates(val: string) {
    return val <= new Date().toISOString().substr(0, 10)
  }

  private syncForUpdates() {
    if (!this.currentUser) return
    this.syncCurrentUser(this.currentUser.id)
  }
}
</script>

<style lang="scss" scoped>
.container--outline {
  border-style: solid !important;
  border-color: #d5d3d3 !important;
  border-width: 0px 0px 1px 0px !important;
  padding-top: 12px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.container--outline-minified {
  height: 50px !important;
  padding: 0 !important;
}

.breadcrumb-item:hover {
  text-decoration: underline;
}

.breadcrumb-item-smallscreen {
  width: 80px !important;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #e5e5e5;
}

.breadcrumb-item-smallscreen-darkmode {
  width: 80px !important;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #6f6c61;
}
.header-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  height: 80px !important;
}

.header-row-minified {
  margin: -2px;
  height: 60px !important;
  justify-content: space-between;
  // background-color: white;
  z-index: 9;
}

.breadcrumbs-container-smallscreen {
  flex-wrap: nowrap !important;
  font-size: 11px !important;
}

.breadcrumbs-container-minified {
  display: none !important;
}
.header-col {
  display: flex !important;
  flex-direction: row !important;
  flex: 0 0 33.333333% !important;
  max-width: 33.333333% !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 0 0 0 20px;
}

.header-breadcrumbs-vrow {
  justify-content: flex-start !important;
}

.breadcrumb-container-minified {
  flex-wrap: nowrap;
}

.header-refresh-timerange-col {
  display: flex !important;
  flex-direction: row !important;
  flex: 0 0 41.666667%;
  /* width: 5 out of 12 columns */
  max-width: 41.666667%;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0 !important;
}

.header-refresh-timerange-col-mobile {
  display: flex !important;
  flex-direction: row !important;
  flex: 0 0 33.666667%;
  /* width: 5 out of 12 columns */
  max-width: 33.666667%;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0 !important;
}

.header-refresh-timerange-row {
  justify-content: flex-end !important;
  max-width: 600px !important;
}
.header-btn-vcol {
  display: flex !important;
  flex-direction: row !important;
  flex: 0 0 25% !important;
  max-width: 25% !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0px !important;
}

.header-btn-progress-vcol {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0px !important;
}

.loading-spinner {
  height: 97vh !important;
}

.header-btn {
  color: black !important;
  background-color: white !important;
  height: 78px !important;
  border: none !important;
}

.header-btn::before {
  background-color: #ffd42a !important;
}

.header-btn {
  height: 61px !important;
  width: 48px !important;
}

.header-btn-minified {
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
}
.icon-minified {
  font-size: 20px !important;
}

.container--outline-smallscreen {
  height: auto !important;
  padding: 0 !important;
}

.admin-header-btn {
  color: white !important;
  background-color: black !important;
  border: none !important;
}

.admin-header-btn::before {
  background-color: #333333 !important;
}

.admin-header-btn:hover {
  background-color: #514f4b !important;
}

.header-btn--selected {
  background-color: #fff5ce !important;
  color: black !important;
  opacity: 1 !important;
  border-style: solid !important;
  border-color: #ffd42a !important;
  border-width: 0px 0px 5px 0px !important;
}

.admin-header-btn--selected {
  background-color: #978d76 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 1 !important;
  border-style: solid !important;
  border-color: #ffd42a !important;
  border-width: 0px 0px 5px 0px !important;
}

.time-selector-btn {
  color: rgb(0, 0, 0) !important;
  border-color: #d5d3d3;
  border-radius: 4px !important;
  text-transform: unset !important;
  background-color: rgb(230 230 230) !important;
  cursor: pointer !important;
}

.time-selector-btn input {
  cursor: pointer !important;
}

.clear-btn,
.apply-btn {
  text-transform: unset !important;
}

.clear-btn {
  border: 1px solid #ffd42a !important;
}

.clear-btn:hover {
  background-color: #ffe789 !important;
}

.tr-button {
  background-color: grey;
  text-transform: unset !important;
}

.timeText {
  cursor: pointer;
  color: grey;
}
.timeRow {
  display: flex;
  flex-direction: column;
}

.timecards {
  display: flex;
}
.selector2 {
  width: 50%;
  margin-left: 20px;
}
.topAlign {
  align-self: flex-start;
  padding: 0em;
  width: 100%;
  height: 80px;
  position: fixed;
}
.v-list-item--avatar {
  height: 50%;
  background-color: #ffd42a !important;
}

@media screen and (min-width: 700px) and (max-width: 1200px) and (orientation: landscape) {
  .container--outline {
    height: 65px !important;
    padding: 8px 0px 8px 8px;
  }

  .header-row {
    height: 65px !important;
  }

  .header-refresh-timerange-row {
    margin-left: -200px !important;
  }

  .icon {
    font-size: 20px !important;
  }

  .header-btn {
    height: 61px !important;
    width: 48px !important;
  }
  .admin-header-btn {
    height: 61px !important;
  }
}
@media screen and (min-width: 700px) and (max-width: 1200px) and (orientation: portrait) {
  .container--outline {
    height: 65px !important;
    padding: 8px 0px 8px 8px;
  }

  .header-row {
    height: 65px !important;
  }

  .header-refresh-timerange-row {
    margin-left: -200px !important;
  }

  .header-btn {
    height: 61px !important;
    width: 48px !important;
  }

  .icon {
    font-size: 20px !important;
  }

  .admin-header-btn {
    color: white !important;
    background-color: black !important;
    height: 61px !important;
  }
}

@media only screen and (max-width: 480px) and (max-height: 950px) and (orientation: portrait) {
}

/* Styles for mobile devices in landscape mode */
@media only screen and (max-width: 950px) and (max-height: 480px) and (orientation: landscape) {
  .header-col {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 390px) and (max-height: 845px) and (orientation: portrait) {
  .header-refresh-timerange-col-mobile {
    display: flex !important;
    flex-direction: row !important;
    flex: 0 0 30.666667% !important;
    /* width: 5 out of 12 columns */
    max-width: 30.666667% !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 375px) and (max-height: 667px) and (orientation: portrait) {
  .header-refresh-timerange-col-mobile {
    display: flex !important;
    flex-direction: row !important;
    flex: 0 0 29% !important;
    /* width: 5 out of 12 columns */
    max-width: 29% !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0 !important;
  }
}
</style>
