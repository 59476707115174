import { getDefaultState } from './state'

const mutations = {
  setAssessment(state: any, payload: string) {
    state.finalAssessment = payload
  },
  setTodosByCameraId(state: { todosByCameraId }, payload: any): void {
    state.todosByCameraId = payload
  },
  setDownloadUrl(state: { downloadUrl }, payload: any): void {
    state.downloadUrl = payload
  },
  setUploadedTask(state: { uploadedTask }, payload: any): void {
    state.uploadedTask = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  },
  setCurrentTodoCameraRef: (state, payload: any) => {
    state.currentTodoCameraRef = payload
  },
  setLastTodoByCameraRef: (state, payload: any) => {
    state.lastTodoByCameraRef = payload
  },
  setIncidentHealthChecks: (state, payload: any) => {
    state.incidentHealthChecks = payload
  },
  setIncidentHealthChecksLoading: (state, payload: any) => {
    state.incidentHealthChecksLoading = payload
  },
  setIncidentHcLoadMoreLoading: (state, payload: any) => {
    state.incidentHcLoadMoreLoading = payload
  },
  appendIncidentHealthChecks(state, newHealthChecks: any) {
    const existingIds = new Set(
      state.incidentHealthChecks?.map((item) => item.id)
    )
    const uniqueNewHealthChecks = newHealthChecks.filter(
      (item) => !existingIds.has(item.id)
    )
    state.incidentHealthChecks = state.incidentHealthChecks.concat(
      uniqueNewHealthChecks
    )
  },
  setLastVisibleIncidentHealthCheck: (state, payload: any) => {
    state.lastVisibleIncidentHealthCheck = payload
  },
  setHasMoreIncidentHealthChecks: (state, payload: any) => {
    state.hasMoreIncidentHealthChecks = payload
  }
}

export default {
  ...mutations
}
