import Vue from 'vue'
import { getDefaultState } from './state'

const mutations = {
  setNodes: (state, payload) => {
    Vue.set(state, 'nodes', JSON.parse(JSON.stringify(payload))) // Deep clone to avoid reactivity issues
  },
  setCreateNodeStatus: (state, payload) => {
    state.createNodeStatus = payload
  },
  resetState(state: any): void {
    Object.assign(state, getDefaultState())
  }
}
export default {
  ...mutations
}
