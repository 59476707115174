export interface IClientState {
  eagleEyeConnect: {
    progress: boolean
    message: string
  }
  clientUsers: any[]
  clientConfig: any
  clientOwner: any
  clientCameras: any
  clientLiveIsLoading: boolean
}

export const getDefaultState = (): IClientState => {
  return {
    clientConfig: null,
    clientOwner: null,
    clientLiveIsLoading: true,
    clientCameras: null,
    eagleEyeConnect: {
      progress: false,
      message: null
    },
    clientUsers: []
  }
}

const state = getDefaultState()

export default {
  ...state
}
