<template>
  <div>
    <v-flex row class="pt-1 ml-1">
      <Header
        :bannerTitle="'Manage Worker list'"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
        style="font-size: 1.25rem; padding-top: 18px"
        data-test-id="manage-worker-list-title"
      >
        <template v-slot:headerActionItems>
          <v-flex class="user-management__sticky-header">
            <v-flex row>
              <v-col>
                <v-text-field
                  style="width: 300px; margin-left: auto"
                  append-icon="mdi-magnify"
                  clearable
                  hide-details
                  label="Search"
                  data-test-id="search-worker-input"
                  single-line
                  v-model="searchText"
                  :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div text-xs-right>
                  <NewUser
                    :isWriteEnabled="isWriteEnabled"
                    :handleCreateUser="handleCreateUser"
                    :userRole="'Worker'"
                  />
                </div>
              </v-col>
            </v-flex>
          </v-flex>
        </template>
      </Header>
    </v-flex>
    <v-data-table
      v-bind="$attrs"
      :value="selectedUsers"
      :headers="headers"
      :items="users"
      :search="searchText"
      :footer-props="{
        'items-per-page-options': rowsPerPageItems
      }"
      @input="handleSelectionChanged"
      must-sort
      :items-per-page="10"
      :show-select="isWriteEnabled"
      :dark="isUserAdmin && isDarkModeToggleEnabled ? true : false"
      :style="{
        backgroundColor:
          isUserAdmin && isDarkModeToggleEnabled
            ? getColors.darkPrimaryColor
            : getColors.lightPrimaryColor
      }"
      style="padding: 0 28px"
      data-test-id="worker-table"
      :item-class="() => 'result-item'"
    >
      <template v-slot:item.id="{ item }">
        <Avatar :id="item.id" style="padding-top: 6px"></Avatar>
      </template>

      <template v-slot:item.workerActions="{ item }">
        <Button
          :disabled="item.workerStatus !== 'online'"
          @onButtonClick="handleForceLogoutWorker(item.id)"
          class="secondary black--text"
          data-test-id="force-logout-worker-button"
        >
          <template #icon>
            <v-icon size="20">mdi-logout-variant </v-icon>
          </template>
        </Button>
      </template>
      <template v-if="isLoading" slot="no-data">
        <tr class="loader-wrapper">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </tr>
      </template>
      <template v-else slot="no-data">
        <tr class="data-table__info-box">
          <td
            colspan="99"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : ''
            }"
          >
            No data available
          </td>
        </tr>
      </template>
      <template slot="no-results">
        <tr class="data-table__info-box">
          <td
            colspan="99"
            class="gt-table--center"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : ''
            }"
            data-test-id="search-no-results-message"
          >
            Your search for "{{ searchText }}" returned no results.
          </td>
        </tr>
      </template>
      <template slot="footer" v-if="showExtraFooterWithNoData">
        <tr class="data-table__info-box">
          <td
            colspan="99"
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : ''
            }"
          >
            No data available
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Header from '@/components/Header.vue'
import NewUser from '@/components/user/NewUser.vue'
import Avatar from '@/components/user/Avatar.vue'
import Button from '@/components/app/Button.vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Header,
    NewUser,
    Avatar,
    Button
  }
})
export default class WorkerTable extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: [] }) headers: any[]
  @Prop({ default: [] }) items: null
  @Prop({ default: null })
  configLevel: 'Admin' | 'Client' | 'Site' | 'Camera'
  @Prop({ default: [] })
  selectedUsers

  @Prop({ default: false }) public showExtraFooterWithNoData!: boolean

  @Prop({ required: true }) public onCreateUser: (payload: {
    firstName: string
    lastName: string
    email: string
    role: string
  }) => Promise<any>

  @Prop({ required: true }) public onSelectUser: (payload: any[]) => void

  @Prop({ required: true }) onForceLogout: (docId: string) => void

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('isLoadingUsers', namespaceUser) public isLoadingUsers: any

  public rowsPerPageItems: number[] = [5, 10, 25, 50]

  public searchText: string = ''

  public isLoading = false
  public users = []

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public mounted() {
    this.isLoading = true
    this.watchParams()
    this.watchItems()
  }

  @Watch('$route')
  @Watch('$route.params')
  public watchParams() {
    this.isLoading = true
  }

  @Watch('items')
  public watchItems() {
    if (this.items) {
      this.users = this.items
    }
  }

  @Watch('isLoadingUsers')
  watchIsLoadingUsers() {
    this.isLoading = this.isLoadingUsers
  }

  public async handleCreateUser(payload) {
    return this.onCreateUser(payload)
  }

  public handleSelectionChanged(list: any[]) {
    this.onSelectUser(list)
  }

  public handleForceLogoutWorker(docId: string) {
    this.onForceLogout(docId)
  }
}
</script>

<style lang="stylus" scoped>
hagl-gray = #FFFDFD;

@media screen and (max-width: 400px) {
  .v-text-field {
    width: 200px;
  }
  .button {
    font-size: 12px;
  }
}
.v-application .elevation-1 {
  box-shadow: none
}

.gt-data-table div {
  display: flex;
  flex-direction: column-reverse;
}

.data-table__info-box {
  text-align: center;
  height: 100px;
}

.loader-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}
.data-table >>> {
  & .v-text-field__details {
    display: none;
  }

  & .theme--dark.v-table {
    background-color: transparent;
  }

  & .gt-table--center {
    text-align: center;
  }

  & th {
    background-color: #f2f2f3;
  }

  & .v-input__slot {
    margin-bottom: 0;
  }

  & .v-datatable__actions {
    background-color: hagl-gray !important;
    border: none !important;
    border-radius: 1rem !important;
  }

  & table.v-table {
    border-collapse: separate;
    border-spacing: 0 3px;
    background-color: transparent;

    & .v-datatable__progress {
      display: none;
    }
  }

  & .v-table__overflow table tr {
    background-color: hagl-gray;

    &.already-in-use {
      & td, & th {
        text-decoration: line-through;
      }
    }
  }

  & td, & th {
    &:first-of-type {
      text-decoration: none;
    }
  }

  & .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background-color: #FFFDFD;
  }
}
@media only screen and (max-width: 480px) and (max-height: 950px) and (orientation: portrait) {
  .loader-wrapper {
    justify-content: space-between !important;
  }
}
</style>
