<template>
  <v-container
    fluid
    v-if="facet.data.length"
    class="d-flex flex-column py-0 pl-0"
  >
    <v-autocomplete
      v-model="values"
      :items="getItems(facet.data)"
      outlined
      item-color="white"
      :background-color="
        isUserAdmin && isDarkModeToggleEnabled ? getColors.darkSectionColor : ''
      "
      chips
      :label="facetName"
      :placeholder="facetName"
      small-chips
      multiple
      solo
      text-color="white"
      @change="setFilter($event)"
      :color="
        isUserAdmin && isDarkModeToggleEnabled
          ? getColors.darkLowFrameColor
          : ''
      "
    >
      <template #prepend-item>
        <v-card
          flat
          width="100%"
          height="100%"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkFrameColor
                : '',
            position: 'absolute',
            top: '0px'
          }"
        />
      </template>
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
        >
          {{ getDisplayValue(data.item) }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item
          v-on="data.on"
          v-bind="data.attrs"
          :style="{
            backgroundColor:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.darkFrameColor
                : ''
          }"
        >
          <div class="d-flex flex-row justify-start">
            <input
              type="checkbox"
              :checked="isChecked(data.item)"
              id="checkbox"
              @click="toggleSelection(data.item)"
              :disabled="true"
              class="checkbox-disabled"
            />
            <span
              class="px-4"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : '#000'
              }"
              >{{ getDisplayValue(data.item) }}</span
            >
          </div>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

type dataObject = {
  count: number
  value: string
}

type facetObject = {
  data: dataObject[]
  field: string
  type: string
}

@Component
export default class TypeSearchFacet extends Vue {
  @Action('bindAllUser', namespaceUser) public bindAllUser
  @Getter('allUser', namespaceUser) public users: any
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Prop() public facet: facetObject
  @Prop() public checked: string[]
  @Prop() public facetName: string
  @Prop() public isClearButtonClicked: boolean

  public values: string[] = []

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  private mounted() {
    this.values = this.checked
    if (this.facetName == 'User') {
      this.bindAllUser()
    }
  }

  public isChecked(value: string) {
    return this.values.includes(value)
  }

  public setFilter(event: string[]) {
    this.$emit('change', { event: this.values, facet: this.facet.field })
  }

  public getItems(array: dataObject[]) {
    return array.map((item) => item.value)
  }

  public getDisplayValue(value: string) {
    if (this.facetName == 'User' || this.facetName == 'Worker') {
      const correspondingUser = this.users.find((user) => user.id == value)
      if (correspondingUser) {
        return correspondingUser.email
      }
    }
    return value
  }

  public toggleSelection(value: string) {
    const index = this.values.indexOf(value)
    if (index === -1) {
      this.values.push(value)
    } else {
      this.values.splice(index, 1)
    }
    this.setFilter(this.values)
  }
  @Watch('isClearButtonClicked')
  public clearSelection() {
    if (this.isClearButtonClicked) {
      this.values = []
      this.setFilter(this.values)
    }
  }
}
</script>

<style scoped>
input[type='checkbox'] {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  border: 1px solid #ffd42a;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  -webkit-border-radius: 2px;
  border-radius: 3px;
}
/* input checked border color */
input[type='checkbox']:checked {
  border-color: #ffd42a;
  background-color: #ffd42a;
}
/* checkbox checked */
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 8px;
  margin-top: 1px;
}

/* Remove box shadow due to v-autocomplete solo  */
:deep(.v-input__control > .v-input__slot) {
  box-shadow: none !important;
}
/* Remove message display text field of v-autocomplete */
:deep(.v-input__control > .v-text-field__details) {
  display: none;
}
.checkbox-disabled {
  pointer-events: none; /* Disable mouse events */
}
</style>
