import { render, staticRenderFns } from "./SimpleHeader.vue?vue&type=template&id=013b7c7f&scoped=true"
import script from "./SimpleHeader.vue?vue&type=script&lang=ts"
export * from "./SimpleHeader.vue?vue&type=script&lang=ts"
import style0 from "./SimpleHeader.vue?vue&type=style&index=0&id=013b7c7f&prod&scoped=true&lang=css"
import style1 from "./SimpleHeader.vue?vue&type=style&index=1&id=013b7c7f&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013b7c7f",
  null
  
)

export default component.exports