<template>
  <!-- Quota alert for dark mode and light mode-->
  <v-card
    :class="{
      'alart-container': !isDarkModeToggleEnabled || !isUserAdmin,
      'alart-container-dark': isDarkModeToggleEnabled && isUserAdmin
    }"
    elevation="5"
    v-if="
      userId && clientId && visibility && totalAlarmQuotaPerMonth.isQuotaEnabled
    "
  >
    <div>
      <div class="progress-icon-container">
        <!-- Container for progress bar and icon -->
        <v-progress-linear
          height="10"
          color="yellow"
          :value="calculateProgress()"
          rounded
        ></v-progress-linear>
        <v-icon
          :class="{
            'icon-style': !isDarkModeToggleEnabled || !isUserAdmin,
            'icon-style-dark': isDarkModeToggleEnabled && isUserAdmin
          }"
          @click="closeAlert"
          >mdi-close</v-icon
        >
      </div>
      <p :class="'incident-count'">
        {{ totalAlarmCountPerMonth }} /
        {{ totalAlarmQuotaPerMonth.alarmQuota }} incidents
      </p>
    </div>
  </v-card>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { QUOTA_ENABLED } from '@/utils/Constants'

const namespaceStatistics = { namespace: 'statistics' }
const namespaceConfig = { namespace: 'config' }
const namespaceUser = { namespace: 'user' }
const namespaceClient = { namespace: 'client' }

@Component
export default class QuotaAlert extends Vue {
  @Action('fetchQuotaData', namespaceStatistics) private fetchQuotaData!: any
  @Action('getUserKeys', namespaceUser) public getUserKeys: (
    userId?: string
  ) => Promise<any[]>
  @Action('getClientDefaultKey', namespaceClient)
  public getClientDefaultKey: (payload: {
    clientId?: string
    userId?: string
  }) => Promise<any>
  @Action('setAllocatedQuota', namespaceStatistics) setAllocatedQuota!: any

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getUsedAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmCountPerMonth: number
  @Getter('getTotalAlarmQuotaPerMonth', namespaceStatistics)
  public totalAlarmQuotaPerMonth: {
    isQuotaEnabled: boolean
    alarmQuota: number
    quotaAlertPercentage: number
  }
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  // Quota is temporarily disabled until the security issues are fixed.
  public tmpQuotaStatus: boolean = QUOTA_ENABLED

  public visibility: boolean = false
  public isQuotaExceeded: boolean = false
  public userId: string = ''
  public clientId: string = ''
  public siteId: string = ''
  public cameraId: string = ''
  public subscriptionKeys: any[] = []

  private async mounted() {
    await this.setAllocatedQuota(this.clientId)
    this.watchParams()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public closeAlert() {
    this.visibility = false
  }

  // Watch the route parameter client id is changed
  @Watch('$route.params.clientId')
  private async watchParams() {
    this.userId = this.$route.params.userId ?? ''
    this.clientId = this.$route.params.clientId ?? ''
    this.siteId = this.$route.params.siteId ?? ''
    this.cameraId = this.$route.params.cameraId ?? ''
    // Reset the subscription keys
    this.subscriptionKeys = []
    // Get the allocated quota from firestore database
    await this.setAllocatedQuota(this.clientId)
    // Assign false to the visibility to remove the alert when the client id is changed
    this.visibility = false
    this.isQuotaExceeded = false

    if (this.userId && this.clientId) {
      // Get the subscription keys for the client and push them to the subscriptionKeys array
      this.subscriptionKeys.push(
        await this.getClientDefaultKey({
          clientId: this.clientId,
          userId: this.userId
        })
      )

      if (this.tmpQuotaStatus) {
        // Fetch the used quota data from the elastic search
        await this.fetchQuotaData({
          startTime: new Date(),
          endTime: new Date(),
          userId: this.userId,
          clientId: this.clientId,
          siteId: this.siteId,
          cameraId: this.cameraId,
          subscriptionKeys: this.subscriptionKeys
        })
      }

      // Calculate the progress of the quota
      const percentage = this.calculateProgress()
      if (
        percentage >= this.totalAlarmQuotaPerMonth.quotaAlertPercentage &&
        percentage < 100
      ) {
        // If the progress is greater than given percentage by admin show the alert
        this.visibility = true
      } else if (percentage === 100) {
        // If the progress is equal to 100%, show the alert with quota limit reached
        this.visibility = true
      } else if (percentage > 100) {
        // If the progress is greater than 100%, show the alert with quota exceeded
        this.visibility = true
      } else {
        // If not, hide the alert
        this.visibility = false
      }
    }
  }

  // Calculate the progress of the quota
  public calculateProgress(): number {
    if (this.totalAlarmQuotaPerMonth.alarmQuota !== 0) {
      return (
        (this.totalAlarmCountPerMonth /
          this.totalAlarmQuotaPerMonth.alarmQuota) *
        100
      )
    } else {
      return 0 // if the quota is 0, the progress bar will be 0
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../variables.styl';

.alart-container {
  width: 350px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 550px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $white;
  border-radius: 5px 5px 5px 5px;
  color: black;
  z-index: 999;
}

.alart-container-dark {
  width: 350px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 550px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #333333;
  border-radius: 5px 5px 5px 5px;
  color: white;
  z-index: 999;
}

.alart-container h4 {
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.alart-container-dark h4 {
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.alart-container p {
  font-size: 10px;
  margin-bottom: 8px;
  text-align: left;
}

.alart-container-dark p {
  font-size: 10px;
  margin-bottom: 8px;
  text-align: left;
}

.incident-count {
  font-size: 10px;
  text-align: left;
  margin-top: 5px;
}

.icon-style {
  padding-left: 10px;
  color: black;
  font-size: 20px;
}

.icon-style-dark {
  padding-left: 10px;
  color: white;
  font-size: 20px;
}

.progress-icon-container {
  display: flex;
  align-items: center;
}
</style>
