var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-flex',{staticClass:"pt-1 ml-1",attrs:{"row":""}},[_c('Header',{staticStyle:{"font-size":"1.25rem","padding-top":"18px"},style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkPrimaryColor
      }),attrs:{"bannerTitle":_vm.isUserCustomer ? 'Manage Collaborator list' : 'Manage Customer list',"data-test-id":"manage-customer-list-title"},scopedSlots:_vm._u([{key:"headerActionItems",fn:function(){return [_c('v-flex',{staticClass:"user-management__sticky-header"},[_c('v-flex',{attrs:{"row":""}},[_c('v-col',[_c('v-text-field',{staticStyle:{"width":"300px","margin-left":"auto"},attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","label":"Search","data-test-id":"search-customer-input","single-line":"","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-spacer'),_c('v-col',[_c('div',{attrs:{"text-xs-right":""}},[(_vm.configLevel === 'Admin')?_c('NewUser',{attrs:{"isWriteEnabled":_vm.isWriteEnabled,"handleCreateUser":_vm.handleCreateUser,"userRole":'Customer'}}):_c('UserEmailDialog',{attrs:{"isWriteEnabled":_vm.isWriteEnabled,"handleCreateUser":_vm.handleCreateUser,"userRole":'Customer'}})],1)])],1)],1)]},proxy:true}])})],1),_c('v-data-table',{staticStyle:{"padding":"0 28px"},style:({
      backgroundColor:
        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
          ? _vm.getColors.darkPrimaryColor
          : _vm.getColors.lightPrimaryColor
    }),attrs:{"value":_vm.selectedUsers,"headers":_vm.userHeaders,"items":_vm.users,"search":_vm.searchText,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItems
    },"must-sort":"","items-per-page":10,"show-select":_vm.isWriteEnabled,"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"loading":_vm.isProcessing,"data-test-id":"customer-table","item-class":() => 'result-item'},on:{"input":_vm.handleSelectionChanged},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('Avatar',{staticStyle:{"padding-top":"6px"},attrs:{"id":item.id}})]}},{key:"item.isWriteEnabled",fn:function({ item }){return [_c('v-switch',{key:item.id,staticClass:"table-switch",style:({ paddingLeft: '25px' }),attrs:{"id":item.id,"inset":"","color":"secondary","data-test-id":"user-write-permission-switch","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"disabled":!_vm.isWriteEnabled || item.isOverrideEnabled === false || _vm.isProcessing},on:{"change":($event) => _vm.onChangeWritePermissions(item.id, $event)},model:{value:(item.isWriteEnabled),callback:function ($$v) {_vm.$set(item, "isWriteEnabled", $$v)},expression:"item.isWriteEnabled"}})]}},{key:"item.isOverrideEnabled",fn:function({ item }){return [_c('v-switch',{key:item.id,staticClass:"table-switch",style:({ paddingLeft: '25px' }),attrs:{"id":item.id,"inset":"","color":"secondary","data-test-id":"user-override-permission-switch","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? true : false,"disabled":!_vm.isWriteEnabled || _vm.isProcessing},on:{"change":($event) => _vm.onChangeOverridePermissions(item.id, $event)},model:{value:(item.isOverrideEnabled),callback:function ($$v) {_vm.$set(item, "isOverrideEnabled", $$v)},expression:"item.isOverrideEnabled"}})]}}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"secondary"}})],1),(_vm.isLoading)?_c('template',{slot:"no-data"},[_c('tr',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)]):_c('template',{slot:"no-data"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99"}},[_vm._v(" No data available ")])])]),_c('template',{slot:"no-results"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{staticClass:"gt-table--center",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99","data-test-id":"search-no-results-message"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchText)+"\" returned no results. ")])])]),(_vm.showExtraFooterWithNoData)?_c('template',{slot:"footer"},[_c('tr',{staticClass:"data-table__info-box"},[_c('td',{style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : ''
          }),attrs:{"colspan":"99"}},[_vm._v(" No data available ")])])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }