<template>
  <div>
    <v-btn
      large
      style="background-color: #ffd42a"
      class="add-button elevation-0 text-subtitle-2"
      color=" black--text"
      :data-ci-new-user-add-user-button="true"
      :disabled="!isWriteEnabled"
      data-test-id="add-new-user-button"
      @click="addUser"
    >
      <v-icon style="padding-right: 15px" data-test-id="add-new-user-label"
        >mdi-account-plus</v-icon
      >
      Add New
      {{ isUserCustomer ? 'Collaborator' : 'Customer' }}
    </v-btn>
    <v-card v-if="showAddUserDialog"
      ><AddNewUserDialog
        :handleCreateUser="handleCreateUser"
        @close="showAddUserDialog = false"
        @save="
          ;(isDialog = false), createForm.reset(), (showAddUserDialog = false)
        "
        @update-success-dialog="handleUpdateSuccessDialog"
    /></v-card>
    <v-card v-if="showSuccessDialog"><SuccesMessageDialog /> </v-card>

    <v-snackbar v-model="snackbar" data-test-id="add-new-user-snackbar-message">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <Button
          text
          v-bind="attrs"
          @onButtonClick="snackbar = false"
          btnText="Close"
          class="secondary black--text"
          data-test-id="snackbar-close-button"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import AddNewUserDialog from './AddNewUserDialog.vue'
import SuccesMessageDialog from './SuccesMessageDialog .vue'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component({
  components: {
    Button,
    AddNewUserDialog,
    SuccesMessageDialog
  }
})
export default class UserEmailDialog extends Vue {
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop({ default: '' }) userRole!: string
  @Prop({ required: true }) handleCreateUser!: (payload: {
    firstName: string
    lastName: string
    email: string
    role: string
  }) => Promise<any>

  @Getter('currentUser', namespaceUser) public currentUser!: any
  @Getter('getColors', namespaceUser) public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled!: any
  public isValid: boolean = false
  public isDialog: boolean = false
  public isLoading: boolean = false
  public userRoles: string[] = []
  public hasFormReset: boolean = false
  public snackbar: boolean = false
  public snackbarMessage: string = ''
  public userEmail: string = ''
  public showAddUserDialog: boolean = false
  public showSuccessDialog: boolean = false

  public setUserRoles() {
    if (this.userRole === 'Customer') {
      this.userRoles = ['Customer']
    } else if (this.userRole === 'Administrator') {
      this.userRoles = ['Administrator']
    } else {
      this.userRoles = ['Worker']
    }
    return this.userRoles
  }

  public get onUserRoleChange(): string {
    this.userRoles = this.setUserRoles()
    return this.userRole
  }

  get error() {
    return this.$store.getters.error
  }

  public roleRule = [(v: string) => !!v || 'The input is required']

  public role = 'Customer'

  public userId: string = ''

  @Ref('form') public createForm!: { validate: () => any; reset: () => void }

  @Watch('$route')
  @Watch('$route.params')
  private async watchParams() {
    this.userId = this.$route.params.userId ?? ''
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  mounted(): void {
    this.$store.dispatch('clearError')
    this.hasFormReset = true
    this.role = this.onUserRoleChange
  }

  handleUpdateSuccessDialog(newValue) {
    this.showSuccessDialog = newValue
    if (this.showSuccessDialog) {
      setTimeout(() => {
        this.showSuccessDialog = false
      }, 2000)
    }
  }

  public async addUser(): Promise<void> {
    this.showAddUserDialog = true
    this.watchParams()
  }

  public onDismissed() {
    this.$store.dispatch('clearError')
  }
  public cancelNewUser() {
    this.isDialog = false
    this.createForm.reset()
    this.$store.dispatch('clearError')
  }
}
</script>

<style scoped>
.add-button {
  margin: 1rem auto;
  text-transform: unset !important;
}

.close-btn:hover {
  background-color: transparent !important;
}
</style>
