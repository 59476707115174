export interface IClient {
  name: string
  clientId: string
  users: string[]
  writeUsers: string[]
}

export enum UpdateFrequency {
  Month = 'month',
  Week = 'week'
}

export interface IUpdateSchedulingConfig {
  selectedTimeZone: string
  applicableDays: string[]
  updateFrequency: UpdateFrequency
  updateNumber: number
  scheduledTime: string
  isAutoUpdate: boolean
  isScheduledUpdate: boolean
  isChanged?: boolean
}
export enum P2pPlayer {
  MTX = 'media-mtx'
}

export enum PlayerType {
  IFRAME = 'iframe',
  WEBRTC = 'webrtc'
}

export type P2pPlayerConfig = {
  player: P2pPlayer.MTX
  playerType: PlayerType
}
