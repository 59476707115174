<template>
  <div class="container-row">
    <v-icon :color="colorIndicator" :title="statusTitle">
      <template v-if="!isChromeBrowser && isOnline">mdi-wifi</template>
      <template v-else-if="effectiveType === '4g' && isOnline">
        mdi-wifi-strength-4
      </template>
      <template v-else-if="effectiveType === '3g' && isOnline">
        mdi-wifi-strength-1
      </template>
      <template v-else-if="effectiveType === '2g' && isOnline">
        mdi-wifi-strength-alert-outline
      </template>
      <template v-else-if="effectiveType === 'slow-2g' && isOnline">
        mdi-wifi-strength-alert-outline
      </template>
      <template v-else>mdi-wifi-off</template>
    </v-icon>
    <span>{{ statusText }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class NetworkInfo extends Vue {
  effectiveType: string = ''
  isOnline: boolean = navigator.onLine
  connection: any =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection

  isChromeBrowser =
    navigator.userAgent.includes('Chrome') &&
    !navigator.userAgent.includes('Edge')

  get statusTitle(): string {
    if (!this.isOnline) return 'You are offline.'
    if (this.effectiveType === '4g') return 'Your connection is good.'
    return 'Your connection is not good.'
  }

  get statusText(): string {
    if (!this.isOnline) return 'Offline'
    if (!this.isChromeBrowser) return 'Online'
    if (this.effectiveType === '4g') return 'Healthy'
    if (this.effectiveType === '3g') return 'Unstable'
    return 'Unhealthy'
  }

  get colorIndicator(): string {
    if (this.effectiveType === '3g') return 'orange'
    if (this.effectiveType === '2g') return 'yellow'
    if (this.effectiveType === 'slow-2g') return 'yellow'
    return 'white'
  }

  mounted() {
    this.updateNetworkInfo()

    if (navigator.connection) {
      navigator.connection.addEventListener('change', this.updateNetworkInfo)
    }

    window.addEventListener('online', () => (this.isOnline = true))
    window.addEventListener('offline', () => (this.isOnline = false))
  }

  beforeDestroy() {
    if (navigator.connection) {
      navigator.connection.removeEventListener('change', this.updateNetworkInfo)
    }

    window.removeEventListener('online', () => (this.isOnline = true))
    window.removeEventListener('offline', () => (this.isOnline = false))
  }

  updateNetworkInfo() {
    if (navigator.connection && this.isChromeBrowser) {
      try {
        this.effectiveType = navigator.connection.effectiveType ?? ''
      } catch (e) {
        this.effectiveType = ''
      }
    }
  }

  @Watch('isOnline')
  @Watch('effectiveType')
  private logNetworkChanges() {
    // save network changes to local storage; Save to array and there will be a limit of 1000 entries

    const networkChanges = JSON.parse(
      localStorage.getItem('networkChanges') || '[]'
    )
    if (networkChanges.length > 1000) {
      networkChanges.shift()
    }
    networkChanges.push({
      timestamp: new Date(),
      isOnline: this.isOnline,
      effectiveType: this.effectiveType
    })
    localStorage.setItem('networkChanges', JSON.stringify(networkChanges))
  }
}
</script>

<style scoped>
* {
  color: white;
  scale: 1.1;
  margin-left: 5px;
}
.container-row {
  display: flex;
  align-items: baseline;
}
</style>
