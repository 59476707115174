<template>
  <div class="text-center">
    <Overlay
      :src="processedUrl"
      :draw="true"
      :type="item?.filemetadata__mediatype"
      :thumbnailType="thumbnailType"
      :opacity="0.5"
      :controls="false"
      :excludedZones="excludedZones"
      :cameraMetadata="cameraMetadata"
      :showWarning="showIndicator"
      :unarmedStatus="item?.isunarmed || 'false'"
      :isPending="isPending"
      :isErrorIncident="isErrorIncident"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Overlay from '../excluded-zones/Overlay.vue'
import { USE_LOCAL_STORAGE } from '@/utils/EnvMapping'
const path = require('path')

enum MediaType {
  video = 'video',
  image = 'image'
}

type SnapshotModalResponse = {
  id: string
  url: string
  cameraid: string
  processedfile__url?: string
  originalFileName: string
  processedfile__path?: string
  originalfile__path?: string
  originalfile__url?: string
  filemetadata__mediatype: MediaType
  finalresult__personassessment: string
  finalresult__vehiclemovement: string
  processedfile__islocal?: string
  completedat: string
  isunarmed: string
  completed: boolean
  deadline: string
}

const namespaceTodos = { namespace: 'todos' }

// TODO: Remove unwanted zone fetching from this component, because zone drawing is not required in alarms list

@Component({
  components: {
    Overlay
  }
})
export default class SnapshotModalForAlarms extends Vue {
  @Prop() item: SnapshotModalResponse
  @Prop({ default: false }) isPending: boolean
  @Action('getDownloadUrl', namespaceTodos) public getDownloadUrl
  @Action('getLocalMediaUrl', namespaceTodos) public getLocalMediaUrl
  @Action('fetchZoneByIncident', namespaceTodos)
  private fetchZoneByIncident: any
  @Getter('getDownloadUrl', namespaceTodos) public downloadUrl

  public dialog: boolean = false
  public processedUrl: string = ''
  public excludedZones: any[] = []
  public cameraMetadata: {
    width: number
    height: number
  } = {
    width: 0,
    height: 0
  }
  public thumbnailType: MediaType = MediaType.image

  public showIndicator: boolean = true

  public async mounted() {
    if (this.isPending) {
      this.thumbnailType = this.item?.filemetadata__mediatype
      this.processedUrl = this.item?.originalfile__url
      return
    }

    this.downloadFileUrl()

    if (this.item?.id) {
      const zones = await this.fetchZoneByIncident(this.item.id)
      if (zones) {
        try {
          this.excludedZones =
            typeof zones?.excludedZones === 'string'
              ? JSON.parse(zones.excludedZones)
              : zones.excludedZones

          this.cameraMetadata = {
            width: zones.metadata.width,
            height: zones.metadata.height
          }
        } catch (e) {}
      }
    }
  }

  public get isErrorIncident() {
    return (
      this.item?.completed !== true &&
      this.hasDeadlinePassed(this.item?.deadline)
    )
  }

  private hasDeadlinePassed(deadline: string) {
    if (!deadline) return false
    const deadlineDate = new Date(deadline)
    const currentDate = new Date()
    return deadlineDate < currentDate
  }

  private async downloadRemoteFile() {
    if (this.item?.processedfile__path && this.item?.originalfile__path) {
      if (this.item?.isunarmed === 'true') {
        this.item.processedfile__path = this.item?.originalfile__path
      }

      let relUrl: string
      if (
        this.item?.finalresult__personassessment === 'no-alarm' &&
        this.item?.finalresult__vehiclemovement === 'no-alarm'
      ) {
        relUrl = this.item?.originalfile__path
      } else {
        relUrl = this.item?.processedfile__path
      }
      if (
        relUrl.endsWith('.mp4') ||
        relUrl.endsWith('.mpg') ||
        relUrl.endsWith('.mpeg') ||
        relUrl.endsWith('.dav')
      ) {
        this.thumbnailType = MediaType.image
        const fileName = path.basename(relUrl, path.extname(relUrl))
        const resizedRelUrl = `thumbnails/thumbnail_${fileName}.jpeg`
        if (!(await this.getDownloadUrl(resizedRelUrl))) {
          await this.getDownloadUrl(relUrl)
          this.thumbnailType = MediaType.video
        }
      } else {
        this.thumbnailType = MediaType.image
        const resizedRelUrl = relUrl.replace(/(\.[^.]+)$/, '_180x150$1')
        await this.getDownloadUrl({ url: resizedRelUrl, fallback: relUrl })
      }
    } else {
      this.thumbnailType = this.item?.filemetadata__mediatype ?? MediaType.image
      if (this.item?.isunarmed === 'true') {
        await this.getDownloadUrl({
          url: this.item?.originalfile__path
        })
      } else {
        await this.getDownloadUrl(
          this.item?.processedfile__path ?? this.item?.originalfile__path
        )
      }
    }
    this.processedUrl = this.downloadUrl
  }

  private async downloadLocalFile() {
    if (this.item?.processedfile__path && this.item?.originalfile__path) {
      let relUrl: string
      if (
        this.item?.finalresult__personassessment !== 'alarm' &&
        this.item?.finalresult__vehiclemovement !== 'alarm'
      ) {
        relUrl = this.item?.originalfile__path
      } else {
        relUrl = this.item?.processedfile__path
      }

      this.processedUrl = relUrl

      await this.getLocalMediaUrl(this.item?.processedfile__path)
      if (this.item?.filemetadata__mediatype === MediaType.image) {
        this.processedUrl = `data:image/jpg;base64,${this.downloadUrl}`
      } else {
        this.processedUrl = `data:video/mp4;base64,${this.downloadUrl}`
      }
    }
  }

  private async downloadFileUrl() {
    if (this.item) {
      if (USE_LOCAL_STORAGE && this.item?.processedfile__islocal === 'true') {
        await this.downloadLocalFile()
      } else {
        await this.downloadRemoteFile()
      }
    }
  }
}
</script>

<style scoped>
.text-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
