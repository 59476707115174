<template>
  <div>
    <div
      v-if="incidentsResultDistributionStatus !== 'Ready'"
      class="loading-spinner"
    >
      <div v-if="incidentsResultDistributionStatus === 'Error'">
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is an error while loading this chart
        </p>
      </div>
      <div v-if="incidentsResultDistributionStatus === 'Loading'">
        <v-progress-circular indeterminate color="secondary" />
      </div>
    </div>
    <div v-if="incidentsResultDistributionStatus === 'Ready'" class="my-2 pa-4">
      <div
        class="my-2 pa-4"
        style="width: 100%"
        v-if="
          sankeyChartSettings !== null &&
          sankeyChartOptions !== null &&
          incidentsResultDistributionData.totalIncidents > 0
        "
      >
        <GChart
          key="incidents-result-distribution"
          type="Sankey"
          :data="performanceSankeyData"
          :settings="sankeyChartSettings"
          :options="sankeyChartOptions"
        />
      </div>

      <div v-else>
        <p
          :style="{
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkPrimaryColor
          }"
        >
          There is not enough data to show this chart
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts/legacy'
import { Action, Getter } from 'vuex-class'
import { debounce } from 'lodash'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceStatistics = { namespace: 'statistics' }

@Component({
  components: {
    GChart
  }
})
export default class SankeyChart extends Vue {
  @Prop() public startTime: any
  @Prop() public endTime: any

  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getIncidentsResultDistributionData', namespaceStatistics)
  public incidentsResultDistributionData: any
  @Getter('getIncidentsResultDistributionStatus', namespaceStatistics)
  public incidentsResultDistributionStatus: string

  @Action('fetchIncidentsResultDistributionData', namespaceStatistics)
  fetchIncidentsResultDistributionData

  public textColors: string = ''
  public sankeyChartSettings = null
  public sankeyChartOptions = null
  public nodeColors = []
  public finalColors = []
  private colors = [
    '#9ED0FC',
    '#FF614B',
    '#FF614B',
    '#4DD052',
    '#4DD052',
    '#FFD42A',
    '#FFD42A'
  ]
  private nodeLabels = [
    'Total Incidents',
    'Alarm',
    'Alarm ',
    'No-Alarm',
    'No-Alarm ',
    'Unsure',
    'Unsure '
  ]

  public performanceSankeyData = []

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public async mounted() {
    await this.fetchChartData()
  }

  public destroyed() {
    this.fetchChartData.cancel()
  }

  @Watch('startTime')
  @Watch('endTime')
  @Watch('$route.params')
  public fetchChartData = debounce(async () => {
    await this.fetchIncidentsResultDistributionData({
      startTime: this.startTime,
      endTime: this.endTime,
      ...this.$route.params
    })
    this.loadSankeyChart()
    this.updateChart()
  }, 300)

  private loadSankeyChart() {
    this.performanceSankeyData = []

    // create header
    this.performanceSankeyData.push(['From', 'To', 'Count', 'Percentage(%)'])
    if (this.isUserAdmin) {
      const n = 2
      this.performanceSankeyData.push([' '.repeat(n), ' '.repeat(n + 1), 0, 0])
      this.performanceSankeyData.push([
        ' '.repeat(n + 1),
        ' '.repeat(n + 2),
        0,
        0
      ])
      this.performanceSankeyData.push([
        ' '.repeat(n + 2),
        ' '.repeat(n + 3),
        0,
        0
      ])
      this.performanceSankeyData.push(['      ', 'AI', 0, 0])
      this.performanceSankeyData.push(['AI', 'Final', 0, 0])
    }

    // push data

    this.pushData(
      'Total Incidents',
      'Alarm',
      this.incidentsResultDistributionData.alarm
    )
    this.pushData(
      'Total Incidents',
      'No-Alarm',
      this.incidentsResultDistributionData.noAlarm
    )
    this.pushData(
      'Total Incidents',
      'Unsure',
      this.incidentsResultDistributionData.unsure
    )

    if (this.isUserAdmin) {
      this.pushData(
        'Alarm',
        'Alarm ',
        this.incidentsResultDistributionData.alarmAndAlarm
      )
      this.pushData(
        'Alarm',
        'No-Alarm ',
        this.incidentsResultDistributionData.alarmAndNoAlarm
      )
      this.pushData(
        'Alarm',
        'Unsure ',
        this.incidentsResultDistributionData.alarmAndUnsure
      )

      this.pushData(
        'No-Alarm',
        'Alarm ',
        this.incidentsResultDistributionData.noAlarmAndAlarm
      )
      this.pushData(
        'No-Alarm',
        'No-Alarm ',
        this.incidentsResultDistributionData.noAlarmAndNoAlarm
      )
      this.pushData(
        'No-Alarm',
        'Unsure ',
        this.incidentsResultDistributionData.noAlarmAndUnsure
      )

      this.pushData(
        'Unsure',
        'Alarm ',
        this.incidentsResultDistributionData.unsureAndAlarm
      )
      this.pushData(
        'Unsure',
        'No-Alarm ',
        this.incidentsResultDistributionData.unsureAndNoAlarm
      )
      this.pushData(
        'Unsure',
        'Unsure ',
        this.incidentsResultDistributionData.unsureAndUnsure
      )
    }
  }

  private pushData(from: string, to: string, count: number) {
    const totalIncidents = this.incidentsResultDistributionData.totalIncidents
    if (count > 0) {
      this.performanceSankeyData.push([
        from,
        to,
        count,
        Number(((count / totalIncidents) * 100).toFixed(0))
      ])
    }
  }

  // Update and match colors to Labels
  private updateColor() {
    // Color array
    this.finalColors = []
    // Already added Nodes
    const checkedNodes = []
    // Filter out Duplicate Nodes
    this.performanceSankeyData.forEach((value, i) => {
      if (i != 0) {
        if (!checkedNodes.includes(value[0])) {
          checkedNodes.push(value[0])
        }
        if (!checkedNodes.includes(value[1])) {
          checkedNodes.push(value[1])
        }
      }
    })

    // Assign Color to Nodes
    checkedNodes.forEach((value, i) => {
      const index = this.nodeLabels.indexOf(value)
      // If incident Node
      if (index != -1) {
        this.finalColors.push(this.colors[index])
      } else {
        // Assign white to filler nodes
        this.finalColors.push('#FFFFFF')
      }
    })
    this.sankeyChartSettings = {
      packages: ['sankey']
    }
  }

  public updateChart() {
    this.textColors =
      this.currentUser.role === 'Administrator' && this.isDarkModeToggleEnabled
        ? this.getColors.lightPrimaryColor
        : this.getColors.darkBlackColor

    this.updateColor()
    this.sankeyChartOptions = {
      sankey: {
        iterations: 0,
        nodePadding: 20,
        width: 15,
        node: {
          interactivity: true,
          label: {
            fontName: 'Poppins',
            fontSize: 12,
            color: this.textColors
          },
          colors: this.finalColors
        },
        link: {
          colorMode: 'gradient',
          colors: this.finalColors
        },
        height: 800
      },
      tooltip: {
        textStyle: {
          fontName: 'Poppins',
          fontSize: 12
        }
      }
    }
  }
}
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}
</style>
