export enum MonitoringStatus {
  Armed = 'Armed',
  Disarmed = 'Disarmed'
}

export enum PromiseQubeNodeStatus {
  Off = 'Off',
  Armed = 'Armed',
  Disarmed = 'Disarmed',
  Unauthenticated = 'Unauthenticated',
  Armed_Inactive = 'Armed_Inactive',
  Disarmed_Inactive = 'Disarmed_Inactive',
  Armed_Active = 'Armed_Active',
  Disarmed_Active = 'Disarmed_Active'
}

export type NodeStatus = MonitoringStatus | PromiseQubeNodeStatus
