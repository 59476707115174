<template>
  <header class="page-header">
    <v-layout>
      <v-flex>{{ bannerTitle }}</v-flex>
      <slot name="headerActionItems"></slot>
      <AssessmentModeIndicator
        v-if="currentUser.role === 'Administrator'"
      ></AssessmentModeIndicator>
    </v-layout>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import AssessmentModeIndicator from '@/components/AssessmentModeIndicator.vue'
import { Getter } from 'vuex-class'

const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    AssessmentModeIndicator
  }
})
export default class Header extends Vue {
  @Prop()
  public bannerTitle: string

  @Getter('currentUser', namespaceUser) public currentUser
}
</script>

<style scoped>
.page-header {
  padding: 10px;
  width: 100%;
  font-size: 2em;
  text-align: left;
  color: var(--v-primary-base, #333333);
}
</style>
