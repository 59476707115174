const getters = {
  siteConfig: (state: { siteConfig: any[] }) => {
    return state.siteConfig
  },
  getSiteCameras: (state: any) => {
    return state.siteCameras
  },
  getSiteUsers: (state: any) => {
    return state.siteUsers
  },
  getLastPingAt: (state: any) => {
    return state.lastPingAt
  }
}

export default {
  ...getters
}
