var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.userId && _vm.clientId && _vm.visibility && _vm.totalAlarmQuotaPerMonth.isQuotaEnabled
  )?_c('v-card',{class:{
    'alart-container': !_vm.isDarkModeToggleEnabled || !_vm.isUserAdmin,
    'alart-container-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
  },attrs:{"elevation":"5"}},[_c('div',[_c('div',{staticClass:"progress-icon-container"},[_c('v-progress-linear',{attrs:{"height":"10","color":"yellow","value":_vm.calculateProgress(),"rounded":""}}),_c('v-icon',{class:{
          'icon-style': !_vm.isDarkModeToggleEnabled || !_vm.isUserAdmin,
          'icon-style-dark': _vm.isDarkModeToggleEnabled && _vm.isUserAdmin
        },on:{"click":_vm.closeAlert}},[_vm._v("mdi-close")])],1),_c('p',{class:'incident-count'},[_vm._v(" "+_vm._s(_vm.totalAlarmCountPerMonth)+" / "+_vm._s(_vm.totalAlarmQuotaPerMonth.alarmQuota)+" incidents ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }