import { statusCollection } from '@/provider/firebase'
import { firestoreAction } from 'vuexfire'

const actions = {
  bindStatus: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef(
      'status',
      statusCollection.where('state', '==', 'online'),
      {
        maxRefDepth: 0,
        wait: true
      }
    )
  }),
  unbindStatus: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('status')
  })
}

export default {
  ...actions
}
