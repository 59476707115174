var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    backgroundColor:
      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkPrimaryColor
        : _vm.getColors.lightPrimaryColor
  })},[(_vm.isLoading)?_c('div',{staticClass:"loader-wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_vm._e(),(!_vm.isLoading)?_c('v-container',{attrs:{"fluid":""}},[(_vm.healthChecks.length > 0)?_c('v-row',[_vm._l((_vm.healthChecks),function(item){return _c('v-col',{key:item.id,staticStyle:{"color":"black"},style:({
          color: 'black'
        }),attrs:{"cols":"12","sm":12 / _vm.numberOfItems}},[_c('v-sheet',{staticClass:"px-1 py-1",style:({
            backgroundColor:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#333333' : '#f5f5f5',
            border:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? '1px solid #C4C4C4'
                : 'none'
          })},[(_vm.type === 'incident')?_c('SimpleGalleryElement',{staticClass:"grid-item-wrapper",style:({ height: _vm.gridHeight }),attrs:{"mediaPath":item?.processedFile?.path,"mediaType":item?.mediaType,"isThumbnail":true}}):_vm._e(),(_vm.type === 'live')?_c('HealthCheckLiveStreamPlayer',{staticClass:"grid-item-wrapper",style:({ height: _vm.gridHeight }),attrs:{"selectedHealthCheck":item,"liveStreamQuality":_vm.liveStreamQuality}}):_vm._e(),(_vm.configLevel !== 'Camera')?_c('p',{staticClass:"mt-1 mb-0",staticStyle:{"text-align":"left","font-weight":"600","font-size":"13px"},style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                  ? _vm.getColors.lightPrimaryColor
                  : _vm.getColors.darkPrimaryColor
            })},[_c('HealthCheckBreadCrumb',{attrs:{"selectedHealthCheck":item,"configLevel":_vm.configLevel}})],1):_vm._e(),_c('v-row',{staticClass:"my-1"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center py-0"},[(item.healthCheck.deviation[0] === 'No Deviation')?_c('v-btn',{staticClass:"py-0 px-2",staticStyle:{"text-transform":"none","background-color":"#4dd053","color":"#ffffff","height":"25px","font-size":"12px","box-shadow":"none"}},[_vm._v(" No deviations detected ")]):_vm._e(),(item.healthCheck.deviation[0] !== 'No Deviation')?_c('v-btn',{staticClass:"py-0 px-2",staticStyle:{"text-transform":"none","background-color":"#ff5252","color":"#ffffff","height":"25px","font-size":"12px","box-shadow":"none"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"12","color":"#C4C4C4"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" Deviations detected ")],1):_vm._e(),(item.healthCheck.reportMsg !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ props, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-0 px-2",staticStyle:{"text-transform":"none","height":"25px","font-size":"12px","border":"1px solid #c4c4c4","box-shadow":"none"},style:({
                      backgroundColor:
                        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? '#333333'
                          : '#e5e5e5'
                    })},'v-btn',props,false),on),[_c('v-icon',{attrs:{"size":"12","color":"#C4C4C4"}},[_vm._v(" mdi-comment ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.healthCheck.reportMsg))])]):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center py-0"},[_c('p',{staticClass:"mb-0",staticStyle:{"text-align":"left","font-size":"12px"},style:({
                  color:
                    _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? _vm.getColors.lightPrimaryColor
                      : _vm.getColors.darkPrimaryColor
                })},[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.healthCheck.createdAt))+" ")]),_c('v-icon',{attrs:{"color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                    ? '#C4C4C4'
                    : 'grey-lighten-1'},on:{"click":function($event){return _vm.setHealthCheckItem(item)}}},[_vm._v(" mdi-information ")])],1)],1)],1)],1)}),_c('v-col',{staticClass:"d-flex justify-center align-center"},[(!_vm.isLoading && _vm.hasMoreHealthChecks)?_c('Button',{attrs:{"btnText":"More","loading":_vm.isLoadMoreLoading},on:{"onButtonClick":function($event){return _vm.loadMore()}}}):_vm._e()],1)],2):_vm._e(),(_vm.healthChecks.length === 0)?_c('v-row',[_c('v-col',[_c('NoHealthChecks',{attrs:{"nodeType":_vm.configLevel,"healthCheckType":_vm.type}})],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }