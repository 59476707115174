<template>
  <div class="container">
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4"
      data-test-id="health-check-config-notification-title"
    >
      <div
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        Health Check Notifications
      </div>
      <v-switch
        data-test-id="alarm-health-check-notification-switch"
        v-model="isHealthCheckNotificationEnabled"
        text
        inset
        class="notification-switch"
        color="secondary"
        @change="onClickHealthCheckNotificationEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
      ></v-switch>
    </div>
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4 pr-4 pt-3 pb-3"
    >
      <v-row no-gutters>
        <v-alert
          dense
          outlined
          :type="refImageNoteWarning"
          :color="refImageNoteColor"
          width="100%"
          v-if="isReferenceImageNotAvailable"
          style="text-align: center"
          data-test-id="health-check-config-reference-image-alert"
        >
          <p style="margin: 0px">{{ refImageNote }}</p>
        </v-alert>
        <v-col class="d-flex align-center">
          <div
            :style="{
              color:
                isUserAdmin && isDarkModeToggleEnabled
                  ? getColors.lightPrimaryColor
                  : getColors.darkBlack
            }"
            data-test-id="health-check-type-label"
          >
            Health Check Type
          </div>
        </v-col>
        <v-col>
          <v-select
            v-model="hcType"
            :items="hcTypes"
            :disabled="isToggleDisabled || !isWriteEnabled"
            outlined
            dense
            hide-details=""
            append-icon="mdi-chevron-down"
            content-class="elevation-0"
            class="text-caption"
            placeholder="Unit"
            :dark="isUserAdmin && isDarkModeToggleEnabled"
            @input="onChangeHealthCheckType"
            data-test-id="health-check-type-select"
          >
            <template #prepend-item>
              <v-card
                flat
                width="100%"
                height="100%"
                :style="{
                  backgroundColor:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.darkFrameColor
                      : '',
                  position: 'absolute',
                  top: '0px'
                }"
              />
            </template>
            <template #item="{ item }">
              <span
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : '',
                  margin: 'auto',
                  'font-size': '13px'
                }"
              >
                {{ item }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div
      class="card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4 pr-3 d-flex flex-column align-start"
      v-if="!isUserCustomer"
    >
      <div
        :style="[
          {
            color:
              isUserAdmin && isDarkModeToggleEnabled
                ? getColors.lightPrimaryColor
                : getColors.darkBlack,
            'padding-top': '19px',
            'padding-bottom': '19px'
          }
        ]"
        data-test-id="health-check-config-frequency-title"
      >
        Health Check Frequency
      </div>
      <div style="width: 100%">
        <v-row no-gutters>
          <v-col>
            <v-subheader
              class="text-no-wrap pl-0 pr-3 pb-5"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              data-test-id="health-check-config-frequency-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
              >
                mdi-counter
              </v-icon>
              Frequency
            </v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              class="text-caption"
              v-model.number="hcDuration"
              :disabled="isToggleDisabled || !isWriteEnabled"
              outlined
              dense
              placeholder="Frequency"
              type="number"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              :rules="[positiveNumberRule]"
              :error-messages="hcDurationError"
              :error="hcDurationError.length > 0"
              data-test-id="health-check-config-frequency-input"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-subheader
              class="text-no-wrap pl-0 pr-3 pb-5"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
              data-test-id="health-check-config-unit-label"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
              >
                mdi-clock-outline
              </v-icon>
              Unit
            </v-subheader>
          </v-col>
          <v-col>
            <v-select
              v-model="hcUnit"
              :items="hcUnits"
              :disabled="isToggleDisabled || !isWriteEnabled"
              outlined
              dense
              append-icon="mdi-chevron-down"
              content-class="elevation-0"
              class="text-caption"
              placeholder="Unit"
              :dark="isUserAdmin && isDarkModeToggleEnabled"
              data-test-id="health-check-config-unit-select"
            >
              <template #prepend-item>
                <v-card
                  flat
                  width="100%"
                  height="100%"
                  :style="{
                    backgroundColor:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }"
                />
              </template>
              <template #item="{ item }">
                <span
                  :style="{
                    color:
                      isUserAdmin && isDarkModeToggleEnabled
                        ? getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  }"
                >
                  {{ item }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="card-style no-bottom text-subtitle-1 font-weight-bold text-left notification-title pl-4"
      data-test-id="latest-health-check-config-notification-title"
    >
      <div
        data-test-id="latest-health-check-title"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkBlack
        }"
      >
        Latest Health Check Notifications
      </div>
      <v-switch
        data-test-id="alarm-latest-health-check-notification-switch"
        v-model="isLatestHealthCheckNotificationEnabled"
        text
        inset
        class="notification-switch"
        color="secondary"
        @change="onClickLatestHealthCheckNotificationEnabled"
        :disabled="isToggleDisabled || !isWriteEnabled"
        :dark="isUserAdmin && isDarkModeToggleEnabled"
      ></v-switch>
    </div>
    <v-col cols="12" v-if="level === 'camera'">
      <div style="width: 100%">
        <v-row no-gutters>
          <v-col>
            <v-subheader
              class="text-no-wrap pl-0 pr-3 pb-5"
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkPrimaryColor
              }"
            >
              <v-icon
                class="pr-2 d-none d-sm-flex"
                :style="{
                  color:
                    isUserAdmin && isDarkModeToggleEnabled
                      ? getColors.lightPrimaryColor
                      : getColors.darkPrimaryColor
                }"
              >
                mdi-calendar-range
              </v-icon>
              Performed
            </v-subheader>
          </v-col>
          <v-col>
            <p
              :style="{
                color:
                  isUserAdmin && isDarkModeToggleEnabled
                    ? getColors.lightPrimaryColor
                    : getColors.darkBlack
              }"
            >
              <code class="pointer" v-text="lastHealthCheckAt"> </code>
            </p>
          </v-col>
        </v-row></div
    ></v-col>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import { getDateAndTime } from '@/utils/Utils'

const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }
const namespaceCamera = { namespace: 'camera' }

@Component({
  components: {
    Button
  }
})
export default class HealthCheckConfiguration extends Vue {
  @Prop({ default: null }) configuration: any
  @Prop({ default: null }) clientConfig!: any
  @Prop({ default: null }) siteConfig!: any
  @Prop() onSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onLatestHCSwitchChange: (status: boolean, isChanged: boolean) => void
  @Prop() onChange: (value: any) => void
  @Prop() onTypeChange: (value: any, isChanged: boolean) => void
  @Prop({ default: 'any' }) level!: string
  @Prop({ default: false }) isWriteEnabled!: boolean
  @Prop() cameraId: string
  @Prop() isHcFieldEnabled!: boolean

  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser)
  public getColors!: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean

  @Action('lastIntegrityCheckHcDate', namespaceCamera)
  public lastIntegrityCheckHcDate!: any

  public hcUnits = ['hours', 'days', 'weeks', 'months']
  public hcTypes = ['Incident', 'Live']

  public hcDuration: number = 0
  public hcUnit: string = this.hcUnits[0]
  public hcType = 'Incident'
  public isHealthCheckNotificationEnabled = false
  public isLatestHealthCheckNotificationEnabled = false
  public isReferenceImageNotAvailable = false

  public hcDurationError: string = ''
  public originalHcData: any = null

  public lastHealthCheckAt: string = 'Loading...'

  public mounted() {
    this.watchConfiguration()
    this.changeCameraId()
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  public get isUserCustomer() {
    return this.currentUser?.role === 'Customer'
  }

  @Watch('configuration')
  public async changeCameraId() {
    if (this.level === 'camera') {
      const lastCheck = await this.lastIntegrityCheckHcDate({
        cameraDocId: this.configuration.id,
        hcType: this.hcType
      })

      if (lastCheck === null) {
        this.lastHealthCheckAt = 'No health check performed!'
      } else {
        const { date, time } = getDateAndTime(lastCheck)
        this.lastHealthCheckAt = `on ${date} at ${time}`
      }
    }
  }

  public get refImageNote() {
    if (this.level === 'camera') {
      return 'Reference Image required for Health Check'
    }
    return 'Camera Reference Images are required for Health Check'
  }

  public get refImageNoteWarning() {
    if (this.level === 'camera') {
      return 'error'
    }
    return 'info'
  }

  public get refImageNoteColor() {
    if (this.level === 'camera') {
      if (this.hcDuration > 0) {
        return '#FF5252'
      }
    }
    return '#ffd42a'
  }

  public get isToggleDisabled() {
    return this.isHcFieldEnabled === null
      ? this.configuration?.blockToggles?.healthCheck !== true
      : this.isHcFieldEnabled !== true && this.level !== 'client'
  }

  @Watch('configuration')
  @Watch('clientConfig')
  @Watch('siteConfig')
  @Watch('isHcFieldEnabled')
  public watchConfiguration() {
    let showingConfig: any

    if (!this.isToggleDisabled) {
      if (this.configuration) {
        showingConfig = this.configuration
      }
    } else {
      if (this.level === 'site') {
        showingConfig = this.clientConfig
      }
      if (this.level === 'camera') {
        showingConfig = this.siteConfig?.blockToggles?.healthCheck
          ? this.siteConfig
          : this.clientConfig
      }
    }

    if (showingConfig) {
      this.isHealthCheckNotificationEnabled =
        showingConfig.isHealthCheckNotificationEnabled || false
      this.isLatestHealthCheckNotificationEnabled =
        showingConfig?.isLatestHealthCheckNotificationEnabled || false
      this.hcType = showingConfig?.healthCheckType ?? this.hcTypes[0]
      this.convertDuration(showingConfig.healthCheckDuration)

      this.originalHcData = {
        hcUnit: this.hcUnit,
        hcDuration: this.hcDuration,
        isHealthCheckNotificationEnabled: this.isHealthCheckNotificationEnabled,
        isLatestHealthCheckNotificationEnabled:
          this.isLatestHealthCheckNotificationEnabled,
        hcType: this.hcType
      }
    }

    this.checkReferenceImage()
  }

  public hasTypeChanged(): boolean {
    return this.hcType !== this.originalHcData.hcType
  }

  public hasHCSwitchChanged(): boolean {
    return (
      this.isHealthCheckNotificationEnabled !==
      this.originalHcData.isHealthCheckNotificationEnabled
    )
  }
  public hasLatestHCSwitchChanged(): boolean {
    return (
      this.isLatestHealthCheckNotificationEnabled !==
      this.originalHcData.isLatestHealthCheckNotificationEnabled
    )
  }

  public hasDataChanged(): boolean {
    return (
      this.hcUnit !== this.originalHcData.hcUnit ||
      this.hcDuration !== this.originalHcData.hcDuration ||
      this.isLatestHealthCheckNotificationEnabled !==
        this.originalHcData.isLatestHealthCheckNotificationEnabled
    )
  }

  private checkReferenceImage() {
    if (this.configuration?.referenceImage && this.level === 'camera') {
      this.isReferenceImageNotAvailable = false
      return
    }
    this.isReferenceImageNotAvailable = true
  }

  public onClickHealthCheckNotificationEnabled(value: boolean) {
    this.onSwitchChange(value, this.hasHCSwitchChanged())
    // Propagate healthcheck type along with enable toogle
    this.onTypeChange(this.hcType, this.hasHCSwitchChanged())
  }
  public onClickLatestHealthCheckNotificationEnabled(value: boolean) {
    this.onLatestHCSwitchChange(value, this.hasLatestHCSwitchChanged())
  }

  @Watch('hcDuration')
  @Watch('hcUnit')
  public onClickSaveHealthCheckConfig() {
    let isError: boolean = false
    this.hcDurationError = ''

    if (this.hcDuration < 0) {
      isError = true
      this.hcDurationError =
        'Please enter a positive number for health check frequency!'
      this.hcDuration = null
    }

    if (!this.hcUnit || !this.hcDuration) {
      isError = true
      this.hcDurationError = 'Please input the frequency!'
    }

    // Convert the frequency and unit to hours
    let durationInHours
    if (this.hcUnit === 'hours') {
      durationInHours = this.hcDuration
    } else if (this.hcUnit === 'days') {
      durationInHours = this.hcDuration * 24
    } else if (this.hcUnit === 'weeks') {
      durationInHours = this.hcDuration * 24 * 7
    } else if (this.hcUnit === 'months') {
      durationInHours = this.hcDuration * 24 * 30
    }

    const data = {
      durationInHours,
      isError,
      isChanged: isError ? false : this.hasDataChanged()
    }

    this.onChange(data)
  }

  public onChangeHealthCheckType() {
    this.onTypeChange(this.hcType, this.hasTypeChanged())
  }

  private convertDuration(durationInHours: number) {
    if (durationInHours) {
      if (durationInHours % 24 === 0) {
        if (durationInHours % (24 * 30) === 0) {
          this.hcDuration = durationInHours / (24 * 30)
          this.hcUnit = 'months'
        } else if (durationInHours % (24 * 7) === 0) {
          this.hcDuration = durationInHours / (24 * 7)
          this.hcUnit = 'weeks'
        } else {
          this.hcDuration = durationInHours / 24
          this.hcUnit = 'days'
        }
      } else {
        this.hcDuration = durationInHours
        this.hcUnit = 'hours'
      }
    } else {
      this.hcDuration = 0
      this.hcUnit = this.hcUnits[0]
    }
  }

  public positiveNumberRule = (value) => {
    const isValid = !!value && value > 0
    return isValid || 'Please enter a positive number'
  }
}
</script>

<style scoped>
.notification-title {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.notification-switch {
  float: left;
  padding-left: 15px;
}

.card-style {
  width: 100%;
  box-shadow: none !important;
  border-top: solid #dadada 1px;
  border-bottom: solid #dadada 1px;
  background-color: transparent;
}

.v-application .text-h6 {
  font-size: 1.1rem !important;
}

.text-h5 {
  font-size: 1.25rem !important;
}

.menu-icon {
  float: right;
  padding-left: 10px;
  bottom: -3px;
}
.no-bottom {
  border-bottom: none;
  background-color: 'red' !important;
}
.container {
  padding: 0;
  border-bottom: solid #dadada 1px;
}
</style>
