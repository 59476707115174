var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"69vh","overflow":"auto"}},[_c('v-data-table',{attrs:{"id":"dashboard-table","items":_vm.results,"hide-default-footer":true,"items-per-page":10,"data-test-id":"incidents-list"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{style:({
          backgroundColor: _vm.getBackgroundColor(props.item.id)
        }),attrs:{"id":"search-an-table-row"},on:{"click":function($event){return _vm.onRowClick(props.item)}}},[_c('v-row',{staticClass:"py-1",class:_vm.smallScreen ? 'alarm-view-smallscreen' : '',staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","border-bottom":"1px solid #d4d4d4"},style:(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-lg-5",attrs:{"cols":_vm.smallScreen ? 3 : 5}},[_c('SnapshotModalForAlarms',{key:props.item.id,attrs:{"item":props.item,"isPending":!_vm.isErrorIncident(props.item) && _vm.isPending(props.item)}})],1),_c('v-col',{staticClass:"custom-offset",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"},attrs:{"cols":"6"}},[_c('div',[(props.item?.completed === true)?_c('div',{class:_vm.smallScreen
                    ? 'alarm-view-datetime-container-smallscreen'
                    : '',staticStyle:{"display":"flex","justify-content":"start"}},[(props.item?.completed === true)?_c('p',{class:_vm.smallScreen ? 'alarm-view-datetime-smallscreen' : '',staticStyle:{"text-align":"left","margin-bottom":"0px","font-size":"18px","margin-right":"10px"},style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
                  })},[_vm._v(" "+_vm._s(_vm.getFormattedDate( new Date(props.item?.createdat).toString() ))+" ")]):_vm._e(),(props.item?.completed === true)?_c('p',{class:_vm.smallScreen ? 'alarm-view-time-smallscreen' : '',staticStyle:{"text-align":"left","font-size":"18px"},style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
                  })},[_vm._v(" "+_vm._s(_vm.getFormattedTime( new Date(props.item?.createdat).toString() ))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('div',{staticStyle:{"margin-right":"25px"}},[_c('p',{class:_vm.smallScreen ? 'alarm-view-id-smallscreen' : '',staticStyle:{"text-align":"left","margin-bottom":"0px","font-size":"10px"},style:({
                      color:
                        _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? '#fff'
                          : '#8c8c8c'
                    })},[_vm._v(" ID: "+_vm._s(props.item.id)+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"start"},style:(_vm.isErrorIncident(props.item) && _vm.smallScreen
                        ? 'height: 10px'
                        : '')},[(_vm.isPending(props.item))?_c('p',{class:_vm.smallScreen
                          ? 'error-alarm-view-datetime-smallscreen'
                          : 'error-alarm-view-datetime-pending',staticStyle:{"text-align":"left","margin-bottom":"0px","font-size":"12px"},style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v(" Created at: "+_vm._s(_vm.getFormattedDate(props.item?.createdat))+" ")]):_vm._e(),(_vm.isPending(props.item))?_c('p',{class:_vm.smallScreen ? 'error-alarm-view-time-smallscreen' : '',style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v(" "+_vm._s(_vm.getFormattedTime(props.item?.createdat))+" ")]):_vm._e()])])]),(
                  !_vm.isErrorIncident(props.item) &&
                  _vm.isPending(props.item) &&
                  !_vm.smallScreen
                )?_c('div',{},[_c('p',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? '#fff' : '#000'
                  })},[_vm._v(" Pending ")]),_c('v-icon',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                      ? 'text-yellow'
                      : 'text-red'},[_vm._v(" mdi-clock-time-four-outline ")])],1):_vm._e(),_c('div',{class:_vm.smallScreen && _vm.isUserAdmin
                    ? 'div-container-mobile-admin'
                    : ''},[_c('div',{class:_vm.smallScreen && _vm.isUserAdmin
                      ? 'div-container-mobile-admin-text'
                      : ''},[(props.item?.isaionly === false && _vm.isUserAdmin)?_c('p',{staticClass:"ai-human-ind",attrs:{"title":"Reviewed by AI and Human","color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : _vm.getColors.darkBlackColor}},[(_vm.smallScreen)?_c('span',{style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v("Reviewed by : Ai +")]):_c('span',{style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v("Ai +")]),_c('v-icon',{attrs:{"color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                          ? _vm.getColors.lightPrimaryColor
                          : _vm.getColors.darkBlackColor,"small":""}},[_vm._v("mdi-account")])],1):_vm._e(),(props.item?.isaionly === true && _vm.isUserAdmin)?_c('p',{staticClass:"ai-human-ind",attrs:{"title":"Reviewed by AI only"}},[(_vm.smallScreen)?_c('span',{style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v("Reviewed by : Ai ")]):_c('span',{style:({
                        color:
                          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                            ? '#fff'
                            : '#000'
                      })},[_vm._v("Ai ")])]):_vm._e()]),_c('div',{class:_vm.smallScreen && _vm.isUserAdmin
                      ? 'div-container-mobile-admin-icons'
                      : ''},[_c('div',{class:{
                      'bottom-result-box':
                        !_vm.smallScreen && _vm.isErrorIncident(props.item),
                      'bottom-result-box-customer':
                        !_vm.smallScreen && !_vm.isUserAdmin,
                      'bottom-result-box-left':
                        !_vm.isErrorIncident(props.item) &&
                        _vm.isUserAdmin &&
                        !_vm.smallScreen,
                      'bottom-result-box-mobile':
                        _vm.smallScreen && _vm.isErrorIncident(props.item),
                      'bottom-result-box-customer-mobile':
                        _vm.smallScreen && !_vm.isUserAdmin,
                      'bottom-result-box-left-mobile':
                        !_vm.isErrorIncident(props.item) &&
                        _vm.isUserAdmin &&
                        _vm.smallScreen
                    }},[_c('div',{class:{
                        assessment: _vm.isUserAdmin,
                        'assessment-customer': !_vm.isUserAdmin,
                        'assessment-customer-mobile':
                          !_vm.isUserAdmin && _vm.smallScreen
                      }},[_c('div',[(
                            props.item?.finalresult__personassessment ===
                            'unsure'
                          )?_c('div',{staticClass:"my-1 text-yellow",class:{
                            'dark-mode-text-yellow':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{staticClass:"text-yellow",class:{
                              'dark-mode-text-yellow':
                                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"small":"","title":"Person Unsure"}},[_vm._v("mdi-human-handsdown")])],1):_vm._e(),(
                            props.item?.finalresult__personassessment ===
                            'alarm'
                          )?_c('div',{staticClass:"my-1 text-red",class:{
                            'dark-mode-text-red':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{staticClass:"text-red",class:{
                              'dark-mode-text-red':
                                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"small":"","title":"Person"}},[_vm._v("mdi-walk")])],1):_vm._e(),(
                            props.item?.finalresult__personassessment ===
                              'no-alarm' && props.item?.isunarmed !== true
                          )?_c('div',{staticClass:"my-1 text-green",class:{
                            'dark-mode-text-green':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{staticClass:"text-green",class:{
                              'dark-mode-text-green':
                                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"small":"","title":"No Person"}},[_vm._v("mdi-human-handsdown")])],1):(
                            props.item?.finalresult__personassessment ===
                              'no-alarm' && props.item?.isunarmed === true
                          )?_c('div',{staticClass:"my-1 text-grey",class:{
                            'dark-mode-text-grey':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{staticClass:"text-grey",class:{
                              'dark-mode-text-grey':
                                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"small":"","title":"No Person"}},[_vm._v("mdi-walk")])],1):_vm._e()]),(
                          props.item?.alarmdefinition === 'person_vehicle'
                        )?_c('div',{staticClass:"mx-1"},[(!_vm.smallScreen)?_c('v-icon',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? 'white-text'
                              : 'black-text',attrs:{"x-small":"","disabled":""}},[_vm._v("mdi-plus-thick")]):_vm._e()],1):_vm._e(),(
                          props.item?.alarmdefinition === 'person_vehicle'
                        )?_c('div',[(
                            props.item?.finalresult__vehiclemovement &&
                            (props.item?.finalresult__vehiclemovement ===
                              'alarm' ||
                              props.item?.finalresult__vehiclemovement ===
                                'true')
                          )?_c('div',{staticClass:"my-1 mx-1 vehicle-radius",class:(_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? 'dark-mode-text-red '
                              : '') +
                            (_vm.smallScreen ? 'text-red-mobile' : 'text-red')},[(
                              props.item?.filemetadata__mediatype === 'image'
                            )?_c('div',[_c('v-icon',{staticClass:"text-red vehicle-radius",class:{
                                'dark-mode-text-red':
                                  _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                                'icon-size-mobile': _vm.smallScreen
                              },attrs:{"title":"Vehicle"}},[_vm._v("mdi-car-hatchback")])],1):_c('div',[_c('img',{class:_vm.smallScreen ? 'image-size-mobile-view' : '',attrs:{"src":'/img/vehicle_movement.svg',"alt":"Icon","title":"Vehicle Movement"}})])]):_vm._e(),(
                            (props.item?.finalresult__vehiclemovement &&
                              (props.item?.finalresult__vehiclemovement ===
                                'no-alarm' ||
                                props.item?.finalresult__vehiclemovement ===
                                  'false')) ||
                            props.item?.finalresult__vehiclemovement ===
                              undefined
                          )?_c('div',{staticClass:"my-1 mx-1 vehicle-radius",class:{
                            'text-green': props.item?.isunarmed !== true,
                            'text-grey': props.item?.isunarmed === true,
                            'dark-mode-text-green':
                              _vm.isUserAdmin &&
                              _vm.isDarkModeToggleEnabled &&
                              props.item?.isunarmed !== true,
                            'dark-mode-text-grey':
                              _vm.isUserAdmin &&
                              _vm.isDarkModeToggleEnabled &&
                              props.item?.isunarmed === true,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{class:{
                              'text-green vehicle-radius':
                                props.item?.isunarmed !== true,
                              'text-grey vehicle-radius':
                                props.item?.isunarmed === true,
                              'dark-mode-text-green':
                                _vm.isUserAdmin &&
                                _vm.isDarkModeToggleEnabled &&
                                props.item?.isunarmed !== true,
                              'dark-mode-text-grey':
                                _vm.isUserAdmin &&
                                _vm.isDarkModeToggleEnabled &&
                                props.item?.isunarmed === true,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"title":props.item?.filemetadata__mediatype === 'image'
                                ? 'No Vehicle'
                                : 'No Vehicle Movement'}},[_vm._v(" mdi-car-hatchback ")])],1):_vm._e(),(
                            props.item?.finalresult__vehiclemovement ===
                            'unsure'
                          )?_c('div',{staticClass:"my-1 text-yellow vehicle-radius",class:{
                            'dark-mode-text-yellow':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          }},[_c('v-icon',{staticClass:"text-yellow vehicle-radius",class:{
                              'dark-mode-text-yellow':
                                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                              'icon-size-mobile': _vm.smallScreen
                            },attrs:{"title":"Vehicle Unsure"}},[_vm._v(" mdi-car-hatchback ")])],1):_vm._e()]):_vm._e(),(
                          props.item?.missed &&
                          _vm.isUserAdmin &&
                          props.item?.missed === true
                        )?_c('div',{staticClass:"mx-1"},[_c('v-icon',{class:_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                              ? 'white-text'
                              : 'black-text',attrs:{"x-small":"","disabled":""}},[_vm._v("mdi-plus-thick")])],1):_vm._e(),(
                          props.item?.missed &&
                          _vm.isUserAdmin &&
                          props.item?.missed === true
                        )?_c('div',{staticClass:"my-1 ml-1"},[_c('v-icon',{staticClass:"text-red",class:{
                            'dark-mode-text-yellow':
                              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled,
                            'icon-size-mobile': _vm.smallScreen
                          },attrs:{"title":"Missed"}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()])])])])])])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }