<template>
  <div class="video-container">
    <iframe
      :src="src"
      title="WHEP Iframe Player"
      allow="autoplay"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class WHEPIframe extends Vue {
  @Prop({ required: true }) whepUrl: string
  @Prop({ required: true }) streamPath: string

  public get src(): string {
    if (!this.whepUrl || !this.streamPath) {
      // TODO: Remove this hard-coded URL (Fixed with https://gitlab.com/aoye/web/aoye_front_end/-/merge_requests/2171)
      return 'https://live.promiseq.com/not-found'
    }
    return `${this.whepUrl}${this.streamPath}`.replace('/whep', '')
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  background: black;
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
