var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"d-flex flex-column py-0 pl-0",attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"outlined":"","item-color":"white","background-color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? _vm.getColors.darkSectionColor : '',"label":_vm.searchText,"placeholder":_vm.searchText,"solo":"","color":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled
        ? _vm.getColors.darkLowFrameColor
        : ''},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
          backgroundColor:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.darkFrameColor
              : '',
          position: 'absolute',
          top: '0px'
        }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }