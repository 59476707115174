<template>
  <div class="link-expiry" v-if="!hideExpiry">
    <div v-if="timer">
      <span class="text-color">Link will expire in</span>
      <span
        class="countdown"
        :style="{
          color:
            isUserAdmin && isDarkModeToggleEnabled
              ? getColors.lightPrimaryColor
              : getColors.darkPrimaryColor
        }"
      >
        {{ hours }} : {{ minutes }} :
        {{ seconds }}
      </span>
    </div>

    <span v-else class="expired">Link has expired</span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const namespaceUser = { namespace: 'user' }
const namespaceConfig = { namespace: 'config' }

@Component
export default class LinkExpiration extends Vue {
  @Prop({ required: true }) public expiryTime!: Date
  @Getter('currentUser', namespaceUser) private currentUser: any
  @Getter('getisDarkModeToggleEnabled', namespaceConfig)
  public isDarkModeToggleEnabled: boolean
  @Getter('getColors', namespaceUser)
  public getColors!: any

  public hideExpiry: boolean = false
  public timer: any = null

  public hours: string = '00'
  public minutes: string = '00'
  public seconds: string = '00'

  public remainingTime(): {
    hours: string
    minutes: string
    seconds: string
  } {
    const currentTime = new Date()
    const remainingTime = this.expiryTime.getTime() - currentTime.getTime()
    const hours = Math.max(Math.floor(remainingTime / (60 * 60 * 1000)), 0)
    const minutes = Math.max(Math.floor(remainingTime / (60 * 1000)) % 60, 0)
    const seconds = Math.max(Math.floor(remainingTime / 1000) % 60, 0)

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    }
  }

  private startTimer(): void {
    if (this.timer) return
    this.timer = setInterval(() => {
      const currentTime = new Date()
      const remainingTime = this.expiryTime.getTime() - currentTime.getTime()
      const { hours, minutes, seconds } = this.remainingTime()
      this.hours = hours
      this.minutes = minutes
      this.seconds = seconds
      if (remainingTime <= 0) {
        this.stopTimer()
        this.$emit('expired')
        return
      }
      this.$forceUpdate()
    }, 1000)
  }

  private stopTimer(): void {
    if (!this.timer) return
    clearInterval(this.timer)
    this.timer = null
  }

  public get isUserAdmin() {
    return this.currentUser?.role === 'Administrator'
  }

  private mounted(): void {
    // if expiry time is more than 1 year, hide the timer
    const currentTime = new Date()
    const remainingTime = this.expiryTime.getTime() - currentTime.getTime()
    if (remainingTime > 365 * 24 * 60 * 60 * 1000) {
      this.hideExpiry = true
      this.$emit('hide')
      return
    }
    this.startTimer()
  }

  private beforeDestroy(): void {
    this.stopTimer()
  }
}
</script>

<style scoped>
.countdown {
  color: #000000;
}

.expired {
  font-weight: 500;
  color: #ff0303;
}

.link-expiry {
  border: 1px solid rgb(152, 193, 255);
  border-radius: 5px;
  padding: 3px;
  width: 100%;
}

.text-color {
  color: rgb(152, 193, 255);
}

.countdown {
  width: 80px;
  display: inline-block;
}
</style>
