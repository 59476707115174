<template>
  <div class="container--outline" style="height: 80px">
    <v-row class="d-flex flex-row align-center" style="height: 80px">
      <v-col class="d-flex flex-row col-md-2 align-center justify-start">
        <v-row justify="start">
          <AccountAvatar />
        </v-row>
      </v-col>

      <v-col
        class="d-flex flex-row col-md-10 align-center justify-end"
        style="padding: 0px"
      >
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Button from '@/components/app/Button.vue'
import AccountAvatar from '@/components/app/AccountAvatar.vue'
import Avatar from '@/components/user/Avatar.vue'

const namespaceUser = { namespace: 'user' }

@Component({
  components: {
    Button,
    Avatar,
    AccountAvatar
  }
})
export default class SimpleHeader extends Vue {
  @Getter('currentUser', namespaceUser) public currentUser: any
  @Getter('getColors', namespaceUser) public getColors!: any

  public isUserAdmin: boolean = false

  mounted() {
    this.isUserAdmin = this.currentUser?.role === 'Administrator'
  }
}
</script>

<style scoped>
.container--outline {
  border-style: solid;
  border-color: #d5d3d3 !important;
  border-width: 0px 0px 1px 0px;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 12px;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'sm-and-up' )}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn
  border-color: rgb(255, 212, 42) !important
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon
  color: inherit,
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  background: #ffffffc,
@media #{map-get($display-breakpoints, 'xs-only' )}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn
  border-color: rgb(255, 212, 42) !important
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon
  color: inherit,
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  background: #ffffffc,
</style>
