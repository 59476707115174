import axios, { AxiosInstance, AxiosResponse } from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { backendURL } from './backendConfig'
import crypto from 'crypto'
import { Endpoint } from '../constants'

export interface RequestConfig {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD'
  url: string
  data?: any
  headers?: Record<string, string>
  params?: Record<string, string>
}

interface RequestCacheEntry {
  timestamp: number
  promise: Promise<AxiosResponse>
}

class SignedRequest {
  private static instance: SignedRequest
  private axiosInstance: AxiosInstance
  private requestCache: Map<string, RequestCacheEntry>

  private constructor(
    backendURL: string,
    private throttlePeriod: number = 5_000
  ) {
    this.axiosInstance = axios.create({
      baseURL: backendURL
    })
    this.requestCache = new Map()
  }

  public static getInstance(backendURL: string): SignedRequest {
    if (!SignedRequest.instance) {
      SignedRequest.instance = new SignedRequest(backendURL)
    }
    return SignedRequest.instance
  }

  private async getAuthHeader() {
    try {
      const token = await firebase.auth().currentUser?.getIdToken(false)
      return { Authorization: `Bearer ${token}` }
    } catch (error) {
      console.error('Error getting ID token:', error)
      throw error
    }
  }

  private generateKey({ method, url, data, params }: RequestConfig): string {
    const hash = crypto.createHash('md5')
    hash.update(
      `${method}:${url}:${JSON.stringify(params)}:${JSON.stringify(data)}`
    )
    return hash.digest('hex')
  }

  private isRequestThrottled(key: string): boolean {
    const entry = this.requestCache.get(key)
    const now = Date.now()
    if (entry && now - entry.timestamp < this.throttlePeriod) {
      return true
    }
    this.requestCache.delete(key)
    return false
  }

  public async request(config: RequestConfig): Promise<AxiosResponse> {
    const key = this.generateKey(config)
    if (this.isRequestThrottled(key)) {
      console.log('Request throttled:', key)
      return this.requestCache.get(key)?.promise
    }

    const authHeader = await this.getAuthHeader()
    const requestHeaders = { ...config.headers, ...authHeader }

    const promise = this.axiosInstance
      .request({
        ...config,
        headers: requestHeaders
      })
      .then((response) => {
        this.requestCache.delete(key)
        return response
      })
      .catch((error) => {
        this.requestCache.delete(key)
        throw error
      })

    this.requestCache.set(key, { timestamp: Date.now(), promise })
    return promise
  }
}

// Export named instance of SignedRequest
export const adminApi = SignedRequest.getInstance(backendURL(Endpoint.Admin))
