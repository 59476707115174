var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"camera"},[(_vm.isAuthenticated === true)?_c('div',{staticStyle:{"width":"100%","height":"100vh","display":"flex","justify-content":"center","overflow":"hidden"}},[_c('div',{staticClass:"video-container",style:({
        display: _vm.isLoading || _vm.isErrorFound ? 'none' : 'flex'
      })},[_c('div',{staticClass:"video-element",attrs:{"id":"video"}},[_c('video',{attrs:{"id":_vm.videoElementId,"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true},on:{"timeupdate":_vm.onTimeUpdate,"playing":_vm.handleOnVideoPlay}})])]),(_vm.isLoading || _vm.isErrorFound)?_c('div',{staticClass:"loading-wrapper"},[_c('div',{staticClass:"image-wrapper"},[_c('div',{staticClass:"img-overlay"}),_c('img',{ref:"imageSource",attrs:{"id":"source","src":_vm.loaderImageLink}})]),_c('div',{staticClass:"loading-content blur-backdrop"},[(_vm.isStreamUnavailable || _vm.isErrorFound)?_c('div',{style:({
            color: _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
          })},[_c('v-icon',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
            })},[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.statusMessage)+" ")],1):_vm._e(),(!(_vm.isStreamUnavailable || _vm.isErrorFound))?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}}),_c('div',{style:({
              color:
                _vm.isUserAdmin && _vm.isDarkModeToggleEnabled ? 'white' : 'black'
            })},[_vm._v(" Loading... ")])],1):_vm._e()])]):_vm._e()]):_vm._e(),(_vm.seekEnabled)?_c('div',{attrs:{"id":"video-controls"}},[_c('Button',{attrs:{"btnText":"Play","btnStyle":"default"},on:{"onButtonClick":function($event){return _vm.onPlaySelect()}}}),_c('Button',{attrs:{"btnText":"Pause","btnStyle":"default"},on:{"onButtonClick":function($event){return _vm.onPauseSelect()}}}),_c('Button',{attrs:{"btnText":_vm.streamSelectButtonTxt,"btnStyle":"default"},on:{"onButtonClick":function($event){return _vm.onStreamSelect()}}}),_c('input',{attrs:{"type":"range","id":_vm.seekBarElementId,"value":"0"},on:{"change":_vm.onChange}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }