var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      { active: _vm.isExpanded },
      'card-style text-subtitle-1 font-weight-bold text-left notification-title pl-4'
    ],on:{"click":function($event){return _vm.processExpansion()}}},[_c('div',{style:({
        color:
          _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
            ? _vm.getColors.lightPrimaryColor
            : _vm.getColors.darkBlack
      }),attrs:{"data-test-id":"hikvision-hcp-expand-title"}},[_vm._v(" HikVision HCP ")]),_c('v-icon',{staticClass:"chevron-padding",attrs:{"size":"30","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"data-test-id":"hikvision-hcp-expand-icon"}},[_vm._v(_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1),(_vm.isExpanded)?_c('div',{staticClass:"pl-4 pb-2"},[_c('div',{staticClass:"pr-3",staticStyle:{"display":"flex","flex-direction":"row","font-size":"13px"}},[_c('v-col',{staticStyle:{"flex":"1"},attrs:{"cols":"12"}},[_c('v-row',[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Service Url - (eg: https://127.0.0.1:4443)","disabled":!_vm.isWriteEnabled,"outlined":"","data-test-id":"hikvision-hcp-service-url-input"},model:{value:(_vm.serviceUrl),callback:function ($$v) {_vm.serviceUrl=$$v},expression:"serviceUrl"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"label":"Partner App Key","disabled":!_vm.isWriteEnabled,"outlined":"","data-test-id":"hikvision-hcp-app-key-input"},model:{value:(_vm.appKey),callback:function ($$v) {_vm.appKey=$$v},expression:"appKey"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":!_vm.isWriteEnabled,"label":"Partner App Secret","outlined":"","data-test-id":"hikvision-hcp-app-secret-input"},model:{value:(_vm.appSecret),callback:function ($$v) {_vm.appSecret=$$v},expression:"appSecret"}})],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"px-0 pt-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"items":['1.7.0', '2.2.1 or Above'],"label":"HikVision HCP Version","outlined":"","hide-details":"true","disabled":!_vm.isWriteEnabled,"data-test-id":"hikvision-hcp-version-select"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-card',{style:({
                    backgroundColor:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.darkFrameColor
                        : '',
                    position: 'absolute',
                    top: '0px'
                  }),attrs:{"flat":"","width":"100%","height":"100%"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('span',{style:({
                    color:
                      _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                        ? _vm.getColors.lightPrimaryColor
                        : '',
                    margin: 'auto',
                    'font-size': '13px'
                  })},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,768580534),model:{value:(_vm.hikCentralVersion),callback:function ($$v) {_vm.hikCentralVersion=$$v},expression:"hikCentralVersion"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","font-size":"13px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h3',{staticClass:"text-left pt-5 pb-3 my-2 mt-2",style:({
            color:
              _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
                ? _vm.getColors.lightPrimaryColor
                : _vm.getColors.darkBlack
          }),attrs:{"data-test-id":"hikvision-hcp-acknowledge-title"}},[_vm._v(" Automatic Event Acknowledgement ")])]),_c('div',{staticClass:"mt-2 mr-1"},[_c('v-switch',{staticClass:"notification-switch",attrs:{"dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":!_vm.isWriteEnabled,"text":"","color":"secondary","inset":"","data-test-id":"hikvision-hcp-acknowledge-switch"},on:{"change":_vm.onClickHikCentralAcknowledgeEnabledStatus},model:{value:(_vm.isAcknowledgementEnabled),callback:function ($$v) {_vm.isAcknowledgementEnabled=$$v},expression:"isAcknowledgementEnabled"}})],1)]),_c('div',{staticClass:"font-weight-bold text-left notification-title pr-1"},[_c('div',{style:({
          color:
            _vm.isUserAdmin && _vm.isDarkModeToggleEnabled
              ? _vm.getColors.lightPrimaryColor
              : _vm.getColors.darkBlack
        }),attrs:{"data-test-id":"hikvision-hcp-notification-title"}},[_vm._v(" Turn on/off Hikvision HCP ")]),_c('v-switch',{staticClass:"notification-switch",attrs:{"id":"alarm-hikvision-notification-switch","dark":_vm.isUserAdmin && _vm.isDarkModeToggleEnabled,"disabled":!_vm.isWriteEnabled,"text":"","color":"secondary","inset":"","data-test-id":"hikvision-hcp-notification-switch"},on:{"change":_vm.onClickHikCentralEnabled},model:{value:(_vm.isHikCentralEnabled),callback:function ($$v) {_vm.isHikCentralEnabled=$$v},expression:"isHikCentralEnabled"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }